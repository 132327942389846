import * as React from 'react';
import { useApiService } from '../../api/useApiService';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Button, Col, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Subscription } from '../../api/models/Subscription';
import { SubscriptionLicense } from '../../api/models/SubscriptionLicense';
import { useNavigateWhenReady } from '../shared/hooks/useNavigateWhenReady';
import { ProgressWheel } from '../schools/progressWheel/ProgressWheel';
import { ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { Subject } from '../../api/models/Subject';
import { useLoadProjectWithRetry } from '../subjectReviews/useLoadProjectWithRetry';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../utilities/replacePlaceholders';
import { QuestionSet } from '../../api/models/QuestionSet';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { Redirect } from 'react-router';
import { Trust } from '../../api/models/Trust';
import { User } from '../../api/models/User';

export interface ReadingReviewOverviewProps {
    questionSetName: string,
    routePart: string,
    subscriptionId: string,
}

// Specialised versions of ReadingReviewOveriew that match to specific question sets.
export const _TrustReadingReviewEYFSToKS2Overview = (props: any) => (<TrustReadingReviewOverview {...props} questionSetName="Reading review EYFS to KS2" routePart="readingReviewEYFSToKS2" />);
export const _TrustReadingReviewKS3ToKS4Overview = (props: any) => (<TrustReadingReviewOverview {...props} questionSetName="Reading review KS3 to KS4" routePart="readingReviewKS3ToKS4" />);


export const TrustReadingReviewEYFSToKS2Overview = connect(
    (state: AppState) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_TrustReadingReviewEYFSToKS2Overview);

export const TrustReadingReviewKS3ToKS4Overview = connect(
    (state: AppState) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_TrustReadingReviewKS3ToKS4Overview);


/**
 * Overview of a reading review.  Usually used via a specalised component such as ReadingReviewEYFSToKS2Overview.
 * @param props
 */
export const TrustReadingReviewOverview = (props: ReadingReviewOverviewProps) => {
    const {
        questionSetName,
        routePart,
        subscriptionId,
    } = props;

    const api = useApiService();
    const loadViewModel = api.trusts.viewModels.overview();

    // Load the main model data.
    //
    const [model, setModel] = React.useState<Trust | undefined>();
    const [schools, setSchools] = React.useState<Array<School>>([]);
    const [user, setUser] = React.useState<User | undefined>();
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(undefined, subscriptionId);
        setSchools(result.schools);
        setUser(result.user);
        setSubscription(result.subscription);
        setModel(result.model);
    }, [loadViewModel, setModel, setSchools, subscriptionId, setSubscription, setUser,]);
    React.useEffect(() => {
        if (!subscriptionId || isLoading) {
            return;
        }

        if (model && model.subscriptionId === subscriptionId) {
            return;
        }

        load();
    }, [subscriptionId, load, model, isLoading]);

    // Select the first school.
    const school = React.useMemo(() => schools.find(it => true), [schools]);

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const {
        project,
        isLoadingProject,
        loadProjectErrors,
    } = useLoadProjectWithRetry({
        schoolId: school && school.id || '',
        trustId: model && model.id || '',
        subjectId: undefined,
        questionSetName: questionSetName,
        createIfMissing: true
    });


    if (!model || !project) {
        return (<LoadingIndicator />);
    }

    return (
        <>
            <Redirect to={`/manage/${routePart}Dashboard/${project && project.id || ''}`} />
        </>
    );
};
