import * as React from 'react';
import { View } from '@react-pdf/renderer';
import { RAGPdfProps } from './RAGPdf';
import { RAGProjectSectionPdfUi } from './RAGProjectSectionPdfUi';


export interface RAGBreakdownPdfUiProps extends RAGPdfProps {
}

/**
 * Breakdown of questions by sections and topics for RAG (pdf format).
 */
export const RAGBreakdownPdfUi = (props: RAGBreakdownPdfUiProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <View>
            {
                props.projectSections.models.map(item => (
                    <RAGProjectSectionPdfUi key={item.id} projectSectionId={item.id} {...props} />
                ))
            }
        </View>  
    );
};

