import { pdf } from '@react-pdf/renderer';

/**
 * Generate a PNG data: URL from an SVG element shown on screen.
 */
export function pngDataUrlFromSvg(svg: Element): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        // Create a canvas and context.
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');

        // Match the size for drawing purposes.
        canvas.width = svg.clientWidth;
        canvas.height = svg.clientHeight;

        // Create an Image that we will use to load the SVG image.
        let loader = new Image(canvas.width, canvas.height);

        // Once the image has loaded we want to draw to the canvas then return it's data URL to resolve the Promise.
        loader.onload = () => {
            if (!context) {
                return;
            }
            context.drawImage(loader, 0, 0, loader.width, loader.height);

            // Resolve the result once we have it.
            resolve(canvas.toDataURL());
        };

        // Seralize the svg.
        let svgAsXml = (new XMLSerializer()).serializeToString(svg);
        let svgDataUrl = `data:image/svg+xml,${encodeURIComponent(svgAsXml)}`;

        // Set the loader's image to the svg data: url to trigger its loading.
        loader.src = svgDataUrl;
    });
}

