import { Store, AnyAction } from "redux";
import { ServiceResolver, ConfigureServices } from "inject-typesafe";
import { AppStateCore } from "../store";
import { ApiFetch, FetchCache } from "apifetch-json";
import { BlobUploadService } from "../services/BlobUploadService";
import { AuthenticationService } from "../services/AuthenticationService";
import { PasswordOptions, defaultPasswordOptions } from "../services/passwordValidation/PasswordOptions";
import { PasswordValidation } from "../services/passwordValidation/PasswordValidation";
import { EdubaseService } from "../services/EdubaseService";
import { renewCurrentUserToken } from "../utilities/renewCurrentUserToken";
import { Identity, login, logout } from "../store/user";
import { ApiService, createApiService } from "../api/apiService";

export interface AppServicesCore {
    store: ServiceResolver<Store<AppStateCore>>,

    apiFetch: ServiceResolver<ApiFetch>,
    fetchCache: ServiceResolver<FetchCache | undefined>,

    authenticationService: ServiceResolver<AuthenticationService>,
    passwordOptions: ServiceResolver<PasswordOptions>,
    passwordValidation: ServiceResolver<PasswordValidation>,

    blobUploadService: ServiceResolver<BlobUploadService>,

    edubaseService: ServiceResolver<EdubaseService>,

    renewToken: ServiceResolver<(performLogoutOnFailure?: boolean) => Promise<void>>,

    // Main REST API with respositories, view models, and actions.
    api: ApiService,
}

/**
 * Configure services used by the app that need global configuration.
 * @param store
 */
export function createConfigureServicesCore(store: Store<AppStateCore>) : ConfigureServices<AppServicesCore> {
    return (builder) => ({
        store: builder.singleton(() => store),

        apiFetch: builder.scoped(services => {
            // Add our JWT token based security to all requests made by ApiFetch.
            let userState = services.store().getState().user;

            let init: RequestInit = {};
            if (userState && userState.token) {
                init = {
                    headers: {
                        'Authorization': `Bearer ${userState.token}`
                    }
                };
            }

            // Return an ApiFetch initalised with our default request init and our shared fetch cache.
            return new ApiFetch(init, services.fetchCache());
        }),
        fetchCache: builder.scoped(services => undefined),
        authenticationService: builder.scoped(services => new AuthenticationService('api/authentication', services.apiFetch())),

        passwordOptions: builder.singleton(services => defaultPasswordOptions),
        passwordValidation: builder.scoped(services => new PasswordValidation(services.passwordOptions())),

        blobUploadService: builder.scoped(services => new BlobUploadService('api/blobs', services.apiFetch())),

        edubaseService: builder.scoped(services => new EdubaseService('api/edubase', services.apiFetch())),

        renewToken: builder.scoped(services => async (performLogoutOnFailure: boolean = true): Promise<void> => {
            let authenticationService = services.authenticationService();
            let store = services.store();
            let userState = store.getState().user;
            let _login = (token: string | undefined, identity: Identity | undefined): AnyAction => store.dispatch(login(token, identity));
            let _logout = (): AnyAction => store.dispatch(logout());

            await renewCurrentUserToken(userState, authenticationService, _login, _logout, performLogoutOnFailure);
        }),

        // API.
        api: createApiService(builder),
    });
}
