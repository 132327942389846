import React from 'react';
import { Document, Page, View, Image, Text } from '@react-pdf/renderer';
import { Action } from '../../../api/models/Action';
import { Styles } from './Styles';
import { ActionUserTask } from '../../../api/models/ActionUserTask';
import { ConditionalFragment } from 'react-conditionalfragment';
import { UserProfile } from '../../../api/models/UserProfile';
import moment from 'moment';
import { DateLabelPdf } from '../../shared/pdf/DateLabelPdf';
import { ActionPriority, actionPriorityDisplayName } from '../../../api/models/codeOnly/ActionPriority';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../utilities/replacePlaceholders';
import { Question } from '../../../api/models/Question';

interface ImprovementPlannerPdfProps {

    actions: Array<Action>
    schoolName: string | undefined
    trustName: string | undefined
    userTasks: Array<ActionUserTask>
    userProfiles: Array<UserProfile>
    placeholderReplacementMode: PlaceholderReplacementMode | undefined,
    questions: Array<Question>,
}

export const ImprovementPlannerPdf = (props: ImprovementPlannerPdfProps) => {
    const {
        actions,
        schoolName,
        trustName,
        userTasks,
        userProfiles,
        questions,
    } = props;
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <Document>
            <Page size="A4" style={{ ...Styles.page, paddingTop: 20, paddingBottom: 20 }}>
                <View style={Styles.body}>
                    <View>
                        <Text style={Styles.h1}>
                            <Text>
                                {
                                    schoolName ? schoolName : trustName ? trustName : ''
                                }
                            </Text>
                        </Text>
                        <Text style={Styles.h1Small}>
                            Actions
                        </Text>
                    </View>
                    {
                        actions.map(action => {
                            const question = questions?.find(item => item.id === action.questionId);

                            return (
                                <View>
                                    <View style={Styles.table}>
                                        <View style={Styles.thead}>
                                            <View style={Styles.row}>
                                                <Text style={{fontSize: 16, paddingTop: 2, paddingBottom: 2, paddingLeft: 4, paddingRight: 4,}}>
                                                    {replacePlaceholders(action.name, placeholderReplacementMode)}
                                                </Text>
                                            </View>
                                            <View style={Styles.row}>
                                                <View style={{ ...Styles.th, width: '33%' }}><Text>Due date: {moment(action.actionAddedDate).format("DD/MM/YYYY")}</Text></View>
                                                <View style={{ ...Styles.th, width: '33%' }}><Text>Action status: { action.actionState}</Text></View>
                                                <View style={{ ...Styles.th, width: '33%' }}><Text>Completed date: {action.completedDate ? moment(action.completedDate).format("DD/MM/YYYY") : "(Not Completed)"}</Text></View>
                                            </View>
                                            <View style={Styles.row}>
                                                <View style={{ ...Styles.th, width: '100%' }}><Text>Priority: {actionPriorityDisplayName(action.priority as ActionPriority)}</Text></View>
                                            </View>
                                        </View>
                                        <View style={Styles.tbody}>
                                            <ConditionalFragment showIf={userTasks.filter(item => item.actionId === action.id).length > 0}>

                                                <View style={Styles.innerTable}>
                                                    <View style={Styles.innerThead}>
                                                        <View style={Styles.trHead}>
                                                            <View style={{ ...Styles.th, width: '25%' }}><Text>Task</Text></View>
                                                            <View style={{ ...Styles.th, width: '25%' }}><Text>Assigned to</Text></View>
                                                            <View style={{ ...Styles.th, width: '25%' }}><Text>Target date</Text></View>
                                                            <View style={{ ...Styles.th, width: '25%' }}><Text>Task status</Text></View>
                                                        </View>
                                                    </View>
                                                    {
                                                        userTasks.filter(item => item.actionId === action.id).map(task => {
                                                            let currentUser = userProfiles.find(item => item.id === task.userId);
                                                            let currentUserName = currentUser ? (currentUser.forename + ' ' + currentUser.surname) : '';

                                                            return (
                                                                <View key={task.id} style={Styles.tr}>
                                                                    <View style={{ ...Styles.td, width: '25%' }}>
                                                                        <Text>{replacePlaceholders(task.tasks, placeholderReplacementMode)}</Text>
                                                                    </View>
                                                                    <View style={{ ...Styles.td, width: '25%' }}>
                                                                        <Text> {currentUserName} </Text>
                                                                    </View>
                                                                    {/*Looking at EditActionUserTask.tsx, the below field is used for target date*/}
                                                                    <View style={{ ...Styles.td, width: '25%' }}> 
                                                                        <DateLabelPdf format="DD/MM/YYYY" value={task.actionAddedDate} />
                                                                    </View>
                                                                    <View style={{ ...Styles.td, width: '25%' }}>
                                                                        <Text>{task.actionState}</Text>
                                                                    </View>
                                                                </View>
                                                            );
                                                        })
                                                    }
                                                </View>
                                            </ConditionalFragment>

                                            <ConditionalFragment showIf={!!question}>
                                                <View style={{
                                                    paddingBottom: 2,
                                                    paddingLeft: 4,
                                                    paddingRight: 4,
                                                    borderBottomWidth: 1,
                                                    borderBottomStyle: 'solid',
                                                }}>
                                                    <View style={{ ...Styles.h2, fontSize: 10, paddingTop: 2  }}>
                                                        <Text>Action relates to question:</Text>
                                                    </View>
                                                    <View>
                                                        <Text style={{ fontSize: 10, paddingTop: 2 }}>
                                                            {replacePlaceholders(question?.name ?? '', placeholderReplacementMode)}
                                                            <ConditionalFragment showIf={!!question?.questionNumber}>
                                                                <> </>
                                                                [{question?.questionNumber}]
                                                            </ConditionalFragment>
                                                        </Text>
                                                    </View>
                                                </View>
                                            </ConditionalFragment>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                </View>
            </Page>
        </Document>
        )
}