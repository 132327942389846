import * as React from 'react';
import { Button, Table } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ListContainer, ListUiProps } from './ListContainer';
import { InfiniteScrollingTable } from '../../shared/infiniteScrolling/InfiniteScrollingTable';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';
import { DateLabel } from '../../shared/DateLabel';

export const ListUi = (props: ListUiProps) => {
    const loadPage = React.useCallback((page: number) => {
        props.loadPage(page, props.search);
    }, [props.loadPage, props.search]);

    return (
        <div className="main-container">
            <AlertOnErrors errors={props.loadingErrors} />

            <div className="toolbar-top">
                <LinkContainer to={`/administration/videoTags/add`}>
                    <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add</span></Button>
                </LinkContainer>
            </div>

            <div className="search-bar-container">
                <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
            </div>

            <div className="table-responsive">
                <Table striped hover className="table-sm">
                    <thead>
                        <tr>
                            <th><span className="d-inline">Date</span></th>

                            <th><span className="d-inline">Consultant</span></th>
                            <th><span className="d-inline">Video</span></th>

                            <th><span className="d-inline">User</span></th>
                            <th><span className="d-inline">School</span></th>
                            <th><span className="d-inline">Subscription</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.model && !props.model.length ? (
                                <tr className="nothing-here">
                                    <td colSpan={6}>
                                        {
                                            props.search ? (
                                                <div>
                                                    <FontAwesomeIcon icon="search-minus" />
                                                    Sorry there are no consultant referrals matching "{props.search}".
                                                </div>
                                            ) : (
                                                    <div>
                                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                        Sorry there are no consultant referrals yet.<br />
                                                </div>
                                                )
                                        }
                                    </td>
                                </tr>
                            ) : null
                        }

                        <InfiniteScrollingTable key={props.search} loadData={loadPage} nextPage={props.nextPage}>
                            {(props.model || []).map((item, index) => (
                                <tr key={index}>
                                    <td><span className="d-inline"><DateLabel format="DD/MM/YYYY HH:mm" value={item.model.eventDate} /></span></td>

                                    <td><span className="d-inline">{`${item.consultant.forename} ${item.consultant.surname} (${item.consultant.email})`}</span></td>
                                    <td><span className="d-inline">{item.video && item.video.name || ''}</span></td>

                                    <td><span className="d-inline">{item.user && `${item.user.forename} ${item.user.surname} (${item.user.email})` || ''}</span></td>
                                    <td><span className="d-inline">{item.school && item.school.name || ''}</span></td>
                                    <td><span className="d-inline">{item.subscription && item.subscription.companyName || ''}</span></td>
                                </tr>
                            ))}
                        </InfiniteScrollingTable>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const List = withContainer(ListContainer)(ListUi);
