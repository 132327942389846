// Colours to use on charts to give a larger colour pallet before repeating the colours.
export const chartLargeColorPallet = [
    // Brand colours
    '#9D5097', '#FD5000', '#E5007E', '#1A1C35',

    // General colours
    '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0',
    '#4caf50', '#3f51b5', '#FF9800',
    '#546E7A', '#d4526e', '#A5978B',
    '#4ecdc4', '#c7f464',
    '#f9a3a4', '#69d2e7',
    '#F86624', '#EA3546', '#C5D86D',
    '#D7263D', '#1B998B', '#2E294E', 
    '#662E9B', '#f9ce1d',
    '#8D5B4C'
];

// Need a special colour pallet that swaps the first and second colours for when we show the spidergraphs for trust projects
// so the trust targets (which are result 0 in that spider graph) still show as the consistant colour they will show as when looking
// at individual schools (where it is result 1).
export const chartLargeColorPallet_SpecialForTrustTargetSpidergraph = [
    chartLargeColorPallet[1],
    chartLargeColorPallet[0],
    ...chartLargeColorPallet.slice(2),
];