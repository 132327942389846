import * as React from 'react';
import { Button, Table, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { Link } from 'react-router-dom';
import { ListContainer, ListUiProps } from './ListContainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { InfiniteScrollingTable } from '../../shared/infiniteScrolling/InfiniteScrollingTable';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';

export const ListUi = (props: ListUiProps) => {
    const navigation = useUniversalNavigation(props);
    const loadPage = React.useCallback((page: number) => {
        props.loadPage(page, props.search);
    }, [props.loadPage, props.search]);

    const [menuIsOpen, setMenuIsOpen] = React.useState<{ [id: string]: boolean }>({});
    const toggleMenuOpen = React.useCallback((id: string) => {
        setMenuIsOpen(prevState => ({
            ...prevState,
            [id]: (prevState[id] ? false : true)
        }));
    }, [setMenuIsOpen]);

    // Open the default action when the user clicks on a row.
    const onRowDoubleClick = React.useCallback((id: string, event: React.MouseEvent<HTMLTableRowElement>) => {
        if (id != "") {
            navigation.push(`/administration/tags/edit/${encodeURIComponent(id)}`);
        }
    }, [navigation]);

    return (
        <div className="main-container">
            <AlertOnErrors errors={props.loadingErrors} />

            <div className="toolbar-top">
                <LinkContainer to={`/administration/tags/add`}>
                    <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add</span></Button>
                </LinkContainer>
            </div>

            <div className="search-bar-container">
                <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
            </div>

            <div className="table-responsive">
                <Table striped hover className="table-sm">
                    <thead>
                        <tr>
                            <th><span className="d-inline">Name</span></th>
                            <th><span className="d-inline">Tag type</span></th>
                            <th><span>&nbsp;</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.model && !props.model.length ? (
                                <tr className="nothing-here">
                                    <td colSpan={3}>
                                        {
                                            props.search ? (
                                                <div>
                                                    <FontAwesomeIcon icon="search-minus" />
                                                    Sorry there are no tags matching "{props.search}".
                                                </div>
                                            ) : (
                                                    <div>
                                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                        You haven't added any tags yet.<br />
                                                        You can <Link to="/administration/tags/add">add one</Link> to get started.
                                                </div>
                                                )
                                        }
                                    </td>
                                </tr>
                            ) : null
                        }

                        <InfiniteScrollingTable key={props.search} loadData={loadPage} nextPage={props.nextPage}>
                            {(props.model || []).map((item, index) => (
                                <tr key={index} onDoubleClick={e => onRowDoubleClick(item.id, e)}>
                                    <td><span className="d-inline">{item.name}</span></td>
                                    <td><span className="d-inline">{item.isSchoolType? 'School type': 'School phase'}</span></td>
                                    <td className="actions">
                                        <ButtonGroup>
                                            <LinkContainer to={`/administration/tags/edit/${encodeURIComponent(item.id)}`}>
                                                <Button color="primary" outline>Edit</Button>
                                            </LinkContainer>
                                            <ButtonDropdown isOpen={menuIsOpen[item.id]} toggle={() => toggleMenuOpen(item.id)}>
                                                <DropdownToggle caret color="primary" outline />
                                                <DropdownMenu right>
                                                    <LinkContainer to={`/administration/tags/delete/${encodeURIComponent(item.id)}`}>
                                                        <DropdownItem className="text-danger">
                                                            <FontAwesomeIcon icon="trash-alt" /> <span>Delete</span>
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </InfiniteScrollingTable>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const List = withContainer(ListContainer)(ListUi);
