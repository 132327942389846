import * as React from 'react';
import { Row, Col, Container, Button, Badge, Collapse, Input } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useApiService } from '../../api/useApiService';
import { Action } from '../../api/models/Action';
import { useManagedChildModels } from '../shared/hooks/useManagedChildModels';
import { UserProfile } from '../../api/models/UserProfile';
import { useAsyncCallback } from 'react-use-async-callback';
import { Trust } from '../../api/models/Trust';
import { School } from '../../api/models/School';
import moment, { Moment } from 'moment';
import { actionStates } from '../../services/actionStates/actionStates';
import { ActionCard } from './ActionCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { EditActionModal } from './EditActionModal';
import { useToggleStateArray } from '../shared/hooks/useToggleState/useToggleStateArray';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { Guid } from 'guid-string';
import { Question } from '../../api/models/Question';
import { AwardTag } from '../../api/models/AwardTag';
import { QuestionTag } from '../../api/models/QuestionTag';
import { isProductTierUnlocked, ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { ProductTierNotUnlockedAlert } from '../shared/productTierNotUnlocked/ProductTierNotUnlockedAlert';
import { actionPriorities, actionPriorityDisplayName, ActionPriority } from '../../api/models/codeOnly/ActionPriority';
import FileSaver from 'file-saver';
import { generatePdfAsBlob } from '../../utilities/generatePdfAsBlob';
import { ImprovementPlannerPdf } from './Pdf Export/ImprovementPlannerPdf';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../utilities/replacePlaceholders';

export interface ActionsProps {
    subscriptionId: string,
    ignoreActionsForSchools?: boolean,
    ignoreActionsForTrust?: boolean,
    title?: string,
    actionId: string,
    projectId?: string,

    userId?: string,
}

/**
 * Actions for a school.
 * @param props
 */
export const _Actions = (props: ActionsProps) => {
    const navigation = useUniversalNavigation(props);
    const {
        subscriptionId,
        ignoreActionsForSchools, ignoreActionsForTrust,
        title = 'Improvement planner',
        actionId = navigation.getParam('actionId', ''),
        projectId,
    } = props;

    const schoolId = navigation.getParam('schoolId', '');
    const awardTagId = navigation.getParam('awardTagId', '');

    const scrollToActionId = actionId;
    const scrollToActionIdRef = React.useRef<HTMLDivElement>(null);

    const [lastScrolledToTopForId, setLastScrolledToTopForId] = React.useState<string>('');

    const api = useApiService();
    const getTrustFromSubscription = api.trusts.viewModels.getTrustFromSubscription();
    const actionRepository = api.actions.repository();
    const actionUserTasksRepository = api.actionUserTasks.repository();
    const loadViewModel = api.actions.viewModels.edit();

    const actionsManager = useManagedChildModels(actionRepository);
    const actionUserTasksManager = useManagedChildModels(actionUserTasksRepository);
    const [trust, setTrust] = React.useState<Trust | undefined>();
    const [schools, setSchools] = React.useState<Array<School>>([]);
    const [userProfiles, setUserProfiles] = React.useState<Array<UserProfile>>([]);
    const [questionTags, setQuestionTags] = React.useState<Array<QuestionTag>>([]);
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);

    const questionsRepository = api.questions.repository();
    const questionsManager = useManagedChildModels(questionsRepository);
    const questionSetsRepository = api.questionSets.repository();
    const questionSetsManager = useManagedChildModels(questionSetsRepository);

    const topicsRepository = api.topics.repository();
    const projectSectionsRepository = api.projectSections.repository();
    const topics = useManagedChildModels(topicsRepository);
    const prioritySections = useManagedChildModels(projectSectionsRepository);
    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const resultTrust = await getTrustFromSubscription(subscriptionId);
        setTrust(resultTrust);

        const result = await loadViewModel(resultTrust.id, schoolId);
        setSchools(result.schools);
        setUserProfiles(result.userProfiles);
        actionUserTasksManager.setModels(result.actionUserTasks);
        actionsManager.setModels(result.model);
        questionsManager.setModels(result.questions);
        questionSetsManager.setModels(result.questionSets);
        prioritySections.setModels(result.prioritySections);
        topics.setModels(result.topics)
        setQuestionTags(result.questionTags);
        setPlaceholderReplacementMode(result.placeholderReplacementMode);
        setAwardTags(result.awardTags);
    }, [loadViewModel, subscriptionId, schoolId, actionsManager, actionUserTasksManager, setSchools, setUserProfiles, questionsManager, questionSetsManager, prioritySections, topics, setQuestionTags,
        setAwardTags, setPlaceholderReplacementMode]);

    // Load if we need to.
    React.useEffect(() => {
        if (!subscriptionId || isLoading || (trust && trust.subscriptionId == subscriptionId)) {
            return;
        }

        load();
    }, [trust]);

    const [filterBySchoolId, setFilterBySchoolId] = React.useState<string>('');
    const [filterByTagId, setFilterByTagId] = React.useState<string>('');


    // see if the section is a piority section
    const isPriority = (topicId: string, projectId: string) => {
        let linkedTopic = topics.models.find(it => it.id == topicId);
        let prioritySection = !!linkedTopic ? prioritySections.models.find(it => it.projectId == projectId && !!linkedTopic && it.sectionId == linkedTopic.sectionId) : undefined;
        return !!prioritySection ? true : false;
    };

    // Get a list of actions we should be showing.
    const actions = actionsManager.models;

    // Allow filtering/searching of actions by text.
    //const { search: searchParam } = ''; //useSearchParams();
    const search = '';//const [search, setSearch] = React.useState<string>(searchParam || '');
    // Filter by the user's search client side so it can work when offline as well as online.
    const filteredActions = React.useMemo(() => {
        let ret = actions;

        if (ignoreActionsForSchools) {
            ret = ret.filter(item => Guid.isEmpty(item.schoolId));
        }

        if (ignoreActionsForTrust) {
            ret = ret.filter(item => !Guid.isEmpty(item.schoolId));
        }

        if (filterBySchoolId) {
            ret = ret.filter(item => item.schoolId === filterBySchoolId);
        }

        if (filterByTagId) {
            ret = ret.filter(item => {
                //// Special behaviour when filtering by  a priority.
                //if (filterByTagId === 'priority') {
                //    if (!item.questionId) {
                //        return false;
                //    }

                //    const thisQuestion = questionsManager.models.find(it => it.id === item.questionId);
                //    if (!thisQuestion) {
                //        return false;
                //    }

                //    let thisIsPriority = isPriority(!!thisQuestion ? thisQuestion.topicId : '', !!item.projectId ? item.projectId : '');
                //    if (thisIsPriority) {
                //        return true;
                //    }

                //    return false;
                //}

                // Special behaviour when filtering by SIP recommendation.
                if (filterByTagId === 'recommendation') {
                    if (item.riskId) {
                        return true;
                    } else {
                        return false;
                    }
                }

                // Special behaviour when filtering by users own actions.
                if (filterByTagId === 'myActions') {
                    const myTasks = actionUserTasksManager.models
                        .filter(it => it.actionId === item.id && it.userId === props.userId);

                    if (!!myTasks.length) {
                        return true;
                    } else {
                        return false;
                    }
                }

                // Filter by action priority
                if (filterByTagId === ActionPriority.Urgent) {
                    return item.priority === ActionPriority.Urgent;
                }

                if (filterByTagId === ActionPriority.High) {
                    return item.priority === ActionPriority.High;
                }

                if (filterByTagId === ActionPriority.Medium) {
                    return item.priority === ActionPriority.Medium;
                }

                if (filterByTagId === ActionPriority.Low) {
                    return item.priority === ActionPriority.Low;
                }

                // Filter by award tags.
                //
                if (!item.questionId) {
                    return false;
                }

                const myTags = questionTags.filter(it => it.questionId === item.questionId && it.tagId.toLowerCase() === filterByTagId.toLowerCase());
                if (myTags.length) {
                    return true;
                }

                // If we get here this isn't a match to the filter.
                return false;
            })
        }

        return ret;
        //if (!actions || !search) {
        //    return actions;
        //}

        //let lowerSearch = search.toLocaleLowerCase();

        //// Filter the items being displayed.
        //return actions.filter(item =>
        //    item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        //    || item.description.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        //);
    }, [actions, search, ignoreActionsForSchools, ignoreActionsForTrust, filterBySchoolId, filterByTagId, questionsManager, isPriority, props.userId]);

    //useReplaceSearchParamsEffect({ search: search });

    // Let an action be opened as a modal.
    const [openActionId, setOpenActionId] = React.useState<string>('');

    // Add an action.
    const addAction = React.useCallback(() => {
        (async () => {
            const newAction = await actionsManager.addModel({
                trustId: trust && trust.id || '',
                schoolId: schoolId || filterBySchoolId || (ignoreActionsForTrust && schools.length ? schools[0].id : ''),
                name: '',
                description: '',
                archived: false,
                projectId: null,
                questionId: null,
                isAutomaticFromProject: false,
                actionAddedDate: moment().add(1, 'month').toISOString(),
                actionState: actionStates.outstanding.id,
                completedDate: null,
                notes: '',
                priority: ActionPriority.Medium,
            });
            setOpenActionId(newAction.id);
        })();
    }, [actionsManager, schoolId, setOpenActionId, filterBySchoolId, ignoreActionsForTrust, schools]);

    // Add a task to an action.
    const addActionUserTask = React.useCallback((actionId: string) => {
        actionUserTasksManager.addModel({
            actionId: actionId,
            userId: '',
            tasks: '',
            actionAddedDate: moment().toISOString(),
            actionState: actionStates.outstanding.id,
            completedDate: null,
            notes: '',
            archived: false,
        });
    }, [actionUserTasksManager]);


    // get questiontext for the action card - either 'due diligence' or ''
    const linkedQuestionSetText = (linkedQuestion: Question) => {
        let linkedQuestionSetId = linkedQuestion.questionSetId;
        let linkedQuestionSet = questionSetsManager.models && questionSetsManager.models.find(it => it.id === linkedQuestionSetId);
        return !!linkedQuestionSet && linkedQuestionSet.name.indexOf('due diligence') > 0 ? 'due diligence' : '';
    };

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options: { dontNavigateBack?: boolean } = {}) => {
        // Save all the actions.
        await actionsManager.save();

        // Save all the tasks
        actionUserTasksManager.save();
    }, [actionsManager, actionUserTasksManager]);

    // Save everything with some debounce support so we don't try and save after change if the user is making changes quickly.
    const [needToSave, setNeedToSave] = React.useState<boolean>(false);
    const saveFormDebounceTimerId = React.useRef<any>();
    const saveFormDebounce = () => {
        let delay = 400;
        if (saveFormDebounceTimerId.current) {
            clearTimeout(saveFormDebounceTimerId.current);
        }

        saveFormDebounceTimerId.current = setTimeout(() => {
            setNeedToSave(true);
        }, delay);
    };

    React.useEffect(() => {
        if (needToSave) {
            saveForm();
            setNeedToSave(false);
        }
    }, [saveForm, needToSave]);

    // Work out where to show everything.
    const groups = React.useMemo(() => {
        const ret = filteredActions.map(item => {
            const dateMoment: Moment = moment(item.actionAddedDate);
            const differenceDays = dateMoment.diff(moment(), 'days');
                        
            let group = 'scheduled';
            if (actionStates.findById(item.actionState).isCompleted) {
                const completedMoment: Moment = moment(item.completedDate || '');
                const completedDifferenceDays = moment().diff(completedMoment, 'days');

                if (completedDifferenceDays <= 30) {
                    group = 'completed-30';
                } else if (completedDifferenceDays <= 90) {
                    group = 'completed-90';
                } else if (completedDifferenceDays <= 365) {
                    group = 'completed-365';
                } else {
                    group = 'completed-earlier';
                }
            } else if (differenceDays <= 0) {
                group = 'due-0';
            } else if (differenceDays <= 30) {
                group = 'due-30';
            } else if (differenceDays <= 90) {
                group = 'due-90';
            } else if (differenceDays <= 183 /* six months */) {
                group = 'scheduled-183';
            } else if (differenceDays <= 365) {
                group = 'scheduled-365';
            } else {
                group = 'scheduled-later';
            }

            return { group, item };
        });
         
        return ret || [];
    }, [filteredActions]);

    // Create the action card component.
    const createActionCard = (item: Action) => {
        const myTasks = actionUserTasksManager.models.filter(it => it.actionId === item.id);
        let thisQuestion = questionsManager.models ? questionsManager.models.find(it => it.id === item.questionId) : undefined
        let thisQuestionSetText = !!thisQuestion ? linkedQuestionSetText(thisQuestion) : '';
        let thisIsPriority = isPriority(!!thisQuestion ? thisQuestion.topicId : '', !!item.projectId ? item.projectId : '');
        let isRecommendation = !!item.riskId && !thisQuestionSetText ? true : false;
        const thisQuestionTags = questionTags.filter(it => !!thisQuestion && it.questionId === thisQuestion.id);

        //const myRequirement = requirements?.find(req => req.originKey === item.requirementOriginKey);
        return (
            <React.Fragment key={item.id}>
                <div key={item.id} ref={scrollToActionId === item.id ? scrollToActionIdRef : null} className={scrollToActionId === item.id ? 'auto-scroll-selected' : ''}> {/* we use this div with a ref to help us scroll to the question when we're directing to a single action when following links from the "View on improvement" buttons. */}
                    <ActionCard
                        model={item} change={changes => actionsManager.change(item.id, changes)}
                        validate={() => true} validationErrors={{}}
                        remove={() => actionsManager.removeModel(item.id)}
                        tasks={myTasks} changeTask={(id, changes) => actionUserTasksManager.change(id, changes)}
                        addTask={() => addActionUserTask(item.id)}
                        removeTask={id => actionUserTasksManager.removeModel(id)}
                        trust={trust} schools={schools} schoolId={schoolId}
                        userProfiles={userProfiles}
                        saveDebounce={saveFormDebounce}
                        openModal={() => setOpenActionId(item.id)}
                        questionText={!!thisQuestion ? thisQuestion.name : ''}
                        questionLabelText={thisQuestionSetText}
                        questionNumber={!!thisQuestion && thisQuestion.questionNumber}
                        isRecommendation={isRecommendation}
                        isPriority={thisIsPriority}
                        awardTagId={awardTagId}
                        awardTags={awardTags}
                        questionTags={thisQuestionTags}
                        placeholderReplacementMode={placeholderReplacementMode}
                    />
                    </div>
            </React.Fragment>
        );
    };

    const createModal = (actionId: string) => {
        let thisAction = actions.find(it => it.id === actionId);
        let thisQuestion = questionsManager.models ? questionsManager.models.find(it => it.id === (!!thisAction ? thisAction.questionId : '')) : undefined
        let thisQuestionSetText = !!thisQuestion ? linkedQuestionSetText(thisQuestion) : '';
        let thisIsPriority = isPriority(!!thisQuestion ? thisQuestion.topicId : '', !!thisAction && !!thisAction.projectId ? thisAction.projectId : '');
        let isRecommendation = !!thisAction && !!thisAction.riskId && !thisQuestionSetText ? true : false;
        const thisQuestionTags = questionTags.filter(it => !!thisQuestion && it.questionId === thisQuestion.id);

        return (
            <EditActionModal isOpen={true} toggle={() => { saveFormDebounce(); setOpenActionId(''); }}
                model={actions.find(it => it.id === openActionId) || ({} as Action)}
                change={changes => actionsManager.change(openActionId, changes)}
                validate={() => true} validationErrors={{}}
                remove={() => actionsManager.removeModel(openActionId)}
                tasks={actionUserTasksManager.models.filter(it => it.actionId === openActionId)}
                changeTask={(id, changes) => actionUserTasksManager.change(id, changes)}
                addTask={() => addActionUserTask(openActionId)}
                removeTask={id => actionUserTasksManager.removeModel(id)}
                trust={trust} schools={schools} schoolId={schoolId}
                userProfiles={userProfiles}
                saveDebounce={saveFormDebounce}
                showSchoolSelector={!schoolId && !ignoreActionsForSchools}
                questionText={!!thisQuestion ? replacePlaceholders(thisQuestion.name, placeholderReplacementMode) : ''}
                questionLabelText={thisQuestionSetText}
                questionNumber={!!thisQuestion && thisQuestion.questionNumber}
                isRecommendation={isRecommendation}
                isPriority={thisIsPriority}
                awardTagId={awardTagId}
                awardTags={awardTags}
                questionTags={thisQuestionTags}
            />
        )
    }



    React.useEffect(() => {
        if (actionId && actionId || '' !== lastScrolledToTopForId) {
            if (scrollToActionIdRef.current) {
                window.scrollTo(0, (scrollToActionIdRef.current.offsetTop + (scrollToActionIdRef.current.offsetParent && (scrollToActionIdRef.current.offsetParent as any).offsetTop ? (scrollToActionIdRef.current.offsetParent as any).offsetTop : 0)) - 60 /* NOTE have to add this for padding of the top bar, but would prefer to handle another way. */)
            } else {
                window.scrollTo(0, 0);
            }
            setLastScrolledToTopForId(`${props.actionId || ''}`);
        }

    }, [createActionCard]);

    // Create a seciton showing a group of action cards with the middle column fully expanded by default, and the top of the other two sections expanded too.
    const [isSectionOpen, _toggleSectionOpen] = useToggleStateArray([
        'due-0', 'due-30', 'due-90',
        'scheduled-183', 'completed-30'
    ]);
    const toggleSectionOpen = React.useCallback((key: string, value?: React.SetStateAction<boolean>) => {
        _toggleSectionOpen(key, value);
    }, [_toggleSectionOpen]);

    const createSection = (heading: string, group: string) => {
        const myActions = groups.filter(item => item.group === group).map(item => item.item);

        // If there is nothing to show in this section hide the section.
        if (!myActions.length) {
            return null;
        }

        // We can toggle sections open and closed manually (some default open, some default closed), 
        // or we auto expand everything when we have a search taking place,
        // or we auto expand the group containing the item we are scrolling to.
        const isOpen = isSectionOpen(group) || !!search || !!myActions.find(item => item.id === scrollToActionId);

        return (
            <>
                <h6 className="actions-column-subheading" onClick={() => toggleSectionOpen(group)}>
                    {heading}
                    <> </>
                    <Badge pill color="dark">
                        {myActions.length}
                    </Badge>
                    <> </>
                    <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                </h6>
                <Collapse isOpen={isOpen}>
                    <ConditionalFragment showIf={isOpen}>
                        {
                            myActions.map(item => createActionCard(item))
                        }
                    </ConditionalFragment>
                </Collapse>
            </>
        );
    };

    // If we are for a particular school, get that school now so its easy to find.
    const school = React.useMemo(() => {
        if (!schoolId) {
            return null;
        }

        const ret = schools.find(it => it.id === schoolId);
        return ret;
    }, [schoolId, schools]);

    const pdfFileName = React.useMemo(() => `Improvement planner - ${school && school.name || 'School'} - ${moment().format('YYYY-MM-DD HHmm')}.pdf`, [school]);
    // Generate a PDF file as a blob
    const generatePdf = React.useCallback(async (): Promise<Blob> => {
        let blob = await generatePdfAsBlob(ImprovementPlannerPdf({
            actions: filteredActions,
            schoolName: school?.name,
            trustName: trust?.name,
            userTasks: actionUserTasksManager.models,
            userProfiles: userProfiles,
            placeholderReplacementMode: placeholderReplacementMode,
            questions: questionsManager.models,
        }))
        return blob;
    }, [generatePdfAsBlob, ImprovementPlannerPdf, filteredActions, school, trust, actionUserTasksManager.models, userProfiles, questionsManager]);

    const [generateAndDownloadPdf, { isExecuting: isGeneratingPdfForDownload }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        FileSaver.saveAs(blob, pdfFileName);
    }, [generatePdf, pdfFileName]);

    if (!trust || isLoading) {
        return <LoadingIndicator />
    }

    // If this is a school's screen and the school has not reached the right tier, hide the functionality.
    if (school && !school.isPotentialSchool && !isProductTierUnlocked(ProductTier.Snapshot, school.tier as ProductTier)) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>{title}</h1>
                </div>

                <ProductTierNotUnlockedAlert tier={ProductTier.Snapshot} />
            </div>
            );
    }

    return (
        <div className="main-container actions">
                <div className="main-heading">
                    <h1>{title}</h1>
                </div>

            <div className="toolbar-top">
                <Row>
                    <ConditionalFragment showIf={!ignoreActionsForSchools && !schoolId}>
                        <Col xs="auto">
                            <Input className="mb-1" type="select" value={filterBySchoolId} onChange={e => setFilterBySchoolId(e.currentTarget.value)}>
                                <option value="">(All schools within {trust.name})</option>
                                {
                                    schools.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!ignoreActionsForSchools}>
                        <Col xs="auto">
                            <Input className="mb-1" type="select" value={filterByTagId} onChange={e => setFilterByTagId(e.currentTarget.value)}>
                                <option value="">(All actions)</option>
                                <option value="myActions">My actions</option>
                                {/*<option value="priority">Priority</option>*/}
                                {
                                    actionPriorities.map(item => (
                                        <option key={item} value={item}>{actionPriorityDisplayName(item)}</option>
                                    ))
                                }
                                <option value="recommendation">Possible areas for development</option>
                                {
                                    awardTags.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </ConditionalFragment>

                    <Col xs="auto">
                        <Button color="primary" onClick={addAction}>
                            <FontAwesomeIcon icon="plus" />
                            <> </>Add action
                        </Button>
                        <Button color="primary" onClick={generateAndDownloadPdf}>
                            <FontAwesomeIcon icon="upload" />
                            <> </> Export
                        </Button>
                    </Col>
                </Row>
            </div>

            <Container fluid className="mt-2">
                <AlertOnErrors errors={[loadingErrors, saveFormErrors]} />

                <Row>
                    <Col>
                        <h5 className="actions-column-heading">
                            Scheduled for the future
                            <> </>
                            <Badge color="dark" pill>
                                {groups.filter(item => item.group.indexOf('scheduled') === 0).length || 0}
                            </Badge>
                        </h5>

                        {createSection('Due in the next six months', 'scheduled-183')}
                        {createSection('Due in the next year', 'scheduled-365')}
                        {createSection('Due later', 'scheduled-later')}
                            
                        <ConditionalFragment showIf={!isLoading && !groups.filter(item => item.group.indexOf('scheduled') === 0).length}>
                            <div className="nothing-here">
                                {
                                        !!search ? (
                                            <div>
                                                <FontAwesomeIcon icon="search-minus" />
                                                Sorry there are no results matching "{search}".
                                            </div>
                                        ) : (
                                                <div>
                                                    <div className="no-results-found-icon">
                                                        <FontAwesomeIcon icon={['far', 'smile']} />
                                                    </div>
                                                <p>You don't have any outstanding actions scheduled for the future.</p>
                                                </div>
                                                )
                                }
                            </div>
                        </ConditionalFragment>
                    </Col>
                    <Col>
                        <h5 className="actions-column-heading">
                            Outstanding
                            <> </>
                            <Badge color="dark" pill>
                                {groups.filter(item => item.group.indexOf('due') === 0).length || 0}
                            </Badge>
                        </h5>

                        {createSection('Overdue', 'due-0')}
                        {createSection('Due in the next 30 days', 'due-30')}
                        {createSection('Due in the next 90 days', 'due-90')}

                        <ConditionalFragment showIf={!isLoading && !groups.filter(item => item.group.indexOf('due') === 0).length}>
                            <div className="nothing-here">
                                {
                                    !!search ? (
                                        <div>
                                            <FontAwesomeIcon icon="search-minus" />
                                            Sorry there are no results matching "{search}".
                                        </div>
                                    ) : (
                                            <div>
                                                <div className="no-results-found-icon">
                                                    <FontAwesomeIcon icon={['far', 'smile']} />
                                                </div>
                                                <p>You don't have any outstanding actions due in the next 90 days.</p>
                                            </div>
                                            )
                                }
                            </div>
                        </ConditionalFragment>
                    </Col>
                    <Col>
                        <h5 className="actions-column-heading">
                            Complete
                            <> </>
                            <Badge color="dark" pill>
                                {groups.filter(item => item.group.indexOf('completed') === 0).length || 0 }
                            </Badge>
                        </h5>

                        {createSection('Completed in the last 30 days', 'completed-30')}
                        {createSection('Completed in the last 90 days', 'completed-90')}
                        {createSection('Completed in the last year', 'completed-365')}
                        {createSection('Completed earlier', 'completed-earlier')}

                        <ConditionalFragment showIf={!isLoading && !groups.filter(item => item.group.indexOf('completed') === 0).length}>
                             <div className="nothing-here">
                                {
                                    !!search ? (
                                        <div>
                                            <FontAwesomeIcon icon="search-minus" />
                                            Sorry there are no results matching "{search}".
                                        </div>
                                    ) : (
                                            <div>
                                                <div className="no-results-found-icon">
                                                    <FontAwesomeIcon icon={['far', 'frown']} />
                                                </div>
                                                <p>You don't have any completed actions.</p>
                                            </div>
                                            )
                                }
                            </div>
                        </ConditionalFragment>
                    </Col>
                </Row>
            </Container>

            <ConditionalFragment showIf={!!openActionId}>
                {createModal(openActionId)}
            </ConditionalFragment>
        </div>
    );
};

export const Actions = connect(
    (state: AppState) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : '',
        userId: state.user.identity && state.user.identity.userId || '',
    })
)(_Actions);