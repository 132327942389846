import * as React from 'react';
import { StyleSheet, Font } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
    h1: {
        flexGrow: 1,
        fontSize: 20,
        width: '100%',
    },
    h1Small: {
        fontSize: 12,
        color: 'gray',
        borderBottomColor: 'lightgray',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        marginBottom: 8,
    },
    page: {

    },
    p: {
        marginTop: 4,
        marginBottom: 4,
    },
    body: {
        flexGrow: 1,
        fontSize: 11,
        marginTop: 30,
        marginBottom: 40,
        marginLeft: 40,
        marginRight: 40,
    },
    table: {
        width: '100%',
        marginBottom: 25,
    },
    innerTable: {
        width: '100%',
    },
    thead: {
        width: '100%',

        backgroundColor: '#1c599c',
        color: 'white',
        borderTopStyle: 'solid',
        borderTopWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,

        borderRightStyle: 'solid',
        borderRightWidth: 2,
    },
    innerThead: {
        width: '100%',

        backgroundColor: '#808080',
        color: 'white',
        
    },
    tbody: {
        width: '100%',
    },
    trHead: {
        width: '100%',
        flexDirection: 'row',
    },
    tr: {
        width: '100%',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
    },
    td: {
        flexDirection: 'column',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
    th: {
        flexDirection: 'column',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
    row: {
        flexGrow: 1,
        flexDirection: 'row',
        width: '100%'
    },
});