import * as React from 'react';
import { Card, Table, CardHeader, CardBody, Button } from 'reactstrap';
import { isNullOrUndefined } from 'util';
import { Link } from 'react-router-dom';
import { dashboardDrilldownUrl } from '../../../../utilities/dashboardDrilldownUrl';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Project } from '../../../../api/models/Project';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { Guid } from 'guid-string';
import { SchoolTerm } from '../../../../api/models/SchoolTerm';
import { getGradeComparisonColor } from '../../../../utilities/getGradeComparisonColor';
import { getGradeString } from '../../../../utilities/getGradeString';
import { AwardTag } from '../../../../api/models/AwardTag';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';

export interface TimelineComparisonTableProps {
    model: Project | undefined,
    timelineResults: Array<ProjectAnalysisResult>,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    schoolTerms: Array<SchoolTerm>,
    awardTag: AwardTag | undefined,
    placeholderReplacementMode: PlaceholderReplacementMode,
}

export const TimelineComparisonTable = (props: TimelineComparisonTableProps) => {
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTag && props.awardTag.id || undefined);

    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index as well.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    const maxTermsToShow = 3; /* One academic year */
    const results = React.useMemo(() => {
        if (!props.timelineResults || !props.schoolTerms) {
            return [];
        }

        // Work out the term to fit each project in.
        let ret = props.timelineResults.map((item, index) => {
            var term = props.schoolTerms.find(it => it.startDate <= (item.project && item.project.startDate || '') && it.endDate > (item.project && item.project.startDate || ''));
            if (term) {
                return { name: term.name, result: item };
            }

            return { name: item.project && item.project.startDate || `Project ${index}`, result: item };
        });

        // Slice only the last X months so we don't end up always scrolling right, but can show in left-to-right timeline order (which people more naturally expect/understand).
        if (ret.length > maxTermsToShow) {
            ret = ret.slice((ret.length - maxTermsToShow) - 1, maxTermsToShow);
        }

        return ret;
    }, [props.timelineResults, props.schoolTerms]);

    if (!results || !results.length) {
        return (<></>);
    }

    // Can navigate deeper from all levels except a question, which can only be navigated to if we're within a project for an individual school, not from the trust level.
    const canNavigateDeeper = !props.filterByTopicOriginKey || (props.model && !Guid.isEmpty(props.model.schoolId) || false);

    return (
        <div className="table-responsive">
            <Table striped className="table-sm">
                <thead>
                    <tr>
                        {
                            isForQuestions ? (
                                <th><span></span></th>
                                ): null
                        }
                        <th><span></span></th>
                        {
                            results.map((item, index) => (
                                <th key={index} className="text-right"><span>
                                    {item.name}
                                </span></th>
                                ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        results[results.length - 1].result.results.map(item => (
                            <tr key={item.originKey}>
                                {
                                    isForQuestions ? (
                                        <td><span>{item.questionNumber}</span></td>
                                    ) : null
                                }
                                <td><span>
                                    {
                                        canNavigateDeeper ? (
                                            <Link to={dashboardDrilldownUrl(projectSettings, props.filterBySectionOriginKey, props.filterByTopicOriginKey, item.originKey)}>
                                                {replacePlaceholders(item.name, props.placeholderReplacementMode)}
                                            </Link>
                                        ) : (
                                                <>{replacePlaceholders(item.name, props.placeholderReplacementMode)}</>
                                                )
                                    }
                                    
                                </span></td>
                                {
                                    results.map((resultItem, index) => {
                                        const matchingRecord = resultItem.result.results.find(it => it.originKey === item.originKey);
                                        const thisScore = matchingRecord ? matchingRecord.percentageQuestionScore : null;
                                        let previousScore: number | null = null;
                                        if (index > 0) {
                                            const previousRecord = results[index - 1].result.results.find(it => it.originKey === item.originKey);
                                            previousScore = previousRecord ? previousRecord.percentageQuestionScore : null;
                                        }

                                        const difference = isNullOrUndefined(thisScore) || isNullOrUndefined(previousScore) ? null : (thisScore - previousScore);

                                        return (
                                            <td key={index} className="text-right">
                                                <span className={isNullOrUndefined(difference) ? '' : `text-${getGradeComparisonColor(thisScore, previousScore)}`}>
                                                    {isNullOrUndefined(thisScore) ? '' : matchingRecord && matchingRecord.questionScore === 0 ? 'N/A' : getGradeString(thisScore)}
                                                </span>
                                            </td>
                                            );                   
                                    })
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    );
};

