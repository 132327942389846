import * as React from 'react';
import { OurTeamItem } from './EditContainer';
import { Row, Col, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface OurTeamUiProps {
    ourTeam: Array<OurTeamItem>,
    addTeamMember: (item: OurTeamItem) => void,
    removeTeamMember: (index: number) => void,
    updateTeamMemberDetails: (index: number, item: OurTeamItem) => void
}

export const OurTeam = (props: OurTeamUiProps) => {
    const onMemberChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, index: number, originalItem: OurTeamItem) => {
        let newItem = {
            ...originalItem,
            [event.target.name]: event.target.value
        };

        props.updateTeamMemberDetails(index, newItem);
    }, [props.ourTeam, props.updateTeamMemberDetails]);

    return (
        <>
            {
                props.ourTeam.map((item, index) => (
                    <Row style={{ marginBottom: 10 }}>
                        <Col xs={12} sm="">
                            <Label>Name:</Label>
                            <input value={item.name} className="form-control" type="text" name="name" autoComplete="off" onChange={(ev) => onMemberChanged(ev, index, item)} />
                        </Col>
                        <Col xs={12} sm="">
                            <Label>Job title:</Label>
                            <input value={item.jobTitle} className="form-control" type="text" name="jobTitle" onChange={(ev) => onMemberChanged(ev, index, item)} />
                        </Col>
                        <Col xs={12} sm="">
                            <Label>Expertise:</Label>
                            <input value={item.expertise} className="form-control" type="text" name="expertise" onChange={(ev) => onMemberChanged(ev, index, item)} />
                        </Col>
                        {
                            props.ourTeam.length > 1 ?
                                <Col xs="auto">
                                    <Button style={{ marginTop: 29 }} onClick={() => props.removeTeamMember(index)}>
                                        <FontAwesomeIcon icon="trash-alt" />
                                    </Button>
                                </Col>
                                : null
                        }

                    </Row>
                ))
            }
            <Row>
                <Col>
                    <Button style={{ marginTop: 10 }} onClick={(ev) => {
                        props.addTeamMember({
                            expertise: '',
                            jobTitle: '',
                            name: ''
                        });
                    }}>
                        <FontAwesomeIcon icon="plus" />
                    </Button>
                </Col>
            </Row>
        </>
    );
};