import * as React from 'react';
import { useApiService } from '../../api/useApiService';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Button, Col, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { useUniversalNavigation } from 'react-universal-navigation';
import { User } from '../../api/models/User';
import { AwardTag } from '../../api/models/AwardTag';
import { Subscription } from '../../api/models/Subscription';
import { SubscriptionLicense } from '../../api/models/SubscriptionLicense';
import { useNavigateWhenReady } from '../shared/hooks/useNavigateWhenReady';
import { ProgressWheel } from './progressWheel/ProgressWheel';
import { ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { SchoolTagCertificateProgress } from '../projects/awardTagSubmissions/SchoolTagCertificateProgress';
import { SchoolTagCertificate } from '../../api/models/SchoolTagCertificate';
import { AwardFeedbackAlert } from '../schoolReports/reportGenerator/awards/AwardFeedbackAlert';
import { useLoadProjectWithRetry } from '../subjectReviews/useLoadProjectWithRetry';
import { PlaceholderReplacementMode } from '../../utilities/replacePlaceholders';

export interface AwardTagOverviewProps {

}

/**
 * Overview of a AwardTag (specific review) within a school.
 * @param props
 */
export const AwardTagOverview = (props: AwardTagOverviewProps) => {
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');
    const projectIdParam = navigation.getParam('projectId', '');
    const awardTagId = navigation.getParam('awardTagId', '');

    const api = useApiService();
    const loadViewModel = api.schools.viewModels.overview();
    const currentProjectFor = api.projects.viewModels.currentProjectFor();
    const loadProgressViewModel = api.projects.viewModels.progress();
    const schoolRepository = api.schools.repository();
    const projectRepository = api.projects.repository();

    // Load the main model data.
    //
    const [model, setModel] = React.useState<School | undefined>();
    const [user, setUser] = React.useState<User | undefined>();
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [license, setLicense] = React.useState<SubscriptionLicense | undefined>();
    const [schoolTagCertificates, setSchoolTagCertificates] = React.useState<Array<SchoolTagCertificate>>([]);
    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(id);
        setUser(result.user);
        setAwardTags(result.awardTags);
        setSubscription(result.subscription);
        setLicense(result.license);
        setSchoolTagCertificates(result.schoolTagCertificates);
        setModel(result.model);
        setPlaceholderReplacementMode(result.placeholderReplacementMode);
    }, [loadViewModel, setModel, id, setUser, setAwardTags, setSubscription, setLicense, setSchoolTagCertificates, setPlaceholderReplacementMode]);
    React.useEffect(() => {
        if (!id || isLoading) {
            return;
        }

        if (model && model.id === id) {
            return;
        }

        load();
    }, [id, load, model, isLoading]);

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const {
        project,
        sections,
        topics,
        isLoadingProject,
        loadProjectErrors,
    } = useLoadProjectWithRetry({
        trustId: model && model.trustId || '',
        schoolId: model && model.id || '',
        awardTagId: awardTagId,
        questionSetName: 'School self evaluation',
        createIfMissing: false
    });

    // Work out the first and first incomplete topic based on the user's progress in this tier so far.
    const {
        firstTopic,
        firstIncompleteTopic,
        isStarted,
    } = React.useMemo(() => (
        {
            firstTopic: topics.find(it => true),
            firstIncompleteTopic: topics.find(it => it.questionResponsesCount < it.questionsCount),
            isStarted: !!topics.filter(it => it.questionResponsesCount > 0).length,
        }
    ), [topics]);

    // If we are resitrcted to showing a single award tag, find that award tag and its certificate for easy reference.
    const {
        awardTag: thisAwardTag,
        schoolTagCertificate: thisSchoolTagCertificate,
    } = React.useMemo(() => {
        const awardTag = awardTags.find(it => it.id === awardTagId);
        const schoolTagCertificate = schoolTagCertificates.find(it => it.tagId === awardTagId);

        return {
            awardTag,
            schoolTagCertificate,
        }
    }, [awardTagId, awardTags, schoolTagCertificates]);

    // Submit and claim the certificates for the current award tag.
    const _submitCertificate = api.schoolTagCertificates.actions.submitCertificate();
    const _claimCertificate = api.schoolTagCertificates.actions.claimCertificate();

    // Submit the award for review.
    const [submitCertificate, { isExecuting: isSubmittingCertificate, errors: submitCertificateErrors }] = useAsyncCallback(async () => {
        if (!project || !thisAwardTag) {
            return;
        }

        const newCertificate = await _submitCertificate(project.id, thisAwardTag.id, thisAwardTag.name, thisAwardTag.awardingOrganisationName);
        if (newCertificate) {
            setSchoolTagCertificates(prevState => [
                ...prevState.filter(it => it.id !== newCertificate.id),
                newCertificate,
            ]);
        }
    }, [_submitCertificate, thisAwardTag, project]);

    // Self claim an the award certificate.
    const [claimCertificate, { isExecuting: isClaimingCertificate, errors: claimCertificateErrors }] = useAsyncCallback(async () => {
        if (!project || !thisAwardTag) {
            return;
        }

        const newCertificate = await _claimCertificate(project.id, thisAwardTag.id, thisAwardTag.name, thisAwardTag.awardingOrganisationName);
        if (newCertificate) {
            setSchoolTagCertificates(prevState => [
                ...prevState.filter(it => it.id !== newCertificate.id),
                newCertificate,
            ]);
        }
    }, [_claimCertificate, thisAwardTag, project]);


    // Navigate to a project URLs but making sure we wait until the projects are ready before doing so.
    const tagNavigateMap = React.useMemo(() => {
        let ret: { [key: string]: string } = {};
        for (const tag of awardTags) {
            ret[`${tag.id}::review`] = `/school/${model && model.id || ''}/awardDashboard/${tag.id}/${project && project.id || ''}`;
            ret[`${tag.id}::update`] = `/school/${model && model.id || ''}/awardHome/${tag.id}/${project && project.id || ''}`;
            ret[`${tag.id}::report`] = `/school/${model && model.id || ''}/awardSummary/${tag.id}/awardSummary/${project && project.id || ''}`;
        }
        return ret;
    }, [awardTags, model, project]);

    const [navigateWhenReady, { isNavigationPending: navigateIsPending }] = useNavigateWhenReady(
        !!model && !!project && !!firstTopic,
        {
            ...tagNavigateMap,
        }
    );


    if (!model) {
        return (<LoadingIndicator />);
    }

    // If we are for an award tag, and are within a trial license, then we don't show anything as awards are not part of the trial.
    if (!!thisAwardTag && license && license.isTrial) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>{thisAwardTag.name}</h1>
                </div>

                <Alert color="warning">
                    <FontAwesomeIcon icon="lock" />
                    <> </>
                    <strong>
                        This area is locked during your trial.
                    </strong>
                    <p>
                        Specific reviews are only available as part of your full Evaluate-Ed subscription.
                    </p>
                </Alert>
            </div>
            );
    }

    // Main UI
    //
    return (
        <>
            <div className="main-container overview">
                <AlertOnErrors errors={[loadingErrors, loadProjectErrors, submitCertificateErrors, claimCertificateErrors]} />

                <div className="overview-logo">
                </div>

                <Row>
                    <Col xs={12} xl={6}>
                        <ProgressWheel
                            model={project || undefined}
                            sections={sections || []}
                            topics={topics || []}
                            currentTier={model.tier as ProductTier}
                            awardTagId={awardTagId}
                            isLoading={isLoading || isLoadingProject}
                            placeholderReplacementMode={placeholderReplacementMode}
                        />
                    </Col>

                    {/* Special handling of right hand options for awards */}
                    <ConditionalFragment showIf={!!projectIdParam && !!awardTagId}>
                        <Col xs={12} xl={6}>
                            <div className="overview-tier-heading">
                                <h3>
                                    Progress
                                </h3>
                            </div>
                            <AwardFeedbackAlert awardTag={thisAwardTag} schoolTagCertificate={thisSchoolTagCertificate} />
                            <SchoolTagCertificateProgress model={model} awardTag={thisAwardTag} schoolTagCertificate={thisSchoolTagCertificate}
                                projectId={project && project.id || undefined}
                                hasAnsweredAllQuestions={topics.length > 0 && !firstIncompleteTopic}
                                submitCertificate={submitCertificate} isSubmittingCertificate={isSubmittingCertificate}
                                claimCertificate={claimCertificate} isClaimingCertificate={isClaimingCertificate}
                            />

                            <div className="overview-tier-heading">
                                <h3>
                                    Reports
                                </h3>
                            </div>
                            <Row className="mt-4">
                                <Col xs={12} lg={6}>
                                    <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady(`${thisAwardTag && thisAwardTag.id}::report`)}>
                                        <Row>
                                            <Col xs="auto">
                                                <FontAwesomeIcon icon={navigateIsPending(`${thisAwardTag && thisAwardTag.id}::report`) ? 'spinner' : 'passport'} spin={navigateIsPending(`${thisAwardTag && thisAwardTag.id}::report`)} />
                                            </Col>
                                            <Col>
                                                <div className="overview-navigation-button-text">
                                                    Generate {thisAwardTag && thisAwardTag.name} report
                                                </div>
                                            </Col>
                                        </Row>
                                    </Button>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady(`${thisAwardTag && thisAwardTag.id}::review`)}>
                                        <Row>
                                            <Col xs="auto">
                                                <FontAwesomeIcon icon={navigateIsPending(`${thisAwardTag && thisAwardTag.id}::review`) ? 'spinner' : 'solar-panel'} spin={navigateIsPending(`${thisAwardTag && thisAwardTag.id}::review`)} />
                                            </Col>
                                            <Col>
                                                <div className="overview-navigation-button-text">
                                                    Review {thisAwardTag && thisAwardTag.name} dashboard
                                                </div>
                                            </Col>
                                        </Row>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </ConditionalFragment>
                </Row>
            </div>
        </>
    );
};
