import * as React from 'react';
import { Button } from 'reactstrap';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ProjectTopic } from '../../../api/models/ProjectTopic';
import { EditProjectTopicOverviewOpinionModal } from './editModals/EditProjectTopicOverviewOpinionModal';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReportProps } from '../reportGenerator/ReportProps';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';


export interface ProjectTopicOverviewOpinionUiProps extends ReportProps {
    projectTopic: ProjectTopic
}

/**
 * ProjectTopicOverviewOpinion section of the executive summary.
 */
export const ProjectTopicOverviewOpinionUi = (props: ProjectTopicOverviewOpinionUiProps) => {
    const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
    const [originalValue, setOriginalValue] = React.useState<string>('');
    const toggleEditModalOpen = React.useCallback(() => {
        setEditModalIsOpen(prevState => !prevState);
    }, [setEditModalIsOpen]);


    let topic = React.useMemo(() => {
        return props.topics.find(item => item.id === props.projectTopic.topicId);
    }, [props.topics, props.projectTopic]);

    // Work out if there is anything to see in this section and if we should show it or not based on our mode and user.
    let roleToSeeAnything = React.useMemo(() => {
        if (!props.projectTopic) {
            return 'Manage';
        }

        // Only the subscription owner should see the blank section in edit mode.
        if (!props.projectTopic.overviewOpinion) {
            return 'Manage';
        }

        // Otherwise anyone can see it.
        return '';
    }, [props.projectTopic]);

    if (!props.model) {
        return (<></>);
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <PrivateContainer role={roleToSeeAnything}>
            <div>
                <ConditionalFragment showIf={!props.readOnly || !!props.projectTopic.overviewOpinion}>
                    <h6>
                        Additional thoughts around {topic && replacePlaceholders(topic.name, placeholderReplacementMode ) || 'topic'}
                        <ConditionalFragment showIf={!props.readOnly}>
                            <PrivateContainer role="Manage">
                                <> <Button size="sm" color="primary" outline onClick={e => {
                                    if (!props.projectTopic) {
                                        return;
                                    }
                                    setOriginalValue(replacePlaceholders(props.projectTopic.overviewOpinion, placeholderReplacementMode));
                                    toggleEditModalOpen();
                                }}>
                                    Edit
                                </Button></>
                            </PrivateContainer>                   
                        </ConditionalFragment>
                    </h6>
                    <p>
                        <PlainTextWithBrs text={replacePlaceholders(props.projectTopic.overviewOpinion, placeholderReplacementMode)} />
                    </p>
                </ConditionalFragment>
            </div>

            {
                editModalIsOpen ? (
                    <EditProjectTopicOverviewOpinionModal model={props.projectTopic} changeModel={(changes) => props.projectTopics.change(props.projectTopic.id, changes)}
                        save={async (): Promise<boolean> => {
                            let ok = await props.projectTopics.save();
                            if (!ok) {
                                return false;
                            }

                            toggleEditModalOpen();
                            return true;
                        }}
                        isSaving={props.isSaving} savingErrors={props.savingErrors}
                        cancel={() => {
                            if (!props.model) {
                                return;
                            }

                            props.changeModel({ overviewOpinion: originalValue });
                            toggleEditModalOpen();
                    }} />
                ): null
            }
        </PrivateContainer>
    );
};
