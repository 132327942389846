import * as React from 'react';
import { List } from '../List';

/**
 * List filtered to show only "Reading review EYFS to KS2" question sets.
 */
export const ReadingReviewEYFSToKS2List = () => {
    const filter = React.useCallback((items: Array<any>) => {
        if (!items) {
            return items;
        }

        return items.filter(item => item.name === 'Reading review EYFS to KS2');
    }, []);

    return (
        <List filter={filter} baseRoute="/administration/reading-eyfs-to-ks2" />
        );
};