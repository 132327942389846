import * as React from 'react';
import { Button } from 'reactstrap';
import { Guid } from "guid-string";
import { EditUiProps } from '../EditContainer';
import { SectionUi } from './SectionUi';
import { Section } from '../../../../api/models/Section';


export const ReviewerQuestionsPage = (props: EditUiProps) => {
    return (
        <div>
            <div>
                {
                    props.sections.models.map((item, index) => (
                        <SectionUi key={item.id} model={item} isCreate={props.sections.isAddedModel(item.id)}
                            questionSetType={props.questionSetType}
                            change={(changes: Partial<Section>) => props.sections.change(item.id, changes)}
                            validate={(fieldsToCheck?: Array<string>) => props.sections.validateModel(item.id, fieldsToCheck)}
                            validationErrors={props.sections.validationErrorsFor(item.id)}
                            remove={() => props.sections.removeModel(item.id)}
                            topics={props.topics}
                            questions={props.questions}
                            questionChoices={props.questionChoices}
                            impacts={props.impacts}
                            isPublished={props.isPublished}
                            subscriptionId={props.model && props.model.subscriptionId || ''}
                            isReadOnly={props.isReadOnly}
                            tags={props.tags} questionTags={props.questionTags}
                            awardTags={props.awardTags}
                            videos={props.videos}
                            changeQuestionOrder={props.changeQuestionOrder}
                            changeSectionOrder={props.changeSectionOrder}
                            changeTopicOrder={props.changeTopicOrder}
                        />
                    ))
                }
            </div>
            {
                !props.isPublished /* Can't add sections to a published question set */ ? (
                    <Button color="primary" outline onClick={() => props.sections.addModel({ questionSetId: props.model ? props.model.id : Guid.empty })}>Add section</Button>
                    ): null
            }
            
        </div>
    );
};
