import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { BasicPagedListContainerProps, BasicPagedListContainer } from "../../containers/common/basicContainers/BasicPagedListContainer";
import { PagedListUiPropsBase } from "../../containers/common/PagedListUiPropsBase";
import { SchoolTagCertificate } from "../../../api/models/SchoolTagCertificate";
import { School } from "../../../api/models/School";
import { Project } from "../../../api/models/Project";
import { connect } from "react-redux";
import { AppState } from "../../../store";
import { Subscription } from "../../../api/models/Subscription";

export interface DataItem {
    schoolTagCertificate: SchoolTagCertificate,
    school: School,
    project: Project,
    awardingOrganisationSubscription: Subscription | undefined | null,
}

export interface ListUiProps extends PagedListUiPropsBase<DataItem> {
    subscriptionId: string | undefined,
    showAllOrganisations?: boolean,
    location: {
        pathname: string,
    },
}

export const _ListContainer = withServiceProps<BasicPagedListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.schoolTagCertificates.viewModels.list()
}))(BasicPagedListContainer);

export const ListContainer = connect(
    (state: AppState) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_ListContainer);
