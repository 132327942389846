import * as React from 'react';
import { View, Image } from '@react-pdf/renderer';
import { SEFPdfProps } from './SEFPdf';
import { IImageEnumerator } from '../../executiveSummary/pdf/ImageEnumerator';

/**
 * Overview section of SEF (pdf format).
 */
export interface OverviewChartPdfUiProps extends SEFPdfProps {
    imageEnumerator: IImageEnumerator
}

export const OverviewChartPdfUi = (props: OverviewChartPdfUiProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <View>
            <Image src={props.imageEnumerator.getNextImage()} style={{ marginBottom: 16 }} />
        </View>  
    );
};

