import { ApiFetch } from 'apifetch-json';


/**
 * Service that handles authentication of users.
 */
export class AuthenticationService {
    protected url: string = '';
    private readonly api: ApiFetch;

    constructor(url: string, apiFetch?: ApiFetch) {
        this.url = url;
        this.api = apiFetch || new ApiFetch();
    }

    /**
     * Login.
     * @param user
     * @param password
     */
    async login(user: string, password: string): Promise<TokenResult> {
        let result = await this.api.fetch(`${this.url}/login`, 
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        user: user,
                        password: password
                    }
                )
            });

        return result;
    }

    /**
     * Renew a token for the current user.
     */
    async renewToken(): Promise<TokenResult> {
        let result = await this.api.fetch(`${this.url}/renewToken`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                    }
                )
            });

        return result;
    }

    /**
     * Send an invite email to the passed in user.
     * @param user
     */
    async sendInvite(user: string) : Promise<boolean> {
        let result = await this.api.fetch(`${this.url}/sendInvite`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        user: user
                    }
                )
            });

        return true;
    }

    /**
     * Confirm acceptance of an invite for a user to join.
     * @param user
     */
    async confirmInvite(user: string, token: string, newPassword: string): Promise<boolean> {
        let result = await this.api.fetch(`${this.url}/confirmInvite`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: user,
                    token: token,
                    newPassword: newPassword
                })
            });

        return true;
    }

    /**
     * Change the current user's password.
     * @param user
     */
    async changePassword(currentPassword: string, newPassword: string): Promise<boolean> {
        let result = await this.api.fetch(`${this.url}/changePassword`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    currentPassword: currentPassword,
                    newPassword: newPassword
                })
            });

        return true;
    }

    /**
     * Send an password reset email to the passed in user.
     * @param user
     */
    async sendPasswordReset(user: string): Promise<boolean> {
        let result = await this.api.fetch(`${this.url}/sendPasswordReset`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        user: user
                    }
                )
            });

        return true;
    }

    /**
     * Returns true if token is a valid unexpired token for password reset for user.
     * @param user
     * @param token
     */
    async checkPasswordResetToken(user: string, token: string): Promise<boolean> {
        let result = await this.api.fetch(`${this.url}/checkPasswordResetToken`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        user: user,
                        token: token
                    }
                )
            });

        if (!result) {
            return false;
        }

        return result.isValid? true: false;
    }

    /**
     * Confirm acceptance of an invite for a user to join.
     * @param user
     */
    async resetPassword(user: string, token: string, newPassword: string): Promise<boolean> {
        let result = await this.api.fetch(`${this.url}/resetPassword`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: user,
                    token: token,
                    newPassword: newPassword
                })
            });

        return true;
    }
}

export interface TokenResult {
    token: string | undefined,
    identity: {
        userId: string,
        user: string,
        roles: Array<string>,
        forename: string,
        surname: string,
        subscriptionId: string
    }
}
