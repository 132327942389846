import * as React from "react";
import { Container, Button, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, FormGroup, FormText, Input } from "reactstrap";
import { ReportSettingsModel } from "../reportSettings/ReportSettingsModel";
import { ProjectSettings } from "../../../utilities/getProjectSettingsFromQuestionSetName";
import { AlertOnErrors } from "../../shared/AlertOnErrors";
import { ButtonGroup } from "reactstrap";
import { ButtonDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalBody } from "reactstrap";
import { Label } from "reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { ModalFooter } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Spinner } from "reactstrap";
import { Topic } from "../../../api/models/Topic";
import { Section } from "../../../api/models/Section";
import { SpecificReportUi } from "./useReportGeneratorSpecificReportUi";
import { PlaceholderReplacementMode } from "../../../utilities/replacePlaceholders";

export interface ReportBuilderOptionsProps {
    reportSettings: ReportSettingsModel,
    changeReportSettings: (changes: Partial<ReportSettingsModel>) => void,

    projectSettings: ProjectSettings,

    reportBuilderComplete: boolean,
    setReportBuilderCompleted: React.Dispatch<React.SetStateAction<boolean | undefined>>,

    saveCustomReport: () => Promise<void>
    isSavingCustomReport: boolean,

    deleteCustomReport: () => Promise<void>,

    reportBuilderSections: Array<Section>,
    reportBuilderTopics: Array<Topic>,

    errors: Array<any>,

    specificReportUi: SpecificReportUi,

    placeholderReplacementMode: PlaceholderReplacementMode,
}

/**
 * ReportBuilderOptions that appears in ReportGenerator when we are customising what we want to build into the report.
 */
export const ReportBuilderOptions = (props: ReportBuilderOptionsProps) => {
    const {
        reportSettings,
        changeReportSettings,

        setReportBuilderCompleted,

        saveCustomReport,
        isSavingCustomReport,

        deleteCustomReport,

        errors,
        placeholderReplacementMode, //needed for section and topic names within ExecutiveSummary and SEF Options

        specificReportUi: SpecificReportUi,
    } = props;

    const maxCustomReportNameLength = 30;
    const customReportNameTooLong = reportSettings.reportName.length > maxCustomReportNameLength;


    // Editing name of a custom report.
    const [customReportModalIsOpen, setCustomReportModalOpen] = React.useState<boolean>(false);
    const toggleCustomReportModal = React.useCallback(() => setCustomReportModalOpen(prevState => !prevState), [setCustomReportModalOpen]);

    const [customReportDropdownIsOpen, setCustomReportDropdownIsOpen] = React.useState<boolean>(false);
    const toggleCustomReportDropdown = React.useCallback(() => setCustomReportDropdownIsOpen(prevState => !prevState), [setCustomReportDropdownIsOpen]);

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    What should appear in the report?
                </h1>
                <AlertOnErrors errors={errors} />
            </div>
            <div>
                <SpecificReportUi.ReportBuilderOptions {...props} />

                <div className="mt-3">
                    <Button color="primary" onClick={() => setReportBuilderCompleted(true)}>
                        Generate report
                    </Button>
                    <> </>
                    <ButtonGroup>
                        {
                            reportSettings.customReportId ? (
                                <>
                                    <Button color="primary" outline onClick={() => saveCustomReport()}>
                                        Save custom {reportSettings.reportName}
                                    </Button>
                                    <ButtonDropdown isOpen={customReportDropdownIsOpen} toggle={() => toggleCustomReportDropdown()}>
                                        <DropdownToggle caret color="primary" outline />
                                        <DropdownMenu right>
                                            <DropdownItem onClick={() => toggleCustomReportModal()}>
                                                <FontAwesomeIcon icon="edit" /> <span>Rename</span>
                                            </DropdownItem>
                                            <DropdownItem className="text-danger" onClick={() => deleteCustomReport()}>
                                                <FontAwesomeIcon icon="trash-alt" /> <span>Delete</span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </>
                            )
                                : (
                                    <Button color="primary" outline onClick={() => toggleCustomReportModal()}>
                                        Save as custom report
                                    </Button>
                                )
                        }
                    </ButtonGroup>
                </div>
            </div>

            <Modal isOpen={customReportModalIsOpen} toggle={toggleCustomReportModal}>
                <ModalHeader toggle={toggleCustomReportModal}>
                    Save custom report
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label htmlFor="customReportName">Name</Label>
                        <Input name="customReportName" type="text" value={reportSettings.reportName} onChange={e => changeReportSettings({ reportName: e.currentTarget.value })}
                            invalid={!reportSettings.reportName || customReportNameTooLong}
                        />
                        <ConditionalFragment showIf={!reportSettings.reportName}>
                            <FormText color="danger">
                                Name is required.
                            </FormText>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={customReportNameTooLong}>
                            <FormText color="danger">
                                Name must be no more than {maxCustomReportNameLength} characters long.
                            </FormText>
                        </ConditionalFragment>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <ButtonAsync color="primary"
                        disabled={!reportSettings.reportName || customReportNameTooLong}
                        onClick={async () => { await saveCustomReport(); toggleCustomReportModal(); }}
                        isExecuting={isSavingCustomReport}
                        executingChildren={<><Spinner size="sm" /><> </>Saving...</>}
                    >
                        <FontAwesomeIcon icon="save" />
                        <> </>
                        Save
                    </ButtonAsync>
                    <>
                    </>
                    <Button color="primary" outline onClick={() => toggleCustomReportModal()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};