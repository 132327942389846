import * as React from 'react';
import { FormGroup, Label, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ValidatedElasticInput } from '../../shared/ValidatedElasticInput';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { User } from '../../../api/models/User';
import { ValidationErrors } from 'pojo-validator';

export interface CreateModalProps {
    model: User | undefined,
    changeModel: (changes: Partial<User>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    save: () => void,
    cancel: () => void,
}

export const CreateModal = (props: CreateModalProps) => {
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    if (!props.model) {
        return (<></>);
    }

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                Add user
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="forename">Forename</Label>
                            <ValidatedInput type="text" name="forename" placeholder="Forename" value={props.model.forename} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="surname">Surname</Label>
                            <ValidatedInput type="text" name="surname" placeholder="Surname" value={props.model.surname} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label htmlFor="name">Email</Label>
                    <ValidatedInput type="email" name="email" placeholder="Email" value={props.model.email} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label>Job title</Label>
                    <Row>
                        <Col sm="12" lg="6">
                            <ValidatedInput type="text" name="jobTitle" value={props.model.jobTitle} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="bio">Bio</Label>
                    <Row>
                        <Col>
                            <ValidatedElasticInput type="textarea" name="bio" value={props.model.bio} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </Col>
                    </Row>
                </FormGroup>
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <Button color="primary" onClick={props.save}>
                    Save
                </Button>
                <Button color="primary" outline onClick={props.cancel}>Cancel</Button>
            </ModalFooter>            
        </Modal>
    );
};
