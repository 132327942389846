import * as React from 'react';
import { Tag } from '../../../../api/models/Tag';
import { School } from '../../../../api/models/School';
import { AwardTag } from '../../../../api/models/AwardTag';

export interface TagUiProps {
    tag: Tag | AwardTag,
    school: School | undefined,
    isSelected: boolean,
    toggleSelected: () => void,
}

export const TagUi = (props: TagUiProps) => {
    const toggle = React.useCallback(() => {
        props.toggleSelected();
    }, [props.toggleSelected]);
    return (
        <div className={`tag-badge ${props.isSelected ? "selected" : ""}`} onClick={(ev) => toggle()}>
            {props.tag.name}
        </div>
    );
};