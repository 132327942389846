import { PasswordValidation } from "../services/passwordValidation/PasswordValidation";

/**
 * Returns all the choices that are presented for a questionnaire's scale.
 */
export function getQuestionaireScaleChoices(): Array<QuestionaireScaleChoice> {
    const ret = [
        { name: 'Strongly Agree', scale: 1 },
        { name: 'Agree', scale: 2 },
        { name: 'Disagree', scale: 3 },
        { name: 'Strongly Disagree', scale: 4 },
    ];
    return ret;
}

/**
 * Type of each result returned by getQuestionaireScaleChoices.
 */
export interface QuestionaireScaleChoice {
    name: string,
    scale: number
}