import * as React from 'react';
import { ContainerComponentProps } from 'react-withcontainer';
import { useAsyncCallback } from 'react-use-async-callback';
import { connect } from 'react-redux';
import { Identity } from '../../../store/user';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Project } from '../../../api/models/Project';
import { useApiService } from '../../../api/useApiService';
import { School } from '../../../api/models/School';
import { Subscription } from '../../../api/models/Subscription';

export interface ContainerProps extends ContainerComponentProps<UiProps> {
    appRoutes: Array<any>,
    children: any,
    currentProjectFor: () => Promise<Project>,
}

export interface UiProps {
    appRoutes: Array<any>,
    children: any,

    model: School | undefined | null,
}

export const _LayoutContainer = (props: ContainerProps) => {
    const { component, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');
    const refreshTimestamp = navigation.getParam('refresh', '');

    const api = useApiService();
    const repository = api.schools.repository();
    const [model, setModel] = React.useState<School>();
    const [loadedForRefreshTimestamp, setLoadedForRefreshTimetamp] = React.useState<string>(refreshTimestamp);

    const [load, { isExecuting: isLoading }] = useAsyncCallback(async () => {
        const result = await repository.find(id);
        setModel(result);
        setLoadedForRefreshTimetamp(refreshTimestamp);

    }, [id, repository, setModel, setLoadedForRefreshTimetamp, refreshTimestamp]);

    React.useEffect(() => {
        if (!id || isLoading || (model && model.id === id && refreshTimestamp === loadedForRefreshTimestamp)) {
            return;
        }

        load();
    }, [id, isLoading, model, refreshTimestamp, loadedForRefreshTimestamp]);

    const Component = component;

    return <Component {...rest}
        model={model}
    />
}

interface State {
    user?: User
}

interface User {
    identity?: Identity
}

export const LayoutContainer = connect(
    (state: State) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_LayoutContainer);

// Ability for other code to notify that we need a full layout refresh, usually to make sure we unlock things on the sidebar
// that otherwise wouldn't be unlocked.
let schoolLayoutRefreshKey = '';

/**
 * Ask the layout and sidebar to fully reload the school information and refresh.
 */
export const refreshSchoolLayoutAndSidebar = () => {

};