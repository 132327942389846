import * as React from 'react';
import { ValidationErrors, Validator, ValidationCallback } from "pojo-validator";
import { Repository } from "pojo-repository";

interface ModelsValidationErrors {
    [id: string]: ValidationErrors
}

/**
 * Create state and functions that manage a collection of child models in a edit container.
 * 
 * NOTE I'm not a fan of the class based API returned by this hook, it feels out of place with everything else, but does it job well so has been left alone.
 * @param repository
 * @param validating
 */
export function useManagedChildModels<T extends { id: string }>(repository: Repository<T>, validating?: ValidationCallback): ManagedChildModels<T> {
    const [models, setModels] = React.useState<Array<T>>([]);
    const [validationErrors, setValidationErrors] = React.useState<ModelsValidationErrors>({}); // NOTE we cant useValidator() as it is designed to manage a single model's state.
    const [removedModels, setRemovedModels] = React.useState<Array<T>>([]);
    const [addedModels, setAddedModels] = React.useState<Array<T>>([]);
    const [editedModels, setEditedModels] = React.useState<Array<T>>([]);

    const validateModel = React.useCallback((id: string, fieldsToCheck?: Array<string>): boolean => {
        const model = models.find(item => item.id === id);
        if (!model) {
            return false;
        }

        let realValidating = validating;
        if (!realValidating) {
            realValidating = (model, validation, fieldsToCheck) => { };
        }
        let validator = new Validator(realValidating);
        validator.validate(model, fieldsToCheck);

        setValidationErrors({
            ...validationErrors,
            [model.id]: {
                ...validationErrors[model.id],
                ...validator.errors()
            }
        });

        return !validator.hasErrors();
    }, [models, validating, setValidationErrors, validationErrors]);

    let ret = React.useMemo(() => {
        return {
            models: models,
            setModels: setModels,
            validationErrors: validationErrors,
            setValidationErrors: setValidationErrors,

            change: (id: string, changes: Partial<T>): void => {
                const model = models.find(item => item.id === id);
                if (!model) {
                    return undefined;
                }

                let newModel = {
                    ...model,
                    ...changes
                };

                // Update the models with the change maintaining the order.
                let newModels = [...models];
                newModels[newModels.findIndex(it => it.id === model.id)] = newModel;

                setModels(newModels);

                // Update the list of models that needs changing.
                setEditedModels(prevState => {
                    return [
                        ...(prevState).filter(it => it.id !== model.id),
                        newModel
                    ];
                });
            },

            addModel: async (initilizer: Partial<T>): Promise<T> => {
                let model = await repository.create()
                model = {
                    ...model,
                    ...initilizer
                };

                setAddedModels(prevState => [
                    ...prevState,
                    model
                ]);

                setModels(prevState => [
                    ...prevState,
                    model
                ]);

                return model;
            },

            removeModel: (id: string): void => {
                let model = models.find(item => item.id === id);
                setModels(prevState => prevState.filter(item => item.id !== id));
                if (model) {
                    if (addedModels.find(it => it.id === id)) {
                        setAddedModels(prevState => prevState.filter(it => it.id !== id));
                    } else {
                        setRemovedModels(prevState => [
                            ...(prevState || []),
                            model as T
                        ]);
                    }
                }
            },

            validateModel: validateModel,

            validateModels: (): boolean => {
                // Check all the sublocations.
                let ret = true;
                for (let i = 0; i < models.length; ++i) {
                    let model = models[i];
                    let isValid = validateModel(model.id);
                    if (!isValid) {
                        ret = false;
                    }
                }

                return ret;
            },

            save: async (): Promise<boolean> => {
                for (let i = 0; i < models.length; ++i) {
                    let model = models[i];
                    if (addedModels.find(it => it.id === model.id)) {
                        await repository.save(model.id, model, true);
                    } else if (editedModels.find(it => it.id === model.id)) {
                        await repository.save(model.id, model, false);
                    } else {
                        // Nothing has changed, so we don't need to save anything.
                    }
                }

                for (let i = 0; i < removedModels.length; ++i) {
                    let model = removedModels[i];
                    await repository.remove(model.id);
                }

                // Reset the added, edited, and removed models (being careful not to loose any state changed that is still pending).
                setAddedModels(prevState => prevState.filter(it => !addedModels.find(itt => itt.id === it.id)));
                setEditedModels(prevState => prevState.filter(it => !editedModels.find(itt => itt.id === it.id)));
                setRemovedModels(prevState => prevState.filter(it => !removedModels.find(itt => itt.id === it.id)));

                return true;
            },

            validationErrorsFor: (id: string): ValidationErrors => {
                let errors = {};
                if (id in validationErrors) {
                    errors = validationErrors[id];
                }
                return errors;
            },

            isAddedModel: (id: string): boolean => {
                if (addedModels.find(it => it.id === id)) {
                    return true;
                }

                return false;
            }
        };
    }, [models, setModels, validationErrors, setValidationErrors, removedModels, setRemovedModels,
        addedModels, setAddedModels, editedModels, setEditedModels, repository, validateModel]);

    return ret;
}

// Type returned by useManagedChildModels.
export interface ManagedChildModels<T> {
    // The child models.
    models: Array<T>,

    // Set the child models.
    setModels: React.Dispatch<React.SetStateAction<Array<T>>>,

    // Validation errors for all child models.
    validationErrors: ModelsValidationErrors,

    // Set the validation errors for all child models.
    setValidationErrors: React.Dispatch<React.SetStateAction<ModelsValidationErrors>>,

    // Field in a child model has changed and needs to be saved back into the model.
    change: (id: string, changes: Partial<T>) => void,

    // Add a new item to models (persisted on save()).
    addModel: (initilizer: Partial<T>) => Promise<T>,

    // Remove an item from models (persisted on save()).
    removeModel: (id: string) => void,

    // Validate a model.
    validateModel: (id: string, fieldsToCheck?: Array<string>) => boolean,

    // Save all models, including edits, adds and removes.
    save: () => Promise<boolean>

    // Validate all models.
    validateModels: () => boolean,

    // Returns the validation errors for a particular model.
    validationErrorsFor: (id: string) => ValidationErrors,

    /**
     * Returns true if the model is added via addModel().
     * 
     * Once save() is called all models are reset to not-added so this will then return false.
     */
    isAddedModel: (id: string) => boolean
}
