import * as React from 'react';
import { ContainerComponentProps } from "react-withcontainer";
import { EditUiPropsBase } from "../../containers/common/EditUiPropsBase";
import { Tag } from "../../../api/models/Tag";
import { Repository } from "pojo-repository";
import { useAsyncCallback } from 'react-use-async-callback';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidationState } from 'pojo-validator';
import { withServiceProps } from 'inject-typesafe-react';
import { AppServicesCore } from '../../../configure/configureServicesCore';
import { Guid } from 'guid-string';


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<Tag>
}

export interface EditUiProps extends EditUiPropsBase<Tag> {
    model: Tag | undefined,
    save: () => Promise<boolean>,
    isSaving: boolean,
}

export const _EditContainer = (props: EditContainerProps) => {
    const { component, repository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam("id", "");

    const [model, setModel] = React.useState<Tag | undefined>();

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let model: Tag;
        if (id === '') {
            model = await repository.create();
            model.id = Guid.newGuid();
            model.archived = false;
        } else {
            model = await repository.find(id);
        }
        setModel(model);
        return true;
    }, [setModel, repository]);

    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, load, isLoading, loadingErrors]);

    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }
        await repository.save(model.id, model);
        return true;
    }, [model, repository, id]);

    const changeModel = React.useCallback((changes: Partial<Tag>) => {
        if (!model) {
            return;
        }

        setModel({
            ...model,
            ...changes
        });
    }, [model, setModel]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

    }, [model]);

    const Component = component;

    return <Component {...rest}
        load={load} isLoading={isLoading} loadingErrors={loadingErrors}
        isCreate={id === ''}
        save={save} isSaving={isSaving} savingErrors={savingErrors}
        model={model} changeModel={changeModel}
        validate={validate} validationErrors={validationErrors}
    />
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.tags.repository()
}))(_EditContainer);