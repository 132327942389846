import { ProductTier } from "../../../api/models/codeOnly/ProductTeir";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";

export interface ProductTierNotUnlockedAlertProps {
    tier: ProductTier,
}

/**
 * Alert to show when a feature requires a product tier which has not yet been unlocked.
 * @param props
 */
export const ProductTierNotUnlockedAlert = (props: ProductTierNotUnlockedAlertProps) => {
    const { tier } = props;

    // Work out instructions to show to the user on how to unlock this by telling them to complete the previous stage.
    const howToUnlockText = useMemo(() => {
        switch (tier) {
            case ProductTier.Snapshot:
                return "You will unlock this functionality once you are authorised.";
            case ProductTier.SixtyMinuteSEF:
                return "You will unlock this functionality once you have completed your SEF Lite.";
            case ProductTier.FullReview:
                return "You will unlock this functionality once you have completed your SEF Plus."
        }
    }, [tier]);

    return (
        <Alert color="warning">
            <FontAwesomeIcon icon="lock" />
            <> </>
            <strong>
                This area is locked.
            </strong>
            <p>
                {howToUnlockText}
            </p>
        </Alert>
        );
};