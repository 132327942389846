import * as React from "react";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { withServiceProps } from "inject-typesafe-react";
import { Subscription } from "../api/models/Subscription";
import { AppServicesCore } from "../configure/configureServicesCore";
import { School } from "../api/models/School";
import { UserState } from "../store/user";
import { AppState } from "../store";
import { connect } from 'react-redux';


export interface ManageContainerProps extends ContainerComponentProps<ManageUiProps> {
    user: UserState,
    loadModel: (id: string) => Promise<any>
}

export interface ManageUiProps {
    model: Subscription | undefined,
    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any,

    school: School | undefined,
}

/**
 * Container for redirecting to the right manage screen depending on the type of subscription.
 * 
 * @param props
 */
export const _ManageContainer = (props: ManageContainerProps) => {
    const { component, loadModel, user, ...rest } = props;

    const [model, setModel] = React.useState<Subscription | undefined>(undefined);
    const [school, setSchool] = React.useState<School | undefined>(undefined);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!user.identity) {
            return false;
        }
        let result = await loadModel(user.identity.subscriptionId);

        setSchool(result.school);

        setModel(result.model);
        return true;
    }, [user, loadModel, setModel, setSchool]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest} model={model}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            school={school}
        />
    );
};

export const __ManageContainer = connect(
    // mapStateToProps
    (state: AppState) => ({
        user: state.user
    })
)(_ManageContainer);



export const ManageContainer = withServiceProps<ManageContainerProps, AppServicesCore>(services => ({
    loadModel: services.api.subscriptions.viewModels.manage(),
}))(__ManageContainer);

