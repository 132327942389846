import * as React from 'react';
import { ProjectSettings, getProjectSettingsFromQuestionSetName, defaultProjectSettings } from '../utilities/getProjectSettingsFromQuestionSetName';
import { useApiService } from './useApiService';
import { Guid } from 'guid-string';

/**
 * Hook to get the settings to use for a project.
 */
export function useProjectSettings(projectId: string, awardTagId?: string): ProjectSettings {
    const api = useApiService();

    // Load the project settings when we can.
    const [lastLoadedForProjectId, setLastLoadedForProjectId] = React.useState<string>();
    const [lastLoadedForAwardTagId, setLastLoadedForAwardTagId] = React.useState<string | undefined>();

    const [ret, setRet] = React.useState<ProjectSettings>();
    React.useEffect(() => {
        if (Guid.isEmpty(projectId) || (projectId === lastLoadedForProjectId && awardTagId === lastLoadedForAwardTagId)) {
            return;
        }

        setLastLoadedForProjectId(projectId);
        setLastLoadedForAwardTagId(awardTagId);
        (async (): Promise<void> => {
            const getProjectAndQuestionSet = api.projects.viewModels.projectAndQuestionSet();
            const result = await getProjectAndQuestionSet(projectId);
            const settings = getProjectSettingsFromQuestionSetName(result.questionSet.name, result.project.id, result.project.trustId, result.project.schoolId, awardTagId, result.project.subjectId);
            setRet(settings);
        })();
    }, [projectId, setRet, api, lastLoadedForProjectId, setLastLoadedForProjectId, awardTagId, lastLoadedForAwardTagId]);

    // If we don't know better return the default settings.
    if (!ret) {
        return defaultProjectSettings;
    }

    // Return the loaded settings.
    return ret;
}