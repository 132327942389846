import * as React from 'react';
import { Container, Button } from 'reactstrap';
import { withContainer } from 'react-withcontainer';
import { ReviewersUiProps, ReviewersContainer } from '../ReviewersContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { ProjectReviewerUi } from './ProjectReviewerUi';
import { ProjectReviewer } from '../../../api/models/ProjectReviewer';

export const ReviewersUi = (props: ReviewersUiProps) => {
    // Work a list of unique reviewers.
    let uniqueReviewers = React.useMemo((): Array<ProjectReviewer> => {
        if (!props.model) {
            return [];
        }

        let ret: Array<ProjectReviewer> = [];
        for (let pr of props.projectReviewers.models) {
            let existing = ret.find(it => it.userId === pr.userId);
            if (existing) {
                continue;
            }

            ret.push(pr);
        }

        return ret;
    }, [props.model, props.projectReviewers]);

    // render UI
    //

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    Reviewers who should be listed on reports as responsible for answering questions <small className="text-muted">({uniqueReviewers.length} reviewers)</small>
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <div>
                {
                    props.projectReviewers.models.map((item, index) => (
                        <ProjectReviewerUi key={item.id}
                            model={item} isCreate={props.projectReviewers.isAddedModel(item.id)} change={changes => props.projectReviewers.change(item.id, changes)}
                            project={props.model}
                            remove={async () => { props.projectReviewers.removeModel(item.id); await props.projectReviewersRepository.remove(item.id); }}
                            validate={fieldsToCheck => {
                                // Validate first.
                                let ok = props.projectReviewers.validateModel(item.id, fieldsToCheck);
                                if (!ok) {
                                    return;
                                }

                                // If we are valid, then also trigger a save back to the database.
                                props.save();
                            }}
                            validationErrors={props.projectReviewers.validationErrorsFor(item.id)}
                            isProjectOwner={index === 0 ? true : false}
                            users={props.users}
                            restrictions={props.restrictions}
                            sendQuestionEmail={props.sendQuestionEmail}
                            isSingleSchoolSubscription={props.isSingleSchoolSubscription}
                            newReviewerRoleGroup={props.newReviewerRoleGroup}
                        />
                    ))
                }
                <div>
                    <Button color="primary" outline onClick={e => props.projectReviewers.addModel({ projectId: props.model && props.model.id || '' })}>
                        Add another reviewer
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export const Reviewers = withContainer(ReviewersContainer)(ReviewersUi);
