import * as React from 'react';
import { Redirect } from 'react-router';
import { DashboardContainer, DashboardUiProps } from './DashboardContainer';
import { withContainer } from 'react-withcontainer';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { SpidergraphCard } from './charts/SpidergraphCard';
import { TrustComparisonCard } from './tables/TrustComparisonCard';
import { SchoolComparisonCard } from './tables/SchoolComparisonCard';
import { SchoolWithoutTrustComparisonCard } from './tables/SchoolWithoutTrustComparisonCard';
import { RiskScattergraphCard } from './charts/RiskScattergraphCard';
import { dashboardDrilldownUrl } from '../../../utilities/dashboardDrilldownUrl';
import { useProjectSettings } from '../../../api/useProjectSettings';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { TimelineCard } from './charts/TimelineCard';
import { TimelineComparisonCard } from './tables/TimelineComparisonCard';
import { defaultProjectSettings } from '../../../utilities/getProjectSettingsFromQuestionSetName';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { isProductTierUnlocked, ProductTier } from '../../../api/models/codeOnly/ProductTeir';
import { ProductTierNotUnlockedAlert } from '../../shared/productTierNotUnlocked/ProductTierNotUnlockedAlert';
import { SubjectComparisonCard } from './tables/SubjectComparisonCard';
import { useApiService } from '../../../api/useApiService';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';
import { Subscription } from '../../../api/models/Subscription';
import { ComparisonTypes } from '../../../api/models/codeOnly/ComparisonTypes';
import { UserProfile } from '../../../api/models/UserProfile';


/**
 * Dashboard screen for a project, this gives a summary and easy navigation for the user.
 */
export const DashboardUi = (props: DashboardUiProps) => {
    const [schoolComparisonOpen, setSchoolComparisonOpen] = React.useState<boolean>(false);
    const toggleSchoolComparison = React.useCallback(() => {
        setSchoolComparisonOpen(prevState => !prevState);
    }, [setSchoolComparisonOpen]);
    const projectSettings = useProjectSettings((props.model && props.model.id) || '', (props.awardTag && props.awardTag.id) || undefined);

    const isSelfEvaluation = React.useMemo(() => { return projectSettings.isSelfEvaluation; }, [projectSettings]);

    console.log(props.allowCrossSchoolComparison);

    // If we aare showing for a trust, then toggle the full school comparison on by default.
    const [hasToggledDefaultSchoolComparison, setHasToggledDefaultSchoolComparison] = React.useState<boolean>();
    React.useEffect(() => {
        if (!props.model || hasToggledDefaultSchoolComparison) {
            return;
        }

        if (Guid.isEmpty(props.model.schoolId) && !props.overviewOnly) {
            setSchoolComparisonOpen(true);
        }

        setHasToggledDefaultSchoolComparison(true);
    }, [props.model, hasToggledDefaultSchoolComparison, setHasToggledDefaultSchoolComparison, setSchoolComparisonOpen, props.overviewOnly]);

    // We should show the termly breakdown for self evaluation projects at the school level only.
    const isTrustLevelProject = (props.model && Guid.isEmpty(props.model.schoolId)) || false;
    const showTermlyBreakdown = projectSettings.isSelfEvaluation && !isTrustLevelProject;
    let thisProject = props.subjectResults[0];
    if (!props.model || projectSettings === defaultProjectSettings) {
        return (<LoadingIndicator />);
    }

    // If this is a school's dashboard and the school has not reached the right tier, hide the functionality.
    if (projectSettings.isSelfEvaluation && !props.awardTag && props.school && !isProductTierUnlocked(ProductTier.Snapshot, props.school.tier as ProductTier)) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>
                        <h1>
                            {
                                props.school ? props.school.name
                                    : props.trust ? props.trust.name
                                        : ''
                            } <small className="text-muted">
                                {props.section ? (<> / <Link to={`${projectSettings.baseRoute}/dashboard`}>{/*props.awardTag ? props.awardTag.name :*/ projectSettings.isSelfEvaluation ? 'Self evaluation' : 'Project'} overview</Link></>) : (<> / {/*props.awardTag ? props.awardTag.name :*/ projectSettings.isSelfEvaluation ? 'Self evaluation' : projectSettings.isDueDiligence ? 'Due diligence' : thisProject.subject?.name} overview</>)}
                                {props.section ? props.topic ? (<> / <Link to={dashboardDrilldownUrl(projectSettings, undefined, undefined, props.filterBySectionOriginKey || '')}>{replacePlaceholders(props.section.name, props.placeholderReplacementMode)}</Link></>) : (<> / {props.section.name}</>) : null}
                                {props.topic ? (<> / {props.topic.name}</>) : null}
                                {props.isLoading ? (<> <LoadingIndicator className="inline-loading-indicator" /></>) : null}
                            </small>
                        </h1>
                    </h1>
                </div>
                <ProductTierNotUnlockedAlert tier={ProductTier.Snapshot} />
            </div>
        );
    }

    return (
        <>
            <div className="main-container">
                <div className="main-heading">
                    <Row>
                        <Col>
                            <h1>
                                {
                                    props.school ? props.school.name
                                        : props.trust ? props.trust.name
                                            : ''
                                } <small className="text-muted">
                                    {props.section ? !props.subject ? (<> / <Link to={`${projectSettings.baseRoute}/dashboard`}> {props.awardTag ? props.awardTag.name : projectSettings.isSelfEvaluation ? 'Self evaluation' : 'Project'} overview</Link></>) : (<> <Link to={`/school/${props.school?.id || ''}/subjectHome/${props.subject?.id}`}> {props.subject.name}</Link> </>)
                                        : (<> / {props.awardTag ? props.awardTag.name + " overview" : projectSettings.isSelfEvaluation ? 'Self evaluation overview' : projectSettings.isDueDiligence ? 'Due diligence overview' : props.subject ? (<> <Link to={`/school/${props.school?.id || ''}/subjectHome/${props.subject?.id}`}> {props.subject.name}</Link> </>) : "Project overview"}  </>)}

                                    {props.section ? props.topic ? (<> / <Link to={dashboardDrilldownUrl(projectSettings, undefined, undefined, props.filterBySectionOriginKey || '')}>{props.section.name}</Link></>) : (<> / {props.section.name}</>) : null}
                                    {props.topic ? (<> / {replacePlaceholders(props.topic.name, props.placeholderReplacementMode, { subjectName: props.topic.name })}</>) : null}
                                    {props.isLoading ? (<> <LoadingIndicator className="inline-loading-indicator" /></>) : null}
                                </small>
                            </h1>
                        </Col>
                    </Row>
                </div>

                <ConditionalFragment showIf={!!props.awardTag && !!props.awardTag.description}>
                    <p><strong>
                        <PlainTextWithBrs text={props.awardTag && props.awardTag.description || ''} />
                    </strong></p>
                </ConditionalFragment>

                <Row>
                    <Col xs={12} xl={!projectSettings.questionSetType.trustTargets ? 12 : showTermlyBreakdown ? 6 : 8}>
                        <SpidergraphCard {...props} showOtherSchools={schoolComparisonOpen} isSelfEvaluation={isSelfEvaluation} />
                    </Col>

                    <ConditionalFragment showIf={showTermlyBreakdown}>
                        <Col xs={12} xl={6}>
                            <TimelineCard {...props} />
                        </Col>
                    </ConditionalFragment>

                    <Col xs={12} xl={showTermlyBreakdown ? 6 : 4}>
                        <ConditionalFragment showIf={props.allowCrossSchoolComparison}>
                            <ConditionalFragment showIf={
                                !props.overviewOnly
                                || isTrustLevelProject /* Even though its not a graph, we do show the high level comparison on the overview because it looks better that way */
                            }>
                                {
                                    !projectSettings.questionSetType.trustTargets ? (null)
                                        : props.results.length > 1 ?
                                            (
                                                <TrustComparisonCard {...props} schoolComparisonOpen={schoolComparisonOpen} toggleSchoolComparison={toggleSchoolComparison} />
                                            ) : (
                                                <SchoolWithoutTrustComparisonCard {...props} />
                                            )
                                }
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={projectSettings.allowRisks && !isTrustLevelProject}>
                            <RiskScattergraphCard {...props} />
                        </ConditionalFragment>
                    </Col>

                    <ConditionalFragment showIf={showTermlyBreakdown}>
                        <ConditionalFragment showIf={!props.overviewOnly}>
                            <Col xs={12} xl={6}>
                                <TimelineComparisonCard {...props} />
                            </Col>
                        </ConditionalFragment>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!props.overviewOnly}>
                        <ConditionalFragment showIf={props.allowCrossSubjectComparison}>
                            {
                                !!props.model.subjectId ? (
                                    <Col xs={12}>
                                        <SubjectComparisonCard {...props} />
                                    </Col>
                                ) : null
                            }
                        </ConditionalFragment>

                        <ConditionalFragment showIf={props.allowCrossSchoolComparison}>
                            {
                                (!projectSettings.questionSetType.trustTargets || schoolComparisonOpen) ? (
                                    <Col xs={12}>
                                        <SchoolComparisonCard {...props} />
                                    </Col>
                                ) : null
                            }
                        </ConditionalFragment>
                    </ConditionalFragment>
                </Row>
            </div>
        </>
    );
};

export const Dashboard = withContainer(DashboardContainer)(DashboardUi);
