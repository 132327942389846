import * as React from 'react';
import { ContainerComponentProps } from "react-withcontainer";
import { connect } from 'react-redux';
import { AppState } from '../store';
import { UserState } from '../store/user';
import { Trust } from '../api/models/Trust';

export interface HomeContainerProps extends ContainerComponentProps<HomeUiProps> {
    user: UserState,
    loadModel: (id: string) => Promise<any>,
    loadTrustFromSubscription: (id: string) => Promise<Trust>
}

export interface HomeUiProps {
    user: UserState,
}

export const _HomeContainer = (props: HomeContainerProps) => {
    const { component, loadModel, user, ...rest } = props;

    const Component = component;
    return (
        <Component {...rest} user={user} />
    );
};


export const HomeContainer = connect(
    // mapStateToProps
    (state: AppState) => ({
        user: state.user
    })
)(_HomeContainer);
