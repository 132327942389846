import * as React from 'react';
import { FormGroup, Row, Col, Button, CardHeader, Card, CardBody, Badge } from 'reactstrap';
import { Strength } from '../../../api/models/Strength';
import { ValidationErrors } from 'pojo-validator';
import { Guid } from 'guid-string';
import { Topic } from '../../../api/models/Topic';
import { Question } from '../../../api/models/Question';
import { Questionaire } from '../../../api/models/Questionaire';
import { ProjectTopic } from '../../../api/models/ProjectTopic';
import { ProjectQuestionaire } from '../../../api/models/ProjectQuestionaire';
import { Link } from 'react-router-dom';
import { Section } from '../../../api/models/Section';
import { ProjectSection } from '../../../api/models/ProjectSection';
import { Impact } from '../../../api/models/Impact';
import { AnonymousQuestionResponse } from '../../../api/models/AnonymousQuestionResponse';
import { isNullOrUndefined } from 'util';
import { getOperationalLevels, OperationalLevel } from '../../../utilities/getOperationalLevels';
import { useProjectSettings } from '../../../api/useProjectSettings';
import { ConditionalFragment } from 'react-conditionalfragment';
import { School } from '../../../api/models/School';
import { Video } from '../../../api/models/Video';
import { Consultant } from '../../../api/models/Consultant';
import { BlobUrl } from '../../../api/models/BlobUrl';
import { VideoTile } from '../../videos/VideoTile';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../utilities/replacePlaceholders';

export interface StrengthUiProps {
    model: Strength,
    changeModel: (changes: Partial<Strength>) => void,
    validate: (fieldsToCheck?: Array<string>) => boolean,
    validationErrors: ValidationErrors,

    save: () => Promise<boolean>,
    isSaving: boolean,

    remove: () => void,

    topic: Topic | undefined,
    question: Question | undefined,
    projectTopic: ProjectTopic | undefined,
    section: Section | undefined,
    projectSection: ProjectSection | undefined,
    questionaire: Questionaire | undefined,
    projectQuestionaire: ProjectQuestionaire | undefined,
    impact: Impact | undefined,
    questionResponses: Array<AnonymousQuestionResponse>,

    hasPermission: boolean,

    showSchoolTag: boolean,
    school: School | undefined,

    awardTagId: string | undefined,

    video: Video | undefined,
    consultant: Consultant | undefined,
    videoThumbnailBlob: BlobUrl | undefined,
    consultantThumbnailBlob: BlobUrl | undefined,
    watchVideo: () => void,

    placeholderReplacementMode: PlaceholderReplacementMode,
}

export const StrengthUi = (props: StrengthUiProps) => {
    const [originalDescription, setOriginalDescription] = React.useState<string | undefined>();
    const projectSettings = useProjectSettings(props.model && props.model.projectId || '', props.awardTagId);

    // Switch in and out of edit mode.
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const toggleEditing = React.useCallback(() => {
        setIsEditing(prevState => !prevState);
    }, [setIsEditing])

    const onSave = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        // Store the new value as the reset point for any future edits
        setOriginalDescription(props.model.description);

        toggleEditing();
    }, [props.save, setOriginalDescription, toggleEditing]);

    // Cancel an edit.
    const onCancel = React.useCallback(() => {
        props.changeModel({ description: originalDescription });

        toggleEditing();
    }, [props.changeModel, originalDescription, toggleEditing]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    let average = React.useMemo(() => {
        // If we have no items then return everything as 0.
        if (!props.questionResponses.length) {
            return 0;
        }

        // Work out the average, highest, and lowest responses.
        let total = 0;
        let count = 0;
        for (let item of props.questionResponses) {
            total += item.scale;
            ++count;
        }

        let average = total / count;
        return average
    }, [props.questionResponses]);

    // Work out the text to show for the operational level.
    const operationalLevel = React.useMemo(() => {
        let operationalLevels = getOperationalLevels();

        let match: OperationalLevel | undefined = undefined;
        for (let level of operationalLevels) {
            if (level.value <= average) {
                match = level;
            }
        }

        // If we couldn't find any match (or the match is for 0) then return just the average.
        if (!match || match.value == 0) {
            return average.toFixed(0);
        }

        // If we have an exact match then return it without a decimal place.
        if (match.value == average) {
            return `${average.toFixed(0)} - ${match.text}`
        }

        // Otherwise return with a decimal place.
        return `${average.toFixed(1)} - ${match.text}`
    }, [props.model, average]);
    
    // Save the original description the first time we see one.
    React.useEffect(() => {
        if (!isNullOrUndefined(originalDescription)) {
            return;
        }

        if (!props.model) {
            return;
        }

        setOriginalDescription(props.model.description);
    }, [props.model, originalDescription]);

    if (!props.model) {
        return (<></>);
    }

    return (
        <Card className="Strength-for-response">
            <CardBody>
                <Row noGutters>
                    <Col>
                        <p>
                            {replacePlaceholders(props.model.description, props.placeholderReplacementMode)}
                            <ConditionalFragment showIf={props.showSchoolTag && !!props.school}>
                                <div>
                                    <Badge color="dark">
                                        {props.school && props.school.name || ''}
                                    </Badge>
                                </div>
                            </ConditionalFragment>
                        </p>
                        <p className="text-muted">
                            <small>
                                {
                                    Guid.isEmpty(props.model.questionId) ? (
                                        <>Strength raised against the whole <Link to={`${projectSettings.baseRoute}/topic/${props.projectTopic && props.projectTopic.id || ''}`}>{props.topic && props.topic.name || ''}</Link> topic</>
                                    ) : props.questionaire ? (
                                            <>This strength concerns the following question: <Link to={`${projectSettings.baseRoute}/showQuestion/${props.model.questionId}`}>{props.question && props.question.name ? `${replacePlaceholders(props.question.name, props.placeholderReplacementMode)} [${props.question.questionNumber}]` : ''}</Link> (part of the <Link to={`${projectSettings.baseRoute}/questionnaire/${props.projectQuestionaire && props.projectQuestionaire.id || ''}`}>{replacePlaceholders(props.questionaire.name) || ''}</Link> questionnaire)</>
                                            ):
                                            (
                                                <>This strength concerns the question <Link to={`${projectSettings.baseRoute}/showQuestion/${props.model.questionId}`}>{props.question && props.question.name ? `${replacePlaceholders(props.question.name, props.placeholderReplacementMode)} [${props.question.questionNumber}]` : ''}</Link></>
                                            )
                                }
                            </small>
                        </p>
                    </Col>
                    <ConditionalFragment showIf={!!props.video}>
                        <Col xs={12} md="auto">
                            <div className="video-inline-help-container">
                                <div className="text-muted">
                                    Want some advice from our expert?
                                    </div>
                                <div>
                                    <VideoTile
                                        model={props.video as Video}
                                        thumbnailBlob={props.videoThumbnailBlob}
                                        consultant={props.consultant}
                                        consultantPhotoBlob={props.consultantThumbnailBlob}
                                        watchVideo={props.watchVideo}
                                    />
                                </div>
                            </div>
                        </Col>
                    </ConditionalFragment>
                </Row>
            </CardBody>
        </Card>
   );
};
