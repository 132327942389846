import * as React from 'react';
import { Card as BootstrapCard, CardTitle, CardBody, Row, Col, Container, CardFooter, Button, Input } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadMoreWaypoint } from './LoadMoreWaypoint';

export interface InfiniteScrollingListProps {
    loadData: { (page: number): void },
    nextPage: number,
    children: any,
    wrapWaypoint?: (Waypoint: JSX.Element) => JSX.Element,
}

/**
 * Infinate scrolling list that calls loadData(nextPage) each time a page needs to be loaded.
 * @param props
 */
export const InfiniteScrollingList = (props: InfiniteScrollingListProps) => {
    const [canLoadData, setCanLoadData] = React.useState<boolean>(true);

    const loadPage = (page: number) => {
        // If we are already loading then do nothing.
        if (!canLoadData && page > 0) {
            return;
        }

        // Load the data using the callback.
        setCanLoadData(false);
        props.loadData(page);
        setCanLoadData(true);
    };

    if (!props.nextPage) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
            );
    }


    let waypoint = (<LoadMoreWaypoint nextPage={props.nextPage} loadPage={ loadPage } />);
    if (props.wrapWaypoint) {
        waypoint = props.wrapWaypoint(waypoint);
    }
    
    return (
        <React.Fragment>
            {props.children}
            {waypoint}
        </React.Fragment>
    );
};