import { ServiceResolver, IServiceCollectionBuilder } from "inject-typesafe";
import { Repository } from "pojo-repository";
import { User } from "./models/User";
import { UserProfile } from "./models/UserProfile";
import { LoadPageResult } from "../components/containers/common/basicContainers/BasicPagedListContainer";
import { isNullOrUndefined } from "util";
import { Impact } from "./models/Impact";
import { Blob } from "./models/Blob";
import { Project } from "./models/Project";
import { ProjectQuestionaire } from "./models/ProjectQuestionaire";
import { ProjectQuestionaireRespondant } from "./models/ProjectQuestionaireRespondant";
import { ProjectReviewer } from "./models/ProjectReviewer";
import { ProjectSection } from "./models/ProjectSection";
import { ProjectTopic } from "./models/ProjectTopic";
import { Question } from "./models/Question";
import { Questionaire } from "./models/Questionaire";
import { QuestionChoice } from "./models/QuestionChoice";
import { QuestionResponse } from "./models/QuestionResponse";
import { QuestionSet } from "./models/QuestionSet";
import { Risk } from "./models/Risk";
import { School } from "./models/School";
import { Section } from "./models/Section";
import { Subscription } from "./models/Subscription";
import { SubscriptionLicense } from "./models/SubscriptionLicense";
import { Topic } from "./models/Topic";
import { Trust } from "./models/Trust";
import { ProjectAnalysisLevel } from "./models/ProjectAnalaysisLevel";
import { AmbidectHttpRestRepository } from "../services/AmbidectHttpRestRepository";
import { ProjectReviewerRestriction } from "./models/ProjectReviewerRestriction";
import { Page } from "./models/Page";
import { GrowthPlan } from "./models/GrowthPlan";
import { Tag } from "./models/Tag";
import { QuestionTag } from "./models/QuestionTag";
import { ActionUserTask } from "./models/ActionUserTask";
import { Action } from "./models/Action";
import { SchoolTerm } from "./models/SchoolTerm";
import { AppServicesCore } from "../configure/configureServicesCore";
import { SchoolTag } from "./models/SchoolTag";
import { SchoolEvidence } from "./models/SchoolEvidence";
import { QuestionResponseSchoolEvidence } from "./models/QuestionResponseSchoolEvidence";
import { SchoolTagCertificate } from "./models/SchoolTagCertificate";
import { AwardTag } from "./models/AwardTag";
import { Consultant } from "./models/Consultant";
import { ConsultantReferral } from "./models/ConsultantReferral";
import { Video } from "./models/Video";
import { VideoTag } from "./models/VideoTag";
import { VideoVideoTag } from "./models/VideoVideoTag";
import { VideoView } from "./models/VideoView";
import { VideoResource } from "./models/VideoResource";
import { UserProfileAwardTag } from "./models/UserProfileAwardTag";
import { Strength } from "./models/Strength";
import { CustomReport } from "./models/CustomReport";
import { SavedReport } from "./models/SavedReport";
import { TrialSignUp } from "./models/TrialSignUp";
import { HelpRequest } from "./models/HelpRequest";
import { AwardTagTag } from "./models/AwardTagTag";
import { Subject } from "./models/Subject";
import { SubjectTag } from "./models/SubjectTag";

// Main REST API with respositories, view models, and actions.
export interface ApiService {
    actions: {
        repository: ServiceResolver<Repository<Action, string>>,
        viewModels: {
            edit: ServiceResolver<(trustId: string, schoolId?: string) => Promise<any>>,
        }
    },
    actionUserTasks: {
        repository: ServiceResolver<Repository<ActionUserTask, string>>,
    },
    blobs: {
        repository: ServiceResolver<Repository<Blob, string>>,
        getBlobUrl: ServiceResolver<(blobId: string) => Promise<string>>,
    },
    customReports: {
        repository: ServiceResolver<Repository<CustomReport, string>>,
        viewModels: {
            reportManager: ServiceResolver<(schoolId: string) => Promise<any>>,
        }
    },
    growthPlans: {
        repository: ServiceResolver<Repository<GrowthPlan, string>>,
        viewModels: {
            loadTrustGrowthPlan: ServiceResolver<(trustId: string) => Promise<any>>,
        }
    },
    impacts: {
        repository: ServiceResolver<Repository<Impact, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
        }
    },
    subjects: {
        repository: ServiceResolver<Repository<Subject, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            edit: ServiceResolver<(id: string) => Promise<any>>,
            overview: ServiceResolver<(schoolId: string, subjectId: string) => Promise<any>>,
        }
    },
    schoolTagCertificates: {
        repository: ServiceResolver<Repository<SchoolTagCertificate, string>>,
        actions: {
            submitCertificate: ServiceResolver<(projectId: string, awardTagId: string, awardName: string, awardingOrganisationName: string) => Promise<SchoolTagCertificate>>,
            claimCertificate: ServiceResolver<(projectId: string, awardTagId: string, awardName: string, awardingOrganisationName: string) => Promise<SchoolTagCertificate>>,
            markCertificate: ServiceResolver<(projectId: string, awardTagId: string, awardName: string, awardingOrganisationName: string, isPass: boolean, certificateNumber: string, feedback: string) => Promise<SchoolTagCertificate>>,
        },
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            certificate: ServiceResolver<(schoolId: string, awardTagId: string) => Promise<any>>,
        },
    },
    pages: {
        repository: ServiceResolver<Repository<Page, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            edit: ServiceResolver<(id: string | undefined) => Promise<any>>,
            page: ServiceResolver<(id: string | undefined) => Promise<any>>,
        },
        actions: {
            newVersion: ServiceResolver<(id: string, isMajor: boolean) => Promise<Page>>,
        }
    },
    projects: {
        repository: ServiceResolver<Repository<Project, string>>,

        viewModels: {
            create: ServiceResolver<(subscriptionId: string | undefined) => Promise<any>>,
            dynamicSidebar: ServiceResolver<(id: string | undefined, awardTagId: string | undefined) => Promise<any>>,
            projectsDropdown: ServiceResolver<(subscriptionId: string, userId: string) => Promise<any>>,
            home: ServiceResolver<(id: string | undefined) => Promise<any>>,
            progress: ServiceResolver<(id: string | undefined, awardTagId: string | undefined, tier: string | undefined) => Promise<any>>,
            list: ServiceResolver<(subscriptionId: string | undefined, completed: boolean | undefined, deleted: boolean | undefined, page: number, search?: string) => Promise<LoadPageResult<any>>>,
            delete: ServiceResolver<(id: string) => Promise<any>>,
            dashboard: ServiceResolver<(id: string | undefined, awardTagId: string | undefined, analysisLevel: ProjectAnalysisLevel, sectionOriginKey: string | undefined, topicOriginKey: string | undefined, questionaireOriginKey: string | undefined) => Promise<any>>,
            showQuestion: ServiceResolver<(id: string, questionKey: string) => Promise<any>>,
            lastAccessed: ServiceResolver<(id: string) => Promise<string>>,
            executiveSummary: ServiceResolver<(id: string, awardTagId: string | undefined, reportSettingsJson: string, includeBreakdown?: boolean) => Promise<any>>,
            reviewers: ServiceResolver<(id: string) => Promise<any>>,
            projectHistory: ServiceResolver<(id: string) => Promise<any>>,
            hasAnyProjects: ServiceResolver<(subscriptionId: string | undefined) => Promise<boolean>>,
            currentProjectFor: ServiceResolver<(options: { trustId: string, schoolId?: string, questionSetName: string, createIfMissing?: boolean, subjectId?: string, }) => Promise<Project | null | undefined>>,
            projectAndQuestionSet: ServiceResolver<(id: string) => Promise<{ project: Project, questionSet: QuestionSet }>>,
            editExpectations: ServiceResolver<(id: string | undefined) => Promise<any>>,
            projectSections: ServiceResolver<(id: string, awardTag: string) => Promise<any>>,
            projectSectionsAndTopics: ServiceResolver<(id: string, awardTag: string) => Promise<any>>,
        },

        actions: {
            generateProject: ServiceResolver<(id: string) => Promise<boolean>>,
            recoverProject: ServiceResolver<(id: string) => Promise<boolean>>
        }
    },
    projectQuestionaires: {
        repository: ServiceResolver<Repository<ProjectQuestionaire, string>>,
        viewModels: {
            edit: ServiceResolver<(id: string) => Promise<any>>,
            respond: ServiceResolver<(id: string, projectQuestionaireRespondantId: string) => Promise<any>>,
            responses: ServiceResolver<(id: string) => Promise<any>>,
        },
        actions: {
            sendInvites: ServiceResolver<(id: string) => Promise<boolean>>,
            importCsvBlob: ServiceResolver<(id: string, blobId: string) => Promise<boolean>>,
        }
    },
    projectQuestionaireRespondants: {
        repository: ServiceResolver<Repository<ProjectQuestionaireRespondant, string>>,
    },
    projectReviewers: {
        repository: ServiceResolver<Repository<ProjectReviewer, string>>,
        actions: {
            sendEmail: ServiceResolver<(userId: string, projectId: string) => Promise<boolean>>,
        }
    },
    projectSections: {
        repository: ServiceResolver<Repository<ProjectSection, string>>,
    },
    projectReviewerRestrictions: {
        repository: ServiceResolver<Repository<ProjectReviewerRestriction, string>>,
        viewModels: {
            restrictionsModal: ServiceResolver<(questoinSetId: string, subscriptionId: string) => Promise<any>>,
        }
    },
    projectTopics: {
        repository: ServiceResolver<Repository<ProjectTopic, string>>,
        viewModels: {
            edit: ServiceResolver<(id: string, awardTagId: string | undefined, tier: string | undefined) => Promise<any>>,
        }
    },
    questions: {
        repository: ServiceResolver<Repository<Question, string>>,
        viewModels: {
            getTrustSEFQuestions: ServiceResolver<() => Promise<any>>,
            getTrustDuediligenceQuestions: ServiceResolver<() => Promise<any>>,
            getSchoolSEFQuestions: ServiceResolver<() => Promise<any>>,
            getSchoolDuediligenceQuestions: ServiceResolver<() => Promise<any>>,
        }
    },
    questionaires: {
        repository: ServiceResolver<Repository<Questionaire, string>>,
    },
    questionChoices: {
        repository: ServiceResolver<Repository<QuestionChoice, string>>,
    },
    questionResponses: {
        repository: ServiceResolver<Repository<QuestionResponse, string>>,
    },
    questionResponseSchoolEvidences: {
        repository: ServiceResolver<Repository<QuestionResponseSchoolEvidence, string>>,
    },
    questionTags: {
        repository: ServiceResolver<Repository<QuestionTag, string>>,
    },
    questionSets: {
        repository: ServiceResolver<Repository<QuestionSet, string>>,
        viewModels: {
            edit: ServiceResolver<(id: string | undefined) => Promise<any>>,
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            create: ServiceResolver<(subscriptionId: string) => Promise<any>>,
            listForSubscription: ServiceResolver<(subscriptionId: string | undefined, page: number, search?: string) => Promise<LoadPageResult<any>>>,
        },
        actions: {
            newVersion: ServiceResolver<(id: string, isMajor: boolean) => Promise<QuestionSet>>,
            copy: ServiceResolver<(id: string, newSubscriptionId?: string) => Promise<QuestionSet>>,
            migrateCurrentProjectsToNewVersion: ServiceResolver<(id: string) => Promise<boolean>>,
        }
    },
    risks: {
        repository: ServiceResolver<Repository<Risk, string>>,
        viewModels: {
            riskReport: ServiceResolver<(id: string) => Promise<any>>,
        }
    },
    savedReports: {
        repository: ServiceResolver<Repository<SavedReport, string>>,
    },
    strengths: {
        repository: ServiceResolver<Repository<Strength, string>>,
        viewModels: {
            strengthReport: ServiceResolver<(id: string) => Promise<any>>,
        }
    },
    schools: {
        repository: ServiceResolver<Repository<School, string>>,
        viewModels: {
            edit: ServiceResolver<(id: string | undefined, subscriptionId: string | undefined, initialTrustId: string | undefined) => Promise<any>>,
            list: ServiceResolver<(subscriptionId: string | undefined, page: number, search?: string) => Promise<LoadPageResult<any>>>,
            schoolsDropdown: ServiceResolver<(subscriptionId: string, userId: string) => Promise<any>>,
            potentialSchools: ServiceResolver<(trustId: string | undefined, searchText?: string) => Promise<Array<School>>>,
            editPotentialSchool: ServiceResolver<(trustId: string, schoolId: string) => Promise<any>>,
            getFirstSchoolFromTrust: ServiceResolver<(trustId: string) => Promise<School | null>>,
            overview: ServiceResolver<(id: string) => Promise<any>>,
            sidebar: ServiceResolver<(id: string) => Promise<any>>,
            readingReviewOverview: ServiceResolver<(id: string) => Promise<any>>,
        }
    },
    schoolTags: {
        repository: ServiceResolver<Repository<SchoolTag, string>>,
    },
    schoolTerms: {
        repository: ServiceResolver<Repository<SchoolTerm, string>>,
        viewModels: {
            edit: ServiceResolver<(id?: string) => Promise<SchoolTerm>>,
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<SchoolTerm>>>,
        }
    },
    sections: {
        repository: ServiceResolver<Repository<Section, string>>,
    },
    subscriptions: {
        repository: ServiceResolver<Repository<Subscription, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            edit: ServiceResolver<(id: string | undefined) => Promise<any>>,
            manage: ServiceResolver<(id: string) => Promise<any>>,
            getSubscriptionFromTrust: ServiceResolver<(trustId: string) => Promise<Subscription>>,
            getSubscriptionFromSchool: ServiceResolver<(schoolId: string) => Promise<Subscription>>,
            getSubscriptionAndLicenseFromSchool: ServiceResolver<(schoolId: string) => Promise<{ subscription: Subscription | null, license: SubscriptionLicense | null }>>,
       }
    },
    subscriptionLicenses: {
        repository: ServiceResolver<Repository<SubscriptionLicense, string>>,
    },
    tags: {
        repository: ServiceResolver<Repository<Tag, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<Tag>>>,
            questionTags: ServiceResolver<(questionSetId: string) => Promise<Array<QuestionTag>>>
        },
        actions: {
            applyTagsToSchool: ServiceResolver<(id: string, tags: Array<Tag>) => Promise<boolean>>,
            applyTagsToQuestions: ServiceResolver<(questionSetId: string, questionTags: Array<QuestionTag>) => Promise<boolean>>
        }
    },
    awardTags: {
        repository: ServiceResolver<Repository<AwardTag, string>>,
        viewModels: {
            edit: ServiceResolver<(id: string) => Promise<any>>,
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<AwardTag>>>,
        },
    },
    topics: {
        repository: ServiceResolver<Repository<Topic, string>>,
    },
    trusts: {
        repository: ServiceResolver<Repository<Trust, string>>,
        viewModels: {
            edit: ServiceResolver<(id: string | undefined, subscriptionId: string | undefined) => Promise<any>>,
            list: ServiceResolver<(subscriptionId: string | undefined, page: number, search?: string) => Promise<LoadPageResult<any>>>,
            getTrustFromSubscription: ServiceResolver<(subscriptionId: string) => Promise<Trust>>,
            overview: ServiceResolver<(id: string | undefined, subscriptionId: string | undefined) => Promise<any>>,
            sidebar: ServiceResolver<(id: string | undefined) => Promise<any>>,
        }
    },
    userProfiles: {
        repository: ServiceResolver<Repository<UserProfile, string>>,
    },
    users: {
        repository: ServiceResolver<Repository<User>>,

        viewModels: {
            join: ServiceResolver<(user: string, token: string) => Promise<any>>,
            edit: ServiceResolver<(id: string | undefined) => Promise<any>>,
            subscriptionUserEdit: ServiceResolver<(id: string | undefined, subscriptionId: string) => Promise<any>>,
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            listAllSubscriptionUsers: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            subscriptionUserList: ServiceResolver<(subscriptionId: string | undefined, page: number, search?: string) => Promise<LoadPageResult<any>>>,
            editSchoolUser: ServiceResolver<((id: string, trustId: string) => Promise<any>)>,
            listSchoolUsers: ServiceResolver<(id: string, page: number, search?: string) => Promise<LoadPageResult<any>>>,
            listTrustUsers: ServiceResolver<(trustId: string, page: number, search?: string) => Promise<LoadPageResult<any>>>,
            editTrustUser: ServiceResolver<(id: string, trustId: string) => Promise<any>>,
            schoolUserEditSchoolUser: ServiceResolver<(id: string, schoolId: string) => Promise<any>>
        }
    },
    reports: {
        viewModels: {
            adminReports: ServiceResolver<() => Promise<any>>
        }
    },
    schoolEvidence: {
        repository: ServiceResolver<Repository<SchoolEvidence, string>>,
        viewModels: {
            evidenceLocker: ServiceResolver<(schoolId: string, subjectId?: string) => Promise<any>>,
        },
        actions: {
            rename: ServiceResolver<(schoolId: string, oldName: string, newName: string) => Promise<any>>,
        }
    },
    consultants: {
        repository: ServiceResolver<Repository<Consultant, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            edit: ServiceResolver<(id: string) => Promise<any>>,
        }
    },
    consultantReferrals: {
        repository: ServiceResolver<Repository<ConsultantReferral, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
        }
    },
    videos: {
        repository: ServiceResolver<Repository<Video, string>>,
        viewModels: {
            browse: ServiceResolver<() => Promise<any>>,
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
            edit: ServiceResolver<(id: string) => Promise<any>>,
            watch: ServiceResolver<(id: string) => Promise<any>>,
        }
    },
    videoTags: {
        repository: ServiceResolver<Repository<VideoTag, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<VideoTag>>>,
        }
    },
    videoVideoTags: {
        repository: ServiceResolver<Repository<VideoVideoTag, string>>,
    },
    videoViews: {
        repository: ServiceResolver<Repository<VideoView, string>>,
        viewModels: {
            list: ServiceResolver<(page: number, search?: string) => Promise<LoadPageResult<any>>>,
        }
    },
    videoResources: {
        repository: ServiceResolver<Repository<VideoResource, string>>,
    },
    userProfileAwardTags: {
        repository: ServiceResolver<Repository<UserProfileAwardTag, string>>,
    },
    trial: {
        actions: {
            signUp: ServiceResolver<(model: TrialSignUp) => Promise<any>>,
        },
    },
    helpRequests: {
        repository: ServiceResolver<Repository<HelpRequest, string>>,
        viewModels: {
            edit: ServiceResolver<(id?: string) => Promise<any>>,
        }
    },
    awardTagTags: {
        repository: ServiceResolver<Repository<AwardTagTag, string>>,
    },
    subjectTags: {
        repository: ServiceResolver<Repository<SubjectTag, string>>,
    },
}

/**
 * Configure services used by the app that need global configuration.
 * @param store
 */
export function createApiService(builder: IServiceCollectionBuilder<AppServicesCore>): ApiService {
    return ({
        actions: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/actions', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (trustId: string, schoolId?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/actions/viewModels/edit?trustId=${trustId}&schoolId=${schoolId || ''}`);
                })),
            }
        },
        actionUserTasks: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/actionUserTasks', services.apiFetch())),
        },
        growthPlans: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/growthPlan', services.apiFetch())),
            viewModels: {
                loadTrustGrowthPlan: builder.scoped(services => (async (trustId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/growthPlan/viewModels/loadTrustGrowthPlan/${encodeURIComponent(trustId)}`, undefined, { expireOnEvents: ['api/projects', 'api/trusts', 'api/schools', 'api/questionSets'] });
                })),
            },
        },
        projects: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/projects', services.apiFetch())),

            viewModels: {
                create: builder.scoped(services => (async (subscriptionId: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/create/?subscriptionId=${encodeURIComponent(subscriptionId ? subscriptionId : '')}`);
                })),
                dynamicSidebar: builder.scoped(services => (async (id: string | undefined, awardTagId: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/dynamicSidebar/${encodeURIComponent(id ? id : 'defaults')}?awardTagId=${awardTagId || ''}`, undefined, { expireOnEvents: ['api/projects', 'api/projectSections', 'api/projectTopics'] });
                })),
                projectsDropdown: builder.scoped(services => (async (subscriptionId: string, userId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/projectsDropdown/?subscriptionId=${encodeURIComponent(subscriptionId)}&userId=${encodeURIComponent(userId)}&`, undefined, { expireOnEvents: ['api/projects'] });
                })),
                home: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/home/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/projects', 'api/projectSections', 'api/projectTopics'] });
                })),
                progress: builder.scoped(services => (async (id: string | undefined, awardTagId: string | undefined, tier: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/progress/${encodeURIComponent(id ? id : 'defaults')}?awardTagId=${awardTagId || ''}&tier=${tier || ''}`, undefined, { expireOnEvents: ['api/projects', 'api/projectSections', 'api/projectTopics'] });
                })),
                list: builder.scoped(services => (async (subscriptionId: string | undefined, completed: boolean | undefined, deleted: boolean | undefined,  page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/projects/viewModels/list?search=${encodeURIComponent(search || '')}&subscriptionId=${subscriptionId}&completed=${isNullOrUndefined(completed) ? '' : completed}&deleted=${isNullOrUndefined(deleted) ? '' : deleted}`, undefined, { expireOnEvents: ['api/projects', 'api/trusts', 'api/schools'] });
                })),
                delete: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/delete/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/projects', 'api/trusts', 'api/schools'] });
                })),
                dashboard: builder.scoped(services => (async (id: string | undefined, awardTagId: string | undefined, analysisLevel: ProjectAnalysisLevel, sectionOriginKey: string | undefined, topicOriginKey: string | undefined, questionaireOriginKey: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/dashboard/${encodeURIComponent(id ? id : 'defaults')}?awardTagId=${awardTagId || ''}&analysisLevel=${analysisLevel}&sectionOriginKey=${encodeURIComponent(sectionOriginKey ? sectionOriginKey : '')}&topicOriginKey=${encodeURIComponent(topicOriginKey ? topicOriginKey : '')}&questionaireOriginKey=${encodeURIComponent(questionaireOriginKey ? questionaireOriginKey : '')}`, undefined, { expireOnEvents: ['api/projects', 'api/projectSections', 'api/projectTopics', 'api/questionResponse'] });
                })),
                showQuestion: builder.scoped(services => (async (id: string, questionKey: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/showQuestion/${encodeURIComponent(id)}?questionKey=${encodeURIComponent(questionKey)}`, undefined, { expireOnEvents: ['api/projects', 'api/questions'] });
                })),
                lastAccessed: builder.scoped(services => (async (id: string): Promise<string> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/lastAccessed/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/projects', 'api/questions'] });
                })),
                executiveSummary: builder.scoped(services => (async (id: string, awardTagId: string | undefined, reportSettingsJson: string, includeBreakdown?: boolean): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.post(`api/projects/viewModels/executiveSummary/${encodeURIComponent(id)}?awardTagId=${awardTagId || ''}&includeBreakdown=${includeBreakdown ? true : false}`, reportSettingsJson);
                })),
                reviewers: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/reviewers/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/projects', 'api/projectReviewers'] });
                })),
                projectHistory: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/projectHistory/${encodeURIComponent(id)}`, undefined, { expireOnEvents: [] });
                })),
                hasAnyProjects: builder.scoped(services => (async (subscriptionId: string | undefined): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/hasAnyProjects/?subscriptionId=${encodeURIComponent(subscriptionId || '')}`, undefined, { expireOnEvents: [] });
                })),

                currentProjectFor: builder.scoped(services => (async (options: { trustId: string, schoolId?: string, questionSetName: string, createIfMissing?: boolean, subjectId?: string, }): Promise<Project | null | undefined> => {
                    const api = services.apiFetch();
                    try {
                        return await api.get(`api/projects/viewModels/currentProjectFor?trustId=${encodeURIComponent(options.trustId)}&schoolId=${encodeURIComponent(options.schoolId || '')}&questionSetName=${encodeURIComponent(options.questionSetName)}&subjectId=${encodeURIComponent(options.subjectId || '')}&createIfMissing=${isNullOrUndefined(options.createIfMissing) ? '' : options.createIfMissing ? 'true' : 'false'}`, undefined, { expireOnEvents: [] });
                    } catch (err) {
                        return null;
                    }
                })),

                projectAndQuestionSet: builder.scoped(services => (async (id: string): Promise<{ project: Project, questionSet: QuestionSet }> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/projectAndQuestionSet/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/projects'] });
                })),
                editExpectations: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/editExpectations/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/projects', 'api/projectSections', 'api/projectTopics'] });
                })),

                projectSections: builder.scoped(services => (async (id: string, awardTag: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/projectSections/${encodeURIComponent(id)}?${`awardTag=` + encodeURIComponent(awardTag)}`, undefined, { expireOnEvents: ['api/projects', 'api/projectSections', 'api/projectTopics'] });
                })),
                projectSectionsAndTopics: builder.scoped(services => (async (id: string, awardTag: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projects/viewModels/projectSectionsAndTopics/${encodeURIComponent(id)}?${`awardTag=` + encodeURIComponent(awardTag)}`, undefined, { expireOnEvents: ['api/projects', 'api/projectSections', 'api/projectTopics'] });
                })),
            },

            actions: {
                generateProject: builder.scoped(services => (async (id: string): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.post(`api/projects/actions/generateProject/${encodeURIComponent(id)}`, {});
                })),
                recoverProject: builder.scoped(services => (async (id: string): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.post(`api/projects/actions/recoverProject/${encodeURIComponent(id)}`, {});
                }))
            }
        },
        impacts: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/impacts', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/impacts/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/impacts'] });
                })),
            }
        },
        subjects: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/subjects', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/subjects/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/subjects'] });
                })),
                list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/subjects/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/subjects'] });
                })),
                overview: builder.scoped(services => (async (schoolId: string, subjectId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/subjects/viewModels/overview/?schoolId=${encodeURIComponent(schoolId ? schoolId : '')}&subjectId=${encodeURIComponent(subjectId ? subjectId : '')}`, undefined, { expireOnEvents: ['api/schools'] });
                })),
            }
        },
        schoolTagCertificates: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/schoolTagCertificates', services.apiFetch())),
            actions: {
                submitCertificate: builder.scoped(services => (async (projectId: string, awardTagId: string, awardName: string, awardingOrganisationName: string): Promise<SchoolTagCertificate> => {
                    const api = services.apiFetch();
                    return await api.post<SchoolTagCertificate>(`api/schoolTagCertificates/actions/submitCertificate/${projectId}?awardTagId=${awardTagId}&awardingOrganisationName=${encodeURIComponent(awardingOrganisationName)}&awardName=${encodeURIComponent(awardName)}`, undefined);
                })),
                claimCertificate: builder.scoped(services => (async (projectId: string, awardTagId: string, awardName: string, awardingOrganisationName: string): Promise<SchoolTagCertificate> => {
                    const api = services.apiFetch();
                    return await api.post<SchoolTagCertificate>(`api/schoolTagCertificates/actions/claimCertificate/${projectId}?awardTagId=${awardTagId}&awardingOrganisationName=${encodeURIComponent(awardingOrganisationName)}&awardName=${encodeURIComponent(awardName)}`, undefined);
                })),
                markCertificate: builder.scoped(services => (async (projectId: string, awardTagId: string, awardName: string, awardingOrganisationName: string, isPass: boolean, certificateNumber: string, feedback: string): Promise<SchoolTagCertificate> => {
                    const api = services.apiFetch();
                    return await api.post<SchoolTagCertificate>(`api/schoolTagCertificates/actions/markCertificate/${projectId}?awardTagId=${awardTagId}&awardingOrganisationName=${encodeURIComponent(awardingOrganisationName)}&awardName=${encodeURIComponent(awardName)}&isPass=${encodeURIComponent(isPass)}&certificateNumber=${encodeURIComponent(certificateNumber)}&feedback=${encodeURIComponent(feedback)}`, undefined);
                })),
            },
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/schoolTagCertificates/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/schoolTagCertificates'] });
                })),
                certificate: builder.scoped(services => (async (schoolId: string, awardTagId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<SchoolTagCertificate>(`api/schoolTagCertificates/viewModels/certificate?schoolId=${encodeURIComponent(schoolId || '')}&awardTagId=${encodeURIComponent(awardTagId)}`, undefined, { expireOnEvents: ['api/schoolTagCertificates'] });
                })),
            }
        },
        pages: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/pages', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/pages/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/pages'] });
                })),
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/pages/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/pages'] });
                })),
                page: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/pages/viewModels/page/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/pages'] });
                })),
            },
            actions: {
                newVersion: builder.scoped(services => (async (id: string, isMajor: boolean): Promise<Page> => {
                    const api = services.apiFetch();
                    return await api.post(`api/pages/actions/newVersion/${encodeURIComponent(id)}?isMajor=${isMajor ? 'true' : 'false'}`, {});
                })),
            }
        },
        blobs: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/blobs', services.apiFetch())),
            getBlobUrl: builder.scoped(services => (async (id: string): Promise<any> => {
                const api = services.apiFetch();
                return await api.get(`api/blobs/getBlobUrl/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/blobs'] });
            })),
        },
        projectQuestionaires: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/projectQuestionaires', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projectQuestionaires/viewModels/edit/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/projectQuestionaires', 'api/questionaires', 'api/projectQustionaireRespondants', 'api/projectQuestionaireResponses'] });
                })),
                respond: builder.scoped(services => (async (id: string, projectQuestionaireRespondantId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projectQuestionaires/viewModels/respond/${encodeURIComponent(id)}?projectQuestionaireRespondantId=${encodeURIComponent(projectQuestionaireRespondantId)}`, undefined, { expireOnEvents: ['api/projectQuestionaires', 'api/questionaires', 'api/projectQustionaireRespondants', 'api/projectQuestionaireResponses'] });
                })),
                responses: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projectQuestionaires/viewModels/responses/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/projectQuestionaires', 'api/questionaires', 'api/questions', 'api/projectQuestionaireResponses'] });
                })),
            },

            actions: {
                sendInvites: builder.scoped(services => (async (id: string): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.post(`api/projectQuestionaires/actions/sendInvites/${encodeURIComponent(id)}`, {});
                })),
                importCsvBlob: builder.scoped(services => (async (id: string ,blobId: string): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.post(`api/projectQuestionaires/actions/importCsvBlob/${encodeURIComponent(id)}?blobId=${encodeURIComponent(blobId)}`, {});
                })),
            }
        },
        projectQuestionaireRespondants: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/projectQuestionaireRespondants', services.apiFetch())),
        },
        projectReviewers: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/projectReviewers', services.apiFetch())),
            actions: {
                sendEmail: builder.scoped(services => (async (userId: string, projectId: string): Promise<boolean> => {
                    const api = services.apiFetch();

                    return await api.post(`api/projectReviewers/actions/sendEmail/${userId}/${projectId}`, {});
                })),
            }
        },
        projectReviewerRestrictions: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/projectReviewerRestrictions', services.apiFetch())),
            viewModels: {
                restrictionsModal: builder.scoped(services => (async (questionSetId: string, subscriptionId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projectReviewerRestrictions/viewModels/restrictionsModal?questionSetId=${encodeURIComponent(questionSetId)}&subscriptionId=${encodeURIComponent(subscriptionId)}`, undefined, { expireOnEvents: [] });
                })),
            }
        },
        projectSections: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/projectSections', services.apiFetch())),
        },
        projectTopics: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/projectTopics', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string, awardTagId: string | undefined, tier: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/projectTopics/viewModels/edit/${encodeURIComponent(id)}?awardTagId=${awardTagId || ''}&tier=${tier || ''}`, undefined, { expireOnEvents: ['api/projectTopics', 'api/topics', 'api/questions', 'api/questionChoices', 'api/questionReponses'] });
                })),
            }
        },
        questions: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/questions', services.apiFetch())),
            viewModels: {
                getTrustSEFQuestions: builder.scoped(services => (async (): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get('api/questions/viewmodels/getTrustSEFQuestions');
                })),
                getTrustDuediligenceQuestions: builder.scoped(services => (async (): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get('api/questions/viewModels/getTrustDuediligenceQuestions');
                })),
                getSchoolDuediligenceQuestions: builder.scoped(services => (async (): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get('api/questions/viewModels/getSchoolDuediligenceQuestions');
                })),
                getSchoolSEFQuestions: builder.scoped(services => (async (): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get('api/questions/viewModels/getSchoolSEFQuestions');
                }))
            }
        },
        questionaires: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/questionaires', services.apiFetch())),
        },
        questionChoices: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/questionChoices', services.apiFetch())),
        },
        questionResponses: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/questionResponses', services.apiFetch())),
        },
        questionResponseSchoolEvidences: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/questionResponseSchoolEvidences', services.apiFetch())),
        },
        questionTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/questionTags', services.apiFetch())),
        },
        questionSets: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/questionSets', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/questionSets/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/questionSets', 'api/sections', 'api/topics', 'api/questions', 'api/questionChoices', 'api/impacts'] });
                })),
                list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/questionSets/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/questionSets'] });
                })),
                create: builder.scoped(services => (async (subscriptionId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/questionSets/viewModels/create?subscriptionId=${encodeURIComponent(subscriptionId ? subscriptionId : '')}`, undefined, { expireOnEvents: ['api/questionSets'] });
                })),
                listForSubscription: builder.scoped(services => (async (subscriptionId: string | undefined, page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/questionSets/viewModels/listForSubscription?search=${encodeURIComponent(search || '')}&subscriptionId=${subscriptionId}`, undefined, { expireOnEvents: ['api/questionSets'] });
                })),
            },
            actions: {
                newVersion: builder.scoped(services => (async (id: string, isMajor: boolean): Promise<QuestionSet> => {
                    const api = services.apiFetch();
                    return await api.post(`api/questionSets/actions/newVersion/${encodeURIComponent(id)}?isMajor=${isMajor ? 'true' : 'false'}`, {});
                })),
                copy: builder.scoped(services => (async (id: string, newSubscriptionId?: string): Promise<QuestionSet> => {
                    const api = services.apiFetch();
                    return await api.post(`api/questionSets/actions/copy/${encodeURIComponent(id)}?newSubscriptionId=${encodeURIComponent(newSubscriptionId || '')}`, {});
                })),
                migrateCurrentProjectsToNewVersion: builder.scoped(services => (async (id: string): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.post(`api/questionSets/actions/migrateCurrentProjectsToNewVersion/${encodeURIComponent(id)}`, {});
                })),
            }
        },
        risks: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/risks', services.apiFetch())),
            viewModels: {
                riskReport: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/risks/viewModels/riskReport/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/risks', 'api/projects', 'api/questions', 'api/topics', 'api/questionaires'] });
                })),
            }
        },
        strengths: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/strengths', services.apiFetch())),
            viewModels: {
                strengthReport: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/strengths/viewModels/strengthReport/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/strengths', 'api/projects', 'api/questions', 'api/topics', 'api/questionaires'] });
                })),
            }
        },
        schools: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/schools', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string | undefined, subscriptionId: string | undefined, initialTrustId: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}?subscriptionId=${subscriptionId || ''}&initialTrustId=${initialTrustId || ''}`, undefined, { expireOnEvents: ['api/schools', 'api/trusts'] });
                })),
                list: builder.scoped(services => (async (subscriptionId: string | undefined, page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/schools/viewModels/list?search=${encodeURIComponent(search || '')}&subscriptionId=${subscriptionId}`, undefined, { expireOnEvents: ['api/schools', 'api/trusts'] });
                })),
                schoolsDropdown: builder.scoped(services => (async (subscriptionId: string, userId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/schoolsDropdown?subscriptionId=${subscriptionId}&userId=${userId}`);
                })),
                potentialSchools: builder.scoped(services => (async (trustId: string | undefined, searchText?: string): Promise<Array<School>> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/listPotentialSchools/${trustId}?search=${encodeURIComponent(searchText || '')}`);
                })),
                editPotentialSchool: builder.scoped(services => (async (trustId: string, schoolId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/editPotentialSchool?trustId=${encodeURIComponent(trustId)}&schoolId=${encodeURIComponent(schoolId)}`);
                })),
                getFirstSchoolFromTrust: builder.scoped(services => (async (trustId: string): Promise<School | null> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/getFirstSchoolFromTrust/${trustId}`);
                })),
                overview: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/overview/${encodeURIComponent(id ? id : '')}`, undefined, { expireOnEvents: ['api/schools'] });
                })),
                sidebar: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/sidebar/${encodeURIComponent(id ? id : '')}`, undefined, { expireOnEvents: ['api/schools'] });
                })),
                readingReviewOverview: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schools/viewModels/readingReviewOverview/${encodeURIComponent(id ? id : '')}`, undefined, { expireOnEvents: ['api/schools'] });
                })),
            }
        },
        schoolTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/schoolTags', services.apiFetch())),
        },
        schoolTerms: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/schoolTerms', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id?: string): Promise<SchoolTerm> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schoolTerms/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`);
                })),
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<SchoolTerm>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<SchoolTerm>>(`api/schoolTerms/viewModels/list?search=${encodeURIComponent(search || '')}`);
                })),
            }
        },
        sections: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/sections', services.apiFetch())),
        },
        subscriptions: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/subscriptions', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/subscriptions/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/subscriptions'] });
                })),
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/subscriptions/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/subscriptions'] });
                })),
                manage: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/subscriptions/viewModels/manage/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/subscriptions'] });
                })),
                getSubscriptionFromTrust: builder.scoped(services => (async (trustId: string): Promise<Subscription> => {
                    const api = services.apiFetch();
                    return await api.get(`api/subscriptions/viewModels/getSubscriptionFromTrust/${trustId}`);
                })),
                getSubscriptionFromSchool: builder.scoped(services => (async (schoolId: string): Promise<Subscription> => {
                    const api = services.apiFetch();
                    return await api.get(`api/subscriptions/viewModels/getSubscriptionFromSchool/${schoolId}`);
                })),
                getSubscriptionAndLicenseFromSchool: builder.scoped(services => (async (schoolId: string): Promise<{ subscription: Subscription | null, license: SubscriptionLicense | null }> => {
                    const api = services.apiFetch();
                    return await api.get(`api/subscriptions/viewModels/getSubscriptionAndLicenseFromSchool/${schoolId}`);
                })),
            }
        },
        subscriptionLicenses: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/subscriptionLicenses', services.apiFetch())),
        },
        //subscriptionProjectLicenses: {
        //    repository: builder.scoped(services => new AmbidectHttpRestRepository('api/subscriptionProjectLicenses', services.apiFetch())),
        //},
        tags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/tags', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<Tag>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<Tag>>(`api/tags/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/trusts'] });
                })),
                questionTags: builder.scoped(services => (async (questionSetId: string): Promise<Array<QuestionTag>> => {
                    const api = services.apiFetch();
                    return await api.get(`/api/tags/viewModels/questionTags/${questionSetId}`);
                }))
            },
            actions: {
                applyTagsToSchool: builder.scoped(services => (async (id: string, tags: Array<Tag>): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.post<boolean>(`api/tags/actions/applyTagsToSchool/${id}`, tags);
                })),
                applyTagsToQuestions: builder.scoped(services => (async (questionSetId: string, questionTags: Array<QuestionTag>): Promise<boolean> => {
                    const api = services.apiFetch();
                    return await api.post<boolean>(`api/tags/actions/applyTagsToQuestions/${questionSetId}`, questionTags);
                }))
            }
        },
        awardTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/awardTags', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/awardTags/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/awardTags'] });
                })),
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<AwardTag>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<AwardTag>>(`api/awardTags/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/awardTags'] });
                })),
            },
        },
        topics: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/topics', services.apiFetch())),
        },
        trusts: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/trusts', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string | undefined, subscriptionId: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/trusts/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}?subscriptionId=${subscriptionId || ''}`, undefined, { expireOnEvents: ['api/trusts'] });
                })),
                list: builder.scoped(services => (async (subscriptionId: string | undefined, page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/trusts/viewModels/list?search=${encodeURIComponent(search || '')}&subscriptionId=${subscriptionId}`, undefined, { expireOnEvents: ['api/trusts'] });
                })),
                getTrustFromSubscription: builder.scoped(services => (async (subscriptionId: string): Promise<Trust> => {
                    const api = services.apiFetch();
                    return await api.get(`api/trusts/viewModels/getTrustFromSubscription/${subscriptionId}`);
                })),
                overview: builder.scoped(services => (async (id: string | undefined, subscriptionId: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/trusts/viewModels/overview?id=${encodeURIComponent(id ? id : '')}&subscriptionId=${subscriptionId || ''}`, undefined, { expireOnEvents: ['api/trusts'] });
                })),
                sidebar: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/trusts/viewModels/sidebar/${encodeURIComponent(id ? id : '')}`, undefined, { expireOnEvents: ['api/trusts'] });
                })),
            }
        },
        userProfiles: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/userProfiles', services.apiFetch())),
        },
        users: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/users', services.apiFetch())),
            viewModels: {
                join: builder.scoped(services => (async (user: string, token: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/join?user=${encodeURIComponent(user)}&token=${encodeURIComponent(token)}`);
                })),
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/users', 'api/locations'] });
                })),
                subscriptionUserEdit: builder.scoped(services => (async (id: string | undefined, subscriptionId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/subscriptionUserEdit/${encodeURIComponent(id ? id : 'defaults')}?subscriptionId=${encodeURIComponent(subscriptionId)}`, undefined, { expireOnEvents: ['api/users', 'api/locations'] });
                })),
                list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/users/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/users', 'api/userProfiles'] });
                })),
                listAllSubscriptionUsers: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/users/viewModels/listAllSubscriptionUsers?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/users', 'api/userProfiles'] });
                })),
                subscriptionUserList: builder.scoped(services => (async (subscripionId: string | undefined, page: number, search?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/users/viewModels/subscriptionUserList?subscriptionId=${encodeURIComponent(subscripionId || '')}&search=${encodeURIComponent(search || '')}&page=${page}`, undefined, { expireOnEvents: ['api/users', 'api/userProfiles'] });
                })),
                editSchoolUser: builder.scoped(services => (async (id: string, trustId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/editSchoolUser/${encodeURIComponent(id)}?trustId=${encodeURIComponent(trustId)}`);
                })),
                listSchoolUsers: builder.scoped(services => (async (id: string, page: number, search?: string): Promise<LoadPageResult<any>> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/listSchoolUsers/${id}?search=${encodeURIComponent(search || '')}`);
                })),
                listTrustUsers: builder.scoped(services => (async (trustId: string, page: number, search?: string): Promise<LoadPageResult<any>> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/listTrustUsers/${trustId}?search=${encodeURIComponent(search || '') }`);
                })),
                editTrustUser: builder.scoped(services => (async (id: string, trustId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/editTrustUser/${encodeURIComponent(id)}?trustId=${encodeURIComponent(trustId)}`);
                })),
                schoolUserEditSchoolUser: builder.scoped(services => (async (id: string, schoolId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/users/viewModels/schoolUserEditSchoolUser/${encodeURIComponent(id)}?schoolId=${schoolId}`);
                })),
            }
        },
        //visibilityGroups: {
        //    repository: builder.scoped(services => new AmbidectHttpRestRepository('api/visibilityGroups', services.apiFetch())),
        //    viewModels: {
        //        list: builder.scoped(services => (async (page: number, search?: string): Promise<any> => {
        //            const api = services.apiFetch();
        //            return await api.get<LoadPageResult<any>>(`api/visibilityGroups/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined);
        //        })),
        //        edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
        //            const api = services.apiFetch();
        //            return await api.get(`api/visibilityGroups/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/visibilityGroups', 'api/visibilityTags', 'api/visibilityGroupPermissions'] });
        //        })),
        //    }
        //},
        //visibilityGroupPermissions: {
        //    repository: builder.scoped(services => new AmbidectHttpRestRepository('api/visibilityGroupPermissions', services.apiFetch())),
        //},
        //visibilityTags: {
        //    repository: builder.scoped(services => new AmbidectHttpRestRepository('api/visibilityTags', services.apiFetch())),
        //},
        reports: {
            viewModels: {
                adminReports: builder.scoped(services => (async (): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/reports/viewModels/adminReports`, undefined);
                }))
            }
        },
        schoolEvidence: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/schoolEvidences', services.apiFetch())),
            viewModels: {
                evidenceLocker: builder.scoped(services => (async (schoolId: string, subjectId?: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/schoolEvidences/viewModels/evidenceLocker?schoolId=${schoolId}&subjectId=${subjectId || ''}`);
                }))
            },
            actions: {
                rename: builder.scoped(services => (async (schoolId: string, name: string, newName: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.post(`api/schoolEvidences/actions/rename`, { schoolId: schoolId, name: name, newName: newName });
                })),
            }
        },
        consultants: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/consultants', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<any>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<Consultant>>(`api/consultants/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/consultants'] });
                })),
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/consultants/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/videos'] });
                })),
            },
        },
        consultantReferrals: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/consultantReferrals', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<any>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/consultantReferrals/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/consultantReferrals'] });
                })),
            },
        },
        videos: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/videos', services.apiFetch())),
            viewModels: {
                browse: builder.scoped(services => (async (): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get<any>(`api/videos/viewModels/browse`, undefined, { expireOnEvents: ['api/videos'] });
                })),
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/videos/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/videos'] });
                })),
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<any>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<Consultant>>(`api/videos/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/videos'] });
                })),
                watch: builder.scoped(services => (async (id: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/videos/viewModels/watch/${encodeURIComponent(id)}`, undefined, { expireOnEvents: ['api/videos'] });
                })),
            },
        },
        videoTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/videoTags', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<VideoTag>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<VideoTag>>(`api/videoTags/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/videoTags'] });
                })),
            },
        },
        videoVideoTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/videoVideoTags', services.apiFetch())),
        },
        videoViews: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/videoViews', services.apiFetch())),
            viewModels: {
                list: builder.scoped(services => (async (page: number, search?: string): Promise<LoadPageResult<any>> => {
                    const api = services.apiFetch();
                    return await api.get<LoadPageResult<any>>(`api/videoViews/viewModels/list?search=${encodeURIComponent(search || '')}`, undefined, { expireOnEvents: ['api/videoViews'] });
                })),
            },
        },
        videoResources: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/videoResources', services.apiFetch())),
        },
        userProfileAwardTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/userProfileAwardTags', services.apiFetch())),
        },
        customReports: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/customReports', services.apiFetch())),
            viewModels: {
                reportManager: builder.scoped(services => (async (schoolId: string): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/customReports/viewModels/reportManager?schoolId=${encodeURIComponent(schoolId)}`, undefined, { expireOnEvents: [] });
                })),
            }
        },
        savedReports: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/savedReports', services.apiFetch())),
        },
        trial: {
            actions: {
                signUp: builder.scoped(services => (async (model: TrialSignUp): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.post(`api/trials/signUp`, model);
                })),
            },
        },
        helpRequests: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/helpRequests', services.apiFetch())),
            viewModels: {
                edit: builder.scoped(services => (async (id: string | undefined): Promise<any> => {
                    const api = services.apiFetch();
                    return await api.get(`api/helpRequests/viewModels/edit/${encodeURIComponent(id ? id : 'defaults')}`, undefined, { expireOnEvents: ['api/helpRequests'] });
                })),
            },
            
        },
        awardTagTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/awardTagTags', services.apiFetch())),
        },
        subjectTags: {
            repository: builder.scoped(services => new AmbidectHttpRestRepository('api/subjectTags', services.apiFetch())),
        }
    });
}
