import * as React from 'react';
import { Action } from '../../api/models/Action';
import { ActionUserTask } from '../../api/models/ActionUserTask';
import { Modal, ModalHeader, Row, Col, ModalBody, FormGroup, Label, Button, ModalFooter, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { EditActionUserTask } from './EditActionUserTask';
import { ConditionalFragment } from 'react-conditionalfragment';
import moment, { Moment } from 'moment';
import { actionStates, ActionState } from '../../services/actionStates/actionStates';
import { School } from '../../api/models/School';
import { DueDateLabel } from './DueDateLabel';
import { Trust } from '../../api/models/Trust';
import { UserProfile } from '../../api/models/UserProfile';
import { ValidatedDateTimeInput } from '../shared/ValidatedDateTimeInput';
import { ElasticInput } from 'reactstrap-elasticinput';
import { Guid } from 'guid-string';
import { Link } from 'react-router-dom';
import { useProjectSettings } from '../../api/useProjectSettings';
import { AwardTag } from '../../api/models/AwardTag';
import { QuestionTag } from '../../api/models/QuestionTag';
import { actionPriorities, actionPriorityDisplayName } from '../../api/models/codeOnly/ActionPriority';

export interface EditActionModalProps {
    isOpen: boolean,
    toggle: () => void,

    model: Action,
    change: (changes: Partial<Action>) => void,
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    tasks: Array<ActionUserTask>,
    changeTask: (id: string, changes: Partial<ActionUserTask>) => void,
    addTask: () => void,
    removeTask: (id: string) => void,

    trust: Trust | undefined | null,
    schools: Array<School>,
    schoolId: string | undefined | null,

    questionText: string;
    questionLabelText: string;
    questionNumber: string;

    userProfiles: Array<UserProfile>,

    saveDebounce: () => void,

    showSchoolSelector: boolean,
    isRecommendation: boolean,
    isPriority: boolean,

    awardTagId: string | undefined,
    awardTags: Array<AwardTag>,
    questionTags: Array<QuestionTag>,
}

/**
 * Edit an action.
 * @param props
 */
export const EditActionModal = (props: EditActionModalProps) => {
    const {
        isOpen, toggle,
        model, change, remove,
        /*validate,*/ validationErrors,
        tasks, changeTask, addTask, removeTask,
        trust, schools, schoolId,
        userProfiles,
        saveDebounce,
        showSchoolSelector,
        isRecommendation,
        questionText,
        questionLabelText,
        questionNumber,
        isPriority,
        awardTagId,
        awardTags,
        questionTags,
    } = props;

    const actionState = React.useMemo(() => actionStates.findById(model.actionState), [model]);

    const projectSettings = useProjectSettings(props.model && props.model.projectId || '', awardTagId || undefined);

    // Change the state of this task
    const changeState = React.useCallback((state: ActionState) => {
        change({
            actionState: state.id,
            completedDate: state.isCompleted ? moment().toISOString() : null,
        });

        saveDebounce();
    }, [change, saveDebounce]);

    // State of one of our tasks has changed, see if we need to update our own state too.
    const taskStateChanged = React.useCallback((actionUserTaskId: string, state: ActionState) => {
        const otherIncompleteTasksCount = tasks
            .filter(item => item.id !== actionUserTaskId && !actionStates.findById(item.actionState).isCompleted)
            .length;

        if (state.isCompleted) {
            if (!actionState.isCompleted && !otherIncompleteTasksCount) {
                change({
                    actionState: actionStates.completed.id,
                    completedDate: moment().toISOString(),
                });
            }
        } else {
            if (actionState.isCompleted) {
                change({
                    actionState: actionStates.outstanding.id,
                    completedDate: null,
                });
            }
        }
    }, [tasks, actionState, change]);

    // Work out how many outstanding tasks we have.
    const outstandingTasksCount = React.useMemo(() => tasks.filter(item => !actionStates.findById(item.actionState).isCompleted).length, [tasks]);

    const color = React.useMemo(() => {
        const dateMoment: Moment = moment(model.actionAddedDate);
        const differenceDays = dateMoment.diff(moment(), 'days');

        let ret = 'dark';
        if (actionStates.findById(model.actionState).isCompleted) {
            ret = 'success';
        } else if (differenceDays <= 0) {
            ret = 'danger';
        } else if (differenceDays <= 30) {
            ret = 'warning';
        }

        return ret;
    }, [model]);

    const school = React.useMemo(() => schools.find(item => item.id === model.schoolId), [schools, model])


    return (
        <Modal size="xl" isOpen={isOpen} toggle={toggle} color={color} className={`modal-${color}` /* Have to do this ourselves as setting color doesn't work */} modalClassName="root-app-modal">
            <ModalHeader toggle={toggle}>
                {model.name}

                <ConditionalFragment showIf={!schoolId && !Guid.isEmpty(model.schoolId)}>
                    <div>
                        <Badge color="dark">
                            {school && school.name || ''}
                        </Badge>
                    </div>
                </ConditionalFragment>
            </ModalHeader>
            <ModalBody>
                <Row className="mb-2">
                    <ConditionalFragment showIf={isPriority}>
                        <Col xs="auto">
                            <Badge color="secondary">
                                Priority
                            </Badge>
                        </Col>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={isRecommendation}>
                        <Col xs="auto">
                            <Badge color="primary">
                                Possible area for development
                            </Badge>
                        </Col>
                    </ConditionalFragment>
                        
                    {
                        awardTags.map(tag => {
                            if (!questionTags.find(it => it.tagId.toLowerCase() === tag.id.toLowerCase())) {
                                return null;
                            }

                            return (
                                <Col xs="auto">
                                    <div>
                                        <Badge color="primary">
                                            {tag.name}
                                        </Badge>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label htmlFor="name">Action</Label>
                        </Col>
                        <ConditionalFragment showIf={!actionState.isCompleted}>
                            <Col xs="auto">
                                <DueDateLabel date={model.actionAddedDate} />
                            </Col>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={actionState.isCompleted}>
                            <Col xs="auto">
                                <ConditionalFragment showIf={outstandingTasksCount > 0}>
                                    <span className="text-muted">
                                        {outstandingTasksCount} task(s) are still outstanding
                                    </span>
                                    <> </>
                                </ConditionalFragment>
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    
                    <Row>
                        <Col>
                            <ValidatedInput name="name" autoComplete="off" type="text" value={model.name || ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                        </Col>
                        <Col xs="auto">
                            <Button color="link" className="text-danger" onClick={() => { remove(); toggle(); saveDebounce(); }}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <span className="sr-only">Delete action</span>
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>

                <ConditionalFragment showIf={showSchoolSelector}>
                    <FormGroup>
                        <Label htmlFor="schoolId">School</Label>
                        <ValidatedInput name="schoolId" type="select" value={model.schoolId || ''} onChange={e => change({ schoolId: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors}>
                            {
                                schools.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </ValidatedInput>
                    </FormGroup>
                </ConditionalFragment>

                <Row>
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="actionAddedDate">Due date</Label>
                            <ValidatedDateTimeInput name="actionAddedDate" type="date" value={model.actionAddedDate || ''} onChange={e => change({ actionAddedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="priority">Priority</Label>
                            <ValidatedInput name="priority" type="select" value={model.priority || ''} onChange={e => change({ priority: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors}>
                                {
                                    actionPriorities.map(item => (
                                        <option key={item} value={item}>{actionPriorityDisplayName(item)}</option>
                                        ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md="auto">
                        <FormGroup>
                            <ConditionalFragment showIf={!!model.completedDate}>
                                <Label htmlFor="completedDate">
                                    Completed on
                                </Label>
                                <Row>
                                    <Col>
                                        <ValidatedDateTimeInput name="completedDate" type="date" value={model.completedDate || ''} onChange={e => change({ completedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="danger" outline onClick={() => changeState(actionStates.outstanding)}>
                                            <FontAwesomeIcon icon="undo" />
                                            <> </>Undo completion
                                        </Button>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!model.completedDate}>
                                <Label htmlFor="completedDate">&nbsp;</Label>
                                <div>
                                    <Button color="success" outline onClick={() => changeState(actionStates.completed)}>
                                        <FontAwesomeIcon icon="check" />
                                        <> </>
                                        Complete
                                    </Button>
                                </div>
                            </ConditionalFragment>
                        </FormGroup>
                    </Col>
                </Row>

                <ConditionalFragment showIf={!!questionText}>
                    <Label htmlFor="questionText">Action relates to {questionLabelText} question</Label> <br />
                    <Link to={`${projectSettings.baseRoute}/showQuestion/${props.model.questionId}`}>{questionText}</Link> <small className="text-muted">[{questionNumber}]</small><br /><br />
                </ConditionalFragment>


                <FormGroup>
                    <Label htmlFor="descriptionHtml">Details</Label>
                    <ElasticInput value={model.description || ''} onChange={e => change({ description: e.currentTarget.value })} onBlur={() => saveDebounce()} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="tasks">Assigned tasks</Label>
                    <div>
                        {
                            tasks.map(item => (
                                <EditActionUserTask key={item.id}
                                    model={item} change={changes => changeTask(item.id, changes)}
                                    remove={() => removeTask(item.id)}
                                    validate={() => true} validationErrors={{}}
                                    userProfiles={userProfiles}
                                    saveDebounce={saveDebounce}
                                    onTaskStateChanged={state => taskStateChanged(item.id, state)}
                                    />
                                ))
                        }

                        <Button color="primary" outline onClick={addTask}>
                            <FontAwesomeIcon icon="plus" />
                            <> </>Assign a task to a user
                        </Button>
                    </div>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" outline onClick={() => { saveDebounce(); toggle(); }}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
        );
}