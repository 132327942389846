import * as React from 'react';
import { Actions, ActionsProps } from './Actions';
import { useUniversalNavigation } from 'react-universal-navigation';

/**
 * Actions component that ignores actions that are for the trust itself, only showing those for schools within the trust. 
 */
export const SchoolOnlyActions = (props: ActionsProps) => {
    const navigation = useUniversalNavigation(props);
    const actionId = navigation.getParam('actionId', '');
    return (<Actions ignoreActionsForTrust={true} title="School improvement planner" actionId={actionId} />);
};