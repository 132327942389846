import * as React from 'react';
import { SpidergraphCard } from '../../projects/dashboard/charts/SpidergraphCard';
import { ReportProps } from '../reportGenerator/ReportProps';


/**
 * Overview chart.
 */
export const OverviewChartUi = (props: ReportProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <SpidergraphCard model={props.model} results={props.results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={undefined} isForSingleSchool={props.isForSingleSchool} isSelfEvaluation={props.projectSettings.isSelfEvaluation} awardTag={props.awardTag} placeholderReplacementMode={'general'} />
        </div>        
    );
};
