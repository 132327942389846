import * as React from 'react';
import { FormGroup, Label, Button, InputGroup, InputGroupAddon, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput, ValidatedFormFeedback } from 'pojo-validator-reactstrap';
import { ValidationErrors } from 'pojo-validator';
import { School } from '../../../../api/models/School';
import { SearchModal } from '../../../manage/edubase/SearchModal';
import { SchoolEdubaseDetailsUi } from '../../../manage/edubase/SchoolEdubaseDetailsUi';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { TagUi } from './TagUi';
import { ManagedChildModels } from '../../../shared/hooks/useManagedChildModels';
import { SchoolTag } from '../../../../api/models/SchoolTag';
import { Tag } from '../../../../api/models/Tag';
import { ConditionalFragment } from 'react-conditionalfragment';
import { PrivateContainer } from '../../../shared/privateRoute/PrivateContainer';
import { AwardTag } from '../../../../api/models/AwardTag';
import { Row } from 'reactstrap';

interface CoreFieldsProps {
    model: School | undefined,
    changeModel: (changes: Partial<School>) => void,

    validate: (fieldsToCheck?: Array<string>) => boolean,
    validationErrors: ValidationErrors,

    updateFromEdubase: (urn: number) => Promise<boolean>,
    isUpdatingFromEdubase: boolean,

    allTags: Array<Tag>,
    schoolTags: ManagedChildModels<SchoolTag>,

    setNeedFullPageRefresh: (value: boolean) => void,
}

/**
 * Core fiels of the School Edit screen, which can also be embedded into other screens (e.g. the create project wizard).
 * @param props
 */
export const CoreFields = (props: CoreFieldsProps) => {
    const [searchModalIsOpen, setSearchModalIsOpen] = React.useState<boolean>(false);
    const toggleSearchModalOpen = React.useCallback(() => {
        setSearchModalIsOpen(prevState => !prevState);
    }, [setSearchModalIsOpen]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const toggleTag = React.useCallback((tagId: string) => {
        if (!props.model) {
            return;
        }

        //If questionTags has a record for this question and tag, then remove it
        let existingTag = props.schoolTags.models.find(it => !!props.model && it.schoolId === props.model.id && it.tagId === tagId);
        if (existingTag) {
            props.schoolTags.removeModel(existingTag.id);
        } else {
            props.schoolTags.addModel({
                schoolId: props.model.id,
                tagId: tagId
            });
        }

        // Any changes to tags needs a full screen refresh on save.
        props.setNeedFullPageRefresh(true);
    }, [props.schoolTags, props.model, props.setNeedFullPageRefresh]);

    // Allow the potential school to join the trust as a full school.
    const [allowUndoOfJoinTrust, setAllowUndoOfJoinTrust] = React.useState<boolean>(false);
    const joinTrust = React.useCallback(() => {
        props.changeModel({
            isPotentialSchool: false,
        });
        setAllowUndoOfJoinTrust(true);
    }, [setAllowUndoOfJoinTrust, props.changeModel]);

    const deleteFromTrust = React.useCallback(() => {
        props.changeModel({
            archived: true,
        });
        setAllowUndoOfJoinTrust(true);
    }, [setAllowUndoOfJoinTrust, props.changeModel]);

    // Undo the joining of a trust (only available until saved).
    const undoJoinTrust = React.useCallback(() => {
        props.changeModel({
            isPotentialSchool: true,
            archived: false,
        });
        setAllowUndoOfJoinTrust(true);
    }, [props.changeModel]);

    // render UI
    //

    if (!props.model) {
        return (<></>);
    }

    return (
        <>
            <FormGroup>
                <Label htmlFor="name">Name</Label>
                <InputGroup>
                    <ValidatedInput type="text" name="name" autoComplete="off" placeholder="Name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} hideFormFeedback={true} />
                    {/*<InputGroupAddon addonType="append">*/}
                    {/*    <Button type="button" color="primary" onClick={toggleSearchModalOpen}>*/}
                    {/*        <FontAwesomeIcon icon="search" /> <span className="sr-only">Search</span>*/}
                    {/*    </Button>*/}
                    {/*</InputGroupAddon>*/}
                </InputGroup>
                <ValidatedFormFeedback name="name" validationErrors={props.validationErrors} />
            </FormGroup>

            <PrivateContainer role="Manage">
                <ConditionalFragment showIf={props.model.isPotentialSchool && !props.model.archived}>
                    <FormGroup>
                        <Label htmlFor="name">This school is not yet part of the trust but is being considered as a potential school.  When a decision has been made on the school you can use these options to progress it to the next stage.</Label>
                        <div>
                            <Button color="success" outline onClick={joinTrust}>
                                <FontAwesomeIcon icon="check" />
                                <> </>School has now joined the trust
                            </Button>
                            <> </>
                            <Button color="danger" outline onClick={deleteFromTrust}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>Remove the school as it is no longer being considered to join the trust
                            </Button>
                        </div>
                    </FormGroup>
                </ConditionalFragment>

                <ConditionalFragment showIf={allowUndoOfJoinTrust}>
                    <FormGroup>
                        <ConditionalFragment showIf={!props.model.isPotentialSchool}>
                            <Button color="danger" outline onClick={undoJoinTrust}>
                                <FontAwesomeIcon icon="undo" />
                                <> </>Undo joining of trust
                            </Button>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={props.model.archived}>
                            <Button color="danger" outline onClick={undoJoinTrust}>
                                <FontAwesomeIcon icon="undo" />
                                <> </>Undo removal
                            </Button>
                        </ConditionalFragment>
                    </FormGroup>
                </ConditionalFragment>
            </PrivateContainer>

            <FormGroup>
                <Label htmlFor="tags">School type</Label>
                <div className="tag-component">
                    {
                        props.allTags.filter(item => item.isSchoolType).map(item => (
                            <TagUi key={item.id}
                                isSelected={!!props.schoolTags.models.find(it => !!props.model && it.schoolId === props.model.id && it.tagId === item.id)}
                                school={props.model}
                                tag={item}
                                toggleSelected={() => toggleTag(item.id)}
                            />
                        ))
                    }
                </div>
                <FormText>Please select all that apply</FormText>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="tags">School phases</Label>
                <div className="tag-component">
                    {
                        props.allTags.filter(item => !item.isSchoolType).map(item => (
                            <TagUi key={item.id} isSelected={!!props.schoolTags.models.find(it => !!props.model && it.schoolId === props.model.id && it.tagId === item.id)} school={props.model} tag={item} toggleSelected={() => toggleTag(item.id)} />
                        ))
                    }
                </div>
                <FormText>Please select all that apply</FormText>
            </FormGroup>

            {/*
                props.model.edubaseJson ? (
                    <>
                        <h3 className="sub-heading">
                            Information from DfE <>
                                <ButtonAsync color="primary" outline size="sm" onClick={e => props.updateFromEdubase(parseFloat(props.model && props.model.dfeReference || ''))} isExecuting={props.isUpdatingFromEdubase}
                                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Refreshing...</>}>
                                    Refresh
                                </ButtonAsync>
                            </>
                        </h3>
                        <SchoolEdubaseDetailsUi json={props.model.edubaseJson} />
                    </>) : (
                        <div className="text-danger">
                            This school has not yet been located within the DfE's database and so cannot show any additional information. <Button color="primary" outline size="sm" type="button" onClick={toggleSearchModalOpen}>Want to try to find it now?</Button>
                        </div>
                        )
            */}

            {
                searchModalIsOpen ? (
                    <SearchModal searchText={props.model.name} cancel={toggleSearchModalOpen} resultSelected={(urn: number) => { props.updateFromEdubase(urn); toggleSearchModalOpen(); }} />
                ) : null
            }
        </>
    );
};
