import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { SEFPdfProps } from './SEFPdf';
import { Styles } from '../../executiveSummary/pdf/Styles';
import { Guid } from 'guid-string';
import { ProjectAnalysisResultItem, ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { getGradeString } from '../../../../utilities/getGradeString';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Strength } from '../../../../api/models/Strength';
import { Risk } from '../../../../api/models/Risk';
import { Question } from '../../../../api/models/Question';
import { QuestionResponse } from '../../../../api/models/QuestionResponse';


export interface SEFProjectTopicPdfUiProps extends SEFPdfProps {
    projectTopicId: Guid,
    isFirstTopic: boolean,
}

/**
 * Breakdown of strengths and recommendations by topics and topics for SEF (pdf format).
 */
export const SEFProjectTopicPdfUi = (props: SEFProjectTopicPdfUiProps) => {
    const {
        questionResponses,
    } = props;

    // Find the appropriate items we want to work with.
    let [projectTopic, topic, results, questions, /*questionaires, */ excellences, strengths, risks, myResults] = React.useMemo(() => {
        let ps = props.projectTopics.models.find(item => item.id === props.projectTopicId);
        let topicId = '';
        if (ps) {
            topicId = ps.topicId;
        }

        let s = props.topics.find(item => item.id === topicId);

        let r = props.resultsByTopic.find(item => item.projectTopicId === props.projectTopicId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        const sectionResults = props.resultsBySection.find(item => !!s && item.sectionId === s.sectionId);
        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (sectionResults && sectionResults.results.length) {
            myResults = sectionResults.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        let q = props.questions.filter(item => item.topicId === topicId);
        //let qq = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));

        const strengthsx = props.strengths.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));
        const strengthsExcellences = strengthsx.filter(item => item.isExcellence);
        const strengthsAreasOfStrength = strengthsx.filter(item => !item.isExcellence);
        const risksx = props.risks.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));

        return [ps, s, rr, q, /*qq,*/ strengthsExcellences, strengthsAreasOfStrength, risksx, myResults];
    }, [props.projectTopics, props.topics, props.projectTopicId, props.questions, props.resultsByTopic, props.strengths, props.risks]);

    if (!props.model) {
        return (<></>);
    }


    if (!projectTopic) {
        return (<></>);
    }

    if (!excellences.length && !strengths.length && !risks.length) {
        return (<></>);
    }

    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View wrap={false} style={{ marginBottom: 20, }}>
            <View style={{ ...Styles.row, marginBottom: 5, }}>
                <View style={{ ...Styles.col, width: '65%' }}>
                    <Text style={Styles.h5}>{topic && replacePlaceholders(topic.name, placeholderReplacementMode, { subjectName: props.subject?.name })}</Text>
                </View>
                <View style={{ ...Styles.col, width: '35%', textAlign: 'right', }}>
                    <Text style={Styles.h5}>{getGradeString(myResults && myResults.percentageQuestionScore || 0)}</Text>
                </View>
            </View>
            <View>
                {/* Areas of excellence */}
                <ConditionalFragment showIf={!!excellences.length}>
                    <TableFor
                        title="Areas of excellence"
                        items={excellences}
                        placeholderReplacementMode={props.placeholderReplacementMode}
                        questions={questions}
                        questionResponses={questionResponses}
                        subjectName={props.subject?.name}
                    />
                </ConditionalFragment>

                {/* Areas of strength */}
                <ConditionalFragment showIf={!!strengths.length}>
                    <TableFor
                        title="Areas of strength"
                        items={strengths}
                        placeholderReplacementMode={props.placeholderReplacementMode}
                        questions={questions}
                        questionResponses={questionResponses}
                        subjectName={props.subject?.name}
                    />
                </ConditionalFragment>

                {/* Areas for development */}
                <ConditionalFragment showIf={!!risks.length}>
                    <TableFor
                        title="Areas for development"
                        items={risks}
                        placeholderReplacementMode={props.placeholderReplacementMode}
                        questions={questions}
                        questionResponses={questionResponses}
                        subjectName={props.subject?.name}
                    />
                </ConditionalFragment>
            </View>
        </View>
    );
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and only used internall by the files main component.
 */
const TableFor = (props: {
    title: string,
    items: Array<Strength | Risk>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    questions: Array<Question>,
    questionResponses: Array<QuestionResponse>,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        questions,
        questionResponses,
        subjectName,
    } = props;

    return (
        <View style={{ ...Styles.table, marginBottom: '2' }}>
            <View style={Styles.thead}>
                <View style={Styles.trHead}>
                    <View style={{ ...Styles.th, width: '10%' }}><Text>{' '}</Text></View>
                    <View style={{ ...Styles.th, width: '90%' }}>
                        <Text>
                            {title}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={Styles.tbody}>
                {
                    items.map(item => {
                        // Without an associated question we can't show anything.
                        const question = questions.find(it => it.id === item.questionId);
                        if (!question) {
                            return null;
                        }

                        // Get the response to look up the feedback.  If any has been written we'll include it in the table.
                        const response = questionResponses.find(it => it.id === item.questionResponseId);
                        let feedback = '';
                        if (response) {
                            feedback = response.feedback;
                        }

                        return (
                            <View key={item.id} style={Styles.tr}>
                                <View style={{ ...Styles.th, width: '10%' }}>
                                    <Text>
                                        {question.questionNumber}
                                    </Text>
                                </View>
                                <View style={{ ...Styles.th, width: '90%' }}>
                                    <Text>
                                        <PlainTextWithBrsPdf text={replacePlaceholders(item.description, props.placeholderReplacementMode, { subjectName: subjectName })} />
                                    </Text>
                                    {
                                        !!feedback ? (
                                            <View style={{ marginTop: '4', paddingTop: '2', borderTopWidth: '1', borderTopColor: 'lightgray', borderTopStyle: 'dotted', color: '#606060', }}>
                                                <Text>{replacePlaceholders(feedback, placeholderReplacementMode, { subjectName: subjectName })}</Text>
                                            </View>
                                        ) : null
                                    }
                                </View>
                            </View>
                        );
                    })
                }
            </View>
        </View>
    );
};

