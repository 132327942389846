import { Document, ImageRun, Paragraph, TextRun, HeadingLevel } from "docx";
import { ReportProps } from "../../reportGenerator/ReportProps";
import { ImageEnumerator, ImageWithMetadata } from "../../executiveSummary/pdf/ImageEnumerator";
import { isNullOrUndefined } from "util";
import { OverviewChartDocxUi } from "./OverviewChartDocxUi"
import { StrengthsSummaryDocxUi } from "./StrengthsSummaryDocxUi";
import { SEFBreakdownDocxUi } from "./SEFBreakdownDocxUi";
import { replacePlaceholders } from "../../../../utilities/replacePlaceholders";

export interface SEFDocxProps extends ReportProps {
    chartImages: Array<ImageWithMetadata>
}

/**
 * Generate and return a Word DOCX document for the SEF report.
 * 
 * NOTE We can't use react to generate docx files, but the docx javascript library does lets us follow a similar code workflow, so we 
 * continue to pass props etc. as if it was react-pdf like to keep code between screen/pdf/docx versions as similar as possible, while knowing
 * the word version will have the most specific internals to cope with this.
 */
export function SEFDocx(props: SEFDocxProps) {
    const { projectSettings, reportSettings } = props;
    const imageEnumerator = new ImageEnumerator(props.chartImages);

    if (!props.model) {
        new Document({
            sections: [
                {
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun("Word document could not be generated."),
                            ],
                        }),
                    ]
                }
            ],
        });
    }

    return new Document({
        styles: {
            // Deault font should be Arial.
            default: {
                document: {
                    run: {
                        font: 'Arial',
                    }
                },
                heading1: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
                heading2: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
                heading3: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
                heading4: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
            },
            paragraphStyles: [

            ],
        },
        sections: [
            {
                children: finalizeChildren([
                    new Paragraph({
                        text: props.school ? props.school.name
                            : props.trust ? props.trust.name
                                : '',
                        heading: HeadingLevel.TITLE,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: 'Evaluate-Ed ' + (replacePlaceholders(
                                    props.awardTag ? `${props.awardTag.name} summary`
                                        : props.subject && props.subject.name && reportSettings.reportName ? `${props.subject.name} ${reportSettings.reportName}`
                                            : reportSettings.reportName ? reportSettings.reportName
                                                : projectSettings.isSelfEvaluation ? 'self evaluation summary'
                                                    : projectSettings.isDueDiligence ? 'executive summary' : 'project summary',
                                    props.placeholderReplacementMode, { subjectName: props.subject?.name })
                                    ),
                                color: '#808080',
                            })
                        ],
                    }),

                    /* Show school logo/banner if it has one */
                    ...(!!props.reportBannerBlobDataUrl ? ([
                        new Paragraph({
                            children: finalizeChildren([
                                new ImageRun({
                                    data: props.reportBannerBlobDataUrl.dataUrl,
                                    transformation: {
                                        width: 600,
                                        height: Math.round(600 / props.reportBannerBlobDataUrl.aspectRatio),
                                    },
                                }),
                            ]),
                        }),
                        new Paragraph({ text: '', }), // Empty paragraph for spacing and making customising the document easier.
                        ])
                    : []),
 
                    reportSettings.overallResults ? 
                        OverviewChartDocxUi({ ...props, imageEnumerator, })
                    : null,

                    reportSettings.areasOfStrength ?
                        StrengthsSummaryDocxUi({ ...props, imageEnumerator, })
                        : null,

                    reportSettings.breakdown ? 
                        SEFBreakdownDocxUi(props)
                        : null
                ]),
            }
        ],
    });
}

/**
 * Helper function that lets our code read a bit more like react components, but cleans up and flattens the children generated ready for use.
 * @param children
 */
export function finalizeChildren<T>(children: Array<any>): Array<any> {
    // Flatten down to a single level.
    let ret = children.flat(10);
    // Strip out nulls or undefineds left from conditional statements.
    ret = ret.filter(item => !isNullOrUndefined(item));

    return ret;
}