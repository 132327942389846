import * as React from 'react';
import { useApiService } from '../../api/useApiService';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Project } from '../../api/models/Project';
import { Button, Col, Row, ListGroup, ListGroupItem, ButtonGroup, Nav, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { useUniversalNavigation } from 'react-universal-navigation';
import { ListGroupItemHeading } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CustomReport } from '../../api/models/CustomReport';
import { DateLabel } from '../shared/DateLabel';
import { ConditionalFragment } from 'react-conditionalfragment';
import { SavedReport } from '../../api/models/SavedReport';
import { ReportSettingsModel } from './reportSettings/ReportSettingsModel';
import moment from 'moment';
import FileSaver from 'file-saver';
import { SearchAsYouTypeInput } from '../shared/SearchAsYouTypeInput';
import { isProductTierUnlocked, ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { ProductTierNotUnlockedAlert } from '../shared/productTierNotUnlocked/ProductTierNotUnlockedAlert';
import { AwardTag } from '../../api/models/AwardTag';
import { Subject } from '../../api/models/Subject';
import { NavItem } from 'reactstrap';
import { TabContent } from 'reactstrap';
import { TabPane } from 'reactstrap';

export interface OverviewProps {

}

/**
 * Report manager for schools.
 * @param props
 */
export const SchoolReportManager = (props: OverviewProps) => {
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');

    const api = useApiService();
    const loadViewModel = api.customReports.viewModels.reportManager();
    const currentProjectFor = api.projects.viewModels.currentProjectFor();

    // Load the main model data.
    //
    const [model, setModel] = React.useState<School | undefined>();
    const [customReports, setCustomReports] = React.useState<Array<CustomReport>>([]);
    const [savedReports, setSavedReports] = React.useState<Array<SavedReport>>([]);
    const [project, setProject] = React.useState<Project | undefined>();
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);

    const [subjects, setSubjects] = React.useState<Array<Subject>>([]);
    const [readingReviews, setReadingReviews] = React.useState<{ eyfsToKS2: boolean, ks3ToKS4: boolean }>({ eyfsToKS2: false, ks3ToKS4: false, });

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        // Load the school details (using the overview screen query).
        const result = await loadViewModel(id);
        setCustomReports(result.customReports);
        setSavedReports(result.savedReports);
        setAwardTags(result.awardTags);
        setSubjects(result.subjects);
        setReadingReviews(result.readingReviews);
        setModel(result.school);

        const school = result.school;
        let projectResult = await currentProjectFor({
            schoolId: school.id || '', trustId: school.trustId,
            questionSetName: school.isPotentialSchool ? 'School due diligence' : 'School self evaluation',
            createIfMissing: false
        });

        if (!!projectResult && !projectResult.isMot) {
            setProject(projectResult);
        }


    }, [loadViewModel, setModel, id, setCustomReports, setSavedReports, setProject, setSubjects, setReadingReviews]);
    React.useEffect(() => {
        if (!id || isLoading) {
            return;
        }

        if (model && model.id === id) {
            return;
        }

        load();
    }, [id, load, model, isLoading]);

    // Tab that is being shown.
    const [activeTab, setActiveTab] = React.useState<'generate' | 'previous'>('generate');

    // Navigation into reports.
    const [navigateWhenReadySettings, setNavigateWhenReadySettings] = React.useState<{ reportName: string, options: { dontAddSelfEvaluationProjectId?: boolean, } | undefined } | undefined>(undefined);
    const navigateWhenReady = React.useCallback((reportName: string, options?: { dontAddSelfEvaluationProjectId?: boolean, }) => {
        setNavigateWhenReadySettings({
            reportName,
            options,
        });
    }, [setNavigateWhenReadySettings]);
    React.useEffect(() => {
        if (!navigateWhenReadySettings || !navigateWhenReadySettings.reportName) {
            return;
        }

        if (!model || !project) {
            return;
        }

        if (navigateWhenReadySettings.options && navigateWhenReadySettings.options.dontAddSelfEvaluationProjectId) {
            // If we don't want to use the self evaluation project as our project, redirect without the project.id.
            navigation.push(`/school/${model.id}/reports/${navigateWhenReadySettings.reportName}`);
        } else {
            // Otherwise navigate with the self evaluation project.id.
            navigation.push(`/school/${model.id}/reports/${navigateWhenReadySettings.reportName}/${project.id}`);
        }
    }, [navigateWhenReadySettings, model, project, navigation]);

    const standardReports = React.useMemo(() => [
        { reportName: 'fullSEF', text: 'SEF', icon: 'chalkboard-teacher' as IconProp },

        { reportName: 'snapshot', text: 'SEF Lite', icon: 'file-alt' as IconProp }, // TODO rename
        { reportName: 'sixtyMinuteSEF', text: 'SEF Plus', icon: 'stopwatch' as IconProp }, // TODO rename

        { reportName: 'headsReport', text: 'Head\'s report', icon: 'highlighter' as IconProp },
        { reportName: 'governorReport', text: 'Governor report', icon: 'user-friends' as IconProp },
        { reportName: 'summary', text: 'Self evaluation executive summary', icon: 'quote-right' as IconProp },
        { reportName: 'breakdown', text: 'Self evaluation breakdown', icon: 'check-double' as IconProp },

        // NOTE the omission of default and empty is on purpose and not a mistake.
    ], []);

    // Resolve a url from a blobId when we need it.
    const getBlobUrl = api.blobs.getBlobUrl();

    const [savedReportsSearchText, setSavedReportsSearchText] = React.useState<string>('');
    const filteredSavedReports = React.useMemo(() => {
        if (!savedReports) {
            return savedReports;
        }

        if (!savedReportsSearchText) {
            return savedReports;
        }

        const lowerSearchText = savedReportsSearchText.toLocaleLowerCase();
        const ret = savedReports.filter(item => {
            const reportSettings = JSON.parse(item.reportSettings || '{}') as ReportSettingsModel;

            if ((reportSettings.reportName || '').toLocaleLowerCase().indexOf(lowerSearchText) !== -1) {
                return true;
            }

            if (item.savedDate.toLocaleLowerCase().indexOf(lowerSearchText) !== -1) {
                return true;
            }

            const formattedDate = moment(item.savedDate).format('DD/MM/YYYY HH:mm');
            if (formattedDate.toLocaleLowerCase().indexOf(lowerSearchText) !== -1) {
                return true;
            }

            return false;
        });

        return ret;
    }, [savedReports, savedReportsSearchText]);

    if (!model) {
        return (<LoadingIndicator />);
    }

    // If the school has not reached the right tier, hide the functionality.
    if (!isProductTierUnlocked(ProductTier.Snapshot, model.tier as ProductTier)) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>Reports manager</h1>
                </div>

                <ProductTierNotUnlockedAlert tier={ProductTier.Snapshot} />
            </div>
        );
    }

    return (
        <>
            <div className="main-container reports-manager">
                <h3>Evaluate-Ed reports</h3>

                <AlertOnErrors errors={[loadingErrors]} />

                <Nav tabs justified>
                    <NavItem>
                        <NavLink className={`${activeTab === 'generate' ? 'active' : ''}`} style={{ cursor: 'pointer', height: '100%' }} onClick={() => setActiveTab('generate')}>
                            Generate a report
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={`${activeTab === 'previous' ? 'active' : ''}`} style={{ cursor: 'pointer', height: '100%' }} onClick={() => setActiveTab('previous')}>
                            Download a copy of an old report
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    {/* Generate new reports */}
                    <TabPane tabId="generate">
                        <Row>
                            <Col xs={12} md={6}>
                                <h3 className="mt-2">Self evaluation</h3>
                                <Row>
                                    {
                                        standardReports.map(item => (
                                            <Col key={item.reportName} xs={12} lg={6}>
                                                <ButtonGroup className="reports-manager-navigation-button-group">
                                                    <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady(`standard/${item.reportName}`)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={item.icon} />
                                                            </Col>
                                                            <Col>
                                                                <div className="reports-manager-navigation-button-text">
                                                                    {item.text}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                    <Button color="primary" outline className="reports-manager-navigation-configure-button" onClick={() => navigateWhenReady(`standardBuild/${item.reportName}`)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon="cogs" />
                                                                <div className="reports-manager-navigation-configure-button-text">Customise</div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        ))
                                    }
                                </Row>

                                {/* If the school has any specailisms, show their indivudal reports in their own section. */}
                                {
                                    !!awardTags.length ? (
                                        <>
                                            <h3 className="mt-2">Specific reviews</h3>
                                            <Row>
                                                {
                                                    awardTags.map(item => (
                                                        <Col key={item.id} xs={12} lg={6}>
                                                            <ButtonGroup className="reports-manager-navigation-button-group">
                                                                <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady(`awardSummary/${item.id}/awardSummary`)}>
                                                                    <Row>
                                                                        <Col xs="auto">
                                                                            <FontAwesomeIcon icon="certificate" />
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="reports-manager-navigation-button-text">
                                                                                {item.name}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Button>
                                                                <Button color="primary" outline className="reports-manager-navigation-configure-button" onClick={() => navigateWhenReady(`awardSummaryBuild/${item.id}/awardSummary`)}>
                                                                    <Row>
                                                                        <Col xs="auto">
                                                                            <FontAwesomeIcon icon="cogs" />
                                                                            <div className="reports-manager-navigation-configure-button-text">Customise</div>
                                                                        </Col>
                                                                    </Row>
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </>
                                    ) : null
                                }

                                <h3 className="mt-2">Custom reports</h3>
                                <Row>
                                    {
                                        customReports.map(item => (
                                            <Col key={item.id} xs={12} lg={6}>
                                                <ButtonGroup className="reports-manager-navigation-button-group">
                                                    <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady(`custom/${item.id}`)}>
                                                        <Row>
                                                            <Col>
                                                                <div className="reports-manager-navigation-button-text">
                                                                    {item.name}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                    <Button color="primary" outline className="reports-manager-navigation-configure-button" onClick={() => navigateWhenReady(`customBuild/${item.id}`)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon="cogs" />
                                                                <div className="reports-manager-navigation-configure-button-text">Customise</div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        ))
                                    }

                                    <Col xs={12} lg={6}>
                                        <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady('standardBuild/custom')}>
                                            <Row>
                                                <Col xs="auto">
                                                    <FontAwesomeIcon icon="plus" />
                                                </Col>
                                                <Col>
                                                    <div className="reports-manager-navigation-button-text">
                                                        Create a new report
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                {/* If the school has any subjects, show their indivudal reports in their own section. */}
                                {
                                    !!subjects.length ? (
                                        <>
                                            <h3 className="mt-2">Subject leadership</h3>
                                            <Row>
                                                {
                                                    subjects.map(item => (
                                                        <React.Fragment key={item.id}>
                                                            <Col xs={12} lg={6}>
                                                                <ButtonGroup className="reports-manager-navigation-button-group">
                                                                    <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady(`subjectLeadershipReview/${item.id}/subjectLeadershipReview`, { dontAddSelfEvaluationProjectId: true, })}>
                                                                        <Row>
                                                                            <Col xs="auto">
                                                                                <FontAwesomeIcon icon="brain" />
                                                                            </Col>
                                                                            <Col>
                                                                                <div className="reports-manager-navigation-button-text">
                                                                                    {item.name} Subject Leadership Report
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                            <Col xs={12} lg={6}>
                                                                <ButtonGroup className="reports-manager-navigation-button-group">
                                                                    <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady(`subjectHelpfulQuestions/${item.id}/subjectHelpfulQuestions`, { dontAddSelfEvaluationProjectId: true, })}>
                                                                        <Row>
                                                                            <Col xs="auto">
                                                                                <FontAwesomeIcon icon="hand-holding-heart" />
                                                                            </Col>
                                                                            <Col>
                                                                                <div className="reports-manager-navigation-button-text">
                                                                                    {item.name} Helpful Questions
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </Row>
                                        </>
                                    ) : null
                                }

                                {/* If the school has any reading reviews, show their indivudal reports in their own section. */}
                                {
                                    readingReviews.eyfsToKS2 || readingReviews.ks3ToKS4 ? (
                                        <>
                                            <h3 className="mt-2">Reading reviews</h3>
                                            <Row>
                                                <ConditionalFragment showIf={readingReviews.eyfsToKS2}>
                                                    <Col xs={12} lg={6}>
                                                        <ButtonGroup className="reports-manager-navigation-button-group">
                                                            <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady(`readingReviewEYFSToKS2/readingReviewEYFSToKS2`, { dontAddSelfEvaluationProjectId: true, })}>
                                                                <Row>
                                                                    <Col xs="auto">
                                                                        <FontAwesomeIcon icon="book-reader" />
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="reports-manager-navigation-button-text">
                                                                            EYFS, KS1, and KS2 reading
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </ConditionalFragment>

                                                <ConditionalFragment showIf={readingReviews.ks3ToKS4}>
                                                    <Col xs={12} lg={6}>
                                                        <ButtonGroup className="reports-manager-navigation-button-group">
                                                            <Button color="primary" outline className="reports-manager-navigation-button" onClick={() => navigateWhenReady(`readingReviewKS3ToKS4/readingReviewKS3ToKS4`, { dontAddSelfEvaluationProjectId: true, })}>
                                                                <Row>
                                                                    <Col xs="auto">
                                                                        <FontAwesomeIcon icon="book-open" />
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="reports-manager-navigation-button-text">
                                                                            KS3 and KS4 reading
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </ConditionalFragment>
                                            </Row>
                                        </>
                                    ) : null
                                }
                            </Col>
                        </Row>
                    </TabPane>

                    {/* Download a previously generate report */}
                    <TabPane tabId="previous">
                        <h3>Previously downloaded reports</h3>
                        <ConditionalFragment showIf={savedReports.length > 0}>
                            <div className="mb-1">
                                <SearchAsYouTypeInput placeholder="Search" onSearch={searchText => setSavedReportsSearchText(searchText)} />
                            </div>
                        </ConditionalFragment>
                        <ListGroup>
                            {
                                filteredSavedReports.map(item => {
                                    const reportSettings = JSON.parse(item.reportSettings || '{}') as ReportSettingsModel;

                                    return (
                                        <ListGroupItem key={item.id}>
                                            <ListGroupItemHeading>
                                                {reportSettings.reportName}
                                            </ListGroupItemHeading>
                                            <Row>
                                                <Col className="text-muted">
                                                    <DateLabel value={item.savedDate} format="DD/MM/YYYY HH:mm" />
                                                </Col>
                                                <Col xs={12} md="auto">
                                                    <ButtonGroup>
                                                        {
                                                            item.fileFormat === 'pdf' ? (
                                                                <>
                                                                    <Button outline onClick={async () => {
                                                                        if (!item.blobId) {
                                                                            return;
                                                                        }

                                                                        const url = await getBlobUrl(item.blobId);
                                                                        window.open(url);
                                                                    }}>
                                                                        <FontAwesomeIcon icon="print" />
                                                                        <> </>
                                                                        Print
                                                                    </Button>
                                                                    <Button outline onClick={async () => {
                                                                        if (!item.blobId) {
                                                                            return;
                                                                        }

                                                                        const url = await getBlobUrl(item.blobId);
                                                                        const filename = `${reportSettings.reportName} - ${model && model.name || 'School'} - ${moment(item.savedDate).format('YYYY-MM-DD HHmm')}.pdf`;
                                                                        FileSaver.saveAs(url, filename);
                                                                    }}>
                                                                        <FontAwesomeIcon icon="file-download" />
                                                                        <> </>
                                                                        Download PDF
                                                                    </Button>
                                                                </>
                                                            ) : item.fileFormat === 'docx' ? (
                                                                <Button outline onClick={async () => {
                                                                    if (!item.blobId) {
                                                                        return;
                                                                    }

                                                                    const url = await getBlobUrl(item.blobId);
                                                                    const filename = `${reportSettings.reportName} - ${model && model.name || 'School'} - ${moment(item.savedDate).format('YYYY-MM-DD HHmm')}.docx`;
                                                                    FileSaver.saveAs(url, filename);
                                                                }}>
                                                                    <FontAwesomeIcon icon="file-download" />
                                                                    <> </>
                                                                    Download Word
                                                                </Button>
                                                            ) : null
                                                        }
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    );
                                })
                            }

                            <ConditionalFragment showIf={!savedReports.length && !!model}>
                                <ListGroupItem className="nothing-here">
                                    <div>
                                        <FontAwesomeIcon icon="file-download" />
                                        <div className="mt-2">
                                            When you print or download reports we save a copy here so you can always download it again if required.
                                        </div>
                                    </div>
                                </ListGroupItem>
                            </ConditionalFragment>
                        </ListGroup>
                    </TabPane>
                </TabContent>
            </div>
        </>
    );
};
