import * as React from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import { Guid } from 'guid-string';

export interface TwoValueSwitchProps {
    leftLabel: string,
    rightLabel: string,
    checked: boolean,
    onChange?: (checked: boolean) => void,
}

/**
 * A switch component that lets the user select between two values leftLabel (false), rightLabel (true).
 */
export const TwoValueSwitch = (props: TwoValueSwitchProps) => {
    const { checked, onChange, leftLabel, rightLabel } = props;
    const [uniqueName] = React.useState<string>(Guid.newGuid());

    return (
        <Row noGutters>
            <Col xs="auto" className={`${checked ? "text-muted" : ""} pr-2`}>
                {leftLabel}
            </Col>
            <Col xs="auto">
                <CustomInput id={uniqueName} name={uniqueName} type="switch" checked={checked}
                    onChange={e => { /* No action taken, handle in onClick */ }}
                    onClick={e => {
                        if (onChange) {
                            onChange(!checked);
                        }
                    }}
                />
            </Col>
            <Col className={checked ? "" : "text-muted"}>
                {rightLabel}
            </Col>
        </Row>
    );
};