import * as React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { IImageEnumerator } from './ImageEnumerator';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

/**
 * SIP Recommendations section of the executive summary (pdf format).
 */
export interface StrengthsPdfUiProps extends ExecutiveSummaryPdfProps {
    imageEnumerator: IImageEnumerator
}

export const StrengthsPdfUi = (props: StrengthsPdfUiProps) => {
    if (!props.model) {
        return (<></>);
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View break>
            <Text style={Styles.h3}>Areas of strength</Text>
            <Image src={props.imageEnumerator.getNextImage()} style={{ marginBottom: 16 }} />

            <View style={Styles.table}>
                <View style={Styles.thead}>
                    <View style={Styles.trHead}>
                        <View style={{ ...Styles.th, width: '8%' }}></View>
                        {/*<View style={{ ...Styles.th, width: '46%' }}><Text>Topic</Text></View>*/}
                        <View style={{ ...Styles.th, width: '92%' }}><Text>Strength</Text></View>
                    </View>
                </View>
                <View style={Styles.tbody}>
                    {
                        props.strengths.map(stengthItem => {
                            let question = props.questions.find(it => it.id === stengthItem.questionId);
                            let topic = props.allTopics.find(it => it.id === stengthItem.topicId);
                            let sectionId = '';
                            if (topic) {
                                sectionId = topic.sectionId;
                            }

                            let section = props.allSections.find(it => it.id == sectionId);

                            let questionaireId = '';
                            //let impactId = '';
                            if (question) {
                                questionaireId = question.questionaireId;
                                //impactId = question.impactId;
                            }

                            let questionaire = props.questionaires.find(it => it.id === questionaireId);

                            return (
                                <View key={stengthItem.id} style={Styles.tr}>
                                    <View style={{ ...Styles.th, width: '8%' }}><Text>{question && replacePlaceholders(question.name, placeholderReplacementMode) ? question.questionNumber : '' }</Text></View>
                                    {/*<View style={{ ...Styles.th, width: '46%' }}>*/}
                                    {/*    <View style={{ paddingBottom: 2 }}>*/}
                                    {/*        <Text style={Styles.p}>*/}
                                    {/*            {section && section.name} / {topic && topic.name} {questionaire ? (<Text style={Styles.textMuted}>(from the {questionaire.name} questionnaire)</Text>) : null}*/}
                                    {/*        </Text>*/}
                                    {/*    </View>*/}
                                    {/*    */}{/*<View style={Styles.p}>*/}
                                    {/*    */}{/*    <Text>{question && question.name || 'Whole topic'}</Text>*/}
                                    {/*    */}{/*</View>*/}
                                    {/*</View>*/}
                                    <View style={{ ...Styles.th, width: '92%' }}>
                                        <PlainTextWithBrsPdf text={replacePlaceholders(stengthItem.description, placeholderReplacementMode)} />
                                    </View>
                                </View>
                            );
                        })}
                </View>
            </View>
        </View>
    );
};

