import * as React from "react";
import { ContainerComponentProps } from "react-withcontainer";
import { User } from "../../../api/models/User";
import { EditUiPropsBase } from "../../containers/common/EditUiPropsBase";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { connect } from "react-redux";
import { AppStateCore } from "../../../store";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from "react-use-async-callback";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidationState } from "pojo-validator";
import { AuthenticationService } from "../../../services/AuthenticationService";
import { IdAndName } from "../../../api/models/IdAndName";

export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    /* From dependnecy injection */
    repository: Repository<User>,
    loadViewModel: (id: string, subscriptionId: string) => Promise<any>,
    authenticationService: AuthenticationService,
    renewToken: () => Promise<void>,

    /* From Redux */
    subscriptionId?: string
}

export interface EditUiProps extends EditUiPropsBase<User> {
    //users: Array<User>
    inviteSent: boolean,
    resendInvite: () => Promise<void>,
    resendingInvite: boolean,
    inviteResent: boolean,
    resendInviteErrors: any,
    roleGroups: Array<IdAndName>,
}

export const _EditContainer = (props: EditContainerProps) => {
    let { component, repository, authenticationService, renewToken, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');
    const isCreate = id ? false : true;

    const [model, setModel] = React.useState<User | undefined>(undefined);
    const [inviteSent, setInviteSent] = React.useState<boolean>(!isCreate);
    const [roleGroups, setRoleGroups] = React.useState<Array<IdAndName>>([]);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<User>) => {
        setModel(prevState => ({
            ...(prevState as User),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await props.loadViewModel(id ? id : 'defaults', props.subscriptionId || '');
        setRoleGroups(result.roleGroups);
        setModel(result.model);

        return true;
    }, [repository, setModel, id, changeModel, props.subscriptionId, setRoleGroups]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('forename', () => !model.forename, 'Forename is required');
        }
        if (!fieldsToCheck || fieldsToCheck.includes('surname')) {
            validation.singleCheck('surname', () => !model.surname, 'Surname is required');
        }
        if (!fieldsToCheck || fieldsToCheck.includes('email')) {
            validation.singleCheck('email', () => !model.email, 'Email is required');
        }
    }, [model]);

    // Send the user an invite.
    const sendInvite = React.useCallback(async (user: string) => {
        authenticationService.sendInvite(user);
        setInviteSent(true);
    }, [authenticationService, setInviteSent]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model, isCreate);

        // Renew the token in case the user being edited is the current user.
        renewToken();

        return true;
    }, [model, validate, repository, isCreate, renewToken]);

    // Resend the invite to a the user.
    const [resendInvite, { isExecuting: resendingInvite, errors: resendInviteErrors, successfullyExecuted: inviteResent }] = useAsyncCallback(async (): Promise<void> => {
        if (!model || !model.email) {
            return;
        }

        await sendInvite(model.email);
    }, [model, sendInvite]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel} isCreate={isCreate}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            resendInvite={resendInvite} inviteSent={inviteSent} inviteResent={inviteResent} resendingInvite={resendingInvite} resendInviteErrors={resendInviteErrors}
            roleGroups={roleGroups}
        />
    );
};

export const __EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.users.repository(),
    loadViewModel: services.api.users.viewModels.subscriptionUserEdit(),
    authenticationService: services.authenticationService(),
    renewToken: services.renewToken(),
}))(_EditContainer);

export const EditContainer = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        subscriptionId: state.user.identity && state.user.identity.subscriptionId || ''
    })
)(__EditContainer);
