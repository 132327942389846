import * as React from 'react';
import { StyleSheet, Font } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

//Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxK.woff2' });

const baseFontSize = 20;

/**
 * Styles to use in the PDF.
 */
export const Styles = StyleSheet.create({
    page: {
    },
    body: {
        flexGrow: 1,
        fontSize: baseFontSize,
        marginTop: 30,
        marginBottom: 40,
        marginLeft: 40,
        marginRight: 40,
    },
    header: {
    },
    footer: {
    },
    main: {
        textAlign: 'center',
    },
    schoolName: {
        fontSize: 2 * baseFontSize,
        marginTop: 1 * baseFontSize,
        marginBottom: 1 * baseFontSize,
    },
    hasAcheived: {
        fontSize: 1.1 * baseFontSize,
        marginBottom: 1.6 * baseFontSize,
    },
    certificateName: {
        fontSize: 3 * baseFontSize,
        fontWeight: 'bold',
        //marginBottom: 2 * baseFontSize,
    },
    detailsContainer: {
        marginTop: 30,
        marginBottom: 40,
        marginLeft: 40,
        marginRight: 40,
    },
    details: {
        width: '100%',

        flexDirection: 'row',
    },
    certificateNumber: {
        fontSize: 1 * baseFontSize,
        width: '50%',
        textAlign: 'left',
        color: '#E5007E',

        flexDirection: 'column',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
    awardedBy: {
        fontSize: 1 * baseFontSize,
        width: '50%',
        textAlign: 'right',
        color: '#9D5097',

        flexDirection: 'column',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
});
