import * as React from 'react';
import { Button, Table, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ListContainer, ListUiProps, DataItem } from './ListContainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { InfiniteScrollingTable } from '../../shared/infiniteScrolling/InfiniteScrollingTable';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';
import { DateLabel } from '../../shared/DateLabel';

export const ListUi = (props: ListUiProps) => {
    const navigation = useUniversalNavigation(props);

    const loadPage = React.useCallback((page: number) => {
        props.loadPage(page, props.search);
    }, [props.loadPage, props.search]);

    const filteredItems = React.useMemo(() : Array<DataItem> | undefined | null => {
        if (!props.filter || !props.model) {
            return props.model;
        }

        return props.filter(props.model);
    }, [props.filter, props.model]);

    const [menuIsOpen, setMenuIsOpen] = React.useState<{ [id: string]: boolean }>({});
    const toggleMenuOpen = React.useCallback((id: string) => {
        setMenuIsOpen(prevState => ({
            ...prevState,
            [id]: (prevState[id] ? false : true)
        }));
    }, [setMenuIsOpen]);

    // Open the default action when the user clicks on a row.
    const onRowDoubleClick = React.useCallback((id: string, event: React.MouseEvent<HTMLTableRowElement>) => {
        navigation.push(`${props.baseRoute}/edit/${encodeURIComponent(id)}`);
    }, [navigation]);

    // Number of columns in the table.
    const columnCount = 3;

    return (
        <div className="main-container">
            <AlertOnErrors errors={props.loadingErrors} />

            {/*<div className="toolbar-top">
                <LinkContainer to={`${props.baseRoute}/add?global=true`}>
                    <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add</span></Button>
                </LinkContainer>
            </div>*/}

            <div className="search-bar-container">
                <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
            </div>

            <div className="table-responsive">
                <Table striped hover className="table-sm">
                    <thead>
                        <tr>
                            <th>Name / Version</th>
                            <th>Published on</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredItems && !filteredItems.length ? (
                                <tr className="nothing-here">
                                    <td colSpan={columnCount}>
                                        {
                                            props.search ? (
                                                <div>
                                                    <FontAwesomeIcon icon="search-minus" />
                                                    Sorry there are no question sets matching "{props.search}".
                                                </div>
                                            ) : (
                                                    <div>
                                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                        You haven't added any question sets yet.<br />
                                                </div>
                                                )
                                        }
                                    </td>
                                </tr>
                            ) : null
                        }

                        <InfiniteScrollingTable key={props.search} loadData={loadPage} nextPage={props.nextPage}>
                            {(filteredItems || []).map(group => (
                                <React.Fragment key={group.originKey}>
                                    <tr className="group-row">
                                        <td colSpan={columnCount}>
                                            <h4>{group.name}</h4>
                                        </td>
                                    </tr>
                                    {
                                        group.versions.map(item => (
                                            <tr key={item.id} onDoubleClick={e => onRowDoubleClick(item.id, e)}>
                                                <td>
                                                    {
                                                        item.publishDate ? (
                                                            <>{item.versionNumber}.{item.patchNumber}</>
                                                        ) : (
                                                                <Badge color="warning">
                                                                    Draft
                                                                </Badge>
                                                            )
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.publishDate ? (
                                                            <DateLabel value={item.publishDate} format="DD/MM/YYYY HH:mm" />
                                                        ) : null
                                                    }
                                                </td>
                                                <td className="actions">
                                                    <ButtonGroup>
                                                        <LinkContainer to={`${props.baseRoute}/edit/${encodeURIComponent(item.id)}`}>
                                                            <Button color="primary" outline>
                                                                {
                                                                    item.publishDate ? (<>View</>): (<>Edit</>)
                                                                }
                                                            </Button>
                                                        </LinkContainer>
                                                        {
                                                            group.versions.length > 1  /* Don't allow the final version of any question set to be deleted */
                                                                && !item.publishDate ? /* Don't allow published question sets to be deleted (because we now auto-update live projects on publish) */
                                                                (
                                                                    <ButtonDropdown isOpen={menuIsOpen[item.id]} toggle={() => toggleMenuOpen(item.id)}>
                                                                        <DropdownToggle caret color="primary" outline />
                                                                        <DropdownMenu right>
                                                                            <LinkContainer to={`${props.baseRoute}/delete/${encodeURIComponent(item.id)}`}>
                                                                                <DropdownItem className="text-danger">
                                                                                    <FontAwesomeIcon icon="trash-alt" /> <span>Delete</span>
                                                                                </DropdownItem>
                                                                            </LinkContainer>
                                                                        </DropdownMenu>
                                                                    </ButtonDropdown>
                                                                    ): null
                                                        }
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                            ))
                                    }
                                </React.Fragment>
                            ))}
                        </InfiniteScrollingTable>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const List = withContainer(ListContainer)(ListUi);
