import * as React from 'react';
import { Form, FormGroup, Label, Container, FormText, Input, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';
import { TagUi } from '../awardTags/TagUi';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type === 'checkbox' ? target.checked : target.value)
        });
    }, [props]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const toggleTag = React.useCallback((tagId: string) => {
        //if tag has a record for this subject, then remove it
        let existingTag = props.subjectTags.models.find(it => !!props.model && it.subjectId === props.model?.id && it.tagId === tagId);
        if (existingTag) {
            props.subjectTags.removeModel(existingTag.id);
        } else {
            props.subjectTags.addModel({
                subjectId: (props.model && props.model.id) || '',
                tagId: tagId
            });
        }
    }, [props.subjectTags, props.model]);

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add subject' : 'Edit subject'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <ValidatedInput type="text" name="name" autoComplete="off" placeholder="Name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>

                <div>
                    <FormGroup>
                        <Label htmlFor="tags">School types</Label>
                        <div className={`tag-component`}>
                            {
                                props.allTags.filter(item => item.isSchoolType).map(item => (
                                    <TagUi key={item.id} isSelected={!!!!props.subjectTags.models.find(it => !!props.model && it.subjectId === props.model.id && it.tagId === item.id)} tag={item} toggleSelected={() => toggleTag(item.id)} />
                                ))
                            }
                        </div>
                        <FormText>
                            This subject will be available to any school with at least one of the selected school types.
                        </FormText>
                    </FormGroup>
                </div>
                <div>
                    <FormGroup>
                        <Label htmlFor="tags">School phases</Label>
                        <div className={`tag-component`}>
                            {
                                props.allTags.filter(item => !item.isSchoolType).map(item => (
                                    <TagUi key={item.id} isSelected={!!props.subjectTags.models.find(it => !!props.model && it.subjectId === props.model.id && it.tagId === item.id)} tag={item} toggleSelected={() => toggleTag(item.id)} />
                                ))
                            }
                        </div>
                        <FormText>
                            This subject will be available to any school with at least one of the selected school phases.
                        </FormText>
                    </FormGroup>
                </div>
                <div>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="displayOrder">Display order</Label>
                                <Input type="number" value={props.model.displayOrder} onChange={(e) => props.changeModel({ displayOrder: e.currentTarget.valueAsNumber })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="displayOrder">Subject group</Label>
                                <Input type="text" value={props.model.subjectGroup} placeholder="No subject group" onChange={(e) => props.changeModel({ subjectGroup: e.currentTarget.value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                </div>

                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
