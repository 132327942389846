import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Alert } from 'reactstrap';
import { SchoolTagCertificate } from '../../../../api/models/SchoolTagCertificate';
import { AwardTag } from '../../../../api/models/AwardTag';
import { DateLabel } from '../../../shared/DateLabel';
import { PlainTextWithBrs } from '../../../shared/PlainTextWithBrs';

export interface AwardFeedbackAlertProps {
    awardTag: AwardTag | undefined | null,
    schoolTagCertificate: SchoolTagCertificate | undefined,
}

export const AwardFeedbackAlert = (props: AwardFeedbackAlertProps) => {
    return (
        <ConditionalFragment showIf={!!props.awardTag && props.awardTag.canSubmit}>
            <ConditionalFragment showIf={!!props.schoolTagCertificate && props.schoolTagCertificate.isSubmitted}>
                <Alert color="primary">
                    <p><strong>
                        You submitted this {props.awardTag && props.awardTag.name || 'review'} for review on <DateLabel format="DD/MM/YYYY" value={props.schoolTagCertificate && props.schoolTagCertificate.certificateDate || ''} />.  Your
                            application will be reviewed by {props.awardTag && props.awardTag.awardingOrganisationName || 'the reviewers'} and you will be notified when there is some feedback for you from them.
                        </strong></p>
                    <ConditionalFragment showIf={!!props.schoolTagCertificate && !!props.schoolTagCertificate.feedback}>
                        <p>
                            Feedback previously provided:<br />
                            <PlainTextWithBrs text={props.schoolTagCertificate && props.schoolTagCertificate.feedback || ''} />
                        </p>
                    </ConditionalFragment>
                </Alert>
            </ConditionalFragment>
            <ConditionalFragment showIf={!!props.schoolTagCertificate && props.schoolTagCertificate.isAwarded && !props.schoolTagCertificate.isSubmitted}>
                <Alert color="success">
                    <p><strong>
                        The school passed {props.awardTag && props.awardTag.name || 'the review'} on <DateLabel format="DD/MM/YYYY" value={props.schoolTagCertificate && props.schoolTagCertificate.certificateDate || ''} /> with
                            the certificate number "{props.schoolTagCertificate && props.schoolTagCertificate.certificateNumber || ''}".
                        </strong></p>
                    <ConditionalFragment showIf={!!props.schoolTagCertificate && !!props.schoolTagCertificate.feedback}>
                        <p>
                            Feedback provided:<br />
                            <PlainTextWithBrs text={props.schoolTagCertificate && props.schoolTagCertificate.feedback || ''} />
                        </p>
                    </ConditionalFragment>
                </Alert>
            </ConditionalFragment>
            <ConditionalFragment showIf={!!props.schoolTagCertificate && !props.schoolTagCertificate.isAwarded && !props.schoolTagCertificate.isSubmitted}>
                <Alert color="warning">
                    <p><strong>
                        The school failed {props.awardTag && props.awardTag.name || 'the review'} on <DateLabel format="DD/MM/YYYY" value={props.schoolTagCertificate && props.schoolTagCertificate.certificateDate || ''} />.
                        </strong></p>
                    <ConditionalFragment showIf={!!props.schoolTagCertificate && !!props.schoolTagCertificate.feedback}>
                        <p>
                            Feedback provided:<br />
                            <PlainTextWithBrs text={props.schoolTagCertificate && props.schoolTagCertificate.feedback || ''} />
                        </p>
                    </ConditionalFragment>
                </Alert>
            </ConditionalFragment>
        </ConditionalFragment>
        );
};
