import * as React from 'react';
import { Form, Label, FormGroup, Input, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer, EditContainerProps } from '../EditContainer';
import { AlertOnErrors } from '../../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../../shared/GoBackLinkContainer';
import { CoreFields } from './CoreFields';
import { School } from '../../../../api/models/School';
import { Guid } from 'guid-string';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const [needFullPageRefresh, setNeedFullPageRefresh] = React.useState<boolean>(false);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        // We may want to do a full refresh of the screen when we change school details to ensure menus get updated etc. (particulary important when we convert
        // a school froma potential school to a member school, or when the name changes after an Edubase lookup).
        if (needFullPageRefresh) {
            window.location.href = `/school/${props.model ? props.model.id : ''}`;
        } else {
            navigation.navigate(`/school/${props.model ? props.model.id : ''}`);
        }
    }, [props.save, navigation, needFullPageRefresh]);

    // We need some special logic for trackig changes to the model so we know what to do on save.
    const newChangeModel = React.useCallback((changes: Partial<School>) => {

        const changedKeys = Object.keys(changes);
        if (changedKeys.find(key => key === 'name')
            || changedKeys.find(key => key === 'isPotentialSchool')
        ) {
            setNeedFullPageRefresh(true);
        }

        props.changeModel(changes);
    }, [props.changeModel]);

    //const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    //    var target = event.currentTarget;
    //    newChangeModel({
    //        [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
    //    });
    //}, [newChangeModel, setNeedFullPageRefresh]);

    //const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    //    props.validate([event.currentTarget.name]);
    //}, [props.validate]);

    // Upload a report banner image.
    const [reportBannerFileSizeError, setReportBannerFileSizeError] = React.useState<string>('');
    const onUploadReportBanner = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setReportBannerFileSizeError('');

        if (!event.currentTarget.files || !event.currentTarget.files.length) {
            return;
        }

        // Limit the file size upload to 12mb as per specfieid requirements.
        const maximumFileSize = (1024 * 1024 * 12);
        if (event.currentTarget.files[0].size > maximumFileSize) {
            setReportBannerFileSizeError(`The maximum file size you can upload is 12mb.  This file is ${(event.currentTarget.files[0].size / 1024.0 / 1024.0).toFixed(1)}mb and too large to be uploaded.`);
            return;
        }

        // Upload the files as the viphotodeo.
        await props.uploadReportBanner(event.currentTarget.files);
    }, [props.uploadReportBanner, setReportBannerFileSizeError]);
    
    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }
    return (
        <div className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add school' : 'Edit school'} {props.isUpdatingFromEdubase ? (<LoadingIndicator className="inline-loading-indicator" />) : null}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.edubaseErrors]} />

            <Form onSubmit={onSubmit}>
                <div className="toolbar-top">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>

                <CoreFields {...props} updateFromEdubase={props.updateFromEdubase} isUpdatingFromEdubase={props.isUpdatingFromEdubase}
                    schoolTags={props.schoolTags} allTags={props.allTags}
                    changeModel={newChangeModel} setNeedFullPageRefresh={setNeedFullPageRefresh}
                />

                <FormGroup className="mt-4">
                    <Label htmlFor="reportBannerBlobId">
                        School logo or banner to show on the top of reports
                    </Label>
                    {
                        !props.reportBannerBlob ? (
                            <div className="video-container video-container-admin video-thumbnail">
                                <div className="nothing-here">
                                    <div>
                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                        You have not uploaded a logo or report banner yet.
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="consultant-photo-preview-container">
                                <img
                                    className="img-fluid"
                                        src={props.reportBannerBlob && props.reportBannerBlob.url || ''}
                                    alt="Photo"
                                    style={{ maxHeight: '300px' }}
                                />
                            </div>
                        )
                    }

                    <AlertOnErrors errors={[reportBannerFileSizeError]} />

                    <div>
                        <Label className="btn btn-outline-secondary file-upload-button">
                            {
                                props.isUploadingReportBanner ? (
                                    <>
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <> </><span>Uploading...</span>
                                    </>
                                ) : (
                                    <span>
                                        {
                                            Guid.isEmpty(props.model.reportBannerBlobId) ? 'Upload logo or report banner image...' : 'Update logo or report banner image...'
                                        }
                                    </span>
                                )
                            }

                            <Input type="file" name="files" onChange={onUploadReportBanner} />
                        </Label>
                    </div>
                    <FormText>
                        This image will be scaled to fill the full width of the paper containing the report.  It is therefore recommended that you upload a wide image rather than a square or tall image and include blank space within the image if required.
                    </FormText>
                </FormGroup>
            </Form>
        </div>
    );
};

export const Edit = withContainer<EditContainerProps>(EditContainer)(EditUi);

export const CreatePotentialSchool = () => (<Edit createAsPotentialSchool={true} />);