import * as React from 'react';
import { Form, FormGroup, Label, FormText, Button, Container } from 'reactstrap';
import { ValidatedInput, ValidatedFormFeedback } from 'pojo-validator-reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { JoinContainer, JoinUiProps } from './JoinContainer';
import { Redirect } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { TermsAndConditionsModal } from '../pages/TermsAndConditionsModal';

export const JoinUi = (props: JoinUiProps) => {
    const {
        save,
        changeModel,
        validate,
    } = props;

    const [termsAndConditionsModalIsOpen, setTermsAndConditionsModalIsOpen] = React.useState<boolean>(false);
    const toggleTermsAndConditionsModalOpen = React.useCallback(() => setTermsAndConditionsModalIsOpen(prevState => !prevState), [setTermsAndConditionsModalIsOpen]);

    const onSubmit = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        save();
    }, [save]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        changeModel({
            [target.name]: (target.type === 'checkbox' ? target.checked : target.value)
        });
    }, [changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        validate([event.currentTarget.name]);
    }, [validate]);

    // Redirect once we are logged in.
    if (props.isAuthenticated) {
        return (<Redirect to="/" />);
    }

    // If we are already registered redirect to login.
    if (props.alreadyRegistered) {
        return (
            <Redirect to={{
                pathname: '/account/login',
                state: {
                    user: props.model && props.model.user || '',
                    isRedirectFromJoin: 'true'
                }
            }} />
        );
    }

    // If we have loading errors (e.g. invalid tokens) then we want to tell the user the problem in a nice looking screen as it won't be exceptional enough to leave ugly.
    if (!props.model && props.loadingErrors) {
        return (
            <Container className="main-container account">
                <div className="main-heading">
                    <img className="logo img-fluid" src="/img/brand/logo-full.png" />
                </div>
                {
                //<AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                }
                    <div className="help-text">
                    {
                        props.loadingErrors.message ? `Sorry,  we couldn't get you signed up because ${props.loadingErrors.message}`
                            : "Sorry we couldn't get you signed up because of the above errors."
                    }
                </div>


                <div className="toolbar-bottom">
                    <LinkContainer to="/">
                        <Button color="primary" outline>
                            Go back to the login page
                        </Button>
                    </LinkContainer>
                </div>
            </Container>
        );
    }

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <>
            <Container className="main-container account">
                <div className="main-heading">
                    <img className="logo img-fluid" src="/img/brand/logo-full.png" />
                </div>

                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

                <div className="help-text">
                    Welcome.  Let's get you signed up...
                </div>

                <Form onSubmit={onSubmit}>
                    <FormGroup>
                        <Label htmlFor="forename">Forename</Label>
                        <ValidatedInput readOnly type="text" name="forename" placeholder="Forename" value={props.model && props.model.forename || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="surname">Surname</Label>
                        <ValidatedInput readOnly type="text" name="surname" placeholder="Surname" value={props.model && props.model.surname || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <ValidatedInput readOnly type="email" name="email" placeholder="Email" value={props.model && props.model.email || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="newPassword">Please set a password</Label>
                        <ValidatedInput type="password" name="newPassword" placeholder="New password" value={props.model && props.model.newPassword || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        <FormText>
                            {props.passwordRulesDescription}
                        </FormText>
                    </FormGroup>

                    <FormGroup check>
                        <Label check>
                            <ValidatedInput type="checkbox" name="acceptTermsAndConditions" checked={props.acceptTermsAndConditions} onChange={props.toggleAcceptTermsAndConditions} onBlur={onValidate} validationErrors={props.validationErrors} hideFormFeedback={true} />
                            I have read and accept the <a href="#" onClick={e => { e.preventDefault(); toggleTermsAndConditionsModalOpen(); }}>terms and conditions of use</a>.
                        </Label>
                        <ValidatedFormFeedback name="acceptTermsAndConditions" validationErrors={props.validationErrors['acceptTermsAndConditions'] || []} />
                    </FormGroup>

                    <div className="toolbar-bottom">
                        <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                            executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Registring...</>}>
                            Continue
                        </ButtonAsync>
                    </div>
                </Form>
            </Container>

            {
                termsAndConditionsModalIsOpen ? (
                    <TermsAndConditionsModal isOpen={termsAndConditionsModalIsOpen} toggleOpen={toggleTermsAndConditionsModalOpen} isAgreed={props.acceptTermsAndConditions} agree={() => { props.toggleAcceptTermsAndConditions(); }} />
                ) : null
            }
        </>
    );
};

export const Join = withContainer(JoinContainer)(JoinUi);
