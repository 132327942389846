import * as React from 'react';
import { Table } from 'reactstrap';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { AnonymousQuestionResponse } from '../../../api/models/AnonymousQuestionResponse';
import { StrengthsBarChartCard } from '../../projects/strengths/StrengthsBarChartCard';
import { ReportProps } from '../reportGenerator/ReportProps';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';


/**
 * SIP strengths section of the executive summary that gives the top level strengths.
 */
export const StrengthsUi = (props: ReportProps) => {
    // The strength chart needs the question responses all in the anonymous format.
    let strengthQuestionResponses = React.useMemo(() => {
        if (!props.questionResponses || !props.anonymousQuestionResponses || !props.questionChoices) {
            return [];
        }

        return [
            ...props.anonymousQuestionResponses,
            ...props.questionResponses.map(item => {
                var choice = props.questionChoices.find(it => it.id === item.questionChoiceId);
                let scale = item.scale;
                if (choice) {
                    scale = choice.operationalLevel;
                }

                return {
                    questionId: item.questionId,
                    feedback: item.feedback,
                    scale: scale,
                } as AnonymousQuestionResponse;
            })
        ];
    }, [props.anonymousQuestionResponses, props.questionResponses, props.questionChoices]);

    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <h3>Areas of strength</h3>
            <StrengthsBarChartCard
                {...props}
                projects={[props.model]}
                model={props.model}
                schools={[props.school || { id: '', name: '' }]}
                strengths={props.strengths}
                questions={props.questions}
                sections={props.allSections}
                topics={props.allTopics}
                filterBySectionOriginKey={undefined}
                filterByTopicOriginKey={undefined}
                awardTagId={props.awardTag && props.awardTag.id || undefined}
            />

            <div className="table-responsive">
                <Table striped className="table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            {/*<th><span>Topic</span></th>*/}
                            <th><span>Strength</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.strengths.map(strengthItem => {
                                let question = props.questions.find(it => it.id === strengthItem.questionId);
                                let topic = props.allTopics.find(it => it.id === strengthItem.topicId);
                                let sectionId = '';
                                if (topic) {
                                    sectionId = topic.sectionId;
                                }

                                let section = props.allSections.find(it => it.id == sectionId);

                                let questionaireId = '';
                                //let impactId = '';
                                if (question) {
                                    questionaireId = question.questionaireId;
                                    //impactId = question.impactId;
                                }

                                let questionaire = props.questionaires.find(it => it.id === questionaireId);

                                return (
                                    <tr key={strengthItem.id}>
                                        <td>{ !!question ? question.questionNumber : '' }</td>
                                        {/*<td><span>*/}
                                        {/*    <div>*/}
                                        {/*        */}{/*<strong>*/}
                                        {/*            {section && section.name} / {topic && topic.name} {questionaire ? (<span className="text-muted">(from the {questionaire.name} questionnaire)</span>) : null}*/}
                                        {/*        */}{/*</strong>*/}
                                        {/*    </div>*/}
                                        {/*    */}{/*<div>*/}
                                        {/*    */}{/*    {question && question.name || 'Whole topic'}*/}
                                        {/*    */}{/*</div>*/}
                                        {/*</span></td>*/}
                                        <td><span>
                                            <PlainTextWithBrs text={replacePlaceholders(strengthItem.description, props.placeholderReplacementMode)} />
                                        </span></td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>
        </div>        
    );
};
