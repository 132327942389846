import * as React from "react";
import { ReportProps } from "../reportGenerator/ReportProps";
import { ConditionalFragment } from "react-conditionalfragment";
import { OverviewChartUi } from "./OverviewChartUi";
import { RAGBreakdownUi } from "./RAGBreakdownUi";
import { RAGProjectWillDoUi } from "./RAGProjectWillDoUi";
import { Row, Table } from "reactstrap";
import { PlainTextWithBrs } from "../../shared/PlainTextWithBrs";
import { replacePlaceholders, PlaceholderReplacementMode } from "../../../utilities/replacePlaceholders";
import { Col } from "reactstrap";



/**
 * RAG on screen report.
 */
export const RAGScreenReport = (props: ReportProps) => {
    const {
        reportSettings,
    } = props;

  
    return (
        <>
            {/* Show school logo/banner if it has one */}
            {
                !!props.reportBannerBlobDataUrl ? (
                    <img className="img-fluid" style={{ minWidth: '100%', }} src={props.reportBannerBlobDataUrl.dataUrl} alt="School logo or banner" />
                ): null
            }

            {
                props.reportSettings.reportName.toLowerCase().indexOf('helpful questions') >= 0 ? (
                    <>
                        <Row className="mb-3" style={{ border: "2px solid lightgray"}} noGutters>
                            <Col xs={6} style={{ borderRight: "2px solid lightgray", padding: "0.6rem" }}>
                                <h5><span>Two Key Points:</span></h5>
                                <p>
                                    The curriculum is the progression model.<br />
                                    Progress is knowing more, remembering more, and being able to use knowledge and skills appropriately.
                                </p>
                            </Col>
                            <Col xs={6} style={{ padding: "0.6rem" }}>
                                <h5><span>Two Key Questions:</span></h5>
                                <p>
                                    Is the full curriculum offered?<br />
                                    Do all learners access the curriculum?
                                </p>
                            </Col>
                        </Row>
                    </>
                ) : props.reportSettings.reportName.toLowerCase() === 'phonic/reading focus | eyfs, key stage 1, key stage 2' ? (
                        <>
                            
                        </>
                    ) : props.reportSettings.reportName.toLowerCase() === 'reading report | key stage 3 and key stage 4' ? (
                            <>
                                
                            </>
                        ) : null
            }
            
            <ConditionalFragment showIf={reportSettings.breakdown}>
                <RAGBreakdownUi {...props} />
            </ConditionalFragment>
            <RAGProjectWillDoUi {...props}/>
            
        </>
    );
};
