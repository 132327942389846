import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { SearchUiProps, SearchContainer } from './SearchContainer';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';
import { LoadingIndicator } from '../../shared/LoadingIndicator';

export interface SearchModalUiProps extends SearchUiProps {
    cancel: () => void
}

export const SearchModalUi = (props: SearchModalUiProps) => {
    // Only want the 
    let openResultsOnly = React.useMemo(() => {
        if (!props.model) {
            return [];
        }

        return props.model.filter(item => item.establishmentStatus && item.establishmentStatus.displayName === 'Open');
    }, [props.model]);

    // Render the UI.
    //

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                Search DFE's database
            </ModalHeader>
            <ModalBody>
                <div className="search-bar-container">
                    <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
                </div>

                {!props.model || props.isLoading ? (<LoadingIndicator />) : null}

                <div className="table-responsive">
                    <Table striped hover className="table-sm">
                        <thead>
                            <tr>
                                <th><span className="">Name</span></th>
                                <th><span className="">URN</span></th>
                                <th><span className="">Town</span></th>
                                <th><span className="">Postcode</span></th>
                                <th><span className="">Head</span></th>
                                <th><span className="">LA</span></th>
                                <th><span className="">Phase</span></th>
                                <th><span className="">Status</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.model && !props.model.length ? (
                                    <tr className="nothing-here">
                                        <td colSpan={8}>
                                            {
                                                props.search ? (
                                                    <div>
                                                        <FontAwesomeIcon icon="search-minus" />
                                                        Sorry there are no schools or trusts matching "{props.search}".
                                        </div>
                                                ) : (
                                                        <div>
                                                            <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                            You need to search for something before we can show you matching schools and trusts.
                                        </div>
                                                    )
                                            }
                                        </td>
                                    </tr>
                                ) : null
                            }

                            {
                                openResultsOnly.map((item, index) => (
                                    <tr key={index} onDoubleClick={e => props.resultSelected(item.urn)}>
                                        <td><span className="">
                                            <Button type="button" color="link" onClick={e => props.resultSelected(item.urn)}>
                                                {item.establishmentName}
                                            </Button>
                                        </span></td>
                                        <td><span className="">{item.urn}</span></td>
                                        <td><span className="">{item.town}</span></td>
                                        <td><span className="">{item.postcode}</span></td>
                                        <td><span className="">{item.headFirstName} {item.headLastName}</span></td>
                                        <td><span className="">{item.la.displayName}</span></td>
                                        <td><span className="">{item.phaseOfEducation.displayName}</span></td>
                                        <td><span className="">{item.establishmentStatus.displayName}</span></td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <Button color="primary" outline onClick={props.cancel}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export const SearchModal = withContainer(SearchContainer)(SearchModalUi);