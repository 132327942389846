import * as React from "react";
import { ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { Page } from "../../../api/models/Page";
import { isNullOrUndefined } from "util";
import moment from 'moment';


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<Page>,
    loadViewModel: (id: string) => Promise<any>,
    newVersion: (id: string, isMajor: boolean) => Promise<Page>
}

export interface EditUiProps extends EditUiPropsBase<Page> {
    isPublished: boolean,
    isLatestVersion: boolean,
    publish: (isMajor: boolean) => Promise<boolean>,
    isPublishing: boolean,
    publishErrors: any,

    createNewVersion: (isMajor: boolean) => Promise<Page | null>,
    isCreatingNewVersion: boolean,
    createNewVersionErrors: any,

    isReadOnly: boolean,
    setIsReadOnly: (value: boolean | null) => void,
}

export const _EditContainer = (props: EditContainerProps) => {
    let { component, repository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');
    const isCreate = id ? false : true;

    const [model, setModel] = React.useState<Page | undefined>(undefined);
    const [_isReadOnly, setIsReadOnly] = React.useState<boolean | null>(null);
    const [isLatestVersion, setIsLatestVersion] = React.useState<boolean>(false);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<Page>) => {
        setModel(prevState => ({
            ...(prevState as Page),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await props.loadViewModel(id ? id : 'defaults');
        setIsLatestVersion(result.isLatestVersion);
        setModel(result.model);
        return true;
    }, [repository, setModel, id, setIsLatestVersion]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

        //if (!fieldsToCheck || fieldsToCheck.includes('description')) {
        //    validation.singleCheck('description', () => !model.description, 'Description is required');
        //}
    }, [model]);
    
    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model, isCreate);

        return true;
    }, [model, validate, repository, isCreate]);

    // Publish a new version.
    // NOTE this also performs a save.
    const [publish, { isExecuting: isPublishing, errors: publishErrors }] = useAsyncCallback(async (isMajor: boolean): Promise<boolean> => {
        if (!model) {
            return false;
        }

        // Start by doing a general save (without a publish date).
        let ok = await save();
        if (!ok) {
            return false;
        }

        // Then create a new model with the new publish date (and persist it to the react state for later).
        let publishDate = moment().toISOString();

        let changes: Partial<Page> = {
            publishDate: publishDate,
            versionNumber: (isMajor ? model.versionNumber + 1 : model.versionNumber),
            patchNumber: (isMajor ? 0 : model.patchNumber), // Was already created as +1 on the patchNumber at the time of creation, so no need to do it on publish.
        };

        var newModel: Page = {
            ...(model as Page),
            ...changes,
        };
        await changeModel(changes);

        // Finally save the new model direct with the repository (so we don't have to wait for react state to catch up.)
        repository.save(newModel.id, newModel);

        return true;
    }, [changeModel, model, repository, save]);

    // Create a new major version
    const [createNewVersion, { isExecuting: isCreatingNewVersion, errors: createNewVersionErrors }] = useAsyncCallback(async (isMajor: boolean): Promise<Page | null> => {
        if (!model) {
            return null;
        }

        let newModel = await props.newVersion(model.id, isMajor);
        return newModel;
    }, [model, props.newVersion]);

    // Have a simple way to check if the model is published or not in the props.
    let isPublished = React.useMemo(() => {
        if (!model) {
            return false;
        }

        return model.publishDate ? true : false;
    }, [model]);

    // Have a simple way to check we are in read only mode or not in the props.
    let isReadOnly = React.useMemo(() => {
        if (!isNullOrUndefined(_isReadOnly)) {
            return _isReadOnly;
        }

        if (!model) {
            return false;
        }

        return model.publishDate ? true : false;
    }, [model, _isReadOnly]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel} isCreate={isCreate}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            isPublished={isPublished}
            isLatestVersion={isLatestVersion}
            publish={publish} isPublishing={isPublishing} publishErrors={publishErrors}
            createNewVersion={createNewVersion} isCreatingNewVersion={isCreatingNewVersion} createNewVersionErrors={createNewVersionErrors}
            isReadOnly={isReadOnly} setIsReadOnly={setIsReadOnly}
            />
    );
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.pages.repository(),
    loadViewModel: services.api.pages.viewModels.edit(),
    newVersion: services.api.pages.actions.newVersion()
}))(_EditContainer);

