import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SchoolEvidence } from '../../api/models/SchoolEvidence';
import { EvidenceLockerCore } from './EvidenceLockerCore';

export interface EvidenceLockerModalProps {
    schoolId: string,
    select: (schoolEvidenceNames: Array<string>) => void,
    cancel: () => void,
    onUploadComplete?: (model: SchoolEvidence) => void,
    initialSelected: Array<string>,
    questionTopicOriginKey: string | undefined,

    subjectId: string | undefined,
    slrSubjectName: string | undefined;
}

/**
 * Evidence locker for a school.
 * @param props
 */
export const EvidenceLockerModal = (props: EvidenceLockerModalProps) => {
    const {
        schoolId,
        select,
        cancel,
        onUploadComplete,
        initialSelected,
        questionTopicOriginKey,

        subjectId,
        slrSubjectName
    } = props;

    const [selectedNames, setSelectedNames] = React.useState<Array<string>>(initialSelected);
    const isSelected = React.useCallback((name: string) => !!selectedNames.find(it => it === name), [selectedNames]);
    const toggleSelected = React.useCallback((name: string) => {
        setSelectedNames(prevValue => {
            if (prevValue.find(it => it === name)) {
                return prevValue.filter(it => it !== name);
            }

            return [...prevValue, name];
        });
    }, [setSelectedNames]);

    return (
        <Modal size="xl" isOpen={true /* If the modal is rendered we render it open */} toggle={cancel}>
            <ModalHeader toggle={cancel}>
                Evidence locker
            </ModalHeader>
            <ModalBody>
                <EvidenceLockerCore
                    schoolId={schoolId}
                    canViewHistory={false}
                    canSelect={true}
                    canRemove={false}
                    isSelected={isSelected}
                    toggleSelected={toggleSelected}
                    onUploadComplete={onUploadComplete}
                    questionTopicOriginKey={questionTopicOriginKey}
                    subjectId={subjectId}
                    isModal={true}
                    slrSubjectName={slrSubjectName }
                />
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <ConditionalFragment showIf={!!selectedNames.length}>
                    <Button color="primary" onClick={() => select(selectedNames)}>Attach {selectedNames.length} document(s)</Button>
                    <Button color="primary" outline onClick={() => cancel()}>Cancel</Button>
                </ConditionalFragment>
                <ConditionalFragment showIf={!selectedNames.length}>
                    <Button color="primary" outline onClick={() => select(selectedNames)}>Close</Button>
                </ConditionalFragment>
            </ModalFooter>            
        </Modal>
    );
};
