import { RAGDocxProps } from './RAGDocx';
import { ProjectAnalysisResultItem } from '../../../../api/models/ProjectAnalaysisResult';
import { Paragraph, HeadingLevel, AlignmentType, Table, TableRow, TableCell, WidthType, BorderStyle } from 'docx';
import { getGradeString } from '../../../../utilities/getGradeString';
import { RAGProjectTopicDocxUi } from './RAGProjectTopicDocxUi';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';


export interface RAGProjectSectionDocxUiProps extends RAGDocxProps {
    projectSectionId: string,
}

/**
 * Breakdown of strengths and recommendations by sections and topics for RAG (docx format).
 */
export const RAGProjectSectionDocxUi = (props: RAGProjectSectionDocxUiProps) => {
    // Find the appropriate items we want to work with.  This is formatted to be similar to a useMemo() because the code is taken from there
    // from within the PDF.
    const { projectSection, section, /*results,*/ projectTopics, myResults } = (() => {
        let ps = props.projectSections.models.find(item => item.id === props.projectSectionId);
        let sectionId = '';
        if (ps) {
            sectionId = ps.sectionId;
        }

        let s = props.sections.find(item => item.id === sectionId);

        //let r = props.resultsBySection.find(item => item.projectSectionId === props.projectSectionId);
        //let rr: Array<ProjectAnalysisResult> = [];
        //if (r) {
        //    rr = r.results;
        //}

        let childTopics = props.topics.filter(item => item.sectionId === sectionId);
        let pt = props.projectTopics.models.filter(item => childTopics.find(it => it.id === item.topicId));

        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (props.results && props.results.length) {
            myResults = props.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        return { projectSection: ps, section: s, /* results: rr,*/ projectTopics: pt, myResults: myResults };
    })();

    if (!props.model) {
        return null;
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return [
        // Heading
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: section && replacePlaceholders(section.name, placeholderReplacementMode, { subjectName: props.subject?.name }) || '',
                                    heading: HeadingLevel.HEADING_2,
                                })
                            ],
                        }),
                    ],
                }),
            ],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [
                100,
            ],
            borders: {
                top: {
                    style: BorderStyle.NONE,
                },
                bottom: {
                    style: BorderStyle.NONE,
                },
                left: {
                    style: BorderStyle.NONE,
                },
                right: {
                    style: BorderStyle.NONE,
                },
                insideHorizontal: {
                    style: BorderStyle.NONE,
                },
                insideVertical: {
                    style: BorderStyle.NONE,
                },
            },
        }),

        
        new Paragraph({ text: '', }), // For spacing and making editing easier.

        // Child topics.
        ...projectTopics.map((item, index) => (
            RAGProjectTopicDocxUi({ projectTopicId: item.id, isFirstTopic: (index === 0), ...props })
        ))
    ];
};
