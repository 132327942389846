import * as React from 'react';
import { ContainerComponentProps } from 'react-withcontainer';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { Repository } from 'pojo-repository';
import { useAsyncCallback } from 'react-use-async-callback';
import { GrowthPlan } from '../../../api/models/GrowthPlan';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidationState } from 'pojo-validator';
import { useUniversalNavigation } from 'react-universal-navigation';
import { withServiceProps } from 'inject-typesafe-react';
import { AppServicesCore } from '../../../configure/configureServicesCore';
import { EdubaseService } from '../../../services/EdubaseService';
import { connect } from 'react-redux';
import { AppStateCore } from '../../../store';
import { Trust } from '../../../api/models/Trust';
import moment from 'moment';
import { School } from '../../../api/models/School';

export class DurationInYearsAndDays {
    years: number;
    days: number;

    constructor(years?: number, days?: number) {
        this.years = years ? years : 0;
        this.days = days ? days : 0;
    }

    toDurationString() {
        const years = this.years;
        const days = this.days;

        //Format {Years} years and {days} days
        if (years === 0 && days === 0) {
            return "0 days";
        }

        /*if (years === 0) {
            return `${days} ${days > 1 ? 'days' : 'day'}`;
        }

        if (days === 0) {
            return `${years} ${years > 1 ? "years" : "year"}`;
        }

        return `${years} ${years > 1 ? "years" : "year"} and ${days} ${days > 1 ? 'days' : 'day'}`;
        */

        return `${days} ${days === 1 ? 'day' : 'days'}`;
    }
}

export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<GrowthPlan>,

    //From redux
    subscriptionId: string,

    //From DI
    loadViewModel: (subscriptionId: string) => Promise<any>,
    edubaseService: EdubaseService,
}

export interface EditUiProps extends EditUiPropsBase<GrowthPlan> {
    trust: Trust | undefined,
    duration: DurationInYearsAndDays,

    //Our team management
    ourTeam: Array<OurTeamItem>,
    addTeamMember: (item: OurTeamItem) => void,
    removeTeamMember: (index: number) => void,
    updateTeamMemberDetails: (index: number, item: OurTeamItem) => void,

    //Sub objectives management
    subObjectives: Array<string>,
    addObjective: () => void,
    updateSubObjective: (text: string, index: number) => void,
    deleteSubObjective: (index: number) => void,

    potentialSchools: Array<School>,
}

export interface OurTeamItem {
    name: string,
    jobTitle: string,
    expertise: string
}

export const _EditContainer = (props: EditContainerProps) => {
    const { component, subscriptionId, loadViewModel, repository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const trustId = navigation.getParam('trustId', '');

    const [model, setModel] = React.useState<GrowthPlan | undefined>(undefined);
    const [trust, setTrust] = React.useState<Trust | undefined>(undefined);
    const [ourTeam, setOurTeam] = React.useState<Array<OurTeamItem>>([
        {
            expertise: "",
            jobTitle: "",
            name: ""
        }
    ]);
    const [subObjectives, setSubObjectives] = React.useState<string[]>([]);
    const [schoolDetailsJsonString, setSchoolDetailsJsonString] = React.useState<string>('');
    const [potentialSchools, setPotentialSchools] = React.useState<Array<School>>([]);

    const addObjective = React.useCallback(() => {
        let objectives = subObjectives.map(item => item);

        objectives.push("");

        setSubObjectives(objectives);
    }, [subObjectives, setSubObjectives]);

    const updateSubObjective = React.useCallback((text: string, index: number) => {
        let updatedList = subObjectives.map((item, i) => {
            if (i === index) {
                return text;
            }

            return item
        });

        setSubObjectives(updatedList);
    }, [subObjectives, setSubObjectives]);

    const deleteSubObjective = React.useCallback((index: number) => {
        setSubObjectives(subObjectives.filter((item, i) => {
            return index !== i;
        }));
    }, [subObjectives, setSubObjectives]);


    //Callback used to calculate duration after providing dates
    const duration = React.useMemo(() => {
        if (!model || !model.startDate || !model.endDate) {
            return new DurationInYearsAndDays();
        }

        if (model.startDate === '' || model.startDate === undefined || model.startDate === null) {
            return new DurationInYearsAndDays();;
        }

        if (model.endDate === '' || model.endDate === undefined || model.endDate === null) {
            return new DurationInYearsAndDays();
        }

        let start = moment(model.startDate);
        let end = moment(model.endDate);
        let years = end.diff(start, "y");
        let days = end.diff(start, "d");
        return new DurationInYearsAndDays(years, days);

    }, [model]);

    const changeModel = React.useCallback((changes: Partial<GrowthPlan>) => {
        setModel(prevState => ({
            ...(prevState as GrowthPlan),
            ...changes
        }));
    }, [setModel]);

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (trustId === '') {
            return false;
        }
        let result = await loadViewModel(trustId);

        let ourTeam = result.model.ourTeam;
        if (ourTeam) {
            setOurTeam(JSON.parse(ourTeam));
        }

        if (result.model.subObjectives) {
            setSubObjectives(JSON.parse(result.model.subObjectives));
        }

        setModel({
            ...result.model,
            startDate: result.model.startDate ? result.model.startDate.substr(0, 10) : "",
            endDate: result.model.endDate ? result.model.endDate.substr(0, 10) : ""
        });
        setTrust(result.trust);

        if (result.schools !== undefined) {
            setPotentialSchools(result.schools);
        }

        return true;
    }, [setModel, setTrust, trustId, subscriptionId, loadViewModel, setOurTeam, setPotentialSchools]);

    const [save, { isExecuting: isSaving, errors: saveErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        //Convert and store sub-objectives as json
        let result = JSON.stringify(subObjectives);

        //Convert and store our team information as json
        let string = JSON.stringify(ourTeam);;
        let newModel = {
            ...model, ourTeam: string, subObjectives: result
        };
        await repository.save(newModel.id, newModel);
        return true;
    }, [subObjectives, repository, model, ourTeam]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        /*if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }*/
    }, [model]);

    const removeTeamMember = React.useCallback((index: number) => {
        let newList: Array<OurTeamItem> = [];
        ourTeam.forEach((item, i) => {
            if (i === index) {
                return;
            }

            newList.push(item);
        });

        setOurTeam(newList);
    }, [ourTeam, setOurTeam]);

    const addTeamMember = React.useCallback((item: OurTeamItem) => {
        let currentList = ourTeam.map(item => {
            return {
                ...item
            }
        });
        
        currentList.push(item);
        setOurTeam(currentList);
    }, [setOurTeam, ourTeam]);

    const updateTeamMemberDetails = React.useCallback((index: number, newDetails: OurTeamItem) => {
        let newList: Array<OurTeamItem> = []
        ourTeam.forEach((x, i) => {
            if (i === index) {
                newList.push({
                    ...newDetails
                });
                return;
            }

            newList.push({ ...x });
        });
        setOurTeam(newList);
    }, [ourTeam, setOurTeam]);

    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors && subscriptionId != '') {
            load();
        }
    }, [load, setModel, isLoading, loadingErrors, subscriptionId]);

    const Component = component;

    return <Component {...rest}
        model={model} changeModel={changeModel}
        trust={trust}
        load={load} isLoading={isLoading} loadingErrors={loadingErrors}
        save={save} isSaving={isSaving} savingErrors={saveErrors}
        validate={validate} validationErrors={validationErrors}
        isCreate={false}
        duration={duration}
        ourTeam={ourTeam} addTeamMember={addTeamMember} removeTeamMember={removeTeamMember} updateTeamMemberDetails={updateTeamMemberDetails}
        subObjectives={subObjectives} addObjective={addObjective} deleteSubObjective={deleteSubObjective} updateSubObjective={updateSubObjective}
        potentialSchools={potentialSchools}
    />
};

export const __EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.growthPlans.repository(),
    loadViewModel: services.api.growthPlans.viewModels.loadTrustGrowthPlan(),
    edubaseService: services.edubaseService(),
}))(_EditContainer);

export const EditContainer = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        subscriptionId: state.user.identity && state.user.identity.subscriptionId || ''
    })
)(__EditContainer);