import * as React from 'react';
import { ContainerComponentProps } from "react-withcontainer";
import { EditUiPropsBase } from "../../containers/common/EditUiPropsBase";
import { AwardTag } from "../../../api/models/AwardTag";
import { Repository } from "pojo-repository";
import { useAsyncCallback } from 'react-use-async-callback';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidationState } from 'pojo-validator';
import { withServiceProps } from 'inject-typesafe-react';
import { AppServicesCore } from '../../../configure/configureServicesCore';
import { ManagedChildModels, useManagedChildModels } from '../../shared/hooks/useManagedChildModels';
import { useApiService } from '../../../api/useApiService';
import { AwardTagTag } from '../../../api/models/AwardTagTag';
import { Tag } from '../../../api/models/Tag';


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<AwardTag>
}

export interface EditUiProps extends EditUiPropsBase<AwardTag> {
    model: AwardTag | undefined,
    save: () => Promise<boolean>,
    isSaving: boolean,

    allTags: Array<Tag>,
    awardTagTags: ManagedChildModels<AwardTagTag>,
}

export const _EditContainer = (props: EditContainerProps) => {
    const { component, repository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam("id", "");

    const [model, setModel] = React.useState<AwardTag | undefined>();
    const [tags, setTags] = React.useState<Array<Tag>>([]);

    const api = useApiService();
    const loadViewModel = api.awardTags.viewModels.edit();
    const awardTagTagsManager = useManagedChildModels(api.awardTagTags.repository());

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        const result = await loadViewModel(id);
        setTags(result.tags || []);
        awardTagTagsManager.setModels(result.awardTagTags || []);
        setModel(result.model);
        return true;
    }, [setModel, loadViewModel, setTags, awardTagTagsManager, ]);

    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, load, isLoading, loadingErrors]);

    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        // Save the main award tag.
        await repository.save(model.id, model);

        // Save changes to the selected tags.
        await awardTagTagsManager.save();

        return true;
    }, [model, repository, id, awardTagTagsManager]);

    const changeModel = React.useCallback((changes: Partial<AwardTag>) => {
        if (!model) {
            return;
        }

        setModel({
            ...model,
            ...changes
        });
    }, [model, setModel]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

    }, [model]);

    const Component = component;

    return <Component {...rest}
        load={load} isLoading={isLoading} loadingErrors={loadingErrors}
        isCreate={id === ''}
        save={save} isSaving={isSaving} savingErrors={savingErrors}
        model={model} changeModel={changeModel}
        validate={validate} validationErrors={validationErrors}
        allTags={tags} awardTagTags={awardTagTagsManager}
    />
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.awardTags.repository()
}))(_EditContainer);