import { isNullOrUndefined } from 'util';
import { getGradeString } from './getGradeString';

/**
 * Get the color (success, warning, danger, underfined) to use when comparing two percentages.
 * @param percentage
 */
export function getGradeComparisonColor(myValue: number | undefined | null, targetValue: number | undefined | null): string | undefined {
    if (isNullOrUndefined(myValue) || isNullOrUndefined(targetValue)) {
        return undefined;
    }

    // If we are meeting or beating the target we can show success.
    if (myValue >= targetValue) {
        return 'success';
    }

    // Otherwise we want to show danger (we are less than than the target and not in the same grade)
    // or warning (we are less than the target, but in the same grade).
    const myValueGrade = getGradeString(myValue);
    const targetValueGrade = getGradeString(targetValue);

    // To save us doing lots of string manipulation, if the first characters match between the two, then we consider it a match
    // as we know the grades we are working with: [I]nadeqate, [R]equires improvement, [G]ood, [O]utstanding.  This saves us
    // either duplicating the logic from getGradeString() or doing more complex string seperation, or creating a base method
    // use by both that starts to get harder to maintain.
    if (myValueGrade[0] === targetValueGrade[0]) {
        return 'warning';
    }

    return 'danger';
}