import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import * as User from "./user";

export const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    ...coreReducers()
});

export const coreReducers = () => ({
    user: User.reducer
});


export interface AppStateCore {
    user: User.UserState
}
export interface AppState extends AppStateCore {
    router: RouterState
}
