import { Store, AnyAction } from "redux";
import { ServiceResolver, ConfigureServices } from "inject-typesafe";
import { AppState } from "../store";
import { AppServicesCore, createConfigureServicesCore } from "./configureServicesCore";

export interface AppServices extends AppServicesCore {
    // UI specific services
}

/**
 * Configure services used by the app that need global configuration.
 * @param store
 */
export function createConfigureServices(store: Store<AppState>): ConfigureServices<AppServices> {
    const configureServicesCore = createConfigureServicesCore(store);

    const configureServices: ConfigureServices<AppServices> = (builder) => ({
        // Include the core services.
        ...configureServicesCore(builder),

        // UI specific services.
    });

    return configureServices;
}
