import * as React from 'react';
import { connect } from 'react-redux';
import { Input, Alert } from 'reactstrap';
import { AnyAction } from 'redux';
import { Redirect, Route, RouteProps, withRouter } from 'react-router';

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component?: any;

    role?: string,

    user: RequiredUserState
}

/**
 * Extension to Route that will redirect to login if we are not authenticated (logged in).
 * @param props
 */
export const _PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, user, ...rest } = props;

    // Return the component as we have the right permission.
    return (
        <Route
            {...rest}
            render={(routeProps: any) =>
                // If the user isn't logged in, redirect them to log in.
                !user.isAuthenticated ?
                    (
                        <Redirect
                            to={{
                                pathname: '/account/login',
                                state: { from: routeProps.location }
                            }}
                        />
                    )
                    // If the user is logged in, the areas is restricted to a specific role, and the user is not in that role, then show an error message.
                    : props.role && (!user.identity || !user.identity.roles || user.identity.roles.findIndex((item) => item == props.role) == -1) ?
                        (
                            <Alert color="danger">
                                You do not have permission to access this screen.
                            </Alert>
                        )
                        :
                        // Otherwise render the actual component.
                        (
                            <Component {...routeProps} />
                        )
            }
        />
    );
};


interface RequiredUserState {
    isAuthenticated: boolean,

    identity: {
        roles: Array<string>
    }
}

interface RequiredAppState {
    user: RequiredUserState
}

export const PrivateRoute = connect(
    // mapStateToProps
    (state: RequiredAppState) => ({
        user: state.user
    }),
    //mapDispatchToProps
    (dispatch: React.Dispatch<AnyAction>) => ({
    }),
    null,
    {
        pure: false
    }
)(_PrivateRoute);
