import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { BasicPagedListContainerProps, BasicPagedListContainer } from "../../containers/common/basicContainers/BasicPagedListContainer";
import { PagedListUiPropsBase } from "../../containers/common/PagedListUiPropsBase";

export interface ListUiProps extends PagedListUiPropsBase<any> {
}

export const ListContainer = withServiceProps<BasicPagedListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.users.viewModels.listAllSubscriptionUsers()
}))(BasicPagedListContainer);
