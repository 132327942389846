import * as React from "react";
import { ReportProps } from "../reportGenerator/ReportProps";
import { ConditionalFragment } from "react-conditionalfragment";
import { OverviewChartUi } from "./OverviewChartUi";
import { StrengthsSummaryUi } from "./StrengthsSummaryUi";
import { SEFBreakdownUi } from "./SEFBreakdownUi";

/**
 * SEF on screen report.
 */
export const SEFScreenReport = (props: ReportProps) => {
    const {
        reportSettings,
    } = props;

    return (
        <>
            {/* Show school logo/banner if it has one */}
            {
                !!props.reportBannerBlobDataUrl ? (
                    <img className="img-fluid" style={{ minWidth: '100%', }} src={props.reportBannerBlobDataUrl.dataUrl} alt="School logo or banner" />
                ): null
            }

            <ConditionalFragment showIf={reportSettings.overallResults}>
                <OverviewChartUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={reportSettings.areasOfStrength}>
                <StrengthsSummaryUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={reportSettings.breakdown}>
                <SEFBreakdownUi {...props} />
            </ConditionalFragment>
        </>
    );
};