import * as React from "react";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { withServiceProps } from "inject-typesafe-react";
import { UserState } from "../../store/user";
import { connect } from "react-redux";
import { AppStateCore } from "../../store";
import { Guid } from "guid-string";
import { Trust } from "../../api/models/Trust";
import { Repository } from "pojo-repository";
import { Subscription } from "../../api/models/Subscription";

export interface DataItem {
    id: string,
    schoolName: string,
    trustName: string,
    startDate: string,
    completedDate: string | undefined,
    isPotentialSchool: boolean
}

export interface SchoolsDropdownContainerProps extends ContainerComponentProps<SchoolsDropdownUiProps> {
    /* Passed in */
    currentSchoolId: string | undefined,

    /* From DI */
    loadModel: (subscriptionId: string, userId: string) => Promise<any>,
    repository: Repository<Trust>,
    trust?: Trust,

    /* From Redux */
    user: UserState
}

export interface SchoolsDropdownUiProps {
    model: Array<DataItem> | undefined,

    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any,

    currentSchool: DataItem | undefined | null,
    trust: Trust | undefined | null,
    
    isSingleSchoolSubscription: boolean,
    showOnlyOneSchool: boolean | undefined,
    showNothing: boolean | undefined,
}

/**
 * Container for the dropdown of projects used in the user's navigation.
 * 
 * @param props
 */
export const _SchoolsDropdownContainer = (props: SchoolsDropdownContainerProps) => {
    const { component, loadModel, repository, user, currentSchoolId, ...rest } = props;

    const [subscription, setSubscription] = React.useState<Subscription>();
    const [trust, setTrust] = React.useState<Trust>();
    const [model, setModel] = React.useState<Array<DataItem> | undefined>(undefined);

    // we want to show a button not a dropdown if the user is just a school user or the trust is single school
    const { showOnlyOneSchool, showNothing } = React.useMemo(() => {
        if (!subscription) {
            return { showOnlyOneSchool: undefined, showNothing: undefined };
        }

        if (subscription.isForAwardingOrganisation) {
            // single school trust
            return { showOnlyOneSchool: false, showNothing: true };
        }

        if (subscription.isForSingleSchool) {
            // single school trust
            return { showOnlyOneSchool: true, showNothing: false };
        }

        if (user.identity && user.identity.roles.find(item => item === 'School User') && !user.identity.roles.find(item => item === 'Manage')) {
            // school user within a multi school trust
            return { showOnlyOneSchool: true, showNothing: false };;
        }

        // default for multischool trust users
        return { showOnlyOneSchool: false, showNothing: false };;
    }, [subscription]);
    
    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        // If we have no active subscription (because we are an admin and we've stoped managing one) then use an empty array as the model without going to the database.
        if (!user.identity || !user.identity.subscriptionId) {
            setModel([]);
            return true;
        }

        if (!model) {
            let result = await loadModel(user.identity && user.identity.subscriptionId || '', user.identity && user.identity.userId || '');
            setTrust(result.trust);
            setModel(result.model);
            setSubscription(result.subscription);
        }

        return true;
    }, [user.identity, loadModel, setModel, model, repository]);

    // Find the current school.
    let currentSchool = React.useMemo(() => {
        if (!model || Guid.isEmpty(currentSchoolId)) {
            return null;
        }

        return model.find(item => item.id === currentSchoolId);
    }, [model, currentSchoolId]);


    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);


    const Component = component;
    return (
        <Component {...rest}
            model={model}
            trust={trust}
            currentSchool={currentSchool}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            isSingleSchoolSubscription={!!subscription ? subscription.isForSingleSchool : false}
            showOnlyOneSchool={showOnlyOneSchool}
            showNothing={showNothing}
        />
    );
};

export const __SchoolsDropdownContainer = withServiceProps<SchoolsDropdownContainerProps, AppServicesCore>(services => ({
    //loadModel: services.api.projects.viewModels.projectsDropdown(),
    loadModel: services.api.schools.viewModels.schoolsDropdown(),
    repository: services.api.trusts.repository(),
    getTrustFromSubscription: services.api.trusts.viewModels.getTrustFromSubscription(),
}))(_SchoolsDropdownContainer);

export const SchoolsDropdownContainer = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        user: state.user
    })
)(__SchoolsDropdownContainer);
