/**
 * Comparison types for reviews.
 */
export enum ComparisonTypes {
    Nobody = 'Nobody',
    School = 'School',
    Trust = 'Trust',
    SubjectLeader = 'SubjectLeader'
}

/**
 * All available ComparisonTypes in an array.
 */
export const comparisonTypes = [
    // Order here is important. We are going from least -> most specific.
    ComparisonTypes.Nobody,
    ComparisonTypes.Trust,
    ComparisonTypes.School,
    ComparisonTypes.SubjectLeader
];

/**
 * Returns the display name of a comparison type.
 * @param value
 */
export function comparisonTypeDisplayName(value: ComparisonTypes) {
    switch (value) {
        case ComparisonTypes.Nobody:
            return 'Nobody';
        case ComparisonTypes.School:
            return 'School';
        case ComparisonTypes.Trust:
            return 'Trust';
        case ComparisonTypes.SubjectLeader:
            return 'Subject leader';
    }
}
