import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { LoadingIndicator } from '../LoadingIndicator';

interface LoadMoreWaypointProps {
    nextPage: number;
    loadPage: { (page: number): void };
}

/**
 * Waypoint that will trigger the loadPage(nextPage) event when it becomes visible.
 * @param props
 */
export const LoadMoreWaypoint = (props: LoadMoreWaypointProps) => {
    const loadNextPage = () => {
        props.loadPage(props.nextPage);
    };

    return (
        <Waypoint key={props.nextPage} onEnter={loadNextPage}>
            <div className="load-more-waypoint">
                <LoadingIndicator />
            </div>
        </Waypoint>
    );
}
