import * as React from 'react';
import Chart from 'react-apexcharts';
import { Project } from '../../../../api/models/Project';
import { chartLargeColorPallet } from '../../../../utilities/chartLargeColorPallet';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { Guid } from 'guid-string';

export interface StrengthsPercentageBarChartProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    data: Array<StrengthsParecentageBarChartGroup>,

    showOtherSchools?: boolean,
    chartHeight?: number,
    awardTagId: string | undefined,
}

export interface StrengthsParecentageBarChartGroup {
    projectId: string,
    schoolId: string,
    name: string,
    items: Array<StrengthsPercentageBarChartItem>,
}

export interface StrengthsPercentageBarChartItem {
    originKey: string,
    label: string,
    strengthsCount: number,
    recommendationsCount: number,
}

/**
 * Bar chart showing the number of strengths vs recommendations in percentage terms grouped by topic etc.
 * @param props
 */
export const StrengthsPercentageBarChart = (props: StrengthsPercentageBarChartProps) => {
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTagId);

    // Need the unique labels.
    const uniqueLabels = React.useMemo(() => {
        let ret: Array<{ originKey: string, label: string }> = [];
        for (const project of props.data) {
            for (const item of project.items) {
                if (!ret.find(it => it.originKey === item.originKey)) {
                    ret.push({ originKey: item.originKey, label: item.label });
                }
            }
        }
        return ret;
    }, [props.data]);

    const options = React.useMemo(() => ({
        labels: uniqueLabels.map(item => item.label),

        title: {
            text: 'Compare your areas of strength against possible areas for development',
            align: "center",
        },

        colors:
            !props.model || !Guid.isEmpty(props.model.schoolId) ? [chartLargeColorPallet[1]] // If we are for a single school, use orange for this report so its different to the purple used for areas for development.
                : chartLargeColorPallet, // For multiple schools use the full colour pallet, including the purple so schools end up assigned the same colours for strengths and developments.
        yaxis: {
            labels: {
                formatter: function (val: any) {
                    return `${parseFloat(val).toFixed(0)}%`;
                }
            },
            min: 0,
            max: 100,
            title: {
                text: ['Areas of stength / questions answered', '(%)'] as any,
                style: {
                    color: 'gray',
                    fontWeight: 'normal',
                },
            },
        },

        xaxis: {
            title: {
                text: ['Section breakdown', '(Areas of strength in relation to total questions answered)', '\u205F' /* This is a "whitespace" character that is not treated as a whitespace charecter when rending and so is invisible but not omitted by the browser/SVG when rendering.  It is needed as a hack because without it the bottom of charecters for the second "real" line in the title get cut off at some screen sizes and was reported as a bug.. */] as any,
                style: {
                    color: 'gray',
                    fontWeight: 'normal',
                },
            },
            labels: {
                formatter: function (val: any) {
                    if (!props.data.length) {
                        return val;
                    }

                    const myData = props.data[0].items.find(it => it.label === val);
                    let strengthsCount = 0;
                    let totalCount = 0;
                    if (myData) {
                        strengthsCount = myData.strengthsCount;
                        totalCount = strengthsCount + myData.recommendationsCount;                        
                    }

                    return [val, `${strengthsCount} / ${totalCount}`];
                }
            },
        },

        dataLabels: {
            formatter: function (val: any) {
                return `${parseFloat(val).toFixed(0)}%`;
            }
        },

        chart: {
            toolbar: {
                show: false,
            },

            //events: {
            //    // When the user clicks on a point in the chart, drill into that item.
            //    // NOTE dataPointSelection event doesn't work on raidal charts so we have to use the click event.
            //    click: (event: any, chartContext: any, config: any) => {


            //        // If we didn't click on a datapoint, do nothing.
            //        if (isNullOrUndefined(config.dataPointIndex)) {
            //            return;
            //        }

            //        // We clicked on a datapoint so lets drill into it.
            //        let myUniqueLabel = uniqueLabels[config.dataPointIndex];
            //        if (!myUniqueLabel) {
            //            return;
            //        }
            //        let myOriginKey = myUniqueLabel.originKey;
            //        let myResult = props.data[config.seriesIndex].items.find(r => r.originKey === myOriginKey);
            //        if (!myResult) {
            //            return;
            //        }
            //        let mySchoolId = props.model && props.model.schoolId;
            //        if (!mySchoolId) {
            //            // trust project so jumping into a school report
            //            mySchoolId = props.data[config.seriesIndex].schoolId;
            //        }

            //        // Do the actual navigation
            //        let thisBaseRoute = `/school/${mySchoolId}`;
            //        let url = `${thisBaseRoute}/selfEvaluation/${props.data[config.seriesIndex].projectId}/strengthReport?strengthId=${myResult.originKey}`;
            //        navigation.navigate(url);
            //    },
            //}
        },

    } as ApexCharts.ApexOptions), [uniqueLabels, projectSettings, props.data]);

    let series = React.useMemo(() => props.data.map(project => ({
        name: project.name,
        data: uniqueLabels.map(label => {
            const match = project.items.find(it => it.originKey === label.originKey);
            if (!match) {
                return 0;
            }

            const totalCount = match.strengthsCount + match.recommendationsCount;
            if (totalCount === 0) {
                return 0;
            }

            // Conver to a % of strengths out of the total stengths + recommendations.
            return Math.round(((match.strengthsCount / totalCount) * 100.0));
        }),
    })), [props.data, uniqueLabels]);

    if (!props.data || !projectSettings.baseRoute) {
        return (<></>);
    }

    // Show the chart.
    return (
        <Chart options={options} series={series} type="bar" height={props.chartHeight ? props.chartHeight : 800} />
    );
};

