import * as React from 'react';
import { Button, Alert, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { Guid } from "guid-string";
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { LinkContainer } from 'react-router-bootstrap';
import { QuestionUi } from './QuestionUi';
import { Risk } from '../../../api/models/Risk';
import { AddToRiskReportModal } from '../risks/AddToRiskReportModal';
import { useProjectSettings } from '../../../api/useProjectSettings';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AddToImprovementPlannerModal } from '../../actions/AddToImprovementPlannerModal';
import { Action } from '../../../api/models/Action';
import moment from 'moment';
import { actionStates } from '../../../services/actionStates/actionStates';
import { AddToRecommendationsModal } from '../risks/AddToRecommendationsModal';
import { WatchVideoModal } from '../../videos/WatchVideoModal';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);
    const projectSettings = useProjectSettings(props.model && props.model.projectId || '', props.awardTagId);

    const scrollToQuestionId = navigation.getParam('questionId', '');
    const scrollToQuestionIdRef = React.useRef<HTMLDivElement>(null);

    // Use an effect to scroll to the top as we navigate between question screens.  As we are not changing component
    // the normal react process that scrolls to the top won't get triggered so we have to handle it ourselves when
    // we navigate between topics.
    // We also use this process to scroll to specific questions if questionId= is supplied as a parameter.
    const [lastScrolledToTopForId, setLastScrolledToTopForId] = React.useState<string>('');
    React.useEffect(() => {
        if (props.model && props.model.id !== lastScrolledToTopForId) {
            if (scrollToQuestionIdRef.current) {
                window.scrollTo(0, scrollToQuestionIdRef.current.offsetTop - 110 /* NOTE have to add this for padding of the top bar, but would prefer to handle another way. */)
            } else {
                window.scrollTo(0, 0);
            }
            setLastScrolledToTopForId(props.model.id);
        }
    }, [props.model, props.isLoading, props.loadingErrors, lastScrolledToTopForId, setLastScrolledToTopForId, scrollToQuestionId]);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    // Handle the ability to add things to the risk report via a modal.
    const [addToRiskReportModalCurrentModelId, setAddToRiskReportModalCurrentModelId] = React.useState<string | undefined>(undefined);

    // Open the modal that lets us add a specific to the report.
    const openAddToRiskReportModal = React.useCallback(async (initialize: Partial<{ questionId?: string, questionResponseId?: string, }>): Promise<void> => {
        if (!props.model) {
            return;
        }

        // Add a risk ready to be edited.
        let newRisk = await props.risks.addModel({
            projectId: props.model.projectId,
            topicId: props.model.topicId,

            ...initialize
        });

        // Set the current modal for the modal (which will cause the modal to display).
        setAddToRiskReportModalCurrentModelId(newRisk.id);
    }, [props.model, props.risks, setAddToRiskReportModalCurrentModelId]);

    // Handle the ability to add things to the sdp recommendations via a modal.
    const [addToRecommendationsModalCurrentModelId, setAddToRecommendationsModalCurrentModelId] = React.useState<string | undefined>(undefined);
    // Open the modal that lets us add to the recommendation.
    const openAddToRecommendationsModal = React.useCallback(async (initialize: Partial<{ questionId?: string, questionResponseId?: string, }>): Promise<void> => {
        if (!props.model) {
            return;
        }
        
        // Add a risk ready to be edited.
        let newRisk = await props.risks.addModel({
            projectId: props.model.projectId,
            topicId: props.model.topicId,

            ...initialize
        });

        // Set the current modal for the modal (which will cause the modal to display).
        setAddToRecommendationsModalCurrentModelId(newRisk.id);
    }, [props.model, props.risks, setAddToRecommendationsModalCurrentModelId]);

    // Save the new risk we are trying to add to the risk report.
    const saveNewRisk = React.useCallback(async (id: string, riskModel: Risk): Promise<boolean> => {
        let ok = await props.risks.save();
        if (!ok) {
            return false;
        }

        // Clear the current modal for the modal (which will cause the modal to close).
        setAddToRiskReportModalCurrentModelId(undefined);
        setAddToRecommendationsModalCurrentModelId(undefined);

        return true;
    }, [props.risks, setAddToRiskReportModalCurrentModelId, setAddToRecommendationsModalCurrentModelId]);

    // Cancel the last risk we were trying to add to the risk report.
    const cancelNewRisk = React.useCallback((id: string, riskModel: Risk): void => {
        props.risks.removeModel(id);

        // Clear the current modal for the modal (which will cause the modal to close).
        setAddToRiskReportModalCurrentModelId(undefined);
        setAddToRecommendationsModalCurrentModelId(undefined);
    }, [props.risks, setAddToRiskReportModalCurrentModelId, setAddToRecommendationsModalCurrentModelId]);

    const topicsRisk = React.useMemo(() => {
        return props.risks.models.find(it => it.topicId === it.topicId && Guid.isEmpty(it.questionId));
    }, [props.risks.models]);

    // use the modal to add this to the improvement planner
    const [addToImprovementPlannerModalCurrentModelId, setAddToImprovementPlannerModalCurrentModelId] = React.useState<string | undefined>(undefined);

    const openAddToImprovementPlannerModal = React.useCallback(async (initialize: Partial<{ projectId?: string, questionId?: string, name?: string, }>): Promise<void> => {
        if (!props.project) {
            return;
        }
        
        // Add an action ready to be edited.
        let newAction = await props.actions.addModel({
            trustId: props.project.trustId || '',
            schoolId: props.project.schoolId || '',
            name: '',
            description: '',
            archived: false,
            isAutomaticFromProject: false,
            actionAddedDate: moment().add(1, 'month').toISOString(),
            actionState: actionStates.outstanding.id,
            completedDate: null,
            notes: '',

            ...initialize
        });

        // Set the current modal for the modal (which will cause the modal to display).
        setAddToImprovementPlannerModalCurrentModelId(newAction.id);
    }, [props.project, setAddToImprovementPlannerModalCurrentModelId]);

    // Save the new action we are trying to add to the improvement planner.
    const saveNewAction = React.useCallback(async (id: string, actionModel: Action): Promise<boolean> => {
        let ok = await props.actions.save();
        if (!ok) {
            return false;
        }

        // Clear the current model for the modal (which will cause the modal to close).
        setAddToImprovementPlannerModalCurrentModelId(undefined);

        return true;
    }, [props.actions, setAddToImprovementPlannerModalCurrentModelId]);

    // Cancel the last action we were trying to add to the improvement planner.
    const cancelNewAction = React.useCallback((id: string, actionModel: Action): void => {
        props.actions.removeModel(id);

        // Clear the current model for the modal (which will cause the modal to close).
        setAddToImprovementPlannerModalCurrentModelId(undefined);
    }, [props.actions, setAddToImprovementPlannerModalCurrentModelId]);

    // Viewing of micro consultation videos.
    const [activeVideoId, setActiveVideoId] = React.useState<string | undefined>();
    const watchVideo = React.useCallback((videoId: string) => {
        setActiveVideoId(videoId);
    }, [setActiveVideoId]);
    const closeVideo = React.useCallback(() => setActiveVideoId(undefined), [setActiveVideoId]);
    

    // render UI
    //

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.selectingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    if (!props.hasPermission) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.selectingErrors]} />
                <Alert color="danger">
                    You do not have permission to view this topic.
                </Alert>
            </>
        );
    }

    return (
        <>
            <div>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

                <div>
                    {
                        props.questions.map((item, index) => {
                            let isPreviousKey = index > 0 && props.questions[index - 1].isKeyQuestion ? true : false;
                            let switchFromKeyQuestions = isPreviousKey && props.hasKeyQuestions && !item.isKeyQuestion ? true : false;

                            let questionChoices = props.questionChoices.filter(it => it.questionId === item.id);
                            let response = props.questionResponses.models.find(it => it.questionId === item.id);

                            let risk = props.risks.models.find(it => it.questionId === item.id);
                            let strength = props.strengths.models.find(it => it.questionId === item.id);
                            let action = props.actions.models.find(it => it.questionId === item.id);

                            const myQuestionResponseSchoolEvidences = props.questionResponseSchoolEvidences.models.filter(it => !it.archived && response && it.questionResponseId === response.id);
                            const mySchoolEvidences = props.schoolEvidences.models.filter(it => !!myQuestionResponseSchoolEvidences.find(itt => itt.schoolEvidenceName === it.name));

                            const myVideo = props.videos.find(it => it.id === item.videoId);
                            const myConsultant = props.consultants.find(it => !!myVideo && it.id === myVideo.consultantId);
                            const myVideoThumbnailBlob = props.videoThumbnailBlobs.find(it => !!myVideo && it.id === myVideo.thumbnailBlobId);
                            const myConsultantPhotoBlob = props.consultantPhotoBlobs.find(it => !!myConsultant && it.id === myConsultant.photoBlobId);

                            let openActionUrlEnd = !!props.project && !!props.project.schoolId ? 'schoolImprovementPlanner' : 'improvementPlanner';
                            return (
                                <div key={item.id} ref={scrollToQuestionId === item.id ? scrollToQuestionIdRef : null} className={scrollToQuestionId === item.id ? 'auto-scroll-selected' : ''}> {/* we use this div with a ref to help us scroll to the question when we're directing to a single question via ShowQuestion. */}
                                    <QuestionUi model={item}
                                        schoolId={props.project && props.project.schoolId || ''}
                                        topic={props.topic}
                                        section={props.section}
                                        questionChoices={questionChoices}
                                        selectedQuestionChoiceId={response && response.questionChoiceId || ''}
                                        selectQuestionChoice={(questionChoiceId: string) => props.selectQuestionChoice(item.id, questionChoiceId)}
                                        feedbackText={response && response.feedback || ''} changeFeedbackText={(value: string) => props.changeFeedbackText(item.id, value)} saveFeedbackText={(feedback: string) => props.saveFeedbackText(item.id, feedback)}
                                        willDoText={response && response.willDoText || ''} changeWillDoText={(value: string) => props.changeWillDoText(item.id, value)} saveWillDoText={(willDoText: string) => props.saveWillDoText(item.id, willDoText)}
                                        willDoDate={response && response.willDoDate || ''} changeWillDoDate={(value: string | null) => props.changeWillDoDate(item.id, value)} saveWillDoDate={(willDoDate: string | null) => props.saveWillDoDate(item.id, willDoDate)}
                                        openAddToSPDRecommendationsModal={() => { openAddToRecommendationsModal({ questionId: item.id, questionResponseId: response && response.id || '', }); }}
                                        openAddToRiskReportModal={() => { openAddToRiskReportModal({ questionId: item.id, questionResponseId: response && response.id || '', }); }}
                                        openAddToImprovementPlannerModal={() => { openAddToImprovementPlannerModal({ projectId: props.model && props.model.projectId || '', questionId: item.id, name: item.riskReportDescription, }); }}
                                        isOnRiskRegister={risk ? true : false}
                                        IsOnImprovementPlan={action ? true : false}
                                        openOnRiskRegister={() => navigation.navigate(`${projectSettings.baseRoute.replace('/dueDiligence/', '/dueDiligenceRiskReport/')}?riskId=${risk && risk.id || ''}`)}
                                        openOnImprovementPlanner={() => navigation.navigate(`/school/${props.project && props.project.schoolId || ''}/improvementPlanner?actionId=${action && action.id || ''}`)}
                                        isOnStrengthReport={strength ? true : false}
                                        isExcellenceStatementOnStrengthReport={!!strength && strength.isExcellence}
                                        allResponses={props.questionResponses} response={response} responseId={response ? response.id : undefined}
                                        saveSchoolEvidenceNames={async (names) => { await props.saveSchoolEvidenceNames(item.id, names); }}
                                        projectSettings={projectSettings}
                                        hasKeyQuestions={props.hasKeyQuestions}
                                        switchFromKeyQuestions={switchFromKeyQuestions}
                                        nextTopic={props.nextTopic}
                                        reload={async () => { props.load(); }}
                                        fileBlobs={props.fileBlobs}
                                        schoolEvidences={mySchoolEvidences}
                                        video={myVideo} videoThumbnailBlob={myVideoThumbnailBlob}
                                        consultant={myConsultant} consultantThumbnailBlob={myConsultantPhotoBlob}
                                        watchVideo={() => watchVideo(myVideo && myVideo.id || '')}
                                        currentTier={props.currentTier}
                                        awardTagId={props.awardTagId}
                                        placeholderReplacementMode={props.placeholderReplacementMode}
                                        subjectName={props.subjectName}
                                        subjectId={props.subjectId}
                                    />
                                </div>
                            );
                        })
                    }
                </div>

                <Row className="toolbar-bottom">
                    <Col xs="auto">
                        {
                            props.previousTopic ? (
                                <LinkContainer to={`${projectSettings.baseRoute}/topic/${props.previousTopic.id}${props.tierParam ? `/tier/${props.tierParam}` : ''}`}>
                                    <Button color="primary" outline>
                                        <FontAwesomeIcon icon="chevron-left" /> Previous - {props.previousTopic.name}
                                    </Button>
                                </LinkContainer>
                            ) : null
                        }
                    </Col>
                    <Col>
                        {/* This autosized column is empty to keep the buttons seperate. */}
                    </Col>
                    <Col xs="auto">
                        {
                            props.nextTopic ? (
                                <LinkContainer to={`${projectSettings.baseRoute}/topic/${props.nextTopic.id}${props.tierParam ? `/tier/${props.tierParam}` : ''}`}>
                                    <Button color="primary">
                                        Next - {props.nextTopic.name} <FontAwesomeIcon icon="chevron-right" />
                                    </Button>
                                </LinkContainer>
                            ) : (
                                    <LinkContainer to={
                                        props.awardTagId ? `/school/${props.project && props.project.schoolId || ''}/awardHome/${props.awardTagId}/${props.project && props.project.id}`
                                            : projectSettings.isDueDiligence ? `/school/${props.project && props.project.schoolId || ''}/home`
                                                : props.project?.subjectId ? `/school/${props.project && props.project.schoolId || ''}/subjectHome/${props.project.subjectId}`
                                                : `/school/${props.project && props.project.schoolId || ''}/home`
                                    }>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="flag-checkered" /> Finish
                                        </Button>
                                    </LinkContainer>
                                    )
                        }
                    </Col>
                </Row>
            </div>

            {
                // If we are to show the AddToRiskRegisterModal do so here.
                addToRiskReportModalCurrentModelId ? (
                    <AddToRiskReportModal model={props.risks.models.find(it => it.id === addToRiskReportModalCurrentModelId)} changeModel={(changes) => props.risks.change(addToRiskReportModalCurrentModelId, changes)} save={saveNewRisk} cancel={cancelNewRisk} />
                ) : null
            }
            {
                // If we are to show the AddToImprovementPlannerModal do so here.
                addToImprovementPlannerModalCurrentModelId ? (
                    <AddToImprovementPlannerModal
                        model={props.actions.models.find(it => it.id === addToImprovementPlannerModalCurrentModelId) as Action} changeModel={(changes) => props.actions.change(addToImprovementPlannerModalCurrentModelId, changes)} save={saveNewAction} cancel={cancelNewAction} />
                ) : null
            }
            {
                // If we are to show the AddToRecommendationsModal do so here.
                addToRecommendationsModalCurrentModelId ? (
                    <AddToRecommendationsModal model={props.risks.models.find(it => it.id === addToRecommendationsModalCurrentModelId)} changeModel={(changes) => props.risks.change(addToRecommendationsModalCurrentModelId, changes)} save={saveNewRisk} cancel={cancelNewRisk} />
                ) : null
            }

            <ConditionalFragment showIf={!!activeVideoId}>
                <WatchVideoModal
                    id={activeVideoId || ''}
                    toggle={() => closeVideo()}
                    baseUrl={`${projectSettings.baseRoute}/microConsultations`}
                />
            </ConditionalFragment>
        </>
    );
};

export const Edit = withContainer<any, any>(EditContainer)(EditUi);

