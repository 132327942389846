import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { Project } from "../../../api/models/Project";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { withServiceProps } from "inject-typesafe-react";
import { IdAndName } from "../../../api/models/IdAndName";
import { ProjectAnalysisResult } from "../../../api/models/ProjectAnalaysisResult";
import { ProjectAnalysisLevel } from "../../../api/models/ProjectAnalaysisLevel";
import { SchoolTerm } from "../../../api/models/SchoolTerm";
import { ManagedChildModels, useManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { ProjectSection } from "../../../api/models/ProjectSection";
import { Section } from "../../../api/models/Section";
import { Repository } from "pojo-repository";
import { AwardTag } from "../../../api/models/AwardTag";
import { School } from "../../../api/models/School";
import { PlaceholderReplacementMode } from "../../../utilities/replacePlaceholders";
import { Subject } from "../../../api/models/Subject";


export interface RiskChartItem {
    id: string,
    projectId: string,
    description: string,
    impactMultiplier: number,
    operationalLevel: number,
    schoolId: string
}

export interface DashboardContainerProps extends ContainerComponentProps<DashboardUiProps> {
    loadModel: (id: string | undefined, awardTagId: string | undefined, analysisLevel: ProjectAnalysisLevel, sectionOriginKey: string | undefined, topicOriginKey: string | undefined, questionaireOriginKey: string | undefined) => Promise<any>
    projectSectionsRepository: Repository<ProjectSection>,
    subjectRepository: Repository<Subject>,

    id?: string,
    graphsOnly?: boolean,
}

export interface DashboardUiProps {
    model: Project | undefined,
    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any

    trust: IdAndName | undefined,
    school: School | undefined,

    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,

    results: Array<ProjectAnalysisResult>,

    section: IdAndName | undefined,
    topic: IdAndName | undefined,

    subject: Subject | undefined,

    risks: Array<RiskChartItem>,

    schoolTerms: Array<SchoolTerm>,
    timelineResults: Array<ProjectAnalysisResult>,

    overviewOnly: boolean,

    projectSections: ManagedChildModels<ProjectSection>,
    sections: Array<Section>,

    isForSingleSchool: boolean,

    awardTag: AwardTag | undefined,
    placeholderReplacementMode: PlaceholderReplacementMode,

    subjectResults: Array<ProjectAnalysisResult>,

    allowCrossSchoolComparison: boolean,
    allowCrossSubjectComparison: boolean,
}

/**
 * Container for the home page of a Project.
 * 
 * @param props
 */
export const _DashboardContainer = (props: DashboardContainerProps) => {
    const { component, loadModel, projectSectionsRepository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = props.id || navigation.getParam('projectId', '');
    const sectionOriginKey = navigation.getParam('sectionOriginKey', '');
    const topicOriginKey = navigation.getParam('topicOriginKey', '');
    const awardTagId = navigation.getParam('awardTagId', '');

    const [model, setModel] = React.useState<any | undefined>(undefined);
    const [trust, setTrust] = React.useState<IdAndName | undefined>(undefined);
    const [school, setSchool] = React.useState<School | undefined>(undefined);
    const [results, setResults] = React.useState<Array<ProjectAnalysisResult>>([]);
    const [timelineResults, setTimelineResults] = React.useState<Array<ProjectAnalysisResult>>([]);
    const [section, setSection] = React.useState<IdAndName | undefined>(undefined);
    const [topic, setTopic] = React.useState<IdAndName | undefined>(undefined);
    const [risks, setRisks] = React.useState<Array<RiskChartItem>>([]);
    const [schoolTerms, setSchoolTerms] = React.useState<Array<SchoolTerm>>([]);
    const [lastSectionOriginKey, setLastSectionOriginKey] = React.useState<string>(sectionOriginKey);
    const [lastTopicOriginKey, setLastTopicOriginKey] = React.useState<string>(topicOriginKey);
    const [sections, setSections] = React.useState<Array<Section>>([]);
    const projectSections = useManagedChildModels<ProjectSection>(projectSectionsRepository);
    const [isForSingleSchool, setIsForSingleSchool] = React.useState<boolean>(false);
    const [awardTag, setAwardTag] = React.useState<AwardTag | undefined>(undefined);
    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');
    const [subjectResults, setSubjectResults] = React.useState<Array<ProjectAnalysisResult>>([]);
    const [subject, setSubject] = React.useState<Subject | undefined>(undefined);
    const [allowCrossSchoolComparison, setAllowCrossSchoolComparison] = React.useState<boolean>(false);
    const [allowCrossSubjectComparison, setAllowCrossSubjectComparison] = React.useState<boolean>(false);


    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        // Work out the analysis level based on the filters.
        let anaysisLevel = ProjectAnalysisLevel.Section;
        if (topicOriginKey) {
            anaysisLevel = ProjectAnalysisLevel.Question;
        } else if (sectionOriginKey) {
            anaysisLevel = ProjectAnalysisLevel.Topic;
        }

        let result = await loadModel(id || 'defaults', awardTagId, anaysisLevel, sectionOriginKey, topicOriginKey, undefined /* quesitonaireId */);
        setTrust(result.trust);
        setSchool(result.school);
        setResults(result.results);
        setSection(result.section);
        setTopic(result.topic);
        setRisks(result.risks);
        setSchoolTerms(result.schoolTerms);
        setTimelineResults(result.timelineResults);
        setSections(result.sections);
        projectSections.setModels(result.projectSections);
        setAwardTag(result.awardTag);
        setSubjectResults(result.subjectResults);
        setSubject(result.subject);

        let subscription = result.subscription;
        setIsForSingleSchool(subscription && subscription.isForSingleSchool ? true : false);
        // Store the last section and topic we loaded for.
        setLastSectionOriginKey(sectionOriginKey);
        setLastTopicOriginKey(topicOriginKey);

        setPlaceholderReplacementMode(result.placeholderReplacementMode);

        setAllowCrossSchoolComparison(result.allowCrossSchoolComparison);
        setAllowCrossSubjectComparison(result.allowCrossSubjectComparison);

        setModel(result.model);
        return true;
    }, [id, loadModel, setModel, setSchool, setTrust, setResults, sectionOriginKey, topicOriginKey, setSection, setTopic,
        setRisks, setLastSectionOriginKey, setLastTopicOriginKey, setSections, projectSections, setIsForSingleSchool,
        awardTagId, setAwardTag, setPlaceholderReplacementMode, setSubjectResults,
        setAllowCrossSchoolComparison, setAllowCrossSubjectComparison,
    ]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if (
            (
                !model || (id !== model.id)
                || (awardTag && awardTagId !== awardTag.id)
                || (sectionOriginKey !== lastSectionOriginKey)
                || (topicOriginKey !== lastTopicOriginKey)
        ) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load, awardTag, id, awardTagId, lastSectionOriginKey, lastTopicOriginKey, sectionOriginKey, topicOriginKey]);


    const Component = component;
    return (
        <Component {...rest} model={model}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            trust={trust} school={school}
            results={results}
            filterBySectionOriginKey={sectionOriginKey}
            filterByTopicOriginKey={topicOriginKey}
            section={section} topic={topic}
            risks={risks}
            schoolTerms={schoolTerms}
            timelineResults={timelineResults}
            overviewOnly={props.graphsOnly || false}
            sections={sections}
            projectSections={projectSections}
            isForSingleSchool={isForSingleSchool}
            awardTag={awardTag}
            placeholderReplacementMode={placeholderReplacementMode}
            subjectResults={subjectResults}
            subject={subject}
            allowCrossSchoolComparison={allowCrossSchoolComparison}
            allowCrossSubjectComparison={allowCrossSubjectComparison}
        />
    );
};

export const DashboardContainer = withServiceProps<DashboardContainerProps, AppServicesCore>(services => ({
    loadModel: services.api.projects.viewModels.dashboard(),
    projectSectionsRepository: services.api.projectSections.repository(),
}))(_DashboardContainer);

