import * as React from 'react';
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { PagedListUiPropsBase } from "../../containers/common/PagedListUiPropsBase";
import { ContainerComponentProps } from "react-withcontainer";
import { LoadPageResult } from "../../containers/common/basicContainers/BasicPagedListContainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { useApiService } from '../../../api/useApiService';
import { School } from '../../../api/models/School';
import { Subscription } from '../../../api/models/Subscription';

export interface ListUiProps extends PagedListUiPropsBase<any> {
    trustId: string,
    isForSingleSchool: boolean,
    schoolId: string,
}

export interface ListContainerProps extends ContainerComponentProps<ListUiProps> {
    /* From dependency injection */
    loadPage: (trustId: string, page: number, search?: string) => Promise<LoadPageResult<any>>
    getFirstSchoolFromTrust: (trustId: string) => Promise<School | null>,
    getSubscriptionFromTrust: (trustId: string) => Promise<Subscription | null>,
}

export const _ListContainer = (props: ListContainerProps) => {
    const { component, loadPage, getFirstSchoolFromTrust, getSubscriptionFromTrust, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const trustId = navigation.getParam('trustId', '');

    const [model, setModel] = React.useState<Array<any> | undefined>(undefined);
    const [search, setSearch] = React.useState<string>(navigation.getParam('search', ''));
    const [nextPage, setNextPage] = React.useState<number>(0);

    // Callback for changing the next page.
    const changeNextPage = React.useCallback((page: number) => {
        setNextPage(page);
    }, [setNextPage]);

    const api = useApiService();
    const trustRepository = api.trusts.repository();

    const [schoolId, setSchoolId] = React.useState<string>('');

    const [isForSingleSchool, setIsForSingleSchool] = React.useState<boolean>(false);

    // Wrap the loadPage method to have correct async behaviour.
    const [wrappedLoadPage, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (page: number, searchText?: string): Promise<boolean> => {
        let result = await loadPage(trustId, page, searchText);
        setModel(result.model);
        setNextPage(result.nextPage);

        return true;
    }, [loadPage, setModel, setNextPage, trustId]);

    // Callback for changing the search.  This will also load the first page for the new search.
    const changeSearch = React.useCallback((search: string) => {
        setSearch(search);
        wrappedLoadPage(1, search);
    }, [setSearch, wrappedLoadPage]);

    // set up the single school information in case we have a single school trust
    const setupSingleSchool = React.useCallback(async () => {
        const thisTrust = await trustRepository.find(trustId);
        const thisSchool = await getFirstSchoolFromTrust(thisTrust.id);
        const thisSubscription = await getSubscriptionFromTrust(thisTrust.id);
        setIsForSingleSchool(thisSubscription && thisSubscription.isForSingleSchool || false);
        // only set the school if this is a single school trust
        setSchoolId(thisSchool && thisSubscription && thisSubscription.isForSingleSchool ? thisSchool.id : '');
    }, [trustRepository, trustId, getFirstSchoolFromTrust, getSubscriptionFromTrust, setSchoolId, setIsForSingleSchool]);


    // Load the first page on mount if we haven't got a model.
    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            wrappedLoadPage(1, search);
            setupSingleSchool();
        }
    }, [model, isLoading, loadingErrors, wrappedLoadPage, search]);

    const Component = component;
    return (
        <Component {...rest}
            model={model}
            loadPage={wrappedLoadPage} isLoading={isLoading} loadingErrors={loadingErrors}
            search={search} changeSearch={changeSearch}
            nextPage={nextPage} changeNextPage={changeNextPage}
            trustId={trustId}
            isForSingleSchool={isForSingleSchool}
            schoolId={schoolId}
        />
    );
};

export const ListContainer = withServiceProps<ListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.users.viewModels.listTrustUsers(),
    getFirstSchoolFromTrust: services.api.schools.viewModels.getFirstSchoolFromTrust(),
    getSubscriptionFromTrust: services.api.subscriptions.viewModels.getSubscriptionFromTrust(),
    subjectsRepository: services.api.subjects.repository(),
}))(_ListContainer);
