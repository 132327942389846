import { withServiceProps } from "inject-typesafe-react";
import { BasicRepositoryDeleteContainerProps, BasicRepositoryDeleteContainer } from "../../containers/common/basicRepositoryContainers/BasicRepositoryDeleteContainer";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { DeleteUiPropsBase } from "../../containers/common/DeleteUiPropsBase";
import { AwardTag } from "../../../api/models/AwardTag";

export interface DeleteUiProps extends DeleteUiPropsBase<AwardTag> {
}

export const DeleteContainer = withServiceProps<BasicRepositoryDeleteContainerProps, AppServicesCore>(services => ({
    repository: services.api.awardTags.repository()
}))(BasicRepositoryDeleteContainer);