// Import the fontawsome icons we actually want to use.

import { Library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faCogs, faLock, faShieldAlt, faUser, faPlus, faEdit, faSave, faSearch, faUsers, faEnvelope, faSearchMinus, faCamera, faBomb, faTrashAlt, faQuestion, faCaretDown, faCaretUp, faSchool, faArchive, faSolarPanel, faHandshake, faMailBulk, faComments, faCommentDots, faCommentAlt, faPrint, faQuoteRight, faDiceD20, faEyeSlash, faChalkboardTeacher, faCheckCircle, faChevronLeft, faChevronRight, faTachometerAlt, faAtom, faQuestionCircle, faIdBadge, faExclamationTriangle, faExclamationCircle, faFlagCheckered, faFileDownload, faPollH, faPortrait, faHistory, faChevronDown, faChevronUp, faPlusSquare, faCode, faTrashRestore, faSeedling, faTasks, faTag, faCalendarAlt, faCheck, faCalendarPlus, faUndo, faThumbsUp, faTimes, faKey, faCloudUploadAlt, faCaretRight, faCertificate, faPaperPlane, faFrown, faReplyAll, faAddressCard, faVideo, faPlay, faFileVideo, faHome, faHeartbeat, faPassport, faMinus, faFileSignature, faCompress, faUserFriends, faFill, faHighlighter, faCheckDouble, faCheese, faPlayCircle, faFileAlt, faStopwatch, faDownload, faUpload, faBookOpen, faBookReader, faBrain, faHandHoldingHeart, faRulerCombined, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { faBell as farBell, faEnvelope as farEnvelope, faFrownOpen as farFrownOpen, faCheckSquare as farCheckSquare, faSquare as farSquare, faCaretSquareRight as farCaretSquareRight, faSmile as farSmile, faCalendarAlt as farCalendarAlt, faFilePdf, faPlusSquare as farPlusSquare, faMinusSquare as farMinusSquare, faFrown as farFrown } from '@fortawesome/free-regular-svg-icons';

export const initializeIcons = (library: Library) => {
    library.add(
        // solid
        faUpload,
        faSpinner,
        faCertificate,
        faCheese,
        faCogs,
        faUser,
        faShieldAlt,
        faLock,
        faPlus,
        faEdit,
        faSave,
        faSearch,
        faUsers,
        faEnvelope,
        faSearchMinus,
        faCamera,
        faBomb,
        faTrashAlt,
        faQuestion,
        faCaretDown,
        faCaretRight,
        faCaretUp,
        faSchool,
        faHandshake,
        faArchive,
        faSolarPanel,
        faMailBulk,
        faCommentAlt,
        faCommentDots,
        faPrint,
        faQuoteRight,
        faDiceD20,
        faEyeSlash,
        faChalkboardTeacher,
        faCheckCircle,
        faChevronLeft,
        faChevronRight,
        faTachometerAlt,
        faAtom,
        faQuestionCircle,
        faIdBadge,
        faExclamationTriangle,
        faExclamationCircle,
        faFlagCheckered,
        faFilePdf,
        faFileDownload,
        faPollH,
        faPortrait,
        faHistory,
        faCode,
        faTrashRestore,
        faSeedling,
        faTasks,
        faTag,
        faTimes,
        faCalendarAlt,
        faCheck,
        faCalendarPlus,
        faCheckCircle,
        faUndo,
        faThumbsUp,
        faTimes,
        faKey,
        faCloudUploadAlt,
        faPaperPlane,
        faFrown,
        faReplyAll,
        faAddressCard,
        faVideo,
        faPlay,
        faFileVideo,
        faHome,
        faHeartbeat,
        faPassport,
        faMinus,
        faFileSignature,
        faCompress,
        faHeartbeat,
        faUserFriends,
        faFill,
        faHighlighter,
        faCheckDouble,
        faPlayCircle,
        faFileAlt,
        faStopwatch,
        faBookOpen,
        faBookReader,
        faBrain,
        faHandHoldingHeart,
        faRulerCombined,
        faLayerGroup,


        // regular
        farBell,
        farEnvelope,
        farFrownOpen,
        farCheckSquare,
        farSquare,
        farCaretSquareRight,
        farSmile,
        farCalendarAlt,
        farPlusSquare,
        farMinusSquare,
        farFrown,
    );
};
