import * as React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { Styles } from '../../executiveSummary/pdf/Styles';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReportProps } from '../../reportGenerator/ReportProps';
import { OverviewChartPdfUi } from './OverviewChartPdfUi';
import { ImageEnumerator, ImageWithMetadata } from '../../executiveSummary/pdf/ImageEnumerator';
import { RAGBreakdownPdfUi } from './RAGBreakdownPdfUi';
import { RAGProjectWillDoPdfUi } from './RAGProjectWillDoPdfUi';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { PlainTextWithBrs } from '../../../shared/PlainTextWithBrs';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

export interface RAGPdfProps extends ReportProps {
    chartImages: Array<ImageWithMetadata>
}

/**
 * RAG report
 */
export const RAGPdf = (props: RAGPdfProps) => {
    const { projectSettings, reportSettings } = props;
    const imageEnumerator = new ImageEnumerator(props.chartImages);

    if (!props.model) {
        return (
            <Document>
                <Page size="A4">
                    <Text>PDF could not be generated.</Text>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page size="A4" style={{ ...Styles.page, paddingTop: 20, paddingBottom: 20 }}>
                <View style={Styles.body}>
                    <View>
                        <Text style={Styles.h1}>
                            <Text>
                                {
                                    props.school ? props.school.name
                                        : props.trust ? props.trust.name
                                            : ''
                                }
                            </Text>
                        </Text>
                        <Text style={{ ...Styles.h1Small }}>Evaluate-Ed {
                            `${replacePlaceholders(props.reportSettings.reportName, props.placeholderReplacementMode, { subjectName: props.subject?.name })}`
                        }</Text>
                    </View>
                    
                    {/* Show school logo/banner if it has one */}
                    {
                        !!props.reportBannerBlobDataUrl ? (
                            <Image src={props.reportBannerBlobDataUrl.dataUrl} style={{ marginBottom: 4 }} />
                        ) : null
                    }
                    {/*Show report specific kibble if needed*/ }
                    {
                        props.reportSettings.reportName.toLowerCase().indexOf('helpful questions') >= 0 ? (
                            <View style={{ ...Styles.table, marginBottom: '2' }}>
                                <View style={Styles.thead}>
                                    <View style={{ ...Styles.trhead, flexDirection: 'row' }}>
                                        <View style={{ ...Styles.th, width: '50%' }}><Text>{'Two Key Points:'}</Text></View>
                                        <View style={{ ...Styles.th, width: '50%' }}><Text>{'Two Key Questions:'}</Text></View>
                                    </View>
                                </View>

                                <View style={{ ...Styles.tbody, flexDirection: 'row' }}>
                                    <View style={{...Styles.th, width: '50%' }}>
                                        <PlainTextWithBrsPdf text={"The curriculum is the progression model\nProgress is knowing more, remembering more, and being able to use knowledge and skills appropriately." }></PlainTextWithBrsPdf>
                                    </View>
                                    <View style={{...Styles.th, width:'50%' }}>
                                        <PlainTextWithBrsPdf text={"Is the full curriculum offered?\nDo all learners access the curriculum?"}></PlainTextWithBrsPdf>
                                    </View>
                                </View>
                            </View>
                            

                        ): null
                    }

                    <ConditionalFragment showIf={reportSettings.breakdown}>
                        <RAGBreakdownPdfUi {...props} />
                    </ConditionalFragment>
                    <RAGProjectWillDoPdfUi {...props} />
                </View>

                {/*
                <View style={Styles.footer} fixed>
                    <Text style={Styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </View>
                */}
            </Page>
        </Document>
    );
};

