import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { ProjectSectionPdfUi } from './ProjectSectionPdfUi';
import { IImageEnumerator } from './ImageEnumerator';

/**
 * Risks section of the executive summary (pdf format).
 */
export interface BreakdownPdfUiProps extends ExecutiveSummaryPdfProps {
    imageEnumerator: IImageEnumerator
}

export const BreakdownPdfUi = (props: BreakdownPdfUiProps) => {
    let getPreviousSectionsTopicCount = React.useCallback((index: number): number => {
        if (index === 0) {
            return 0;
        }

        let number = 0;

        for (let i = 0; i < index; ++i) {
            let topics = props.topics.filter(item => props.sections[i].id === item.sectionId);
            number += topics.length;
        }
        number += 1;
        return number;
    }, [props]);

    if (!props.model) {
        return (<></>);
    }


    return (
        <View>
            <View style={Styles.h3} break>
                <Text>Breakdown of Results</Text>
            </View>
            {
                props.sections.map((section, index) => {
                    let sectionImageNumber = 2 + (getPreviousSectionsTopicCount(index));

                    return (
                        <ProjectSectionPdfUi {...props} section={section} isFirstSection={index == 0} />
                    );
                })
            }
        </View >
    );
};

