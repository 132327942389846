import { Image, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Section } from '../../../../api/models/Section';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { IImageEnumerator } from './ImageEnumerator';
import { ProjectTopicPdfUi } from './ProjectTopicPdfUi';
import { SchoolWithoutTrustComparisonTablePdfUi } from './SchoolWithoutTrustComparisonTablePdfUi';
import { Styles } from './Styles';
import { TrustComparisonTablePdfUi } from './TrustComparisonTablePdfUi';

export interface ProjectSectionPdfUiProps extends ExecutiveSummaryPdfProps {
    section: Section,
    isFirstSection: boolean,
    imageEnumerator: IImageEnumerator
}

export const ProjectSectionPdfUi = (props: ProjectSectionPdfUiProps) => {
    const [projectSection, sectionTopics, resultsBySection] = React.useMemo(() => {
        let projectSection = props.projectSections.models.find(it => it.sectionId === props.section.id);
        let sectionTopics = props.topics.filter(i => i.sectionId === props.section.id);

        let resultsBySection: ProjectAnalysisResult[] = [];
        if (!!projectSection) {
            let projectSectionId = projectSection.id;
            let resultBySection = props.resultsBySection.find(item => item.projectSectionId === projectSectionId);

            if (resultBySection) {
                resultsBySection = resultBySection.results;
            }
        }

        return [projectSection, sectionTopics, resultsBySection];
    }, [props])
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View break={!props.isFirstSection}>
            <View>
                <View><Text style={Styles.h4}>{replacePlaceholders(props.section.name, placeholderReplacementMode)}</Text></View>
                <View>
                    <Text style={Styles.h6}>
                        Section description
                    </Text>
                </View>
                <View>
                    <Text>
                        {replacePlaceholders(props.section.description, placeholderReplacementMode)}
                    </Text>
                </View>
            </View>
            <View>
                {
                    //Show graph and table of topics.
                }
                <Text style={Styles.h6}>Section summary</Text>
                <View>
                    <View>
                        <Image src={props.imageEnumerator.getNextImage()} style={{ marginBottom: 16 }} />
                    </View>
                    <View>
                        <ConditionalFragment showIf={props.results.length > 1}>
                            {!!resultsBySection ?
                                <TrustComparisonTablePdfUi
                                    model={props.model} results={resultsBySection} isForSingleSchool={props.isForSingleSchool}
                                    filterBySectionOriginKey={props.section.originKey} filterByTopicOriginKey={undefined}
                                    placeholderReplacementMode={props.placeholderReplacementMode}
                                /> : null
                            }

                        </ConditionalFragment>
                        <ConditionalFragment showIf={props.results.length < 2}>
                            <SchoolWithoutTrustComparisonTablePdfUi {...props}
                                filterBySectionOriginKey={props.section.originKey} filterByTopicOriginKey={undefined}
                                placeholderReplacementMode={props.placeholderReplacementMode}
                            />
                        </ConditionalFragment>
                    </View>
                </View>
            </View>
            {
                sectionTopics.map((topic, topicIndex) => (
                    <ProjectTopicPdfUi {...props} projectTopicId={topic.id} />
                ))
            }

        </View>
    );
};