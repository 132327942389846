import * as React from 'react';
import { Table } from 'reactstrap';
import { ReportProps } from '../reportGenerator/ReportProps';
import { StrengthsPercentageBarChartCard } from '../../projects/strengths/StrengthsPercentageBarChartCard';


/**
 * Stengths % breakdown.
 */
export const StrengthsSummaryUi = (props: ReportProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <StrengthsPercentageBarChartCard
                {...props}
                projects={[props.model]}
                model={props.model}
                schools={[props.school || { id: '', name: '' }]}
                strengths={props.strengths}
                risks={props.risks}
                questions={props.questions}
                sections={props.allSections}
                topics={props.allTopics}
                filterBySectionOriginKey={undefined}
                filterByTopicOriginKey={undefined}
                awardTagId={props.awardTag && props.awardTag.id || undefined}
            />
        </div>        
    );
};
