import * as React from 'react';
import { Actions, ActionsProps } from './Actions';
import { useUniversalNavigation } from 'react-universal-navigation';

/**
 * Actions component that ignores actions that are for schools, so shows only those for the trust itself. 
 */
export const TrustOnlyActions = (props: ActionsProps) => {
    const navigation = useUniversalNavigation(props);
    const actionId = navigation.getParam('actionId', '');
    return (<Actions ignoreActionsForSchools={true} title="Trust improvement planner" actionId={actionId} />);
};