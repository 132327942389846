import * as React from "react";
import { ReportGenerator } from "./ReportGenerator";

/**
 * Report builder.
 */
export const ReportBuilder = (props: any) => {
    return (
        <ReportGenerator startWithReportBuilder={true} {...props} />
        );
}