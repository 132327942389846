import { View, Text, Image } from '@react-pdf/renderer';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Section } from '../../../../api/models/Section';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { IImageEnumerator } from './ImageEnumerator';
import { ProjectQuestionnairePdfUi } from './ProjectQuestionnairePdfUi';
import { ProjectQuestionResponsesPdfUi } from './ProjectQuestionResponsesPdfUi';
import { SchoolWithoutTrustComparisonTablePdfUi } from './SchoolWithoutTrustComparisonTablePdfUi';
import { Styles } from './Styles';
import { TrustComparisonTablePdfUi } from './TrustComparisonTablePdfUi';

export interface ProjectTopicPdfUiProps extends ExecutiveSummaryPdfProps {
    projectTopicId: string,
    imageEnumerator: IImageEnumerator,
    isFirstTopicInSection?: boolean
}

export const ProjectTopicPdfUi = (props: ProjectTopicPdfUiProps) => {
    const [topicQuestions, questionaires, projectTopic, topic, section, topicOverviewText] = React.useMemo(() => {
        let topic = props.topics.find(i => i.id == props.projectTopicId);
        let section: Section | undefined;

        if (topic !== undefined) {
            let t = topic;
            section = props.sections.find((item) => item.id === t.sectionId);
        }

        //get topic questions
        let topicQuestions = props.questions.filter(q => q.topicId === props.projectTopicId);

        //get questionaires
        let questionaires = props.questionaires
            .filter(item => topicQuestions.find(it => it.questionaireId === item.id))

        let projectTopicResults = props.resultsByTopic.find(item => item.topicId === props.projectTopicId);
        let projectTopic: Array<ProjectAnalysisResult> | undefined;

        if (!!projectTopicResults) {
            projectTopic = projectTopicResults.results;
        }

        let s = props.projectTopics.models.find(item => item.topicId == props.projectTopicId);
        let topicOverviewText: string = '';
        if (!!s) {
            topicOverviewText = s.overviewOpinion;
        }

        return [topicQuestions, questionaires, projectTopic, topic, section, topicOverviewText];
    }, [props]);
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View break>
            <View>
                <Text style={Styles.h4}>{topic ? replacePlaceholders(topic.name, placeholderReplacementMode) : ''}</Text>
            </View>
            <View>
                <Text style={Styles.h6}>Results</Text>
                <View>
                    <View>
                        <Image src={props.imageEnumerator.getNextImage()} style={{ marginBottom: 16 }} />
                    </View>
                    <View>
                        <ConditionalFragment showIf={!props.isForSingleSchool}>
                            {!!projectTopic ?
                                <TrustComparisonTablePdfUi
                                    filterBySectionOriginKey={section ? section.originKey : ''} filterByTopicOriginKey={topic ? topic.originKey : undefined}
                                    isForSingleSchool={false}
                                    model={props.model} results={projectTopic}
                                    placeholderReplacementMode={props.placeholderReplacementMode}
                                /> : null
                            }
                        </ConditionalFragment>
                        <ConditionalFragment showIf={props.isForSingleSchool}>
                            {
                                !!projectTopic ?
                                    <SchoolWithoutTrustComparisonTablePdfUi
                                        filterBySectionOriginKey={section ? section.originKey : undefined} filterByTopicOriginKey={topic ? topic.originKey : undefined}
                                        model={props.model} results={projectTopic}
                                        placeholderReplacementMode={props.placeholderReplacementMode}
                                    /> : null
                            }
                        </ConditionalFragment>
                    </View>
                </View>
            </View>
            <ProjectQuestionResponsesPdfUi {...props} />
            <ProjectQuestionnairePdfUi {...props} />

            <ConditionalFragment showIf={!!topicOverviewText}>
                <View style={{ paddingTop: 30 }}>
                    <Text style={{ ...Styles.h6 }}>Additional thoughts around {topic && topic.name || ''}</Text>
                    <View>
                        <Text>
                            {replacePlaceholders(topicOverviewText, placeholderReplacementMode)}
                        </Text>
                    </View>
                </View>
            </ConditionalFragment>
        </View>
    );
};