import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Button, Card, CardBody, Col, Collapse, FormText, Input, Label, Row } from 'reactstrap';
import { BlobUrl } from '../../api/models/BlobUrl';
import { generateClickableUrl } from '../../utilities/generateClickableUrl';
import { VideoResource } from '../../api/models/VideoResource';
import { LinkUrlModal } from '../schoolEvidence/LinkUrlModal';
import { useAsyncCallback } from 'react-use-async-callback';

export interface VideoResourceItemProps {
    model: VideoResource,
    changeModel: (changes: Partial<VideoResource>) => void,
    uploadFile: (files: FileList) => Promise<void>,
    fileBlob: BlobUrl | undefined,
    remove: () => void,
}

/**
 * One item of evidence for a school. 
 */
export const VideoResourceItem = (props: VideoResourceItemProps) => {
    const {
        model,
        changeModel,
        uploadFile,
        fileBlob,
        remove,
    } = props;

    // Toggling of URL modal.
    const [isUrlModalOpen, setIsUrlModalOpen] = React.useState<boolean>(false);
    const toggleUrlModal = React.useCallback(() => setIsUrlModalOpen(prevState => !prevState), [setIsUrlModalOpen]);

    // Upload a file.
    const [fileSizeError, setFileSizeError] = React.useState<string>('');
    const [onUploadFile, isFileUploading /*, onUploadFileErrors*/] = useAsyncCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setFileSizeError('');

        if (!event.currentTarget.files || !event.currentTarget.files.length) {
            return;
        }

        // Limit the file size upload to 12mb as per specfieid requirements.
        const maximumFileSize = (1024 * 1024 * 12);
        if (event.currentTarget.files[0].size > maximumFileSize) {
            setFileSizeError(`The maximum file size you can upload is 12mb.  This file is ${(event.currentTarget.files[0].size / 1024.0 / 1024.0).toFixed(1)}mb and too large to be uploaded.`);
            return;
        }

        // Upload the files as the photo.
        await uploadFile(event.currentTarget.files);
    }, [uploadFile, setFileSizeError, changeModel]);


    return (
        <>
            <Card className="evidence-locker-item">
                <CardBody tag="div">
                    <Row>
                        <Col>
                            <Input placeholder="Resource name" value={model.name} onChange={e => changeModel({ name: e.currentTarget.value })} />
                        </Col>
                        <Col>
                            <div className="file-link">
                                {
                                    fileBlob ? (
                                        <a href={fileBlob.url} download={fileBlob.filename} target="_blank">
                                            {fileBlob.filename}
                                        </a>
                                    ) : model && model.url ? (
                                        <a href={generateClickableUrl(model.url)} target="_blank">
                                            {model.url}
                                        </a>
                                    ) : (
                                                <span className="text-muted">(No file uploaded)</span>
                                            )
                                }
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <Row className="no-gutters">
                                    <Col xs="auto">
                                        <Label className="btn btn-outline-secondary file-upload-button">
                                            {
                                                isFileUploading ? (
                                                    <>
                                                        <FontAwesomeIcon icon="spinner" spin />
                                                        <> </><span>Uploading...</span>
                                                    </>
                                                ) : (
                                                    <span>
                                                        {
                                                            !model? 'Upload file...' : 'Update file...'
                                                        }
                                                    </span>
                                                    )
                                            }

                                            <Input type="file" name="files" onChange={onUploadFile} />
                                        </Label>
                                    </Col>
                                    <Col className="pl-1">
                                        <Button type="button" color="secondary" outline onClick={toggleUrlModal}>
                                            Link to url...
                                        </Button>
                                    </Col>
                                </Row>
                            </div>

                            {
                                fileSizeError ? (
                                    <div className="text-danger">
                                        {fileSizeError}
                                    </div>
                                ) : null
                            }
                        </Col>
                        <Col xs="auto">
                            <Button color="link" className="text-danger" onClick={() => {
                                if (!remove) {
                                    return;
                                }

                                remove();
                            }}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <span className="sr-only">Remove additional document</span>
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <ConditionalFragment showIf={!!isUrlModalOpen}>
                <LinkUrlModal originalUrlText={model && model.url || ''}
                    cancel={() => toggleUrlModal()}
                    save={(url) => {
                        changeModel({ url: url });
                        toggleUrlModal();
                    }}
                    />
            </ConditionalFragment>
        </>
        );

};
