import * as React from 'react';
import { Button, Row, Col, FormGroup, Label, InputGroupAddon, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput, ValidatedFormFeedback } from 'pojo-validator-reactstrap';
import { ProjectReviewer } from '../../../api/models/ProjectReviewer';
import { ValidationErrors } from 'pojo-validator';
import { ManagedChildModels } from '../../../components/shared/hooks/useManagedChildModels';
import { User } from '../../../api/models/User';
import { CreateModal } from '../../manage/users/CreateModal';
import { useAsyncCallback } from 'react-use-async-callback';
import { Project } from '../../../api/models/Project';
import { ProjectReviewerRestriction } from '../../../api/models/ProjectReviewerRestriction';
import { RestrictionsModel } from './RestrictionsModal';

export interface ProjectReviewerUi {
    model: ProjectReviewer,
    isCreate: boolean,
    isProjectOwner: boolean,
    change: (changes: Partial<ProjectReviewer>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,

    restrictions: ManagedChildModels<ProjectReviewerRestriction>,
    users: ManagedChildModels<User>,

    sendQuestionEmail?: (userId: string) => Promise<boolean>,
    //isSendingQuestionEmail?: boolean,
    //sendQuestionEmailErrors?: any,

    project: Project | undefined,

    isSingleSchoolSubscription: boolean,

    newReviewerRoleGroup: string | undefined
}

export const ProjectReviewerUi = (props: ProjectReviewerUi) => {

    const [newUserId, setNewUserId] = React.useState<string>('');
    const [originalUserId, setOriginalUserId] = React.useState<string>('');

    const [sendEmail, { isExecuting: isSendingEmail, errors: sendEmailErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!props.model) {
            return false;
        }

        if (!props.sendQuestionEmail) {
            return false;
        }

        return await props.sendQuestionEmail(props.model.userId);
    }, [props.sendQuestionEmail, props.model]);

    // Open a modal to let a new user be added.
    const [openUserModal, { isExecuting: isCreatingUser, errors: creatingUserErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!props.project) {
            return false;
        }

        setOriginalUserId(props.model.userId);

        let createdUser = await props.users.addModel({ subscriptionId: props.project.subscriptionId, schoolId: props.project.schoolId, roleGroup: props.newReviewerRoleGroup});
        setNewUserId(createdUser.id);

        // Update the model ready to use the new id.
        props.change({ userId: createdUser.id });

        return true;
    }, [props.users, props.project, setNewUserId, props.change, setOriginalUserId, props.newReviewerRoleGroup]);

    // Use the new user added in the modal.
    // NOTE the user is not saved here, that is done when everything is saved in the containing component.
    const useNewUser = React.useCallback(() => {
        if (!newUserId) {
            return;
        }

        let newUser = props.users.models.find(item => item.id === newUserId);
        if (!newUser) {
            return;
        }

        // If the user is invalid, don't close the modal.
        if (!props.users.validateModel(newUserId)) {
            return;
        }

        // NOTE we've already called change() on it in the model, so don't need to do it again here.  We've done this to avoid an async
        // race condition with the call to validate() below.

        // Validate this model now we've changed it.
        // NOTE although validating it here is a valid thing to do, we're primarily doing it here for its hidden side effect
        // on ReviewersUi which will cause save() to be called.
        props.validate();

        // Clear the modal.
        setNewUserId('');
        setOriginalUserId('');
    }, [newUserId, setNewUserId, props.users, setOriginalUserId]);

    const cancelNewUser = React.useCallback(() => {
        if (!newUserId) {
            return;
        }

        // Remove the user from the collection.
        props.users.removeModel(newUserId);

        // Reset the userId in the model.
        props.change({ userId: originalUserId });

        // Clear the modal.
        setNewUserId('');
        setOriginalUserId('');
    }, [newUserId, props.users, setNewUserId, originalUserId, setOriginalUserId, props.change]);

    // Resitrctions modal.
    const [restrictionsModalOpen, setRestrictionsModalOpen] = React.useState<boolean>(false);
    const toggleRestrictionsModalOpen = React.useCallback(() => {
        setRestrictionsModalOpen(prevState => !prevState);
    }, [setRestrictionsModalOpen]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.change({ [target.name]: (target.type == 'checkbox' ? target.checked : target.value) });
    }, [props.change]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const onSendEmailClick = React.useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
        await sendEmail();
        //props.sendQuestionEmail(props.model.userId);

    }, [props.sendQuestionEmail, props.model]);

    const newUser = React.useMemo(() => {
        return props.users.models.find(item => item.id === newUserId);
    }, [newUserId, props.users.models]);

    const myRestrictions = React.useMemo(() => {
        return props.restrictions.models.filter(item => item.projectReviewerId === props.model.id);
    }, [props.restrictions, props.model]);

    // Render
    if (!props.model) {
        return (<></>);
    }

    return (
        <>
            <div>
                <Row>
                    <Col xs={12} sm="">
                        <FormGroup>
                            <Label htmlFor="userId" className="sr-only">User</Label>
                            <InputGroup>
                                <ValidatedInput type="select" name="userId" placeholder="(Please select or add a user)" value={props.model.userId} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} hideFormFeedback={true}>
                                    <option value="">(Please select or add a user)</option>
                                    {
                                        props.users.models.map(item => (
                                            <option key={item.id} value={item.id}>{`${item.forename} ${item.surname} ${item.email ? ' (' + item.email + ')' : ''}`}</option>
                                        ))
                                    }
                                </ValidatedInput>
                                
                                <InputGroupAddon addonType="append">
                                    <ButtonAsync type="button" color="primary" outline
                                        onClick={openUserModal}
                                        isExecuting={isCreatingUser}
                                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> <span className="sr-only">Preparing new user</span></>}
                                    >
                                        <FontAwesomeIcon icon="plus" /> <span className="sr-only">Add user</span>
                                    </ButtonAsync>
                                </InputGroupAddon>
                                {
                                    props.sendQuestionEmail ?
                                        <InputGroupAddon addonType="append">
                                            <ButtonAsync outline isExecuting={isSendingEmail}
                                                onClick={onSendEmailClick}
                                                executingChildren={<>
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                    <span className="sr-only">Sending invite</span>
                                                </>}
                                            >
                                                <FontAwesomeIcon icon="envelope"/>
                                            </ButtonAsync>
                                        </InputGroupAddon> :
                                        null
                                }
                            </InputGroup>
                            <ValidatedFormFeedback name="userId" validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    {/*<Col>
                        <FormGroup>
                            <Label htmlFor="restrictions" className="sr-only">Restrictions</Label>
                            <div>
                                <Button color="primary" outline onClick={toggleRestrictionsModalOpen}>
                                    Add restrictions ({myRestrictions.length ? (<>{myRestrictions.length} restrictions</>) : (<>no restrictions</>)})
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>*/}
                    <Col xs="auto">
                        <Button disabled={props.isProjectOwner} color="link" onClick={() => props.remove()}><FontAwesomeIcon icon="trash-alt" color="danger" /><span className="sr-only">Remove</span></Button>
                    </Col>
                </Row>
                <div className="d-block d-sm-none">
                    <hr />
                </div>
            </div>

            {
                newUser ? (
                    <CreateModal model={newUser} changeModel={changes => props.users.change(newUser.id, changes)}
                        validate={fieldsToCheck => props.users.validateModel(newUser.id, fieldsToCheck)} validationErrors={props.users.validationErrorsFor(newUser.id)}
                        save={useNewUser}
                        cancel={cancelNewUser}
                    />
                ) : null
            }

            {
                restrictionsModalOpen ? (
                    <RestrictionsModel
                        project={props.project}
                        projectReviewerId={props.model.id}
                        restrictions={props.restrictions}
                        close={() => { toggleRestrictionsModalOpen(); props.validate(); /* we call validate here because of its side-effect of saving in Reviewers.tsx. */ }} />
                ) : null
            }
        </>
    );
};
