import * as React from 'react';
import { Form, FormGroup, Label, Alert, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { PasswordResetContainer, PasswordResetUiProps, PasswordResetContainerProps } from './PasswordResetContainer';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { LoadingIndicator } from '../shared/LoadingIndicator';

export const PasswordResetUi = (props: PasswordResetUiProps) => {
    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await props.save();
    }, [props.save]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);
    
    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={props.savingErrors} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container account">
            <div className="main-heading">
                <img className="logo img-fluid" src="/img/brand/logo-full.png" />
                <h1>Edudiligence</h1>
            </div>

            <AlertOnErrors errors={props.savingErrors} />

            {
                props.saveSuccessful ? (
                    <Alert color="success">
                        <p>
                            An email with instructions to reset your password has been sent to {props.model.user}.
                        </p>
                        <p>
                            To complete the password reset please follow the link in the email.
                        </p>
                    </Alert>
                ) : null
            }

            <div className="help-text">
                {
                    props.isRedirect ?
                        "Sorry that link has expired or is not valid.  If still need to reset password don't worry we can send you another email.  Just check your email address below is correct and we can send you a new email to let you reset your password."
                        : "If you've forgotten your password don't worry we can reset it for you.  Just enter your email below and we can send you an email to let you reset your password."
                }
                
            </div>

            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Label htmlFor="user">Email</Label>
                    <ValidatedInput type="email" name="user" placeholder="Email" value={props.model.user} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>

                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Sending...</>}>
                        <FontAwesomeIcon icon="envelope" /> Send password reset email
                    </ButtonAsync>
                </div>
            </Form>
        </Container>
    );
};

export const PasswordReset = withContainer<PasswordResetContainerProps>(PasswordResetContainer)(PasswordResetUi);
