import * as React from "react";
import { useApiService } from "../../api/useApiService";
import { SectionDataItem, TopicDataItem } from '../projects/projectSections/ProjectSectionComponent';
import { useAsyncCallback } from "react-use-async-callback";
import { Project } from "../../api/models/Project";
import moment, { Moment } from "moment";

export interface UseLoadProjectWithRetryOptions {
    trustId: string,
    schoolId?: string,
    subjectId?: string,
    questionSetName: string,
    createIfMissing?: boolean,

    // Not used in loading, but used for filtering.
    awardTagId?: string,
}

/**
 * Load a Project as specified in options with sensible retries to allow the project to be created.
 * @param options
 * @returns
 */
export function useLoadProjectWithRetry(options: UseLoadProjectWithRetryOptions) {
    const api = useApiService();
    const currentProjectFor = api.projects.viewModels.currentProjectFor();
    const loadProgressViewModel = api.projects.viewModels.progress();

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const [project, setProject] = React.useState<Project | null | undefined>();
    const [sections, setSections] = React.useState<Array<SectionDataItem>>([]);
    const [topics, setTopics] = React.useState<Array<TopicDataItem>>([]);

    const [firstRetryTime, setFirstRetryTime] = React.useState<Moment | undefined>();
    const retrySeconds = 60; // number of seconds to retry before we accept we are not getting the required project
    const retryExpireTime = React.useMemo(() => {
        if (!!firstRetryTime) {
            return firstRetryTime.add(retrySeconds, 'seconds');
        }
        return undefined;
    }, [firstRetryTime]);

    const [shouldRetry, setShouldRetry] = React.useState<boolean>(true);
    const scheduleRetry = React.useCallback(() => {
        if (!firstRetryTime) {
            setFirstRetryTime(moment());
        }
        setTimeout(() => {
            setShouldRetry(true);
        }, 1000);
    }, [setShouldRetry, setFirstRetryTime, firstRetryTime]);

    const [loadedProgressForOptions, setLoadedProgressForOptions] = React.useState<UseLoadProjectWithRetryOptions>();

    const [loadProject, { isExecuting: isLoadingProject, errors: loadProjectErrors }] = useAsyncCallback(async () => {
        try {
            // Load the best project for the school.
            const projectResult = await currentProjectFor({
                schoolId: options.schoolId || '',
                trustId: options.trustId,
                subjectId: options.subjectId,
                questionSetName: options.questionSetName,
                createIfMissing: options.createIfMissing,
            });

            if (!!projectResult && !projectResult.isMot) {
                setProject(projectResult);

                // Load the progress view model.
                const result = await loadProgressViewModel(projectResult.id || undefined, options.awardTagId || undefined, undefined);
                setSections(result.sections);
                setTopics(result.topics);
                setLoadedProgressForOptions(options);
            } else {
                scheduleRetry();
            }
        } catch {
            // Ignored
        }
    }, [scheduleRetry, setProject, loadProgressViewModel, setSections, setTopics, options]);

    React.useEffect(() => {
        if ((!options.schoolId && !options.trustId) || isLoadingProject) {
            return;
        }

        if (loadedProgressForOptions
            && loadedProgressForOptions.schoolId === options.schoolId
            && loadedProgressForOptions.subjectId === options.subjectId
            && loadedProgressForOptions.awardTagId === options.awardTagId
            && loadedProgressForOptions.questionSetName === options.questionSetName
            && loadedProgressForOptions.trustId === options.trustId
            && loadedProgressForOptions.createIfMissing === options.createIfMissing
        ) {
            return;
        } else {
            setProject(undefined);
            setSections([]);
            setTopics([]);
        }

        // Special handling of retries to cope with the fact the projects may be being generated (triggered by sidebar)
        // at the same time we are trying to load them, so we wait and try again.
        // Only allow the retry for a set number of seconds and then let it fall through with no report
        if (!!firstRetryTime && (!!retryExpireTime && moment() < retryExpireTime)) {
            setShouldRetry(false);
        }

        // Load if we need to.
        if (!project || shouldRetry) {
            setShouldRetry(false);
            loadProject();
        }
    }, [options, loadProject, setShouldRetry, shouldRetry, project, firstRetryTime, retryExpireTime, isLoadingProject, loadedProgressForOptions, loadProjectErrors]);

    return { project, sections, topics, isLoadingProject , loadProjectErrors };
}