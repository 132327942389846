import * as React from 'react';
import { ValidatedFormFeedback } from 'pojo-validator-reactstrap';
import { HtmlEditorProps, HtmlEditor } from './HtmlEditor';
import { ValidationErrors } from 'pojo-validator';


export interface ValidatedHtmlEditorProps extends HtmlEditorProps {
    name?: string,
    validationErrors: ValidationErrors | Array<string> | string | undefined | null;
    hideFormFeedback?: boolean;
}

/**
 * HtmlEditor and ValidatedInput wrapper class that makes it easy to use both together.
 * 
 * @param props
 */
export const ValidatedHtmlEditor = (props: ValidatedHtmlEditorProps) => {
    const { name, validationErrors, hideFormFeedback, ...rest } = props;

    // Find the errors based on the types we support.
    let errors: Array<string> = [];
    if (!validationErrors) {
        // Errors can stay as an empty array.
    } else if (typeof validationErrors === 'string') {
        errors = [validationErrors];
    } else if (Array.isArray(validationErrors)) {
        errors = validationErrors;
    } else {
        // We have ValidationErrors from Validator.errors()
        errors = validationErrors[(props.name ? props.name : '')] || [];
    }

    return (
        <>
            <HtmlEditor {...rest} />
            {
                hideFormFeedback ? (
                    <></>
                    ): (
                        <ValidatedFormFeedback name={name} validationErrors={errors} />
                    )
            }
        </>
    );
};
