import * as React from 'react';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { PageUiProps, PageContainer } from './PageContainer';
import { HtmlDisplay } from '../../shared/htmlEditor/HtmlDisplay';

/**
 * UI for pages.
 * 
 * NOTE that even though this is under the administration folder, it is fine to use from other areas if required.  It is here so its alongside the other Page functionality.
 * @param props
 */
export const PartialPageUi = (props: PageUiProps) => {
    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <>
            <AlertOnErrors errors={[props.loadingErrors]} />

            <HtmlDisplay sanatizedHtml={props.model.contents} />
        </>
    );
};

export const PartialPage = withContainer(PageContainer)(PartialPageUi);