import * as React from 'react';
import { DashboardUiProps } from '../DashboardContainer';
import { Card, Table, CardHeader, CardBody, Button } from 'reactstrap';
import { TrustComparisonTable } from './TrustComparisonTable';
import { Guid } from 'guid-string';
import { Section } from '../../../../api/models/Section';
import { ManagedChildModels } from '../../../shared/hooks/useManagedChildModels';
import { ProjectSection } from '../../../../api/models/ProjectSection';
import { ProjectTopic } from '../../../../api/models/ProjectTopic';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface TrustComparisonCardProps extends DashboardUiProps {
    sections: Array<Section>,
    projectSections: ManagedChildModels<ProjectSection>,
    schoolComparisonOpen: boolean,
    toggleSchoolComparison: () => void,
}

export const TrustComparisonCard = (props: TrustComparisonCardProps) => {
    if (!props.results || props.results.length < 2) {
        return (<></>);
    }

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.results[0];
    let trustAverages = props.results[1];
  
    return (
        <Card>
            <CardHeader>
                <h6>
                    <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                        {
                            props.model && Guid.isEmpty(props.model.schoolId) ? 'Comparison with schools'
                                : props.isForSingleSchool ? 'Comparison against targets'
                                    : 'Comparison with trust'
                        }
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!props.awardTag && props.awardTag.hideTargets}>
                        {
                            props.model && Guid.isEmpty(props.model.schoolId) ? 'School averages'
                                : props.isForSingleSchool ? 'School results'
                                    : 'School results'
                        }
                    </ConditionalFragment>
                    
                </h6>
            </CardHeader>
            
            <CardBody>
                <TrustComparisonTable {...props} />

                {
                    // If we have records for other schools, allow the viewing of the full school by school comparison.
                    props.results.length >= 3? (
                        <Button color="primary" outline onClick={props.toggleSchoolComparison} className="full-width">
                            {
                                !props.schoolComparisonOpen ? (<>
                                    {
                                        props.model && Guid.isEmpty(props.model.schoolId) ?
                                            'Show breakdown of schools within the trust'
                                            : 'Show full comparison of schools within the trust'
                                    }
                                </>) : (<>
                                        {
                                            props.model && Guid.isEmpty(props.model.schoolId) ?
                                                'Hide breakdown of schools'
                                                : 'Hide full school comparison'
                                        }
                                        </>)
                            }
                        </Button>
                        ): null
                }
                
            </CardBody>
        </Card>
    );
};

