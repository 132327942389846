import * as React from 'react';
import { Button } from 'reactstrap';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { EditConclusionModal } from './editModals/EditConclusionModal';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReportProps } from '../reportGenerator/ReportProps';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';


/**
 * Conclusion section of the executive summary.
 */
export const ConclusionUi = (props: ReportProps) => {
    const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
    const [originalValue, setOriginalValue] = React.useState<string>('');
    const toggleEditModalOpen = React.useCallback(() => {
        setEditModalIsOpen(prevState => !prevState);
    }, [setEditModalIsOpen]);

    if (!props.model) {
        return (<></>);
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <>
            <div>
                <h3>
                    Conclusion

                    <ConditionalFragment showIf={!props.readOnly}>
                        <PrivateContainer role="Manage">
                            <> <Button size="sm" color="primary" outline onClick={e => {
                                if (!props.model) {
                                    return;
                                }
                                setOriginalValue(props.model.overviewOpinion);
                                toggleEditModalOpen();
                            }}>
                                Edit
                            </Button></>
                        </PrivateContainer>                    
                    </ConditionalFragment>
                </h3>
                <p>
                    <PlainTextWithBrs text={replacePlaceholders(props.model.overviewOpinion, placeholderReplacementMode)} />
                </p>
                
            </div>

            {
                editModalIsOpen ? (
                    <EditConclusionModal model={props.model} changeModel={props.changeModel}
                        save={async (): Promise<boolean> => {
                            let ok = await props.save();
                            if (!ok) {
                                return false;
                            }

                            toggleEditModalOpen();
                            return true;
                        }}
                        isSaving={props.isSaving} savingErrors={props.savingErrors}
                        cancel={() => {
                            if (!props.model) {
                                return;
                            }

                            props.changeModel({ overviewOpinion: originalValue });
                        toggleEditModalOpen();
                    }} />
                ): null
            }
        </>
    );
};
