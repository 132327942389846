import * as React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Strength } from '../../../api/models/Strength';
import { Question } from '../../../api/models/Question';
import { StrengthsBarChart, StrengthBarChartGroup } from '../dashboard/charts/StrengthsBarChart';
import { Project } from '../../../api/models/Project';
import { Section } from '../../../api/models/Section';
import { Topic } from '../../../api/models/Topic';
import { IdAndName } from '../../../api/models/IdAndName';

export interface StrengthsBarChartCardProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    strengths: Array<Strength>,
    sections: Array<Section>,
    topics: Array<Topic>,
    questions: Array<Question>,
    projects: Array<{ id: string, schoolId: string | undefined | null }>,
    schools: Array<IdAndName>,
    awardTagId: string | undefined,
}

export const StrengthsBarChartCard = (props: StrengthsBarChartCardProps) => {
    const data = React.useMemo(() => {
        if (!props.strengths) {
            return [];
        }

        let ret: Array<StrengthBarChartGroup> = [];

        for (const strength of props.strengths) {
            // Find the question, topic, and section.
            let topic: Topic | undefined = undefined;
            let section: Section | undefined = undefined;
            const question = props.questions.find(it => it.id === strength.questionId);

            topic = props.topics.find(it => it.id === strength.topicId);
            if (topic) {
                const sectionId = topic.sectionId;
                section = props.sections.find(it => it.id === sectionId);
            }

            // Work out the label we are working at.
            const label = section ? { name: section.name, originKey: section.originKey }
                : topic ? { name: topic.name, originKey: topic.originKey }
                    : question ? { name: question.name, originKey: question.originKey }
                        : { name: 'Manual', originKey: '' };

            // Find the group for the project.
            let projectEntry = ret.find(item => item.projectId === strength.projectId);
            if (!projectEntry) {
                const project = props.projects.find(it => it.id === strength.projectId);
                let school: IdAndName | undefined = undefined;
                if (project) {
                    const schoolId = project.schoolId;
                    school = props.schools.find(it => it.id === schoolId);
                }
                projectEntry = {
                    projectId: strength.projectId,
                    schoolId: school && school.id || '',
                    name: school && school.name || '',
                    items: [],
                };
                ret.push(projectEntry);
            }

            // See if we already have an entry for this label.
            let labelEntry = projectEntry.items.find(it => it.originKey === label.originKey);
            if (!labelEntry) {
                labelEntry = {
                    originKey: label.originKey,
                    label: label.name,
                    excellenceCount: 0,
                    strengthCount: 0
                };
                projectEntry.items.push(labelEntry);
            }

            if (strength.isExcellence) {
                labelEntry.excellenceCount += 1;
            } else {
                labelEntry.strengthCount += 1;
            }
        }

        return ret;
    }, [props.strengths, props.questions, props.topics, props.sections, props.model, props.schools, props.projects]);

    if (!props.strengths) {
        return (<></>);
    }

    // Show the chart.
    return (
        <Card>
            <CardBody>
                <StrengthsBarChart
                    model={props.model} data={data}
                    filterBySectionOriginKey={props.filterBySectionOriginKey} filterByTopicOriginKey={props.filterByTopicOriginKey}
                    awardTagId={props.awardTagId}
                    chartHeight={400} />
            </CardBody>
        </Card>
    );
};

