import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { AppSidebarToggler } from '@coreui/react';
import { UserNav } from './UserNav';
import { NavLinkTrackActive } from '../../shared/NavLinkTrackActive';
import { Link } from 'react-router-dom';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { SchoolsDropdown } from '../../schools/SchoolsDropdown';
import { connect } from 'react-redux';
import { Identity } from '../../../store/user';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface HeaderProps {
    subscriptionId?: string | undefined | null,
    schoolId?: string | undefined | null,
}

export const _Header = (props: HeaderProps) => {
    const { subscriptionId, schoolId, ...rest } = props;

    return (
        <>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />

            <Link to="/">
                <div className="navbar-brand">
                    <div className="navbar-brand-full">
                    </div>
                    <div className="navbar-brand-minimized">
                    </div>
                </div>
            </Link>
            <AppSidebarToggler className="d-md-down-none" display="lg" />
            <ConditionalFragment showIf={!!subscriptionId}>
                <div className="d-none d-sm-block">
                    <Nav navbar>
                        <NavItem>
                            <SchoolsDropdown currentSchoolId={schoolId ?? undefined} />
                        </NavItem>
                    </Nav>
                </div>
            </ConditionalFragment>
            <Nav className="admin-nav ml-auto" navbar>
                <PrivateContainer role="Manage">
                    <ConditionalFragment showIf={!!subscriptionId}>
                        <div className="d-none d-sm-block">
                            <NavItem>
                                <NavLinkTrackActive to="/manage" tag={Link}>
                                    Manage
                                </NavLinkTrackActive>
                            </NavItem>
                        </div>
                    </ConditionalFragment>
                </PrivateContainer>
                <PrivateContainer role="Administration">
                    <NavItem>
                        <NavLinkTrackActive to="/administration" tag={Link}>
                            Administration
                        </NavLinkTrackActive>
                    </NavItem>
                </PrivateContainer>
            </Nav>
            <Nav className="signin-nav" navbar>
                <UserNav />
            </Nav>
        </>
    );
};


interface State {
    user?: User
}

interface User {
    identity?: Identity
}

export const Header = connect(
    (state: State) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_Header);