import * as React from "react";
import { ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { School } from "../../../api/models/School";
import { connect } from "react-redux";
import { AppState } from "../../../store";
import { EdubaseService } from "../../../services/EdubaseService";
import { Tag } from "../../../api/models/Tag";
import { ManagedChildModels, useManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { SchoolTag } from "../../../api/models/SchoolTag";
import { Trust } from "../../../api/models/Trust";
import { AwardTag } from "../../../api/models/AwardTag";
import { BlobUrl } from "../../../api/models/BlobUrl";
import { BlobUploadService } from "../../../services/BlobUploadService";


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    /* From dependnecy injection */
    repository: Repository<School>,
    loadViewModel: (id: string, subscriptionId: string | undefined, initialTrustId: string | undefined) => Promise<any>,
    edubaseService: EdubaseService,
    schoolTagsRepository: Repository<SchoolTag>,
    getTrustFromSubscription: (id: string) => Promise<Trust>,
    blobUploadService: BlobUploadService,

    /* From Redux */
    subscriptionId?: string,

    /* Passed in */
    createAsPotentialSchool?: boolean,
}

export interface EditUiProps extends EditUiPropsBase<School> {
    updateFromEdubase: (urn: number) => Promise<boolean>,
    isUpdatingFromEdubase: boolean,
    edubaseErrors: any,

    allTags: Array<Tag>,
    specialismTags: Array<AwardTag>,
    schoolTags: ManagedChildModels<SchoolTag>,

    reportBannerBlob: BlobUrl | undefined | null,
    uploadReportBanner: (files: FileList) => Promise<BlobUrl | null>,
    isUploadingReportBanner: boolean,
    uploadReportBannerErrors: any,
}

export const _EditContainer = (props: EditContainerProps) => {
    let { component, repository, edubaseService, schoolTagsRepository, loadViewModel, getTrustFromSubscription, blobUploadService, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');

    const [model, setModel] = React.useState<School | undefined>(undefined);
    const [tags, setTags] = React.useState<Array<Tag>>([]);
    const [specialismTags, setSpecialismTags] = React.useState<Array<AwardTag>>([]);
    const schoolTagsManager = useManagedChildModels(schoolTagsRepository);
    const [reportBannerBlob, setReportBannerBlob] = React.useState<BlobUrl | undefined | null>();

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<School>) => {
        setModel(prevState => ({
            ...(prevState as School),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        const trust = await getTrustFromSubscription(props.subscriptionId || '');
        let result = await loadViewModel(id, props.subscriptionId, trust.id);

        setTags(result.availableTags);
        setSpecialismTags(result.specialismTags);
        schoolTagsManager.setModels(result.schoolTags);
        setReportBannerBlob(result.reportBannerBlob);

        let newModel = result.model;
        if (props.createAsPotentialSchool) {
            newModel = {
                ...newModel,
                isPotentialSchool: true,
            };
        }
        setModel(newModel);

        return true;
    }, [loadViewModel, setModel, id, setTags, schoolTagsManager, getTrustFromSubscription, props.subscriptionId, setReportBannerBlob]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }
    }, [model]);
    
    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model);

        // Save the tags
        await schoolTagsManager.save();

        return true;
    }, [model, validate, repository, schoolTagsManager]);

    // Update this model from edubase.
    const [updateFromEdubase, { isExecuting: isUpdatingFromEdubase, errors: edubaseErrors }] = useAsyncCallback(async (urn: number): Promise<boolean> => {
        // Query the edubase API for results on the establishment and store the results back into the school.
        let result = await edubaseService.getEstablishment(urn);
        changeModel({
            name: result.establishmentName,
            dfeReference: result.urn,
            edubaseJson: JSON.stringify(result)
        });
        return true;
    }, [changeModel, edubaseService]);

    // Upload the reportBanner.
    const [uploadReportBanner, { isExecuting: isUploadingReportBanner, errors: uploadReportBannerErrors }] = useAsyncCallback(async (files: FileList) => {
        if (!files) {
            return null;
        }

        if (!model) {
            return null;
        }

        // Do the upload.
        const blob = await blobUploadService.upload(files);
        if (!blob) {
            return null;
        }

        setReportBannerBlob(blob);
        changeModel({ reportBannerBlobId: blob.id });

        return blob;
    }, [blobUploadService, model, changeModel, setReportBannerBlob]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component
            model={model} changeModel={changeModel}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            updateFromEdubase={updateFromEdubase} isUpdatingFromEdubase={isUpdatingFromEdubase} edubaseErrors={edubaseErrors}
            schoolTags={schoolTagsManager} allTags={tags} specialismTags={specialismTags}
            reportBannerBlob={reportBannerBlob} uploadReportBanner={uploadReportBanner} isUploadingReportBanner={isUploadingReportBanner} uploadReportBannerErrors={uploadReportBannerErrors}
            />
    );
};

export const __EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.schools.repository(),
    loadViewModel: services.api.schools.viewModels.edit(),
    edubaseService: services.edubaseService(),
    schoolTagsRepository: services.api.schoolTags.repository(),
    getTrustFromSubscription: services.api.trusts.viewModels.getTrustFromSubscription(),
    blobUploadService: services.blobUploadService(),
}))(_EditContainer);

export const EditContainer = connect(
    (state: AppState) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(__EditContainer);