import * as React from 'react';
import { ReportProps } from '../reportGenerator/ReportProps';
import { SEFProjectTopicUi } from './SEFProjectTopicUi';
import { Row, Col, Card } from 'reactstrap';
import { getGradeString } from '../../../utilities/getGradeString';
import { ProjectAnalysisResultItem } from '../../../api/models/ProjectAnalaysisResult';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../utilities/replacePlaceholders';
import { Tag } from '../../../api/models/Tag';
import { ManagedChildModels } from '../../shared/hooks/useManagedChildModels';
import { SchoolTag } from '../../../api/models/SchoolTag';



export interface SEFProjectSectionUiProps extends ReportProps {
    projectSectionId: string
}

/**
 * Strenghts and recommendations breakdown within a section.
 */
export const SEFProjectSectionUi = (props: SEFProjectSectionUiProps) => {
    // Find the appropriate items we want to work with.
    let [projectSection, section, /*results,*/ projectTopics, myResults] = React.useMemo(() => {
        let ps = props.projectSections.models.find(item => item.id === props.projectSectionId);
        let sectionId = '';
        if (ps) {
            sectionId = ps.sectionId;
        }

        let s = props.sections.find(item => item.id === sectionId);

        
        
        //let r = props.resultsBySection.find(item => item.projectSectionId === props.projectSectionId);
        //let rr: Array<ProjectAnalysisResult> = [];
        //if (r) {
        //    rr = r.results;
        //}

        let childTopics = props.topics.filter(item => item.sectionId === sectionId);
        let pt = props.projectTopics.models.filter(item => childTopics.find(it => it.id === item.topicId));

        

        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (props.results && props.results.length) {
            myResults = props.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        return [ps, s, /*rr,*/ pt, myResults];
    }, [props.projectSections, props.sections, props.projectSectionId, props.topics, props.projectTopics, props.resultsBySection]);

    if (!props.model) {
        return (<></>);
    }

    if (!projectSection) {
        return (<></>);
    }
    

    return (
        <div>
            <Row>
                <Col>
                    <h4>{section && replacePlaceholders(section.name, props.placeholderReplacementMode) || 'Topic'}</h4>
                </Col>
                <Col xs="auto">
                    <h4>
                        {getGradeString(myResults && myResults.percentageQuestionScore || 0)}
                    </h4>
                </Col>
            </Row>

            <Card body tag="div" style={{ borderLeftStyle: 'none', borderRightStyle: 'none', borderBottomStyle: 'none', borderRadius: '0px', }}>
                {
                    projectTopics.length ? (
                        <div>
                            {
                                projectTopics.map(item => (
                                    <SEFProjectTopicUi key={item.id} projectTopicId={item.id} {...props} />
                                    ))
                            }
                        </div>
                        ): null
                }
            </Card>
        </div>        
    );
};
