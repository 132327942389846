import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { connect } from 'react-redux';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useAsyncCallback } from 'react-use-async-callback';
import { Badge, Button, Card, CardBody, Col, Modal, ModalBody, Row } from 'reactstrap';
import { BlobUrl } from '../../api/models/BlobUrl';
import { Consultant } from '../../api/models/Consultant';
import { Video } from '../../api/models/Video';
import { VideoResource } from '../../api/models/VideoResource';
import { VideoTag } from '../../api/models/VideoTag';
import { VideoVideoTag } from '../../api/models/VideoVideoTag';
import { useApiService } from '../../api/useApiService';
import { AppStateCore } from '../../store';
import { Identity } from '../../store/user';
import { generateClickableUrl } from '../../utilities/generateClickableUrl';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { PlainTextWithBrs } from '../shared/PlainTextWithBrs';

export interface WatchVideoModalProps {
    id: string,
    toggle: () => void,
    baseUrl: string,

    // From redux
    user?: Identity | undefined,
}

interface RelatedVideoCount {
    targetId: string,
    count: number,
}

/**
 * Modal for watching a video.
 * @param props
 */
export const _WatchVideoModal = (props: WatchVideoModalProps) => {
    const {
        id,
        toggle,
        baseUrl,
        user,
    } = props;

    const api = useApiService();
    const loadViewModel = api.videos.viewModels.watch();
    const consultantReferralsRepository = api.consultantReferrals.repository();

    const [model, setModel] = React.useState<Video | undefined>();
    const [consultant, setConsultant] = React.useState<Consultant | undefined>();
    const [videoTags, setVideoTags] = React.useState<Array<VideoTag>>([]);
    const [videoVideoTags, setVideoVideoTags] = React.useState<Array<VideoVideoTag>>([]);
    const [videoBlob, setVideoBlob] = React.useState<BlobUrl | undefined>();
    const [thumbnailBlob, setThumbnailBlob] = React.useState<BlobUrl | undefined>();
    const [consultantPhotoBlob, setConsultantPhotoBlob] = React.useState<BlobUrl | undefined>();
    const [videoResources, setVideoResources] = React.useState<Array<VideoResource>>([]);
    const [videoResourceBlobs, setVideoResourceBlobs] = React.useState<Array<BlobUrl>>([]);
    const [relatedVideoCounts, setRelatedVideoCounts] = React.useState<Array<RelatedVideoCount>>([]);
    const [load, { isExecuting: isLoading, errors: loadErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(id);
        setConsultant(result.consultant);
        setVideoTags(result.videoTags);
        setVideoVideoTags(result.videoVideoTags);
        setVideoBlob(result.videoBlob);
        setThumbnailBlob(result.thumbnailBlob);
        setConsultantPhotoBlob(result.consultantPhotoBlob);
        setRelatedVideoCounts(result.relatedVideoCounts);
        setVideoResources(result.videoResources);
        setVideoResourceBlobs(result.videoResourceBlobs);
        setModel(result.model);
    }, [id, loadViewModel, setModel, setConsultant, setVideoTags, setVideoVideoTags, setVideoBlob, setThumbnailBlob, setConsultantPhotoBlob, setRelatedVideoCounts,
        setVideoResources, setVideoResourceBlobs,
    ]);

    React.useEffect(() => {
        if ((!model || model.id !== id) && !isLoading) {
            load();
        }
    }, [load, isLoading, model, id]);

    const [isContactDetailsOpen, setIsContactDetailsOpen] = React.useState<boolean>(false);
    const showContactDetails = React.useCallback(async () => {
        // Show the details.
        setIsContactDetailsOpen(true);

        // Log the referral to the database.
        if (model && consultant && user) {
            const newReferral = await consultantReferralsRepository.create();
            newReferral.consultantId = consultant.id;
            newReferral.videoId = model.id;
            newReferral.userId = user && user.userId;
            await consultantReferralsRepository.save(newReferral.id, newReferral, true);
        }
    }, [setIsContactDetailsOpen, consultantReferralsRepository, user]);

    // Work out how many videos this consultant has.
    const consultantVideoCount = React.useMemo(() => {
        if (!consultant || !relatedVideoCounts) {
            return 0;
        }

        const myCount = relatedVideoCounts.find(it => it.targetId === consultant.id);
        if (!myCount) {
            return 0;
        }

        return myCount.count;
    }, [consultant, relatedVideoCounts]);

    // Ability to load related videos
    const navigation = useUniversalNavigation(props);
    const navigateToRelatedVideos = React.useCallback((tagId: string) => {
        navigation.push(`${baseUrl}?tagId=${tagId}`);
        toggle();
    }, [navigation, baseUrl]);

    if (!model) {
        return (<></>);
    }

    return (
        <Modal className="watch-video-modal" size="xl" isOpen={true /* If the modal is rendered we render it open */} toggle={toggle}>
            {/*<ModalHeader toggle={toggle}>
                
            </ModalHeader>*/}
            <ModalBody>
                <div className="watch-video-modal-close" onClick={() => toggle()}>
                    &times;
                </div>

                <AlertOnErrors errors={[loadErrors]} />

                <div className="embed-responsive embed-responsive-16by9 mb-2 watch-video-video-container">
                    <video
                        src={videoBlob && videoBlob.url || ''}
                        poster={thumbnailBlob && thumbnailBlob.url || ''}
                        controls autoPlay={true}>
                    </video>
                </div>
                <div className="watch-video-modal-name">
                    {model.name}
                </div>

                <Row>
                    <Col>
                        <ConditionalFragment showIf={!!model.description}>
                            <p className="watch-video-modal-description">
                                <PlainTextWithBrs text={model.description} />
                            </p>
                        </ConditionalFragment>
                    </Col>
                    <ConditionalFragment showIf={!!videoResources.length}>
                        <Col xs={12} md="auto">
                            <Card body className="watch-video-modal-resources">
                                <strong>Useful resources</strong>
                                {
                                    videoResources.map(item => {
                                        const myFileBlob = videoResourceBlobs.find(it => it.id === item.blobId);

                                        return (
                                            <div key={item.id}>
                                                {
                                                    myFileBlob ? (
                                                        <a href={myFileBlob.url} download={myFileBlob.filename} target="_blank">
                                                            {item.name}
                                                        </a>
                                                    ) : item.url ? (
                                                        <a href={generateClickableUrl(item.url)} target="_blank">
                                                            {item.name}
                                                        </a>
                                                    ) : (
                                                                <span className="text-muted">{item.name}</span>
                                                            )
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </Card>
                        </Col>
                    </ConditionalFragment>
                </Row>



                <Card className="watch-video-modal-consultant">
                    <CardBody tag="div">
                        <Row>
                            <Col xs="auto">
                                <div className="watch-video-modal-consultant-photo" style={{ backgroundImage: `url(${consultantPhotoBlob && consultantPhotoBlob.url || ''})` }}>
                                    <ConditionalFragment showIf={!consultantPhotoBlob}>
                                        {consultant && `${consultant.forename.slice(0, 1)}${consultant.surname.slice(0, 1)}`.toUpperCase() || ''}
                                    </ConditionalFragment>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    {consultant && `${consultant.forename} ${consultant.surname}` || ''}
                                </div>
                                <div><small>
                                    {consultant && consultant.company || ''}
                                </small></div>
                                <div>
                                    <PlainTextWithBrs text={consultant && consultant.bio || ''} />
                                </div>
                            </Col>
                            <ConditionalFragment showIf={!!consultant}>
                                <Col xs="auto">
                                    {
                                        isContactDetailsOpen && !!consultant ? (
                                            <>
                                                <ConditionalFragment showIf={!!consultant.phone}>
                                                    <div>
                                                        <a href={`tel:${consultant.phone}`}>{consultant.phone}</a>
                                                    </div>
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={!!consultant.email}>
                                                    <div>
                                                        <a href={`mailto:${consultant.email}`}>{consultant.email}</a>
                                                    </div>
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={!!consultant.web}>
                                                    <div>
                                                        <a href={consultant.web} target="_blank">{consultant.web}</a>
                                                    </div>
                                                </ConditionalFragment>
                                            </>
                                        ) : (
                                                <Button color="secondary" outline onClick={() => showContactDetails()}>
                                                    Want to get in touch with {consultant && `${consultant.forename} ${consultant.surname}` || ''}?
                                                </Button>
                                            )
                                    }
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    </CardBody>
                </Card>

                <Row>
                    <Col xs={12} sm="auto" className="text-muted">
                        Learn more about:
                    </Col>
                    <Col>
                        {
                            videoTags.map(tag => {
                                if (!relatedVideoCounts) {
                                    return null;
                                }

                                const myCount = relatedVideoCounts.find(it => it.targetId === tag.id);

                                if (!myCount || !myCount.count) {
                                    return null;
                                }

                                return (
                                    <span key={tag.id} className="watch-video-more-video-tag" onClick={() => navigateToRelatedVideos(tag.id)}>
                                        {tag.name} <Badge>{myCount.count}</Badge>
                                    </span>
                                );
                            })
                        }

                        {
                            !!consultant ? (
                                <span className="watch-video-more-video-tag" onClick={() => navigateToRelatedVideos(consultant.id)}>
                                    {`${consultant.forename} ${consultant.surname}`} <Badge>{consultantVideoCount}</Badge>
                                </span>
                                ): null
                        }
                        
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export const WatchVideoModal = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        user: state.user.identity,
    }),
    /* mapDispatchToProps */
    null
)(_WatchVideoModal);
