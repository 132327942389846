import * as React from 'react';
import { EditUiProps, EditContainer } from './EditContainer';
import { withContainer } from 'react-withcontainer';
import { Form, Label, FormGroup } from 'reactstrap';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OurTeam } from './OurTeam';
import { SubObjectives } from './SubObjectives';
import { ElasticInput } from 'reactstrap-elasticinput';

export const EditUi = (props: EditUiProps) => {
    let { model, changeModel, save, isSaving, savingErrors, trust, ...rest } = props;


    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const formSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let result = await save();
        return result;
    }, [model, save, isSaving]);

    if (!model || !trust) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return <>
        <div className="main-container">
            <div className="main-heading">
                <h1>Growth plan for {trust.name}</h1>
            </div>
            <Form onSubmit={(ev) => formSubmit(ev)}>
                <div className="toolbar-top">
                    <ButtonAsync type="submit" color="primary" isExecuting={isSaving} executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                </div>
                {/*
                <FormGroup>
                    <Label htmlFor="startDate">Start time</Label>
                    <ValidatedInput type="date" name="startDate" value={model.startDate} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="endDate">End date</Label>
                    <ValidatedInput type="date" name="endDate" value={model.endDate} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="duration">Duration</Label>
                    <ValidatedInput type="text" readOnly name="duration" value={props.duration.toDurationString()} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                */}
                <FormGroup>
                    <Label htmlFor="visionAndValues">Vision and values</Label>
                    <ElasticInput name="visionAndValues" value={model.visionAndValues} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="headlineStrategicBenefits">Headline strategic benefits</Label>
                    <ElasticInput name="headlineStrategicBenefits" value={model.headlineStrategicBenefits} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="accountability">Accountability</Label>
                    <ElasticInput name="accountability" value={model.accountability} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="equalityAndDiversity">Equality and diversity</Label>
                    <ElasticInput name="equalityAndDiversity" value={model.equalityAndDiversity} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="continuousImprovement">Continuous improvement</Label>
                    <ElasticInput name="continuousImprovement" value={model.continuousImprovement} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="changeManagement">Change management</Label>
                    <ElasticInput name="changeManagement" value={model.changeManagement} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="riskManagement">Risk management</Label>
                    <ElasticInput name="riskManagement" value={model.riskManagement} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ourResources">Our resources</Label>
                    <ElasticInput name="ourResources" value={model.ourResources} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <SubObjectives {...props} />
                </FormGroup>
                <FormGroup>
                    <h3>Our team</h3>
                    <OurTeam {...rest} />
                </FormGroup>
            </Form>
            {/*
            <div>
                <SchoolDetails jsonString={props.model ? props.model.potentialSchoolDetails : ""} setJsonString={(s) => { props.changeModel({ potentialSchoolDetails: s }) }} schools={props.potentialSchools} />
            </div>
            */}
        </div>
    </>
};

export const Edit = withContainer(EditContainer)(EditUi);