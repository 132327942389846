import * as React from "react";
import { ValidationState } from "pojo-validator";
import { ContainerComponentProps } from "react-withcontainer";
import { ChangePassword } from "../../api/models/ChangePassword";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { AuthenticationService } from "../../services/AuthenticationService";
import { PasswordValidation } from "../../services/passwordValidation/PasswordValidation";
import { useValidatorCallback } from "pojo-validator-react";
import { useAsyncCallback } from "react-use-async-callback";
import { generatePasswordRulesDescrption } from "../../utilities/generatePasswordRulesDescription";
import { SaveOnlyEditUiPropsBase } from "../containers/common/SaveOnlyEditUiPropsBase";


export interface ChangePasswordContainerProps extends ContainerComponentProps<ChangePasswordUiProps> {
    authenticationService: AuthenticationService,
    passwordValidation: PasswordValidation
};

export interface ChangePasswordUiProps extends SaveOnlyEditUiPropsBase<ChangePassword> {
    saveSuccessful: boolean,
    passwordRulesDescription: string
}

/**
 * Container that allows us to change the password of the current user.
 * @param props
 */
export const _ChangePasswordContainer = (props: ChangePasswordContainerProps) => {
    let { component, authenticationService, passwordValidation, ...rest } = props;

    const [model, setModel] = React.useState<ChangePassword>({ currentPassword: '', newPassword: '' });

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<ChangePassword>) => {
        setModel(prevState => ({
            ...prevState,
            ...changes
        }));
    }, [setModel]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('currentPassword')) {
            validation.singleCheck('currentPassword', () => !model.currentPassword, 'Old password is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('newPassword')) {
            validation.clearErrors('newPassword');
            validation.check('newPassword', () => !model.newPassword, 'New password is required');
            const [isPasswordValid, passwordErrors] = passwordValidation.check(model.newPassword);
            validation.check('newPassword', () => !isPasswordValid, `Password ${passwordErrors.join(', ')}.`)
        }
    }, [model, passwordValidation]);

    // Save to the store.
    const [saveSuccessful, setSaveSuccessful] = React.useState<boolean>(false);
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        setSaveSuccessful(false);

        if (!validate()) {
            return false;
        }

        // Change the user's password based on the model.
        let ok = await authenticationService.changePassword(model.currentPassword, model.newPassword);
        setSaveSuccessful(ok);
        return ok;
    }, [model, validate, authenticationService]);

    // Generate a description of the required password rules by validating an empty password.
    const passwordRulesDescription = React.useMemo(() => generatePasswordRulesDescrption(passwordValidation), [passwordValidation]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors} saveSuccessful={saveSuccessful}
            passwordRulesDescription={passwordRulesDescription} />
    );
};

export const ChangePasswordContainer = withServiceProps<ChangePasswordContainerProps, AppServicesCore>(services => ({
    authenticationService: services.authenticationService(),
    passwordValidation: services.passwordValidation()
}))(_ChangePasswordContainer);