import * as React from 'react';
import { Form, FormGroup, Label, Alert, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { EditDetailsUiProps, EditDetailsContainer } from './EditDetailsContainer';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { ValidatedElasticInput } from '../shared/ValidatedElasticInput';
import { Guid } from 'guid-string';

export const EditDetailsUi = (props: EditDetailsUiProps) => {
    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }
    }, [props.save]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    var hasSubscription = !Guid.isEmpty(props.model.subscriptionId);

    return (
        <div>
            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
            {
                props.saveSuccessful ? (
                    <Alert color="success">
                        Your details have been updated.
                    </Alert>
                ) : null
            }

            <div className="help-text">
                Here you can change your own personal details.
            </div>

            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <ValidatedInput type="email" name="email" value={props.model.email} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="forename">Forename</Label>
                    <ValidatedInput type="text" name="forename" placeholder="Forename" value={props.model.forename} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="surname">Surname</Label>
                    <ValidatedInput type="text" name="surname" placeholder="Surname" value={props.model.surname} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                {
                    hasSubscription ?
                        <div>
                            <FormGroup>
                                <Label>Job title</Label>
                                <Row>
                                    <Col sm="12" lg="6">
                                        <ValidatedInput type="text" name="jobTitle" value={props.model.jobTitle} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label>Your bio</Label>
                                <ValidatedElasticInput type="textarea" value={props.model.bio} onChange={onChange} name="bio" onBlur={onValidate} validationErrors={props.validationErrors} />
                            </FormGroup>
                        </div> : null
                }
                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                </div>
            </Form>
        </div>
    );
};

export const EditDetails = withContainer(EditDetailsContainer)(EditDetailsUi);
