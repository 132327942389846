import * as React from 'react';
import { SEFProjectSectionUi } from './SEFProjectSectionUi';
import { ReportProps } from '../reportGenerator/ReportProps';


/**
 * Breakdown that shows a section by section and topic by topic breakdown of areas of stength vs recommendations.
 */
export const SEFBreakdownUi = (props: ReportProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            {
                props.projectSections.models.map(item => (
                    <SEFProjectSectionUi key={item.id} projectSectionId={item.id} {...props} />
                    ))
            }
        </div>        
    );
};
