import { UserState, login, logout, Identity } from "../store/user";
import { AuthenticationService } from "../services/AuthenticationService";
import { Dispatch } from "react";
import { AnyAction } from "redux";

/**
 * Renews the UserState from the redux store and will either update the token with a new token from the server or logout.
 */
export async function renewCurrentUserToken(user: UserState, authenticationService: AuthenticationService, login: (token: string | undefined, identity: Identity | undefined) => void, logout: () => void, performLogoutOnFailure: boolean): Promise<void> {
    // If the user is not logged in then do no renewal.
    if (!user.token) {
        return;
    }

    let originalIdentity = user.identity;

    // If the user is logged in, then renew the token and give the server a chance to reject the stored credentials.
    try {
        let newToken = await authenticationService.renewToken();
        if (newToken) {
            // Special code for administrators of Edudiligence to let the "managed"/emaulted subscription persist.
            if (originalIdentity && originalIdentity.subscriptionId
                && newToken.identity && !newToken.identity.subscriptionId
                && newToken.identity.roles.find(item => item === 'Administration')
            ) {
                newToken.identity = { ...newToken.identity, subscriptionId: originalIdentity.subscriptionId };
            }

            // Store the new token as the logged in user.
            login(newToken.token, newToken.identity);
        }
    } catch (error) {
        // Log the user out.
        if (performLogoutOnFailure) {
            logout();
        }
    }
}
