import * as React from 'react';
import { Form, FormGroup, Label, Row, Col, Container, Collapse, FormText, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';
import { TwoValueSwitch } from '../../shared/TwoValueSwitch';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ElasticInput } from 'reactstrap-elasticinput';
import { ValidatedElasticInput } from '../../shared/ValidatedElasticInput';
import { Guid } from 'guid-string';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    // Upload a photo.
    const [photoFileSizeError, setPhotoFileSizeError] = React.useState<string>('');
    const onUploadPhoto = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setPhotoFileSizeError('');

        if (!event.currentTarget.files || !event.currentTarget.files.length) {
            return;
        }

        // Limit the file size upload to 12mb as per specfieid requirements.
        const maximumFileSize = (1024 * 1024 * 12);
        if (event.currentTarget.files[0].size > maximumFileSize) {
            setPhotoFileSizeError(`The maximum file size you can upload is 12mb.  This file is ${(event.currentTarget.files[0].size / 1024.0 / 1024.0).toFixed(1)}mb and too large to be uploaded.`);
            return;
        }

        // Upload the files as the viphotodeo.
        await props.uploadPhoto(event.currentTarget.files);
    }, [props.uploadPhoto, setPhotoFileSizeError]);


    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add consultant' : 'Edit consultant'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <Form onSubmit={onSubmit}>
                <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup>
                            <Label htmlFor="forename">Forename</Label>
                            <ValidatedInput type="text" name="forename" autoComplete="off" placeholder="Forename" value={props.model.forename} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                        <FormGroup>
                            <Label htmlFor="surname">Surname</Label>
                            <ValidatedInput type="text" name="surname" autoComplete="off" placeholder="Surname" value={props.model.surname} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="company">Company</Label>
                            <ValidatedInput type="text" name="company" autoComplete="off" placeholder="Company" value={props.model.company} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="email">Email</Label>
                            <ValidatedInput type="email" name="email" autoComplete="off" placeholder="Email" value={props.model.email} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="phone">Phone</Label>
                            <ValidatedInput type="tel" name="phone" autoComplete="off" placeholder="Phone" value={props.model.phone} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="web">Web link</Label>
                            <ValidatedInput type="url" name="web" autoComplete="off" placeholder="Web" value={props.model.web} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md="">
                        <FormGroup>
                            {
                                !props.photoBlob && !props.photoBlob ? (
                                    <div className="video-container video-container-admin video-thumbnail">
                                        <div className="nothing-here">
                                            <div>
                                                <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                You have not uploaded a photo yet.
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                        <div className="consultant-photo-preview-container">
                                            <img
                                                className="img-fluid"
                                                src={props.photoBlob && props.photoBlob.url || ''}
                                                alt="Photo"
                                                style={{ maxHeight: '300px'}}
                                            />
                                        </div>
                                    )
                            }

                            <AlertOnErrors errors={[photoFileSizeError]} />

                            <div>
                                <Label className="btn btn-outline-secondary file-upload-button">
                                    {
                                        props.isUploadingPhoto ? (
                                            <>
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <> </><span>Uploading...</span>
                                            </>
                                        ) : (
                                                <span>
                                                    {
                                                        Guid.isEmpty(props.model.photoBlobId) ? 'Upload photo...' : 'Update photo...'
                                                    }
                                                </span>
                                            )
                                    }

                                    <Input type="file" name="files" onChange={onUploadPhoto} />
                                </Label>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label>Bio</Label>
                    <ValidatedElasticInput type="textarea" value={props.model.bio} onChange={onChange} name="bio" onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>


                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
