import * as React from 'react';
import { Row, Col, Table, Card } from 'reactstrap';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ReportProps } from '../reportGenerator/ReportProps';
import { ProjectAnalysisResult, ProjectAnalysisResultItem } from '../../../api/models/ProjectAnalaysisResult';
import { getGradeString } from '../../../utilities/getGradeString';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../utilities/replacePlaceholders';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Strength } from '../../../api/models/Strength';
import { Risk } from '../../../api/models/Risk';
import { Question } from '../../../api/models/Question';
import { QuestionResponse } from '../../../api/models/QuestionResponse';
import { QuestionChoice } from '../../../api/models/QuestionChoice';


export interface RAGProjectTopicUiProps extends ReportProps {
    projectTopicId: string
}

/**
 * Strengths vs recommendations breakdown for a project topic.
 */
export const RAGProjectTopicUi = (props: RAGProjectTopicUiProps) => {
    const {
        questionResponses,
        questionChoices,
    } = props;

    // Find the appropriate items we want to work with.
    let [projectTopic, topic, results, questions, /*questionaires, */ excellences, strengths, risks, myResults, ] = React.useMemo(() => {
        let ps = props.projectTopics.models.find(item => item.id === props.projectTopicId);
        let topicId = '';
        if (ps) {
            topicId = ps.topicId;
        }

        let s = props.topics.find(item => item.id === topicId);

        let r = props.resultsByTopic.find(item => item.projectTopicId === props.projectTopicId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        const sectionResults = props.resultsBySection.find(item => !!s && item.sectionId === s.sectionId);
        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (sectionResults && sectionResults.results.length) {
            myResults = sectionResults.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        let q = props.questions.filter(item => item.topicId === topicId);
        //let qq = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));

        const strengthsx = props.strengths.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));
        const strengthsExcellences = strengthsx.filter(item => item.isExcellence);
        const strengthsAreasOfStrength = strengthsx.filter(item => !item.isExcellence);
        const risksx = props.risks.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));

        return [ps, s, rr, q, /*qq,*/ strengthsExcellences, strengthsAreasOfStrength, risksx, myResults];
    }, [props.projectTopics, props.topics, props.projectTopicId, props.questions, props.resultsByTopic, props.strengths, props.risks]);

    if (!props.model) {
        return (<></>);
    }


    if (!projectTopic) {
        return (<></>);
    }

    return (
        <div>
            <Row>
                <Col>
                    {/*Prevents redundant text when the topic name is = to section name i.e. section has only one topic*/ }
                    <ConditionalFragment showIf={topic?.name !== props.sections.find(it => it.id === topic?.sectionId)?.name}>
                        <h5>{topic && replacePlaceholders(topic.name, props.placeholderReplacementMode) || 'Topic'}</h5>
                    </ConditionalFragment>
                </Col>
                <Col xs="auto">
                    
                </Col>
            </Row>

            <Card body tag="div">
                <TableFor
                    title="How confident are you in answering these questions?"
                    items={questions}
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    questions={questions}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    subjectName={props.subject?.name}
                />
            </Card>
        </div>        
    );
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and only used internall by the files main component.
 */
const TableFor = (props: {
    title: string,
    items: Array<Question>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    questions: Array<Question>,
    questionResponses: Array<QuestionResponse>,
    questionChoices: Array<QuestionChoice>,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        questions,
        questionResponses,
        questionChoices,
        subjectName,
    } = props;

    return (
        <div className="table-responsive">
            <Table striped className="table-sm" >
                <thead>
                    <tr>
                        <th style={{width: "5%"} }></th> {/*We have to force the columns to be these widths, or the seperate tables won't align properly*/ }
                        <th style={{width: "75%"} }><span>{title}</span></th>
                        <th style={{width: "20%"} }><span>RAG</span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        questions.map(item => {
                            // Without a question we can't show anything.
                            if (!item) {
                                return null;
                            }

                            // Get the RAG response and use the right colour for it
                            const RAGchoice = questionChoices.find(it => it.questionId === item.id)?.name;
                            if (!RAGchoice) {
                                return null;
                            }
                            let RAGcolor = "";
                            switch (RAGchoice?.split(":")[0]) {

                                default:
                                    break;
                                case "Green":
                                    RAGcolor = "#4dbd74";
                                    break;
                                case "Amber":
                                    RAGcolor = "#ffc107";
                                    break;
                                case "Red":
                                    RAGcolor = "#f86c6b";
                                    break;

                            }

                            return (
                                <tr key={item.id}>
                                    <td><span>{item.questionNumber}</span></td>
                                    <td><PlainTextWithBrs text={replacePlaceholders(item.name, placeholderReplacementMode, { subjectName: subjectName })} /></td>
                                    <td> 
                                        {
                                            !!RAGchoice ? (
                                                <div style={{ color: RAGcolor }}>
                                                    {RAGchoice}
                                                </div>
                                            ): null
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};
