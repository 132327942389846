import * as React from 'react';
import { Alert, AlertProps } from 'reactstrap';

export interface AlertOnErrorsProps extends AlertProps {
    errors?: any,
    children?: any
}

/**
 * Alert that shows an <Alert color="danger"> if error is set.
 * @param props
 */
export const AlertOnErrors = (props: AlertOnErrorsProps) => {
    const { errors, color, ...rest } = props;

    // Flatten up to three levels of errors (wrapping in our own array first) and removing any
    // empty items (e.g. null, undefined, '').
    let flatErrors = [errors].flat(4).filter(item => item? true: false);
    
    // If we have no errors, return nothing.
    if (!flatErrors.length) {
        return null;
    }

    return (
        <Alert color={color || 'danger'} {...rest}>
            {props.children}
            {
                flatErrors.map((item, index) => (
                    <span key={index}>{item.message ? item.message.toString() : item.toString()}</span>
                ))
            }
        </Alert>
    );
};
