import { View, Text } from '@react-pdf/renderer';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { ProjectTopicPdfUiProps } from './ProjectTopicPdfUi';
import { Styles } from './Styles';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

export const ProjectQuestionResponsesPdfUi = (props: ProjectTopicPdfUiProps) => {
    const [thisProject, trustAverages, isDueDiligence] = React.useMemo(() => {
        return [props.results[0], props.results[1], props.projectSettings.isDueDiligence];
    }, [props]);

    const questions = React.useMemo(() => {
        return props.questions.filter(it => it.topicId === props.projectTopicId).filter(item => !item.questionaireId);
    }, [props]);

    return (
        <View break>
            <View style={{ paddingTop: 30, paddingBottom: 10 }}>
                <Text style={Styles.h6}>Reviewer responses</Text>
            </View>
            <View style={Styles.table}>
                {/*Table heading*/}
                <View style={Styles.thead}>
                    <View style={Styles.trHead}>
                        <View style={{ ...Styles.th, width: isDueDiligence ? '20%' : '25%' }}>
                            <Text>Question</Text>
                        </View>
                        <View style={{ ...Styles.th, width: isDueDiligence ? '20%' : '25%' }}>
                            <Text>Response</Text>
                        </View>
                        <View style={{ ...Styles.th, width: isDueDiligence ? '20%' : '25%' }}>
                            <Text>Feedback</Text>
                        </View>
                        <View style={{ ...Styles.th, width: isDueDiligence ? '10%' : '12%' }}>
                            <Text>Documents</Text>
                        </View>
                        <View style={{ ...Styles.th, width: isDueDiligence ? '10%' : '13%' }}>
                            <Text>Reviewer</Text>
                        </View>
                        <ConditionalFragment showIf={isDueDiligence}>
                            <View style={{ ...Styles.th, width: '20%' }}>
                                <Text>Risk?</Text>
                            </View>
                        </ConditionalFragment>
                    </View>
                </View>
                {/*Table body*/}
                <View style={{ ...Styles.tbody }}>
                    {
                        questions.map((item, index) => {
                            let response = props.questionResponses.find(it => it.questionId === item.id);
                            let questionChoiceId = '';
                            let userId = '';
                            if (response) {
                                questionChoiceId = response.questionChoiceId;
                                userId = response.userId;
                            }

                            let questionChoice = props.questionChoices.find(it => it.id === questionChoiceId);
                            let reviewer = props.reviewers.find(it => it.id === userId);

                            let risk = props.risks.find(it => it.questionId === item.id);

                            const questionResponseSchoolEvidences = props.questionResponseSchoolEvidences.filter(it => !it.archived && response && it.questionResponseId === response.id);

                            // If the question hasn't been answered, skip it.
                            if (!response) {
                                return null;
                            }

                            return (
                                <View style={Styles.tr}>
                                    {/* Add Question number */}
                                    <View style={{ ...Styles.th, width: isDueDiligence ? '20%' : '25%' }}>
                                        <Text>{replacePlaceholders(item.name, props.placeholderReplacementMode)}</Text>
                                    </View>
                                    <View style={{ ...Styles.th, width: isDueDiligence ? '20%' : '25%' }}>
                                        <Text>{questionChoice ? replacePlaceholders(questionChoice.name, props.placeholderReplacementMode) : response && response.scale > 0 ? response.scale.toString() : ''}</Text>
                                    </View>
                                    <View style={{ ...Styles.th, width: isDueDiligence ? '20%' : '25%' }}>
                                        <Text><PlainTextWithBrsPdf text={response && replacePlaceholders(response.feedback, props.placeholderReplacementMode) || ''} /></Text>
                                    </View>
                                    <View style={{ ...Styles.th, width: isDueDiligence ? '10%' : '12%' }}>
                                        {
                                            questionResponseSchoolEvidences ? (
                                                <>
                                                    {
                                                        questionResponseSchoolEvidences.map(questionResponseSchoolEvidence => {
                                                            const schoolEvidence = props.schoolEvidences.find(it => it.name === questionResponseSchoolEvidence.schoolEvidenceName);
                                                            if (!schoolEvidence) {
                                                                return null;
                                                            }

                                                            let blob: any | undefined;
                                                            if (schoolEvidence && schoolEvidence.blobId) {
                                                                const blobId = schoolEvidence.blobId;
                                                                blob = props.blobs.find(it => it.id === blobId);
                                                            }

                                                            if (!!blob && !!schoolEvidence.url) {
                                                                return (
                                                                    <View>
                                                                        <View>
                                                                            <Text>{blob && blob.filename || ''}</Text>
                                                                        </View>
                                                                        <View>
                                                                            <Text>{schoolEvidence.url}</Text>
                                                                        </View>
                                                                    </View>
                                                                );
                                                            }
                                                            else if (!!blob) {
                                                                return (
                                                                    <View>
                                                                        <Text>{blob && blob.filename || ''}</Text>
                                                                    </View>
                                                                );
                                                            } else if (!!schoolEvidence.url) {
                                                                return (
                                                                    <View>
                                                                        <Text>{schoolEvidence.url}</Text>
                                                                    </View>
                                                                );
                                                            }

                                                            return (
                                                                <View>
                                                                </View>
                                                            );
                                                        })
                                                    }
                                                </>
                                            ) : null

                                        }
                                    </View>
                                    <View style={{ ...Styles.th, width: isDueDiligence ? '10%' : '13%' }}>
                                        <Text>{reviewer && `${reviewer.forename} ${reviewer.surname}`}</Text>
                                    </View>
                                    <ConditionalFragment showIf={isDueDiligence}>
                                        <View style={{ ...Styles.th, width: '20%' }}>
                                            <Text>{replacePlaceholders(item.name, props.placeholderReplacementMode)}</Text>
                                        </View>
                                    </ConditionalFragment>
                                </View>
                            );
                        })
                    }
                </View>
            </View>
        </View>
    );
};