import * as React from "react";
import { Container, Button, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, FormGroup, FormText, Input } from "reactstrap";
import { ReportSettingsModel } from "../reportSettings/ReportSettingsModel";
import { AlertOnErrors } from "../../shared/AlertOnErrors";
import { ButtonGroup } from "reactstrap";
import { ButtonDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalBody } from "reactstrap";
import { Label } from "reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { ModalFooter } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Spinner } from "reactstrap";
import { LoadingIndicator } from "../../shared/LoadingIndicator";

export interface ReportGenerationProgressProps {
    reportSettings: ReportSettingsModel,

    errors: Array<any>,
}

/**
 * Progress screen that appears during the generation of a report inside ReportGenerator.
 */
export const ReportGenerationProgress = (props: ReportGenerationProgressProps) => {
    const {
        reportSettings,
        errors,
    } = props;

    // This screen can take a while to generate all the information, so we show a useful generation message rather than the normal spinner.
    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    Generating {reportSettings.reportName}

                    <LoadingIndicator className="inline-loading-indicator" />
                </h1>
            </div>

            <p className="text-muted">
                This can take some time, please wait.
            </p>

            <AlertOnErrors errors={errors} />
        </Container>
    );
};