import * as React from 'react';
import { FormGroup, Label, Row, Col, Input, Button, CardHeader, Card, CardBody, Badge, Dropdown, DropdownToggle, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ManagedChildModels } from '../../../components/shared/hooks/useManagedChildModels';
import { Question } from '../../../api/models/Question';
import { QuestionChoice } from '../../../api/models/QuestionChoice';
import { QuestionResponse } from '../../../api/models/QuestionResponse';
import { ElasticInput } from 'reactstrap-elasticinput';
import { BlobUrl } from '../../../api/models/BlobUrl';
import { ProjectSettings } from '../../../utilities/getProjectSettingsFromQuestionSetName';
import { ConditionalFragment } from 'react-conditionalfragment';
import { IdAndName } from "../../../api/models/IdAndName";
import { EvidenceLockerModal } from '../../schoolEvidence/EvidenceLockerModal';
import { Topic } from '../../../api/models/Topic';
import { Section } from '../../../api/models/Section';
import { SchoolEvidence } from '../../../api/models/SchoolEvidence';
import { generateClickableUrl } from '../../../utilities/generateClickableUrl';
import { Video } from '../../../api/models/Video';
import { Consultant } from '../../../api/models/Consultant';
import { VideoTile } from '../../videos/VideoTile';
import { productTierDisplayName, ProductTier, isProductTierUnlocked } from '../../../api/models/codeOnly/ProductTeir';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../utilities/replacePlaceholders';
import moment from 'moment';

export interface QuestionUiProps {
    model: Question,
    questionChoices: Array<QuestionChoice>,
    allResponses: ManagedChildModels<QuestionResponse>,
    responseId?: string,
    response: QuestionResponse | undefined,
    schoolId: string,
    topic: Topic | undefined,
    section: Section | undefined,
    subjectName: string | undefined,

    selectedQuestionChoiceId: string | undefined,
    selectQuestionChoice: (id: string) => Promise<void>,

    schoolEvidences: Array<SchoolEvidence>,
    fileBlobs: Array<BlobUrl>,

    feedbackText: string,
    changeFeedbackText: (value: string) => void,
    saveFeedbackText: (feedback: string) => Promise<void>,

    willDoText: string,
    changeWillDoText: (value: string) => void,
    saveWillDoText: (willDoText: string) => Promise<void>,

    willDoDate: string,
    changeWillDoDate: (value: string | null) => void,
    saveWillDoDate: (willDoDate: string | null) => Promise<void>,

    openAddToRiskReportModal: () => void,
    isOnRiskRegister: boolean,
    openOnRiskRegister: () => void,

    openAddToSPDRecommendationsModal: () => void,

    isOnStrengthReport: boolean,
    isExcellenceStatementOnStrengthReport: boolean,

    openAddToImprovementPlannerModal: () => void,
    IsOnImprovementPlan: boolean,
    openOnImprovementPlanner: () => void,

    projectSettings: ProjectSettings,

    hasKeyQuestions: boolean,
    switchFromKeyQuestions: boolean,
    nextTopic: IdAndName | undefined,

    saveSchoolEvidenceNames: (value: Array<string>) => Promise<void>,
    reload: () => Promise<void>,

    video: Video | undefined,
    consultant: Consultant | undefined,
    videoThumbnailBlob: BlobUrl | undefined,
    consultantThumbnailBlob: BlobUrl | undefined,
    watchVideo: () => void,

    currentTier: ProductTier,
    awardTagId: string | undefined,
    placeholderReplacementMode: PlaceholderReplacementMode,

    subjectId: string | undefined,
    isProjectSection?: boolean,
}

export const QuestionUi = (props: QuestionUiProps) => {
    const { projectSettings, topic, isExcellenceStatementOnStrengthReport, } = props;

    const [evidenceLockerModalIsOpen, setEvidenceLockerModalIsOpen] = React.useState<boolean>();
    const toggleEvidenceLockerModal = React.useCallback(() => setEvidenceLockerModalIsOpen(prevState => !prevState), [setEvidenceLockerModalIsOpen]);

    const {
        showIsOnStrengthReportMessage,
        showIsOnRiskRegisterMessage,
    } = React.useMemo(() => {
        const showIsOnStrengthReportMessage = !!(props.isOnStrengthReport
            && (props.questionChoices.find(it => it.id === props.selectedQuestionChoiceId)
                && (props.questionChoices.find(it => it.id === props.selectedQuestionChoiceId) as QuestionChoice).operationalLevel <= props.model.strengthsReportMinimumOperationalLevel));
        const showIsOnRiskRegisterMessage = !!(props.isOnRiskRegister
            && (props.questionChoices.find(it => it.id === props.selectedQuestionChoiceId)
                && (props.questionChoices.find(it => it.id === props.selectedQuestionChoiceId) as QuestionChoice).operationalLevel >= props.model.riskReportMinimumOperationalLevel));

        return {
            showIsOnStrengthReportMessage,
            showIsOnRiskRegisterMessage,
        };
    }, [props.isOnStrengthReport, props.isOnRiskRegister, props.questionChoices, props.selectedQuestionChoiceId, props.model]);

    // When within the evidence locker we upload new files or urls we want to flag that we need to do a reload on the close
    // of the evidence locker.
    const [needsReload, setNeedsReload] = React.useState<boolean>(false);

    // Toggle of the visiblity of the rich understanding tools.  If we have feedback or evidence already, default to expanded, otherwise start collapsed.
    const [isRichUnderstandingOpen, setIsRichUnderstandingOpen] = React.useState<boolean>(!!props.feedbackText || !!props.schoolEvidences.length);
    const toggleRichUnderstandingOpen = React.useCallback(() => setIsRichUnderstandingOpen(prevState => !prevState), [setIsRichUnderstandingOpen]);

    // Check if the user can use richer understanding tools.
    // These unlock for self evaluation at the sixty minute sef tier, and are always unlocked when completing a specific review regardless of school tier.
    const canUseRichUnderstadingTools = !!props.awardTagId || !!props.subjectId || isProductTierUnlocked(ProductTier.Snapshot, props.currentTier);

    // We want access to the answer that was selected.
    const selectedAnswer = React.useMemo(() => props.questionChoices.find(it => it.id === props.selectedQuestionChoiceId), [props.questionChoices, props.selectedQuestionChoiceId]);

    if (!props.model) {
        return (<></>);
    }

    return (
        <>
            <Card className="question-for-response">
                <CardHeader>
                    <Row>
                        <Col>
                            {
                                replacePlaceholders(props.model.name, props.placeholderReplacementMode, {subjectName: props.subjectName})
                            }
                            <> </>
                            {props.model.questionNumber ? (<small className="text-muted">[{props.model.questionNumber}]</small>) : null}
                            <br />

                            {/* If we are working at any tier other than SEF Lite (formally snapshot) show the teir the qustion comes from. */}
                            <ConditionalFragment showIf={isProductTierUnlocked(ProductTier.SixtyMinuteSEF, props.currentTier) && !props.awardTagId && props.projectSettings.questionSetType.tierChoice}>
                                <Badge color="secondary">
                                    {productTierDisplayName(props.model.tier as ProductTier)}
                                </Badge>
                            </ConditionalFragment>
                        </Col>

                        <ConditionalFragment showIf={canUseRichUnderstadingTools}>
                            <ConditionalFragment showIf={projectSettings.allowActions}>
                                <Col xs="auto" className="text-right">
                                    {
                                        props.IsOnImprovementPlan ? (
                                            <Button type="button" color="secondary" size="sm" onClick={props.openOnImprovementPlanner}>
                                                View on improvement planner
                                            </Button>
                                        ) : (
                                                <Button type="button" color="secondary" outline size="sm" onClick={props.openAddToImprovementPlannerModal}>
                                                    Add action
                                                </Button>
                                            )
                                    }
                                </Col>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={projectSettings.isDueDiligence}>
                            <Col xs="auto" className="text-right">
                                {
                                    // all referencees to risk actually mean SIP recomendations in this context
                                    props.isOnRiskRegister ? (
                                        <Button type="button" color="danger" size="sm" onClick={props.openOnRiskRegister}>
                                            View on risk register
                                        </Button>
                                    ) : (
                                            <Button type="button" color="danger" outline size="sm" onClick={props.openAddToRiskReportModal}>
                                                Add risk
                                        </Button>
                                        )
                                }
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </CardHeader>

                <CardBody>

                    <Row noGutters>
                        <Col>
                            <ConditionalFragment showIf={props.projectSettings.questionSetType.ragAnswers}>
                                <Label htmlFor="multiple-choices">
                                    How confident are you in the answer to this question?
                                </Label>
                            </ConditionalFragment>
                            {
                                props.questionChoices.length ? (
                                    <div className="multiple-choice">
                                        {
                                            props.questionChoices.map((item, index) => {
                                                let isSelected: boolean = (item.id === props.selectedQuestionChoiceId);

                                                // Colour the RAG answers
                                                let color: string | undefined = 'primary';
                                                if (props.projectSettings.questionSetType.ragAnswers) {
                                                    if (item.name.toLowerCase().indexOf('green') >= 0) {
                                                        color = 'success';
                                                    } else if (item.name.toLowerCase().indexOf('amber') >= 0) {
                                                        color = 'warning';
                                                    } else if (item.name.toLowerCase().indexOf('red') >= 0) {
                                                        color = 'danger';
                                                    }
                                                }
                                                    

                                                return (
                                                    <div key={item.id} className="question-choice">
                                                        <Button className="full-width" color={color} outline={!isSelected}
                                                            onClick={(e) => props.selectQuestionChoice(item.id)} >
                                                            {
                                                                replacePlaceholders(item.name, props.placeholderReplacementMode, { subjectName: props.subjectName })
                                                            }
                                                        </Button>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                ) : null
                            }

                            {
                                props.projectSettings.questionSetType.ragAnswers ? (
                                    <>
                                        {/* RAG questions use the notes field for practice and give "WillDo" fields for making a plan. an plan  */}
                                        <FormGroup>
                                            <Label htmlFor="feedbackText">
                                                Practise your answer if its helpful:
                                            </Label>
                                            <ElasticInput name="feedbackText" type="textarea" placeholder=""
                                                value={props.feedbackText} onChange={(e) => props.changeFeedbackText(e.currentTarget.value)} onBlur={(e) => props.saveFeedbackText(e.currentTarget.value)}
                                            />
                                        </FormGroup>

                                        <div>
                                            {
                                                !!selectedAnswer ? (
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label htmlFor="willDoText">
                                                                    {
                                                                        selectedAnswer.name.toLowerCase().indexOf('green') >= 0 ? 'What will you do to maintain and share confidence in this area?'
                                                                            : selectedAnswer.name.toLowerCase().indexOf('amber') >= 0 ? 'What will you do to embed and strengthen your confidence in this area?'
                                                                                : selectedAnswer.name.toLowerCase().indexOf('red') >= 0 ? 'What will you do to establish confidence in this areas?'
                                                                                    : 'What will you do in this area?' // Should never be used, but included for completeness.
                                                                    }
                                                                </Label>
                                                                <ElasticInput name="willDoText" type="textarea" placeholder=""
                                                                    value={props.willDoText} onChange={(e) => props.changeWillDoText(e.currentTarget.value)} onBlur={(e) => props.saveWillDoText(e.currentTarget.value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={12} md="auto">
                                                            <Label htmlFor="willDoText">
                                                                Target date
                                                            </Label>
                                                            <Input name="willDoDate" type="date" placeholder=""
                                                                value={!props.willDoDate ? '' : moment(props.willDoDate).local().format('YYYY-MM-DD')} onChange={(e) => props.changeWillDoDate(e.currentTarget.value ? moment(e.currentTarget.value).toISOString() : null)} onBlur={(e) => props.saveWillDoDate(e.currentTarget.value ? moment(e.currentTarget.value).toISOString() : null)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ): null
                                            }
                                        </div>
                                    </>
                                ) : (
                                        <>
                                            {/* Non-RAG questions use the richer understanding tools */}
                                            <ConditionalFragment showIf={canUseRichUnderstadingTools}>
                                                {/* Auto added stengths and areas for development messages */}
                                                <ConditionalFragment showIf={props.model.autoAddToStrengthsReport && showIsOnStrengthReportMessage}>
                                                    <p>
                                                        <small className="text-success">
                                                            {
                                                                isExcellenceStatementOnStrengthReport ?
                                                                    'An area of excellence has been identified and has been added to your self-evaluation report.'
                                                                    : 'An area of strength has been identified and has been added to your self-evaluation report.'
                                                            }
                                                        </small>
                                                    </p>
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={props.model.autoAddToRiskReport && showIsOnRiskRegisterMessage}>
                                                    <p>
                                                        <small className="text-danger">
                                                            {
                                                                projectSettings.isDueDiligence ? (<>This question's response has been automatically added to the risk report.</>)
                                                                    : (<>A possible area of development for your consideration has been added to your self-evaluation report.</>)
                                                            }
                                                        </small>
                                                    </p>
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={
                                                    (props.model.autoAddToStrengthsReport && !showIsOnStrengthReportMessage)
                                                    || (props.model.autoAddToRiskReport && !showIsOnRiskRegisterMessage)
                                                }>
                                                    {/* Show a single combined message about the fact we may add things to the various reports for you */}
                                                    {
                                                        ((props.model.autoAddToStrengthsReport && !showIsOnStrengthReportMessage)
                                                            && (props.model.autoAddToRiskReport && !showIsOnRiskRegisterMessage)) ?
                                                            (<p>
                                                                <small className="text-muted">
                                                                    {
                                                                        projectSettings.isDueDiligence ? (<>If we consider the response to be an area of strength or a risk, this question will be automatically added to the due executive summary and risk reports.</>)
                                                                            : (<>If your answer suggests an area of strength or a possible area for development this will be added to your self-evaluation report.</>)
                                                                    }
                                                                </small>
                                                            </p>)
                                                            : (props.model.autoAddToStrengthsReport && !showIsOnStrengthReportMessage && !showIsOnRiskRegisterMessage) ?
                                                                (<p>
                                                                    <small className="text-muted">
                                                                        If your answer suggests an area of strength this will be added to your self-evaluation report.
                                                                    </small>
                                                                </p>)
                                                                : ((props.model.autoAddToRiskReport && !showIsOnRiskRegisterMessage && !showIsOnStrengthReportMessage)) ?
                                                                    (<p>
                                                                        <small className="text-muted">
                                                                            {
                                                                                projectSettings.isDueDiligence ? (<>If we consider the response to be a risk, this question will be automatically added to the risk report.</>)
                                                                                    : (<>If your answer suggests a possible area for development this will be added to your self-evaluation report.</>)
                                                                            }
                                                                        </small>
                                                                    </p>)
                                                                    : null
                                                    }
                                                </ConditionalFragment>

                                                {/* Rich understanding */}
                                                <div style={{ cursor: 'pointer', fontWeight: 'bold', }} onClick={() => toggleRichUnderstandingOpen()}>
                                                    Rich understanding
                                                    <> </>
                                                    <FontAwesomeIcon icon={isRichUnderstandingOpen ? 'caret-up' : 'caret-down'} />
                                                </div>
                                                <Collapse isOpen={isRichUnderstandingOpen}>
                                                    {
                                                        props.model.isFeedbackAllowed ? (
                                                            <div className="feedback">
                                                                <FormGroup>
                                                                    {
                                                                        props.model.feedbackDescription ? (
                                                                            <Label htmlFor="feedbackText">
                                                                                {props.model.feedbackDescription}
                                                                            </Label>
                                                                        ) : <Label htmlFor="feedbackText">Your own notes here</Label>
                                                                    }
                                                                    <ElasticInput name="feedbackText" type="textarea" placeholder=""
                                                                        value={props.feedbackText} onChange={(e) => props.changeFeedbackText(e.currentTarget.value)} onBlur={(e) => props.saveFeedbackText(e.currentTarget.value)}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        ) : null
                                                    }

                                                    {
                                                        props.model.isUploadAllowed ? (
                                                            <Row>
                                                                <Col>
                                                                    <div className="upload">
                                                                        <FormGroup>
                                                                            {
                                                                                props.model.uploadDescription ? (
                                                                                    <Label htmlFor="uploadFile">
                                                                                        {replacePlaceholders(props.model.uploadDescription, props.placeholderReplacementMode, { subjectName: props.subjectName })}
                                                                                    </Label>
                                                                                ) : <Label htmlFor="uploadFile">
                                                                                    Possible sources of evidence
                                                                                </Label>
                                                                            }

                                                                            {
                                                                                props.schoolEvidences.map(schoolEvidence => {
                                                                                    let fileBlob: BlobUrl | undefined;
                                                                                    if (schoolEvidence.blobId) {
                                                                                        fileBlob = props.fileBlobs.find(it => it.id === schoolEvidence.blobId);
                                                                                    }

                                                                                    return (
                                                                                        <div className="file-link">
                                                                                            {schoolEvidence.name}
                                                                                            <> </>
                                                                                            ({
                                                                                                fileBlob ? (
                                                                                                    <a href={fileBlob.url} download={fileBlob.filename} target="_blank">
                                                                                                        {fileBlob.filename}
                                                                                                    </a>
                                                                                                ) : schoolEvidence.url ? (
                                                                                                    <a href={generateClickableUrl(schoolEvidence.url)} target="_blank">
                                                                                                        {schoolEvidence.url}
                                                                                                    </a>
                                                                                                ) : (
                                                                                                    <span className="text-muted">(No file uploaded)</span>
                                                                                                )
                                                                                            })
                                                                                            <> </>
                                                                                            <Button size="sm" color="link" className="text-danger" onClick={() => {
                                                                                                props.saveSchoolEvidenceNames(props.schoolEvidences.filter(it => it.name !== schoolEvidence.name).map(it => it.name))
                                                                                            }}>
                                                                                                <FontAwesomeIcon icon="times" />
                                                                                                <span className="sr-only">
                                                                                                    Remove
                                                                                                </span>
                                                                                            </Button>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            }

                                                                            <div>
                                                                                <Row className="no-gutters">
                                                                                    <Col xs="auto">
                                                                                        <DropdownToggle color="secondary" outline onClick={() => toggleEvidenceLockerModal()}>
                                                                                            Attach or upload evidence...
                                                                                        </DropdownToggle>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ) : null
                                                    }
                                                </Collapse>
                                            </ConditionalFragment>
                                        </>
                                    )
                            }
                        </Col>
                        <ConditionalFragment showIf={!!props.video}>
                            <Col xs={12} md="auto">
                                <div className="video-inline-help-container">
                                    <div className="text-muted">
                                        Want some advice from our expert? 
                                    </div>
                                    <div>
                                        <VideoTile
                                            model={props.video as Video}
                                            thumbnailBlob={props.videoThumbnailBlob}
                                            consultant={props.consultant}
                                            consultantPhotoBlob={props.consultantThumbnailBlob}
                                            watchVideo={props.watchVideo}
                                            />
                                    </div>
                                </div>
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </CardBody>
            </Card>
            <ConditionalFragment showIf={!!evidenceLockerModalIsOpen}>
                <EvidenceLockerModal
                    schoolId={props.schoolId}
                    select={async (name) => {
                        await props.saveSchoolEvidenceNames(name);

                        if (needsReload) {
                            await props.reload();
                            setNeedsReload(false);
                        }

                        toggleEvidenceLockerModal();
                    }}
                    cancel={() => toggleEvidenceLockerModal()}
                    onUploadComplete={() => setNeedsReload(true)}
                    initialSelected={props.schoolEvidences.map(it => it.name)}
                    questionTopicOriginKey={topic && topic.originKey}
                    subjectId={props.subjectId}
                    slrSubjectName={props.subjectName}
                />
            </ConditionalFragment>
        </>
    );
};
