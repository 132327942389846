import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useAsyncCallback } from 'react-use-async-callback';
import { Badge, Card, CardBody, CardColumns, CardDeck, CardGroup, CardHeader, CardImg, CardImgOverlay, CardSubtitle, CardTitle, Col, Container, Row } from 'reactstrap';
import { BlobUrl } from '../../api/models/BlobUrl';
import { Consultant } from '../../api/models/Consultant';
import { Video } from '../../api/models/Video';
import { VideoTag } from '../../api/models/VideoTag';
import { VideoVideoTag } from '../../api/models/VideoVideoTag';
import { useApiService } from '../../api/useApiService';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { SearchAsYouTypeInput } from '../shared/SearchAsYouTypeInput';
import { VideoTile } from './VideoTile';
import { WatchVideoModal } from './WatchVideoModal';
import { isProductTierUnlocked, ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { ProductTierNotUnlockedAlert } from '../shared/productTierNotUnlocked/ProductTierNotUnlockedAlert';
import { School } from '../../api/models/School';

export interface BrowseProps {
    // From routing
    location: {
        pathname: string,
    },
}

/**
 * Main view for Micro-Consutlations.  Allows users to browse videos in the Micro-consultation library.
 * @param props
 */
export const Browse = (props: BrowseProps) => {
    const {
        location: routeLocation
    } = props;

    const navigation = useUniversalNavigation(props);

    // Work out the baseUrl to use for links to related videos.
    const baseUrl = React.useMemo(() => {
        const index = routeLocation.pathname.indexOf('/microConsultations');
        const ret = routeLocation.pathname.substr(0, index + 19);
        return ret;
    }, [routeLocation]);

    const tagId = navigation.getParam('tagId', '');
    const schoolId = navigation.getParam('schoolId', '');

    const api = useApiService();
    const loadViewModel = api.videos.viewModels.browse();
    const schoolRepository = api.schools.repository();

    const [items, setItems] = React.useState<Array<Video> | undefined>();
    const [consultants, setConsultants] = React.useState<Array<Consultant>>([]);
    const [videoTags, setVideoTags] = React.useState<Array<VideoTag>>([]);
    const [videoVideoTags, setVideoVideoTags] = React.useState<Array<VideoVideoTag>>([]);
    const [blobs, setBlobs] = React.useState<Array<BlobUrl>>([]);
    const [school, setSchool] = React.useState<School | undefined>();
    const [load, { isExecuting: isLoading, errors: loadErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel();
        setConsultants(result.consultants);
        setVideoTags(result.videoTags);
        setVideoVideoTags(result.videoVideoTags);
        setBlobs(result.blobs);

        if (schoolId) {
            const schoolResult = await schoolRepository.find(schoolId);
            setSchool(schoolResult);
        }

        setItems(result.items);
    }, [loadViewModel, setItems, setConsultants, setVideoTags, setVideoVideoTags, setBlobs, setSchool, schoolId]);

    React.useEffect(() => {
        if (!items && !isLoading) {
            load();
        }
    }, [load, isLoading, items]);

    const [activeVideoId, setActiveVideoId] = React.useState<string | undefined>();
    const watchVideo = React.useCallback((videoId: string) => {
        setActiveVideoId(videoId);
    }, [setActiveVideoId]);
    const closeVideo = React.useCallback(() => setActiveVideoId(undefined), [setActiveVideoId]);


    // Filter videos by a tag.
    const filterVideos = React.useCallback((tagId: string | null) => {
        if (tagId) {
            navigation.push(`${baseUrl}?tagId=${tagId}`);
        } else {
            navigation.push(`${baseUrl}`);
        }
    }, [navigation, baseUrl]);

    // Filter the items to be used.
    const [search, setSearch] = React.useState<string>('');
    const { items: filteredItems, videoTags: filteredVideoTags, isFilteredByVideoTag } = React.useMemo(() => {
        if (!items || !videoTags || !videoVideoTags) {
            return {
                items,
                videoTags,
                isFilteredByVideoTag: false,
            };
        }

        // If we have a search it overrides any tag filter and resets it.
        if (search) {
            const lowerSearch = search.toLowerCase();
            // Filter the videos by search text.
            const matchingVideos = items.filter(video => {
                // First search within the video metadata itself.
                if (video.name.toLowerCase().indexOf(lowerSearch) !== -1) {
                    return true;
                }

                // Then within the consultant metadata.
                const myConsultant = consultants.find(item => item.id === video.consultantId);
                if (myConsultant) {
                    if (myConsultant.forename.toLowerCase().indexOf(lowerSearch) !== -1
                        || myConsultant.surname.toLowerCase().indexOf(lowerSearch) !== -1
                        || myConsultant.company.toLowerCase().indexOf(lowerSearch) !== -1
                        || `${myConsultant.forename} ${myConsultant.surname}`.toLowerCase().indexOf(lowerSearch) !== -1
                        ) {
                        return true;
                    }
                }

                // And finally within the tags.
                const myLinks = videoVideoTags.filter(it => it.videoId === video.id);
                const myVideoTags = videoTags.filter(tag => !!myLinks.find(link => link.videoTagId === tag.id));
                for (const videoTag of myVideoTags) {
                    if(videoTag.name.toLowerCase().indexOf(lowerSearch) !== -1) {
                        return true;
                    }
                }

                // If we get here we found no matches.
                return false;
            });

            // Now filter the tags by the videos.
            const matchingTags = videoTags.filter(tag => !!videoVideoTags.find(link => link.videoTagId === tag.id && !!matchingVideos.find(video => video.id === link.videoId)));

            // If we are currntly filtered by a tag, searching clears that filter so make sure the URL reflects that and
            // we won't go back to a filtered list after searching.
            if (tagId) {
                filterVideos(null);
            }

            return {
                items: matchingVideos,
                videoTags: matchingTags,
                isFilteredByVideoTag: false,
            };
        }

        if (!tagId) {
            return {
                items,
                videoTags,
                isFilteredByVideoTag: false,
            };
        }

        // Work out the filters.
        const matchingConsultant = consultants.find(it => it.id === tagId);
        const matchingLinks = videoVideoTags.filter(it => it.videoTagId === tagId);

        // Filter the videos.
        const matchingVideos = items.filter(item => {
            if (matchingConsultant) {
                if (item.consultantId === tagId) {
                    return true;
                }
            }

            const ret = !!matchingLinks.find(it => it.videoId === item.id);
            return ret;
        });

        // Only use tags that match the filtered videos.
        const matchingTags = videoTags.filter(tag => !!videoVideoTags.find(link => link.videoTagId === tag.id && !!matchingVideos.find(video => video.id === link.videoId)));

        return {
            items: matchingVideos,
            videoTags: matchingTags,
            isFilteredByVideoTag: matchingConsultant? false: true,
        };
    }, [items, tagId, videoTags, videoVideoTags, consultants, search]);


    if (!filteredItems || !filteredVideoTags) {
        return (<LoadingIndicator />);
    }

    // If this is showing for a school and the school has not reached the right tier, hide the functionality.
    if (school && !school.isPotentialSchool && !isProductTierUnlocked(ProductTier.Snapshot, school.tier as ProductTier)) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>Micro-consultation videos</h1>
                </div>

                <ProductTierNotUnlockedAlert tier={ProductTier.Snapshot} />
            </div>
        );
    }

    return (
        <>
            <div>
                <AlertOnErrors errors={[loadErrors]} />

                <div className="video-filters">
                    <Row>
                        <Col xs={12} sm={6} md={8}>
                            <span className={`video-filter-video-tag ${!tagId && !search ? 'selected' : ''}`} onClick={() => filterVideos(null)}>
                                All <Badge>{items && items.length || 0}</Badge>
                            </span>

                            {
                                // Show filters (always based on the full library not the filtered current display).
                                videoTags.map(tag => {
                                    const count = videoVideoTags.filter(it => it.videoTagId === tag.id).length;

                                    return (
                                        <span key={tag.id} className={`video-filter-video-tag ${tag.id === tagId ? 'selected' : ''}`} onClick={() => filterVideos(tag.id)}>
                                            {tag.name} <Badge>{count}</Badge>
                                        </span>
                                    );
                                })
                            }
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <SearchAsYouTypeInput placeholder="Search micro-consultation library" value={search} onSearch={value => setSearch(value)} />
                        </Col>
                    </Row>
                </div>

                {
                    filteredVideoTags
                        .filter(tag => !isFilteredByVideoTag || tag.id === tagId /* If the filter is a videoTagId then only show that, otherwise its a consultant or no filter so still show all related videoTags */)
                        .filter(tag => isFilteredByVideoTag || tag.isCategory)
                        .map(tag => {
                        const links = videoVideoTags.filter(it => it.videoTagId === tag.id);
                        const videos = filteredItems.filter(it => !!links.find(link => link.videoId === it.id));

                        return (
                            <Card key={tag.id} className="video-browse-tag-card">
                                <CardHeader>
                                    <h5>
                                        {tag.name}
                                    </h5>
                                </CardHeader>
                                <CardBody tag="div">
                                    {
                                        videos.map(video => {
                                            const thumbnailBlob = blobs.find(it => it.id === video.thumbnailBlobId);
                                            const consultant = consultants.find(it => it.id === video.consultantId);
                                            const consultantPhotoBlob = blobs.find(it => !!consultant && it.id === consultant.photoBlobId);

                                            return (
                                                <VideoTile key={video.id}
                                                    tileSize="fixed"
                                                    model={video}
                                                    thumbnailBlob={thumbnailBlob}
                                                    consultant={consultant}
                                                    consultantPhotoBlob={consultantPhotoBlob}
                                                    watchVideo={() => watchVideo(video.id)}
                                                    />
                                            );
                                        })
                                    }
                                </CardBody>
                            </Card>
                        );
                    })
                }
            </div>

            <ConditionalFragment showIf={!!activeVideoId}>
                <WatchVideoModal
                    id={activeVideoId || ''}
                    toggle={() => closeVideo()}
                    baseUrl={baseUrl}
                />
            </ConditionalFragment>
        </>
        );
};
