import * as React from 'react';
import {  Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { AddToImprovementPlannerModalUiProps, AddToImprovementPlannerModalContainer } from './AddToImprovementPlannerModalContainer';
import { ValidatedElasticInput } from '../shared/ValidatedElasticInput';
import { withContainer } from 'react-withcontainer';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidatedDateTimeInput } from '../shared/ValidatedDateTimeInput';

export interface ExtendedAddToImprovementPlannerModalUiProps extends AddToImprovementPlannerModalUiProps {
}

export const AddToImprovementPlannerModalUi = (props: ExtendedAddToImprovementPlannerModalUiProps) => {
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    // Display the UI.

    if (!props.model) {
        return (<></>);
    }

    return (
        <Modal size="xl" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                {
                    <>Add an action to the improvement planner</>
                }
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={props.savingErrors} />
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label htmlFor="name">Action</Label>
                            <ValidatedInput name="name" type="text" autoComplete="off" value={props.model.name || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label htmlFor="description">Description</Label>
                            <ValidatedElasticInput type="textarea" name="description" placeholder="Action description" value={props.model.description} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormGroup>
                            <Label htmlFor="actionAddedDate">Due date</Label>
                            <ValidatedDateTimeInput name="actionAddedDate" type="date" value={props.model.actionAddedDate || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <ButtonAsync color="primary" onClick={props.save} isExecuting={props.isSaving}
                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                    Save
                </ButtonAsync>
                <Button color="primary" outline onClick={props.cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export const AddToImprovementPlannerModal = withContainer(AddToImprovementPlannerModalContainer)(AddToImprovementPlannerModalUi);