import * as React from 'react';
import { FormGroup, Label, Row, Col, Button, Collapse, CardHeader, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidatedElasticInput } from '../../../shared/ValidatedElasticInput';
import { Section } from '../../../../api/models/Section';
import { ValidationErrors } from 'pojo-validator';
import { ManagedChildModels } from '../../../../components/shared/hooks/useManagedChildModels';
import { Topic } from '../../../../api/models/Topic';
import { TopicUi } from './TopicUi';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';
import { Question } from '../../../../api/models/Question';
import { Impact } from '../../../../api/models/Impact';
import { Tag } from '../../../../api/models/Tag';
import { QuestionTag } from '../../../../api/models/QuestionTag';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AwardTag } from '../../../../api/models/AwardTag';
import { Video } from '../../../../api/models/Video';
import { QuestionSetTypeSettings } from '../QuestionSetType';

export interface SectionUiProps {
    model: Section,
    questionSetType: QuestionSetTypeSettings,
    isCreate: boolean,
    change: (changes: Partial<Section>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,

    topics: ManagedChildModels<Topic>,
    questions: ManagedChildModels<Question>,
    questionChoices: ManagedChildModels<QuestionChoice>,
    tags: Array<Tag>,
    questionTags: ManagedChildModels<QuestionTag>,
    awardTags: Array<AwardTag>,
    videos: Array<Video>,

    impacts: Array<Impact>,

    isPublished: boolean,
    subscriptionId: string | undefined,
    isReadOnly: boolean,

    changeQuestionOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
    changeSectionOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
    changeTopicOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
}

export const SectionUi = (props: SectionUiProps) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(props.isCreate);
    const toggleOpen = React.useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, [setIsOpen]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.change({ [target.name]: (target.type == 'checkbox' ? target.checked : target.value) });
    }, [props.change]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    let topics = React.useMemo(() => props.topics.models.filter(it => it.sectionId == props.model.id), [props.topics.models]);

    return (
        <Card>
            <CardHeader onClick={toggleOpen} style={{ cursor: 'pointer' }}>
                <Row>
                    <ConditionalFragment showIf={!props.isReadOnly}>
                        <Col xs="auto">
                            <Button size="sm" color="primary" outline onClick={(e) => { e.preventDefault(); setIsOpen(false); props.changeSectionOrder('up', props.model.id); e.stopPropagation(); }}>
                                <FontAwesomeIcon icon='caret-up' />
                            </Button>
                            <Button size="sm" className="ml-1" color="primary" outline onClick={(e) => { e.preventDefault(); setIsOpen(false); props.changeSectionOrder('down', props.model.id); e.stopPropagation(); }}>
                                <FontAwesomeIcon icon='caret-down' />
                            </Button>
                        </Col>
                    </ConditionalFragment>

                    <Col>
                        {props.model.name? props.model.name: '(New section)'}
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon="cheese" style={{ color: props.model.progressWheelColor }} />
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen? 'caret-up': 'caret-down'} />
                    </Col>
                </Row>
            </CardHeader>

            <Collapse isOpen={isOpen}>
                {
                    isOpen ? (
                        <CardBody>
                            <FormGroup>
                                <Label htmlFor="name">Section name</Label>
                                <Row>
                                    <Col>
                                        <ValidatedInput type="text" readOnly={props.isReadOnly} name="name" autoComplete="off" placeholder="Section name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                    </Col>
                                    {
                                        !props.isPublished /* Can't remove sections from a published question set */ ? (
                                            <Col xs="auto">
                                                <Button color="link" onClick={props.remove}><FontAwesomeIcon icon="trash-alt" color="danger" /><span className="sr-only">Remove</span></Button>
                                            </Col>
                                            ): null
                                    }
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="description">Description for executive summary</Label>
                                <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="description" placeholder="Description for the executive summary" value={props.model.description} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="description">Progress wheel colour</Label>
                                <ValidatedInput type="color" readOnly={props.isReadOnly} name="progressWheelColor" placeholder="#000000" value={props.model.progressWheelColor} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="">Topics</Label>
                                <div>
                                    <div>
                                        {
                                            topics.map((item, index) => (
                                                <TopicUi key={item.id} model={item} isCreate={props.topics.isAddedModel(item.id)}
                                                    questionSetType={props.questionSetType}
                                                    change={(changes: Partial<Topic>) => props.topics.change(item.id, changes)}
                                                    validate={(fieldsToCheck?: Array<string>) => props.topics.validateModel(item.id, fieldsToCheck)}
                                                    validationErrors={props.topics.validationErrorsFor(item.id)} remove={() => props.topics.removeModel(item.id)}
                                                    questions={props.questions}
                                                    questionChoices={props.questionChoices}
                                                    impacts={props.impacts}
                                                    isPublished={props.isPublished}
                                                    subscriptionId={props.subscriptionId}
                                                    isReadOnly={props.isReadOnly}
                                                    tags={props.tags} questionTags={props.questionTags}
                                                    awardTags={props.awardTags}
                                                    videos={props.videos}
                                                    changeTopicOrder={props.changeTopicOrder}
                                                    changeQuestionOrder={props.changeQuestionOrder}
                                                />
                                            ))
                                        }
                                    </div>
                                    {
                                        !props.isPublished /* Can't add topics to a published question set */? (
                                            <Button color="primary" outline onClick={() => props.topics.addModel({ questionSetId: props.model.questionSetId, sectionId: props.model.id })}>Add topic</Button>
                                            ): null
                                    }
                                </div>
                            </FormGroup>
                        </CardBody>
                        ): null
                }
                
            </Collapse>
        </Card>
    );
};
