import { SEFDocxProps, finalizeChildren } from './SEFDocx';
import { IImageEnumerator } from '../../executiveSummary/pdf/ImageEnumerator';
import { Paragraph, ImageRun } from 'docx';

/**
 * Strength barchar summary section of SEF (docx format).
 */
export interface StrengthsSummaryDocxUiProps extends SEFDocxProps {
    imageEnumerator: IImageEnumerator
}

export const StrengthsSummaryDocxUi = (props: StrengthsSummaryDocxUiProps) => {
    if (!props.model) {
        return null;
    }

    const image = props.imageEnumerator.getNextImageWithMetadata();

    return [
        new Paragraph({
            children: finalizeChildren([
                new ImageRun({
                    data: image.dataUrl,
                    transformation: {
                        width: 600,
                        height: Math.round(600 / image.aspectRatio),
                    },
                }),
            ]),
        }),
        new Paragraph({ text: '', }), // Empty paragraph for spacing and making customising the document easier.
    ];
};
