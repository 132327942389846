import * as React from 'react';
import { Button, Table, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { Link } from 'react-router-dom';
import { ListContainer, ListUiProps } from './ListContainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { InfiniteScrollingTable } from '../../shared/infiniteScrolling/InfiniteScrollingTable';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';

export const CurrentListUi = (props: ListUiProps) => {
    const navigation = useUniversalNavigation(props);

    const loadPage = React.useCallback((page: number) => {
        props.loadPage(page, props.search);
    }, [props.loadPage, props.search]);

    const [menuIsOpen, setMenuIsOpen] = React.useState<{ [id: string]: boolean }>({});
    const toggleMenuOpen = React.useCallback((id: string) => {
        setMenuIsOpen(prevState => ({
            ...prevState,
            [id]: (prevState[id] ? false : true)
        }));
    }, [setMenuIsOpen]);

    // Open the default action when the user clicks on a row.
    const onRowDoubleClick = React.useCallback((id: string, event: React.MouseEvent<HTMLTableRowElement>) => {
        navigation.push(`/school/${encodeURIComponent(id)}`);
    }, [navigation]);

    const filteredItems = React.useMemo(() => {
        if (!props.model) {
            return [];
        }

        return props.model.filter(item => !item.isPotentialSchool);
    }, [props.model]);

    return (
        <div className="main-container">
            <AlertOnErrors errors={props.loadingErrors} />

            <PrivateContainer role="Administration">
                <div className="toolbar-top">
                    <LinkContainer to={`/manage/schools/add`}>
                        <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add</span></Button>
                    </LinkContainer>
                </div>
            </PrivateContainer>

            <div className="search-bar-container">
                <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
            </div>

            <div className="table-responsive">
                <Table striped hover className="table-sm">
                    <thead>
                        <tr>
                            <th><span className="d-inline d-sm-none">School</span></th>
                            <th><span className="d-none d-sm-inline">Name</span></th>
                            <th><span className="d-none d-sm-inline">DfE URN</span></th>
                            <th><span className="d-none d-sm-inline">School type</span></th>
                            <th><span className="d-none d-sm-inline">School phases</span></th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredItems && !filteredItems.length ? (
                                <tr className="nothing-here">
                                    <td colSpan={6}>
                                        {
                                            props.search ? (
                                                <div>
                                                    <FontAwesomeIcon icon="search-minus" />
                                                    Sorry there are no schools matching "{props.search}".
                                                </div>
                                            ) : (
                                                    <div>
                                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                        You haven't added any schools yet.<br />
                                                        You can <Link to={`/manage/schools/add`}>add one</Link> to get started.
                                                </div>
                                                )
                                        }
                                    </td>
                                </tr>
                            ) : null
                        }

                        <InfiniteScrollingTable key={props.search} loadData={loadPage} nextPage={props.nextPage}>
                            {(filteredItems || []).map(item => (
                                <tr key={item.id} onDoubleClick={e => onRowDoubleClick(item.id, e)}>
                                    <td><div className="d-inline d-sm-none">
                                        <div>
                                            {item.name}
                                        </div>
                                        <div className="text-muted">
                                            {item.dfeReference}
                                        </div>
                                    </div></td>
                                    <td><span className="d-none d-sm-inline">{item.name}</span></td>
                                    <td><span className="d-none d-sm-inline">{item.dfeReference}</span></td>
                                    <td><span className="d-none d-sm-inline">{item.schoolTypesTags}</span></td>
                                    <td><span className="d-none d-sm-inline">{item.schoolPhasesTags}</span></td>
                                    <td className="actions">
                                        <ButtonGroup>
                                            <LinkContainer to={`/school/${encodeURIComponent(item.id)}`}>
                                                <Button color="primary" outline>Open</Button>
                                            </LinkContainer>
                                            <ButtonDropdown isOpen={menuIsOpen[item.id]} toggle={() => toggleMenuOpen(item.id)}>
                                                <DropdownToggle caret color="primary" outline />
                                                <DropdownMenu right>
                                                    <LinkContainer to={`/school/${encodeURIComponent(item.id)}/schoolDetails`}>
                                                        <DropdownItem className="text-primary">
                                                            <FontAwesomeIcon icon="edit" /> <span>Edit</span>
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                    <LinkContainer to={`/manage/schools/delete/${encodeURIComponent(item.id)}`}>
                                                        <DropdownItem className="text-danger">
                                                            <FontAwesomeIcon icon="trash-alt" /> <span>Delete</span>
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </InfiniteScrollingTable>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const CurrentList = withContainer(ListContainer)(CurrentListUi);
