import * as React from "react";
import { Repository } from "pojo-repository";
import { ContainerComponentProps } from "react-withcontainer";
import { BasicDeleteContainer } from "../basicContainers/BasicDeleteContainer";

export interface BasicRepositoryDeleteContainerProps extends ContainerComponentProps {
    repository: Repository<{id: string}>
}

/**
 * Basic implementation of a container containing the ability to load() from a repository.
 * 
 * You can use this if you don't need any special logic for your view container.
 * 
 * You cannot subclass it or try and extend it to do anything other than the basic handling, if you want to do any of that then
 * you should create your own DeleteContainer.
 * 
 * @param props
 */
export const BasicRepositoryDeleteContainer = (props: BasicRepositoryDeleteContainerProps) => {
    const { repository, ...rest } = props;

    // Wrap the find method of repository as our loadModel.
    const loadModel = React.useCallback(async (id: string): Promise<any> => {
        return await repository.find(id);
    }, [repository]);

    // Wrap the remove method of repository as our removeModel.
    const removeModel = React.useCallback(async (id: string): Promise<boolean> => {
        await repository.remove(id);
        return true;
    }, [repository]);

    return (
        <BasicDeleteContainer {...rest} loadModel={loadModel} removeModel={removeModel} />
    );
};
