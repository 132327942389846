import * as React from 'react';
import { useApiService } from '../../api/useApiService';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Button, Col, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Subscription } from '../../api/models/Subscription';
import { SubscriptionLicense } from '../../api/models/SubscriptionLicense';
import { useNavigateWhenReady } from '../shared/hooks/useNavigateWhenReady';
import { ProgressWheel } from '../schools/progressWheel/ProgressWheel';
import { ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { Subject } from '../../api/models/Subject';
import { useLoadProjectWithRetry } from './useLoadProjectWithRetry';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../utilities/replacePlaceholders';

export interface OverviewProps {

}

/**
 * Overview of a subject review.
 * @param props
 */
export const SubjectReviewOverview = (props: OverviewProps) => {
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');
    const subjectId = navigation.getParam('subjectId', '');

    const api = useApiService();
    const loadViewModel = api.subjects.viewModels.overview();
   
    // Load the main model data.
    //
    const [model, setModel] = React.useState<School | undefined>();
    const [subject, setSubject] = React.useState<Subject | undefined>();
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [license, setLicense] = React.useState<SubscriptionLicense | undefined>();
    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(id, subjectId); 

      
        setSubject(result.subject);
        setSubscription(result.subscription);
        setLicense(result.license);
        setModel(result.model);
        setPlaceholderReplacementMode(result.placeholderReplacementMode);
    }, [loadViewModel, setModel, id, setSubject, setSubscription, setLicense, subjectId, setPlaceholderReplacementMode]);
    React.useEffect(() => {
        if (!id || isLoading) {
            return;
        }

        if (model && model.id === id && subject && subject.id === subjectId) {
            return;
        }

        load();
    }, [id, load, model, isLoading, subject, subjectId]);

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const {
        project,
        sections,
        topics,
        isLoadingProject,
        loadProjectErrors,
    } = useLoadProjectWithRetry({
        schoolId: model && model.id || '',
        trustId: model && model.trustId || '',
        subjectId: subjectId,
        questionSetName: 'Subject leadership review',
        createIfMissing: true
    });

    // Also load the helpful questions project.
    const {
        project: helpfulQuestionsProject,
        sections: helpfulQuestionsSections,
        topics: helpfulQuestionsTopics,
        isLoadingProject: isLoadingHelpfulQuestionsProject,
        loadProjectErrors: loadHelpfulQuestionsProjectErrors,
    } = useLoadProjectWithRetry({
        schoolId: model && model.id || '',
        trustId: model && model.trustId || '',
        subjectId: subjectId,
        questionSetName: 'Subject helpful questions',
        createIfMissing: true
    });

    const [navigateWhenReady, { isNavigationPending: navigateIsPending }] = useNavigateWhenReady(
        !!model && !!project,
        {
            'review': `/school/${model && model.id || ''}/subjectLeadershipReviewDashboard/${project && project.id || ''}`,
            'report': `/school/${model && model.id || ''}/reports/subjectLeadershipReview/${subject && subject.id || ''}/subjectLeadershipReview/${project && project.id || ''}`,

            'reviewHelpfulQuestions': `/school/${model && model.id || ''}/subjectHelpfulQuestionsDashboard/${helpfulQuestionsProject && helpfulQuestionsProject.id || ''}`,
            'reportHelpfulQuestions': `/school/${model && model.id || ''}/reports/subjectHelpfulQuestions/${subject && subject.id || ''}/subjectHelpfulQuestions/${helpfulQuestionsProject && helpfulQuestionsProject.id || ''}`,

            'evidenceLocker': `/school/${model && model.id || ''}/evidenceLocker/${subjectId}`,
        }
    );

    if (!model) {
        return (<LoadingIndicator />);
    }

    // If we are for trial, don't show anything as subject reviews are not part of trials.
    if (license && license.isTrial) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>{subject && subject.name || ''}</h1>
                </div>

                <Alert color="warning">
                    <FontAwesomeIcon icon="lock" />
                    <> </>
                    <strong>
                        This area is locked during your trial.
                    </strong>
                    <p>
                        Specific reviews are only available as part of your full Evaluate-Ed subscription.
                    </p>
                </Alert>
            </div>
            );
    }

    return (
        <>
            <div className="main-container overview">
                <AlertOnErrors errors={[loadingErrors, loadProjectErrors, loadHelpfulQuestionsProjectErrors]} />

                <Row>
                    <Col xs={12} md="">
                        <div className="overview-logo">
                        </div>
                    </Col>
                    <Col xs={12} md="auto">
                        <div className="text-muted">
                            <h3>{subject && subject.name}</h3>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
                
                <Row>
                    {/* Show the progress wheel for subject leadership review */}
                    <Col xs={12} xl={6}>
                        <div className="overview-tier-heading" style={{ textAlign: 'center' }}>
                            <h3>
                                Subject leadership review
                            </h3>
                        </div>

                        <ProgressWheel
                            model={project || undefined}
                            sections={sections || []}
                            topics={topics || []}
                            currentTier={model.tier as ProductTier}
                            awardTagId={undefined}
                            isLoading={isLoading || isLoadingProject}
                            placeholderReplacementMode={placeholderReplacementMode}
                        />

                        <Row className="mt-4">
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('report')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('report') ? 'spinner' : 'passport'} spin={navigateIsPending('report')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                {subject && subject.name} subject leadership review report
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('review')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('review') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('review')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                {subject && subject.name}  subject leadership review dashboard
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('evidenceLocker')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('evidenceLocker') ? 'spinner' : 'cloud-upload-alt'} spin={navigateIsPending('evidenceLocker')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                {subject && subject.name} evidence locker
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                    {/* Show the progress wheel for helpful questions */}
                    <Col xs={12} xl={6}>
                        <div className="overview-tier-heading" style={{ textAlign: 'center' }}>
                            <h3>
                                Helpful questions
                            </h3>
                        </div>

                        <ProgressWheel
                            model={helpfulQuestionsProject || undefined}
                            sections={helpfulQuestionsSections || []}
                            topics={helpfulQuestionsTopics || []}
                            currentTier={model.tier as ProductTier}
                            awardTagId={undefined}
                            isLoading={isLoading || isLoadingHelpfulQuestionsProject}
                            placeholderReplacementMode={placeholderReplacementMode}
                        />

                        <Row className="mt-4">
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('reportHelpfulQuestions')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('report') ? 'spinner' : 'passport'} spin={navigateIsPending('reportHelpfulQuestions')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                {subject && subject.name} helpful questions report
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>

                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('reviewHelpfulQuestions')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('review') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('reviewHelpfulQuestions')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                {subject && subject.name}  helpful questions dashboard
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};
