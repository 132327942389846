import * as React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SubObjectivesProps {
    subObjectives: Array<string>,
    addObjective: () => void,
    updateSubObjective: (text: string, index: number) => void,
    deleteSubObjective: (index: number) => void,
}

export const SubObjectives = (props: SubObjectivesProps) => {

    const onChange = React.useCallback((text: string, index: number) => {
        props.updateSubObjective(text, index);
    }, [props.updateSubObjective])

    return <div>
        <div className="main-heading">
            <h1>Objectives</h1>
        </div>
        {
            props.subObjectives.map((item, index) => (
                <Row style={{marginBottom: 10}}>
                    <Col>
                        <textarea className="form-control" value={item} onChange={(ev) => onChange(ev.target.value, index)} />
                    </Col>
                    <Col xs="auto">
                        <Button onClick={() => props.deleteSubObjective(index)}>
                            <FontAwesomeIcon icon="trash-alt" />
                        </Button>
                    </Col>
                </Row>
            ))
        }

        <Row>
            <Col>
                <Button onClick={() => props.addObjective()}>
                    <FontAwesomeIcon icon="plus" />
                </Button>
            </Col>
        </Row>
    </div>
};