import * as React from 'react';
import { Form, FormGroup, Label, Alert, Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const onResendInvite = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        props.resendInvite();
    }, [props.resendInvite]);
    
    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add user' : 'Edit user'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            {
                props.inviteResent ? (
                    <Alert color="success">
                        Invite has been resent.
                    </Alert>
                ) : props.resendInviteErrors ? (
                    <Alert color="danger">
                        Could not resend invite: {props.resendInviteErrors}
                    </Alert>
                ) : null
            }

            <Form onSubmit={onSubmit}>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="forename">Forename</Label>
                            <ValidatedInput type="text" name="forename" placeholder="Forename" value={props.model.forename} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="surname">Surname</Label>
                            <ValidatedInput type="text" name="surname" placeholder="Surname" value={props.model.surname} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label htmlFor="name">Email</Label>
                    <Row>
                        <Col>
                            <ValidatedInput /*readOnly={props.inviteSent}*/ type="email" name="email" placeholder="Email" value={props.model.email} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </Col>
                        {
                            props.inviteSent ? (
                                <Col xs="auto">
                                    <ButtonAsync color="primary" outline onClick={onResendInvite} isExecuting={props.resendingInvite}
                                        executingChildren={(<><FontAwesomeIcon icon="spinner" spin /> Sending...</>)}>
                                        <FontAwesomeIcon icon="envelope" /> Resend invite
                                    </ButtonAsync>
                                </Col>
                            ) : null
                        }
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="roleGroup">Security level</Label>
                    <ValidatedInput type="select" name="roleGroup" placeholder="Security" value={props.model.roleGroup} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors}>
                        <option value="">(Select a security level)</option>
                        {
                            props.roleGroups.map((item, index) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </ValidatedInput>
                </FormGroup>

                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
