import * as React from 'react';
import { Text, View, Link, Image } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { IImageEnumerator } from './ImageEnumerator';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

/**
 * SIP Recommendations section of the executive summary (pdf format).
 */

export interface ReccomendationsPdfUiProps extends ExecutiveSummaryPdfProps {
    imageEnumerator: IImageEnumerator
}

export const ReccomendationsPdfUi = (props: ReccomendationsPdfUiProps) => {
    if (!props.model) {
        return (<></>);
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View break>
            <Text style={Styles.h3}>Possible areas for development</Text>
            <Image src={props.imageEnumerator.getNextImage()} style={{ marginBottom: 16 }} />

            <View style={Styles.table}>
                <View style={Styles.thead}>
                    <View style={Styles.trHead}>
                        <View style={{ ...Styles.th, width: '8%' }}></View>
                        <View style={{ ...Styles.th, width: '46%' }}><Text>Question</Text></View>
                        <View style={{ ...Styles.th, width: '46%' }}><Text>Suggestion</Text></View>
                    </View>
                </View>
                <View style={Styles.tbody}>
                    {
                        props.risks.map(riskItem => {
                            let question = props.questions.find(it => it.id === riskItem.questionId);
                            let topic = props.allTopics.find(it => it.id === riskItem.topicId);
                            let sectionId = '';
                            if (topic) {
                                sectionId = topic.sectionId;
                            }

                            let section = props.allSections.find(it => it.id == sectionId);

                            let questionaireId = '';
                            //let impactId = '';
                            if (question) {
                                questionaireId = question.questionaireId;
                                //impactId = question.impactId;
                            }

                            let questionaire = props.questionaires.find(it => it.id === questionaireId);

                            return (
                                <View key={riskItem.id} style={Styles.tr}>
                                    <View style={{ ...Styles.th, width: '8%' }}><Text>{question && replacePlaceholders(question.name, placeholderReplacementMode) ? question.questionNumber : '' }</Text></View>
                                    <View style={{ ...Styles.th, width: '46%' }}>
                                        <View style={{ paddingBottom: 2 }}>
                                            <Text style={Styles.strong}>
                                                {section && replacePlaceholders(section.name, placeholderReplacementMode)} / {topic && replacePlaceholders(topic.name, placeholderReplacementMode)} {questionaire ? (<Text style={Styles.textMuted}>(from the {replacePlaceholders(questionaire.name, placeholderReplacementMode)} questionnaire)</Text>) : null}
                                            </Text>
                                        </View>
                                        <View style={Styles.p}>
                                            <Text>{question && replacePlaceholders(question.name, placeholderReplacementMode) || 'Whole topic'}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...Styles.th, width: '46%' }}>
                                        <PlainTextWithBrsPdf text={replacePlaceholders(riskItem.description, placeholderReplacementMode)} />
                                    </View>
                                </View>
                            );
                        })}
                </View>
            </View>
        </View>
    );
};

