import * as React from 'react';
import { Card, CardHeader, CardBody, Input, Row, Col } from 'reactstrap';
import { withContainer } from 'react-withcontainer';
import { RecommendationReportUiProps, RecommendationReportContainer } from './RecommendationReportContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { RecommendationUi } from './RecommendationUi';
import { Link } from 'react-router-dom';
import { useProjectSettings } from '../../../api/useProjectSettings';
import { Action } from '../../../api/models/Action';
import moment, { Moment } from 'moment';
import { actionStates } from '../../../services/actionStates/actionStates';
import { AddToImprovementPlannerModal } from '../../actions/AddToImprovementPlannerModal';
import { RecommendationsBarChartCard} from './RecommendationsBarChartCard';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Project } from '../../../api/models/Project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WatchVideoModal } from '../../videos/WatchVideoModal';
import { isProductTierUnlocked, ProductTier } from '../../../api/models/codeOnly/ProductTeir';
import { ProductTierNotUnlockedAlert } from '../../shared/productTierNotUnlocked/ProductTierNotUnlockedAlert';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';

export const RecommendationReportUi = (props: RecommendationReportUiProps) => {
    const navigation = useUniversalNavigation(props);
    const awardTagId = navigation.getParam('awardTagId', '');

    const projectSettings = useProjectSettings(props.model && props.model.id || '', awardTagId);

    const scrollToRiskId = navigation.getParam('recommendationId', ''); // note - this could also be a section id
    const scrollToRiskIdRef = React.useRef<HTMLDivElement>(null);

    // Use an effect to scroll to the top as we navigate between question screens.  As we are not changing component
    // the normal react process that scrolls to the top won't get triggered so we have to handle it ourselves when
    // we navigate between topics.
    // We also use this process to scroll to specific questions if questionId= is supplied as a parameter.
    const [lastScrolledToTopForId, setLastScrolledToTopForId] = React.useState<string>('');
    React.useEffect(() => {
        if (props.model && `${props.model.id}.${scrollToRiskId || ''}` !== lastScrolledToTopForId) {
            if (scrollToRiskIdRef.current) {
                window.scrollTo(0, (scrollToRiskIdRef.current.offsetTop + (scrollToRiskIdRef.current.offsetParent && (scrollToRiskIdRef.current.offsetParent as any).offsetTop ? (scrollToRiskIdRef.current.offsetParent as any).offsetTop : 0)) - 60 /* NOTE have to add this for padding of the top bar, but would prefer to handle another way. */)
            } else {
                window.scrollTo(0, 0);
            }
            setLastScrolledToTopForId(`${props.model.id}.${scrollToRiskId || ''}`);
        }
    }, [props.model, props.isLoading, props.loadingErrors, lastScrolledToTopForId, setLastScrolledToTopForId, scrollToRiskId, scrollToRiskIdRef.current]);

    // use the modal to add this to the improvement planner
    const [addToImprovementPlannerModalCurrentModelId, setAddToImprovementPlannerModalCurrentModelId] = React.useState<string | undefined>(undefined);

    const openAddToImprovementPlannerModal = React.useCallback(async (initialize: Partial<{ projectId?: string, questionId?: string, riskId?: string, riskDescription: string }>): Promise<void> => {
        if (!props.model) {
            return;
        }

        let project: Project | undefined = props.model;
        if (initialize.projectId && initialize.projectId != project.id) {
            project = props.otherProjects.find(it => it.id === initialize.projectId);
        }

        // Add an action ready to be edited.
        let newAction = await props.actions.addModel({
            trustId: project && project.trustId || '',
            schoolId: project && project.schoolId || '',
            name: initialize.riskDescription,
            description: '',
            archived: false,
            isAutomaticFromProject: false,
            actionAddedDate: moment().add(1, 'month').toISOString(),
            actionState: actionStates.outstanding.id,
            completedDate: null,
            notes: '',

            ...initialize
        });

        // Set the current modal for the modal (which will cause the modal to display).
        setAddToImprovementPlannerModalCurrentModelId(newAction.id);
    }, [props.model, setAddToImprovementPlannerModalCurrentModelId]);

    // Save the new action we are trying to add to the improvement planner.
    const saveNewAction = React.useCallback(async (id: string, actionModel: Action): Promise<boolean> => {
        let ok = await props.actions.save();
        if (!ok) {
            return false;
        }

        // Clear the current model for the modal (which will cause the modal to close).
        setAddToImprovementPlannerModalCurrentModelId(undefined);

        return true;
    }, [props.actions, setAddToImprovementPlannerModalCurrentModelId]);

    // Cancel the last action we were trying to add to the improvement planner.
    const cancelNewAction = React.useCallback((id: string, actionModel: Action): void => {
        props.actions.removeModel(id);

        // Clear the current model for the modal (which will cause the modal to close).
        setAddToImprovementPlannerModalCurrentModelId(undefined);
    }, [props.actions, setAddToImprovementPlannerModalCurrentModelId]);

    const [filterBySchoolId, setFilterBySchoolId] = React.useState<string>('');
    const filteredRisks = React.useMemo(() => {
        let ret = props.risks.models;

        if (filterBySchoolId) {
            ret = ret.filter(item => {
                if (!props.model) {
                    return true;
                }

                let project: Project | undefined = props.model;
                if (item.projectId !== project.id) {
                    project = props.otherProjects.find(it => it.id === item.projectId);
                }

                if (!project) {
                    return true;
                }

                if (project.schoolId === filterBySchoolId) {
                    return true;
                }

                return false;
            });
        }

        return ret;
    }, [props.risks, props.otherProjects, props.model, filterBySchoolId]);

    // Viewing of micro consultation videos.
    const [activeVideoId, setActiveVideoId] = React.useState<string | undefined>();
    const watchVideo = React.useCallback((videoId: string) => {
        setActiveVideoId(videoId);
    }, [setActiveVideoId]);
    const closeVideo = React.useCallback(() => setActiveVideoId(undefined), [setActiveVideoId]);

    // If we are for a particular school, get that school now so its easy to find.
    const school = React.useMemo(() => {
        if (!props.model) {
            return null;
        }

        if (!props.model.schoolId) {
            return null;
        }

        const schoolId = props.model.schoolId;
        const ret = props.schools.find(it => it.id === schoolId);
        return ret;
    }, [props.model, props.schools]);

    // render UI
    //

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.removingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    let recommendationText = props.risks.models.length === 1 ? 'suggestion' : 'suggestions';

    // If this is a school's screen and the school has not reached the right tier, hide the functionality.
    if (school && !isProductTierUnlocked(ProductTier.Snapshot, school.tier as ProductTier)) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>
                        Possible areas for development <small className="text-muted">({props.risks.models.length} {recommendationText})</small>
                    </h1>
                </div>

                <ProductTierNotUnlockedAlert tier={ProductTier.Snapshot} />
            </div>
        );
    }

    const isShowingMultipleSchools = !!props.otherProjects.length;

    const isTrust = !props.model.schoolId ? true : false;


    return (
        <>
            <div className="main-container">
                <div className="main-heading">
                    <h1>
                        Possible areas for development <small className="text-muted">({props.risks.models.length} {recommendationText})</small>
                    </h1>
                </div>
                <ConditionalFragment showIf={isShowingMultipleSchools}>
                    <div className="toolbar-top">
                        <Input className="mb-1" type="select" value={filterBySchoolId} onChange={e => setFilterBySchoolId(e.currentTarget.value)}>
                            <option value="">(All schools within {props.trust && props.trust.name || 'trust'})</option>
                            {
                                props.schools.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Input>
                    </div>
                </ConditionalFragment>

                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.removingErrors]} />

                <RecommendationsBarChartCard
                    model={props.model}
                    risks={filteredRisks}
                    questions={props.questions}
                    topics={props.topics}
                    sections={props.sections}
                    projects={[props.model, ...props.otherProjects]}
                    schools={props.schools}
                    filterBySectionOriginKey={undefined}
                    filterByTopicOriginKey={undefined}
                    awardTagId={awardTagId}
                />

                {
                    props.sections.map(sectionItem => {
                        return (
                            <div key={sectionItem.id} ref={scrollToRiskId === sectionItem.originKey ? scrollToRiskIdRef : null} className={scrollToRiskId === sectionItem.originKey ? 'auto-scroll-selected' : ''}> {/* this added to allow scrolling to section from bar chart */}
                                {
                                    props.topics.filter(topicItem => topicItem.sectionId === sectionItem.id).map(item => {
                                        let myRisks = filteredRisks.filter(it => it.topicId === item.id);

                                        // If we've removed all the risks in this section then don't show the section.
                                        if (!myRisks.length) {
                                            return null;
                                        }

                                        let prioritySection = props.projectSections.find(it => it.sectionId === item.sectionId && it.improvementTarget > 0);
                                        let isPrioritySection = !!prioritySection ? true : false;
                                        let projectId = '';
                                        if (props.model) {
                                            projectId = props.model.id;
                                        }
                                        let myProjectTopic = props.projectTopics.find(it => it.projectId === projectId && it.topicId === item.id);
                                        let myProjectRecommendationText = myRisks.length === 1 ? 'suggestion' : 'suggestions';
                                        let placeholderReplacementMode = props.placeholderReplacementMode;
                                        return (
                                            <Card key={item.id} className={isPrioritySection ? 'card-highlighted' : ''}>
                                                <CardHeader>
                                                    <Row>
                                                        <Col>
                                                            {/* only link the title if this is a school - there won't be a project to link to for the trust */}
                                                            <ConditionalFragment showIf={isTrust}>
                                                                {replacePlaceholders(sectionItem.name, placeholderReplacementMode) || ''} / {replacePlaceholders(item.name, placeholderReplacementMode)} <small className="text-muted">({myRisks.length} {myProjectRecommendationText})</small>
                                                            </ConditionalFragment>
                                                            <ConditionalFragment showIf={!isTrust}>
                                                                <Link to={`${projectSettings.baseRoute}/topic/${myProjectTopic && myProjectTopic.id || ''}`}>
                                                                    {replacePlaceholders(sectionItem.name, placeholderReplacementMode) || ''} / {replacePlaceholders(item.name, placeholderReplacementMode)} <small className="text-muted">({myRisks.length} {myProjectRecommendationText})</small>
                                                                </Link>
                                                            </ConditionalFragment>
                                                        </Col>
                                                        <ConditionalFragment showIf={isPrioritySection}>
                                                            <Col xs={12} md="auto">
                                                                <small className="text-secondary">
                                                                    <FontAwesomeIcon icon="key" />
                                                                    <> </>
                                                            This section is a priority for improvement
                                                        </small>
                                                            </Col>
                                                        </ConditionalFragment>
                                                    </Row>
                                                </CardHeader>

                                                <CardBody>
                                                    {
                                                        myRisks.map(riskItem => {
                                                            let question = props.questions.find(it => it.id === riskItem.questionId);
                                                            let topic = props.topics.find(it => it.id === riskItem.topicId);
                                                            let sectionId = '';
                                                            let topicId = '';
                                                            if (topic) {
                                                                sectionId = topic.sectionId;
                                                                topicId = topic.id;
                                                            }
                                                            let questionaireId = '';
                                                            let impactId = '';
                                                            if (question) {
                                                                questionaireId = question.questionaireId;
                                                                impactId = question.impactId;
                                                            }

                                                            let sectionPermission = props.sectionPermissions.find(item => item.id === sectionId);
                                                            let topicPermission = props.topicPermissions.find(item => item.id === topicId);
                                                            let questionPermission = props.questionPermissions.find(item => item.id == riskItem.questionId);
                                                            let hasPermission = (sectionPermission && sectionPermission.hasPermission) && (topicPermission && topicPermission.hasPermission) && (!question || (questionPermission && questionPermission.hasPermission)) ? true : false;

                                                            let action = props.actions.models ? props.actions.models.find(it => it.riskId === riskItem.id) : undefined;
                                                            let openActionUrlEnd = !!props.model && !!props.model.schoolId ? 'schoolImprovementPlanner' : 'improvementPlanner';

                                                            let project = props.model;
                                                            if (project && project.id !== riskItem.projectId) {
                                                                project = props.otherProjects.find(it => it.id === riskItem.projectId);
                                                            }

                                                            const myVideo = props.videos.find(it => !!question && it.id === question.videoId);
                                                            const myConsultant = props.consultants.find(it => !!myVideo && it.id === myVideo.consultantId);
                                                            const myVideoThumbnailBlob = props.videoThumbnailBlobs.find(it => !!myVideo && it.id === myVideo.thumbnailBlobId);
                                                            const myConsultantPhotoBlob = props.consultantPhotoBlobs.find(it => !!myConsultant && it.id === myConsultant.photoBlobId);

                                                            return (
                                                                <div key={riskItem.id} ref={scrollToRiskId === riskItem.id ? scrollToRiskIdRef : null} className={scrollToRiskId === riskItem.id ? 'auto-scroll-selected' : ''}> {/* we use this div with a ref to help us scroll to the question when we're directing to a single risk when following links from the "View on risk register" buttons. */}
                                                                    <RecommendationUi
                                                                        model={riskItem}
                                                                        changeModel={(changes) => props.risks.change(riskItem.id, changes)}
                                                                        validate={(fieldsToCheck) => props.risks.validateModel(riskItem.id, fieldsToCheck)}
                                                                        validationErrors={props.risks.validationErrorsFor(riskItem.id)}
                                                                        save={props.save} isSaving={props.isSaving}
                                                                        remove={() => props.remove(riskItem.id)}
                                                                        question={question}
                                                                        topic={topic}
                                                                        projectTopic={props.projectTopics.find(it => it.topicId === riskItem.topicId && it.projectId === riskItem.projectId)}
                                                                        section={props.sections.find(it => it.id === sectionId)}
                                                                        projectSection={props.projectSections.find(it => it.sectionId === sectionId && it.projectId === riskItem.projectId)}
                                                                        questionaire={props.questionaires.find(it => it.id === questionaireId)}
                                                                        projectQuestionaire={props.projectQuestionaires.find(it => it.questionaireId === questionaireId && it.projectId === riskItem.projectId)}
                                                                        impact={props.impacts.find(it => it.id === impactId)}
                                                                        questionResponses={props.questionResponses.filter(it => it.questionId === riskItem.questionId && it.projectId === riskItem.projectId)}
                                                                        hasPermission={hasPermission}
                                                                        IsOnImprovementPlan={action ? true : false}
                                                                        openAddToImprovementPlannerModal={() => { openAddToImprovementPlannerModal({ projectId: riskItem.projectId, questionId: riskItem.questionId, riskId: riskItem.id, riskDescription: riskItem.description }); }}
                                                                        openOnImprovementPlanner={() => navigation.navigate(`/school/${project && project.schoolId || ''}/improvementPlanner?actionId=${action && action.id || ''}`)}
                                                                        showSchoolTag={isShowingMultipleSchools}
                                                                        school={props.schools.find(it => project != null && it.id === project.schoolId)}
                                                                        awardTagId={awardTagId}
                                                                        video={myVideo} videoThumbnailBlob={myVideoThumbnailBlob}
                                                                        consultant={myConsultant} consultantThumbnailBlob={myConsultantPhotoBlob}
                                                                        watchVideo={() => watchVideo(myVideo && myVideo.id || '')}
                                                                        placeholderReplacementMode={props.placeholderReplacementMode}
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </CardBody>
                                            </Card>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                )}
            </div>
            {
                // If we are to show the AddToImprovementPlannerModal do so here.
                addToImprovementPlannerModalCurrentModelId ? (
                    <AddToImprovementPlannerModal
                        model={props.actions.models.find(it => it.id === addToImprovementPlannerModalCurrentModelId) as Action} changeModel={(changes) => props.actions.change(addToImprovementPlannerModalCurrentModelId, changes)} save={saveNewAction} cancel={cancelNewAction} />
                ) : null
            }

            <ConditionalFragment showIf={!!activeVideoId}>
                <WatchVideoModal
                    id={activeVideoId || ''}
                    toggle={() => closeVideo()}
                    baseUrl={`${projectSettings.baseRoute}/microConsultations`}
                />
            </ConditionalFragment>
        </>
    );
};

export const RecommendationReport = withContainer(RecommendationReportContainer)(RecommendationReportUi);
