import * as React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { SchoolTagCertificate } from '../../../api/models/SchoolTagCertificate';
import { School } from '../../../api/models/School';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AwardTag } from '../../../api/models/AwardTag';

export interface CertificatePdfProps {
    model: SchoolTagCertificate,
    school: School,
    awardTag: AwardTag,
}

/**
 * Executive summary screen for a project, this gives a long report-like breakdown of the project results.
 */
export const CertificatePdf = (props: CertificatePdfProps) => {
    const {
        model,
        school,
        awardTag,
    } = props;

    const baseUrl = `https://${window.location.hostname}${window.location.port && window.location.port !== '443' ? ':' + window.location.port : ''}`;
    
    if (!props.model) {
        return (
            <Document>
                <Page size="A4" orientation="landscape">
                    <Text>PDF could not be generated.</Text>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page size="A4" orientation="landscape" style={Styles.page}>
                <View fixed style={Styles.header}>
                    <Image src={`${baseUrl}/img/defaultCertificate/CertificateHeader.png`} />
                </View>

                <View style={Styles.body}>
                    <View style={Styles.main}>
                        <View style={Styles.schoolName}>
                            <Text>
                                {school.name}
                            </Text>
                        </View>
                        <View style={Styles.hasAcheived}>
                            <Text>
                                Has successfully achieved the following certification
                            </Text>
                        </View>
                        <View style={Styles.certificateName}>
                            <Text>
                                {model.awardName}
                            </Text>
                        </View>

                        
                    </View>
                </View>

                <View fixed style={Styles.detailsContainer}>
                    <View fixed style={Styles.details}>
                        <View style={Styles.certificateNumber}>
                            <Text>
                                <ConditionalFragment showIf={!!model && !!model.certificateNumber}>
                                    Certificate number: {model && model.certificateNumber || ''}
                                </ConditionalFragment>
                            </Text>
                        </View>
                        <View style={Styles.awardedBy}>
                            <Text>
                                <ConditionalFragment showIf={!!model.awardingOrganisationName}>
                                    Awarded by: {model.awardingOrganisationName}
                                </ConditionalFragment>
                            </Text>
                        </View>
                    </View>
                </View>
                <View fixed style={Styles.footer}>
                    <Image src={`${baseUrl}/img/defaultCertificate/CertificateFooter.png`} />
                </View>
            </Page>
        </Document>
    );
};

