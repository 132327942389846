import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

/**
 * Conclusion section of the executive summary (pdf format).
 */
export const ConclusionPdfUi = (props: ExecutiveSummaryPdfProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <View>
            <Text style={Styles.h3}>Conclusion</Text>
            <Text style={Styles.p}>
                <PlainTextWithBrsPdf text={replacePlaceholders(props.model.overviewOpinion, props.placeholderReplacementMode)} />
            </Text>
        </View>
    );
};

