import { withServiceProps } from "inject-typesafe-react";
import { BasicRepositoryDeleteContainerProps, BasicRepositoryDeleteContainer } from "../../containers/common/basicRepositoryContainers/BasicRepositoryDeleteContainer";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { User } from "../../../api/models/User";
import { DeleteUiPropsBase } from "../../containers/common/DeleteUiPropsBase";

export interface DeleteUiProps extends DeleteUiPropsBase<User> {
}

export const DeleteContainer = withServiceProps<BasicRepositoryDeleteContainerProps, AppServicesCore>(services => ({
    repository: services.api.users.repository()
}))(BasicRepositoryDeleteContainer);