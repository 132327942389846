import * as React from 'react';
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { Page } from "../../../api/models/Page";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";

export interface PageContainerProps extends ContainerComponentProps<PageUiProps> {
    /* Passed in by caller */
    name: string,

    /* From dependency injection */
    loadViewModel: (name: string) => Promise<any>,
}

export interface PageUiProps {
    model: Page | undefined,

    isLoading: boolean,
    loadingErrors: any,
}

export const _PageContainer = (props: PageContainerProps) => {
    const { name, loadViewModel, component, ...rest } = props;

    const [model, setModel] = React.useState<Page | undefined>(undefined);


    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadViewModel(name);
        setModel(result.model);
        return true;
    }, [loadViewModel, setModel, name]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (name && name !== model.name)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            model={model}
            isLoading={isLoading} loadingErrors={loadingErrors}
        />
    );
};

export const PageContainer = withServiceProps<PageContainerProps, AppServicesCore>(services => ({
    loadViewModel: services.api.pages.viewModels.page(),
}))(_PageContainer);