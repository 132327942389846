import * as React from 'react';
import { useUniversalNavigation } from 'react-universal-navigation';
import { EvidenceLockerCore } from './EvidenceLockerCore';
import { useApiService } from '../../api/useApiService';
import { School } from '../../api/models/School';
import { useAsyncCallback } from 'react-use-async-callback';
import { isProductTierUnlocked, ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { ProductTierNotUnlockedAlert } from '../shared/productTierNotUnlocked/ProductTierNotUnlockedAlert';

export interface EvidenceLockerProps {
}

/**
 * Evidence locker for a school.
 * @param props
 */
export const EvidenceLocker = (props: EvidenceLockerProps) => {
    const navigation = useUniversalNavigation(props);
    const schoolId = navigation.getParam('schoolId', '');
    const subjectId = navigation.getParam('subjectId', '');

    const api = useApiService();
    const schoolRepository = api.schools.repository();

    const [subjectName, setSubjectName] = React.useState<string>('');

    const [model, setModel] = React.useState<School | undefined>();

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        const result = await schoolRepository.find(schoolId);
        setModel(result);
        return true;
    }, [schoolRepository, schoolId]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (schoolId && schoolId !== model.id)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load, schoolId, subjectName, subjectId, setSubjectName]);

    // If this is a school's screen and the school has not reached the right tier, hide the functionality.
    if (model && !model.isPotentialSchool && !isProductTierUnlocked(ProductTier.Snapshot, model.tier as ProductTier)) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>{subjectName} Evidence locker</h1>
                </div>

                <ProductTierNotUnlockedAlert tier={ProductTier.Snapshot} />
            </div>
        );
    }
    const placeholderReplacementMode = 'general';
    return (
        <div className="main-container">
            <div className="main-heading">
                <h1>{subjectName} Evidence locker</h1>
            </div>

            <div className="toolbar-top">
            </div>

            <div className="mt-2">
                <EvidenceLockerCore
                    schoolId={schoolId}
                    placeholderReplacementMode={placeholderReplacementMode}
                    subjectId={subjectId}
                    subjectName={subjectName}
                    setSubjectName={(value: string) => setSubjectName(value)} isModal={false}
                    onUploadComplete={() => load()}
                />
            </div>
        </div>
    );
};
