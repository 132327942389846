import * as React from 'react';
import moment, { Moment } from 'moment';
import { Badge } from 'reactstrap';

export interface DueDateLabelProps {
    date: string | Moment | Date,
}

/**
 * Component that shows a Due Date with appropriate colouring.
 * @param props
 */
export const DueDateLabel = (props: DueDateLabelProps) => {
    const { date } = props;
    const dateMoment: Moment = moment(date);
    const differenceDays = dateMoment.diff(moment(), 'days');

    let color = 'dark';
    if (differenceDays <= 0) {
        color = 'danger';
    } else if (differenceDays <= 30) {
        color = 'warning';
    }

    return (
        <Badge color={color}>
            Due: {dateMoment.format('DD/MM/YYYY')}
        </Badge>
    );
};
