
/**
 * Returns all the choices for operational levels.
 */
export function getOperationalLevels(): Array<OperationalLevel> {
    const ret = [
        { name: '0 - Not applicable', text: '',  value: 0 },
        { name: '1 - Outstanding', text: 'Outstanding', value: 1 },
        { name: '2 - Good', text: 'Good',  value: 2 },
        { name: '3 - Requires improvement', text: 'Requires improvement', value: 3 },
        { name: '4 - Inadequate', text: 'Inadequate', value: 4 },
    ];
    return ret;
}

/**
 * Type of each result returned by getOperationalLevels.
 */
export interface OperationalLevel {
    name: string,
    text: string,
    value: number
}