import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Simple spinner that can be shown during loading.
 * @param props
 */
export const LoadingIndicator = (props: any) => {
    return (
        <div className={'loading-indicator ' + (props.className ? props.className : '')}>
            <FontAwesomeIcon icon="spinner" spin />
            <span className="sr-only">Loading</span>
        </div>
    );
}