import * as React from 'react';
import { Table, Input } from 'reactstrap';
import { isNullOrUndefined } from 'util';
import { Link } from 'react-router-dom';
import { Project } from '../../../api/models/Project';
import { ManagedChildModels } from '../../shared/hooks/useManagedChildModels';
import { ProjectSection } from '../../../api/models/ProjectSection';
import { Section } from '../../../api/models/Section';
import { Topic } from '../../../api/models/Topic';
import { ProjectTopic } from '../../../api/models/ProjectTopic';
import { ProjectAnalysisResult } from '../../../api/models/ProjectAnalaysisResult';
import { ProjectSettings } from '../../../utilities/getProjectSettingsFromQuestionSetName';
import { getGradeString } from '../../../utilities/getGradeString';
import { getGradeComparisonColor } from '../../../utilities/getGradeComparisonColor';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface SchoolTargetComparisonTableProps {
    model: Project,
    projectSections: ManagedChildModels<ProjectSection>,
    projectTopics: ManagedChildModels<ProjectTopic>,
    sections: Array<Section>,
    topics: Array<Topic>,
    results: Array<ProjectAnalysisResult>,
    filterBySectionOriginKey: string,
    saveDebounce: () => void,
    projectSettings: ProjectSettings,
    isForSingleSchool: boolean,
}

export const SchoolTargetComparisonTable = (props: SchoolTargetComparisonTableProps) => {
    const { projectSettings } = props;

    let otherSchools = React.useMemo(() => {
        if (!props.results || props.results.length < 2) {
            return [];
        }

        return props.results.slice(2);
    }, [props.results]);

    if (!props.results || props.results.length < 2 || !props.model) {
        return (<></>);
    }

    const { isForSingleSchool } = props;

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.results[0];
    let trustAverages = props.results[1];
    let thisschool = props.results[2];

    return (
        <>
            <div className="table-responsive">
                <Table striped className="table-sm">
                    <thead>
                        <tr>
                            <th><span></span></th>
                            <ConditionalFragment showIf={isForSingleSchool}>
                               <th><span>School targets</span></th>
                                <th className="text-right"><span>{thisschool && thisschool.school && thisschool.school.name || ''}</span></th>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!isForSingleSchool}>
                                <th><span>Trust targets</span></th>
                                <th className="text-right"><span>School averages</span></th>
                                {
                                    otherSchools.map((item, index) => (
                                        <th key={index} className="text-right"><span>
                                            {
                                                props.model && props.model.schoolId ? (
                                                    <>
                                                        {item.school ? item.school.name : item.trust ? item.trust.name : 'Project'}
                                                    </>
                                                ): (
                                                        <Link to = {`${projectSettings.otherProjectBaseRoute(item.project && item.project.id || '', item.school && item.school.id || '')}/dashboard`}>
                                                            {item.school ? item.school.name : item.trust ? item.trust.name : 'Project'}
                                                        </Link>
                                                    )
                                            }
                                        </span></th>
                                        ))
                                    }
                            </ConditionalFragment>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            thisProject.results.map(item => {
                                let matchingRecord = trustAverages.results.find(it => it.originKey === item.originKey);
                                let trustScore = matchingRecord ? matchingRecord.percentageQuestionScore : null;

                                const mySection = props.sections.find(it => it.originKey === item.originKey);
                                const myProjectSection = props.projectSections.models.find(it => mySection && it.sectionId === mySection.id);
                                const myTopic = props.sections.find(it => it.originKey === item.originKey);
                                const myProjectTopic = props.projectTopics.models.find(it => myTopic && it.topicId === myTopic.id);

                                let myTargetScore: number = parseInt(item.percentageQuestionScore.toFixed(0));
                                if (myProjectTopic) {
                                    myTargetScore = myProjectTopic.targetForSchoolsWithinTrust;
                                } else if (myProjectSection) {
                                    myTargetScore = myProjectSection.targetForSchoolsWithinTrust;
                                }

                                let averagesDifference = isNullOrUndefined(trustScore) ? null : 0 - (myTargetScore - trustScore);

                                // Filter out archived sections and topics (can occur when mid-term publishes are used to update
                                // the underlying question sets).
                                if ((mySection && mySection.archived) || (myTopic && myTopic.archived)) {
                                    return null;
                                }

                                return (
                                    <tr key={item.originKey}>
                                        <td><span>
                                            {item.name}
                                        </span></td>
                                        <td>
                                            <Input type="select" value={myTargetScore || 0}
                                                onChange={e => {
                                                    if (myProjectTopic) {
                                                        props.projectTopics.change(myProjectTopic.id, { targetForSchoolsWithinTrust: parseInt(e.currentTarget.value) });
                                                    } else if (myProjectSection) {
                                                        props.projectSections.change(myProjectSection.id, { targetForSchoolsWithinTrust: parseInt(e.currentTarget.value) });
                                                    }

                                                    props.saveDebounce();
                                                }}>
                                                <option value={0}>{getGradeString(0)}</option>
                                                <option value={10}>{getGradeString(10)}</option>
                                                <option value={20}>{getGradeString(20)}</option>
                                                <option value={30}>{getGradeString(30)}</option>
                                                <option value={40}>{getGradeString(40)}</option>
                                                <option value={50}>{getGradeString(50)}</option>
                                                <option value={60}>{getGradeString(60)}</option>
                                                <option value={70}>{getGradeString(70)}</option>
                                                <option value={80}>{getGradeString(80)}</option>
                                                <option value={90}>{getGradeString(90)}</option>
                                                <option value={100}>{getGradeString(100)}</option>
                                            </Input>
                                        </td>
                                        <td className="text-right">
                                            <strong className={isNullOrUndefined(averagesDifference) ? '' : `text-${getGradeComparisonColor(trustScore, myTargetScore)}`}>
                                                {isNullOrUndefined(trustScore) ? '' : getGradeString(trustScore)}
                                            </strong>
                                        </td>
                                        <ConditionalFragment showIf={!isForSingleSchool}>
                                            {
                                                otherSchools.map((otherSchoolProject, index) => {
                                                    let myRecord = otherSchoolProject.results.find(it => it.originKey === item.originKey);
                                                    let myScore = myRecord ? myRecord.percentageQuestionScore : null;
                                                    let difference = isNullOrUndefined(myTargetScore) || isNullOrUndefined(myScore) ? null : (myScore - myTargetScore);

                                                    return (
                                                        <td key={index} className="text-right">
                                                            <span className={isNullOrUndefined(difference) ? '' : myRecord && myRecord.questionScore === 0 ? '' : `text-${getGradeComparisonColor(myScore, myTargetScore)}`}>
                                                                {isNullOrUndefined(myScore) ? '' : myRecord && myRecord.questionScore === 0 ? 'N/A' : getGradeString(myScore)}
                                                            </span>
                                                        </td>
                                                    );
                                                })
                                            }
                                        </ConditionalFragment>

                                    </tr>
                                );
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

