import * as React from 'react';
import { FormGroup, Label,  Row, Col, Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, CardHeader, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Guid } from "guid-string";
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidatedElasticInput } from '../../../shared/ValidatedElasticInput';
import { Topic } from '../../../../api/models/Topic';
import { ValidationErrors } from 'pojo-validator';
import { QuestionUi } from './QuestionUi';
import { Question } from '../../../../api/models/Question';
import { ManagedChildModels } from '../../../../components/shared/hooks/useManagedChildModels';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';
import { Impact } from '../../../../api/models/Impact';
import { Tag } from '../../../../api/models/Tag';
import { QuestionTag } from '../../../../api/models/QuestionTag';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AwardTag } from '../../../../api/models/AwardTag';
import { Video } from '../../../../api/models/Video';
import { QuestionSetTypeSettings } from '../QuestionSetType';

export interface TopicUiProps {
    model: Topic,
    questionSetType: QuestionSetTypeSettings,
    isCreate: boolean,
    change: (changes: Partial<Topic>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,

    questions: ManagedChildModels<Question>,
    questionChoices: ManagedChildModels<QuestionChoice>,
    tags: Array<Tag>,
    questionTags: ManagedChildModels<QuestionTag>,
    impacts: Array<Impact>,
    awardTags: Array<AwardTag>,
    videos: Array<Video>,

    isPublished: boolean,
    subscriptionId: string | undefined,
    isReadOnly: boolean,
    changeTopicOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
    changeQuestionOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
}

export const TopicUi = (props: TopicUiProps) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(props.isCreate);
    const toggleOpen = React.useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, [setIsOpen]);

    const [addButtonDropdownIsOpen, setAddButtonDropdownIsOpen] = React.useState<boolean>(false);
    const toggleAddButtonDropdown = React.useCallback(() => {
        setAddButtonDropdownIsOpen(prevState => !prevState);
    }, [setAddButtonDropdownIsOpen]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.change({ [target.name]: (target.type === 'checkbox' ? target.checked : target.value) });
    }, [props.change]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    // Add a new question.
    const addQuestion = React.useCallback(() => {
        // Add the question itself.
        const newId = Guid.newGuid();
        props.questions.addModel({
            id: newId,
            originKey: newId,

            questionSetId: props.model.questionSetId,
            topicId: props.model.id,

            // Impact should be defaulted only if we're not displaying it.
            ...(
                !props.questionSetType.impactChoice && props.impacts.length ? {
                    impactId: props.impacts[0].id,
                } : {}
            )
        });

        // If this is a RAG question set, then add the Green Amber Red answers too.  We order the answers with
        // Green first for consistancy with preprop questions, where the best tends to be at the top too.
        if (props.questionSetType.ragAnswers) {
            props.questionChoices.addModel({ questionId: newId, name: 'Green: Confidence is strong', operationalLevel: 1, displayOrder: 1, });
            props.questionChoices.addModel({ questionId: newId, name: 'Amber: Developing confidence', operationalLevel: 2, displayOrder: 2, });
            props.questionChoices.addModel({ questionId: newId, name: 'Red: Confidence gaps', operationalLevel: 3, displayOrder: 3, });
        }

        // Adding a new question always auto select inclusion in the base layer.
        // This is both for convinence, and also because some question sets don't support selection of specific reviews.
        // It this becomes a problem for those that do support specific review we can limit it by props.questionSetType.specificReviews.
        props.questionTags.addModel({
            questionId: newId,
            tagId: Guid.empty,
        });
    }, [props.questions, props.questionSetType, props.questionChoices, props.model.id, props.model.questionSetId, props.questionTags, props.impacts]);

    // Clone the last question along with its answers.
    const cloneLastQuestion = React.useCallback(() => {
        // Get the last question in this section if we have any.
        let myQuestions = props.questions.models.filter(it => it.topicId === props.model.id);
        if (!myQuestions.length) {
            return;
        }
        let cloneFromQuestion = myQuestions[myQuestions.length - 1];

        // Make a clone of the last question by copying some fields we care about.
        let newId = Guid.newGuid();
        props.questions.addModel({
            ...cloneFromQuestion,

            id: newId,
            originKey: newId,
            displayOrder: cloneFromQuestion.displayOrder + 1,
        });

        // Clone all the answers from the last question too, again by copying fields we care about.
        let cloneFromAnswers = props.questionChoices.models.filter(it => it.questionId === cloneFromQuestion.id);
        for (let cloneFromAnswer of cloneFromAnswers) {
            const answerId = Guid.newGuid();
            props.questionChoices.addModel({
                ...cloneFromAnswer,

                id: answerId,
                originKey: answerId,
                questionId: newId,
            });
        }

        // Clone all the tags
        let cloneFromTags = props.questionTags.models.filter(it => it.questionId === cloneFromQuestion.id);
        for (let cloneFromTag of cloneFromTags) {
            props.questionTags.addModel({
                ...cloneFromTag,

                id: Guid.newGuid(),
                questionId: newId,
            });
        }
    }, [props.questions, props.model, props.questionChoices, props.questionTags]);



    let questions = React.useMemo(() => props.questions.models.filter(it => it.topicId == props.model.id && Guid.isEmpty(it.questionaireId)), [props.questions.models]);

    const schoolEvidenceNames = React.useMemo(() => {
        let ret: Array<string> = [];

        // Get unique values.
        for (const item of props.questions.models) {
            if (!item.schoolEvidenceName) {
                continue;
            }

            const existing = ret.find(it => it === item.schoolEvidenceName);
            if (existing) {
                continue;
            }

            ret.push(item.schoolEvidenceName)
        }

        // Sort alphabetically.
        ret.sort();

        return ret;
    }, [props.questions]);

    return (
        <Card>
            <CardHeader onClick={toggleOpen} style={{ cursor: 'pointer' }}>
                <Row>
                    <ConditionalFragment showIf={!props.isReadOnly}>
                        <Col xs="auto">
                            <Button size="sm" color="primary" outline onClick={(e) => { e.preventDefault(); setIsOpen(false); props.changeTopicOrder('up', props.model.id, props.model.sectionId); e.stopPropagation(); }}>
                                <FontAwesomeIcon icon='caret-up' />
                            </Button>
                            <Button size="sm" className="ml-1" color="primary" outline onClick={(e) => { e.preventDefault(); setIsOpen(false); props.changeTopicOrder('down', props.model.id, props.model.sectionId); e.stopPropagation(); }}>
                                <FontAwesomeIcon icon='caret-down' />
                            </Button>
                        </Col>
                    </ConditionalFragment>

                    <Col>
                        {props.model.name? props.model.name: '(New topic)'}
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen? 'caret-up': 'caret-down'} />
                    </Col>
                </Row>
            </CardHeader>

            <Collapse isOpen={isOpen}>
                {
                    isOpen ? (
                        <CardBody>
                            <FormGroup>
                                <Label htmlFor="name">Topic name</Label>
                                <Row>
                                    <Col>
                                        <ValidatedInput type="text" readOnly={props.isReadOnly} name="name" autoComplete="off" placeholder="Topic name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                    </Col>
                                    {
                                        !props.isPublished /* Can't remove topics from a published question set. */ ? (
                                            <Col xs="auto">
                                                <Button color="link" onClick={props.remove}><FontAwesomeIcon icon="trash-alt" color="danger" /><span className="sr-only">Remove</span></Button>
                                            </Col>
                                            ): null
                                    }
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="description">Description for executive summary</Label>
                                <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="description" placeholder="Description for the executive summary" value={props.model.description} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="">Questions</Label>
                                <div>
                                    <div>
                                        {
                                            questions.map((item, index) => (
                                                <QuestionUi key={index} model={item} isCreate={props.questions.isAddedModel(item.id)}
                                                    questionSetType={props.questionSetType}
                                                    change={(changes: Partial<Question>) => props.questions.change(item.id, changes)}
                                                    validate={(fieldsToCheck?: Array<string>) => props.questions.validateModel(item.id, fieldsToCheck)}
                                                    validationErrors={props.questions.validationErrorsFor(item.id)}
                                                    remove={() => {
                                                        // Remove the question.
                                                        props.questions.removeModel(item.id);

                                                        // Remove any choices (particularly important if they were auto added e.g. for RAG questions).
                                                        for (const choice of props.questionChoices.models.filter(it => it.questionId === item.id)) {
                                                            props.questionChoices.removeModel(choice.id);
                                                        }

                                                        // Remove any tag assignments.
                                                        for (const questionTag of props.questionTags.models.filter(it => it.questionId === item.id)) {
                                                            props.questionTags.removeModel(questionTag.id);
                                                        }
                                                    }}
                                                    questionChoices={props.questionChoices}
                                                    impacts={props.impacts}
                                                    isPublished={props.isPublished}
                                                    isReadOnly={props.isReadOnly}
                                                    allTags={props.tags} questionTags={props.questionTags}
                                                    awardTags={props.awardTags}
                                                    videos={props.videos}
                                                    changeQuestionOrder={props.changeQuestionOrder}
                                                    schoolEvidenceNames={schoolEvidenceNames}
                                                />
                                            ))
                                        }
                                    </div>
                                    {
                                        !props.isPublished /* Can't add questions to a published question set. */ ? (
                                            <ButtonGroup>
                                                <Button color="primary" outline onClick={addQuestion}>Add question</Button>
                                                {
                                                    questions.length ? (
                                                        <ButtonDropdown isOpen={addButtonDropdownIsOpen} toggle={toggleAddButtonDropdown}>
                                                            <DropdownToggle color="primary" outline caret />
                                                            <DropdownMenu right>
                                                                <DropdownItem onClick={cloneLastQuestion}>
                                                                    Clone last question
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </ButtonDropdown>
                                                    ) : null
                                                }
                                            </ButtonGroup>
                                            ): null
                                    }
                                </div>
                            </FormGroup>
                        </CardBody>
                        ) : null
                }
            </Collapse>
        </Card>

    );
};
