import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';
import { AnyAction } from 'redux';
import { RouterState } from 'connected-react-router';
import classNames from 'classnames';

interface NavLinkTrackActiveProps /*extends NavLinkProps*/ {
    router: RouterState
    to: string,
    tag?: React.ReactType,
    children: any
    onClick?: () => void,
}

interface RequiredAppState {
    router: RouterState
}

// NavLink wrapper that tracks the active route and sets adds/removes the active class based on it.
export const _NavLinkTrackActive = (props: NavLinkTrackActiveProps) => {
    return (
        <NavLink to={props.to} tag={props.tag} className={classNames({ active: props.router.location.pathname.indexOf(props.to) == 0 })} onClick={props.onClick}>
            {props.children}
        </NavLink>
    );
};

export const NavLinkTrackActive = connect(
    // mapStateToProps
    (state: RequiredAppState) => ({
        router: state.router
    }),
    //mapDispatchToProps
    (dispatch: React.Dispatch<AnyAction>) => ({
    })
)(_NavLinkTrackActive);
