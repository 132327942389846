import * as React from "react";
import { ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { Impact } from "../../../api/models/Impact";


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<Impact>
}

export interface EditUiProps extends EditUiPropsBase<Impact> {
}

export const _EditContainer = (props: EditContainerProps) => {
    let { component, repository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');
    const isCreate = id ? false : true;

    const [model, setModel] = React.useState<Impact | undefined>(undefined);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<Impact>) => {
        setModel(prevState => ({
            ...(prevState as Impact),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await repository.find(id? id: 'defaults');
        setModel(result);
        return true;
    }, [repository, setModel, id]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

        //if (!fieldsToCheck || fieldsToCheck.includes('description')) {
        //    validation.singleCheck('description', () => !model.description, 'Description is required');
        //}
    }, [model]);
    
    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model, isCreate);

        return true;
    }, [model, validate, repository, isCreate]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel} isCreate={isCreate}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            />
    );
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.impacts.repository()
}))(_EditContainer);

