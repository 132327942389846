import { Guid } from "guid-string";
import { getQuestionSetTypeFromName, QuestionSetType, QuestionSetTypeSettings } from "../components/administration/questions/QuestionSetType";

export interface ProjectSettings {
    // Top level checks (can be used when needed but prefer the specific solutions below).
    isSelfEvaluation: boolean,
    isDueDiligence: boolean,

    // Specific options that should be used (so we can make it easy to turn things on/off by project type in the future).
    baseRoute: string,
    otherProjectBaseRoute: (projectId: string, schoolId: string | null | undefined, routeToDashboard?: boolean) => string,
    allowActions: boolean,
    allowRisks: boolean,
    allowQuestionnaires: boolean,
    allowHistory: boolean,

    questionSetType: QuestionSetTypeSettings,
}


/**
 * Return the best settings to use for a project based on the name of the questionset.
 */
export function getProjectSettingsFromQuestionSetName(name: string, projectId: string, trustId?: string, schoolId?: string, awardTagId?: string, subjectId?: string | null): ProjectSettings {
    if (!name) {
        return defaultProjectSettings;
    }

    const questionSetType = getQuestionSetTypeFromName(name);
    const isTrust = Guid.isEmpty(schoolId);

    // Function that lets us get a route for other projects of the same type.
    const otherProjectBaseRoute = (otherProjectId: string, otherSchoolId: string | null | undefined, routeToDashboard?: boolean) => {
        // Other project is for a trust.
        if (Guid.isEmpty(otherSchoolId)) {
            if (awardTagId) {
                return `/manage/award/${awardTagId}/${otherProjectId}`;
            }

            switch (questionSetType.questionSetName) {
                case QuestionSetType.dueDiligence.questionSetName:
                    return `/manage/dueDiligence/${otherProjectId}`;
                case QuestionSetType.subjectLeadershipReview.questionSetName:
                    return `/manage/subjectLeadershipReview/${otherProjectId}`;
                case QuestionSetType.subjectHelpfulQuestions.questionSetName:
                    return `/manage/subjectHelpfulQuestions/${otherProjectId}`;
                case QuestionSetType.readingReviewEYFSToKS2.questionSetName:
                    return `/manage/readingReviewEYFSToKS2/${otherProjectId}`;
                case QuestionSetType.readingReviewKS3ToKS4.questionSetName:
                    return `/manage/readingReviewKS3ToKS4/${otherProjectId}`;
                case QuestionSetType.selfEvaluation.questionSetName:
                default:
                    return `/manage/selfEvaluation/${otherProjectId}`;
            }
        }

        // Other project is for a school.
        if (awardTagId) {
            return `/school/${otherSchoolId || ''}/award${routeToDashboard? 'Dashboard': ''}/${awardTagId}/${otherProjectId}`;
        }

        switch (questionSetType.questionSetName) {
            case QuestionSetType.dueDiligence.questionSetName:
                return `/school/${otherSchoolId || ''}/dueDiligence{routeToDashboard ? 'Dashboard' : ''}/${otherProjectId}`;;
            case QuestionSetType.subjectLeadershipReview.questionSetName:
                return `/school/${otherSchoolId || ''}/subjectLeadershipReview{routeToDashboard ? 'Dashboard' : ''}/${otherProjectId}`;
            case QuestionSetType.subjectHelpfulQuestions.questionSetName:
                return `/school/${otherSchoolId || ''}/subjectHelpfulQuestions{routeToDashboard ? 'Dashboard' : ''}/${otherProjectId}`;
            case QuestionSetType.readingReviewEYFSToKS2.questionSetName:
                return `/school/${otherSchoolId || ''}/readingReviewEYFSToKS2{routeToDashboard ? 'Dashboard' : ''}/${otherProjectId}`;
            case QuestionSetType.readingReviewKS3ToKS4.questionSetName:
                return `/school/${otherSchoolId || ''}/readingReviewKS3ToKS4{routeToDashboard ? 'Dashboard' : ''}/${otherProjectId}`;
            case QuestionSetType.selfEvaluation.questionSetName:
            default:
                return `/school/${otherSchoolId || ''}/selfEvaluation${routeToDashboard ? 'Dashboard' : ''}/${otherProjectId}`;;
        }
    };

    if (isTrust) {
        switch (questionSetType.questionSetName) {
            case QuestionSetType.dueDiligence.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: true,
                    baseRoute: awardTagId ? `/manage/award/${awardTagId}/${projectId}`
                        : `/manage/dueDiligence/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: true,
                    allowRisks: true,
                    allowQuestionnaires: true,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.subjectLeadershipReview.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/manage/subjectLeadershipReview/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.subjectHelpfulQuestions.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/manage/subjectHelpfulQuestions/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.readingReviewEYFSToKS2.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/manage/readingReviewEYFSToKS2/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.readingReviewKS3ToKS4.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/manage/readingReviewKS3ToKS4/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.selfEvaluation.questionSetName:
            default:
                return {
                    isSelfEvaluation: true,
                    isDueDiligence: false,
                    baseRoute: awardTagId ? `/manage/award/${awardTagId}/${projectId}`
                        : `/manage/selfEvaluation/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: true,
                    allowRisks: false,
                    allowQuestionnaires: true,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
        }
    } else {
        switch (questionSetType.questionSetName) {
            case QuestionSetType.dueDiligence.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: true,
                    baseRoute: awardTagId ? `/school/${schoolId || ''}/award/${awardTagId}/${projectId}`
                        : `/school/${schoolId || ''}/dueDiligence/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: true,
                    allowRisks: true,
                    allowQuestionnaires: true,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.subjectLeadershipReview.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/school/${schoolId || ''}/subjectLeadershipReview/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.subjectHelpfulQuestions.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/school/${schoolId || ''}/subjectHelpfulQuestions/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.readingReviewEYFSToKS2.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/school/${schoolId || ''}/readingReviewEYFSToKS2/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.readingReviewKS3ToKS4.questionSetName:
                return {
                    isSelfEvaluation: false,
                    isDueDiligence: false,
                    baseRoute: `/school/${schoolId || ''}/readingReviewKS3ToKS4/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: false,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
            case QuestionSetType.selfEvaluation.questionSetName:
            default:
                return {
                    isSelfEvaluation: true,
                    isDueDiligence: false,
                    baseRoute: awardTagId ? `/school/${schoolId || ''}/award/${awardTagId}/${projectId}`
                        : `/school/${schoolId || ''}/selfEvaluation/${projectId}`,
                    otherProjectBaseRoute: otherProjectBaseRoute,
                    allowActions: true,
                    allowRisks: false,
                    allowQuestionnaires: false,
                    allowHistory: false,
                    questionSetType: questionSetType,
                };
        }
    }

    //return defaultProjectSettings;
}

/**
 * Default ProjectSettings (used until we know better).
 */
export const defaultProjectSettings: ProjectSettings = {
    isSelfEvaluation: false,
    isDueDiligence: false,

    baseRoute: '',
    otherProjectBaseRoute: () => '',
    allowActions: false,
    allowRisks: false,
    allowQuestionnaires: false,
    allowHistory: false,

    questionSetType: {
        questionSetName: '',
        impactChoice: false,
        ragAnswers: false,
        riskText: '',
        riskTextAddToSentence: '',
        specificReviewMainTagText: '',
        specificReviews: false,
        tierChoice: false,
        
    }
};