import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody } from 'reactstrap';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Project } from '../../../../api/models/Project';
import { SpidergraphChart } from './SpidergraphChart';
import { SpidergraphBarChart } from './SpidergraphBarChart';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AwardTag } from '../../../../api/models/AwardTag';
import { PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';

export interface SpidergraphCardProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    results: Array<ProjectAnalysisResult>,

    showOtherSchools?: boolean,
    chartHeight?: number,

    isSelfEvaluation?: boolean,

    isForSingleSchool: boolean

    placeholderReplacementMode: PlaceholderReplacementMode

    awardTag: AwardTag | undefined,
}

export const SpidergraphCard = (props: SpidergraphCardProps) => {
    // Show the chart.
    return (
        <Card>
            <CardBody>
                {/* no results at all so nothing to show */}
                <ConditionalFragment showIf={!props.results.length}>
                    <div className="nothing-here">
                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                        No information is available to create this dashboard display yet.
                    </div>
                </ConditionalFragment>

                {/* this a self evaluation project */}
                <ConditionalFragment showIf={!!props.isSelfEvaluation}>
                    {/* 1 or 2 individual results */}
                    <ConditionalFragment showIf={props.results[0].results.length > 0 && props.results[0].results.length <= 2}>
                        <SpidergraphBarChart {...props} />
                    </ConditionalFragment>
                    {/* more than 2 individual results */}
                    <ConditionalFragment showIf={props.results[0].results.length > 2}>
                        <SpidergraphChart {...props} />
                    </ConditionalFragment>
                    {/* no individual results */}
                    <ConditionalFragment showIf={!props.results[0].results.length}>
                        <ConditionalFragment showIf={!props.awardTag}>
                            <SpidergraphChart {...props} />
                            {/* special message for self evaluation with no results to map */}
                            <div className="text-muted">
                                As you update your self evaluation each term you will be able to see your school's information mapped against the targets.
                                Because you haven't started your self evaluation yet, only the targets are shown at the moment.
                            </div>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!props.awardTag}>
                            <SpidergraphBarChart {...props} />
                            {/* special message for self evaluation with no results to map */}
                            <div className="text-muted">
                                 As you update your self evaluation each term you will be able to see your school's information mapped here filtered for this award or inspection.
                                 Because you haven't started your self evaluation for these areas yet, we can't show you anything at the moment.
                            </div>
                        </ConditionalFragment>
                    </ConditionalFragment>
                </ConditionalFragment>

                {/* this is either a due diligence project or we don't know */}
                <ConditionalFragment showIf={!props.isSelfEvaluation}>
                    {/* 2 or less individual results and due diligence project */}
                    <ConditionalFragment showIf={props.results[0].results.length <= 2}>
                        <SpidergraphBarChart {...props} />
                    </ConditionalFragment>
                    {/* more than 2 individual results */}
                    <ConditionalFragment showIf={props.results[0].results.length > 2}>
                        <SpidergraphChart {...props} />
                    </ConditionalFragment>
                </ConditionalFragment>

            </CardBody>
        </Card>
    );
};

