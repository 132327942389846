import * as React from 'react';
import { List } from '../List';

/**
 * List filtered to show only school due diligence question sets.
 */
export const SchoolDueDiligenceList = () => {
    const filter = React.useCallback((items: Array<any>) => {
        if (!items) {
            return items;
        }

        return items.filter(item => item.name === 'School due diligence');
    }, []);

    return (
        <List filter={filter} baseRoute="/administration/school-due-diligence" />
        );
};