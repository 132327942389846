// This is a reducer that knows how to handle some or all actions for this slice.
// It will mostly handle actions from within the slice, but may handle any other action thats appropriate as well as actions
// do not have a one to one relationship with reducers.

import { AnyAction } from 'redux';
import { UserState, Identity } from './state';
import { loginType, LoginAction } from './actions/login';
import { logoutType, LogoutAction } from './actions/logout';
import { selectSubscriptionType, SelectionSubscriptionAction } from './actions/selectSubscription';




const initialState: UserState = { isAuthenticated: false, identity: undefined, token: '' };

export const reducer = function (state = initialState, action: AnyAction): UserState {
    if (action.type === loginType) {
        let payload = (<LoginAction>action).payload;

        return {
            ...state,
            isAuthenticated: true,
            identity: payload.identity,
            token: payload.token
        };
    }

    if (action.type === logoutType) {
        //let payload = (<LogoutAction>action).payload;

        return {
            ...state,
            isAuthenticated: false,
            identity: undefined,
            token: ''
        };
    }

    if (action.type === selectSubscriptionType) {
        let payload = (<SelectionSubscriptionAction>action).payload;

        // If the user is not logged in to an identity then do nothing.
        if (!state.identity) {
            return state;
        }

        // User is logged into an identity so change its subscriptionId.
        return {
            ...state,
            identity: {
                ...(state.identity as Identity),
                subscriptionId: payload.subscriptionId || ''
            }
        };
    }

    return state;
}
