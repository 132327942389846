import * as React from 'react';
import { Row, Col, Table, Card } from 'reactstrap';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ReportProps } from '../reportGenerator/ReportProps';
import { ProjectAnalysisResult, ProjectAnalysisResultItem } from '../../../api/models/ProjectAnalaysisResult';
import { getGradeString } from '../../../utilities/getGradeString';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../utilities/replacePlaceholders';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Strength } from '../../../api/models/Strength';
import { Risk } from '../../../api/models/Risk';
import { Question } from '../../../api/models/Question';
import { QuestionResponse } from '../../../api/models/QuestionResponse';
import { QuestionChoice } from '../../../api/models/QuestionChoice';
import moment from 'moment';


export interface RAGProjectWillDoUiProps extends ReportProps {
    /*projectTopicId: string*/
}

/**
 * Will-do tables sorted by RAG
 */
export const RAGProjectWillDoUi = (props: RAGProjectWillDoUiProps) => {
    const {
        questionResponses,
        questionChoices,
    } = props;

    let questions = props.questions;

    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <Row>
                <Col>
                    <h4><span>Commitments</span></h4>
                </Col>
                <Col xs = "auto">
                </Col>
            </Row>
            
            <Card body tag="div">
                <TableFor
                    title="Green: Confidence is Strong"
                    items={questions}
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    colour={"#4dbd74"}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    RAG={"Green"}
                    subjectName={props.subject?.name}
                />
            </Card>
            <Card body tag="div">
                <TableFor
                    title="Amber: Developing confidence "
                    items={questions}
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    colour={"#ffc107"}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    RAG={"Amber"}
                    subjectName={props.subject?.name}
                />
            </Card>
            <Card body tag="div">
                <TableFor
                    title="Red: Confidence gaps "
                    items={questions}
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    colour={"#f86c6b"}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    RAG={"Red"}
                    subjectName={props.subject?.name}
                />
            </Card>
        </div>
    );
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and is only used internally by the files main component.
 */
const TableFor = (props: {
    title: string,
    items: Array<Question>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    colour: string,
    questionResponses: Array<QuestionResponse>,
    questionChoices: Array<QuestionChoice>,
    RAG: string,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        colour,
        questionResponses,
        questionChoices,
        RAG,
        subjectName,
    } = props;
    return (
        <div className="table-responsive">
            <Table striped className="table-sm" >
                <thead>
                    <tr>
                        <th style={{width: "5%"} }></th> {/*We have to force the columns to be these widths, or the seperate tables won't align properly*/ }
                        <th style={{ color: colour, width: "45%" }}><span>{title}</span></th>
                        <th style={{ color: colour, width: "40%" }}><span>{RAG === "Green" ? "Maintaining and Sharing Confidence" : RAG === "Amber" ? "Embedding Confidence": RAG === "Red" ? "Establishing Confidence" : ""}</span></th>
                        <th style={{ color: colour, width:"10%" }}><span>Timeline</span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map(item => {
                            // Without a question we can't show anything.
                            if (!item) {
                                return null;
                            }
                            let willDoText = questionResponses.find(it => it.questionId === item.id)?.willDoText;
                            let willDoDate = questionResponses.find(it => it.questionId === item.id)?.willDoDate;
                            // Get the RAG response and filter each table to only show their own colour
                            const RAGchoice = questionChoices.find(it => it.questionId === item.id)?.name;

                            if (RAGchoice?.split(":")[0] !== RAG || !willDoText) {
                                return null;
                            }

                            return (
                                <tr key={item.id}>
                                    <td style={{ width: "5%" }}><span>{item.questionNumber}</span></td>
                                    <td style={{ width: "45%" }}><PlainTextWithBrs text={replacePlaceholders(item.name, placeholderReplacementMode, { subjectName: subjectName })} /></td>
                                    <td style={{ width: "40%" }}><PlainTextWithBrs text={replacePlaceholders(questionResponses.find(it => it.questionId === item.id)?.willDoText, placeholderReplacementMode)}></PlainTextWithBrs></td>
                                    <td style={{ width: "10%" }}>
                                        {
                                            !!willDoDate ? (
                                                <div>
                                                    {moment(willDoDate).local().format('DD/MM/YYYY')}
                                                </div>
                                            ): null
                                    }
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};
