import * as React from 'react';
import moment from 'moment';
import { Text, View } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { Project } from '../../../../api/models/Project';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { getGradeString } from '../../../../utilities/getGradeString';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';

export interface SchoolWithoutTrustComparisonTablePdfUi {
    model: Project | undefined,
    results: Array<ProjectAnalysisResult>,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    placeholderReplacementMode: PlaceholderReplacementMode,
}


/**
 * Trust comparison table for pdf.
 */
export const SchoolWithoutTrustComparisonTablePdfUi = (props: SchoolWithoutTrustComparisonTablePdfUi) => {
    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index as well.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    if (!props.results || props.results.length < 2) {
        return (<></>);
    }

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.results[0];

    return (
        <View style={Styles.table}>
            <View style={Styles.thead}>
                <View style={Styles.trHead}>
                    {
                        isForQuestions ? (
                            <View style={{ ...Styles.th, width: '10%' }}><Text>{' '}</Text></View>
                        ) : null
                    }
                    <View style={{ ...Styles.th, width: (isForQuestions ? '60%' : '70%') }}><Text>{' '}</Text></View>
                    <View style={{ ...Styles.th, width: '30%', textAlign: 'right' }}>
                        <Text>{thisProject.school ? thisProject.school.name : thisProject.trust ? thisProject.trust.name : 'Project'}</Text>
                    </View>
                </View>
            </View>
            <View style={Styles.tbody}>
                {
                    thisProject.results.map(item => {
                        return (
                            <View key={item.originKey} style={Styles.tr}>
                                {
                                    isForQuestions ? (
                                        <View style={{ ...Styles.td, width: '10%' }}><Text>{item.questionNumber}</Text></View>
                                    ) : null
                                }
                                <View style={{ ...Styles.th, width: (isForQuestions ? '60%' : '70%') }}>
                                    <Text>{replacePlaceholders(item.name, props.placeholderReplacementMode)}</Text>
                                </View>
                                <View style={{ ...Styles.th, width: '30%', textAlign: 'right' }}>
                                    <Text><Text style={Styles.strong}>{getGradeString(item.percentageQuestionScore)}</Text></Text>
                                </View>
                            </View>
                        );
                    })}
            </View>
        </View>
    );
};

