import * as React from 'react';
import { Redirect } from 'react-router';
import { withContainer } from 'react-withcontainer';
import { Row, Col, Card, CardBody, Input } from 'reactstrap';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { AdminReportsUiProps, AdminReportsContainer } from './AdminReportsContainer';
import { DonutChart } from './charts/DonutChart';
import { ResponsesChart } from './charts/ReponsesChart';


/**
 * AdminReports screen for a project, this gives a summary and easy navigation for the user.
 */
export const AdminReportsUi = (props: AdminReportsUiProps) => {
    const [filterBySubscriptionId, setFilterBySubscriptionId] = React.useState<string>('');

    let subscriptionsToUse = React.useMemo(() => {
        if (!props.subscriptions) {
            return [];
        }

        if (!filterBySubscriptionId) {
            return props.subscriptions;
        }

        return props.subscriptions.filter(item => item.id === filterBySubscriptionId);
    }, [props.subscriptions, filterBySubscriptionId]);

    if (!props.subscriptions) {
        return (<LoadingIndicator />);
    }

    return (
        <div className="main-container">
            <div className="main-heading">
                <Row>
                    <Col>
                        <h1>Evaluate-Ed overview</h1>
                    </Col>
                    <Col xs={12} sm="auto">
                        <Input type="select" value={filterBySubscriptionId} onChange={e => setFilterBySubscriptionId(e.currentTarget.value)}>
                            <option value="">(All subscriptions)</option>
                            {
                                props.subscriptions.map(item => (
                                    <option key={item.id} value={item.id}>{`${item.name} (${item.email})`}</option>
                                    ))
                            }
                        </Input>
                    </Col>
                </Row>
            </div>
                
            <Row>
                {
                    !filterBySubscriptionId ? (
                        <Col xs={12} xl={4}>
                            <DonutChart title="Subscriptions" model={[{ 'subscription': 'Subscriptions', data: subscriptionsToUse.length }]} />
                        </Col>
                        ): null
                }
                <Col xs={12} md={6} xl={filterBySubscriptionId? 6: 4}>
                    <DonutChart title="Schools" model={
                        subscriptionsToUse.map(item => {
                            var result = props.results.find(it => it.subscriptionId === item.id);
                            if (!result) {
                                return { subscription: item.name, data: 0 }
                            }

                            return {
                                subscription: item.name,
                                data: result.numberOfSchools,
                            };
                        })
                    } />
                </Col>
                <Col xs={12} md={6} xl={filterBySubscriptionId ? 6 : 4}>
                    <DonutChart title="Potential schools" model={
                        subscriptionsToUse.map(item => {
                            var result = props.results.find(it => it.subscriptionId === item.id);
                            if (!result) {
                                return { subscription: item.name, data: 0}
                            }

                            return {
                                subscription: item.name,
                                data: result.numberOfPotentialSchools
                            };
                        })
                    } />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <ResponsesChart key={filterBySubscriptionId /* Used as a hack to force Apex charts to refresh otherwise it gets upset. */}
                                subscriptions={subscriptionsToUse} results={props.results} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export const AdminReports = withContainer(AdminReportsContainer)(AdminReportsUi);
