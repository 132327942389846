import * as React from 'react';
import moment from 'moment';
import { DateLabel } from '../../shared/DateLabel';
import { Establishment } from '../../../api/models/edubase/Establishment';
import { ReasonForConsiderationUi } from './ReasonForConsiderationUi';
import { SchoolSummaryUi } from './SchoolSummaryUi';
import { Guid } from 'guid-string';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReportProps } from '../reportGenerator/ReportProps';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';


/**
 * Introduction section of the executive summary.
 */
export interface IntroductionUiProps extends ReportProps {
    displayIntroduction?: boolean,
    displaySchoolBackground?: boolean,
    displayReviewers?: boolean
}

export const IntroductionUi = (props: IntroductionUiProps) => {
    const { projectSettings } = props;


    let schoolEdubaseModel = React.useMemo(() => {
        if (!props.school || !props.school.edubaseJson) {
            return undefined;
        }

        return JSON.parse(props.school.edubaseJson) as Establishment;
    }, [props.school]);


    let isForTrust = React.useMemo(() => {
        if (!props.model) {
            return false;
        }

        if (!Guid.isEmpty(props.model.schoolId)) {
            return false;
        }

        if (!Guid.isEmpty(props.model.trustId)) {
            return true;
        }

        return false;
    }, [props.model]);


    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <ConditionalFragment showIf={props.displayIntroduction || false}>
                <h3>Introduction</h3>
                {
                    isForTrust ? (
                        <p>
                            The purpose of this report is to {props.awardTag ? `prepare for the ${props.awardTag.name} review of` : projectSettings.isSelfEvaluation ? 'perform a self evaluation of' : projectSettings.isDueDiligence ? 'perform due diligence on' : 'review'} {props.trust && props.trust.name || ''}{props.trust && props.trust.dfeReference ? (<>{` (${props.trust.dfeReference})`}</>) : null}.
                        </p>
                    ) : (
                        <p>
                            The purpose of this report is to {props.awardTag ? `prepare for the ${props.awardTag.name} review of` : projectSettings.isSelfEvaluation ? 'perform a self evaluation of' : projectSettings.isDueDiligence ? 'perform due diligence on' : 'review'} {props.school && props.school.name || ''}{props.trust ? ' on behalf of ' + props.trust.name : ''}
                            {schoolEdubaseModel ? (<> ({`URN: ${schoolEdubaseModel.urn}, DfE number: ${schoolEdubaseModel.establishmentNumber}, UKPRN: ${schoolEdubaseModel.ukprn}`}).</>) : (<>.</>)}
                        </p>
                    )
                }

                <p>
                    <ConditionalFragment showIf={props.projectSettings.isDueDiligence}>
                        The {props.awardTag ? 'review' : projectSettings.isSelfEvaluation ? 'self evaluation' : projectSettings.isDueDiligence ? 'due diligence' : 'review'} was carried out between <DateLabel format="DD/MM/YYYY" value={props.model.startDate} /> and {props.model.completedDate ? (<DateLabel format="DD/MM/YYYY" value={props.model.completedDate} />) : (<DateLabel format="DD/MM/YYYY" value={moment().toISOString()} />)}.
                    </ConditionalFragment>

                    The results were gathered by {props.reviewers.length == 1 ? (<>{props.reviewers[0].forename} {props.reviewers[0].surname}</>) : (<>{props.reviewers.length} reviewers</>)}
                    {props.projectQuestionaires.filter(it => it.sentOnDate).length ? (<> and responses gathered by {props.projectQuestionaires.filter(it => it.sentOnDate).length} questionnaires</>) : (<></>)}.
                </p>
            </ConditionalFragment>
            {
                props.school ? (
                    <ConditionalFragment showIf={props.displaySchoolBackground || false}>
                        <h4>School background</h4>
                        {
                            schoolEdubaseModel ? (
                                <>
                                    <p>
                                        {props.school && props.school.name} is a school under the {schoolEdubaseModel.la.displayName} local authority located on {[schoolEdubaseModel.street, schoolEdubaseModel.locality, schoolEdubaseModel.town, schoolEdubaseModel.county.displayName, schoolEdubaseModel.postcode].filter(it => it).join(', ')}.
                                        <> The headteacher at the school is {schoolEdubaseModel.headTitle.displayName} {schoolEdubaseModel.headFirstName} {schoolEdubaseModel.headLastName}{schoolEdubaseModel.headHonours ? ` ${schoolEdubaseModel.headHonours}` : ''}.</>
                                        <> The school covers the {schoolEdubaseModel.phaseOfEducation.displayName} phase of education and has {schoolEdubaseModel.numberOfPupils || 0} pupils between ages {schoolEdubaseModel.statutoryLowAge} to {schoolEdubaseModel.statutoryHighAge}.  The school's pupils capacity is {schoolEdubaseModel.schoolCapacity || 0}.</>
                                        {schoolEdubaseModel.officialSixthForm.displayName === 'Has a sixth form' ? (<> The school has an official sixth form.</>) : (<></>)}

                                    </p>
                                    <p>
                                        The school has an Ofsted rating of <strong>{schoolEdubaseModel.ofstedRating.displayName}</strong>
                                        {schoolEdubaseModel.ofstedLastInsp ? (
                                            <> and was last inspected by Ofsted on <DateLabel format="DD/MM/YYYY" value={schoolEdubaseModel.ofstedLastInsp} />.</>
                                        ) : (
                                            <> and has no date of last inspection on record.</>
                                        )}
                                        <> The Ofsted report can be viewed at <a href={`http://www.ofsted.gov.uk/oxedu_providers/full/(urn)/${schoolEdubaseModel.urn}`} target="_blank">{`http://www.ofsted.gov.uk/oxedu_providers/full/(urn)/${schoolEdubaseModel.urn}`}</a>.</>
                                    </p>
                                </>
                            ) : null
                        }
                        <SchoolSummaryUi {...props} />
                    </ConditionalFragment>
                ) : (
                    <>
                        <h4>Trust background</h4>
                        <SchoolSummaryUi {...props} />
                    </>
                )
            }
            <ConditionalFragment showIf={projectSettings.isDueDiligence}>
                <ReasonForConsiderationUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={props.displayReviewers || false}>
                <h4>Reviewers</h4>
                <p>
                    {props.awardTag ? 'The review' : projectSettings.isSelfEvaluation ? 'Self evaluation' : projectSettings.isDueDiligence ? 'Due diligence' : 'The review'} was performed by the following {props.reviewers.length == 1 ? (<>reviewer</>) : (<>{props.reviewers.length} reviewers</>)}:
                </p>
                {
                    props.reviewers.map(item => (
                        <div key={item.id}>
                            <h6>{item.forename} {item.surname} ({item.email})</h6>
                            <p>
                                <PlainTextWithBrs text={replacePlaceholders(item.bio, props.placeholderReplacementMode)} />
                            </p>
                        </div>
                    ))
                }
            </ConditionalFragment>
        </div>
    );
};
