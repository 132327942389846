import { withServiceProps } from "inject-typesafe-react";
import { BasicRepositoryDeleteContainerProps, BasicRepositoryDeleteContainer } from "../../containers/common/basicRepositoryContainers/BasicRepositoryDeleteContainer";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { DeleteUiPropsBase } from "../../containers/common/DeleteUiPropsBase";
import { School } from "../../../api/models/School";

export interface DeleteUiProps extends DeleteUiPropsBase<School> {
}

export const DeleteContainer = withServiceProps<BasicRepositoryDeleteContainerProps, AppServicesCore>(services => ({
    repository: services.api.schools.repository()
}))(BasicRepositoryDeleteContainer);