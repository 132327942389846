import * as React from "react";
import { Modal, ModalHeader, ModalBody, FormGroup, Label, ModalFooter, Button, Alert } from "reactstrap";
import { AlertOnErrors } from "../shared/AlertOnErrors";
import { useApiService } from "../../api/useApiService";
import { HelpRequest } from "../../api/models/HelpRequest";
import { ButtonAsync } from "reactstrap-buttonasync";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsyncCallback } from "react-use-async-callback";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidationState } from "pojo-validator";
import { ValidatedElasticInput } from "../shared/ValidatedElasticInput";
import { connect } from "react-redux";
import { AppState } from "../../store";


export interface HelpRequestModalProps {
    isOpen: boolean,
    toggle: () => void,
    id?: string | undefined,

    // From redux
    userId?: string,
}

/**
 * Modal for submitting (or responding to) a help request.
 */
export const _HelpRequestModal = (props: HelpRequestModalProps) => {
    const {
        isOpen,
        toggle,
        id,
        userId,
    } = props;

    const isCreate = !id;

    // Get API endpoints.
    const api = useApiService();
    const loadViewModel = api.helpRequests.viewModels.edit();
    const repository = React.useMemo(() => api.helpRequests.repository(), [api]);

    // Need a model and a way to change it.
    const [model, setModel] = React.useState<HelpRequest>();
    const changeModel = React.useCallback((changes: Partial<HelpRequest>) => {
        setModel(prevState => ({
            ...(prevState || {} as HelpRequest),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        const result = await loadViewModel(id);

        // If this is a create set some defaults so we know who is making the request and where they are making it.
        let newModel: HelpRequest = { ...result.model };
        if (isCreate) {
            newModel = {
                ...newModel,
                requestUserId: userId || '',
                requestUrl: window.location.href,
            };
        }

        setModel(newModel);

        return true;
    }, [loadViewModel, setModel, id, isCreate]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);


    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('requestText')) {
            validation.singleCheck('requestText', () => !model.requestText, 'You must detail the question you want to ask an expert');
        }
    }, [model]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: saveErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        await repository.save(model.id, model, isCreate);

        toggle();
    }, [model, repository, toggle]);

    // UI Input helper functions.
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        changeModel({
            [target.name]: (target.type === 'checkbox' ? target.checked : target.value)
        });
    }, [changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        validate([event.currentTarget.name]);
    }, [validate]);

    // Show nothing until we have a model.
    if (!model) {
        return null;
    }

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader>
                Ask an expert
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={[loadingErrors, saveErrors]} />

                <Alert color="info">
                    You can ask our experts about any of the questions or information provided by Evaluate-Ed and we'll be happy to email you
                    with personal advice as suggestions for your query.
                </Alert>

                <FormGroup>
                    <Label htmlFor="requestText">Your question for our experts</Label>
                    <ValidatedElasticInput name="requestText" type="textarea" value={model.requestText || ''} onChange={onChange} onBlur={onValidate} validationErrors={validationErrors} />
                </FormGroup>
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <ButtonAsync color="primary" onClick={save} isExecuting={isSaving}
                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Sending...</>}>
                    <FontAwesomeIcon icon="envelope" />
                    <> </>
                    Send
                </ButtonAsync>
                <Button color="primary" outline onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
        );
};


export const HelpRequestModal = connect(
    (state: AppState) => ({
        userId: state.user.identity && state.user.identity.userId || '',
    })
)(_HelpRequestModal);