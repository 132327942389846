import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { ContainerComponentProps } from "react-withcontainer";

export interface HomeContainerProps extends ContainerComponentProps<HomeUiProps> {
    
}

export interface HomeUiProps {
    schoolId: string
}

/**
 * Container for the home page of a Project.
 * 
 * @param props
 */
export const HomeContainer = (props: HomeContainerProps) => {
    const { component } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');

    const Component = component;
    return (
        <Component schoolId={id} />
    );
};


