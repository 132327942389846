import * as React from "react";
import { User } from "../../../api/models/User";
import { Subscription } from '../../../api/models/Subscription';
import { Validator, ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { AuthenticationService } from "../../../services/AuthenticationService";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { SubscriptionLicense } from "../../../api/models/SubscriptionLicense";
import { ManagedChildModels, useManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { connect } from "react-redux";
import { AppStateCore } from "../../../store";
import { AnyAction } from "redux";
import { selectSubscription } from "../../../store/user/actions/selectSubscription";
import { Trust } from "../../../api/models/Trust";
import { useApiService } from "../../../api/useApiService";
import { School } from "../../../api/models/School";
import { create } from "domain";
import { AwardTag } from "../../../api/models/AwardTag";
import { UserProfileAwardTag } from "../../../api/models/UserProfileAwardTag";


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<Subscription>,
    userRepository: Repository<User>,
    licenseRepository: Repository<SubscriptionLicense>,
    trustRepository: Repository<Trust>,
    authenticationService: AuthenticationService,
    loadViewModel: (id: string | undefined) => Promise<any>,
    selectSubscription: (subscriptionId: string | undefined) => void,
    renewToken: () => Promise<void>,
    currentSubscription: string,
    initializeCreate: (model: Subscription) => void,
    userProfileAwardTagsRepository: Repository<UserProfileAwardTag>,
}

export interface EditUiProps extends EditUiPropsBase<Subscription> {
    user: User | undefined,
    trust: Trust | undefined,
    school: School | undefined,
    licenses: ManagedChildModels<SubscriptionLicense>,
    inviteSent: boolean,
    resendInvite: () => Promise<void>,
    resendingInvite: boolean,
    inviteResent: boolean,
    resendInviteErrors: any,
    changeUser: (changes: Partial<User>) => void,
    changeTrust: (changes: Partial<Trust>) => void,
    changeSchool: (changes: Partial<School>) => void,
    selectSubscription: (subscriptionId: string) => void,
    currentSubscription: string,
    //changeLicense: (changes: Partial<SubscriptionLicense>) => void,
    userProfileAwardTags: ManagedChildModels<UserProfileAwardTag>,
    awardTags: Array<AwardTag>,
}


export const _EditContainer = (props: EditContainerProps) => {
    let { component, trustRepository, repository, loadViewModel, userRepository, licenseRepository, authenticationService, renewToken, userProfileAwardTagsRepository, ...rest } = props;
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');
    const isCreate = id ? false : true;

    const [model, setModel] = React.useState<Subscription | undefined>(undefined);
    const [trust, setTrust] = React.useState<Trust | undefined>(undefined);
    const [inviteSent, setInviteSent] = React.useState<boolean>(!isCreate);
    const [user, setUser] = React.useState<User | undefined>(undefined);
    //const [licenses, setLicenses] = React.useState<Array<SubscriptionLicense>>([]);

    // set up the school in case we have a single school trust
    const api = useApiService();
    const schoolRepository = api.schools.repository();
    const [school, setSchool] = React.useState<School | undefined>(undefined);


    const userProfileAwardTags = useManagedChildModels(userProfileAwardTagsRepository);
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<Subscription>) => {
        setModel(prevState => ({
            ...(prevState as Subscription),
            ...changes
        }));
    }, [setModel]);

    const changeTrust = React.useCallback((changes: Partial<Trust>) => {
        setTrust(prevState => ({
            ...(prevState as Trust),
            ...changes
        }));
    }, [setTrust]);

    const changeSchool = React.useCallback((changes: Partial<School>) => {
        setSchool(prevState => ({
            ...(prevState as School),
            ...changes
        }));
    }, [setSchool]);

    const changeUser = React.useCallback((changes: Partial<Subscription>) => {
        setUser(prevState => ({
            ...(prevState as User),
            ...changes
        }));
    }, [setUser]);

    //const changeSubscriptionLicense = React.useCallback((changes: Partial<SubscriptionLicense>) => {
    //    setLicenses(prevState => ({
    //        ...(prevState as Array<SubscriptionLicense>),
    //        ...changes
    //    }));
    //}, [setLicenses]);

    //Load licesnes into ManagedChildModels
    const licenses = useManagedChildModels(licenseRepository, (item: SubscriptionLicense, validation: ValidationState, fieldsToCheck?: Array<string>) => {

    });

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadViewModel(id);
        setUser(result.user);

        // Run any initialization (based on the type being created) for the new subscription if we are creating
        // a new one.
        let newModel = { ...result.model };
        if (isCreate && props.initializeCreate) {
            props.initializeCreate(newModel);
        }

        setAwardTags(result.awardTags);
        userProfileAwardTags.setModels(result.userProfileAwardTags);

        setModel(newModel);
        setTrust(result.trust);
        setSchool(result.school);
        //setLicenses(result.licenses);
        licenses.setModels(result.licenses);
        return true;
    }, [loadViewModel, setModel, setUser, setTrust, setSchool, id, setAwardTags, userProfileAwardTags]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model || !user || !licenses) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('forename')) {
            validation.singleCheck('forename', () => !user.forename, 'Forename is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('surname')) {
            validation.singleCheck('surname', () => !user.surname, 'Surname is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('email')) {
            validation.singleCheck('email', () => !user.email, 'Email is required');
        }

    }, [model, user]);

    // Send the user an invite.
    const sendInvite = React.useCallback(async (user: string) => {
        authenticationService.sendInvite(user);
        setInviteSent(true);
    }, [authenticationService, setInviteSent]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model || !user || !licenses || !trust) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await userRepository.save(user.id, user, isCreate);

        await repository.save(model.id, model, isCreate);
        licenses.save();

        await trustRepository.save(trust.id, trust, isCreate);

        if (model.isForSingleSchool && school) {
            await schoolRepository.save(school.id, school, isCreate);
        }

        // Send the user an invite if they have just been created.
        if (!inviteSent) {
            await sendInvite(user.email);
        }

        // Update the award tag restrictions.
        await userProfileAwardTags.save();

        // Renew the current user's login token, so if the change has been made to the current user its changes will apply immediatly.
        renewToken();

        //return false;
        return true;
    }, [model, trust, trustRepository, school, schoolRepository, user, licenses, validate, repository, userRepository, licenseRepository, isCreate, inviteSent, sendInvite, renewToken,
        userProfileAwardTags,]);

    // Resend the invite to a the user.
    const [resendInvite, { isExecuting: resendingInvite, errors: resendInviteErrors, successfullyExecuted: inviteResent }] = useAsyncCallback(async (): Promise<void> => {
        if (!model || !user || !user.email) {
            return;
        }

        await sendInvite(user.email);

    }, [model, sendInvite]);

    //Manage subscription
    const selectSubscription = React.useCallback((subscriptionId: string) => {
        // Change the logged in user's "currently using" subscription in the redux global state (not the database!)
        props.selectSubscription(subscriptionId);
    }, [props.selectSubscription, navigation]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id)) && !user && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel} isCreate={isCreate}
            user={user} changeUser={changeUser}
            trust={trust} changeTrust={changeTrust}
            licenses={licenses} //changeLicense={changeSubscriptionLicense}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            selectSubscription={selectSubscription}
            resendInvite={resendInvite} inviteSent={inviteSent} inviteResent={inviteResent} resendingInvite={resendingInvite} resendInviteErrors={resendInviteErrors}
            school={school} changeSchool={changeSchool}
            awardTags={awardTags} userProfileAwardTags={userProfileAwardTags}
        />
    );
};

export const __EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.subscriptions.repository(),
    userRepository: services.api.users.repository(),
    licenseRepository: services.api.subscriptionLicenses.repository(),
    trustRepository: services.api.trusts.repository(),
    loadViewModel: services.api.subscriptions.viewModels.edit(),
    authenticationService: services.authenticationService(),
    renewToken: services.renewToken(),
    userProfileAwardTagsRepository: services.api.userProfileAwardTags.repository(),
}))(_EditContainer);

export const EditContainer = connect(
    /*mapStateToProps*/
    (state: AppStateCore) => ({
        currentSubscription: state.user.identity ? state.user.identity.subscriptionId : ''
    }),

    /*mapDispatchToProps*/
    (dispatch: React.Dispatch<AnyAction>) => ({
        selectSubscription: (subscriptionId: string | undefined) => dispatch(selectSubscription(subscriptionId))
    })
)(__EditContainer);
