import * as React from 'react';
import { Table } from 'reactstrap';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { AnonymousQuestionResponse } from '../../../api/models/AnonymousQuestionResponse';
import { RecommendationsBarChartCard } from '../../projects/risks/RecommendationsBarChartCard';
import { ReportProps } from '../reportGenerator/ReportProps';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';


/**
 * SIP recommendations section of the executive summary that gives the top level recommendations.
 */
export const RecommendationsUi = (props: ReportProps) => {
    // The risk chart needs the question responses all in the anonymous format.
    let riskQuestionResponses = React.useMemo(() => {
        if (!props.questionResponses || !props.anonymousQuestionResponses || !props.questionChoices) {
            return [];
        }

        return [
            ...props.anonymousQuestionResponses,
            ...props.questionResponses.map(item => {
                var choice = props.questionChoices.find(it => it.id === item.questionChoiceId);
                let scale = item.scale;
                if (choice) {
                    scale = choice.operationalLevel;
                }

                return {
                    questionId: item.questionId,
                    feedback: item.feedback,
                    scale: scale,
                } as AnonymousQuestionResponse;
            })
        ];
    }, [props.anonymousQuestionResponses, props.questionResponses, props.questionChoices]);

    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <h3>Possible areas for development</h3>
            <RecommendationsBarChartCard
                {...props}
                projects={[props.model]}
                model={props.model}
                schools={[props.school || { id: '', name: '' }]}
                risks={props.risks}
                questions={props.questions}
                sections={props.allSections}
                topics={props.allTopics}
                filterBySectionOriginKey={undefined}
                filterByTopicOriginKey={undefined}
                awardTagId={props.awardTag && props.awardTag.id || undefined}
            />

            <div className="table-responsive">
                <Table striped className="table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th><span>Question</span></th>
                            <th><span>Suggestion</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.risks.map(riskItem => {
                                let question = props.questions.find(it => it.id === riskItem.questionId);
                                let topic = props.allTopics.find(it => it.id === riskItem.topicId);
                                let sectionId = '';
                                if (topic) {
                                    sectionId = topic.sectionId;
                                }

                                let section = props.allSections.find(it => it.id == sectionId);

                                let questionaireId = '';
                                //let impactId = '';
                                if (question) {
                                    questionaireId = question.questionaireId;
                                    //impactId = question.impactId;
                                }

                                let questionaire = props.questionaires.find(it => it.id === questionaireId);
                                let placeholderReplacementMode = props.placeholderReplacementMode;
                                return (
                                    <tr key={riskItem.id}>
                                        <td>{ !!question ? question.questionNumber : '' }</td>
                                        <td><span>
                                            <div>
                                                <strong>
                                                    {section && replacePlaceholders(section.name, placeholderReplacementMode)} / {topic && replacePlaceholders(topic.name, placeholderReplacementMode)} {questionaire ? (<span className="text-muted">(from the {replacePlaceholders(questionaire.name, placeholderReplacementMode)} questionnaire)</span>) : null}
                                                </strong>
                                            </div>
                                            <div>
                                                {question && replacePlaceholders(question.name, placeholderReplacementMode) || 'Whole topic'}
                                            </div>
                                        </span></td>
                                        <td><span>
                                            <PlainTextWithBrs text={replacePlaceholders(riskItem.description, placeholderReplacementMode)} />
                                        </span></td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>
        </div>        
    );
};
