import * as React from 'react';
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from 'react-use-async-callback';
import { EdubaseService } from '../../../services/EdubaseService';
import { Establishment } from '../../../api/models/edubase/Establishment';

export interface SearchUiProps {
    model: Array<Establishment> | undefined

    load: (searchText: string) => void,
    isLoading: boolean,
    loadingErrors: any,

    search: string,
    changeSearch: (search: string) => void,

    resultSelected: (edubaseResult: any) => void
}

export interface SearchContainerProps extends ContainerComponentProps<SearchUiProps> {
    /* From dependnecy injection */
    edubaseService: EdubaseService,

    /* Can be passed in by the caller */
    searchText?: string,
    resultSelected: (edubaseResult: any) => void
}

export const _SearchContainer = (props: SearchContainerProps) => {
    const { component, edubaseService, resultSelected, ...rest } = props;

    const [model, setModel] = React.useState<Array<any> | undefined>(undefined);
    const [search, setSearch] = React.useState<string>(props.searchText || '');

    // Wrap the loadPage method to have correct async behaviour.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (searchText?: string): Promise<boolean> => {
        let result = await edubaseService.findEstablishments(searchText || '');
        setModel(result);
        return true;
    }, [edubaseService, setModel]);

    // Callback for changing the search.  This will also load the first page for the new search.
    const changeSearch = React.useCallback((search: string) => {
        setSearch(search);
        load(search);
    }, [setSearch, load]);


    // Load the first page on mount if we haven't got a model.
    React.useEffect(() => {
        if (!model  && !isLoading && !loadingErrors) {
            load(search);
        }
    }, [model, isLoading, loadingErrors, load, search]);

    const Component = component;
    return (
        <Component {...rest}
            model={model}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            search={search} changeSearch={changeSearch}
            resultSelected={resultSelected}
        />
    );
};


export const SearchContainer = withServiceProps<SearchContainerProps, AppServicesCore>(services => ({
    edubaseService: services.edubaseService()
}))(_SearchContainer);
