import * as React from 'react';
import { Card, CardHeader, CardBody, Input, Row, Col } from 'reactstrap';
import { withContainer } from 'react-withcontainer';
import { StrengthReportUiProps, StrengthReportContainer } from './StrengthReportContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { StrengthUi } from './StrengthUi';
import { Link } from 'react-router-dom';
import { useProjectSettings } from '../../../api/useProjectSettings';
import { Action } from '../../../api/models/Action';
import moment, { Moment } from 'moment';
import { actionStates } from '../../../services/actionStates/actionStates';
import { AddToImprovementPlannerModal } from '../../actions/AddToImprovementPlannerModal';
import { StrengthsBarChartCard} from './StrengthsBarChartCard';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Project } from '../../../api/models/Project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WatchVideoModal } from '../../videos/WatchVideoModal';
import { isProductTierUnlocked, ProductTier } from '../../../api/models/codeOnly/ProductTeir';
import { ProductTierNotUnlockedAlert } from '../../shared/productTierNotUnlocked/ProductTierNotUnlockedAlert';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';

export const StrengthReportUi = (props: StrengthReportUiProps) => {
    const navigation = useUniversalNavigation(props);
    const awardTagId = navigation.getParam('awardTagId', '');

    const projectSettings = useProjectSettings(props.model && props.model.id || '', awardTagId);

    const scrollToStrengthId = navigation.getParam('strengthId', ''); // note - this could also be a section id
    const scrollToStrengthIdRef = React.useRef<HTMLDivElement>(null);

    // Use an effect to scroll to the top as we navigate between question screens.  As we are not changing component
    // the normal react process that scrolls to the top won't get triggered so we have to handle it ourselves when
    // we navigate between topics.
    // We also use this process to scroll to specific questions if questionId= is supplied as a parameter.
    const [lastScrolledToTopForId, setLastScrolledToTopForId] = React.useState<string>('');
    React.useEffect(() => {
        if (props.model && `${props.model.id}.${scrollToStrengthId || ''}` !== lastScrolledToTopForId) {
            if (scrollToStrengthIdRef.current) {
                window.scrollTo(0, (scrollToStrengthIdRef.current.offsetTop + (scrollToStrengthIdRef.current.offsetParent && (scrollToStrengthIdRef.current.offsetParent as any).offsetTop ? (scrollToStrengthIdRef.current.offsetParent as any).offsetTop : 0)) - 60 /* NOTE have to add this for padding of the top bar, but would prefer to handle another way. */)
            } else {
                window.scrollTo(0, 0);
            }
            setLastScrolledToTopForId(`${props.model.id}.${scrollToStrengthId || ''}`);
        }
    }, [props.model, props.isLoading, props.loadingErrors, lastScrolledToTopForId, setLastScrolledToTopForId, scrollToStrengthId, scrollToStrengthIdRef.current]);

    const [filterBySchoolId, setFilterBySchoolId] = React.useState<string>('');
    const filteredStrengths = React.useMemo(() => {
        let ret = props.strengths.models;

        if (filterBySchoolId) {
            ret = ret.filter(item => {
                if (!props.model) {
                    return true;
                }

                let project: Project | undefined = props.model;
                if (item.projectId !== project.id) {
                    project = props.otherProjects.find(it => it.id === item.projectId);
                }

                if (!project) {
                    return true;
                }

                if (project.schoolId === filterBySchoolId) {
                    return true;
                }

                return false;
            });
        }

        return ret;
    }, [props.strengths, props.otherProjects, props.model, filterBySchoolId]);

    // Viewing of micro consultation videos.
    const [activeVideoId, setActiveVideoId] = React.useState<string | undefined>();
    const watchVideo = React.useCallback((videoId: string) => {
        setActiveVideoId(videoId);
    }, [setActiveVideoId]);
    const closeVideo = React.useCallback(() => setActiveVideoId(undefined), [setActiveVideoId]);

    // If we are for a particular school, get that school now so its easy to find.
    const school = React.useMemo(() => {
        if (!props.model) {
            return null;
        }

        if (!props.model.schoolId) {
            return null;
        }

        const schoolId = props.model.schoolId;
        const ret = props.schools.find(it => it.id === schoolId);
        return ret;
    }, [props.model, props.schools]);

    const {
        excellenceCount,
        strengthsCount,
    } = React.useMemo(() => ({
        excellenceCount: props.strengths.models.filter(it => it.isExcellence).length,
        strengthsCount: props.strengths.models.filter(it => !it.isExcellence).length,
    }), [props.strengths]);

    function countText(excellence: number, strengths: number) {
        if (excellence > 0 && strengths > 0) {
            return `${excellence} excellences and ${strengths} strengths`;
        } else if (excellence > 0) {
            return `${excellence} excellences`;
        } else {
            return `${strengths} strengths`;
        }
    }

    // render UI
    //

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.removingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    // If this is a school's screen and the school has not reached the right tier, hide the functionality.
    if (school && !school.isPotentialSchool && !isProductTierUnlocked(ProductTier.Snapshot, school.tier as ProductTier)) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>
                        Areas of strength <small className="text-muted">({countText(excellenceCount, strengthsCount)})</small>
                    </h1>
                </div>

                <ProductTierNotUnlockedAlert tier={ProductTier.Snapshot} />
            </div>
        );
    }

    const isShowingMultipleSchools = !!props.otherProjects.length;

    const isTrust = !props.model.schoolId ? true : false;


    return (
        <>
            <div className="main-container">
                <div className="main-heading">
                    <h1>
                        Areas of strength <small className="text-muted">({countText(excellenceCount, strengthsCount)}))</small>
                    </h1>
                </div>
                <ConditionalFragment showIf={isShowingMultipleSchools}>
                    <div className="toolbar-top">
                        <Input className="mb-1" type="select" value={filterBySchoolId} onChange={e => setFilterBySchoolId(e.currentTarget.value)}>
                            <option value="">(All schools within {props.trust && props.trust.name || 'trust'})</option>
                            {
                                props.schools.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Input>
                    </div>
                </ConditionalFragment>

                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.removingErrors]} />

                <StrengthsBarChartCard
                    model={props.model}
                    strengths={filteredStrengths}
                    questions={props.questions}
                    topics={props.topics}
                    sections={props.sections}
                    projects={[props.model, ...props.otherProjects]}
                    schools={props.schools}
                    filterBySectionOriginKey={undefined}
                    filterByTopicOriginKey={undefined}
                    awardTagId={awardTagId}
                />

                {
                    props.sections.map(sectionItem => {
                        return (
                            <div key={sectionItem.id} ref={scrollToStrengthId === sectionItem.originKey ? scrollToStrengthIdRef : null} className={scrollToStrengthId === sectionItem.originKey ? 'auto-scroll-selected' : ''}> {/* this added to allow scrolling to section from bar chart */}
                                {
                                    props.topics.filter(topicItem => topicItem.sectionId === sectionItem.id).map(item => {
                                        const myExcellences = filteredStrengths.filter(it => it.topicId === item.id && it.isExcellence);
                                        const myStrengths = filteredStrengths.filter(it => it.topicId === item.id && !it.isExcellence);

                                        // If we've removed all the strengths in this section then don't show the section.
                                        if (!myExcellences.length && !myStrengths.length) {
                                            return null;
                                        }

                                        let prioritySection = props.projectSections.find(it => it.sectionId === item.sectionId && it.improvementTarget > 0);
                                        let isPrioritySection = !!prioritySection ? true : false;
                                        let projectId = '';
                                        if (props.model) {
                                            projectId = props.model.id;
                                        }
                                        let myProjectTopic = props.projectTopics.find(it => it.projectId === projectId && it.topicId === item.id);
                                        let placeholderReplacementMode = props.placeholderReplacementMode;
                                        return (
                                            <Card key={item.id} className={isPrioritySection ? 'card-highlighted' : ''}>
                                                <CardHeader>
                                                    <Row>
                                                        <Col>
                                                            {/* only link the title if this is a school - there won't be a project to link to for the trust */}
                                                            <ConditionalFragment showIf={isTrust}>
                                                                {replacePlaceholders(sectionItem.name, placeholderReplacementMode) || ''} / {replacePlaceholders(item.name, placeholderReplacementMode)} <small className="text-muted">({countText(myExcellences.length, myStrengths.length)})</small>
                                                            </ConditionalFragment>
                                                            <ConditionalFragment showIf={!isTrust}>
                                                                <Link to={`${projectSettings.baseRoute}/topic/${myProjectTopic && myProjectTopic.id || ''}`}>
                                                                    {replacePlaceholders(sectionItem.name, placeholderReplacementMode) || ''} / {replacePlaceholders(item.name, placeholderReplacementMode)} <small className="text-muted">({countText(myExcellences.length, myStrengths.length)})</small>
                                                                </Link>
                                                            </ConditionalFragment>
                                                        </Col>
                                                        <ConditionalFragment showIf={isPrioritySection}>
                                                            <Col xs={12} md="auto">
                                                                <small className="text-secondary">
                                                                    <FontAwesomeIcon icon="key" />
                                                                    <> </>
                                                            This section is a priority for improvement
                                                        </small>
                                                            </Col>
                                                        </ConditionalFragment>
                                                    </Row>
                                                </CardHeader>

                                                <CardBody>
                                                    {/* Areas of excellence/Excellence statements */}
                                                    {
                                                        myExcellences.length ? (
                                                            <Card>
                                                                <CardHeader>
                                                                    Areas of excellence <small className="text-muted">({myExcellences.length} excellences)</small>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    {
                                                                        myExcellences.map(strengthItem => {
                                                                            let question = props.questions.find(it => it.id === strengthItem.questionId);
                                                                            let topic = props.topics.find(it => it.id === strengthItem.topicId);
                                                                            let sectionId = '';
                                                                            let topicId = '';
                                                                            if (topic) {
                                                                                sectionId = topic.sectionId;
                                                                                topicId = topic.id;
                                                                            }
                                                                            let questionaireId = '';
                                                                            let impactId = '';
                                                                            if (question) {
                                                                                questionaireId = question.questionaireId;
                                                                                impactId = question.impactId;
                                                                            }

                                                                            let sectionPermission = props.sectionPermissions.find(item => item.id === sectionId);
                                                                            let topicPermission = props.topicPermissions.find(item => item.id === topicId);
                                                                            let questionPermission = props.questionPermissions.find(item => item.id == strengthItem.questionId);
                                                                            let hasPermission = (sectionPermission && sectionPermission.hasPermission) && (topicPermission && topicPermission.hasPermission) && (!question || (questionPermission && questionPermission.hasPermission)) ? true : false;

                                                                            let project = props.model;
                                                                            if (project && project.id !== strengthItem.projectId) {
                                                                                project = props.otherProjects.find(it => it.id === strengthItem.projectId);
                                                                            }

                                                                            const myVideo = props.videos.find(it => !!question && it.id === question.videoId);
                                                                            const myConsultant = props.consultants.find(it => !!myVideo && it.id === myVideo.consultantId);
                                                                            const myVideoThumbnailBlob = props.videoThumbnailBlobs.find(it => !!myVideo && it.id === myVideo.thumbnailBlobId);
                                                                            const myConsultantPhotoBlob = props.consultantPhotoBlobs.find(it => !!myConsultant && it.id === myConsultant.photoBlobId);

                                                                            return (
                                                                                <div key={strengthItem.id} ref={scrollToStrengthId === strengthItem.id ? scrollToStrengthIdRef : null} className={scrollToStrengthId === strengthItem.id ? 'auto-scroll-selected' : ''}> {/* we use this div with a ref to help us scroll to the question when we're directing to a single strength when following links from the "View on strength register" buttons. */}
                                                                                    <StrengthUi
                                                                                        model={strengthItem}
                                                                                        changeModel={(changes) => props.strengths.change(strengthItem.id, changes)}
                                                                                        validate={(fieldsToCheck) => props.strengths.validateModel(strengthItem.id, fieldsToCheck)}
                                                                                        validationErrors={props.strengths.validationErrorsFor(strengthItem.id)}
                                                                                        save={props.save} isSaving={props.isSaving}
                                                                                        remove={() => props.remove(strengthItem.id)}
                                                                                        question={question}
                                                                                        topic={topic}
                                                                                        projectTopic={props.projectTopics.find(it => it.topicId === strengthItem.topicId && it.projectId === strengthItem.projectId)}
                                                                                        section={props.sections.find(it => it.id === sectionId)}
                                                                                        projectSection={props.projectSections.find(it => it.sectionId === sectionId && it.projectId === strengthItem.projectId)}
                                                                                        questionaire={props.questionaires.find(it => it.id === questionaireId)}
                                                                                        projectQuestionaire={props.projectQuestionaires.find(it => it.questionaireId === questionaireId && it.projectId === strengthItem.projectId)}
                                                                                        impact={props.impacts.find(it => it.id === impactId)}
                                                                                        questionResponses={props.questionResponses.filter(it => it.questionId === strengthItem.questionId && it.projectId === strengthItem.projectId)}
                                                                                        hasPermission={hasPermission}
                                                                                        showSchoolTag={isShowingMultipleSchools}
                                                                                        school={props.schools.find(it => project != null && it.id === project.schoolId)}
                                                                                        awardTagId={awardTagId}
                                                                                        video={myVideo} videoThumbnailBlob={myVideoThumbnailBlob}
                                                                                        consultant={myConsultant} consultantThumbnailBlob={myConsultantPhotoBlob}
                                                                                        watchVideo={() => watchVideo(myVideo && myVideo.id || '')}
                                                                                        placeholderReplacementMode={props.placeholderReplacementMode}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        ): null
                                                    }
                                                    
                                                    {/* Areas of strength */}
                                                    {
                                                        myStrengths.length ? (
                                                            <Card>
                                                                <CardHeader>
                                                                    Areas of strength <small className="text-muted">({myStrengths.length} strengths)</small>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    {
                                                                        myStrengths.map(strengthItem => {
                                                                            let question = props.questions.find(it => it.id === strengthItem.questionId);
                                                                            let topic = props.topics.find(it => it.id === strengthItem.topicId);
                                                                            let sectionId = '';
                                                                            let topicId = '';
                                                                            if (topic) {
                                                                                sectionId = topic.sectionId;
                                                                                topicId = topic.id;
                                                                            }
                                                                            let questionaireId = '';
                                                                            let impactId = '';
                                                                            if (question) {
                                                                                questionaireId = question.questionaireId;
                                                                                impactId = question.impactId;
                                                                            }

                                                                            let sectionPermission = props.sectionPermissions.find(item => item.id === sectionId);
                                                                            let topicPermission = props.topicPermissions.find(item => item.id === topicId);
                                                                            let questionPermission = props.questionPermissions.find(item => item.id == strengthItem.questionId);
                                                                            let hasPermission = (sectionPermission && sectionPermission.hasPermission) && (topicPermission && topicPermission.hasPermission) && (!question || (questionPermission && questionPermission.hasPermission)) ? true : false;

                                                                            let project = props.model;
                                                                            if (project && project.id !== strengthItem.projectId) {
                                                                                project = props.otherProjects.find(it => it.id === strengthItem.projectId);
                                                                            }

                                                                            const myVideo = props.videos.find(it => !!question && it.id === question.videoId);
                                                                            const myConsultant = props.consultants.find(it => !!myVideo && it.id === myVideo.consultantId);
                                                                            const myVideoThumbnailBlob = props.videoThumbnailBlobs.find(it => !!myVideo && it.id === myVideo.thumbnailBlobId);
                                                                            const myConsultantPhotoBlob = props.consultantPhotoBlobs.find(it => !!myConsultant && it.id === myConsultant.photoBlobId);

                                                                            return (
                                                                                <div key={strengthItem.id} ref={scrollToStrengthId === strengthItem.id ? scrollToStrengthIdRef : null} className={scrollToStrengthId === strengthItem.id ? 'auto-scroll-selected' : ''}> {/* we use this div with a ref to help us scroll to the question when we're directing to a single strength when following links from the "View on strength register" buttons. */}
                                                                                    <StrengthUi
                                                                                        model={strengthItem}
                                                                                        changeModel={(changes) => props.strengths.change(strengthItem.id, changes)}
                                                                                        validate={(fieldsToCheck) => props.strengths.validateModel(strengthItem.id, fieldsToCheck)}
                                                                                        validationErrors={props.strengths.validationErrorsFor(strengthItem.id)}
                                                                                        save={props.save} isSaving={props.isSaving}
                                                                                        remove={() => props.remove(strengthItem.id)}
                                                                                        question={question}
                                                                                        topic={topic}
                                                                                        projectTopic={props.projectTopics.find(it => it.topicId === strengthItem.topicId && it.projectId === strengthItem.projectId)}
                                                                                        section={props.sections.find(it => it.id === sectionId)}
                                                                                        projectSection={props.projectSections.find(it => it.sectionId === sectionId && it.projectId === strengthItem.projectId)}
                                                                                        questionaire={props.questionaires.find(it => it.id === questionaireId)}
                                                                                        projectQuestionaire={props.projectQuestionaires.find(it => it.questionaireId === questionaireId && it.projectId === strengthItem.projectId)}
                                                                                        impact={props.impacts.find(it => it.id === impactId)}
                                                                                        questionResponses={props.questionResponses.filter(it => it.questionId === strengthItem.questionId && it.projectId === strengthItem.projectId)}
                                                                                        hasPermission={hasPermission}
                                                                                        showSchoolTag={isShowingMultipleSchools}
                                                                                        school={props.schools.find(it => project != null && it.id === project.schoolId)}
                                                                                        awardTagId={awardTagId}
                                                                                        video={myVideo} videoThumbnailBlob={myVideoThumbnailBlob}
                                                                                        consultant={myConsultant} consultantThumbnailBlob={myConsultantPhotoBlob}
                                                                                        watchVideo={() => watchVideo(myVideo && myVideo.id || '')}
                                                                                        placeholderReplacementMode={props.placeholderReplacementMode}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        ) : null
                                                    }
                                                </CardBody>
                                            </Card>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                )}
            </div>
            
            <ConditionalFragment showIf={!!activeVideoId}>
                <WatchVideoModal
                    id={activeVideoId || ''}
                    toggle={() => closeVideo()}
                    baseUrl={`${projectSettings.baseRoute}/microConsultations`}
                />
            </ConditionalFragment>
        </>
    );
};

export const StrengthReport = withContainer(StrengthReportContainer)(StrengthReportUi);
