import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { useApiService } from '../../api/useApiService';
import { Trust } from '../../api/models/Trust';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Project } from '../../api/models/Project';
import { ConditionalFragment } from 'react-conditionalfragment';
import moment, { Moment } from 'moment';
import { Button, Carousel, CarouselControl, CarouselItem, Col, Row } from 'reactstrap';
import { Video } from '../../api/models/Video';
import { Consultant } from '../../api/models/Consultant';
import { BlobUrl } from '../../api/models/BlobUrl';
import { VideoTile } from '../videos/VideoTile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { LinkContainer } from 'react-router-bootstrap';
import { WatchVideoModal } from '../videos/WatchVideoModal';
import { Subscription } from '../../api/models/Subscription';
import { User } from '../../api/models/User';
import { AwardTag } from '../../api/models/AwardTag';
import { useNavigateWhenReady } from '../shared/hooks/useNavigateWhenReady';
import { PrivateContainer } from '../shared/privateRoute/PrivateContainer';

export interface OverviewProps {
    subscriptionId: string,
}

/**
 * Overview of a trust.
 * @param props
 */
export const _Overview = (props: OverviewProps) => {
    const { subscriptionId } = props;

    const api = useApiService();
    const loadViewModel = api.trusts.viewModels.overview();
    const currentProjectFor = api.projects.viewModels.currentProjectFor();

    // Load the main model data.
    //
    const [model, setModel] = React.useState<Trust | undefined>();
    const [videos, setVideos] = React.useState<Array<Video>>([]);
    const [consultants, setConsultants] = React.useState<Array<Consultant>>([]);
    const [videoThumbnailBlobs, setVideoThumbnailBlobs] = React.useState<Array<BlobUrl>>([]);
    const [consultantPhotoBlobs, setConsultantPhotoBlobs] = React.useState<Array<BlobUrl>>([]);
    const [schools, setSchools] = React.useState<Array<School>>([]);
    const [user, setUser] = React.useState<User | undefined>();
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(undefined, subscriptionId);
        setSchools(result.schools);
        setVideos(result.videos);
        setConsultants(result.consultants);
        setVideoThumbnailBlobs(result.videoThumbnailBlobs);
        setConsultantPhotoBlobs(result.consultantPhotoBlobs);
        setUser(result.user);
        setAwardTags(result.awardTags);
        setSubscription(result.subscription);
        setModel(result.model);
    }, [loadViewModel, setModel, setSchools, subscriptionId, setVideos, setConsultants, setVideoThumbnailBlobs, setConsultantPhotoBlobs, setSubscription, setUser, setAwardTags]);
    React.useEffect(() => {
        if (!subscriptionId || isLoading) {
            return;
        }

        if (model && model.subscriptionId === subscriptionId) {
            return;
        }

        load();
    }, [subscriptionId, load, model, isLoading]);

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const retrySeconds = 60; // number of seconds to retry before we accept we are not getting the required project
    const [selfEvaluationProject, setSelfEvaluationProject] = React.useState<Project | null | undefined>();
    const [firstRetryTime, setFirstRetryTime] = React.useState<Moment | undefined>();
    const retryExpireTime = React.useMemo(() => {
        if (!!firstRetryTime) {
            return firstRetryTime.add(retrySeconds, 'seconds');
        }
        return;
    }, [firstRetryTime]);

    const [shouldRetry, setShouldRetry] = React.useState<boolean>(true);
    const scheduleRetry = React.useCallback(() => {
        if (!firstRetryTime) {
            setFirstRetryTime(moment());
        }
        setTimeout(() => {
            setShouldRetry(true);
        }, 1000);
    }, [setShouldRetry, setFirstRetryTime, firstRetryTime]);

    const [loadProject, { isExecuting: isLoadingPoject, errors: loadProjectErrors }] = useAsyncCallback(async () => {
        if (!model) {
            scheduleRetry();
            return;
        }

        try {
            let selfEvalulation = await currentProjectFor({ trustId: model.id || '', questionSetName: 'School self evaluation', createIfMissing: false });

            if (selfEvalulation && !selfEvalulation.isMot) {
                setSelfEvaluationProject(selfEvalulation);
            } else {
                scheduleRetry();
            }
        } catch {
            // Ignored
        }
    }, [model, scheduleRetry, setSelfEvaluationProject]);

    React.useEffect(() => {
        if (!subscriptionId || isLoadingPoject) {
            return;
        }

        if ((model && model.subscriptionId === subscriptionId) && selfEvaluationProject && selfEvaluationProject.trustId === model.id) {
            return;
        }

        // Special handling of retries to cope with the fact the projects may be being generated (triggered by sidebar)
        // at the same time we are trying to load them, so we wait and try again.
        // Only allow the retry for a set number of seconds and then let it fall through with no report
        if (!!firstRetryTime && (!!retryExpireTime && moment() < retryExpireTime)) {
            setShouldRetry(false);
        }
        if (model && model.subscriptionId === subscriptionId && !shouldRetry) {
            return;
        }

        setShouldRetry(false);
        loadProject();
    }, [subscriptionId, loadProject, model, setShouldRetry, shouldRetry, selfEvaluationProject, firstRetryTime, retryExpireTime, isLoadingPoject]);

    // Carousel management.
    const [carouselIndex, setCarouselIndex] = React.useState<number>(0);
    const carouselNext = React.useCallback(() => {
        setCarouselIndex(prevState => {
            let ret = prevState + 1;
            if (ret >= videos.length) {
                ret = 0;
            }

            return ret;
        });
    }, [setCarouselIndex, videos]);
    const carouselPrevious = React.useCallback(() => {
        setCarouselIndex(prevState => {
            let ret = prevState - 1;
            if (ret <= 0) {
                ret = videos.length - 1;
            }

            return ret;
        });
    }, [setCarouselIndex, videos]);

    const [activeVideoId, setActiveVideoId] = React.useState<string | undefined>();
    const watchVideo = React.useCallback((videoId: string) => {
        setActiveVideoId(videoId);
    }, [setActiveVideoId]);
    const closeVideo = React.useCallback(() => setActiveVideoId(undefined), [setActiveVideoId]);

    // Navigate to a project URLs but making sure we wait until the projects are ready before doing so.
    const tagNavigateMap = React.useMemo(() => {
        let ret: { [key: string]: string } = {};
        for (const tag of awardTags) {
            ret[`${tag.id}::review`] = `/manage/award/${tag.id}/${selfEvaluationProject && selfEvaluationProject.id || ''}/dashboard`;
        }
        return ret;
    }, [awardTags, model, selfEvaluationProject]);

    const [navigateWhenReady, { isNavigationPending: navigateIsPending }] = useNavigateWhenReady(
        !!selfEvaluationProject,
        {
            'review': `/manage/selfEvaluation/${selfEvaluationProject && selfEvaluationProject.id || ''}/dashboard`,
            'update': `/manage/selfEvaluationTargets/${selfEvaluationProject && selfEvaluationProject.id || ''}`,

            ...tagNavigateMap,
        }
    );

    if (!model) {
        return (<LoadingIndicator />);
    }

    return (
        <>
            <div className="main-container overview">
                <AlertOnErrors errors={[loadingErrors, loadProjectErrors]} />

                <Row>
                    <Col xs={12} md={6}>
                        <div className="overview-logo">
                        </div>

                        <div>
                            <h3>
                                Schools
                            </h3>

                            {
                                schools.map(item => (
                                    <LinkContainer key={item.id} to={`/school/${item.id}`}>
                                        <Button color="secondary" className="overview-school-button">
                                            {item.name}
                                        </Button>
                                    </LinkContainer>
                                ))
                            }

                            <PrivateContainer role="Administration">
                                <div>
                                    <LinkContainer to="/manage/schools/add">
                                        <Button color="secondary" className="overview-school-button" outline={!!schools.length}>
                                            <FontAwesomeIcon icon="plus" />
                                            <> </>
                                            {
                                                !schools.length ? 'Add your first school'
                                                    : 'Add another school'
                                            }
                                        </Button>
                                    </LinkContainer>
                                </div>
                            </PrivateContainer>
                        </div>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature}>
                            <div className="mt-4">
                                <ConditionalFragment showIf={!((!!subscription && subscription.isLimitedToAwardTags) || (!!user && user.isLimitedToAwardTags))} >
                                    <h3>Review</h3>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('review')}>
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon={navigateIsPending('review') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('review')} />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            Review school self evaluation
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('update')}>
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon={navigateIsPending('update') ? 'spinner' : 'edit'} spin={navigateIsPending('update')} />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            Set trust-wide targets for schools
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ConditionalFragment>

                                <ConditionalFragment showIf={(!!subscription && subscription.isLimitedToAwardTags) || (!!user && user.isLimitedToAwardTags)}>
                                    <h3>Review</h3>
                                    <Row>
                                        {
                                            awardTags.map(tag => (
                                                <Col key={tag.id} xs={12} lg={6}>
                                                    <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady(`${tag.id}::review`)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending(`${tag.id}::review`) ? 'spinner' : 'solar-panel'} spin={navigateIsPending(`${tag.id}::review`)} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    Review {tag.name} dashboard
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </ConditionalFragment>

                                <h3>Improve</h3>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <LinkContainer to="/manage/improvementPlanner">
                                            <Button color="primary" outline className="overview-navigation-button">
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon="calendar-plus" />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            Plan trust-wide improvements
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </LinkContainer>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <LinkContainer to="/manage/schoolImprovementPlanner">
                                            <Button color="primary" outline className="overview-navigation-button">
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon="school" />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            Review improvement plans from your schools
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </LinkContainer>
                                    </Col>
                                </Row>

                                <ConditionalFragment showIf={!((!!subscription && subscription.isLimitedToAwardTags) || (!!user && user.isLimitedToAwardTags))}>
                                    <h3>Grow</h3>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <LinkContainer to="/manage/potentialSchools">
                                                <Button color="primary" outline className="overview-navigation-button">
                                                    <Row>
                                                        <Col xs="auto">
                                                            <FontAwesomeIcon icon="seedling" />
                                                        </Col>
                                                        <Col>
                                                            <div className="overview-navigation-button-text">
                                                                Perform due diligence on potential schools to grow your trust
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Button>
                                            </LinkContainer>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            {/* empty */}
                                        </Col>
                                    </Row>
                                </ConditionalFragment>
                            </div>
                        </ConditionalFragment>
                    </Col>
                    <Col xs={12} md={6}>
                        <Carousel activeIndex={carouselIndex}
                            ride="carousel"
                            next={carouselNext}
                            previous={carouselPrevious}>
                            {/*<CarouselIndicators items={videos} activeIndex={carouselIndex} onClickHandler={setCarouselIndex} />*/}

                            {
                                videos.map(video => {
                                    const consultant = consultants.find(it => it.id === video.consultantId);

                                    return (
                                        <CarouselItem key={video.id}>
                                                <VideoTile
                                                model={video}
                                                consultant={consultant}
                                                thumbnailBlob={videoThumbnailBlobs.find(it => it.id === video.thumbnailBlobId)}
                                                consultantPhotoBlob={consultantPhotoBlobs.find(it => !!consultant && it.id === consultant.photoBlobId)}
                                                watchVideo={() => watchVideo(video.id)}
                                                    />
                                        </CarouselItem>
                                        );
                                 })
                            }

                            <ConditionalFragment showIf={videos.length > 1}>
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={carouselPrevious} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={carouselNext} />
                            </ConditionalFragment>
                        </Carousel>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature}>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs="auto">
                                    <LinkContainer to="/manage/microConsultations">
                                        <Button color="secondary">
                                            Browse the whole micro-consultations library
                                            <> </>
                                            <FontAwesomeIcon icon="caret-right" />
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                        </ConditionalFragment>
                    </Col>
                </Row>
            </div>

            <ConditionalFragment showIf={!!activeVideoId}>
                <WatchVideoModal
                    id={activeVideoId || ''}
                    toggle={() => closeVideo()}
                    baseUrl={`/manage/microConsultations`}
                />
            </ConditionalFragment>
        </>
    );
};

export const Overview = connect(
    (state: AppState) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_Overview);
