import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardHeader, CardBody, Col, Collapse, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Section } from '../../api/models/Section';
import { Topic } from '../../api/models/Topic';

export interface EvidenceTopicModalProps {
    updateTopicOriginKeyJson: (selectedTopicOriginKeys: Array<string>) => void,
    sections: Array<Section>,
    topics: Array<Topic>,
    cancel: () => void,
    preSelectedTopics: string | undefined,
}

export const EvidenceTopicModal = (props: EvidenceTopicModalProps) => {
    const {
        sections,
        topics,
        updateTopicOriginKeyJson,
        cancel,
        preSelectedTopics
    } = props;

    const [selectedTopics, setSelectedTopics] = React.useState<Array<string>>(JSON.parse(preSelectedTopics ?? "[]"))

    // Swap the topics selected status
    const toggleTopicIsSelected = React.useCallback((currentTopicOriginKey: string) => {
        if (selectedTopics?.find(item => item === currentTopicOriginKey)) {
            // Means the item is already open so we should close it
            setSelectedTopics(selectedTopics.filter(item => item !== currentTopicOriginKey));
        } else {
            // Means the item isnt open so we need to set it to be
            setSelectedTopics(selectedTopics?.concat([currentTopicOriginKey]))
        }
    }, [selectedTopics, setSelectedTopics]);

    const isTopicSelected = React.useCallback((currentTopicOriginKey: string) => {
        if (selectedTopics?.find(item => item === currentTopicOriginKey)) {
            return true;
        } else {
            return false;
        }
    }, [selectedTopics]);

    const [openSections, setOpenSections] = React.useState<Array<string>>([])

    // Swap the sections open status
    const toggleSectionIsOpen = React.useCallback((currentSectionOriginKey: string) => {
        if (openSections?.find(item => item === currentSectionOriginKey)) {
            // Means the item is already open so we should close it
            setOpenSections(openSections.filter(item => item !== currentSectionOriginKey));
        } else {
            // Means the item isnt open so we need to set it to be
            setOpenSections(openSections?.concat([currentSectionOriginKey]))
        }
    }, [openSections, setOpenSections]);

    const isSectionOpen = React.useCallback((currentSectionOriginKey: string) => {
        if (openSections?.find(item => item === currentSectionOriginKey)) {
            return true;
        } else {
            return false;
        }
    }, [openSections]);

    return (
        <Modal isOpen={true}>
            <ModalHeader>
                <h3>Select all topics for this evidence</h3>
            </ModalHeader>
            <ModalBody>
                {sections.map(section => { 
                    return (

                        <Card>
                            < CardHeader >
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleSectionIsOpen(section.originKey)}>
                                        <div style={{ textDecoration: 'underline', display: 'inline-block' }}>
                                            {section.name}
                                        </div>
                                        <> </>
                                        <FontAwesomeIcon icon={isSectionOpen(section.originKey) ? 'caret-up' : 'caret-down'} />
                                    </div>
                            </CardHeader>
                            <Collapse isOpen={isSectionOpen(section.originKey)}>
                                <CardBody>
                                    <hr/>
                                    {topics.filter(item => item.sectionId === section.id).map(topic => {
                                        return (
                                            <>
                                            <Row>
                                                <Button color="primary" outline={!isTopicSelected(topic.originKey)} size="sm" style={{ maxHeight: '35px' }}
                                                    onClick={() => {
                                                        if (!toggleTopicIsSelected) {
                                                            return;
                                                        }
                                                        toggleTopicIsSelected(topic.originKey);
                                                    }}>
                                                    {
                                                        isTopicSelected(topic.originKey) ? (<>Selected</>) : (<>Select</>)
                                                    }
                                                </Button>
                                                <Col>
                                                    <p> {topic.name} </p>
                                                </Col>
                                                </Row>
                                                    <hr/>
                                            </>
                                           
                                        )
                                    })}
                                </CardBody>
                            </Collapse>
                        </Card>
                    )
                })}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => updateTopicOriginKeyJson(selectedTopics)}>
                    Save
                </Button>
                <Button color="primary" outline onClick={cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
        
        );
};