import * as React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { SchoolsDropdownContainer, SchoolsDropdownContainerProps, SchoolsDropdownUiProps } from './SchoolsDropdownContainer';
import { withContainer } from 'react-withcontainer';
import { PrivateContainer } from '../shared/privateRoute/PrivateContainer';
import { ConditionalFragment } from 'react-conditionalfragment';
import { isNullOrUndefined } from 'util';

/**
 * Dropdown that lets the user quickly switch between recent projects.
 * @param props
 */
export const SchoolsDropdownUi = (props: SchoolsDropdownUiProps) => {
    const maxItemsToShow = 6; // Maximum number of recent proejcts to show in each category before showing a "See all" option.

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const toggle = React.useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, [setIsOpen])

    const { model, currentSchool, ...rest } = props;

    const schools = React.useMemo(() => {
        if (!model) {
            return [];
        }

        return model.filter(item => !item.isPotentialSchool);
    }, [model]);
    const potentialSchools = React.useMemo(() => {
        if (!model) {
            return [];
        }

        return model.filter(item => item.isPotentialSchool);
    }, [model]);

    if (isNullOrUndefined(props.showOnlyOneSchool)) {
        return (<></>);
    }

    if (props.showNothing) {
        return (<></>);
    } else if (props.showOnlyOneSchool) {
        let oneSchool = schools.length > 0 ? schools[0] :
            potentialSchools.length > 0 ? potentialSchools[0] :
                null;

        return (
            <Link to={`/school/${oneSchool && oneSchool.id || ''}`} className="btn btn-secondary">
                {oneSchool && oneSchool.schoolName || 'School'}
            </Link>
        );
    } else {
        return (
            <Dropdown isOpen={isOpen} toggle={toggle} className="project-dropdown" >
                <DropdownToggle caret>
                    {
                        currentSchool ? (
                            <>
                                {currentSchool.schoolName}
                            </>
                        ) : (
                                <>
                                    Schools
                            </>
                            )
                    }
                </DropdownToggle>
                <DropdownMenu>
                    <ConditionalFragment showIf={!!props.trust && !props.isSingleSchoolSubscription}>
                        <DropdownItem header>
                            Trust
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink to={`/manage`} tag={Link}>
                                {props.trust && props.trust.name || 'Trust'}
                            </NavLink>
                        </DropdownItem>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!props.isSingleSchoolSubscription}>
                        <DropdownItem header>
                            Schools
                    </DropdownItem>
                    </ConditionalFragment>
                    {
                        schools.length ? (
                            <>
                                {
                                    schools.map((item, index) => {
                                        // Only show the top n items.
                                        if (index >= maxItemsToShow) {
                                            return null;
                                        }

                                        return (
                                            <DropdownItem key={item.id}>
                                                <NavLink className="nav-link" to={`/school/${item.id}`} tag={Link}>
                                                    {item.schoolName || 'School'}
                                                </NavLink>
                                            </DropdownItem>
                                        );
                                    })
                                }

                                <ConditionalFragment showIf={schools.length > maxItemsToShow}>
                                    <DropdownItem>
                                        <NavLink className="nav-link" to={`/manage/schools`} tag={Link}>
                                            <strong>
                                                See all {schools.length} schools...
                                                </strong>
                                        </NavLink>
                                    </DropdownItem>
                                </ConditionalFragment>
                            </>
                        ) : (
                                <DropdownItem disabled={true}>
                                    <span className="text-muted">
                                        You have not added any schools
                                </span>
                                </DropdownItem>
                            )
                    }

                    <ConditionalFragment showIf={!!potentialSchools.length}>
                        <DropdownItem header>
                            Potential schools
                    </DropdownItem>
                        {
                            potentialSchools.length ? (
                                <>
                                    {
                                        potentialSchools.map((item, index) => {
                                            // Only show the top n items.
                                            if (index >= maxItemsToShow) {
                                                return null;
                                            }

                                            return (
                                                <DropdownItem key={item.id}>
                                                    <NavLink className="nav-link" to={`/school/${item.id}`} tag={Link}>
                                                        {item.schoolName || 'School'}
                                                    </NavLink>
                                                </DropdownItem>
                                            );
                                        })
                                    }

                                    <ConditionalFragment showIf={potentialSchools.length > maxItemsToShow}>
                                        <DropdownItem>
                                            <NavLink className="nav-link" to={`/manage/potentialSchools`} tag={Link}>
                                                <strong>
                                                    See all {potentialSchools.length} schools...
                                            </strong>
                                            </NavLink>
                                        </DropdownItem>
                                    </ConditionalFragment>
                                </>
                            ) : null
                        }
                    </ConditionalFragment>

                    <PrivateContainer role="Administration">
                        <ConditionalFragment showIf={!props.isSingleSchoolSubscription}>
                            <DropdownItem divider />
                            <DropdownItem>
                                <LinkContainer to="/manage/schools/add">
                                    <Button color="primary">
                                        <FontAwesomeIcon icon="plus" /> Add school
                                </Button>
                                </LinkContainer>
                            </DropdownItem>
                        </ConditionalFragment>
                    </PrivateContainer>
                </DropdownMenu>
            </Dropdown>
        );
    }
};

export const SchoolsDropdown = withContainer<SchoolsDropdownContainerProps>(SchoolsDropdownContainer)(SchoolsDropdownUi);
