import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { SpidergraphCard } from '../../projects/dashboard/charts/SpidergraphCard';
import { SchoolWithoutTrustComparisonTable } from '../../projects/dashboard/tables/SchoolWithoutTrustComparisonTable';
import { TrustComparisonTable } from '../../projects/dashboard/tables/TrustComparisonTable';
import { ProjectAnalysisResult } from '../../../api/models/ProjectAnalaysisResult';
import { ProjectTopicUi } from './ProjectTopicUi';
import { ProjectSectionOverviewOpinionUi } from './ProjectSectionOverviewOpinionUi';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ReportProps } from '../reportGenerator/ReportProps';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';


export interface ProjectSectionUiProps extends ReportProps {
    projectSectionId: string
}

/**
 * Question breakdown for a project section.
 */
export const ProjectSectionUi = (props: ProjectSectionUiProps) => {
    // Find the appropriate items we want to work with.
    let [projectSection, section, results, projectTopics] = React.useMemo(() => {
        let ps = props.projectSections.models.find(item => item.id === props.projectSectionId);
        let sectionId = '';
        if (ps) {
            sectionId = ps.sectionId;
        }

        let s = props.sections.find(item => item.id === sectionId);

        let r = props.resultsBySection.find(item => item.projectSectionId === props.projectSectionId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        let childTopics = props.topics.filter(item => item.sectionId === sectionId);
        let pt = props.projectTopics.models.filter(item => childTopics.find(it => it.id === item.topicId));

        return [ps, s, rr, pt];
    }, [props.projectSections, props.sections, props.projectSectionId, props.topics, props.projectTopics, props.resultsBySection]);

    if (!props.model) {
        return (<></>);
    }

    if (!projectSection) {
        return (<></>);
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <div>
            <h4>{section && replacePlaceholders(section.name, placeholderReplacementMode) || 'Section'}</h4>
            {
                section && section.description ? (
                    <>
                        <h5>Section description</h5>
                        <p>
                            <PlainTextWithBrs text={replacePlaceholders(section.description, placeholderReplacementMode)} />
                        </p>
                    </>
                    ): null
            }
            <h5>Section summary</h5>
            <Row>
                <Col sm={8}>
                    <SpidergraphCard model={props.model} results={results} filterBySectionOriginKey={section && section.originKey || ''} filterByTopicOriginKey={undefined} chartHeight={400} isForSingleSchool={props.isForSingleSchool} awardTag={props.awardTag} placeholderReplacementMode={'general'} />
                </Col>
                <Col sm={4}>
                    {
                        props.results.length > 1 ? (
                            <TrustComparisonTable model={props.model} results={results} filterBySectionOriginKey={section && section.originKey || ''} filterByTopicOriginKey={undefined} isForSingleSchool={props.isForSingleSchool} awardTag={props.awardTag} placeholderReplacementMode={props.placeholderReplacementMode} />
                        ) : (
                                <SchoolWithoutTrustComparisonTable model={props.model} results={results} filterBySectionOriginKey={section && section.originKey || ''} filterByTopicOriginKey={undefined} awardTag={props.awardTag} placeholderReplacementMode={props.placeholderReplacementMode} />
                            )
                    }
                </Col>
            </Row>
            
            {
                projectTopics.length ? (
                    <div>
                        {
                            projectTopics.map(item => (
                                <ProjectTopicUi key={item.id} projectTopicId={item.id} {...props} />
                                ))
                        }
                    </div>
                    ): null
            }

            <ProjectSectionOverviewOpinionUi {...props} projectSection={projectSection} />
        </div>        
    );
};
