import * as React from 'react';
import { LayoutProps } from "./Layout";
import { ContainerComponentProps } from "react-withcontainer";
import { connect } from "react-redux";
import { Identity } from '../../../store/user';


export interface LayoutContainerProps extends ContainerComponentProps<LayoutProps> {
    subscriptionId: string,
    appRoutes: Array<any>,
    children: any,

    location: {
        pathname: string,
    }
}

export const _LayoutContainer = (props: LayoutContainerProps) => {
    const { component, ...rest } = props;

    const Component = component;

    return (
        <Component {...rest} />
    );
}

interface State {
    user?: User
}

interface User {
    identity?: Identity
}

export const LayoutContainer = connect(
    (state: State) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_LayoutContainer);