import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { Guid } from 'guid-string';
import { Project } from '../../../../api/models/Project';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { isNullOrUndefined } from 'util';
import { getGradeString } from '../../../../utilities/getGradeString';
import { getGradeComparisonColor } from '../../../../utilities/getGradeComparisonColor';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';

export interface TrustComparisonTablePdfUiProps {
    model: Project | undefined,
    results: Array<ProjectAnalysisResult>,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    isForSingleSchool: boolean,
    placeholderReplacementMode: PlaceholderReplacementMode,
}


/**
 * Trust comparison table for pdf.
 */
export const TrustComparisonTablePdfUi = (props: TrustComparisonTablePdfUiProps) => {
    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index as well.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    if (!props.results || props.results.length < 2) {
        return (<></>);
    }

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.results[0];
    let trustAverages = props.results[1];

    return (
        <View style={Styles.table}>
            <View style={Styles.thead}>
                <View style={Styles.trHead}>
                    {
                        isForQuestions ? (
                            <View style={{ ...Styles.th, width: '10%' }}><Text>{' '}</Text></View>
                        ) : null
                    }
                    <View style={{ ...Styles.th, width: (isForQuestions ? '30%' : '40%') }}><Text>{' '}</Text></View>
                    <View style={{ ...Styles.th, width: '25%', textAlign: 'right' }}>
                        <Text>
                            {
                                thisProject.school ? thisProject.school.name
                                    : props.model && Guid.isEmpty(props.model.schoolId) ? `${thisProject.trust && thisProject.trust.name || 'Trust'}`
                                        : thisProject.trust ? thisProject.trust.name
                                            : 'Project'
                            }
                        </Text>
                    </View>
                    <View style={{ ...Styles.th, width: '25%', textAlign: 'right' }}>
                        <Text>
                            {
                                props.model && Guid.isEmpty(props.model.schoolId) ? 'School averages' // Special case when showing a project for the trust itself
                                    : props.isForSingleSchool? 'School targets'
                                    : trustAverages.trust ? 'Trust targets'
                                        : 'Trust tragets'
                            }
                        </Text>
                    </View>
                    <View style={{ ...Styles.th, width: '10%', textAlign: 'right' }}><Text>+/-</Text></View>
                </View>
            </View>
            <View style={Styles.tbody}>
                {
                    thisProject.results.map(item => {
                        let matchingRecord = trustAverages.results.find(it => it.originKey === item.originKey);
                        let trustScore = matchingRecord ? matchingRecord.percentageQuestionScore : null;
                        let difference = isNullOrUndefined(trustScore) ? null : (item.percentageQuestionScore - trustScore);
                        let differenceColor = getGradeComparisonColor(item.percentageQuestionScore, trustScore);

                        return (
                            <View key={item.originKey} style={Styles.tr}>
                                {
                                    isForQuestions ? (
                                        <View style={{ ...Styles.td, width: '10%' }}><Text>{item.questionNumber}</Text></View>
                                    ) : null
                                }
                                <View style={{ ...Styles.th, width: (isForQuestions ? '30%' : '40%') }}>
                                    <Text>{replacePlaceholders(item.name, props.placeholderReplacementMode)}</Text>
                                </View>
                                <View style={{ ...Styles.th, width: '25%', textAlign: 'right' }}>
                                    <Text><Text style={Styles.strong}>{getGradeString(item.percentageQuestionScore)}</Text></Text>
                                </View>
                                <View style={{ ...Styles.th, width: '25%', textAlign: 'right' }}>
                                    <Text>{isNullOrUndefined(trustScore) ? '' : getGradeString(trustScore)}</Text>
                                </View>
                                <View style={{ ...Styles.th, width: '10%', textAlign: 'right' }}>
                                    <Text style={{ color: isNullOrUndefined(difference) ? '' : differenceColor === 'success' ? 'green' : differenceColor === 'warning' ? 'orange' : differenceColor === 'danger'? 'red': ''}}>
                                        {isNullOrUndefined(difference) || difference < 0 ? '' : '+'}{isNullOrUndefined(difference) ? '' : (difference / 10.0).toFixed(1)}
                                    </Text>
                                </View>
                            </View>
                        );
                    })}
            </View>
        </View>
    );
};

