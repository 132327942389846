import * as React from 'react';
import { SpidergraphCard } from '../../projects/dashboard/charts/SpidergraphCard';
import { TrustComparisonTable } from '../../projects/dashboard/tables/TrustComparisonTable';
import { SchoolWithoutTrustComparisonTable } from '../../projects/dashboard/tables/SchoolWithoutTrustComparisonTable';
import { ReportProps } from '../reportGenerator/ReportProps';
import { PlaceholderReplacementMode } from '../../../../src/utilities/replacePlaceholders';

/**
 * Overview section of the executive summary that gives the top level breakdown.
 */
export const OverviewUi = (props: ReportProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <h3>Overall results</h3>
            <SpidergraphCard model={props.model} results={props.results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={undefined} isForSingleSchool={props.isForSingleSchool} isSelfEvaluation={props.projectSettings.isSelfEvaluation} awardTag={props.awardTag} placeholderReplacementMode={'general'} />

            {
                props.results.length > 1 ? (
                    <TrustComparisonTable model={props.model} results={props.results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={undefined} isForSingleSchool={props.isForSingleSchool} awardTag={props.awardTag} placeholderReplacementMode={props.placeholderReplacementMode} />
                ) : (
                        <SchoolWithoutTrustComparisonTable model={props.model} results={props.results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={undefined} awardTag={props.awardTag} placeholderReplacementMode={props.placeholderReplacementMode} />
                    )
            }
        </div>        
    );
};
