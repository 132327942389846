import * as React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { IntroductionPdfUi } from './IntroductionPdfUi';
import { OverviewPdfUi } from './OverviewPdfUi';
import { RisksPdfUi } from './RisksPdfUi';
import { ConclusionPdfUi } from './ConclusionPdfUi';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReccomendationsPdfUi } from './ReccomendationsPdfUi';
import { StrengthsPdfUi } from './StrengthsPdfUi';
import { BreakdownPdfUi } from './BreakdownPdfUi';
import { ImageEnumerator } from './ImageEnumerator';
import { ReportProps } from '../../reportGenerator/ReportProps';

export interface ExecutiveSummaryPdfProps extends ReportProps {
    chartImages: Array<string>
}

/**
 * Executive summary screen for a project, this gives a long report-like breakdown of the project results.
 */
export const ExecutiveSummaryPdf = (props: ExecutiveSummaryPdfProps) => {
    const { projectSettings, reportSettings } = props;
    const imageEnumerator = new ImageEnumerator(props.chartImages);

    if (!props.model) {
        return (
            <Document>
                <Page size="A4">
                    <Text>PDF could not be generated.</Text>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page size="A4" style={{ ...Styles.page, paddingTop: 20, paddingBottom: 20 }}>
                <View style={Styles.body}>
                    <View>
                        <Text style={Styles.h1}>
                            <Text>
                                {
                                    props.school ? props.school.name
                                        : props.trust ? props.trust.name
                                            : ''
                                }
                            </Text>
                        </Text>
                        <Text style={{ ...Styles.h1Small }}>Evaluate-Ed {props.awardTag ? `${props.awardTag.name} summary` : props.reportSettings.reportName ? props.reportSettings.reportName : projectSettings.isSelfEvaluation ? 'self evaluation summary' : projectSettings.isDueDiligence ? 'executive summary' : 'project summary'}</Text>
                    </View>

                    {/* Show school logo/banner if it has one */}
                    {
                        !!props.reportBannerBlobDataUrl ? (
                            <Image src={props.reportBannerBlobDataUrl.dataUrl} style={{ marginBottom: 4 }} />
                        ) : null
                    }

                    <IntroductionPdfUi {...props} />

                    <ConditionalFragment showIf={reportSettings.overallResults}>
                        <OverviewPdfUi {...props} imageEnumerator={imageEnumerator} />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={projectSettings.allowRisks && reportSettings.areasForDevelopment}>
                        <RisksPdfUi {...props} />
                    </ConditionalFragment>

                    <ConditionalFragment showIf={reportSettings.areasOfStrength}>
                        <StrengthsPdfUi {...props} imageEnumerator={imageEnumerator} />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={reportSettings.breakdown}>
                        <BreakdownPdfUi {...props} imageEnumerator={imageEnumerator} />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={projectSettings.isSelfEvaluation && reportSettings.areasForDevelopment}>
                        <ReccomendationsPdfUi {...props} imageEnumerator={imageEnumerator} />
                    </ConditionalFragment>

                    <ConditionalFragment showIf={reportSettings.conclusion}>
                        <ConclusionPdfUi {...props} />
                    </ConditionalFragment>
                </View>

                {/*
                <View style={Styles.footer} fixed>
                    <Text style={Styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </View>
                */}
            </Page>
        </Document>
    );
};

