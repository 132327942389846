import * as React from 'react';
import { Form, FormGroup, Label, Container, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';
import { ValidatedDateTimeInput } from '../../shared/ValidatedDateTimeInput';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add school term' : 'Edit school term'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <Form onSubmit={onSubmit}>

                <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <ValidatedInput type="text" name="name" autoComplete="off" placeholder="Name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="startDate">Start date</Label>
                    <ValidatedDateTimeInput type="date" name="startDate" placeholder="Start date" value={props.model.startDate} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    <FormText>
                        The term will start at midnight at the start of the day you enter here and will include this day as the first day of the term.
                    </FormText>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="endDate">End date</Label>
                    <ValidatedDateTimeInput type="date" name="endDate" placeholder="End date" value={props.model.endDate} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    <FormText>
                        The term will end at midnight at the start of the day you enter here, this means this term will not include this day and you will want to use the same date as the "Start date" of the next term when you set it up.
                    </FormText>
                </FormGroup>

                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
