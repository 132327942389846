import * as React from "react";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { withServiceProps } from "inject-typesafe-react";

export interface SubscriptionDataItem {
    id: string,
    email: string,
    name: string,
    numberOfSchools: number,
    numberOfTrusts: number,
    numberOfProjects: number,
    lastProjectAdded: string,
    joinedDate: string,
    renewalDate: string
}

export interface ResultDataItem {
    subscriptionId: string,
    active: number,
    completed: number,
    archived: number,
    reviewerQuestionsAnswered: number,
    reviewerTopicsAnswered: number,
    reviewerSectionsAnswered: number,
    questionaireQuestionsAnswered: number,
    questionaireRespondants: number,
    questionairesResponses: number,
    numberOfSchools: number,
    numberOfPotentialSchools: number,
    numberOfTrusts: number,
}


export interface AdminReportsContainerProps extends ContainerComponentProps<AdminReportsUiProps> {
    loadModel: () => Promise<any>,
}

export interface AdminReportsUiProps {
    subscriptions: Array<SubscriptionDataItem> | undefined,
    results: Array<ResultDataItem>,

    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any,
}

/**
 * Container for the admin reports.
 * 
 * @param props
 */
export const _AdminReportsContainer = (props: AdminReportsContainerProps) => {
    const { component, loadModel, ...rest } = props;
    
    const [subscriptions, setSubscriptions] = React.useState<Array<SubscriptionDataItem> | undefined>(undefined);
    const [results, setResults] = React.useState<Array<ResultDataItem>>([]);
    

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadModel();
        setResults(result.results);

        setSubscriptions(result.subscriptions);
        return true;
    }, [loadModel, setSubscriptions, setResults]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!subscriptions) && !isLoading && !loadingErrors) {
            load();
        }
    }, [subscriptions, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            subscriptions={subscriptions}
            results={results}
        />
    );
};

export const AdminReportsContainer = withServiceProps<AdminReportsContainerProps, AppServicesCore>(services => ({
    loadModel: services.api.reports.viewModels.adminReports(),
}))(_AdminReportsContainer);

