import * as React from 'react';
import { DashboardUiProps } from '../DashboardContainer';
import { Card, Table, CardHeader, CardBody } from 'reactstrap';
import { SchoolWithoutTrustComparisonTable } from './SchoolWithoutTrustComparisonTable';

export const SchoolWithoutTrustComparisonCard = (props: DashboardUiProps) => {
    if (!props.results || !props.results.length) {
        return (<></>);
    }

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.results[0];

    return (
        <Card>
            <CardHeader>
                <h6>Results for the {props.school? 'school': props.trust? 'trust': 'project'}</h6>
            </CardHeader>
            
            <CardBody>
                <SchoolWithoutTrustComparisonTable {...props} />
            </CardBody>
        </Card>
    );
};

