import * as React from 'react';
import moment from 'moment';
import { Text, View, Link } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { Establishment } from '../../../../api/models/edubase/Establishment';
import { Guid } from 'guid-string';
import { DateLabelPdf } from '../../../shared/pdf/DateLabelPdf';
import { SchoolSummaryPdfUi } from './SchoolSummaryPdfUi';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { ReasonForConsiderationPdfUi } from './ReasonForConsiderationPdfUi';
import { ConditionalFragment } from 'react-conditionalfragment';
/**
 * Introduction section of the executive summary (pdf format).
 */
export const IntroductionPdfUi = (props: ExecutiveSummaryPdfProps) => {
    const { projectSettings, reportSettings } = props;

    let schoolEdubaseModel = React.useMemo(() => {
        if (!props.school || !props.school.edubaseJson) {
            return undefined;
        }

        return JSON.parse(props.school.edubaseJson) as Establishment;
    }, [props.school]);


    let isForTrust = React.useMemo(() => {
        if (!props.model) {
            return false;
        }

        if (!Guid.isEmpty(props.model.schoolId)) {
            return false;
        }

        if (!Guid.isEmpty(props.model.trustId)) {
            return true;
        }

        return false;
    }, [props.model]);


    if (!props.model) {
        return (<></>);
    }


    return (
        <View>
            <ConditionalFragment showIf={reportSettings.introduction}>
                <Text style={Styles.h3}>Introduction</Text>

                <Text style={Styles.p}>
                    {
                        isForTrust ? (
                            <Text>
                                The purpose of this project is to {props.awardTag ? `prepare for the ${props.awardTag.name} review of` : projectSettings.isSelfEvaluation ? 'perform a self evaluation of' : projectSettings.isDueDiligence ? 'perform due diligence on' : 'review'} {props.trust && props.trust.name || ''}{props.trust && props.trust.dfeReference ? (<>{` (${props.trust.dfeReference})`}</>) : null}.
                            </Text>
                        ) : (
                            <Text>
                                The purpose of this project is to {props.awardTag ? `prepare for the ${props.awardTag.name} review of` : projectSettings.isSelfEvaluation ? 'perform a self evaluation of' : projectSettings.isDueDiligence ? 'perform due diligence on' : 'review'} on {props.school && props.school.name || ''}{props.trust ? ' on behalf of ' + props.trust.name : ''}
                                {schoolEdubaseModel ? (<> ({`URN: ${schoolEdubaseModel.urn}, DfE number: ${schoolEdubaseModel.establishmentNumber}, UKPRN: ${schoolEdubaseModel.ukprn}`}).</>) : (<>.</>)}
                            </Text>
                        )
                    }
                </Text>

                <Text style={Styles.p}>
                    <ConditionalFragment showIf={props.projectSettings.isDueDiligence}>
                        The {props.awardTag ? 'review' : projectSettings.isSelfEvaluation ? 'self evaluation' : projectSettings.isDueDiligence ? 'due diligence' : 'review'} was carried out between <DateLabelPdf format="DD/MM/YYYY" value={props.model.startDate} /> and {props.model.completedDate ? (<DateLabelPdf format="DD/MM/YYYY" value={props.model.completedDate} />) : (<DateLabelPdf format="DD/MM/YYYY" value={moment().toISOString()} />)}.
                    </ConditionalFragment>
                    The results were gathered by {props.reviewers.length == 1 ? (<>{props.reviewers[0].forename} {props.reviewers[0].surname}</>) : (<>{props.reviewers.length} reviewers</>)}
                    {props.projectQuestionaires.filter(it => it.sentOnDate).length ? (<> and responses gathered by {props.projectQuestionaires.filter(it => it.sentOnDate).length} questionnaires</>) : (<></>)}.
                </Text>
            </ConditionalFragment>
            {
                props.school ? (
                    <ConditionalFragment showIf={reportSettings.schoolBackground}>
                        <Text style={Styles.h4}>School background</Text>
                        {
                            schoolEdubaseModel ? (
                                <>
                                    <Text style={Styles.p}>
                                        {props.school && props.school.name} is a school under the {schoolEdubaseModel.la.displayName} local authority located on {[schoolEdubaseModel.street, schoolEdubaseModel.locality, schoolEdubaseModel.town, schoolEdubaseModel.county.displayName, schoolEdubaseModel.postcode].filter(it => it).join(', ')}.
                                        <> The headteacher at the school is {schoolEdubaseModel.headTitle.displayName} {schoolEdubaseModel.headFirstName} {schoolEdubaseModel.headLastName}{schoolEdubaseModel.headHonours ? ` ${schoolEdubaseModel.headHonours}` : ''}.</>
                                        <> The school covers the {schoolEdubaseModel.phaseOfEducation.displayName} phase of education and has {schoolEdubaseModel.numberOfPupils || 0} pupils between ages {schoolEdubaseModel.statutoryLowAge} to {schoolEdubaseModel.statutoryHighAge}.  The school's pupils capacity is {schoolEdubaseModel.schoolCapacity || 0}.</>
                                        {schoolEdubaseModel.officialSixthForm.displayName === 'Has a sixth form' ? (<> The school has an official sixth form.</>) : (<></>)}

                                    </Text>
                                    <Text style={Styles.p}>
                                        The school has an Ofsted rating of <Text style={Styles.strong}>{schoolEdubaseModel.ofstedRating.displayName}</Text>
                                        {schoolEdubaseModel.ofstedLastInsp ? (
                                            <> and was last inspected by Ofsted on <DateLabelPdf format="DD/MM/YYYY" value={schoolEdubaseModel.ofstedLastInsp} />.</>
                                        ) : (
                                            <> and has no date of last inspection on record.</>
                                        )}
                                        <> The Ofsted report can be viewed at <Link src={`http://www.ofsted.gov.uk/oxedu_providers/full/(urn)/${schoolEdubaseModel.urn}`}>{`http://www.ofsted.gov.uk/oxedu_providers/full/(urn)/${schoolEdubaseModel.urn}`}</Link>.</>
                                    </Text>
                                </>
                            ) : null
                        }
                        <SchoolSummaryPdfUi {...props} />
                    </ConditionalFragment>
                ) : (
                    <ConditionalFragment showIf={reportSettings.schoolBackground}>
                        <Text style={Styles.h4}>Trust background</Text>
                        <SchoolSummaryPdfUi {...props} />
                    </ConditionalFragment>
                )
            }
            <ConditionalFragment showIf={reportSettings.consideration}>
                <ReasonForConsiderationPdfUi {...props} />
            </ConditionalFragment>
            <Text style={Styles.h4}>Reviewers</Text>
            <Text style={Styles.p}>
                {props.awardTag ? 'The review' : projectSettings.isSelfEvaluation ? 'Self evaluation' : projectSettings.isDueDiligence ? 'Due diligence' : 'The review'} was performed on the school by the following {props.reviewers.length == 1 ? (<>reviewer</>) : (<>{props.reviewers.length} reviewers</>)}:
            </Text>
            {
                props.reviewers.map(item => (
                    <View key={item.id}>
                        <Text style={Styles.h6}>{item.forename} {item.surname} ({item.email})</Text>
                        <Text style={Styles.p}>
                            <PlainTextWithBrsPdf text={item.bio} />
                        </Text>
                    </View>
                ))
            }
        </View>
    );
};

