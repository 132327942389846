import * as React from 'react';
import { Row, Col, Table, Card } from 'reactstrap';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ReportProps } from '../reportGenerator/ReportProps';
import { ProjectAnalysisResult, ProjectAnalysisResultItem } from '../../../api/models/ProjectAnalaysisResult';
import { getGradeString } from '../../../utilities/getGradeString';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../utilities/replacePlaceholders';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Strength } from '../../../api/models/Strength';
import { Risk } from '../../../api/models/Risk';
import { Question } from '../../../api/models/Question';
import { QuestionResponse } from '../../../api/models/QuestionResponse';


export interface SEFProjectTopicUiProps extends ReportProps {
    projectTopicId: string
}

/**
 * Strengths vs recommendations breakdown for a project topic.
 */
export const SEFProjectTopicUi = (props: SEFProjectTopicUiProps) => {
    const {
        questionResponses,
    } = props;
    
    // Find the appropriate items we want to work with.
    let [projectTopic, topic, results, questions, /*questionaires, */ excellences, strengths, risks, myResults, ] = React.useMemo(() => {
        let ps = props.projectTopics.models.find(item => item.id === props.projectTopicId);
        let topicId = '';
        if (ps) {
            topicId = ps.topicId;
        }

        let s = props.topics.find(item => item.id === topicId);

        let r = props.resultsByTopic.find(item => item.projectTopicId === props.projectTopicId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        const sectionResults = props.resultsBySection.find(item => !!s && item.sectionId === s.sectionId);
        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (sectionResults && sectionResults.results.length) {
            myResults = sectionResults.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        let q = props.questions.filter(item => item.topicId === topicId);
        //let qq = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));

        const strengthsx = props.strengths.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));
        const strengthsExcellences = strengthsx.filter(item => item.isExcellence);
        const strengthsAreasOfStrength = strengthsx.filter(item => !item.isExcellence);
        const risksx = props.risks.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));

        return [ps, s, rr, q, /*qq,*/ strengthsExcellences, strengthsAreasOfStrength, risksx, myResults];
    }, [props.projectTopics, props.topics, props.projectTopicId, props.questions, props.resultsByTopic, props.strengths, props.risks]);

    if (!props.model) {
        return (<></>);
    }


    if (!projectTopic) {
        return (<></>);
    }

    if (!excellences.length && !strengths.length && !risks.length) {
        return (<></>);
    }

    return (
        <div>
            <Row>
                <Col>
                    <h5>{topic && replacePlaceholders(topic.name, props.placeholderReplacementMode, { subjectName: props.subject?.name }) || 'Topic'}</h5>
                </Col>
                <Col xs="auto">
                    <h5>
                        {getGradeString(myResults && myResults.percentageQuestionScore || 0)}
                    </h5>
                </Col>
            </Row>

            <Card body tag="div">
                {/* Areas of excellence */}
                <ConditionalFragment showIf={!!excellences.length}>
                    <TableFor
                        title="Areas of excellence"
                        items={excellences}
                        placeholderReplacementMode={props.placeholderReplacementMode}
                        questions={questions}
                        questionResponses={questionResponses}
                        subjectName={props.subject?.name}
                        />
                </ConditionalFragment>
                
                {/* Areas of strength */}
                <ConditionalFragment showIf={!!strengths.length}>
                    <TableFor
                        title="Areas of strength"
                        items={strengths}
                        placeholderReplacementMode={props.placeholderReplacementMode}
                        questions={questions}
                        questionResponses={questionResponses}
                        subjectName={props.subject?.name}
                    />
                </ConditionalFragment>

                {/* Areas for development */}
                <ConditionalFragment showIf={!!risks.length}>
                    <TableFor
                        title="Areas for development"
                        items={risks}
                        placeholderReplacementMode={props.placeholderReplacementMode}
                        questions={questions}
                        questionResponses={questionResponses}
                        subjectName={props.subject?.name}
                    />
                </ConditionalFragment>
            </Card>
        </div>        
    );
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and only used internall by the files main component.
 */
const TableFor = (props: {
    title: string,
    items: Array<Strength | Risk>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    questions: Array<Question>,
    questionResponses: Array<QuestionResponse>,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        questions,
        questionResponses,
        subjectName,
    } = props;

    return (
        <div className="table-responsive">
            <Table striped className="table-sm">
                <thead>
                    <tr>
                        <th></th>
                        <th><span>{title}</span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map(item => {
                            // Without an associated question we can't show anything.
                            const question = questions.find(it => it.id === item.questionId);
                            if (!question) {
                                return null;
                            }

                            // Get the response to look up the feedback.  If any has been written we'll include it in the table.
                            const response = questionResponses.find(it => it.id === item.questionResponseId);
                            let feedback = '';
                            if (response) {
                                feedback = response.feedback;
                            }

                            return (
                                <tr key={item.id}>
                                    <td><span>{question.questionNumber}</span></td>
                                    <td><span>
                                        <PlainTextWithBrs text={replacePlaceholders(item.description, placeholderReplacementMode, { subjectName: subjectName })} />

                                        {
                                            !!feedback ? (
                                                <div className="sef-question-feedback">
                                                    {feedback}
                                                </div>
                                            ): null
                                        }
                                    </span></td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};
