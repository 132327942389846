import { ProjectSettings } from "./getProjectSettingsFromQuestionSetName";

/**
 * Generates a url for drilling down the dashboard display,.
 */
export function dashboardDrilldownUrl(projectSettings: ProjectSettings, filterBySectionOriginKey: string | undefined, filterByTopicOriginKey: string | undefined, thisOriginKey: string): string {    
    // Trying to click into a question takes the user to the actual responses for this topic on this project.
    if (filterByTopicOriginKey) {
        return `${projectSettings.baseRoute}/showQuestion/${thisOriginKey}`;
    }

    const dashboardRoute = projectSettings.baseRoute
        .replace('selfEvaluation', 'selfEvaluationDashboard')
        .replace('dueDiligence', 'dueDiligenceDashboard')
        .replace('award', 'awardDashboard');


    // Clicking into a topic takes you into the topic.
    if (filterBySectionOriginKey) {
        return `${dashboardRoute}?sectionOriginKey=${encodeURIComponent(filterBySectionOriginKey)}&topicOriginKey=${encodeURIComponent(thisOriginKey)}`;
    }

    // Clicking into a section takes you into the section.
    return `${dashboardRoute}?sectionOriginKey=${encodeURIComponent(thisOriginKey)}`;
}
