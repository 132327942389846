import * as React from "react";
import { ReportGenerator } from "./ReportGenerator";

/**
 * Report generator that shows the report readonly (e.g. for reviewing to pass or fail an award).
 */
export const ReadOnlyReportGenerator = (props: any) => {
    return (
        <ReportGenerator readOnly={true} {...props} />
        );
}