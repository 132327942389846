import * as React from 'react';
import { Form, FormGroup, Label, Button, Alert, Container } from 'reactstrap';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoginUiProps, LoginContainer } from './LoginContainer';
import { withContainer } from 'react-withcontainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { LinkContainer } from 'react-router-bootstrap';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { AlertOnErrors } from '../shared/AlertOnErrors';

export const LoginUi = (props: LoginUiProps) => {
    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await props.save();
    }, [props.save]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const navigation = useUniversalNavigation(props);
    if (props.isAuthenticated) {
        let to = navigation.getParam('from', '/');
        return (<Redirect to={to} />);
    }

    return (
        <Container className="main-container account">
            <div className="main-heading">
                <img className="logo img-fluid" src="/img/brand/logo-full.png" />
            </div>

            <AlertOnErrors errors={props.savingErrors} />

            {
                props.isRedirectFromJoin ? (
                    <Alert color="warning">
                        You already have an account here for {props.model.user} so you don't need to register again you can just login.
                    </Alert>
                ) : null
            }

            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Label htmlFor="user">Email</Label>
                    <ValidatedInput type="email" name="user" placeholder="Email" value={props.model && props.model.user ? props.model.user : ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="password">Password</Label>
                    <ValidatedInput type="password" name="password" placeholder="Password" value={props.model && props.model.password ? props.model.password : ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>

                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Logging in...</>}>
                        Login
                    </ButtonAsync>

                    <LinkContainer to="/account/passwordReset">
                        <Button color="primary" outline>
                            Forgotten your password?
                        </Button>
                    </LinkContainer>
                    <div className="float-right float-bottom">
                        No account yet?
                        <> </>
                        <a className="btn btn-secondary" href="https://www.evaluate-ed.com/free-trial/">
                            Start free trial for your school
                        </a>
                    </div>
                </div>
            </Form>
        </Container>
    );
};

export const Login = withContainer(LoginContainer)(LoginUi);
