import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { PartialPage } from '../administration/pages/PartialPage';

export interface TermsAndConditionsModalProps {
    isOpen: boolean,
    toggleOpen: () => void,
    isAgreed: boolean,
    agree: () => void,
}

/**
 * Modal showing the Terms and conditions for confirmation.
 * @param props
 */
export const TermsAndConditionsModal = (props: TermsAndConditionsModalProps) => {
    const { isOpen, toggleOpen, isAgreed, agree } = props;

    return (
        <Modal isOpen={isOpen} size="lg">
            <ModalHeader toggle={toggleOpen}>
                Please read and accept our terms and conditions
                
            </ModalHeader>
            <ModalBody>
                <PartialPage name="Terms and Conditions" />
            </ModalBody>
            <ModalFooter>
                {
                    !isAgreed ? (
                        <Button color="success" onClick={() => { agree(); toggleOpen(); }}>
                            Accept
                        </Button>
                    ) : null
                }
                
                <Button color="primary" outline onClick={toggleOpen}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
        
    );

    //<Page name="Terms and Conditions" />
};