/**
 * Settings that can control the behaviour of screens for different question sets.
 * 
 * This should be used instead of components checking the name of the question set to decide things.
 */
export interface QuestionSetTypeSettings {
    questionSetName: string,
    tierChoice: boolean,
    riskText: string,
    riskTextAddToSentence: string,
    ragAnswers: boolean,
    specificReviews: boolean,
    specificReviewMainTagText: string,
    impactChoice: boolean,
    trustTargets: boolean,
}

/**
 * Available question set types.
 */
export const QuestionSetType: { [key: string]: QuestionSetTypeSettings } = {
    selfEvaluation: { questionSetName: 'School self evaluation', tierChoice: true, riskText: 'Area for development', riskTextAddToSentence: 'possible areas for development', ragAnswers: false, specificReviews: true, specificReviewMainTagText: 'Self evaluation', impactChoice: true, trustTargets: true, } as QuestionSetTypeSettings,
    dueDiligence: { questionSetName: 'School due diligence', tierChoice: false, riskText: 'Risk', riskTextAddToSentence: 'risk report', ragAnswers: false, specificReviews: true, specificReviewMainTagText: 'Due diligence', impactChoice: true, trustTargets: true, } as QuestionSetTypeSettings,
    subjectLeadershipReview: { questionSetName: 'Subject leadership review', tierChoice: false, riskText: 'Area for development', riskTextAddToSentence: 'possible areas for development', ragAnswers: false, specificReviews: false, specificReviewMainTagText: '', impactChoice: true, trustTargets: false, } as QuestionSetTypeSettings,
    subjectHelpfulQuestions: { questionSetName: 'Subject helpful questions', tierChoice: false, riskText: '', riskTextAddToSentence: '', ragAnswers: true, specificReviews: false, specificReviewMainTagText: '', impactChoice: false, trustTargets: false, } as QuestionSetTypeSettings,
    readingReviewEYFSToKS2: { questionSetName: 'Reading review EYFS to KS2', tierChoice: false, riskText: '', riskTextAddToSentence: '', ragAnswers: true, specificReviews: false, specificReviewMainTagText: '', impactChoice: false, trustTargets: false, } as QuestionSetTypeSettings,
    readingReviewKS3ToKS4: { questionSetName: 'Reading review KS3 to KS4', tierChoice: false, riskText: '', riskTextAddToSentence: '', ragAnswers: true, specificReviews: false, specificReviewMainTagText: '', impactChoice: false, trustTargets: false, } as QuestionSetTypeSettings,
};

/**
 * Returns the QuestionSetTypeSettings from the name of the question set.
 * @param name
 */
export function getQuestionSetTypeFromName(name: string): QuestionSetTypeSettings
{
    const lowerName = name.toLowerCase();

    for (const key of Object.keys(QuestionSetType)) {
        const item = QuestionSetType[key];
        if (item.questionSetName.toLowerCase() === lowerName) {
            return item;
        }
    }

    // Default to selfEvaluation.
    return QuestionSetType.selfEvaluation;
}