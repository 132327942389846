import * as React from 'react';
import { Button, } from 'reactstrap';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { EditReasonForConsiderationModal } from './editModals/EditReasonForConsiderationModal';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReportProps } from '../reportGenerator/ReportProps';


/**
 * ReasonForConsideration section of the executive summary.
 */
export const ReasonForConsiderationUi = (props: ReportProps) => {
    const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
    const [originalValue, setOriginalValue] = React.useState<string>('');
    const toggleEditModalOpen = React.useCallback(() => {
        setEditModalIsOpen(prevState => !prevState);
    }, [setEditModalIsOpen]);

    if (!props.model) {
        return (<></>);
    }

    return (
        <>
            <div>
                <h4>
                    {
                        !props.school && props.trust ? (
                            <>Project goals </>
                        ) : (
                                <>Reason for consideration </>
                            )
                    }

                    <ConditionalFragment showIf={!props.readOnly}>
                        <PrivateContainer role="Manage">
                            <> <Button size="sm" color="primary" outline onClick={e => {
                                if (!props.model) {
                                    return;
                                }
                                setOriginalValue(props.model.reasonForConsideration);
                                toggleEditModalOpen();
                            }}>
                                Edit
                            </Button></>
                        </PrivateContainer>
                    </ConditionalFragment>
                </h4>
                <p>
                    <PlainTextWithBrs text={props.model.reasonForConsideration} />
                </p>
            </div>

            {
                editModalIsOpen ? (
                    <EditReasonForConsiderationModal model={props.model} changeModel={props.changeModel}
                        isProjectGoals={!props.school && props.trust? true: false}
                        save={async (): Promise<boolean> => {
                            let ok = await props.save();
                            if (!ok) {
                                return false;
                            }

                            toggleEditModalOpen();
                            return true;
                        }}
                        isSaving={props.isSaving} savingErrors={props.savingErrors}
                        cancel={() => {
                            if (!props.model) {
                                return;
                            }

                            props.changeModel({ reasonForConsideration: originalValue });
                        toggleEditModalOpen();
                    }} />
                ): null
            }
        </>
    );
};
