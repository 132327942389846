/**
 * Here are some mappings to specific sections and topics to help us make the standard report code readable.
 * 
 * The names here are under admin control as data, so its possible in the future the code won't use all the right exct names but it will
 * at least be better than just having a list of raw strings with OriginKeys in it.
 */
export const standardReportsOriginKeyMap = {
    schoolImprovment: {
        key: '56730dd6-d661-46be-a6f4-eb2483082f2b',
        topics: {
            schoolImprovementsSelfEvaluationAndOfsted: '57c832c9-5318-480f-b3e0-56b2d14f0e5f',
        },
    },
    educationalEnvironment: {
        key: 'b05805cc-64fa-4702-aeec-c65ecde05ef0',
        topics: {
            leadershipAndManagement: '6a98e1f4-a140-445a-a9db-1962d1a9a0be',
            qualityOfEducationIntent: '33fdb5ff-f71b-4e25-9d60-d4d04ef8c723',
            qualityOfEducationImplementation: '2bbcec9f-9896-47d1-bd6d-4f56e1aaa70c',
            qualityOfEducationImpactLearning: 'ac6dba65-7a9a-4b2a-969e-d3c3695c4901',
            qualityOfEducationImpactData: 'bc405073-f794-4a32-a32a-51a395359e29',
            behaviourAndAttitudes: 'fe7770a8-a911-4624-aceb-86153b25aad4',
            personalDevelopment: '9ba13fe3-3e37-43ea-83f4-c429d3cc8dc5',
        },
    },
    governance: {
        key: '36189a2c-566a-43ef-9fea-9b0187156b32',
        topics: {
            governance: 'cc63b986-82d6-431d-be2c-223574dc96d3',
        },
    },
    people: {
        key: '7eae4953-2487-49ad-8d74-1fea9a463683',
        topics: {
            wellbeing: '6792f44c-51b5-4ba2-b4f5-c2b4f193256e',
            employmentAndHr: '990e8e72-34cd-40b3-8ba4-637bbdf6aa12',
        },
    },
    premises: {
        key: 'aaf44000-8129-46b6-8d1a-9b9089eb99a4',
        topics: {
            healthAndSafety: 'c99e8134-3b8c-4dcb-b40a-740d450fe3d5',
            healthAndSafetyCovid19: 'f3dddbf8-8c04-4680-bd44-39af22a88d8a',
            assets: 'e29751f0-d924-4c46-9147-c42cb7263462',
            facilities: '714d852f-dce1-444e-b7bb-94d0094a7000',
        },
    },
    finance: {
        key: 'd4fe6f2d-46ec-4c27-993f-320a5da3147d',
        topics: {
            banking: '44acb5c3-9f13-441e-8c03-bc02acfad339',
            proceduresAndPractices: '8feb6db4-9cf9-4845-855f-984ea9147bff',
            incomeGeneration: '8d6cc1c7-12e0-48a9-b75e-119c3b49cc07',
            vat: 'f5268453-880a-4788-9ddb-2d369dedf52e',
            pensions: '953ca8d2-3546-49f7-b73d-513fe3ca98a1',
            purchasingAndSlas: '824bf81b-a100-42f2-b720-ae9806bea79d',
        },
    },
    policies: {
        key: 'c6e41ae3-a2dc-4477-9129-23a50991183b',
        topics: {
            statutoryPolicies: '5d8a28cd-8281-499d-9186-0b42eaf75369',
        },
    },
    reputationalRisk: {
        key: 'e099087c-0ca6-47c0-9d0d-8949b43ed534',
        topics: {
            reputationalRisk: 'bd2d264e-6c21-48f5-a0e4-c8d4242b2cfd',
        },
    },
    legal: {
        key: '13c71591-0bd1-41a4-a29b-3ba90a4a795f',
        topics: {
            legalCompliance: '69d788d3-34fc-44ab-be14-8d3f5f620e6c',
        },
    },
};
