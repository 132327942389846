import * as React from 'react';
import { Form, FormGroup, Label, Alert, Row, Col, Container, Table, ButtonGroup, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Trust } from '../../../../api/models/Trust';
import { ValidationErrors } from 'pojo-validator';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { SearchModal } from '../../../manage/edubase/SearchModal';
import { TrustEdubaseDetailsUi } from '../../../manage/edubase/TrustEdubaseDetailsUi';

interface CoreFieldsProps {
    model: Trust | undefined,
    changeModel: (changes: Partial<Trust>) => void,

    validate: (fieldsToCheck?: Array<string>) => boolean,
    validationErrors: ValidationErrors,

    updateFromEdubase: (urn: number) => Promise<boolean>,
    isUpdatingFromEdubase: boolean
}

/**
 * Core fiels of the Trust Edit screen, which can also be embedded into other screens (e.g. the create project wizard).
 * @param props
 */
export const CoreFields = (props: CoreFieldsProps) => {
    const [searchModalIsOpen, setSearchModalIsOpen] = React.useState<boolean>(false);
    const toggleSearchModalOpen = React.useCallback(() => {
        setSearchModalIsOpen(prevState => !prevState);
    }, [setSearchModalIsOpen]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);


    // render UI
    //

    if (!props.model) {
        return (<></>);
    }

    return (
        <>
            <FormGroup>
                <Label htmlFor="name">Name</Label>
                <InputGroup>
                    <ValidatedInput type="text" name="name" autoComplete="off" placeholder="Name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    {/*<InputGroupAddon addonType="append">
                        <Button type="button" color="primary" onClick={toggleSearchModalOpen}>
                            <FontAwesomeIcon icon="search" /> <span className="sr-only">Search</span>
                        </Button>
                    </InputGroupAddon>*/}
                </InputGroup>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="dfeReference">DfE UID/Postcode</Label>
                <ValidatedInput required={true} type="text" name="dfeReference" autoComplete="off" placeholder="DfE UID/Postcode" value={props.model.dfeReference} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
            </FormGroup>
            {/*
            {
                props.model.edubaseJson ? (
                    <>
                        <h3 className="sub-heading">
                            Information from DFE <>
                                <ButtonAsync color="primary" outline size="sm" onClick={e => props.updateFromEdubase(parseFloat(props.model && props.model.dfeReference || ''))} isExecuting={props.isUpdatingFromEdubase}
                                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Refreshing...</>}>
                                    Refresh
                                </ButtonAsync>
                            </>
                        </h3>
                        <TrustEdubaseDetailsUi json={props.model.edubaseJson} />
                    </>) : (
                        <div className="text-warning">
                            This trust has not yet been located within the DfE's database and so cannot show any additional information. <Button color="primary" outline size="sm" type="button" onClick={toggleSearchModalOpen}>Want to try find it now?</Button>
                        </div>
                    )
            }

            {
                searchModalIsOpen ? (
                    <SearchModal searchText={props.model.name} cancel={toggleSearchModalOpen} resultSelected={(urn: number) => { props.updateFromEdubase(urn); toggleSearchModalOpen(); }} />
                ) : null
            }
            */}
        </>
    );
};
