import { ApiFetch } from 'apifetch-json';
import { BlobUrl } from '../api/models/BlobUrl';
import { Establishment } from '../api/models/edubase/Establishment';

/**
 * Service for querying the Edubase API for information on schools and trusts.
 */
export class EdubaseService {
    private readonly url: string;
    private readonly api: ApiFetch;

    constructor(url: string, apiFetch?: ApiFetch) {
        this.url = url;
        this.api = apiFetch || new ApiFetch();
    }

    /**
     * Returns a list of all establishments matching the passed in name.
     * @param name
     */
    async findEstablishments(name: string): Promise<Array<Establishment>> {
        return this.api.get(`${this.url}/findEstablishments?name=${encodeURIComponent(name)}`);
    }

    /**
     * Returns details of the establishment with the matching urn.
     * @param urn
     */
    async getEstablishment(urn: number): Promise<Establishment> {
        return this.api.get(`${this.url}/getEstablishment?urn=${encodeURIComponent(urn.toString())}`);
    }
}
