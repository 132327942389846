import * as React from 'react';
import { View } from '@react-pdf/renderer';
import { SEFPdfProps } from './SEFPdf';
import { SEFProjectSectionPdfUi } from './SEFProjectSectionPdfUi';


export interface SEFBreakdownPdfUiProps extends SEFPdfProps {
}

/**
 * Breakdown of strengths and recommendations by sections and topics for SEF (pdf format).
 */
export const SEFBreakdownPdfUi = (props: SEFBreakdownPdfUiProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <View>
            {
                props.projectSections.models.map(item => (
                    <SEFProjectSectionPdfUi key={item.id} projectSectionId={item.id} {...props} />
                ))
            }
        </View>  
    );
};

