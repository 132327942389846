import * as React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Project } from "../../../api/models/Project";
import { useAsyncCallback } from "react-use-async-callback";
import { AlertOnErrors } from "../../shared/AlertOnErrors";
import { useApiService } from "../../../api/useApiService";
import { Row, Col, Nav, NavItem, NavLink, TabContent, Alert } from "reactstrap";
import { StickyToolbar } from "../../shared/StickyToolbar";
import { useUniversalNavigation } from "react-universal-navigation";
import { Edit as ProjectTopicEdit } from "../projectTopics/Edit"
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProjectSettings } from "../../../api/useProjectSettings";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { ProductTier, productTierDisplayName } from "../../../api/models/codeOnly/ProductTeir";
import "./projectSectionComponent.scss";
import { HelpRequestButton } from "../../helpRequests/HelpRequestButton";
import { AwardTag } from "../../../api/models/AwardTag";
import { Subject } from "../../../api/models/Subject";
import { replacePlaceholders } from "../../../utilities/replacePlaceholders";
import { LoadingIndicator } from "../../shared/LoadingIndicator";

export interface TopicDataItem {
    id: string,
    name: string,
    sectionId: string,
    sectionName: string,
    questionsCount: number,
    questionResponsesCount: number,
    keyQuestionsCount: number,
    keyQuestionResponsesCount: number,
}

export interface SectionDataItem {
    id: string,
    name: string,
    progressWheelColor: string,
    questionsCount: number,
    questionResponsesCount: number,
    keyQuestionsCount: number,
    keyQuestionResponsesCount: number,
    topicCount: number,
}

export interface ProjectSectionComponentProps {
}

/**
 * Project section component that contains all the topics within a section of a project.
 */
export const ProjectSectionComponent = (props: ProjectSectionComponentProps) => {
    const navigation = useUniversalNavigation(props);
    const projectId = navigation.getParam('projectId', '');
    const topicId = navigation.getParam('topicId', '');
    const awardTagId = navigation.getParam('awardTagId', '');
    const tierParam = navigation.getParam('tier', '');

    // Load the schools progress through the project.
    const api = useApiService();
    const loadViewModel = api.projects.viewModels.progress();
    const [model, setModel] = React.useState<Project | undefined>();
    const [allSections, setAllSections] = React.useState<Array<SectionDataItem>>([]);
    const [allTopics, setAllTopics] = React.useState<Array<TopicDataItem>>([]);
    const [currentTeir, setCurrentTier] = React.useState<ProductTier | undefined>();
    const [awardTag, setAwardTag] = React.useState<AwardTag | null | undefined>();
    const [subject, setSubject] = React.useState<Subject | null | undefined>();

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(projectId || undefined, awardTagId || undefined, tierParam || undefined);
        setAllSections(result.sections);
        setAllTopics(result.topics);
        setCurrentTier(result.currentTier);
        setAwardTag(result.awardTag);
        setSubject(result.subject);
        setModel(result.model);
    }, [loadViewModel, setModel, projectId, setAllSections, setAllTopics, setCurrentTier, awardTagId, tierParam, setAwardTag, setSubject]);
    React.useEffect(() => {
        if (!projectId || isLoading) {
            return;
        }

        if (model && model.id === projectId) {
            return;
        }

        load();
    }, [projectId, load, model, isLoading]);

    const projectSettings = useProjectSettings(model && model.id || '', awardTagId);

    // Lookup the sectionId from the topic.
    const sectionId = React.useMemo(() => {
        const topic = allTopics.find(it => it.id === topicId);
        if (topic) {
            return topic.sectionId;
        }

        // Couldn't find our section, so select no section.
        return '';
    }, [allTopics, topicId]);

    // Get this section.
    const { section, sectionIndex } = React.useMemo(() => {
        if (!allSections) {
            return { section: null, sectionIndex: -1 };
        }

        const section = allSections.find(it => it.id === sectionId);
        if (!section) {
            return { section: section, sectionIndex: -1 };
        }

        return { section: section, sectionIndex: allSections.indexOf(section) }
    }, [allSections, sectionId]);

    // Get the topics within this section.
    const topics = React.useMemo(() => {
        if (!allTopics) {
            return [];
        }

        return allTopics.filter(it => it.sectionId === sectionId);
    }, [allTopics, sectionId]);

    // Helper method for rendering a section's wedge.
    const renderSectionWedge = React.useCallback((section: { id: string, progressWheelColor: string, name: string, }) => {
        const firstTopic = allTopics.find(it => it.sectionId === section.id);
        if (!firstTopic) {
            return null;
        }

        return (
            <Col key={section.id} xs="auto" className="project-section-component-section-wedge">
                <Link to={`${projectSettings.baseRoute}/topic/${firstTopic.id}${tierParam ? `/tier/${tierParam}` : ''}`}>
                    <FontAwesomeIcon icon="cheese" style={{ color: section.progressWheelColor }} />
                    
                    <span className="project-section-component-section-wedge-text">
                        <> </>
                        {replacePlaceholders(section.name, 'general')}
                    </span>
                </Link>
            </Col>
        );
    }, [allTopics, projectSettings, tierParam]);

    // Render UI
    //
    return (
        <div className="main-container project-section-component">
            <div className="main-heading">
                <StickyToolbar>
                    <Row>
                        <Col xs="auto">
                            <h2>
                                {
                                    awardTagId ? awardTag && awardTag.name
                                        : subject ? subject.name
                                            : projectSettings.isDueDiligence ? 'Due diligence'
                                                : projectSettings.isSelfEvaluation && currentTeir ? `${productTierDisplayName(currentTeir)}`
                                                    : projectSettings.questionSetType ? projectSettings.questionSetType.questionSetName
                                                        : ''
                                }
                            </h2>
                        </Col>
                        {
                            // Wedges for sections that come before this one.
                            allSections.map((item, index) => {
                                if (index >= sectionIndex) {
                                    return null;
                                }

                                return renderSectionWedge(item);
                            })
                        }
                        <Col>
                            <h1>
                                <ConditionalFragment showIf={!!section}>
                                    <FontAwesomeIcon icon="cheese" style={{ color: section && section.progressWheelColor || '' }} />
                                    <> </>
                                </ConditionalFragment>
                                {replacePlaceholders(section && section.name || '', 'general')}
                                <> </>
                                <ConditionalFragment showIf={!!section}>
                                    <small className="text-muted">
                                        ({section && section.questionsCount} questions
                                        {
                                            section && section.topicCount > 1 ? (
                                                <> across {section && section.topicCount} topics</>
                                            ) : null
                                        }
                                        )
                                    </small>
                                </ConditionalFragment>
                            </h1>
                        </Col>
                        {
                            // Wedges for sections that come after this one.
                            allSections.map((item, index) => {
                                if (index <= sectionIndex) {
                                    return null;
                                }

                                return renderSectionWedge(item);
                            })
                        }
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <HelpRequestButton />
                        </Col>
                    </Row>
                </StickyToolbar>
            </div>

            <AlertOnErrors errors={[loadingErrors]} />
            <ConditionalFragment showIf={projectSettings.questionSetType.questionSetName.includes("helpful questions")}>
                <Alert color="info">
                    Two Key Points:<br />The curriculum is the progression model.<br />Progress is knowing more, remembering more, and being able to use knowledge and skills appropriately.
                </Alert>
            </ConditionalFragment>
            <ConditionalFragment showIf={topics.length > 1}>
                <Nav tabs justified>
                    {
                        topics.map(topic => (
                            <LinkContainer to={`${projectSettings.baseRoute}/topic/${topic.id}${tierParam ? `/tier/${tierParam}` : ''}`}>
                                <NavItem key={topic.id}>
                                    <NavLink className={classNames({ active: topicId === topic.id })} style={{ cursor: 'pointer', height: '100%' }} >
                                        <div>
                                            {shorternText(replacePlaceholders(topic.name), 50)}
                                        </div>
                                        <div className="text-muted">
                                            ({topic.questionsCount} questions)
                                        </div>
                                    </NavLink>
                                </NavItem>
                            </LinkContainer>
                        ))
                    }
                </Nav>
            </ConditionalFragment>
            <div className="mt-2">
                {
                    !!topicId && !!model ? (
                        <ProjectTopicEdit key={topicId || '' /* Used to make sure we get a loading indicator */}
                            id={topicId || ''}
                            subjectName={subject?.name}
                            subjectId={subject?.id}
                            {...props} />
                    ) : (
                            <LoadingIndicator />
                    )
                }
            </div>
        </div>
    );
};

/**
 * Shorten text to be no longer than maxLength, and to have ... at the end if we've shorterend it.
 * @param text
 * @param maxLength
 */
function shorternText(text: string, maxLength: number) {
    // If the text is short enough already, do nothing.
    let ret = text;
    if (ret.length <= maxLength) {
        return text;
    }

    // Shortern it.
    ret = ret.substring(0, maxLength) + "...";
    return ret;
}