import { RAGDocxProps, finalizeChildren } from './RAGDocx';
import { ProjectAnalysisResultItem, ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Paragraph, HeadingLevel, AlignmentType, Table, TableRow, TableCell, WidthType, BorderStyle, ShadingType, TextRun } from 'docx';
import { getGradeString } from '../../../../utilities/getGradeString';
import { Strength } from '../../../../api/models/Strength';
import { Risk } from '../../../../api/models/Risk';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../../../utilities/replacePlaceholders';
import { Question } from '../../../../api/models/Question';
import { QuestionResponse } from '../../../../api/models/QuestionResponse';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';
import moment from 'moment';


export interface RAGProjectWillDoDocxUiProps extends RAGDocxProps {
}

/**
 * Breakdown of questions by RAG with will do dates
 */
export const RAGProjectWillDoDocxUi = (props: RAGProjectWillDoDocxUiProps) => {
    // Find the appropriate items we want to work with.
    const {
        questionResponses,
        questionChoices,
    } = props;

    let questions = props.questions;

    if (!props.model) {
        return null;
    }

    let placeholderReplacementMode = props.placeholderReplacementMode;
    return [
        // Heading.
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: "Commitments",
                                    heading: HeadingLevel.HEADING_3,
                                })
                            ],
                        }),
                    ],
                }),
            ],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [
                5,
                45,
                40,
                10,
            ],
            borders: {
                top: {
                    style: BorderStyle.NONE,
                },
                bottom: {
                    style: BorderStyle.NONE,
                },
                left: {
                    style: BorderStyle.NONE,
                },
                right: {
                    style: BorderStyle.NONE,
                },
                insideHorizontal: {
                    style: BorderStyle.NONE,
                },
                insideVertical: {
                    style: BorderStyle.NONE,
                },
            },
        }),
        new Paragraph({ text: '', }), // For spacing and making editing easier.


        // Question breakdown by RAG
        TableFor({
            title: "Green: Confidence is Strong",
            items: questions,
            placeholderReplacementMode: props.placeholderReplacementMode,
            colour: "#4dbd74",
            questionChoices: questionChoices,
            questionResponses: questionResponses,
            RAG: "Green",
            subjectName: props.subject?.name,
        }),
        TableFor({
            title: "Amber: Developing Confidence",
            items: questions,
            placeholderReplacementMode: props.placeholderReplacementMode,
            colour: "#ffc107",
            questionChoices: questionChoices,
            questionResponses: questionResponses,
            RAG: "Amber",
            subjectName: props.subject?.name,
        }),
        TableFor({
            title: "Red: Confidence Gaps",
            items: questions,
            placeholderReplacementMode: props.placeholderReplacementMode,
            colour: "#f86c6b",
            questionChoices: questionChoices,
            questionResponses: questionResponses,
            RAG: "Red",
            subjectName: props.subject?.name,
        })
    ];
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and only used internall by the files main component.
 */
const TableFor = (props: {
    title: string,
    items: Array<Question>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    colour: string,
    questionChoices: Array<QuestionChoice>,
    questionResponses: Array<QuestionResponse>,
    RAG: string,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        colour,
        questionChoices,
        questionResponses,
        RAG,
        subjectName,
    } = props;

    return [
        new Table({
            rows: finalizeChildren([
                // Heading row
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "",
                                            color: colour,
                                        }),
                                    ],
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                            width: {
                                size: 5,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: title,
                                            color: colour,
                                        }),
                                    ],
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                            width: {
                                size: 45,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: RAG === "Green" ? "Maintaining and Sharing Confidence" : RAG === "Amber" ? "Embedding Confidence": RAG === "Red" ? "Establishing Confidence" : "",
                                            color: colour,
                                        }),
                                    ],
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                            width: {
                                size: 40,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Timeline",
                                            color: colour,
                                        }),
                                    ],
                                })
                            ],
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE,
                            },
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                        }),
                    ],
                    tableHeader: true,
                }),

                // Item rows
                ...items.map(item => {
                    let willDotext = questionResponses.find(it => it.questionId === item.id)?.willDoText;
                    // Without a question we can't show anything.
                    if (!item || willDotext === "") {
                        return null;
                    }
                    let willDoDate = questionResponses.find(it => it.questionId === item.id)?.willDoDate;
                    // Get the RAG response and filter out questions that don't match
                    const RAGchoice = questionChoices.find(it => it.questionId === item.id)?.name;

                    if (RAGchoice?.split(":")[0] !== RAG) {
                        return null;
                    }

                    return new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        text: item.questionNumber,
                                    }),
                                ],
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                            }),
                            new TableCell({
                                children: finalizeChildren([
                                    new Paragraph({
                                        text: replacePlaceholders(item.name, placeholderReplacementMode, { subjectName: subjectName }),
                                    }),
                                ]),
                                width: {
                                    size: 45,
                                    type: WidthType.PERCENTAGE,
                                },
                            }),
                            new TableCell({
                                children: finalizeChildren([
                                    new Paragraph({
                                        text: replacePlaceholders(questionResponses.find(it => it.questionId === item.id)?.willDoText, placeholderReplacementMode, { subjectName: subjectName }),
                                    }),
                                ]),
                                width: {
                                    size: 40,
                                    type: WidthType.PERCENTAGE,
                                },
                            }),
                            new TableCell({
                                children: finalizeChildren([
                                    !!willDoDate ? (
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: moment(willDoDate).local().format('DD/MM/YYYY'),
                                                    color: colour,
                                                }),
                                            ],
                                            border: {
                                                top: {
                                                    style: BorderStyle.DOTTED,
                                                },

                                            },

                                        })
                                    ) : null,
                                ]),
                                width: {
                                    size: 10,
                                    type: WidthType.PERCENTAGE,
                                },
                            })
                        ],
                        cantSplit: false,
                    });
                }),
            ]),
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [
                5,
                45,
                40,
                10,
            ],
            borders: {
                top: {
                    style: BorderStyle.SINGLE,
                },
                bottom: {
                    style: BorderStyle.SINGLE,
                },
                left: {
                    style: BorderStyle.NONE,
                },
                right: {
                    style: BorderStyle.NONE,
                },
                insideHorizontal: {
                    style: BorderStyle.SINGLE,
                },
                insideVertical: {
                    style: BorderStyle.NONE,
                },
            },
        }),
        new Paragraph({ text: '', }), // For spacing and making editing easier.
    ];
};

