import * as React from 'react';
import { Form, FormGroup, Label, Row, Col, Input, Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';
import { ValidatedElasticInput } from '../../shared/ValidatedElasticInput';
import { DateLabel } from '../../shared/DateLabel';
import { ValidatedHtmlEditor } from '../../shared/htmlEditor/ValidatedHtmlEditor';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    // State of the dropdown for creating a new version.
    const [createNewVersionDropDownIsOpen, setCreateNewVersionDropDownIsOpen] = React.useState<boolean>(false);
    const toggleCreateNewVersionDropdown = React.useCallback(() => {
        setCreateNewVersionDropDownIsOpen(prevState => !prevState);
    }, [setCreateNewVersionDropDownIsOpen])

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);
    
    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <div className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add Page' : 'Edit Page'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <Form onSubmit={onSubmit}>
                <div className="toolbar-top">
                    {
                        props.isReadOnly ? (
                            <>
                                <GoBackLinkContainer>
                                    <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Close</ButtonAsync>
                                </GoBackLinkContainer>
                            </>
                        ) : (
                                <>
                                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                                        <FontAwesomeIcon icon="save" /> Save
                                </ButtonAsync>
                                    <GoBackLinkContainer>
                                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                                    </GoBackLinkContainer>
                                </>
                            )
                    }

                </div>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">Name</Label>
                            <ValidatedInput readOnly={true /* Can never change the name, it is our lookup key when we consume the page. */} type="text" name="name" placeholder="Name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs="auto">
                        <FormGroup>
                            <Label htmlFor="version">Version</Label>
                            {
                                props.isPublished ? (
                                    <Input type="text" readOnly plaintext name="version" value={`${props.model.versionNumber}.${props.model.patchNumber}`} />
                                ) : (
                                        <div>
                                            <Badge color="warning">
                                                Draft
                                            </Badge>
                                        </div>
                                    )
                            }
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    {
                        props.isPublished ? (
                            <>
                                <Label htmlFor="publishedDate">Published on</Label>
                                <Row>
                                    <Col>
                                        <DateLabel value={props.model.publishDate} format="DD/MM/YYYY" />
                                    </Col>
                                    {
                                        props.isLatestVersion ? (
                                            <Col xs="auto">
                                                <ButtonAsync color="success" isExecuting={props.isCreatingNewVersion} onClick={async () => {
                                                    let newModel = await props.createNewVersion(false);
                                                    if (newModel) {
                                                        navigation.navigate(`/administration/pages/edit/${newModel.id}`);
                                                    }
                                                }}
                                                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Creating new version...</>}>
                                                    Create a new version
                                                </ButtonAsync>
                                            </Col>
                                        ) : null
                                    }
                                </Row>
                                <FormText>
                                    You cannot edit this version now it has been published.  You will need to create a new version to perform changes.
                                </FormText>
                            </>
                        ) : props.isLatestVersion ? (
                            <>
                                <Label htmlFor="releaseNotes">Release notes</Label>
                                <Row>
                                    <Col>
                                        <ValidatedElasticInput type="textarea" name="releaseNotes" placeholder="What has changed in this version?" value={props.model.releaseNotes} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                    </Col>
                                    <Col xs="12" md="auto">
                                        <ButtonDropdown isOpen={createNewVersionDropDownIsOpen} toggle={toggleCreateNewVersionDropdown} >
                                            <DropdownToggle caret color="success">
                                                {
                                                    props.isPublishing ? (
                                                        <><FontAwesomeIcon icon="spinner" spin /> Publishing...</>
                                                    ) : (<>Publish new version</>)
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => props.publish(true)}>
                                                    Publish as a new major version for major changes
                                                    </DropdownItem>
                                                <DropdownItem onClick={() => props.publish(false)}>
                                                    Publish as a new minor version for small corrections and clarifications
                                                    </DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                                    <FormText>
                                        You cannot edit or publish an old version.  Please open the latest version to make changes.
                                    </FormText>
                                )
                    }
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="contents">Contents</Label>
                    <ValidatedHtmlEditor name="contents" value={props.model.contents} readOnly={props.isReadOnly} onChange={(content) => props.changeModel({ contents: content })} onBlur={() => props.validate(['contents'])} validationErrors={props.validationErrors} />
                </FormGroup>
            </Form>
        </div>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
