import * as React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { TrustComparisonTablePdfUi } from './TrustComparisonTablePdfUi';
import { SchoolWithoutTrustComparisonTablePdfUi } from './SchoolWithoutTrustComparisonTablePdfUi';
import { IImageEnumerator } from './ImageEnumerator';

/**
 * Overview section of the executive summary (pdf format).
 */
export interface OverviewPdfUiProps extends ExecutiveSummaryPdfProps {
    imageEnumerator: IImageEnumerator
}

export const OverviewPdfUi = (props: OverviewPdfUiProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <View break>
            <Text style={Styles.h3}>Overall results</Text>
            <Image src={props.imageEnumerator.getNextImage()} style={{ marginBottom: 16 }} />

            {
                props.results.length > 1 ? (
                    <TrustComparisonTablePdfUi model={props.model} results={props.results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={undefined} isForSingleSchool={props.isForSingleSchool} placeholderReplacementMode={props.placeholderReplacementMode} />
                ) : (
                        <SchoolWithoutTrustComparisonTablePdfUi model={props.model} results={props.results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={undefined} placeholderReplacementMode={props.placeholderReplacementMode} />
                    )
            }
        </View>  
    );
};

