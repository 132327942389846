import { AnyAction, Dispatch } from 'redux';
import { Identity } from '../state';
import { AuthenticationService } from '../../../services/AuthenticationService';

export const selectSubscriptionType = '@@User/selectSubscription';

export interface SelectionSubscriptionAction {
    type: typeof selectSubscriptionType,
    payload: {
        subscriptionId: string | undefined
    }
}

/**
 * Change the subscription of the current user's identity to subscriptionId (which may be undefined if we want to clear the selected subscription).
 */
export function selectSubscription(subscriptionId: string | undefined) {
    return {
        type: selectSubscriptionType,
        payload: {
            subscriptionId: subscriptionId
        }
    } as SelectionSubscriptionAction;
}
