import * as React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Container } from 'reactstrap';
import classNames from 'classnames';
import { ChangePassword } from './ChangePassword';
import { EditDetails } from './EditDetails';

/**
 * Profile page for the logged in user.
 * @param props
 */
export const Profile = (props: {}) => {
    const [activeTab, setActiveTab] = React.useState<string>('details');

    const changeTab = React.useCallback((tab: string) => {
        setActiveTab(tab);
    }, [setActiveTab]);

    return (
        <Container className="main-container">
            <Nav tabs>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === 'details' })} onClick={() => changeTab('details')}>
                        Personal details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === 'password' })} onClick={() => changeTab('password')}>
                        Password and security
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="details">
                    <EditDetails />
                </TabPane>
                <TabPane tabId="password">
                    <ChangePassword />
                </TabPane>
            </TabContent>
        </Container>
        );
};

