import * as React from 'react';
import { ValidationErrors } from "pojo-validator";
import { SubscriptionLicense } from '../../../api/models/SubscriptionLicense';
import { ContainerComponentProps } from 'react-withcontainer';

export interface LicenseEditContainerProps extends ContainerComponentProps<LicenseEditUiProps> {
    model: SubscriptionLicense,
    change: (changes: Partial<SubscriptionLicense>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,
}

export interface LicenseEditUiProps {
    model: SubscriptionLicense,
    change: (changes: Partial<SubscriptionLicense>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,
}

export const LicenseEditContainer = (props: LicenseEditContainerProps) => {
    let { component, ...rest } = props;

    const Component = component;
    return (
        <Component {...rest} />
    );
}
