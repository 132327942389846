import * as React from 'react';
import { CardHeader, Card } from 'reactstrap';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';
import { ValidationErrors } from 'pojo-validator';

export interface QuestionChoiceRAGUiProps {
    model: QuestionChoice,
    isCreate: boolean,
    change: (changes: Partial<QuestionChoice>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,

    isPublished: boolean,
    isReadOnly: boolean,
}

/**
 * Special QuestionChoiceUi component that handles the very simplifieid answers in RAG question sets.
 * @param props
 * @returns
 */
export const QuestionChoiceRAGUi = (props: QuestionChoiceRAGUiProps) => {
    // Convert the name into a visible color.
    const color = React.useMemo(() =>
        props.model.name.toLowerCase().indexOf('green') >= 0 ? 'success'
            : props.model.name.toLowerCase().indexOf('amber') >= 0 ? 'warning'
                : props.model.name.toLowerCase().indexOf('red') >= 0 ? 'danger'
                    : undefined
    , [props.model]);

    return (
        <Card color={color}>
            <CardHeader>
                {props.model.name? props.model.name: '(New answer)'}
            </CardHeader>
        </Card>
    );
};
