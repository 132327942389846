import * as React from 'react';
import { NavItem } from 'reactstrap';

export const SidebarNavTitle = (props: any) => {
    return (
        <NavItem className={'nav-title ' + props.className || ''}>
            {props.children}
        </NavItem>
    );
};
