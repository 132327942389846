import * as React from 'react';
import { Edit } from '../edit/Edit';
import { useUniversalNavigation } from 'react-universal-navigation';

/**
 * Edit for school due diligence question sets.
 */
export const SchoolDueDiligenceEdit = (props: any) => {
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');

    return (
        <Edit id={id}  baseRoute="/administration/school-due-diligence" />
        );
};