import { AnyAction, Dispatch } from 'redux';

export const logoutType = '@@User/LOGOUT';

export interface LogoutAction {
    type: typeof logoutType
}

export function logout() {
    return {
        type: logoutType
    } as LogoutAction;
}
