import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { RAGPdfProps } from './RAGPdf';
import { Styles } from '../../executiveSummary/pdf/Styles';
import { Guid } from 'guid-string';
import { ProjectAnalysisResultItem } from '../../../../api/models/ProjectAnalaysisResult';
import { getGradeString } from '../../../../utilities/getGradeString';
import { RAGProjectTopicPdfUi } from './RAGProjectTopicPdfUi';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';


export interface RAGProjectSectionPdfUiProps extends RAGPdfProps {
    projectSectionId: Guid,
}

/**
 * Breakdown of questions by sections and topics for RAG (pdf format).
 */
export const RAGProjectSectionPdfUi = (props: RAGProjectSectionPdfUiProps) => {
    // Find the appropriate items we want to work with.
    let [projectSection, section, /*results,*/ projectTopics, myResults] = React.useMemo(() => {
        let ps = props.projectSections.models.find(item => item.id === props.projectSectionId);
        let sectionId = '';
        if (ps) {
            sectionId = ps.sectionId;
        }

        let s = props.sections.find(item => item.id === sectionId);

        //let r = props.resultsBySection.find(item => item.projectSectionId === props.projectSectionId);
        //let rr: Array<ProjectAnalysisResult> = [];
        //if (r) {
        //    rr = r.results;
        //}

        let childTopics = props.topics.filter(item => item.sectionId === sectionId);
        let pt = props.projectTopics.models.filter(item => childTopics.find(it => it.id === item.topicId));

        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (props.results && props.results.length) {
            myResults = props.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        return [ps, s, /*rr,*/ pt, myResults];
    }, [props.projectSections, props.sections, props.projectSectionId, props.topics, props.projectTopics, props.resultsBySection]);

    if (!props.model) {
        return (<></>);
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View>
            <View style={{ ...Styles.row, marginBottom: 5, }}>
                <View style={{ ...Styles.col, width: '65%' }}>
                    <Text style={Styles.h4}>{section && replacePlaceholders(section.name, placeholderReplacementMode, { subjectName: props.subject?.name })}</Text>
                </View>
            </View>
            <View>
                {
                    projectTopics.length ? (
                        <>
                            {
                                projectTopics.map((item, index) => (
                                    <RAGProjectTopicPdfUi key={item.id} projectTopicId={item.id} isFirstTopic={index === 0} {...props} />
                                ))
                            }
                        </>
                    ) : null
                }
            </View>
        </View>
    );
};
