import * as React from 'react';
import { FormGroup, Label, Row, Col, Button, Collapse, CardHeader, Card, CardBody, Badge, Nav, NavItem, NavLink, TabContent, TabPane, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidatedElasticInput } from '../../../shared/ValidatedElasticInput';
import { Question } from '../../../../api/models/Question';
import { ValidationErrors } from 'pojo-validator';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';
import { ManagedChildModels } from '../../../../components/shared/hooks/useManagedChildModels';
import { QuestionChoiceUi } from './QuestionChoiceUi';
import { Impact } from '../../../../api/models/Impact';
import Switch from 'react-switch';
import { Tag } from '../../../../api/models/Tag';
import { QuestionTag } from '../../../../api/models/QuestionTag';
import { TagUi } from './TagUi';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AwardTag } from '../../../../api/models/AwardTag';
import { Video } from '../../../../api/models/Video';
import { ProductTier, productTierDisplayName, productTiers } from '../../../../api/models/codeOnly/ProductTeir';
import { QuestionSetTypeSettings } from '../QuestionSetType';
import { QuestionChoiceRAGUi } from './QuestionChoiceRAGUi';

export interface QuestionUiProps {
    model: Question,
    questionSetType: QuestionSetTypeSettings
    isCreate: boolean,
    change: (changes: Partial<Question>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,

    questionChoices: ManagedChildModels<QuestionChoice>,

    impacts: Array<Impact>,
    allTags: Array<Tag>,
    awardTags: Array<AwardTag>,
    videos: Array<Video>,
    questionTags: ManagedChildModels<QuestionTag>,
    isPublished: boolean,
    isReadOnly: boolean,

    changeQuestionOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,

    schoolEvidenceNames: Array<string>,
}

export const QuestionUi = (props: QuestionUiProps) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(props.isCreate); 
    const toggleOpen = React.useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, [setIsOpen]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.change({ [target.name]: (target.type === 'checkbox' ? target.checked : target.value) });
    }, [props.change]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    let questionChoices = React.useMemo(() => props.questionChoices.models.filter(it => it.questionId == props.model.id), [props.questionChoices.models]);

    const toggleTag = React.useCallback((tagId: string) => {
        //If questionTags has a record for this question and tag, then remove it
        let existingTag = props.questionTags.models.find(it => it.questionId === props.model.id && it.tagId === tagId);
        if (existingTag) {
            props.questionTags.removeModel(existingTag.id);
        } else {
            props.questionTags.addModel({
                questionId: props.model.id,
                tagId: tagId
            });
        }
    }, [props.questionTags, props.model]);

    const [strengthsActiveTab, setStrengthsActiveTab] = React.useState<'excellence' | 'strength' | 'risk'>('excellence');

    return (
        <Card>
            <CardHeader onClick={toggleOpen} style={{ cursor: 'pointer' }}>
                <Row>

                    <ConditionalFragment showIf={!props.isReadOnly}>
                        <Col xs="auto">
                            <Button size="sm" color="primary" outline onClick={(e) => { e.preventDefault(); setIsOpen(false); props.changeQuestionOrder('up', props.model.id, props.model.topicId); e.stopPropagation(); }}>
                                <FontAwesomeIcon icon='caret-up' />
                            </Button>
                            <Button size="sm" className="ml-1" color="primary" outline onClick={(e) => { e.preventDefault(); setIsOpen(false); props.changeQuestionOrder('down', props.model.id, props.model.topicId); e.stopPropagation(); }}>
                                <FontAwesomeIcon icon='caret-down' />
                            </Button>
                        </Col>
                    </ConditionalFragment>

                    <Col>
                        {props.model.name ? props.model.name : '(New question)'}
                        <> </>
                        {props.model.questionNumber ? (<small className="text-muted">[{props.model.questionNumber}]</small>) : null}
                    </Col>
                    {
                        props.questionSetType.tierChoice ? (
                            <Col xs="auto">
                                <Badge color="secondary">
                                    {productTierDisplayName(props.model.tier as ProductTier)}
                                </Badge>
                            </Col>
                        ): null
                    }
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                    </Col>
                </Row>
            </CardHeader>

            <Collapse isOpen={isOpen}>
                {
                    isOpen ? (
                        <CardBody>
                            <FormGroup>
                                <Label htmlFor="name">Question</Label>
                                <Row>
                                    <Col>
                                        <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="name" autoComplete="off" placeholder="Question name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                    </Col>
                                    {
                                        !props.isPublished /* Can't remove questions from a published question set */ ? (
                                            <Col xs="auto">
                                                <Button color="link" onClick={props.remove}><FontAwesomeIcon icon="trash-alt" color="danger" /><span className="sr-only">Remove</span></Button>
                                            </Col>
                                        ) : null
                                    }
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="questionNumber">Question reference</Label>
                                <ValidatedInput type="text" readOnly={props.isReadOnly} name="questionNumber" autoComplete="off" placeholder="Recommended format: AA000" value={props.model.questionNumber} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                            </FormGroup>
                            <ConditionalFragment showIf={props.questionSetType.impactChoice}>
                                <FormGroup>
                                    <Label htmlFor="impactId">Impact</Label>
                                    <ValidatedInput type="select" readOnly={props.isReadOnly} name="impactId" placeholder="Impact" value={props.model.impactId} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors}>
                                        <option value="">(Select an impact)</option>
                                        {
                                            props.impacts.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={props.questionSetType.tierChoice}>
                                <FormGroup>
                                    <Label htmlFor="tier">Tier</Label>
                                    <ValidatedInput type="select" readOnly={props.isReadOnly} name="tier" placeholder="Tier" value={props.model.tier} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors}>
                                        <option value="">(Select an tier)</option>
                                        {
                                            productTiers.map(item => (
                                                <option key={item} value={item}>{productTierDisplayName(item)}</option>
                                                ))
                                        }
                                    </ValidatedInput>
                                    <FormText>
                                        This question will be unlocked for a school's self evaluation once the school unlocks this tier.  Please note that tiers only affect self evaluation.  Any other specific reviews this questions appears within will ignore this tier and show the question anyway to any school with access to the question regardless of the school's tier.
                                    </FormText>
                                </FormGroup>
                            </ConditionalFragment>
                            {
                                props.questionSetType.ragAnswers ? (
                                    <FormGroup>
                                        <Label htmlFor="">RAG answers</Label>
                                        <div>
                                            <div>
                                                {
                                                    questionChoices.map((item, index) => (
                                                        <QuestionChoiceRAGUi key={item.id} model={item} isCreate={props.questionChoices.isAddedModel(item.id)}
                                                            change={(changes: Partial<Question>) => props.questionChoices.change(item.id, changes)}
                                                            validate={(fieldsToCheck?: Array<string>) => props.questionChoices.validateModel(item.id, fieldsToCheck)}
                                                            validationErrors={props.questionChoices.validationErrorsFor(item.id)} remove={() => props.questionChoices.removeModel(item.id)}
                                                            isPublished={props.isPublished}
                                                            isReadOnly={props.isReadOnly}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                )
                                    : (
                                        <FormGroup>
                                            <Label htmlFor="">Multiple choice answers</Label>
                                            <div>
                                                <div>
                                                    {
                                                        questionChoices.map((item, index) => (
                                                            <QuestionChoiceUi key={item.id} model={item} isCreate={props.questionChoices.isAddedModel(item.id)}
                                                                change={(changes: Partial<Question>) => props.questionChoices.change(item.id, changes)}
                                                                validate={(fieldsToCheck?: Array<string>) => props.questionChoices.validateModel(item.id, fieldsToCheck)}
                                                                validationErrors={props.questionChoices.validationErrorsFor(item.id)} remove={() => props.questionChoices.removeModel(item.id)}
                                                                isPublished={props.isPublished}
                                                                isReadOnly={props.isReadOnly}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    !props.isReadOnly /* We do allow addition of choices to questions for published question sets as long as we are not read only */ ? (
                                                        <Button color="primary" outline onClick={() => props.questionChoices.addModel({ questionId: props.model.id })}>Add answer</Button>
                                                    ) : null
                                                }
                                            </div>
                                        </FormGroup>
                                    )
                            }

                            {/* RAG questions don't have excellence/strength/development questions or uploads etc. as they are a simplified question format. */}
                            <ConditionalFragment showIf={!props.questionSetType.ragAnswers}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={strengthsActiveTab === 'excellence' ? 'active' : ''} onClick={() => setStrengthsActiveTab('excellence')}>
                                            Excellence statement
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={strengthsActiveTab === 'strength' ? 'active' : ''} onClick={() => setStrengthsActiveTab('strength')}>
                                            Area of strength
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={strengthsActiveTab === 'risk' ? 'active' : ''} onClick={() => setStrengthsActiveTab('risk')}>
                                            {props.questionSetType.riskText}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={strengthsActiveTab} className="mb-2">
                                    <TabPane tabId="excellence">
                                        <FormGroup>
                                            <Label htmlFor="autoAddToExcellenceReport">Automatically add excellence statement to areas of strength report based on operational level</Label>
                                            <div>
                                                <Switch name="autoAddToExcellenceReport" readOnly={props.isReadOnly} checked={props.model.autoAddToExcellenceReport} onChange={(checked) => { if (props.isReadOnly) { return; } props.change({ autoAddToExcellenceReport: checked }); }} />
                                            </div>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="strengthsReportMinimumOperationalLevel">Add excellence statement to areas of strength report if operational level is less than or equal to</Label>
                                            <ValidatedInput type="number" step="0.1" readOnly={props.isReadOnly} name="excellenceReportMinimumOperationalLevel" placeholder="Operation level" value={props.model.excellenceReportMinimumOperationalLevel} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="strengthsReportDescription">Excellence statement for areas of strength report</Label>
                                            <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="excellenceReportDescription" placeholder="Excellence statement" value={props.model.excellenceReportDescription} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                        </FormGroup>
                                    </TabPane>

                                    <TabPane tabId="strength">
                                        <FormGroup>
                                            <Label htmlFor="autoAddToStrengthReport">Automatically add to areas of strength report based on operational level</Label>
                                            <div>
                                                <Switch name="autoAddToStrengthReport" readOnly={props.isReadOnly} checked={props.model.autoAddToStrengthsReport} onChange={(checked) => { if (props.isReadOnly) { return; } props.change({ autoAddToStrengthsReport: checked }); }} />
                                            </div>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="strengthsReportMinimumOperationalLevel">Add to areas of strength report if operational level is less than or equal to</Label>
                                            <ValidatedInput type="number" step="0.1" readOnly={props.isReadOnly} name="strengthsReportMinimumOperationalLevel" placeholder="Operation level" value={props.model.strengthsReportMinimumOperationalLevel} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="strengthsReportDescription">Description for areas of strength report</Label>
                                            <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="strengthsReportDescription" placeholder="Area of strength" value={props.model.strengthsReportDescription} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                        </FormGroup>
                                    </TabPane>

                                    <TabPane tabId="risk">
                                        <FormGroup>
                                            <Label htmlFor="autoAddToRiskReport">Automatically add to {props.questionSetType.riskTextAddToSentence} based on operational level</Label>
                                            <div>
                                                <Switch name="autoAddToRiskReport" readOnly={props.isReadOnly} checked={props.model.autoAddToRiskReport} onChange={(checked) => { if (props.isReadOnly) { return; } props.change({ autoAddToRiskReport: checked }); }} />
                                            </div>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="riskReportMinimumOperationalLevel">Add to {props.questionSetType.riskTextAddToSentence} if operational level is greater than or equal to</Label>
                                            <ValidatedInput type="number" step="0.1" readOnly={props.isReadOnly} name="riskReportMinimumOperationalLevel" placeholder="Operation level" value={props.model.riskReportMinimumOperationalLevel} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="riskReportDescription">Description for {props.questionSetType.riskTextAddToSentence}</Label>
                                            <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="riskReportDescription"
                                                placeholder={props.questionSetType.riskText}
                                                value={props.model.riskReportDescription} onChange={onChange}
                                                onBlur={onValidate} validationErrors={props.validationErrors} />
                                        </FormGroup>
                                    </TabPane>
                                </TabContent>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label htmlFor="isFeedbackAllowed">Prompt for feedback</Label>
                                            <div>
                                                <Switch name="isFeedbackAllowed" readOnly={props.isReadOnly} checked={props.model.isFeedbackAllowed} onChange={(checked) => { if (props.isReadOnly) { return; } props.change({ isFeedbackAllowed: checked }); }} />
                                            </div>

                                            {
                                                props.model.isFeedbackAllowed ? (
                                                    <>
                                                        <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="feedbackDescription" placeholder="Your own notes here" value={props.model.feedbackDescription} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                                    </>
                                                ) : null
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label htmlFor="isUploadAllowed">Prompt for upload</Label>
                                            <div>
                                                <Switch name="isUploadAllowed" readOnly={props.isReadOnly} checked={props.model.isUploadAllowed} onChange={(checked) => { if (props.isReadOnly) { return; } props.change({ isUploadAllowed: checked }); }} />
                                            </div>

                                            {
                                                props.model.isUploadAllowed ? (
                                                    <>
                                                        <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="uploadDescription" placeholder="Possible sources of evidence" value={props.model.uploadDescription} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                                    </>
                                                ) : null
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ConditionalFragment>

                            
                            <div>
                                <FormGroup>
                                    <Label htmlFor="videoId">Micro-consultation video</Label>
                                    <ValidatedInput type="select" readOnly={props.isReadOnly} name="videoId" value={props.model.videoId || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors}>
                                        <option value="">(No micro-consultation video)</option>
                                        {
                                            props.videos.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </div>


                            <div>
                                <FormGroup>
                                    <Label htmlFor="tags">School types</Label>
                                    <div className={`tag-component ${props.isReadOnly? 'tag-component-readonly': ''}`}>
                                        {
                                            props.allTags.filter(item => item.isSchoolType).map(item => (
                                                <TagUi key={item.id} isReadOnly={props.isReadOnly} isSelected={!!props.questionTags.models.find(it => it.questionId === props.model.id && it.tagId === item.id)} question={props.model} tag={item} toggleSelected={() => toggleTag(item.id)} />
                                            ))
                                        }
                                    </div>
                                    <FormText>
                                        This question will be available to any school with at least one of the selected school types.
                                    </FormText>
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label htmlFor="tags">School phases</Label>
                                    <div className={`tag-component ${props.isReadOnly ? 'tag-component-readonly' : ''}`}>
                                        {
                                            props.allTags.filter(item => !item.isSchoolType).map(item => (
                                                <TagUi key={item.id} isReadOnly={props.isReadOnly} isSelected={!!props.questionTags.models.find(it => it.questionId === props.model.id && it.tagId === item.id)} question={props.model} tag={item} toggleSelected={() => toggleTag(item.id)} />
                                            ))
                                        }
                                    </div>
                                    <FormText>
                                        This question will be available to any school with at least one of the selected school phases.
                                    </FormText>
                                </FormGroup>
                            </div>
                            <ConditionalFragment showIf={props.questionSetType.specificReviews}>
                                <div>
                                    <FormGroup>
                                        <Label htmlFor="tags">Specific reviews</Label>
                                        <div className={`tag-component ${props.isReadOnly ? 'tag-component-readonly' : ''}`}>
                                            {
                                                props.awardTags.map(item => (
                                                    <TagUi key={item.id} isReadOnly={props.isReadOnly} isSelected={!!props.questionTags.models.find(it => it.questionId === props.model.id && it.tagId === item.id)} question={props.model} tag={item} toggleSelected={() => toggleTag(item.id)} />
                                                ))
                                            }
                                        </div>
                                        <FormText>
                                            This question will be available within each of the selected specific reviews.  A school will see the question within a specific review so long as the school completing the review has a matching school type and school phase from those selected above for this question.
                                        </FormText>
                                    </FormGroup>
                                </div>
                            </ConditionalFragment>
                        </CardBody>
                    ) : null
                }
            </Collapse>
        </Card>
    );
};
