import { Document, ImageRun, Paragraph, TextRun, HeadingLevel, Table, TableRow, TableCell, BorderStyle, ShadingType, WidthType } from "docx";
import { ReportProps } from "../../reportGenerator/ReportProps";
import { ImageEnumerator, ImageWithMetadata } from "../../executiveSummary/pdf/ImageEnumerator";
import { isNullOrUndefined } from "util";
import { RAGBreakdownDocxUi } from "./RAGBreakdownDocxUi";
import { RAGProjectWillDoDocxUi } from "./RAGProjectWillDoDocxUi";
import { replacePlaceholders } from "../../../../utilities/replacePlaceholders";

export interface RAGDocxProps extends ReportProps {
    chartImages: Array<ImageWithMetadata>
}

/**
 * Generate and return a Word DOCX document for the RAG report.
 * 
 * NOTE We can't use react to generate docx files, but the docx javascript library does lets us follow a similar code workflow, so we 
 * continue to pass props etc. as if it was react-pdf like to keep code between screen/pdf/docx versions as similar as possible, while knowing
 * the word version will have the most specific internals to cope with this.
 */
export function RAGDocx(props: RAGDocxProps) {
    const { projectSettings, reportSettings } = props;
    const imageEnumerator = new ImageEnumerator(props.chartImages);

    if (!props.model) {
        new Document({
            sections: [
                {
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun("Word document could not be generated."),
                            ],
                        }),
                    ]
                }
            ],
        });
    }

    return new Document({
        styles: {
            // Deault font should be Arial.
            default: {
                document: {
                    run: {
                        font: 'Arial',
                    }
                },
                heading1: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
                heading2: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
                heading3: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
                heading4: {
                    run: {
                        color: '#000000',
                        bold: true,
                    },
                },
            },
            paragraphStyles: [

            ],
        },
        sections: [
            {
                children: finalizeChildren([
                    new Paragraph({
                        text: props.school ? props.school.name
                            : props.trust ? props.trust.name
                                : '',
                        heading: HeadingLevel.TITLE,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: 'Evaluate-Ed ' + (
                                    replacePlaceholders(reportSettings.reportName, props.placeholderReplacementMode, { subjectName: props.subject?.name })
                                    ),
                                color: '#808080',
                            })
                        ],
                    }),

                    /* Show school logo/banner if it has one */
                    ...(!!props.reportBannerBlobDataUrl ? ([
                        new Paragraph({
                            children: finalizeChildren([
                                new ImageRun({
                                    data: props.reportBannerBlobDataUrl.dataUrl,
                                    transformation: {
                                        width: 600,
                                        height: Math.round(600 / props.reportBannerBlobDataUrl.aspectRatio),
                                    },
                                }),
                            ]),
                        }),
                        new Paragraph({ text: '', }), // Empty paragraph for spacing and making customising the document easier.
                        ])
                        : []),
                    ...(reportSettings.reportName.toLowerCase().indexOf("helpful") >= 0 ? ([new Table({
                        rows: finalizeChildren([
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Two Key Points:",
                                                        color: "#ffffff"
                                                    })
                                                ]
                                                
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.THICK,
                                            },
                                            bottom: {
                                                style: BorderStyle.THICK,
                                            },
                                        },
                                        shading: {
                                            type: ShadingType.SOLID,
                                            color: '#1c599c',
                                        },
                                        width: {
                                            size: 50,
                                            type: WidthType.PERCENTAGE,
                                        },
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Two Key Questions:",
                                                        color: "#ffffff"
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.THICK,
                                            },
                                            bottom: {
                                                style: BorderStyle.THICK,
                                            },
                                        },
                                        shading: {
                                            type: ShadingType.SOLID,
                                            color: '#1c599c',
                                        },
                                        width: {
                                            size: 50,
                                            type: WidthType.PERCENTAGE,
                                        },
                                    }),
                                ],

                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text:"The curriculum is the progression model.\nProgress is knowing more, remembering more, and being able to use knowledge and skills appropriately."
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.THICK,
                                            },
                                            bottom: {
                                                style: BorderStyle.THICK,
                                            },
                                        },
                                        shading: {
                                            type: ShadingType.SOLID,
                                            color: '#ffffff',
                                        },
                                        width: {
                                            size: 50,
                                            type: WidthType.PERCENTAGE,
                                        },
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text:"Is the full curriculum offered?\nDo all learners access the curriculum?"
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.THICK,
                                            },
                                            bottom: {
                                                style: BorderStyle.THICK,
                                            },
                                        },
                                        shading: {
                                            type: ShadingType.SOLID,
                                            color: '#ffffff',
                                        },
                                        width: {
                                            size: 50,
                                            type: WidthType.PERCENTAGE,
                                        },
                                    }),
                                ],
                            })

                        ])
                    })]):[]),
 

                    RAGBreakdownDocxUi(props),
                    RAGProjectWillDoDocxUi(props),
                ]),
            }
        ],
    });
}

/**
 * Helper function that lets our code read a bit more like react components, but cleans up and flattens the children generated ready for use.
 * @param children
 */
export function finalizeChildren<T>(children: Array<any>): Array<any> {
    // Flatten down to a single level.
    let ret = children.flat(10);
    // Strip out nulls or undefineds left from conditional statements.
    ret = ret.filter(item => !isNullOrUndefined(item));

    return ret;
}