import * as React from 'react';
import { Button, ButtonGroup, Col, Input, Row, Table } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { Link } from 'react-router-dom';
import { ListContainer, ListUiProps } from './ListContainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { InfiniteScrollingTable } from '../../shared/infiniteScrolling/InfiniteScrollingTable';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';
import { DateLabel } from '../../shared/DateLabel';
import moment, { Moment } from 'moment';
import { isNullOrUndefined } from 'util';

export const ListUi = (props: ListUiProps) => {
    const navigation = useUniversalNavigation(props);
    const loadPage = React.useCallback((page: number) => {
        props.loadPage(page, props.search);
    }, [props.loadPage, props.search]);

    const [menuIsOpen, setMenuIsOpen] = React.useState<{ [id: string]: boolean }>({});
    const toggleMenuOpen = React.useCallback((id: string) => {
        setMenuIsOpen(prevState => ({
            ...prevState,
            [id]: (prevState[id] ? false : true)
        }));
    }, [setMenuIsOpen]);

    // Open the default action when the user clicks on a row.
    const onRowDoubleClick = React.useCallback((id: string, event: React.MouseEvent<HTMLTableRowElement>) => {
        if (id != "") {
            navigation.push(`/administration/subscriptions/edit/${encodeURIComponent(id)}`);
        }
    }, [navigation]);

    // Manage the subscription by changing the user's current subscription in redux and redirecting to the manage screen.
    const manageSubscription = React.useCallback(async (subscriptionId: string) => {
        let trust = await props.getTrustFromSubscription(subscriptionId);
        let selectResult = await props.selectSubscription(subscriptionId);
        navigation.navigate(`/manage`);
    }, [props.getTrustFromSubscription, navigation]);

    const [filter, setFilter] = React.useState<string>('current');
    const filteredItems = React.useMemo(() => {
        if (!props.model) {
            return props.model;
        }

        const nowIsoString = (moment() as Moment).toISOString();
        if (filter === 'current') {
            return props.model.filter(item => !item.renewalDate || moment(item.renewalDate).toISOString() >= nowIsoString);
        } else if (filter === 'expired') {
            return props.model.filter(item => item.renewalDate && moment(item.renewalDate).toISOString() < nowIsoString);
        } else if (filter === 'trial') {
            return props.model.filter(item => item.license === 'Trial');
        } else if (filter === 'paid') {
            return props.model.filter(item => (!item.renewalDate || moment(item.renewalDate).toISOString() >= nowIsoString) && item.license !== 'Trial');
        } else if (filter === 'expiredTrial') {
            return props.model.filter(item => (item.renewalDate && moment(item.renewalDate).toISOString() < nowIsoString) && item.license === 'Trial');
        }

        // Return everything.
        return props.model;
    }, [props.model, filter]);

    return (
        <div className="main-container">
            <AlertOnErrors errors={props.loadingErrors} />

            <div className="toolbar-top">
                <ButtonGroup>
                    <LinkContainer to={`/administration/subscriptions/add`}>
                        <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add trust</span></Button>
                    </LinkContainer>
                    <LinkContainer to={`/administration/subscriptions/addSingleSchool`}>
                        <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add single school</span></Button>
                    </LinkContainer>
                    <LinkContainer to={`/administration/subscriptions/addAwardingOrganisation`}>
                        <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add awarding organisation</span></Button>
                    </LinkContainer>
                </ButtonGroup>
            </div>

            <div className="search-bar-container">
                <Row>
                    <Col>
                        <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
                    </Col>
                    <Col xs="auto">
                        <Input type="select" value={filter} onChange={e => setFilter(e.currentTarget.value)}>
                            <option value="current">Current licences</option>
                            <option value="trial">Trial licences</option>
                            <option value="paid">Paid licences</option>
                            <option value="expiredTrial">Expired trials</option>
                            <option value="expired">All expired licences</option>
                            <option value="all">All</option>
                        </Input>
                    </Col>
                </Row>
            </div>

            <div className="table-responsive">
                <Table striped hover className="table-sm">
                    <thead>
                        <tr>
                            <th><span className="d-none d-md-inline">Name</span></th>
                            <th><span className="d-none d-md-inline">Unique identifier</span></th>
                            <th><span className="d-none d-md-inline">Subscription owner</span></th>
                            <th><span className="d-none d-md-inline">Email</span></th>
                            <th><span className="d-inline d-md-none">Subscription</span></th>
                            <th><span className="d-none d-md-inline">Licence</span></th>
                            <th><span className="d-none d-md-inline">Joined on</span></th>
                            <th><span className="d-none d-md-inline">Renewal date</span></th>
                            <th><span className="d-none d-lg-inline">Schools</span></th>
                            <th><span className="d-none d-lg-inline">Potential schools</span></th>
                            <th><span className="d-none d-sm-inline d-lg-none">Usage</span></th>
                            <th><span>&nbsp;</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredItems && !filteredItems.length ? (
                                <tr className="nothing-here">
                                    <td colSpan={11}>
                                        {
                                            props.search ? (
                                                <div>
                                                    <FontAwesomeIcon icon="search-minus" />
                                                    Sorry there are no subscriptions matching "{props.search}".
                                                </div>
                                            ) : (
                                                    <div>
                                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                        You haven't added any subscriptions yet.<br />
                                                        You can <Link to="/administration/subscriptions/add">add one</Link> to get started.
                                                </div>
                                                )
                                        }
                                    </td>
                                </tr>
                            ) : null
                        }

                        <InfiniteScrollingTable key={props.search} loadData={loadPage} nextPage={props.nextPage}>
                            {(filteredItems || []).map((item, index) => {
                                const daysLeft = !item.renewalDate ? undefined : moment(item.renewalDate).diff(moment(), 'days');

                                return (
                                    <tr key={index} onDoubleClick={e => onRowDoubleClick(item.id, e)}>
                                        <td><span className="d-none d-md-inline">{item.companyName}</span></td>
                                        <td><span className="d-none d-md-inline">{item.dfeReference}</span></td>
                                        <td><span className="d-none d-md-inline">{item.name}</span></td>
                                        <td><span className="d-none d-md-inline">{item.email}</span></td>
                                        <td><div className="d-inline d-md-none">
                                            <div>{item.companyName}</div>
                                            <div>{item.name}</div>
                                            <div>{item.email}</div>
                                        </div></td>
                                        <td><span className="d-none d-md-inline">{item.license}</span></td>
                                        <td><span className="d-none d-md-inline">{
                                            item.joinedDate ? (
                                                <DateLabel format="DD/MM/YYYY" value={item.joinedDate} />
                                            ) : null
                                        }</span></td>
                                        <td><span className={`d-none d-md-inline`}>
                                            {item.renewalDate ? (
                                            <DateLabel format="DD/MM/YYYY" value={item.renewalDate} />
                                        ) : null
                                            }
                                            <div className={!daysLeft || daysLeft < 30 ? 'text-danger' : daysLeft < 90 ? 'text-warning' : 'text-muted'}>
                                                <small>
                                                    {
                                                        isNullOrUndefined(daysLeft) ? (<></>)
                                                            : daysLeft < 0 ? (<>Expired</>)
                                                                : daysLeft < 1 ? (<>Renewal in {daysLeft} day</>)
                                                                        : (<>Renewal in {daysLeft} days</>)
                                                    }
                                                </small>
                                            </div>
                                        </span></td>
                                        <td><span className="d-none d-lg-inline">{item.numberOfSchools}</span></td>
                                        <td><span className="d-none d-lg-inline">{item.numberOfPotentialSchools}</span></td>
                                        <td><div className="d-none d-sm-inline d-lg-none">
                                            <small>
                                                <div><span className="text-muted">Schools:</span> {item.numberOfTrusts}</div>
                                                <div><span className="text-muted">Potential schools:</span> {item.numberOfPotentialSchools}</div>
                                            </small>
                                        </div></td>
                                        <td className="actions">
                                            <Button color="primary" onClick={(e) => {
                                                e.preventDefault();
                                                manageSubscription(item.id);
                                            }}>
                                                <FontAwesomeIcon icon="atom" /> Manage
                                            </Button>
                                            <LinkContainer to={`/administration/subscriptions/edit/${encodeURIComponent(item.id)}`}>
                                                <Button color="primary" outline>Edit</Button>
                                            </LinkContainer>
                                        </td>
                                    </tr>
                                );
                            })}
                        </InfiniteScrollingTable>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const List = withContainer(ListContainer)(ListUi);
