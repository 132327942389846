import * as React from "react";
import { useLoadProjectWithRetry } from "../../subjectReviews/useLoadProjectWithRetry";
import { useUniversalNavigation } from "react-universal-navigation";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { AlertOnErrors } from "../../shared/AlertOnErrors";
import { Redirect } from "react-router";

export const LookupSubjectLeadershipReviewReport = (props: any) => (<LookupSubjectReport {...props} questionSetName="Subject leadership review" />);
export const LookupSubjectHelpfulQuestionsReport = (props: any) => (<LookupSubjectReport {...props} questionSetName="Subject helpful questions" />);

export interface LookupSubjetReportProps {
    questionSetName: string,
}

/**
 * Component that will look up a report for a subject for a school, and then redirect to the actual report generator passing the correct project.
 * @param props
 */
export const LookupSubjectReport = (props: LookupSubjetReportProps) => {
    const { questionSetName } = props;

    const navigation = useUniversalNavigation(props);
    const schoolId = navigation.getParam('schoolId', '');
    const subjectId = navigation.getParam('subjectId', '');

    const { project, loadProjectErrors, } = useLoadProjectWithRetry({
        schoolId: schoolId,
        trustId: '',
        questionSetName: questionSetName,
        subjectId: subjectId,
        awardTagId: undefined,
        createIfMissing: true,
    });

    // UI when loading.
    if (!project && !loadProjectErrors) {
        return (<LoadingIndicator fullWidth />);
    }

    // UI when an error occured looking up a project.
    if (!project && loadProjectErrors) {
        return (<AlertOnErrors errors={[loadProjectErrors]} />);
    }

    // UI to redirect once we have the right details.
    const newUrl = `${window.location.pathname}/${project && project.id || ''}`;
    return (<Redirect to={newUrl} />)

};