import * as React from 'react';
import { Form, FormGroup, Label, Alert, Row, Col, Container, Button, Collapse, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { Guid } from "guid-string";
import { EditUiProps, EditContainerProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';
import { ValidatedElasticInput } from '../../shared/ValidatedElasticInput';
import { TwoValueSwitch } from '../../shared/TwoValueSwitch';
import { ConditionalFragment } from 'react-conditionalfragment';

export const EditUi = (props: EditUiProps) => {
    let { validationErrors, ...rest } = props;

    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation, props.model]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type === 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const onResendInvite = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        props.resendInvite();
    }, [props.resendInvite]);

    const toggleAwardTag = React.useCallback((awardTagId: string) => {
        if (!props.model) {
            return;
        }

        const existing = props.userProfileAwardTags.models.find(it => it.awardTagId === awardTagId);
        if (existing) {
            props.userProfileAwardTags.removeModel(existing.id);
        } else {
            props.userProfileAwardTags.addModel({ id: Guid.newGuid(), awardTagId: awardTagId, userId: props.model.id });
        }
    }, [props.userProfileAwardTags, props.model]);

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add user' : 'Edit user'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            {
                props.inviteResent ? (
                    <Alert color="success">
                        Invite has been resent.
                    </Alert>
                ) : props.resendInviteErrors ? (
                    <Alert color="danger">
                        Could not resend invite: {props.resendInviteErrors}
                    </Alert>
                ) : null
            }

            <Form onSubmit={onSubmit}>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="forename">Forename</Label>
                            <ValidatedInput type="text" name="forename" placeholder="Forename" value={props.model.forename} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="surname">Surname</Label>
                            <ValidatedInput type="text" name="surname" placeholder="Surname" value={props.model.surname} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label htmlFor="name">Email</Label>
                    <Row>
                        <Col>
                            <ValidatedInput /*readOnly={props.inviteSent}*/ type="email" name="email" placeholder="Email" value={props.model.email} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </Col>
                        {
                            props.inviteSent ? (
                                <Col xs="auto">
                                    <ButtonAsync color="primary" outline onClick={onResendInvite} isExecuting={props.resendingInvite}
                                        executingChildren={(<><FontAwesomeIcon icon="spinner" spin /> Sending...</>)}>
                                        <FontAwesomeIcon icon="envelope" /> Resend invite
                                </ButtonAsync>
                                </Col>
                            ) : null
                        }
                    </Row>
                </FormGroup>


                <FormGroup>
                    <Label htmlFor="name">Job title</Label>
                    <ValidatedInput type="text" name="jobTitle" value={props.model.jobTitle} onChange={onChange} onBlur={onValidate} validationErrors={validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="bio">Bio</Label>
                    <Row>
                        <Col>
                            <ValidatedElasticInput type="textarea" name="bio" value={props.model.bio} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </Col>
                    </Row>
                </FormGroup>

                <ConditionalFragment showIf={!!props.isForSingleSchool}>
                    <ConditionalFragment showIf={!props.subscription || !props.subscription.isLimitedToAwardTags}>
                    <FormGroup>
                        <Label htmlFor="isLimitedToAwardTags">Restrict access for this user to only some specific reviews, subject reviews, or reading reviews?</Label>
                        <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={props.model.isLimitedToAwardTags} onChange={checked => props.changeModel({ isLimitedToAwardTags: checked })} />
                    </FormGroup>
                    <Collapse isOpen={props.model.isLimitedToAwardTags}>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="specificReviews">Specific reviews</Label>
                                    <div>
                                        {
                                            props.awardTags.map(tag => (
                                                <div key={tag.id}>
                                                    <TwoValueSwitch leftLabel="" rightLabel={tag.name} checked={!!props.userProfileAwardTags.models.find(it => it.awardTagId === tag.id)} onChange={checked => toggleAwardTag(tag.id)} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="name">Subject leadership review</Label>
                                    <Input type="select" name="subject"
                                        value={props.model.subjectId ?? ''}
                                        onChange={e => props.changeModel({ subjectId: e.currentTarget.value || null })}>

                                        <option value="">(Not a subject lead)</option>
                                        {
                                            props.subjects.filter(item => !item.archived).map(item => (
                                                <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                            ))
                                        }
                                    </Input>
                                    {/*Subject 2 */}
                                    <ConditionalFragment showIf={!!props.model.subjectId || !!props.model.subject2Id}>
                                        <Label htmlFor="name">Subject leadership review #2</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject2Id ?? ''}
                                            onChange={e => props.changeModel({ subject2Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 3 */}
                                    <ConditionalFragment showIf={!!props.model.subject2Id || !!props.model.subject3Id}>
                                        <Label htmlFor="name">Subject leadership review #3</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject3Id ?? ''}
                                            onChange={e => props.changeModel({ subject3Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 4 */}
                                    <ConditionalFragment showIf={!!props.model.subject3Id || !!props.model.subject4Id}>
                                        <Label htmlFor="name">Subject leadership review #4</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject4Id ?? ''}
                                            onChange={e => props.changeModel({ subject4Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 5 */}
                                    <ConditionalFragment showIf={!!props.model.subject4Id || !!props.model.subject5Id}>
                                        <Label htmlFor="name">Subject leadership review #5</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject5Id ?? ''}
                                            onChange={e => props.changeModel({ subject5Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 6 */}
                                    <ConditionalFragment showIf={!!props.model.subject5Id || !!props.model.subject6Id}>
                                        <Label htmlFor="name">Subject leadership review #6</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject6Id ?? ''}
                                            onChange={e => props.changeModel({ subject6Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 7 */}
                                    <ConditionalFragment showIf={!!props.model.subject6Id || !!props.model.subject7Id}>
                                        <Label htmlFor="name">Subject leadership review #7</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject7Id ?? ''}
                                            onChange={e => props.changeModel({ subject7Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 8 */}
                                    <ConditionalFragment showIf={!!props.model.subject7Id || !!props.model.subject8Id}>
                                        <Label htmlFor="name">Subject leadership review #8</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject8Id ?? ''}
                                            onChange={e => props.changeModel({ subject8Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 9 */}
                                    <ConditionalFragment showIf={!!props.model.subject8Id || !!props.model.subject9Id}>
                                        <Label htmlFor="name">Subject leadership review #9</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject9Id ?? ''}
                                            onChange={e => props.changeModel({ subject9Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>
                                    {/*Subject 10 */}
                                    <ConditionalFragment showIf={!!props.model.subject9Id || !!props.model.subject10Id}>
                                        <Label htmlFor="name">Subject leadership review #10</Label>
                                        <Input type="select" name="subject"
                                            value={props.model.subject10Id ?? ''}
                                            onChange={e => props.changeModel({ subject10Id: e.currentTarget.value || null })}>

                                            <option value="">(No additional subjects)</option>
                                            {
                                                props.subjects.filter(item => !item.archived).map(item => (
                                                    <option key={item.id} value={item.id}>{`${item.name}`}</option>
                                                ))
                                            }
                                        </Input>
                                    </ConditionalFragment>

                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="canAccessReadingReview">Reading review?</Label>
                                    <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={props.model.readingReviewAccess} onChange={checked => props.changeModel({ readingReviewAccess: checked })} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapse>
                </ConditionalFragment>

                </ConditionalFragment>
                
                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                    {/*<LinkContainer to={"/manage/users/delete/" + props.model.id}>
                        <Button color="danger" className="float-right">
                            <FontAwesomeIcon icon="trash-alt"/> Delete
                        </Button>
                    </LinkContainer>*/}
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
export const EditForSingleSchool = (props: EditContainerProps) => (<Edit {...props} isForSingleSchool={true} />);
