import * as React from 'react';
import { Action } from '../../api/models/Action';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { ActionUserTask } from '../../api/models/ActionUserTask';
import { School } from '../../api/models/School';
import { actionStates, ActionState } from '../../services/actionStates/actionStates';
import moment, { Moment } from 'moment';
import { Card, CardHeader, Row, Col, CardBody, FormGroup, Label, Button, Badge } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { DueDateLabel } from './DueDateLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trust } from '../../api/models/Trust';
import { UserProfile } from '../../api/models/UserProfile';
import { ValidatedDateTimeInput } from '../shared/ValidatedDateTimeInput';
import { PlainTextWithBrs } from '../shared/PlainTextWithBrs';
import { Guid } from 'guid-string';
import { Link } from 'react-router-dom';
import { useProjectSettings } from '../../api/useProjectSettings';
import { AwardTag } from '../../api/models/AwardTag';
import { QuestionTag } from '../../api/models/QuestionTag';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { actionPriorityDisplayName, ActionPriority, actionPriorities } from '../../api/models/codeOnly/ActionPriority';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../utilities/replacePlaceholders';

export interface ActionCardProps {
    model: Action,
    change: (changes: Partial<Action>) => void,
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    tasks: Array<ActionUserTask>,
    changeTask: (id: string, changes: Partial<ActionUserTask>) => void,
    addTask: () => void,
    removeTask: (id: string) => void,

    trust: Trust | undefined | null,
    schools: Array<School>,
    schoolId: string | undefined | null,

    questionText: string;
    questionLabelText: string;
    questionNumber: string;

    userProfiles: Array<UserProfile>,

    saveDebounce: () => void,

    openModal: () => void,

    isPriority: boolean,
    isRecommendation: boolean,

    awardTagId: string | undefined,
    questionTags: Array<QuestionTag>,
    awardTags: Array<AwardTag>,

    placeholderReplacementMode: PlaceholderReplacementMode,
}

export const ActionCard = (props: ActionCardProps) => {
    const {
        model, change, /*remove,*/
        /*validate,*/ validationErrors,
        tasks, /*changeTask, addTask, removeTask,*/
        trust, schools, schoolId,
        saveDebounce,
        openModal,
        questionText,
        questionLabelText,
        questionNumber,
        isPriority,
        isRecommendation,
        awardTagId,
        questionTags,
        awardTags,
        placeholderReplacementMode,
    } = props;

    const actionState = React.useMemo(() => actionStates.findById(model.actionState), [model]);

    const projectSettings = useProjectSettings(props.model && props.model.projectId || '', props.awardTagId || undefined);

    // Change the state of this task
    const changeState = React.useCallback((state: ActionState) => {
        change({
            actionState: state.id,
            completedDate: state.isCompleted ? moment().toISOString() : null,
        });
        saveDebounce();
    }, [change, saveDebounce]);

    // Work out how many outstanding tasks we have.
    const outstandingTasksCount = React.useMemo(() => tasks.filter(item => !actionStates.findById(item.actionState).isCompleted).length, [tasks]);

    const color = React.useMemo(() => {
        const dateMoment: Moment = moment(model.actionAddedDate);
        const differenceDays = dateMoment.diff(moment(), 'days');

        let ret = 'dark';
        if (actionStates.findById(model.actionState).isCompleted) {
            ret = 'success';
        } else if (differenceDays <= 0) {
            ret = 'danger';
        } else if (differenceDays <= 30) {
            ret = 'warning';
        }

        return ret;
    }, [model]);


    // We want to know if model.descriptionHtml is empty or white space.
    const descriptionHtmllIsEmpty = !model.description.length;
    const school = React.useMemo(() => schools.find(item => item.id === model.schoolId), [schools, model])

    return (
        <Card className={`action-card action-card-${color}`} color={color} outline>
            <CardHeader style={{ cursor: 'pointer' }} onClick={() => openModal()}>
                {replacePlaceholders(model.name, placeholderReplacementMode)}
                <Row>
                    <ConditionalFragment showIf={!schoolId && !Guid.isEmpty(model.schoolId)}>
                        <Col xs="auto"> 
                            <div>
                                <Badge color="dark">
                                    {school && school.name || ''}
                                </Badge>
                            </div>
                        </Col>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={isPriority}>
                        <Col xs="auto">
                            <div>
                                <Badge color="secondary">
                                    Priority
                                </Badge>
                            </div>
                        </Col>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={isRecommendation}>
                        <Col xs="auto">
                            {/* question label text is set if this is a due diligence action so if that is not set
                                and there is a risk it must be an sdp recommendation question */}
                            <div>
                                <Badge color="primary">
                                    Possible area for development
                                </Badge>
                            </div>
                        </Col>
                    </ConditionalFragment>

                    {
                        props.awardTags.map(tag => {
                            if (!questionTags.find(it => it.tagId.toLowerCase() === tag.id.toLowerCase())) {
                                return null;
                            }

                            return (
                                <Col xs="auto">
                                    <div>
                                        <Badge color="primary">
                                            {tag.name}
                                        </Badge>
                                    </div>
                                </Col>
                                );
                        })
                    }
                </Row>

            </CardHeader>
            <CardBody tag="div">
                <Row>
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="actionAddedDate">Due date</Label>
                            <ValidatedDateTimeInput name="actionAddedDate" type="date" value={model.actionAddedDate || ''} onChange={e => change({ actionAddedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} onClick={e => { e.preventDefault(); openModal(); /* editing inline gets confusing as things move around the screen, so open the modal instead */ }} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={!!model.completedDate? '': 'auto'}>
                        <FormGroup>
                            <ConditionalFragment showIf={!!model.completedDate}>
                                <Label htmlFor="completedDate">
                                    Completed on
                                </Label>
                                <Row>
                                    <Col>
                                        <ValidatedDateTimeInput name="completedDate" type="date" value={model.completedDate || ''} onChange={e => change({ completedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} onClick={e => { e.preventDefault(); openModal(); /* editing inline gets confusing as things move around the screen, so open the modal instead */ }} />
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="danger" outline onClick={() => changeState(actionStates.outstanding)}>
                                            <FontAwesomeIcon icon="undo" />
                                            <> </>Undo completion
                                        </Button>
                                    </Col>
                                </Row>
                                <ConditionalFragment showIf={actionState.isCompleted && outstandingTasksCount > 0}>
                                    <span className="text-muted">
                                        {outstandingTasksCount} task(s) are still outstanding
                                    </span>
                                    <> </>
                                </ConditionalFragment>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!model.completedDate}>
                                <Row>
                                    <Col>
                                        <Label htmlFor="completedDate">&nbsp;</Label>
                                    </Col>
                                    <ConditionalFragment showIf={!actionState.isCompleted}>
                                        <Col xs="auto">
                                            <DueDateLabel date={model.actionAddedDate} />
                                        </Col>
                                    </ConditionalFragment>
                                </Row>
                                <div>
                                    <Button color="success" outline onClick={() => changeState(actionStates.completed)}>
                                        <FontAwesomeIcon icon="check" />
                                        <> </>
                                        Complete
                                    </Button>
                                </div>
                            </ConditionalFragment>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label htmlFor="priority">Priority</Label>
                    <ValidatedInput name="priority" type="select" value={model.priority || ''} onChange={e => change({ priority: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors}
                        onClick={e => { e.preventDefault(); openModal(); /* editing inline gets confusing as things move around the screen, so open the modal instead */ }}
                        >
                        {
                            actionPriorities.map(item => (
                                <option key={item} value={item}>{actionPriorityDisplayName(item)}</option>
                            ))
                        }
                    </ValidatedInput>
                </FormGroup>

                <ConditionalFragment showIf={ !!questionText }>
                    <Label htmlFor="questionText">Action relates to {questionLabelText} question</Label> <br />
                    <Link to={`${projectSettings.baseRoute}/showQuestion/${props.model.questionId}`}>{replacePlaceholders(questionText, placeholderReplacementMode)}</Link> <small className="text-muted">[{questionNumber}]</small><br /><br />
                </ConditionalFragment>
                <ConditionalFragment showIf={!descriptionHtmllIsEmpty}>
                    <FormGroup>
                        <Label htmlFor="descriptionHtml">Details</Label>
                        <div>
                            <PlainTextWithBrs text={replacePlaceholders(model.description, placeholderReplacementMode) || ''} />
                        </div>
                    </FormGroup>
                </ConditionalFragment>

                <FormGroup>
                    <Label htmlFor="tasks">Assigned tasks</Label>
                    <div>
                        <ConditionalFragment showIf={!!tasks.length && !!tasks.filter(it => !actionStates.findById(it.actionState).isCompleted).length}>
                            <Button color="primary" outline onClick={() => openModal()}>
                                View {tasks.filter(it => !actionStates.findById(it.actionState).isCompleted).length | 0} outstanding task(s)
                            </Button>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!tasks.length && !tasks.filter(it => !actionStates.findById(it.actionState).isCompleted).length}>
                            <Button color="primary" outline onClick={() => openModal()}>
                                View {tasks.length || 0} completed task(s)
                            </Button>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!tasks.length}>
                            <Button color="primary" outline onClick={() => openModal()}>
                                <FontAwesomeIcon icon="plus" />
                                <> </>Assign tasks
                            </Button>
                        </ConditionalFragment>
                    </div>
                </FormGroup>
            </CardBody>
        </Card>
    );
};