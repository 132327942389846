import * as React from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { AnyAction, Dispatch } from 'redux';
import { goBack, CallHistoryMethodAction } from 'connected-react-router';

interface GoBackLinkContainerProps {
    goBack: () => any,
    children: any
}

/**
 * Container for links that will cause the browser to navigate back.
 */
export const _GoBackLinkContainer = (props: GoBackLinkContainerProps) => {
    const goBack = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        props.goBack();
    };

    return (
        <LinkContainer to="#" onClick={goBack}>
            {props.children}
        </LinkContainer>
    );
};

export const GoBackLinkContainer = connect(
    (state: any) => ({
    }),
    (dispatch: Dispatch<AnyAction>) => ({
        goBack: () => dispatch(goBack())
    })
)(_GoBackLinkContainer);
