import * as React from 'react';
import { List } from '../List';

/**
 * List filtered to show only school self evaluation question sets.
 */
export const SchoolSelfEvaluationList = () => {
    const filter = React.useCallback((items: Array<any>) => {
        if (!items) {
            return items;
        }

        return items.filter(item => item.name === 'School self evaluation');
    }, []);

    return (
        <List filter={filter} baseRoute="/administration/school-self-evaluation" />
        );
};