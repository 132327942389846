import * as React from 'react';
import { Form, FormGroup, Label, Row, Col, Container, Collapse, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';
import { TwoValueSwitch } from '../../shared/TwoValueSwitch';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ElasticInput } from 'reactstrap-elasticinput';
import { TagUi } from './TagUi';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const toggleTag = React.useCallback((tagId: string) => {
        //If tag has a record for this question and tag, then remove it
        let existingTag = props.awardTagTags.models.find(it => !!props.model && it.awardTagId === props.model.id && it.tagId === tagId);
        if (existingTag) {
            props.awardTagTags.removeModel(existingTag.id);
        } else {
            props.awardTagTags.addModel({
                awardTagId: props.model && props.model.id || '',
                tagId: tagId
            });
        }
    }, [props.awardTagTags, props.model]);


    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add specific review' : 'Edit specific review'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <Form onSubmit={onSubmit}>
                <Row>
                    <Col xs={12} sm={8}>
                        <FormGroup>
                            <Label htmlFor="name">Name</Label>
                            <ValidatedInput type="text" name="name" autoComplete="off" placeholder="Award or inspection name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={4}>
                        <FormGroup>
                            <Label htmlFor="displayOrder">Order</Label>
                            <ValidatedInput type="number" name="displayOrder" autoComplete="off" placeholder="" value={props.model.displayOrder} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label htmlFor="description">Description</Label>
                    <ElasticInput type="textarea" name="description" autoComplete="off" placeholder="Description" value={props.model.description} onChange={onChange} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="hasCertificate">Show to schools?</Label>
                    <TwoValueSwitch leftLabel="No" rightLabel="Yes this is visible to schools" checked={props.model.isVisibleToSchools} onChange={checked => props.changeModel({ isVisibleToSchools: checked })} />
                </FormGroup>

                <div>
                    <FormGroup>
                        <Label htmlFor="tags">School types</Label>
                        <div className={`tag-component`}>
                            {
                                props.allTags.filter(item => item.isSchoolType).map(item => (
                                    <TagUi key={item.id} isSelected={!!!!props.awardTagTags.models.find(it => !!props.model && it.awardTagId === props.model.id && it.tagId === item.id)} tag={item} toggleSelected={() => toggleTag(item.id)} />
                                ))
                            }
                        </div>
                        <FormText>
                            This specific review will be available to any school with at least one of the selected school types.
                        </FormText>
                    </FormGroup>
                </div>
                <div>
                    <FormGroup>
                        <Label htmlFor="tags">School phases</Label>
                        <div className={`tag-component`}>
                            {
                                props.allTags.filter(item => !item.isSchoolType).map(item => (
                                    <TagUi key={item.id} isSelected={!!props.awardTagTags.models.find(it => !!props.model && it.awardTagId === props.model.id && it.tagId === item.id)} tag={item} toggleSelected={() => toggleTag(item.id)} />
                                ))
                            }
                        </div>
                        <FormText>
                            This specific review will be available to any school with at least one of the selected school phases.
                        </FormText>
                    </FormGroup>
                </div>

                <FormGroup>
                    <Label htmlFor="hasCertificate">Does this award or inspection have a certificate?</Label>
                    <TwoValueSwitch leftLabel="No" rightLabel="Yes a certificate should be available" checked={props.model.hasCertificate} onChange={checked => props.changeModel({ hasCertificate: checked })} />
                </FormGroup>

                <ConditionalFragment showIf={props.model.hasCertificate}>
                    <Collapse isOpen={props.model.hasCertificate}>
                        <FormGroup>
                            <Label htmlFor="hasCertificate">Does the school need to submit the report to anyone to get a certificate?</Label>
                            <TwoValueSwitch leftLabel="No the certificate can be self claimed by the school" rightLabel="Yes the certificate is awarded by an organisation" checked={props.model.canSubmit} onChange={checked => props.changeModel({ canSubmit: checked })} />
                        </FormGroup>

                        <ConditionalFragment showIf={props.model.canSubmit}>
                            <Collapse isOpen={props.model.canSubmit}>
                                <FormGroup>
                                    <Label htmlFor="hasCertificate">Awarding organisation</Label>
                                    <ValidatedInput type="text" name="awardingOrganisationName" autoComplete="off" placeholder="Awarding organisation" value={props.model.awardingOrganisationName} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                </FormGroup>
                            </Collapse>
                        </ConditionalFragment>
                    </Collapse>
                </ConditionalFragment>

                <FormGroup>
                    <Label htmlFor="hasCertificate">Show or hide trust targets on the dashboard and reports?</Label>
                    <TwoValueSwitch leftLabel="Hide" rightLabel="Show" checked={!props.model.hideTargets} onChange={checked => props.changeModel({ hideTargets: !checked })} />
                </FormGroup>
                
                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
