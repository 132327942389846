import * as React from 'react';
import { Tag } from '../../../api/models/Tag';

export interface TagUiProps {
    tag: Tag | { id: string, name: string },
    isSelected: boolean,
    toggleSelected: () => void,
    isReadOnly?: boolean
}

export const TagUi = (props: TagUiProps) => {
    const toggle = React.useCallback(() => {
        if (!props.isReadOnly) {
            props.toggleSelected();
        }
    }, [props.isReadOnly, props.toggleSelected]);
    return (
        <div className={`tag-badge ${props.isSelected ? "selected" : ""}`} onClick={(ev) => toggle()}>
            {props.tag.name}
        </div>
    );
};