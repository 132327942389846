import * as React from 'react';
import { Button } from 'reactstrap';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { EditSchoolSummaryModal } from './editModals/EditSchoolSummaryModal';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReportProps } from '../reportGenerator/ReportProps';


/**
 * SchoolSummary section of the executive summary.
 */
export const SchoolSummaryUi = (props: ReportProps) => {
    const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
    const [originalValue, setOriginalValue] = React.useState<string>('');
    const toggleEditModalOpen = React.useCallback(() => {
        setEditModalIsOpen(prevState => !prevState);
    }, [setEditModalIsOpen]);

    let isForTrust = React.useMemo(() => {
        if (!props.model) {
            return false;
        }

        if (!Guid.isEmpty(props.model.schoolId)) {
            return false;
        }

        if (!Guid.isEmpty(props.model.trustId)) {
            return true;
        }

        return false;
    }, [props.model]);

    if (!props.model) {
        return (<></>);
    }

    return (
        <>
            <p>
                <PlainTextWithBrs text={props.model.schoolSummary} />
            </p>
            <ConditionalFragment showIf={!props.readOnly}>
                <PrivateContainer role="Manage">
                    <p>
                        <Button size="sm" color="primary" outline onClick={e => {
                            if (!props.model) {
                                return;
                            }
                            setOriginalValue(props.model.schoolSummary);
                            toggleEditModalOpen();
                        }}>
                            {
                                !props.model.schoolSummary ? (
                                    <>
                                        {
                                            isForTrust ? (
                                                <>Add comments about about the trust</>
                                            ): (
                                                <>Add additional comments to the school summary</>
                                                )
                                        }
                                    </>
                                        
                                ) : (
                                        <>
                                            {
                                                isForTrust ? (
                                                    <>Edit comments about the trust</>
                                                ) : (
                                                        <>Edit additional comments in the school summary</>
                                                    )
                                            }
                                        </>
                                    )
                            }
                        </Button>
                    </p>
                </PrivateContainer>
            </ConditionalFragment>
            
            {
                editModalIsOpen ? (
                    <EditSchoolSummaryModal model={props.model} changeModel={props.changeModel}
                        save={async (): Promise<boolean> => {
                            let ok = await props.save();
                            if (!ok) {
                                return false;
                            }

                            toggleEditModalOpen();
                            return true;
                        }}
                        isSaving={props.isSaving} savingErrors={props.savingErrors}
                        cancel={() => {
                            if (!props.model) {
                                return;
                            }

                            props.changeModel({ schoolSummary: originalValue });
                        toggleEditModalOpen();
                    }} />
                ): null
            }
        </>
    );
};
