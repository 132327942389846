import * as React from 'react';
import { DashboardUiProps } from '../DashboardContainer';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import { RiskScattergraphChartProps, RiskScattergraphChart } from './RiskScattergraphChart';
import { LinkContainer } from 'react-router-bootstrap';
import { useProjectSettings } from '../../../../api/useProjectSettings';

export const RiskScattergraphCard = (props: DashboardUiProps) => {
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTag && props.awardTag.id || undefined);

    // Render UI.
    //

    if (!props.model) {
        return (<></>);
    }

    return (
        <Card>
            <CardHeader>
                <h6>{props.risks.length} risks on the risk report</h6>
            </CardHeader>
            <CardBody>
                <RiskScattergraphChart risks={props.risks} awardTagId={props.awardTag && props.awardTag.id || undefined} />

                <div>
                    <LinkContainer to={`${projectSettings.baseRoute.replace('/dueDiligence/', '/dueDiligenceRiskReport/')}`}>
                        <Button color="primary" outline className="full-width">
                            View risk report
                        </Button>
                    </LinkContainer>
                </div>
            </CardBody>
        </Card>
    );
};

