import * as React from 'react';
import { ContainerComponentProps } from "react-withcontainer";
import { EditUiPropsBase } from "../../containers/common/EditUiPropsBase";
import { Consultant } from "../../../api/models/Consultant";
import { Repository } from "pojo-repository";
import { useAsyncCallback } from 'react-use-async-callback';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidationState } from 'pojo-validator';
import { withServiceProps } from 'inject-typesafe-react';
import { AppServicesCore } from '../../../configure/configureServicesCore';
import { Guid } from 'guid-string';
import { BlobUploadService } from '../../../services/BlobUploadService';
import { BlobUrl } from '../../../api/models/BlobUrl';


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<Consultant>,
    loadViewModel: (id: string) => Promise<any>,
    blobUploadService: BlobUploadService,
}

export interface EditUiProps extends EditUiPropsBase<Consultant> {
    model: Consultant | undefined,
    save: () => Promise<boolean>,
    isSaving: boolean,

    photoBlob: BlobUrl | undefined | null,
    uploadPhoto: (files: FileList) => Promise<BlobUrl | null>,
    isUploadingPhoto: boolean,
    uploadPhotoErrors: any,
}

export const _EditContainer = (props: EditContainerProps) => {
    const { component, repository, loadViewModel, blobUploadService, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam("id", "");

    const [model, setModel] = React.useState<Consultant | undefined>();
    const [photoBlob, setPhotoBlob] = React.useState<BlobUrl | undefined | null>();

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        const result = await loadViewModel(id);
        setPhotoBlob(result.photoBlob);
        setModel(result.model);
        return true;
    }, [setModel, loadViewModel]);

    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, load, isLoading, loadingErrors]);

    const changeModel = React.useCallback((changes: Partial<Consultant>) => {
        if (!model) {
            return;
        }

        setModel({
            ...model,
            ...changes
        });
    }, [model, setModel]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('forename')) {
            validation.singleCheck('forename', () => !model.forename, 'Forename is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('forename')) {
            validation.singleCheck('surname', () => !model.forename, 'Surname is required');
        }

    }, [model]);


    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        await repository.save(model.id, model);
        return true;
    }, [model, repository, id, validate]);

    // Upload the photo.
    const [uploadPhoto, { isExecuting: isUploadingPhoto, errors: uploadPhotoErrors }] = useAsyncCallback(async (files: FileList) => {
        if (!files) {
            return null;
        }

        if (!model) {
            return null;
        }

        // Do the upload.
        const blob = await blobUploadService.upload(files);
        if (!blob) {
            return null;
        }

        setPhotoBlob(blob);
        changeModel({ photoBlobId: blob.id });

        return blob;
    }, [blobUploadService, model, changeModel, setPhotoBlob]);



    const Component = component;

    return <Component {...rest}
        load={load} isLoading={isLoading} loadingErrors={loadingErrors}
        isCreate={id === ''}
        save={save} isSaving={isSaving} savingErrors={savingErrors}
        model={model} changeModel={changeModel}
        validate={validate} validationErrors={validationErrors}
        photoBlob={photoBlob} uploadPhoto={uploadPhoto} isUploadingPhoto={isUploadingPhoto} uploadPhotoErrors={uploadPhotoErrors}
    />
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.consultants.repository(),
    loadViewModel: services.api.consultants.viewModels.edit(),
    blobUploadService: services.blobUploadService(),
}))(_EditContainer);