import * as React from 'react';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody } from 'reactstrap';
import { isNullOrUndefined } from 'util';
import { useUniversalNavigation } from 'react-universal-navigation';
import { RiskScattergraphChart } from '../dashboard/charts/RiskScattergraphChart';
import { Risk } from '../../../api/models/Risk';
import { Impact } from '../../../api/models/Impact';
import { Question } from '../../../api/models/Question';
import { AnonymousQuestionResponse } from '../../../api/models/AnonymousQuestionResponse';
import { Guid } from 'guid-string';
import { Project } from '../../../api/models/Project';
import { AwardTag } from '../../../api/models/AwardTag';

export interface RiskReportScattergraphCardProps {
    risks: Array<Risk>,
    impacts: Array<Impact>,
    questions: Array<Question>,
    questionResponses: Array<AnonymousQuestionResponse>,
    otherProjects: Array<Project> | undefined,
    awardTagId: string | undefined,
}

export const RiskReportScattergraphCard = (props: RiskReportScattergraphCardProps) => {
    const data = React.useMemo(() => {
        if (!props.risks) {
            return [];
        }

        return props.risks.map(item => {
            let project = props.otherProjects && props.otherProjects.find(it => it.id === item.projectId);
            let schoolId = !!project && !!project.schoolId ? project.schoolId : '';
            let question = props.questions.find(it => it.id === item.questionId);
            let impact: number = item.questionId? 0: 6;
            if (question) {
                let impactId = question.impactId;
                let impactModel = props.impacts.find(it => it.id === impactId);
                if (impactModel) {
                    impact = impactModel.multiplier;
                }
            }

            let responses = props.questionResponses.filter(it => it.questionId === item.questionId && (Guid.isEmpty(it.projectId) /* for executive summary */ || it.projectId === item.projectId /* for the trust level risk report */));
            let operationalLevel = item.questionId? 0: 4;
            if (responses.length >= 1) {
                let total: number = 0;
                let count: number = 0;
                for (let response of responses) {
                    total += response.scale;
                    ++count;
                }
                operationalLevel = total / count;
            }

            return {
                id: item.id,
                projectId: item.projectId,
                description: item.description,
                operationalLevel: operationalLevel,
                impactMultiplier: impact,
                schoolId: schoolId
            };
        });
    }, [props.risks, props.questions, props.impacts, props.questionResponses]);


    if (!props.risks) {
        return (<></>);
    }


    // Show the chart.
    return (
        <Card>
            <CardBody>
                <RiskScattergraphChart risks={data} awardTagId={props.awardTagId} chartHeight={400} />
            </CardBody>
        </Card>
    );
};

