import { View, Text } from '@react-pdf/renderer';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { getQuestionaireScaleChoices } from '../../../../utilities/getQuestionaireScaleChoices';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { ProjectTopicPdfUiProps } from './ProjectTopicPdfUi';
import { Styles } from './Styles';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

export const ProjectQuestionnairePdfUi = (props: ProjectTopicPdfUiProps) => {

    const [questionnaires, questions, isDueDiligence] = React.useMemo(() => {
        let q = props.questions.filter(item => item.topicId === props.projectTopicId);
        let questionnaires = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));

        return [questionnaires, q, props.projectSettings.isDueDiligence];
    }, [props]);
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View style={{ marginTop: 30 }}>
            {
                questionnaires.map((questionnaire, index) => {
                    return (
                        <View>
                            {/*Heading*/}
                            <View>
                                <Text style={Styles.h6}>{replacePlaceholders(questionnaire.name, placeholderReplacementMode)} Questionnaire</Text>
                                <View>
                                    <Text>
                                        <PlainTextWithBrsPdf text={replacePlaceholders(questionnaire.description, placeholderReplacementMode)} />
                                    </Text>
                                </View>
                            </View>
                            <View style={Styles.table}>
                                <View style={Styles.thead}>
                                    <View style={Styles.trHead}>
                                        <View style={{ ...Styles.th, width: '8%' }}>
                                            <Text></Text>
                                        </View>
                                        <View style={{ ...Styles.th, width: isDueDiligence ? '19%' : '25%' }}>
                                            <Text>Question</Text>
                                        </View>

                                        <View style={{ ...Styles.th, width: isDueDiligence ? '39%' : '42%' }}>
                                            <Text>Responses</Text>
                                        </View>
                                        <View style={{ ...Styles.th, width: isDueDiligence ? '26%' : '25%' }}>
                                            <Text>Feedback</Text>
                                        </View>
                                        <ConditionalFragment showIf={isDueDiligence}>
                                            <View style={{ ...Styles.th, width: '10%' }}>
                                                <Text>Risk?</Text>
                                            </View>
                                        </ConditionalFragment>
                                    </View>
                                </View>
                                <View style={Styles.tbody}>
                                    {
                                        questions.filter(item => item.questionaireId === questionnaire.id).map((question, index) => {

                                            let responses = props.anonymousQuestionResponses.filter(it => it.questionId === question.id);

                                            // Work out the average, highest, and lowest responses.
                                            let total = 0;
                                            let count = 0;
                                            let lowest = 10000;
                                            let highest = -10000;
                                            for (let item of responses) {
                                                total += item.scale;
                                                ++count;

                                                if (item.scale < lowest) {
                                                    lowest = item.scale;
                                                }

                                                if (item.scale > highest) {
                                                    highest = item.scale;
                                                }
                                            }

                                            let average = 0;
                                            if (count > 0) {
                                                average = total / count;
                                            }

                                            const scaleChoices = getQuestionaireScaleChoices();

                                            let percentage = ((average - scaleChoices[0].scale) / (scaleChoices[scaleChoices.length - 1].scale - scaleChoices[0].scale)) * 100.0;
                                            let lowestScaleChoice = scaleChoices.find(it => it.scale === lowest);
                                            let highestScaleChoice = scaleChoices.find(it => it.scale === highest);

                                            let risk = props.risks.find(it => it.questionId === question.id);


                                            return (
                                                <View style={{ ...Styles.tr }}>
                                                    <View style={{ ...Styles.th, width: isDueDiligence ? '8%' : '8%' }}>
                                                        <Text>{question.questionNumber}</Text>
                                                    </View>
                                                    <View style={{ ...Styles.th, width: isDueDiligence ? '19%' : '25%' }}>
                                                        <Text>{replacePlaceholders(question.name, placeholderReplacementMode)}</Text>
                                                    </View>
                                                    <View style={{ ...Styles.th, width: isDueDiligence ? '39%' : '42%' }}>
                                                        <View style={{ ...Styles.row }}>
                                                            {/**/}
                                                            {
                                                                scaleChoices.map((item, index) => (
                                                                    <View key={index} style={{ width: '25%' }}>
                                                                        <View><Text>{replacePlaceholders(item.name, placeholderReplacementMode)}</Text></View>
                                                                        <View>
                                                                            <Text>(Selected {responses.filter(it => it.scale === item.scale).length} times)</Text>
                                                                        </View>
                                                                    </View>
                                                                ))
                                                            }
                                                        </View>
                                                        <View style={Styles.row}>
                                                            <View style={{ width: '100%', padding: 1, border: 1 }}>
                                                                <View style={{ width: `${percentage}%`, height: 10, backgroundColor: '#9D5097' }}></View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ ...Styles.th, width: isDueDiligence ? '26%' : '25%' }}>
                                                        {
                                                            responses.filter(it => it.feedback).map((it, i) => (
                                                                <View style={{ padding: 10, border: 1 }}>
                                                                    <Text>
                                                                        <PlainTextWithBrsPdf text={replacePlaceholders(it.feedback, placeholderReplacementMode)} />
                                                                    </Text>
                                                                </View>
                                                            ))
                                                        }
                                                    </View>
                                                    <ConditionalFragment showIf={isDueDiligence}>
                                                        <View style={{ ...Styles.th, width: '8%' }}>
                                                            <Text>{risk ? 'Yes' : 'No'}</Text>
                                                        </View>
                                                    </ConditionalFragment>
                                                </View>
                                            );
                                        })
                                    }
                                </View>
                            </View>
                        </View>
                    )
                })
            }
        </View>
    );
};