import * as React from "react";
import { ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { Guid } from "guid-string";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { QuestionSet } from "../../../api/models/QuestionSet";
import moment from 'moment';
import { useManagedChildModels, ManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { Section } from "../../../api/models/Section";
import { Topic } from "../../../api/models/Topic";
import { Question } from "../../../api/models/Question";
import { QuestionChoice } from "../../../api/models/QuestionChoice";
import { Impact } from "../../../api/models/Impact";
import { Questionaire } from "../../../api/models/Questionaire";
import { isNullOrUndefined } from "util";
import { Tag } from "../../../api/models/Tag";
import { QuestionTag } from "../../../api/models/QuestionTag";
import { AwardTag } from "../../../api/models/AwardTag";
import { Video } from "../../../api/models/Video";
import { getQuestionSetTypeFromName, QuestionSetTypeSettings } from "./QuestionSetType";

export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    id?: string,

    /* From dependency injection */
    repository: Repository<QuestionSet>,
    loadViewModel: (id: string) => Promise<any>,
    sectionsRepository: Repository<Section>,
    topicsRepository: Repository<Topic>,
    questionsRepository: Repository<Question>,
    questionChoiceRepository: Repository<QuestionChoice>,
    questionaireRepository: Repository<Questionaire>,
    newVersion: (id: string, isMajor: boolean) => Promise<QuestionSet>,
    tagRepository: Repository<Tag>,
    questionTagsRepository: Repository<QuestionTag>,
    migrateCurrentProjectsToNewVersion: (id: string) => Promise<boolean>,
}

export interface EditUiProps extends EditUiPropsBase<QuestionSet> {
    sections: ManagedChildModels<Section>,
    topics: ManagedChildModels<Topic>,
    questions: ManagedChildModels<Question>,
    questionChoices: ManagedChildModels<QuestionChoice>,
    questionaires: ManagedChildModels<Questionaire>,
    questionSetType: QuestionSetTypeSettings,

    impacts: Array<Impact>,

    isPublished: boolean,
    isLatestVersion: boolean,
    publish: (isMajor: boolean) => Promise<boolean>,
    isPublishing: boolean,
    publishErrors: any,

    createNewVersion: (isMajor: boolean) => Promise<QuestionSet | null>,
    isCreatingNewVersion: boolean,
    createNewVersionErrors: any,

    isReadOnly: boolean,
    setIsReadOnly: (value: boolean | null) => void,
    tags: Array<Tag>,
    questionTags: ManagedChildModels<QuestionTag>,
    awardTags: Array<AwardTag>,
    videos: Array<Video>,

    baseRoute?: string,

    changeQuestionOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
    changeSectionOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
    changeTopicOrder: (direction: 'up' | 'down', thisId: string, checkId?: string) => void,
}

export interface SelectableTag extends Tag {
    questionIds: Array<string>
}

export const _EditContainer = (props: EditContainerProps) => {
    let { component, repository, tagRepository, questionTagsRepository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = props.id || navigation.getParam('id', '');
    const isCreate = id ? false : true;

    const [isLatestVersion, setIsLatestVersion] = React.useState<boolean>(false);
    const [model, setModel] = React.useState<QuestionSet | undefined>(undefined);
    const [impacts, setImpacts] = React.useState<Array<Impact>>([]);
    const [_isReadOnly, setIsReadOnly] = React.useState<boolean | null>(null);
    const [tags, setTags] = React.useState<Array<Tag>>([]);
    const questionTagsManager = useManagedChildModels(questionTagsRepository);
    const [_awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);
    const [videos, setVideos] = React.useState<Array<Video>>([]);

    // Work out the type of question set we are working with.
    const questionSetType = React.useMemo(() => getQuestionSetTypeFromName((model && model.name) || ''), [model]);

    // We always add our own special award tag to the list for the general SEF/DD.
    const awardTags = React.useMemo(() => {
        if (!model || !_awardTags) {
            return [];
        }

        return [
            {
                id: Guid.empty,
                name: questionSetType.specificReviewMainTagText,
                archived: false,
                awardingOrganisationName: 'Hub4Leaders',
                hasCertificate: false,
                canSubmit: false,
                hideTargets: false,
                displayOrder: -1, // Show first.
                description: '',
                isVisibleToSchools: false,
            } as AwardTag,
            ..._awardTags,
        ];
    }, [_awardTags, model, questionSetType]);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<QuestionSet>) => {
        setModel(prevState => ({
            ...(prevState as QuestionSet),
            ...changes
        }));
    }, [setModel]);

    // Sections within the question set.
    const sections = useManagedChildModels(props.sectionsRepository, (model: Section, validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }
    });

    // Topics within the question set.
    const topics = useManagedChildModels(props.topicsRepository, (model: Topic, validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }
    });

    // Questions within the question set.
    const questions = useManagedChildModels(props.questionsRepository, (model: Question, validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('impactId')) {
            validation.singleCheck('impactId', () => Guid.isEmpty(model.impactId), 'Impact is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('topicId')) {
            validation.singleCheck('topicId', () => Guid.isEmpty(model.topicId), 'Topic is required');
        }
    });

    // Question choices (i.e. answers) within the question set.
    const questionChoices = useManagedChildModels(props.questionChoiceRepository, (model: QuestionChoice, validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }
    });

    // Questionires.
    const questionaires = useManagedChildModels(props.questionaireRepository, (model: Questionaire, validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('description')) {
            validation.singleCheck('description', () => !model.description, 'Description is required');
        }
    });

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await props.loadViewModel(id ? id : 'defaults');
        setIsLatestVersion(result.isLatestVersion);
        sections.setModels(result.sections);
        topics.setModels(result.topics);
        questions.setModels(result.questions);
        questionChoices.setModels(result.questionChoices);
        questionaires.setModels(result.questionaires);
        setImpacts(result.impacts);
        setAwardTags(result.awardTags);
        setVideos(result.videos);
        setModel(result.model);

        setTags(result.tags);
        questionTagsManager.setModels(result.questionTags);
        return true;
    }, [props.loadViewModel, setModel, id, setIsLatestVersion, sections.setModels, topics.setModels, questions.setModels,
        questionChoices.setModels, questionaires.setModels, setImpacts, setTags, questionTagsManager, setAwardTags,
        setVideos,
        ]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

        //if (!fieldsToCheck || fieldsToCheck.includes('releaseNotes')) {
        //    // Release notes are required if we are publishing.
        //    validation.clearErrors('releaseNotes');
        //    if (model.publishDate) {
        //        validation.singleCheck('releaseNotes', () => !model.releaseNotes, 'Release notes are required when publish a new version.');
        //    }
        //}

        // Validate all sections
        if (!fieldsToCheck) {
            validation.singleCheck('sections', () => !sections.validateModels(), 'One or more sections is invalid.');
        }

        // Validate all topics
        if (!fieldsToCheck) {
            validation.singleCheck('topics', () => !topics.validateModels(), 'One or more topic is invalid.');
        }

        // Validate all questions
        if (!fieldsToCheck) {
            validation.singleCheck('questions', () => !questions.validateModels(), 'One or more question is invalid.');
        }

        // Validate all question choices
        if (!fieldsToCheck) {
            validation.singleCheck('questionChoices', () => !questionChoices.validateModels(), 'One or more question choice is invalid.');
        }
    }, [model, sections, topics, questions, questionChoices, questionaires]);
    
    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model, isCreate);
        
        // Save the questionaires.
        await questionaires.save();

        // Save the child sections.
        await sections.save();

        // Save the child topics.
        await topics.save();

        // Save the child questions.
        await questions.save();

        // Save the child question choices.
        await questionChoices.save();

        // Save the tags
        await questionTagsManager.save();

        return true;
    }, [model, questionTagsManager, validate, repository, isCreate, sections, topics, questions, questionChoices, questionaires]);

    // Publish a new version.
    // NOTE this also performs a save.
    const [publish, { isExecuting: isPublishing, errors: publishErrors }] = useAsyncCallback(async (isMajor: boolean): Promise<boolean> => {
        if (!model) {
            return false;
        }

        // Start by doing a general save (without a publish date).
        let ok = await save();
        if (!ok) {
            return false;
        }

        // Then create a new model with the new publish date (and persist it to the react state for later).
        let publishDate = moment().toISOString(); 

        let changes: Partial<QuestionSet> = {
            publishDate: publishDate,
            versionNumber: (isMajor ? model.versionNumber + 1 : model.versionNumber),
            patchNumber: (isMajor ? 0 : model.patchNumber), // Was already created as +1 on the patchNumber at the time of creation, so no need to do it on publish.
        };

        var newModel: QuestionSet = {
            ...(model as QuestionSet),
            ...changes,
        };
        await changeModel(changes);

        // Save the new model direct with the repository (so we don't have to wait for react state to catch up.)
        repository.save(newModel.id, newModel);

        // Update all existing live projects that use the version we are replacing to use the new version instead.
        await props.migrateCurrentProjectsToNewVersion(newModel.id);

        return true;
    }, [changeModel, model, repository, save, props.migrateCurrentProjectsToNewVersion]);

    // Create a new major version
    const [createNewVersion, { isExecuting: isCreatingNewVersion, errors: createNewVersionErrors }] = useAsyncCallback(async (isMajor: boolean): Promise<QuestionSet | null> => {
        if (!model) {
            return null;
        }

        let newModel = await props.newVersion(model.id, isMajor);
        return newModel;
    }, [model, props.newVersion]);

    // check if an item is to be included when moving an item up or down a list
    // the model cannot be archived
    // one id only will be present for checking in checkId
    // if the model is a questionaire question there will be a questionaireId in it and the checkId will be a QuestionaireId
    // if the model is a reviewer question there will be a topicId in it and the checkId will be a topicId
    // if the item is a topic there will be a sectionId in it and the checkId will be a sectionId
    // if the item is a section or anything else then we won't check anything and true will be returned
    const isItemIncludedForChangeOrder = React.useCallback((model: { id: string, displayOrder: number, archived: boolean, topicId?: string, sectionId?: string, questionaireId?: string }, checkId?: string) => {

        // check for archived
        if (model.archived) {
            return false;
        }

        // check for no match on checkId
        var compareId = '';
        if (!!model.questionaireId) {
            // check the questionaire id
            compareId = model.questionaireId;
        } else {
            if (!!model.topicId) {
                // check the topic id (topicId on a questionaire question is ignored)
                compareId = model.topicId;
            } else {
                if (!!model.sectionId) {
                    // check section id
                    compareId = model.sectionId;
                }
            }
        }

        if (!!compareId && compareId != checkId) {
            return false;
        }

        // everything else will return true
        return true;
    }, []);

    // change an item's display order either up or down in the list
    // if you pass in questions then there must be a topicId
    // if you pass in topics then there must be a sectionId
    // otherwise all items in the array will be considered as included when checking for previous and next
    const changeOrder = React.useCallback((models: Array<{ id: string, displayOrder: number, archived: boolean, topicId?: string, sectionId?: string, questionaireId?: string }>, change: (id: string, changes: Partial<{ id: string, displayOrder: number, archived: boolean, topicId?: string, sectionId?: string, questionaireId?: string }>) => void,
        direction: 'up' | 'down', thisId: string, checkId?: string) => {
        // get the required item's position in the array
        let thisItem = models.find(item => item.id === thisId);
        let thisItemIndex = !!thisItem ? models.indexOf(thisItem) : -1;
        let thisItemDisplayOrder = !!thisItem ? thisItem.displayOrder : 0;

        if (direction === 'up') {
            // nothing to move up to?
            if (thisItemIndex === 0) { return; }

            // get the previous non-archived item's details
            let previousItemIndex = thisItemIndex - 1;

            // get the first item before this one that is relevant, or no item at all if there isn't one
            for (let y = previousItemIndex; previousItemIndex > -1; y--) {
                previousItemIndex = y;
                let checkModel = previousItemIndex > -1 ? models[previousItemIndex] : models[0]; //fallback model doesn't matter as it will never get used
                if (isItemIncludedForChangeOrder(checkModel, checkId)) {
                    break;
                }
            }
            let previousItemId = previousItemIndex > -1 ? models[previousItemIndex].id : '';
            let previousItemDisplayOrder = previousItemIndex > -1 ? models[previousItemIndex].displayOrder : 0;

            // if moving up and there is no previous item do nothing
            if (!previousItemId) { return; }

            // update the previous item being swapped with
            let otherChanges: Partial<Question> = {
                displayOrder: thisItemDisplayOrder, // set previous to the current
            };
            change(previousItemId, otherChanges);

            // move the item up in the model
            let holdItem = models[previousItemIndex];
            models[previousItemIndex] = models[thisItemIndex];
            models[thisItemIndex] = holdItem;

            // update the item being moved
            if (!!thisItem) {
                let thisChanges: Partial<Question> = {
                    displayOrder: previousItemDisplayOrder,
                };
                change(thisId, thisChanges);
            }

            // sort out the array
            models[previousItemIndex].displayOrder = previousItemDisplayOrder;
            models[thisItemIndex].displayOrder = thisItemDisplayOrder;
        }
        if (direction === 'down') {
            // nothing to move down to?
            if (thisItemIndex === models.length -1) { return; }

            // get the next non-archived item's details
            let nextItemIndex = thisItemIndex + 1;

            // get the first item after this one that is relevant, or no item at all if there isn't one
            for (let y = nextItemIndex; nextItemIndex < models.length; y++) {
                nextItemIndex = y;
                let checkModel = nextItemIndex < models.length ? models[nextItemIndex] : models[models.length-1]; //fallback model doesn't matter as it will never get used
                if (isItemIncludedForChangeOrder(checkModel, checkId)) {
                    break;
                }
            }

            let nextItemId = nextItemIndex <= models.length - 1 ? models[nextItemIndex].id : '';
            let nextItemDisplayOrder = nextItemIndex <= models.length - 1 ? models[nextItemIndex].displayOrder :0;

            // if moving down and there is no later item do nothing
            if (!nextItemId) { return; }

            // update the next item being swapped with
            let otherChanges: Partial<Question> = {
                displayOrder: thisItemDisplayOrder, // set next to the current
            };
            change(nextItemId, otherChanges);

            // move the item down in the model
            let holdQuestion = models[nextItemIndex];
            models[nextItemIndex] = models[thisItemIndex];
            models[thisItemIndex] = holdQuestion;

            // update the item being moved
            if (!!thisItem) {
                let thisChanges: Partial<Question> = {
                    displayOrder: nextItemDisplayOrder,
                };
                change(thisId, thisChanges);
            }

            models[nextItemIndex].displayOrder = nextItemDisplayOrder;
            models[thisItemIndex].displayOrder = thisItemDisplayOrder;
        }

    }, [isItemIncludedForChangeOrder]);

    const changeQuestionOrder = React.useCallback((direction: 'up' | 'down', thisId: string, checkId?: string) => changeOrder(questions.models, questions.change, direction, thisId, checkId), [questions, changeOrder]);
    const changeSectionOrder = React.useCallback((direction: 'up' | 'down', thisId: string, checkId?: string) => changeOrder(sections.models, sections.change, direction, thisId, checkId), [sections, changeOrder]);
    const changeTopicOrder = React.useCallback((direction: 'up' | 'down', thisId: string, checkId?: string) => changeOrder(topics.models, topics.change, direction, thisId, checkId), [topics, changeOrder]);

    // Have a simple way to check if the model is published or not in the props.
    let isPublished = React.useMemo(() => {
        if (!model) {
            return false;
        }

        return model.publishDate ? true : false;
    }, [model]);

    // Have a simple way to check we are in read only mode or not in the props.
    let isReadOnly = React.useMemo(() => {
        if (!isNullOrUndefined(_isReadOnly)) {
            return _isReadOnly;
        }

        if (!model) {
            return false;
        }

        return model.publishDate ? true : false;
    }, [model, _isReadOnly]);


    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            questionSetType={questionSetType}
            model={model} changeModel={changeModel} isCreate={isCreate}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            isPublished={isPublished}
            isLatestVersion={isLatestVersion}
            sections={sections}
            topics={topics}
            questions={questions}
            questionChoices={questionChoices}
            questionaires={questionaires}
            impacts={impacts}
            publish={publish} isPublishing={isPublishing} publishErrors={publishErrors}
            createNewVersion={createNewVersion} isCreatingNewVersion={isCreatingNewVersion} createNewVersionErrors={createNewVersionErrors}
            isReadOnly={isReadOnly} setIsReadOnly={setIsReadOnly}
            tags={tags} questionTags={questionTagsManager}
            awardTags={awardTags}
            videos={videos}
            changeQuestionOrder={changeQuestionOrder}
            changeSectionOrder={changeSectionOrder}
            changeTopicOrder={changeTopicOrder}
            />
    );
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.questionSets.repository(),
    loadViewModel: services.api.questionSets.viewModels.edit(),
    sectionsRepository: services.api.sections.repository(),
    topicsRepository: services.api.topics.repository(),
    questionsRepository: services.api.questions.repository(),
    questionChoiceRepository: services.api.questionChoices.repository(),
    questionaireRepository: services.api.questionaires.repository(),
    newVersion: services.api.questionSets.actions.newVersion(),
    tagRepository: services.api.tags.repository(),
    questionTagsRepository: services.api.questionTags.repository(),
    migrateCurrentProjectsToNewVersion: services.api.questionSets.actions.migrateCurrentProjectsToNewVersion(),
}))(_EditContainer);

