import { RouteConfig } from '../components/AppRoutes'

import { Home } from '../components/Home';
import { NotFound } from '../components/NotFound';

import { Login as Account_Login } from '../components/account/Login';
import { Join as Account_Join } from '../components/account/Join';
import { PasswordReset as Account_PasswordReset } from '../components/account/PasswordReset';
import { ResetPassword as Account_ResetPassword } from '../components/account/ResetPassword';

import { Profile as UserProfiles_Profile } from '../components/userProfiles/Profile';

import { Edit as Projects_ProjectTopics_Edit } from '../components/projects/projectTopics/Edit';
import { Dashboard as Projects_Dashboard } from '../components/projects/dashboard/Dashboard';
import { ShowQuestion as Projects_ShowQuestion } from '../components/projects/ShowQuestion';
import { RiskReport as Projects_RiskReport } from '../components/projects/risks/RiskReport';
import { RecommendationReport as Projects_RecommendationReport } from '../components/projects/risks/RecommendationReport';
import { StrengthReport as Projects_StrengthReport } from '../components/projects/strengths/StrengthReport';
import { Reviewers as Projects_Reviewers } from '../components/projects/reviewers/Reviewers';

import { Home as School_Home } from '../components/schools/Home';
import { Overview as School_Overview } from '../components/schools/Overview';
import { List as SchoolTerms_List } from '../components/administration/schoolTerms/List';
import { Edit as SchoolTerms_Edit } from '../components/administration/schoolTerms/Edit';
import { Delete as SchoolTerms_Delete } from '../components/administration/schoolTerms/Delete';

import { Edit as Manage_Users_Edit } from '../components/manage/users/Edit';
import { Edit as GrowthPlan_Edit } from '../components/trust/growthPlan/Edit';

import { Home as Administration_Home } from '../components/administration/Home';

import { Delete as Questions_Delete } from '../components/administration/questions/Delete';

import { List as Tags_List } from '../components/administration/tags/List';
import { Edit as Tag_Edit } from '../components/administration/tags/Edit';
import { Delete as Tag_Delete } from '../components/administration/tags/Delete';

import { List as AwardTags_List } from '../components/administration/awardTags/List';
import { Edit as AwardTags_Edit } from '../components/administration/awardTags/Edit';
import { Delete as AwardTags_Delete } from '../components/administration/awardTags/Delete';

import { List as Impacts_List } from '../components/administration/impacts/List';
import { Edit as Impacts_Edit } from '../components/administration/impacts/Edit';
import { Delete as Impacts_Delete } from '../components/administration/impacts/Delete';

import { List as Subjects_List } from '../components/administration/subjects/List';
import { Edit as Subjects_Edit } from '../components/administration/subjects/Edit';
import { Delete as Subjects_Delete } from '../components/administration/subjects/Delete';

import { List as Pages_List } from '../components/administration/pages/List';
import { Edit as Pages_Edit } from '../components/administration/pages/Edit';
import { Delete as Pages_Delete } from '../components/administration/pages/Delete';

import { List as Users_List } from '../components/administration/users/List';
import { Edit as Users_Edit } from '../components/administration/users/Edit';
import { Delete as Users_Delete } from '../components/administration/users/Delete';

import { List as Subscriptions_List } from '../components/administration/subscriptions/List';
import {
    Edit as Subscriptions_Edit,
    CreateTrust as Subscriptions_CreateTrust,
    CreateSingleSchool as Subscriptions_CreateSingleSchool,
    CreateAwardingOrganisation as Subscriptions_CreateAwardingOrganisation,
} from '../components/administration/subscriptions/Edit/Edit';
import { List as Subscription_Users_List } from '../components/administration/subscriptionUsers/List';

import { Show as MySubscription_Show } from '../components/manage/subscription/Show';

import { AdminReports as Reports_AdminReports } from '../components/reports/AdminReports';

import { Home as Trust_Home } from '../components/trust/Home';
import { Overview as Trust_Overview } from '../components/trust/Overview';
import { Edit as Schools_Edit, CreatePotentialSchool as Schools_CreatePotentialSchool } from '../components/schools/schoolDetails/edit/Edit';
import { Delete as Schools_Delete } from '../components/schools/schoolDetails/Delete';
import { CurrentList as Schools_CurrentList } from '../components/schools/schoolDetails/CurrentList';
import { PotentialList as Schools_PotentialList } from '../components/schools/schoolDetails/PotentialList';

import { List as Trust_SchoolUser_List } from '../components/trust/schoolUsers/List';
import { Edit as Trust_SchoolUser_Edit } from '../components/trust/schoolUsers/Edit';
import { Delete as Trust_SchoolUser_Delete } from '../components/trust/schoolUsers/Delete';
import { List as Trust_TrustUser_List } from '../components/trust/trustUsers/List';
import { Edit as Trust_TrustUser_Edit, EditForSingleSchool as Trust_TrustUser_EditForSingleSchool } from '../components/trust/trustUsers/Edit';
import { Delete as Trust_TrustUser_Delete } from '../components/trust/trustUsers/Delete';
import { Edit as Trust_TrustDetails } from '../components/trust/trustDetails/edit/Edit';

import { Edit as School_Details } from '../components/schools/schoolDetails/edit/Edit';
import { List as School_Users_List } from '../components/schools/users/List';
import { Edit as School_Users_Edit } from '../components/schools/users/Edit';
import { Delete as School_Users_Delete } from '../components/schools/users/Delete';

import * as DefaultLayout from '../components/layouts/defaultLayout/Layout';
import * as LoginLayout from '../components/layouts/loginLayout/Layout';
import * as PrintLayout from '../components/layouts/printLayout/Layout';
import * as AdministrationLayout from '../components/layouts/administrationLayout/Layout';
import * as SchoolsLayout from '../components/layouts/schoolLayout/Layout';
import * as TrustLayout from '../components/layouts/trustLayout/Layout';
import * as ReviewCertificateLayout from '../components/layouts/reviewCertificateLayout/Layout';
import * as AwardingOrganisationLayout from '../components/layouts/awardingOrganisationLayout/Layout';

import { TermsAndConditionsPage } from '../components/pages/TermsAndConditionsPage';
import { PrivacyPolicyPage } from '../components/pages/PrivacyPolicyPage';
import { SchoolSelfEvaluationList } from '../components/administration/questions/schoolSelfEvaluation/SchoolSelfEvaluationList';
import { SchoolDueDiligenceList } from '../components/administration/questions/schoolDueDiligence/SchoolDueDiligenceList';
import { SchoolDueDiligenceEdit } from '../components/administration/questions/schoolDueDiligence/SchoolDueDiligenceEdit';
import { SchoolSelfEvaluationEdit } from '../components/administration/questions/schoolSelfEvaluation/SchoolSelfEvaluationEdit';
import { TrustOnlyActions } from '../components/actions/TrustOnlyActions';
import { SchoolOnlyActions } from '../components/actions/SchoolOnlyActions';
import { Actions } from '../components/actions/Actions';
import { EditTargets } from '../components/projects/editTargets/EditTargets';
import { Manage } from '../components/Manage';
import { EvidenceLocker } from '../components/schoolEvidence/EvidenceLocker';

import { PendingList as SchoolTagCertificates_PendingList, PendingListForAdministrators as SchoolTagCertificates_PendingListForAdministrators } from '../components/administration/schoolTagCertificates/PendingList';
import { AwardedList as SchoolTagCertificates_AwardedList, AwardedListForAdministrators as SchoolTagCertificates_AwardedListForAdministrators } from '../components/administration/schoolTagCertificates/AwardedList';
import { FailedList as SchoolTagCertificates_FailedList, FailedListForAdministrators as SchoolTagCertificates_FailedListForAdministrators } from '../components/administration/schoolTagCertificates/FailedList';
import { Certificate } from '../components/projects/certificates/Certificate';

import { List as VideoTags_List } from '../components/administration/videoTags/List';
import { Edit as VideoTags_Edit } from '../components/administration/videoTags/Edit';
import { Delete as VideoTags_Delete } from '../components/administration/videoTags/Delete';
import { List as Consultants_List } from '../components/administration/consultants/List';
import { Edit as Consultants_Edit } from '../components/administration/consultants/Edit';
import { Delete as Consultants_Delete } from '../components/administration/consultants/Delete';
import { List as Videos_List } from '../components/videos/List';
import { Edit as Videos_Edit } from '../components/videos/Edit';
import { Delete as Videos_Delete } from '../components/videos/Delete';
import { Browse as Videos_Browse } from '../components/videos/Browse';
import { List as VideoViews_List } from '../components/administration/videoViews/List';
import { List as ConsultantReferrals_List } from '../components/administration/consultantReferrals/List';
import { SchoolReportManager } from '../components/schoolReports/SchoolReportManager';
import { ProjectSectionComponent } from '../components/projects/projectSections/ProjectSectionComponent';
import { TrialSignUp } from '../components/trial/TrialSignUp';
import { ReportGenerator } from '../components/schoolReports/reportGenerator/ReportGenerator';
import { ReportBuilder } from '../components/schoolReports/reportGenerator/ReportBuilder';
import { ReadOnlyReportGenerator } from '../components/schoolReports/reportGenerator/ReadOnlyReportGenerator';
import { SubjectLeadershipReviewList } from '../components/administration/questions/subjectLeadershipReview/SubjectLeadershipReviewList';
import { SubjectLeadershipReviewEdit } from '../components/administration/questions/subjectLeadershipReview/SubjectLeadershipReviewEdit';
import { SubjectHelpfulQuestionsList } from '../components/administration/questions/subjectHelpfulQuestions/SubjectHelpfulQuestionsList';
import { SubjectHelpfulQuestionsEdit } from '../components/administration/questions/subjectHelpfulQuestions/SubjectHelpfulQuestionsEdit';
import { ReadingReviewEYFSToKS2List } from '../components/administration/questions/readingReviewEYFSToKS2/ReadingReviewEYFSToKS2List';
import { ReadingReviewEYFSToKS2Edit } from '../components/administration/questions/readingReviewEYFSToKS2/ReadingReviewEYFSToKS2Edit';
import { ReadingReviewKS3ToKS4List } from '../components/administration/questions/readingReviewKS3ToKS4/ReadingReviewKS3ToKS4List';
import { ReadingReviewKS3ToKS4Edit } from '../components/administration/questions/readingReviewKS3ToKS4/ReadingReviewKS3ToKS4Edit';
import { SubjectReviewOverview } from '../components/subjectReviews/SubjectReviewOverview';
import { ReadingReviewEYFSToKS2Overview, ReadingReviewKS3ToKS4Overview } from '../components/readingReviews/ReadingReviewOverview';
import { LookupSubjectLeadershipReviewReport, LookupSubjectHelpfulQuestionsReport } from '../components/schoolReports/lookupReports/LookupSubjectReport';
import { LookupReadingReviewEYFSToKS2Report } from '../components/schoolReports/lookupReports/LookupReadingReviewReport';
import { AwardTagOverview } from '../components/schools/AwardTagOverview';
import { TrustReadingReviewEYFSToKS2Overview } from '../components/readingReviews/TrustReadingReviewOverview';
import { TrustSubjectReviewOverview } from '../components/subjectReviews/TrustSubjectReviewOverview';



// Generate child routes that are used for all the different award review routes available.
const generateAwardReviewRoutes = (prefix: string) => (
    {
        path: `${prefix}/reviewCertificate/:schoolId/award/:awardTagId/:projectId`, component: ReviewCertificateLayout.Layout,
        childRoutes: [
            { path: `${prefix}/reviewCertificate/:schoolId/award/:awardTagId/:projectId/submittedReport`, exact: true, private: true, role: '', component: ReadOnlyReportGenerator, name: 'Submitted report' },
            { path: `${prefix}/reviewCertificate/:schoolId/award/:awardTagId/:projectId/certificate`, exact: true, private: true, role: '', component: Certificate, name: 'Certificate' },
        ],
    }
);

export const routes: Array<RouteConfig> = [
    // Pages available when the user is not logged in.
    {
        path: '/account/', component: LoginLayout.Layout, childRoutes: [
            { path: '/account/login', component: Account_Login, name: 'Sign in' },
            { path: '/account/join', component: Account_Join, name: 'Register' },
            { path: '/account/passwordReset', component: Account_PasswordReset, name: 'Password reset' },
            { path: '/account/resetPassword', component: Account_ResetPassword, name: 'Reset password' },
            { path: '/account/trial', component: TrialSignUp, name: 'Trial' },
        ]
    },

    // Printing layout
    {
        path: '/print/', component: PrintLayout.Layout, childRoutes: [
        ]
    },



    // Awards and inspections review for certificates (e.g. SchoolMark) by administrators and awarding organisaitons.
    generateAwardReviewRoutes('/administration/pendingCertificates'),
    generateAwardReviewRoutes('/administration/awardedCertificates'),
    generateAwardReviewRoutes('/administration/failedCertificates'),
    generateAwardReviewRoutes('/awardingOrganisation/pendingCertificates'),
    generateAwardReviewRoutes('/awardingOrganisation/awardedCertificates'),
    generateAwardReviewRoutes('/awardingOrganisation/failedCertificates'),

    // Adminitration layout
    {
        path: '/administration', component: AdministrationLayout.Layout, childRoutes: [
            { path: '/administration', exact: true, private: true, role: 'Administration', component: Administration_Home, name: 'Administration' },

            { path: '/administration/impacts/', exact: true, private: true, role: 'Administration', component: Impacts_List, name: 'Impacts' },
            { path: '/administration/impacts/search/:search?', private: true, role: 'Administration', component: Impacts_List, name: 'Search' },
            { path: '/administration/impacts/edit/:id', private: true, role: 'Administration', component: Impacts_Edit, name: 'Edit' },
            { path: '/administration/impacts/add', private: true, role: 'Administration', component: Impacts_Edit, name: 'Add' },
            { path: '/administration/impacts/delete/:id', private: true, role: 'Administration', component: Impacts_Delete, name: 'Delete' },

            { path: '/administration/subjects/', exact: true, private: true, role: 'Administration', component: Subjects_List, name: 'Subjects' },
            { path: '/administration/subjects/search/:search?', private: true, role: 'Administration', component: Subjects_List, name: 'Search' },
            { path: '/administration/subjects/edit/:id', private: true, role: 'Administration', component: Subjects_Edit, name: 'Edit' },
            { path: '/administration/subjects/add', private: true, role: 'Administration', component: Subjects_Edit, name: 'Add' },
            { path: '/administration/subjects/delete/:id', private: true, role: 'Administration', component: Subjects_Delete, name: 'Delete' },

            { path: '/administration/pages/', exact: true, private: true, role: 'Administration', component: Pages_List, name: 'Pages' },
            { path: '/administration/pages/search/:search?', private: true, role: 'Administration', component: Pages_List, name: 'Search' },
            { path: '/administration/pages/edit/:id', private: true, role: 'Administration', component: Pages_Edit, name: 'Edit' },
            { path: '/administration/pages/delete/:id', private: true, role: 'Administration', component: Pages_Delete, name: 'Delete' },

            { path: '/administration/school-self-evaluation/', exact: true, private: true, role: 'Administration', component: SchoolSelfEvaluationList, name: 'School self evaluation questions' },
            { path: '/administration/school-self-evaluation/search/:search?', private: true, role: 'Administration', component: SchoolSelfEvaluationList, name: 'Search' },
            { path: '/administration/school-self-evaluation/edit/:id', private: true, role: 'Administration', component: SchoolSelfEvaluationEdit, name: 'Edit' },
            //{ path: '/administration/school-self-evaluation/add', private: true, role: 'Administration', component: Questions_Create, name: 'Add' },
            { path: '/administration/school-self-evaluation/delete/:id', private: true, role: 'Administration', component: Questions_Delete, name: 'Delete' },

            { path: '/administration/school-due-diligence/', exact: true, private: true, role: 'Administration', component: SchoolDueDiligenceList, name: 'School due diligence questions' },
            { path: '/administration/school-due-diligence/search/:search?', private: true, role: 'Administration', component: SchoolDueDiligenceList, name: 'Search' },
            { path: '/administration/school-due-diligence/edit/:id', private: true, role: 'Administration', component: SchoolDueDiligenceEdit, name: 'Edit' },
            //{ path: '/administration/school-due-diligence/add', private: true, role: 'Administration', component: Questions_Create, name: 'Add' },
            { path: '/administration/school-due-diligence/delete/:id', private: true, role: 'Administration', component: Questions_Delete, name: 'Delete' },

            { path: '/administration/subject-leadership-review/', exact: true, private: true, role: 'Administration', component: SubjectLeadershipReviewList, name: 'Subject leadership review' },
            { path: '/administration/subject-leadership-review/search/:search?', private: true, role: 'Administration', component: SubjectLeadershipReviewList, name: 'Search' },
            { path: '/administration/subject-leadership-review/edit/:id', private: true, role: 'Administration', component: SubjectLeadershipReviewEdit, name: 'Edit' },
            //{ path: '/administration/subject-leadership-review/add', private: true, role: 'Administration', component: Questions_Create, name: 'Add' },
            { path: '/administration/subject-leadership-review/delete/:id', private: true, role: 'Administration', component: Questions_Delete, name: 'Delete' },

            { path: '/administration/subject-helpful-questions/', exact: true, private: true, role: 'Administration', component: SubjectHelpfulQuestionsList, name: 'Subject helpful questions' },
            { path: '/administration/subject-helpful-questions/search/:search?', private: true, role: 'Administration', component: SubjectHelpfulQuestionsList, name: 'Search' },
            { path: '/administration/subject-helpful-questions/edit/:id', private: true, role: 'Administration', component: SubjectHelpfulQuestionsEdit, name: 'Edit' },
            //{ path: '/administration/subject-helpful-questions/add', private: true, role: 'Administration', component: Questions_Create, name: 'Add' },
            { path: '/administration/subject-helpful-questions/delete/:id', private: true, role: 'Administration', component: Questions_Delete, name: 'Delete' },

            { path: '/administration/reading-eyfs-to-ks2/', exact: true, private: true, role: 'Administration', component: ReadingReviewEYFSToKS2List, name: 'EYFS, KS1, and KS2 reading' },
            { path: '/administration/reading-eyfs-to-ks2/search/:search?', private: true, role: 'Administration', component: ReadingReviewEYFSToKS2List, name: 'Search' },
            { path: '/administration/reading-eyfs-to-ks2/edit/:id', private: true, role: 'Administration', component: ReadingReviewEYFSToKS2Edit, name: 'Edit' },
            //{ path: '/administration/reading-eyfs-to-ks2/add', private: true, role: 'Administration', component: Questions_Create, name: 'Add' },
            { path: '/administration/reading-eyfs-to-ks2/delete/:id', private: true, role: 'Administration', component: Questions_Delete, name: 'Delete' },


            { path: '/administration/reading-ks3-to-ks4/', exact: true, private: true, role: 'Administration', component: ReadingReviewKS3ToKS4List, name: 'KS3 and KS4 reading' },
            { path: '/administration/reading-ks3-to-ks4/search/:search?', private: true, role: 'Administration', component: ReadingReviewKS3ToKS4List, name: 'Search' },
            { path: '/administration/reading-ks3-to-ks4/edit/:id', private: true, role: 'Administration', component: ReadingReviewKS3ToKS4Edit, name: 'Edit' },
            //{ path: '/administration/reading-ks3-to-ks4/add', private: true, role: 'Administration', component: Questions_Create, name: 'Add' },
            { path: '/administration/reading-ks3-to-ks4/delete/:id', private: true, role: 'Administration', component: Questions_Delete, name: 'Delete' },

            { path: '/administration/users/', exact: true, private: true, role: 'Administration', component: Users_List, name: 'Users' },
            { path: '/administration/users/search/:search?', private: true, role: 'Administration', component: Users_List, name: 'Search' },
            { path: '/administration/users/edit/:id', private: true, role: 'Administration', component: Users_Edit, name: 'Edit' },
            { path: '/administration/users/add', private: true, role: 'Administration', component: Users_Edit, name: 'Add' },
            { path: '/administration/users/delete/:id', private: true, role: 'Administration', component: Users_Delete, name: 'Delete' },

            { path: '/administration/subscriptions/', exact: true, private: true, role: 'Administration', component: Subscriptions_List, name: 'Subscriptions' },
            { path: '/administration/subscriptions/search/:search?', exact: true, private: true, role: 'Administration', component: Subscriptions_List, name: 'Search' },
            { path: '/administration/subscriptions/edit/:id', private: true, role: 'Administration', component: Subscriptions_Edit, name: 'Edit' },
            { path: '/administration/subscriptions/add', private: true, role: 'Administration', component: Subscriptions_CreateTrust, name: 'Add trust' },
            { path: '/administration/subscriptions/addSingleSchool', private: true, role: 'Administration', component: Subscriptions_CreateSingleSchool, name: 'Add single school' },
            { path: '/administration/subscriptions/addAwardingOrganisation', private: true, role: 'Administration', component: Subscriptions_CreateAwardingOrganisation, name: 'Add awarding organisation' },

            { path: '/administration/subscriptionUsers/', exact: true, private: true, role: 'Administration', component: Subscription_Users_List, name: 'Subscription users' },
            { path: '/administration/subscriptionUsers/search/:search?', exact: true, private: true, role: 'Administration', component: Subscription_Users_List, name: 'Search' },
            { path: '/administration/subscriptionUsers/edit/:id', exact: true, private: true, role: 'Administration', component: Manage_Users_Edit, name: 'Edit' },

            { path: '/administration/tags/', exact: true, private: true, role: 'Administration', component: Tags_List, name: 'Tags' },
            { path: '/administration/tags/search/:search?', exact: true, private: true, role: 'Administration', component: Tags_List, name: 'Tags' },
            { path: '/administration/tags/edit/:id', exact: true, role: 'Administration', component: Tag_Edit, name: 'Edit' },
            { path: '/administration/tags/add', exact: true, role: 'Administration', component: Tag_Edit, name: 'Add' },
            { path: '/administration/tags/delete/:id', private: true, role: 'Administration', component: Tag_Delete, name: 'Delete' },

            { path: '/administration/awardTags/', exact: true, private: true, role: 'Administration', component: AwardTags_List, name: 'Specific reviews' },
            { path: '/administration/awardTags/search/:search?', exact: true, private: true, role: 'Administration', component: AwardTags_List, name: 'Search' },
            { path: '/administration/awardTags/edit/:id', exact: true, role: 'Administration', component: AwardTags_Edit, name: 'Edit' },
            { path: '/administration/awardTags/add', exact: true, role: 'Administration', component: AwardTags_Edit, name: 'Add' },
            { path: '/administration/awardTags/delete/:id', private: true, role: 'Administration', component: AwardTags_Delete, name: 'Delete' },

            { path: '/administration/schoolTerms', exact: true, private: true, role: 'Administration', component: SchoolTerms_List, name: 'School terms' },
            { path: '/administration/schoolTerms/add', exact: true, private: true, role: 'Administration', component: SchoolTerms_Edit, name: 'Add' },
            { path: '/administration/schoolTerms/edit/:id', exact: true, private: true, role: 'Administration', component: SchoolTerms_Edit, name: 'Edit' },
            { path: '/administration/schoolTerms/delete/:id', private: true, role: 'Administration', component: SchoolTerms_Delete, name: 'Delete' },

            { path: '/administration/dashboard', private: true, role: 'Administration', component: Reports_AdminReports, name: 'Dashboard' },

            { path: '/administration/pendingCertificates/', exact: true, private: true, role: 'Administration', component: SchoolTagCertificates_PendingListForAdministrators, name: 'Pending certificates' },
            { path: '/administration/pendingCertificates/search/:search?', exact: true, private: true, role: 'Administration', component: SchoolTagCertificates_PendingListForAdministrators, name: 'Search' },
            { path: '/administration/awardedCertificates/', exact: true, private: true, role: 'Administration', component: SchoolTagCertificates_AwardedListForAdministrators, name: 'Awarded certificates' },
            { path: '/administration/awardedCertificates/search/:search?', exact: true, private: true, role: 'Administration', component: SchoolTagCertificates_AwardedListForAdministrators, name: 'Search' },
            { path: '/administration/failedCertificates/', exact: true, private: true, role: 'Administration', component: SchoolTagCertificates_FailedListForAdministrators, name: 'Failed certificates' },
            { path: '/administration/failedCertificates/search/:search?', exact: true, private: true, role: 'Administration', component: SchoolTagCertificates_FailedListForAdministrators, name: 'Search' },

            { path: '/administration/videoTags/', exact: true, private: true, role: 'Administration', component: VideoTags_List, name: 'Video tags' },
            { path: '/administration/videoTags/search/:search?', exact: true, private: true, role: 'Administration', component: VideoTags_List, name: 'Search' },
            { path: '/administration/videoTags/edit/:id', exact: true, role: 'Administration', component: VideoTags_Edit, name: 'Edit' },
            { path: '/administration/videoTags/add', exact: true, role: 'Administration', component: VideoTags_Edit, name: 'Add' },
            { path: '/administration/videoTags/delete/:id', private: true, role: 'Administration', component: VideoTags_Delete, name: 'Delete' },

            { path: '/administration/consultants/', exact: true, private: true, role: 'Administration', component: Consultants_List, name: 'Consultants' },
            { path: '/administration/consultants/search/:search?', exact: true, private: true, role: 'Administration', component: Consultants_List, name: 'Search' },
            { path: '/administration/consultants/edit/:id', exact: true, role: 'Administration', component: Consultants_Edit, name: 'Edit' },
            { path: '/administration/consultants/add', exact: true, role: 'Administration', component: Consultants_Edit, name: 'Add' },
            { path: '/administration/consultants/delete/:id', private: true, role: 'Administration', component: Consultants_Delete, name: 'Delete' },

            { path: '/administration/videos/', exact: true, private: true, role: 'Administration', component: Videos_List, name: 'Videos' },
            { path: '/administration/videos/search/:search?', exact: true, private: true, role: 'Administration', component: Videos_List, name: 'Search' },
            { path: '/administration/videos/edit/:id', exact: true, role: 'Administration', component: Videos_Edit, name: 'Edit' },
            { path: '/administration/videos/add', exact: true, role: 'Administration', component: Videos_Edit, name: 'Add' },
            { path: '/administration/videos/delete/:id', private: true, role: 'Administration', component: Videos_Delete, name: 'Delete' },

            { path: '/administration/videoViews/', exact: true, private: true, role: 'Administration', component: VideoViews_List, name: 'Video views' },
            { path: '/administration/videoViews/search/:search?', exact: true, private: true, role: 'Administration', component: VideoViews_List, name: 'Search' },
            { path: '/administration/consultantReferrals/', exact: true, private: true, role: 'Administration', component: ConsultantReferrals_List, name: 'Consultant referrals' },
            { path: '/administration/consultantReferrals/search/:search?', exact: true, private: true, role: 'Administration', component: ConsultantReferrals_List, name: 'Search' },

            { path: '/administration/microConsultations', exact: true, private: true, role: 'Administration', component: Videos_Browse, name: 'Micro-consultations' },

        ]
    },

    {
        path: '/awardingOrganisation', component: AwardingOrganisationLayout.Layout, childRoutes: [
            { path: '/awardingOrganisation', exact: true, private: true, role: '', component: Manage, name: 'Manage' },
            
            //{ path: '/awardingOrganisation/schoolUsers/:trustId', exact: true, private: true, role: '', component: Trust_SchoolUser_List, name: 'School users' },
            //{ path: '/awardingOrganisation/schoolUsers/:trustId/add', exact: true, private: true, role: '', component: Trust_SchoolUser_Edit, name: 'Add school user' },
            //{ path: '/awardingOrganisation/schoolUsers/:trustId/edit/:id', exact: true, private: true, role: '', component: Trust_SchoolUser_Edit, name: 'Edit school user' },
            //{ path: '/awardingOrganisation/schoolUsers/:trustId/delete/:id', exact: true, private: true, role: '', component: Trust_SchoolUser_Delete, name: 'Delete' },
            { path: '/awardingOrganisation/trustUsers/:trustId', exact: true, private: true, role: '', component: Trust_TrustUser_List, name: 'Trust users' },
            { path: '/awardingOrganisation/trustUsers/:trustId/add', exact: true, private: true, role: '', component: Trust_TrustUser_Edit, name: 'Add trust user' },
            { path: '/awardingOrganisation/trustUsers/:trustId/edit/:id', exact: true, private: true, role: '', component: Trust_TrustUser_Edit, name: 'Edit trust user' },
            { path: '/awardingOrganisation/trustUsers/:trustId/delete/:id', exact: true, private: true, role: '', component: Trust_TrustUser_Delete, name: 'Delete trust user' },
            
            { path: '/awardingOrganisation/details/:id', exact: true, private: true, role: '', component: Trust_TrustDetails, name: 'Organisation details' },

            { path: '/awardingOrganisation/subscription', exact: true, private: true, role: '', component: MySubscription_Show, name: 'Subscription' },

            { path: '/awardingOrganisation/pendingCertificates', exact: true, private: true, role: '', component: SchoolTagCertificates_PendingList, name: 'Pending certificates' },
            { path: '/awardingOrganisation/awardedCertificates', exact: true, private: true, role: '', component: SchoolTagCertificates_AwardedList, name: 'Awarded certificates' },
            { path: '/awardingOrganisation/failedCertificates', exact: true, private: true, role: '', component: SchoolTagCertificates_FailedList, name: 'Failed certificates' },
        ]
    },

    {
        path: '/manage', component: TrustLayout.Layout, childRoutes: [
            { path: '/manage', exact: true, private: true, role: 'Manage', component: Manage, name: 'Manage' },
            { path: '/manage/trust', exact: true, private: true, role: 'Manage', component: Trust_Home, name: 'Trust' },
            { path: '/manage/schools/home/:id', exact: true, private: true, role: 'Manage', component: School_Overview, name: 'Home' },
            { path: '/manage/home', exact: true, private: true, role: 'Manage', component: Trust_Overview, name: 'Home' },
            { path: '/manage/schools', exact: true, private: true, role: 'Manage', component: Schools_CurrentList, name: 'Schools' },
            { path: '/manage/schools/add', exact: true, private: true, role: 'Manage', component: Schools_Edit, name: 'Add' },
            { path: '/manage/schools/edit/:id', exact: true, private: true, role: 'Manage', component: Schools_Edit, name: 'Edit' },
            { path: '/manage/schools/delete/:id', exact: true, private: true, role: 'Manage', component: Schools_Delete, name: 'Delete' },
            { path: '/manage/potentialSchools', exact: true, private: true, role: 'Manage', component: Schools_PotentialList, name: 'Potential schools' },
            { path: '/manage/potentialSchools/add', exact: true, private: true, role: 'Manage', component: Schools_CreatePotentialSchool, name: 'Add' },
            { path: '/manage/potentialSchools/edit/:id', exact: true, private: true, role: 'Manage', component: Schools_Edit, name: 'Edit' },
            { path: '/manage/potentialSchools/delete/:id', exact: true, private: true, role: 'Manage', component: Schools_Delete, name: 'Delete' },
            { path: '/manage/growthPlan/:trustId', exact: true, private: true, role: 'Manage', component: GrowthPlan_Edit, name: "Growth plan" },
            { path: '/manage/schoolUsers/:trustId', exact: true, private: true, role: 'Manage', component: Trust_SchoolUser_List, name: 'School users' },
            //{ path: '/manage/schoolUsers/:trustId/add', exact: true, private: true, role: 'Manage', component: Trust_SchoolUser_Edit, name: 'Add school user' },
            { path: '/manage/schoolUsers/:trustId/edit/:id', exact: true, private: true, role: 'Manage', component: Trust_SchoolUser_Edit, name: 'Edit school user' },
            { path: '/manage/schoolUsers/:trustId/delete/:id', exact: true, private: true, role: 'Manage', component: Trust_SchoolUser_Delete, name: 'Delete' },
            { path: '/manage/trustUsers/:trustId', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_List, name: 'Trust users' },
            { path: '/manage/trustUsers/:trustId/add', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_Edit, name: 'Add trust user' },
            { path: '/manage/trustUsers/:trustId/edit/:id', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_Edit, name: 'Edit trust user' },
            { path: '/manage/trustUsers/:trustId/delete/:id', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_Delete, name: 'Delete trust user' },
            { path: '/manage/topic/:id', exact: true, private: true, role: 'Manage', component: Projects_ProjectTopics_Edit, name: '' },
            { path: '/manage/details/:id', exact: true, private: true, role: 'Manage', component: Trust_TrustDetails, name: 'Trust details' },

            { path: '/manage/subscription', exact: true, private: true, role: 'Manage', component: MySubscription_Show, name: 'Subscription' },
            { path: '/manage/improvementPlanner', exact: true, private: true, role: 'Manage', component: TrustOnlyActions, name: 'Trust improvement planner' },
            { path: '/manage/schoolImprovementPlanner', exact: true, private: true, role: 'Manage', component: SchoolOnlyActions, name: 'School improvement planner' },


            // Shortcuts to show part of projects inline
            { path: '/manage/selfEvaluationDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Self evaluation dashboard' },
            { path: '/manage/selfEvaluation/:projectId/dashboard', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Self evaluation dashboard' },
            //{ path: '/manage/selfEvaluationExecutiveSummary/:projectId', exact: true, private: true, role: '', component: Projects_ExecutiveSummary, name: 'Self evaluation summary' },
            { path: '/manage/selfEvaluationTargets/:projectId', exact: true, private: true, role: '', component: EditTargets, name: 'Trust targets for schools' },
            { path: '/manage/recommendationReport/:projectId', exact: true, private: true, role: '', component: Projects_RecommendationReport, name: 'Areas for development' },
            { path: '/manage/strengthReport/:projectId', exact: true, private: true, role: '', component: Projects_StrengthReport, name: 'Areas of strength' },

            { path: '/manage/dueDiligenceDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Due diligence dashboard' },
            { path: '/manage/dueDiligence/:projectId/dashboard', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Due diligence dashboard' },
            { path: '/manage/dueDiligenceRiskReport/:projectId', exact: true, private: true, role: '', component: Projects_RiskReport, name: 'Risk report' },
            //{ path: '/manage/dueDiligenceExecutiveSummary/:projectId', exact: true, private: true, role: '', component: Projects_ExecutiveSummary, name: 'Executive summary' },
            { path: '/manage/dueDiligenceExpectations/:projectId', exact: true, private: true, role: '', component: EditTargets, name: 'Due diligence expectations' },


            { path: '/manage/microConsultations', exact: true, private: true, role: '', component: Videos_Browse, name: 'Micro-consultations' },

            { path: '/manage/award/:awardTagId/:projectId/dashboard', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/manage/awardDashboard/:awardTagId/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },

            { path: '/manage/subjectHome/:subjectId', exact: true, private: true, role: '', component: TrustSubjectReviewOverview, name: 'Subject review' },
            { path: '/manage/subjectLeadershipReviewDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/manage/subjectHelpfulQuestionsDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },

            { path: '/manage/readingReviewEYFSToKS2Home', exact: true, private: true, role: '', component: TrustReadingReviewEYFSToKS2Overview, name: 'Reading review' },
            { path: '/manage/readingReviewEYFSToKS2Dashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/manage/readingReviewKS3ToKS4Home', exact: true, private: true, role: '', component: TrustReadingReviewEYFSToKS2Overview, name: 'Reading review' },
            { path: '/manage/readingReviewKS3ToKS4Dashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },

        ]
    },

    // School layout
    {
        path: '/school/:schoolId', component: SchoolsLayout.Layout, childRoutes: [
            { path: '/school/:schoolId', exact: true, private: true, role: 'School User', component: School_Home, name: 'School' },
            { path: '/school/:schoolId/home', exact: true, private: true, role: 'School User', component: School_Overview, name: 'Home' },
           //{ path: '/school/:schoolId/contextual_report', exact: true, private: true, role: 'School User', component: School_ContextualReport, name: 'Contextual report' },
            { path: '/school/:schoolId/schoolDetails', exact: true, private: true, role: 'School User', component: School_Details, name: 'School details' },
            { path: '/school/:schoolId/users', exact: true, private: true, role: 'School User', component: School_Users_List, name: 'Users' },
            { path: '/school/:schoolId/users/add', exact: true, private: true, role: 'School User', component: School_Users_Edit, name: 'New' },
            { path: '/school/:schoolId/users/edit/:id', exact: true, private: true, role: 'School User', component: School_Users_Edit, name: 'Edit' },
            { path: '/school/:schoolId/users/delete/:id', exact: true, private: true, role: 'School User', component: School_Users_Delete, name: 'Delete' },

            { path: '/school/:schoolId/improvementPlanner', exact: true, private: true, role: 'School User', component: Actions, name: 'Improvement planner' },

            // Shortcuts to show part of projects inline
            { path: '/school/:schoolId/selfEvaluationDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Self evaluation dashboard' },
            { path: '/school/:schoolId/selfEvaluationExecutiveSummary/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Self evaluation summary' },

            { path: '/school/:schoolId/dueDiligenceDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Due diligence dashboard' },
            { path: '/school/:schoolId/dueDiligenceRiskReport/:projectId', exact: true, private: true, role: '', component: Projects_RiskReport, name: 'Risk report' },
            { path: '/school/:schoolId/recommendationReport/:projectId', exact: true, private: true, role: '', component: Projects_RecommendationReport, name: 'Areas for development' },
            { path: '/school/:schoolId/strengthReport/:projectId', exact: true, private: true, role: '', component: Projects_StrengthReport, name: 'Areas of stregth' },
            { path: '/school/:schoolId/dueDiligenceExecutiveSummary/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Executive summary' },

            { path: '/school/:schoolId/:awardTagId/:projectId/progress', exact: true, private: true, role: '', component: AwardTagOverview, name: 'Review dashboard' },
            { path: '/school/:schoolId/awardHome/:awardTagId/:projectId', exact: true, private: true, role: '', component: AwardTagOverview, name: 'Review dashboard' },
            { path: '/school/:schoolId/awardDashboard/:awardTagId/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/school/:schoolId/awardSummary/:awardTagId/:reportName/:projectId', exact: true, private: true, role: '', component: ReadOnlyReportGenerator, name: 'Review summary' },
            { path: '/school/:schoolId/awardCertificate/:awardTagId/:projectId', exact: true, private: true, role: '', component: Certificate, name: 'Certificate' },
            { path: '/school/:schoolId/award/:awardTagId/:projectId/topic/:topicId', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Review' },
            { path: '/school/:schoolId/award/:awardTagId/:projectId/showQuestion/:questionKey', private: true, role: '', component: Projects_ShowQuestion, name: 'Finding question...' },

            { path: '/school/:schoolId/selfEvaluationTargets/:projectId/:isForSingleSchool', exact: true, private: true, role: '', component: EditTargets, name: 'School targets' },
            { path: '/school/:schoolId/dueDiligenceExpectations/:projectId/:isForSingleSchool', exact: true, private: true, role: '', component: EditTargets, name: 'Due diligence expectations' },

            //
            { path: '/school/:schoolId/singleSchoolTrustUsers/:trustId', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_List, name: 'Users' },
            { path: '/school/:schoolId/singleSchoolTrustUsers/:trustId/add', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_EditForSingleSchool, name: 'New' },
            { path: '/school/:schoolId/singleSchoolTrustUsers/:trustId/edit/:id', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_EditForSingleSchool, name: 'Edit' },
            { path: '/school/:schoolId/singleSchoolTrustUsers/:trustId/delete/:id', exact: true, private: true, role: 'Manage', component: Trust_TrustUser_Delete, name: 'Delete' },

            { path: '/school/:schoolId/evidenceLocker', exact: true, private: true, role: '', component: EvidenceLocker, name: 'Evidence locker' },
            { path: '/school/:schoolId/evidenceLocker/:subjectId', exact: true, private: true, role: '', component: EvidenceLocker, name: 'Subject Evidence locker' },

            { path: '/school/:schoolId/microConsultations', exact: true, private: true, role: '', component: Videos_Browse, name: 'Micro-consultations' },


            { path: '/school/:schoolId/reports', exact: true, private: true, role: '', component: SchoolReportManager, name: 'Reports manager' },
            { path: '/school/:schoolId/reports/standard/:reportName/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Report' },
            { path: '/school/:schoolId/reports/standardBuild/:reportName/:projectId', exact: true, private: true, role: '', component: ReportBuilder, name: 'Report' },
            { path: '/school/:schoolId/reports/custom/:customReportId/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Report' },
            { path: '/school/:schoolId/reports/customBuild/:customReportId/:projectId', exact: true, private: true, role: '', component: ReportBuilder, name: 'Report' },
            { path: '/school/:schoolId/reports/awardSummary/:awardTagId/:reportName/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Report' },
            { path: '/school/:schoolId/reports/awardSummaryBuild/:awardTagId/:reportName/:projectId', exact: true, private: true, role: '', component: ReportBuilder, name: 'Report' },

            { path: '/school/:schoolId/reports/subjectLeadershipReview/:subjectId/:reportName', exact: true, private: true, role: '', component: LookupSubjectLeadershipReviewReport, name: 'Report' },
            { path: '/school/:schoolId/reports/subjectLeadershipReview/:subjectId/:reportName/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Report' },

            { path: '/school/:schoolId/reports/subjectHelpfulQuestions/:subjectId/:reportName', exact: true, private: true, role: '', component: LookupSubjectHelpfulQuestionsReport, name: 'Report' },
            { path: '/school/:schoolId/reports/subjectHelpfulQuestions/:subjectId/:reportName/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Report' },

            { path: '/school/:schoolId/reports/readingReviewEYFSToKS2/:reportName', exact: true, private: true, role: '', component: LookupReadingReviewEYFSToKS2Report, name: 'Report' },
            { path: '/school/:schoolId/reports/readingReviewEYFSToKS2/:reportName/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Report' },

            { path: '/school/:schoolId/reports/readingReviewKS3ToKS4/:reportName', exact: true, private: true, role: '', component: LookupReadingReviewEYFSToKS2Report, name: 'Report' },
            { path: '/school/:schoolId/reports/readingReviewKS3ToKS4/:reportName/:projectId', exact: true, private: true, role: '', component: ReportGenerator, name: 'Report' },

            { path: '/school/:schoolId/reviewers/:projectId', exact: true, private: true, role: '', component: Projects_Reviewers, name: 'Reviewers' },

            { path: '/school/:schoolId/selfEvaluation/:projectId/topic/:topicId', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Previous' },
            { path: '/school/:schoolId/selfEvaluation/:projectId/topic/:topicId/tier/:tier', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Review' },
            { path: '/school/:schoolId/selfEvaluation/:projectId/showQuestion/:questionKey', private: true, role: '', component: Projects_ShowQuestion, name: 'Finding question...' },

            { path: '/school/:schoolId/dueDiligence/:projectId', exact: true, private: true, role: '', component: School_Overview, name: 'Due diligence' },
            { path: '/school/:schoolId/dueDiligence/:projectId/topic/:topicId', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Review' },
            { path: '/school/:schoolId/dueDiligence/:projectId/showQuestion/:questionKey', private: true, role: '', component: Projects_ShowQuestion, name: 'Finding question...' },

            { path: '/school/:schoolId/subjectHome/:subjectId', exact: true, private: true, role: '', component: SubjectReviewOverview, name: 'Subject review' },

            { path: '/school/:schoolId/subjectLeadershipReview/:projectId/topic/:topicId', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Review' },
            { path: '/school/:schoolId/subjectLeadershipReview/:projectId/showQuestion/:questionKey', private: true, role: '', component: Projects_ShowQuestion, name: 'Finding question...' },
            { path: '/school/:schoolId/subjectLeadershipReview/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/school/:schoolId/subjectLeadershipReviewDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },

            { path: '/school/:schoolId/subjectHelpfulQuestions/:projectId/topic/:topicId', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Review' },
            { path: '/school/:schoolId/subjectHelpfulQuestions/:projectId/showQuestion/:questionKey', private: true, role: '', component: Projects_ShowQuestion, name: 'Finding question...' },
            { path: '/school/:schoolId/subjectHelpfulQuestions/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/school/:schoolId/subjectHelpfulQuestionsDashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },

            { path: '/school/:schoolId/readingReviewEYFSToKS2Home', exact: true, private: true, role: '', component: ReadingReviewEYFSToKS2Overview, name: 'Reading review' },
            { path: '/school/:schoolId/readingReviewEYFSToKS2/:projectId/topic/:topicId', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Review' },
            { path: '/school/:schoolId/readingReviewEYFSToKS2/:projectId/showQuestion/:questionKey', private: true, role: '', component: Projects_ShowQuestion, name: 'Finding question...' },
            { path: '/school/:schoolId/readingReviewEYFSToKS2/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/school/:schoolId/readingReviewEYFSToKS2Dashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },


            { path: '/school/:schoolId/readingReviewKS3ToKS4Home', exact: true, private: true, role: '', component: ReadingReviewKS3ToKS4Overview, name: 'Reading review' },
            { path: '/school/:schoolId/readingReviewKS3ToKS4/:projectId/topic/:topicId', exact: true, private: true, role: '', component: ProjectSectionComponent, name: 'Review' },
            { path: '/school/:schoolId/readingReviewKS3ToKS4/:projectId/showQuestion/:questionKey', private: true, role: '', component: Projects_ShowQuestion, name: 'Finding question...' },
            { path: '/school/:schoolId/readingReviewKS3ToKS4/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
            { path: '/school/:schoolId/readingReviewKS3ToKS4Dashboard/:projectId', exact: true, private: true, role: '', component: Projects_Dashboard, name: 'Review dashboard' },
        ]
    },

    // Routes for the defaults
    {
        path: '/', component: DefaultLayout.Layout, childRoutes: [
            { path: '/', exact: true, component: Home, name: 'Home' },

            { path: '/profile', component: UserProfiles_Profile, name: 'Profile' },

            { path: '/terms-and-conditions', component: TermsAndConditionsPage, name: 'Terms and conditions' },
            { path: '/privacy-poliy', component: PrivacyPolicyPage, name: 'Privacy policy' },

            // 404 not found.  This should always be the last route configured.
            { path: undefined, component: NotFound, name: 'Page not found' }
        ]
    },
];

/**
 * All routes exported flat. 
 */
export const breadcrumbRoutes = [
    ...routes.map(item => item.childRoutes || []).flat(1).filter(item => item.path !== '/')
];