import * as React from 'react';
import { FormGroup, Button, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { LicenseEditContainer, LicenseEditUiProps } from '../LicensesEditContainer';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidatedDateTimeInput } from '../../../shared/ValidatedDateTimeInput';
import Switch from 'react-switch';

export const _LicensesEdit = (props: LicenseEditUiProps) => {
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.change({ [target.name]: (target.type == 'checkbox' ? target.checked : target.value) });
    }, [props.change]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    return (
        <div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="startDate">Start date</Label>
                        <ValidatedDateTimeInput type="date" name="startDate" value={!props.model ? '' : props.model.startDate} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="endDate">Expiry date</Label>
                        <ValidatedDateTimeInput type="date" name="endDate" value={!props.model ? '' : props.model.endDate} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    </FormGroup>
                </Col>
                <Col xs="auto">
                    <FormGroup>
                        <Label htmlFor="isTrial">Trial?</Label>
                        <div>
                            <Switch name="isTrial" checked={props.model.isTrial} onChange={(checked) => { props.change({ isTrial: checked }); }} />
                        </div>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="licenseCost">Cost of licence</Label>
                        <ValidatedInput type="number" step={1} name="licenseCost" value={!props.model ? '' : props.model.licenseCost} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    </FormGroup>
                </Col>
                {/*<Col>
                    <FormGroup>
                        <Label htmlFor="projectCost">Cost of project</Label>
                        <ValidatedInput type="number" step={1} name="projectCost" value={!props.model ? '' : props.model.projectCost} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                    </FormGroup>
                </Col>*/}
                <Col xs="auto">
                    <Label>&nbsp;</Label>
                    <div>
                        <Button color="link" onClick={props.remove}><FontAwesomeIcon icon="trash-alt" color="danger" /><span className="sr-only">Remove</span></Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const LicenseEdit = withContainer(LicenseEditContainer)(_LicensesEdit);
