import * as React from 'react';
import { Button, Table, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ListContainer, ListUiProps } from './ListContainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { InfiniteScrollingTable } from '../../shared/infiniteScrolling/InfiniteScrollingTable';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';
import { DateLabel } from '../../shared/DateLabel';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';

export const FailedListUi = (props: ListUiProps) => {
    const navigation = useUniversalNavigation(props);
    const loadPage = React.useCallback((page: number) => {
        props.loadPage(page, props.search);
    }, [props.loadPage, props.search]);

    // Open the default action when the user clicks on a row.
    const onRowDoubleClick = React.useCallback((item: any, event: React.MouseEvent<HTMLTableRowElement>) => {
        if (item) {
            navigation.push(`${props.location.pathname}/reviewCertificate/${encodeURIComponent(item.schoolTagCertificate.schoolId)}/award/${encodeURIComponent(item.schoolTagCertificate.tagId)}/${encodeURIComponent(item.project.id)}/certificate`);
        }
    }, [navigation]);

    const filteredItems = React.useMemo(() => {
        if (!props.model) {
            return [];
        }

        let ret = props.model
            .filter(item => props.showAllOrganisations || (item.awardingOrganisationSubscription && item.awardingOrganisationSubscription.id === props.subscriptionId))
            .filter(item => !item.schoolTagCertificate.isSubmitted && !item.schoolTagCertificate.isAwarded);

        // Reverse the order so the newest shows first.
        ret.reverse();

        return ret;
    }, [props.model, props.subscriptionId, props.showAllOrganisations]);

    return (
        <div className="main-container">
            <AlertOnErrors errors={props.loadingErrors} />

            <div className="search-bar-container">
                <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
            </div>

            <div className="table-responsive">
                <Table striped hover className="table-sm">
                    <thead>
                        <tr>
                            <th><span className="d-inline d-sm-none">School</span></th>
                            <th><span className="d-none d-sm-inline">Name</span></th>
                            <th><span className="d-none d-sm-inline">DfE URN</span></th>
                            <th><span className="d-none d-sm-inline">Award or Inspection</span></th>
                            <th><span className="d-none d-sm-inline">Certificate date</span></th>
                            <th><span className="d-none d-sm-inline">Feedback</span></th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.model && !props.model.length ? (
                                <tr className="nothing-here">
                                    <td colSpan={7}>
                                        {
                                            props.search ? (
                                                <div>
                                                    <FontAwesomeIcon icon="search-minus" />
                                                    Sorry there are no schools matching "{props.search}".
                                                </div>
                                            ) : (
                                                    <div>
                                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                        Sorry there are no schools with submitted certificate requests at the moment.<br />
                                                </div>
                                                )
                                        }
                                    </td>
                                </tr>
                            ) : null
                        }

                        <InfiniteScrollingTable key={props.search} loadData={loadPage} nextPage={props.nextPage}>
                            {(filteredItems || []).map(item => {
                                return (
                                    <tr key={item.schoolTagCertificate.id} onDoubleClick={e => onRowDoubleClick(item, e)}>
                                        <td><div className="d-inline d-sm-none">
                                            <div>
                                                {item.school.name}
                                            </div>
                                            <div className="text-muted">
                                                {item.schoolTagCertificate.awardName}
                                            </div>
                                        </div></td>
                                        <td><span className="d-none d-sm-inline">{item.school.name}</span></td>
                                        <td><span className="d-none d-sm-inline">{item.school.dfeReference}</span></td>
                                        <td><span className="d-none d-sm-inline">{item.schoolTagCertificate && item.schoolTagCertificate.awardName || ''}</span></td>

                                        <td><span className="d-none d-sm-inline"><DateLabel value={item.schoolTagCertificate.certificateDate} format="DD/MM/YYYY" /></span></td>
                                        <td><span className="d-none d-sm-inline"><PlainTextWithBrs text={item.schoolTagCertificate.feedback} /></span></td>
                                        <td className="actions">
                                            <ButtonGroup>
                                                <LinkContainer to={`${props.location.pathname}/reviewCertificate/${encodeURIComponent(item.schoolTagCertificate.schoolId)}/award/${encodeURIComponent(item.schoolTagCertificate.tagId)}/${encodeURIComponent(item.project.id)}/certificate`}>
                                                    <Button color="primary" outline>Review</Button>
                                                </LinkContainer>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                );
                            })}
                        </InfiniteScrollingTable>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const FailedList = withContainer(ListContainer)(FailedListUi);
export const FailedListForAdministrators = withContainer(ListContainer, { showAllOrganisations: true, } as any)(FailedListUi);
