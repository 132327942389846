export interface ActionState {
    id: string,
    name: string,
    isCompleted: boolean,
}

class ActionStates {
    /**
     * Action is outstanding
     */
    outstanding = { id: 'outstanding', name: 'Outstanding', isCompleted: false, } as ActionState;

    /**
     * Action has been completed.
     */
    completed = { id: 'completed', name: 'Completed', isCompleted: true, } as ActionState;

    /**
     * Access to all available States as an array.
     */
    items = [
        this.outstanding,
        this.completed,
    ];

    /**
     * Find a state from its id.
     * @param id
     */
    findById = (id: string | undefined | null): ActionState  => {
        const ret = this.items.find(item => item.id === id);
        if (!ret) {
            return this.outstanding;
        }

        return ret;
    }
}

/**
 * Requirement compliance states.
 */
export const actionStates = new ActionStates();

