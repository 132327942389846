import * as React from 'react';
import { Redirect } from 'react-router';
import { ShowQuestionContainer, ShowQuestionUiProps } from './ShowQuestionContainer'
import { withContainer } from 'react-withcontainer';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useProjectSettings } from '../../api/useProjectSettings';

/**
 * ShowQuestion screen for a project, this gives a summary and easy navigation for the user.
 */
export const ShowQuestionUi = (props: ShowQuestionUiProps) => {
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTagId);

    if (!props.model || !projectSettings.baseRoute) {
        return (<LoadingIndicator />);
    }

    // If we have no question then just redirect back to the home page of the project.
    if (!props.question) {
        return (
            <Redirect to={`${projectSettings.baseRoute}`} />
        );
    }

    // If the question is part of a questionnaire then redirect into the questionnaire results.
    if (props.projectQuestionaire) {
        return (
            <Redirect to={`${projectSettings.baseRoute}/questionnaire/${props.projectQuestionaire.id}/responses?questionId=${props.question.id}`} />
        );
    }

    // Redirect to the right topic for the questionnaire.
    if (props.projectTopic) {
        return (
            <Redirect to={`${projectSettings.baseRoute}/topic/${props.projectTopic.id}?questionId=${props.question.id}`} />
        );
    }

    // Give up and go back to the project's homepage.
    return (
        <Redirect to={`${projectSettings.baseRoute}`} />
    );
};

export const ShowQuestion = withContainer(ShowQuestionContainer)(ShowQuestionUi);
