import * as React from 'react';
import { DashboardUiProps } from '../DashboardContainer';
import { Card, Table, CardHeader, CardBody } from 'reactstrap';
import { isNullOrUndefined } from 'util';
import { Link } from 'react-router-dom';
import { dashboardDrilldownUrl } from '../../../../utilities/dashboardDrilldownUrl';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { Guid } from 'guid-string';
import { getGradeComparisonColor } from '../../../../utilities/getGradeComparisonColor';
import { getGradeString } from '../../../../utilities/getGradeString';
import { ConditionalFragment } from 'react-conditionalfragment';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';

export const SubjectComparisonCard = (props: DashboardUiProps) => {
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTag && props.awardTag.id || undefined);

    let otherSubjects = React.useMemo(() => {
        if (!props.subjectResults || props.subjectResults.length < 2) {
            return [];
        }

        return props.subjectResults.slice(2);
    }, [props.subjectResults]);

    // Can navigate deeper from all levels except a question, which can only be navigated to if we're within a project for an individual school, not from the trust level.
    const canNavigateDeeper = !props.filterByTopicOriginKey || (props.model && !Guid.isEmpty(props.model.schoolId) || false);


    const calculateQuestionScoreAverage = React.useCallback((originKey: string) => {
        var results = 0;
        var divider = 0;

        for (let subjectResult of props.subjectResults) {
            if (subjectResult === null) {
                continue;
            }

            for (var result of subjectResult.results.filter(item => item.originKey === originKey)) {
                results += result.percentageQuestionScore;
                divider++;
            }
        }
        return results / divider;
    }, [props.subjectResults]);



    if (!props.subjectResults || props.subjectResults.length < 2) {
        return (<></>);
    }

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.subjectResults[0];
    let trustAverages = props.subjectResults[1];

    // If we are displaying for a trust, all averages should be based on the trust's input averages not the calculated ones, so adjust to result[0] now.
    //if ((props.model && Guid.isEmpty(props.model.schoolId)) || !trustAverages) {
    //    trustAverages = props.subjectResults[0];
    //}

    return (
        <Card>
            <CardHeader>
                <h6>
                    {
                        props.model && Guid.isEmpty(props.model.schoolId) ?
                            'Breakdown of subjects within the school'
                            : 'Comparison with other subjects in the school'
                    }
                </h6>
            </CardHeader>

            <CardBody>
                <div className="table-responsive">
                    <Table striped className="table-sm">
                        <thead>
                            <tr>
                                <th><span></span></th>

                                {
                                    props.model && Guid.isEmpty(props.model.schoolId) ? (
                                        <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                            <th className="text-right"><span>{thisProject.subject ? thisProject.subject.name : 'Project'}</span></th>
                                        </ConditionalFragment>
                                    ) : (
                                            <th className="text-right"><span>{thisProject.subject ? thisProject.subject.name : 'Project'}</span></th>
                                    )
                                }

                                {/*<th><span>{trustAverages.trust ? trustAverages.trust.name : 'Trust'} averages</span></th>*/}
                                {
                                    otherSubjects.map((item, index) => (
                                        <th key={index} className="text-right"><span>
                                            {/*<Link to={`${projectSettings.otherProjectBaseRoute(item.project && item.project.id || '', item.school && item.school.id || '', true)}`}>*/}
                                                {item.subject ? item.subject.name : 'Project'}
                                            {/*</Link>*/}
                                        </span></th>
                                        ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                thisProject.results.map(item => {
                                    let matchingRecord = null;
                                    let trustScore: number | null = 0;
                                    let thisProjectDifference = null;
                                    if (trustAverages !== null) {
                                        matchingRecord = trustAverages.results.find(it => it.originKey === item.originKey);
                                        trustScore = matchingRecord ? matchingRecord.percentageQuestionScore : null;
                                        thisProjectDifference = isNullOrUndefined(trustScore) ? null : (item.percentageQuestionScore - trustScore);
                                    }
                                    else {
                                        trustScore = calculateQuestionScoreAverage(item.originKey);
                                        thisProjectDifference = isNullOrUndefined(trustScore) ? null : (item.percentageQuestionScore - trustScore);
                                    }
                                    return (
                                        <tr key={item.originKey}>
                                            <td><span>
                                                {
                                                    canNavigateDeeper ? (
                                                        <Link to={dashboardDrilldownUrl(projectSettings, props.filterBySectionOriginKey, props.filterByTopicOriginKey, item.originKey)}>
                                                            {replacePlaceholders(item.name, props.placeholderReplacementMode, { subjectName: thisProject.subject?.name }) + (item.questionNumber ? " [" + item.questionNumber + "]" : "")}
                                                            </Link>
                                                    ) : (
                                                            <>{`${!!item.questionNumber ? `${item.questionNumber} - ${replacePlaceholders(item.name, props.placeholderReplacementMode, { subjectName: thisProject.subject ? thisProject.subject.name : 'Subject' })}` : replacePlaceholders(item.name, props.placeholderReplacementMode, { subjectName: thisProject.subject ? thisProject.subject.name : 'Subject' }) }`}</> 
                                                    )
                                                }
                                                
                                            </span></td>

                                            {
                                                props.model && Guid.isEmpty(props.model.schoolId) ? (
                                                    <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                                        <td className="text-right">
                                                            <span className={(props.model && Guid.isEmpty(props.model.schoolId)) ? '' : isNullOrUndefined(thisProjectDifference) ? '' : item.questionScore === 0 ? '' : props.awardTag && props.awardTag.hideTargets ? '' : `text-${getGradeComparisonColor(item.percentageQuestionScore, trustScore)}`}>
                                                                <strong>{getGradeString(item.percentageQuestionScore)}</strong>
                                                            </span>
                                                        </td>
                                                    </ConditionalFragment>
                                                ) : (
                                                        <td className="text-right">
                                                            <span className={(props.model && Guid.isEmpty(props.model.schoolId)) ? '' : isNullOrUndefined(thisProjectDifference) ? '' : item.questionScore === 0 ? '' : props.awardTag && props.awardTag.hideTargets ? '': `text-${getGradeComparisonColor(item.percentageQuestionScore, trustScore)}`}>
                                                                <strong>{getGradeString(item.percentageQuestionScore)}</strong>
                                                            </span>
                                                        </td>
                                                    )
                                            }

                                            
                                            {/*<td><span>{isNullOrUndefined(trustScore) ? '' : trustScore.toFixed(0)}%</span></td>*/}

                                            {
                                                otherSubjects.map((otherSubjectProject, index) => {
                                                    let myRecord = otherSubjectProject.results.find(it => it.originKey === item.originKey);
                                                    let myScore = myRecord ? myRecord.percentageQuestionScore : null;
                                                    let difference = isNullOrUndefined(trustScore) || isNullOrUndefined(myScore) ? null : (myScore - trustScore);

                                                    return (
                                                        <td key={index} className="text-right">
                                                            <span className={isNullOrUndefined(difference) ? '' : myRecord && myRecord.questionScore === 0 ? '' : props.awardTag && props.awardTag.hideTargets? '': `text-${getGradeComparisonColor(myScore, trustScore)}`}>
                                                                {isNullOrUndefined(myScore) ? '' : myRecord && myRecord.questionScore === 0 ? 'N/A' : getGradeString(myScore)}
                                                            </span>
                                                        </td>
                                                    );
                                                })
                                            }
                                        </tr>
                                    );
                            })}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
    );
};

