import { PasswordValidation } from "../services/passwordValidation/PasswordValidation";

/**
 * Generates a plain text description of the password rules for passwordValidation.
 * @param passwordValidation
 */
export function generatePasswordRulesDescrption(passwordValidation: PasswordValidation): string {
    const [isPasswordValid, passwordErrors] = passwordValidation.check('');
    if (isPasswordValid) {
        return '';
    }

    return `Your password ${passwordErrors.join(', ')}.`;
}
