import * as React from 'react';
import { ContainerComponentProps } from 'react-withcontainer';
import { useAsyncCallback } from 'react-use-async-callback';
import { connect } from 'react-redux';
import { Identity } from '../../../store/user';
import { Project } from '../../../api/models/Project';
import { useApiService } from '../../../api/useApiService';
import { Trust } from '../../../api/models/Trust';

export interface ContainerProps extends ContainerComponentProps<UiProps> {
    appRoutes: Array<any>,
    children: any,
    currentProjectFor: () => Promise<Project>,

    subscriptionId: string | undefined,
}

export interface UiProps {
    appRoutes: Array<any>,
    children: any,

    model: Trust | undefined | null,
}

export const _LayoutContainer = (props: ContainerProps) => {
    const { component, ...rest } = props;

    const api = useApiService();
    const loadViewModel = api.trusts.viewModels.getTrustFromSubscription();
    const [model, setModel] = React.useState<Trust>();

    const [load, { isExecuting: isLoading }] = useAsyncCallback(async () => {
        const result = await loadViewModel(props.subscriptionId || '');
        setModel(result);
    }, [props.subscriptionId, loadViewModel, setModel]);

    React.useEffect(() => {
        if (model || !props.subscriptionId || isLoading) {
            return;
        }

        load();
    });

    const Component = component;

    return <Component {...rest}
        model={model}
    />
}

interface State {
    user?: User
}

interface User {
    identity?: Identity
}

export const LayoutContainer = connect(
    (state: State) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_LayoutContainer);