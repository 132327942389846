import * as React from 'react';
import { PagedListUiPropsBase } from '../../containers/common/PagedListUiPropsBase';
import { User } from '../../../api/models/User';
import { withServiceProps } from 'inject-typesafe-react';
import { LoadPageResult } from '../../containers/common/basicContainers/BasicPagedListContainer';
import { AppServicesCore } from '../../../configure/configureServicesCore';
import { ContainerComponentProps } from 'react-withcontainer';
import { useAsyncCallback } from 'react-use-async-callback';
import { useUniversalNavigation } from 'react-universal-navigation';

export interface ListContainerProps extends ContainerComponentProps<ListUiProps> {
    loadPage: (id: string, page: number, search?: string) => Promise<LoadPageResult<any>>
}

export interface ListUiProps extends PagedListUiPropsBase<any> {
    schoolId: string
}

export const _ListContainer = (props: ListContainerProps) => {
    const { component, loadPage } = props;
    const navigation = useUniversalNavigation(props);
    const schoolId = navigation.getParam('schoolId', '');

    const [model, setModel] = React.useState<Array<User> | undefined>(undefined);
    const [nextPage, setNextPage] = React.useState<number>(0);
    const [search, setSearch] = React.useState<string>('');

    const [wrappedLoadPage, { isExecuting: isLoadingPage, errors: loadingPageErrors }] = useAsyncCallback(async (page: number, search?: string): Promise<boolean> => {
        let result = await loadPage(schoolId, page, search);
        setModel(result.model);
        setNextPage(result.nextPage);
        return true;
    }, [setModel, setNextPage, loadPage, schoolId, search]);

    const changeSearch = React.useCallback((search: string) => {
        setSearch(search);
        wrappedLoadPage(0, search);
    }, [wrappedLoadPage, setSearch]);

    React.useEffect(() => {
        if (!model && !isLoadingPage && !loadingPageErrors) {
            wrappedLoadPage(0);
        }
    }, [model, wrappedLoadPage, isLoadingPage, loadingPageErrors]);

    const Component = component;

    return <Component
        changeNextPage={setNextPage} nextPage={nextPage}
        loadPage={wrappedLoadPage} isLoading={isLoadingPage} loadingErrors={loadingPageErrors}
        changeSearch={changeSearch} search={search}
        model={model} schoolId={schoolId}/>
};

export const ListContainer = withServiceProps<ListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.users.viewModels.listSchoolUsers(),
    subjectRepository: services.api.subjects.repository(),
}))(_ListContainer);
