import * as React from 'react';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardUiProps } from '../DashboardContainer';
import { Card, CardBody } from 'reactstrap';
import { isNullOrUndefined } from 'util';
import { useUniversalNavigation } from 'react-universal-navigation';
import { dashboardDrilldownUrl } from '../../../../utilities/dashboardDrilldownUrl';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Project } from '../../../../api/models/Project';
import { chartLargeColorPallet } from '../../../../utilities/chartLargeColorPallet';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { Guid } from 'guid-string';
import { SchoolTerm } from '../../../../api/models/SchoolTerm';
import { getGradeString } from '../../../../utilities/getGradeString';
import { AwardTag } from '../../../../api/models/AwardTag';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../../../utilities/replacePlaceholders';

export interface TimelineChartProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    timelineResults: Array<ProjectAnalysisResult>,
    chartHeight?: number,
    schoolTerms: Array<SchoolTerm>,
    awardTag: AwardTag | undefined,
    placeholderReplacementMode: PlaceholderReplacementMode,
}

export const TimelineChart = (props: TimelineChartProps) => {
    const navigation = useUniversalNavigation(props);
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTag && props.awardTag.id || undefined);

    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index index of the name.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    const canNavigateDeeper = !isForQuestions || (props.model && !Guid.isEmpty(props.model.schoolId) || false);

    // Get the section of the results we care about.
    const results = React.useMemo(() => {
        // Handle the case when we get a single result back special, so we can draw a straight line as a dot doesn't show up
        // well or look useful.
        if (props.timelineResults.length === 1) {
            // Include the same result twice so we end up with two points to draw straight lines between.
            return [
                props.timelineResults[0],
                props.timelineResults[0],
            ];
        }

        // For all other sets or results we'll be able to map out lines between real reasults so return them now.
        return props.timelineResults;
    }, [props.timelineResults]);

    // Work out some scales and color for the background of the chart based on its data.
    const [min, max, colors] = React.useMemo(() => {
        if (!results || !results.length) {
            return [0, 1, []];
        }

        let dataMax = 100;
        for (let series of results) {
            for (let record of series.results) {
                if (record.percentageQuestionScore > dataMax) {
                    dataMax = record.percentageQuestionScore;
                }
            }
        }

        // Work out the range of the colours to show in the background.
        const redMax = 30;
        const orangeMax = 50;
        const greenMax = 80;

        // Colors need to be built in reverse order.
        let colors: Array<string> = [];
        let currentOpacity = 1.0;
        for (let i = 0; i < dataMax; i += 10) {
            if (i < redMax) {
                colors = [`rgba(253, 33, 33, ${currentOpacity})`, ...colors];
            } else if (i < orangeMax) {
                colors = [`rgba(253, 162, 33, ${currentOpacity})`, ...colors];
            } else if (i < greenMax) {
                colors = [`rgba(52, 207, 52, ${currentOpacity})`, ...colors];
            } else {
                colors = [`rgba(188, 198, 204, ${currentOpacity})`, ...colors];
            }

            if (currentOpacity > 0.2) {
                currentOpacity -= 0.1;
            }
        }

        return [0, dataMax, colors];
    }, [results]);
   

    const options = React.useMemo(() => ({   
        labels: results.map((item, index) => {
            var term = props.schoolTerms.find(it => it.startDate <= (item.project && item.project.startDate || '') && it.endDate > (item.project && item.project.startDate || ''));
            if (term) {
                return term.name;
            }

            return item.project && item.project.startDate || `Project ${index}`;
        }),

        chart: {
            toolbar: {
                show: false,
            },
            events: {
                //// When the user clicks on a point in the chart, drill into that item.
                //click: (event: any, chartContext: any, config: any) => {
                //    // If we didn't click on a data series, do nothing.
                //    if (isNullOrUndefined(config.seriesIndex)) {
                //        return;
                //    }

                //    // We clicked on a datapoint so lets drill into it.
                //    let myResult = results[results.length - 1].results[config.seriesIndex];
                //    if (!myResult) {
                //        return;
                //    }

                //    // If we don't allow any deeper navigation, stop.
                //    if (!canNavigateDeeper) {
                //        return;
                //    }

                //    // Do the actual navigation
                //    let url = dashboardDrilldownUrl(projectSettings, props.filterBySectionOriginKey, props.filterByTopicOriginKey, myResult.originKey);
                //    navigation.navigate(url);
                //},
            }
        },

        colors: chartLargeColorPallet,

        dataLabels: {
            formatter: function (val: any) {
                return getGradeString(val);
            }
        },

        grid: {
            row: {
                colors: colors,
            }
        },

        tooltip: {
            y: {
                formatter: function (val: any) {
                    return getGradeString(val);
                }
            },
        },

        yaxis: {
            min: min,
            max: max,
            tickAmount: (max / 10),
            labels: {
                formatter: function (val: any) {
                    return getGradeString(val);
                },
                minWidth: 125,
                style: {
                    fontSize: '10px',
                },
            },
        }
    }), [results, min, max, colors, projectSettings, canNavigateDeeper]);

    var series = React.useMemo(() => (
        results[results.length - 1].results.map(item => ({
            name: isForQuestions ? `${item.questionNumber} ${item.name.length <= 20 ? replacePlaceholders(item.name, props.placeholderReplacementMode) : ''}` : replacePlaceholders(item.name, props.placeholderReplacementMode),
            data: results.map(project => {
                const originKey = item.originKey;
                const match = project.results.find(itt => itt.originKey === originKey);
                if (!match) {
                    return 0;
                }

                return match.percentageQuestionScore;
            }) || []
        }))
    ), [results, isForQuestions]);

    if (!results || !projectSettings.baseRoute) {
        return (<></>);
    }

    // Show the chart.
    return (
        <Chart options={options} series={series} type="line" height={props.chartHeight ? props.chartHeight : 800} />
    );
};

