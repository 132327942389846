import * as React from "react";
import "./progressWheel.scss";
import { ConditionalFragment } from "react-conditionalfragment";
import { Project } from "../../../api/models/Project";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { PlainTextWithBrs } from "../../shared/PlainTextWithBrs";
import { useUniversalNavigation } from "react-universal-navigation";
import { useProjectSettings } from "../../../api/useProjectSettings";
import { TopicDataItem, SectionDataItem } from "../../projects/projectSections/ProjectSectionComponent";
import { ProductTier } from "../../../api/models/codeOnly/ProductTeir";
import { replacePlaceholders, PlaceholderReplacementMode } from "../../../utilities/replacePlaceholders";


export interface ProductWheelProps {
    awardTagId?: string | undefined,

    model: Project | undefined,
    sections: Array<SectionDataItem>,
    topics: Array<TopicDataItem>,
    currentTier: ProductTier,
    isLoading: boolean,
    placeholderReplacementMode: PlaceholderReplacementMode,
}

/**
 * Progress wheel that displays a schools progress through the product teirs based on a project.
 */
export const ProgressWheel = (props: ProductWheelProps) => {
    const {
        awardTagId,

        model,
        sections,
        topics,
        currentTier,
        isLoading,
        placeholderReplacementMode,
    } = props;

    const navigation = useUniversalNavigation(props);

    // Load the schools progress through the project.
    
    const projectSettings = useProjectSettings(model && model.id || '', awardTagId);

    // Generate the slices for each section.
    const { nodes: sliceNodes, lookup: sliceLookup, } = React.useMemo(() => {
        // Renders one slice.
        const renderSlice = (key: string | number, startDegrees: number, widthDegrees: number, color: string, text: string | undefined, callToActionText: string | undefined, sliceIndex: number) => {
            return (
                <>
                    <div key={key} className="progress-wheel-slice-container" style={{ transform: `rotate(${startDegrees}deg)` }}>
                        <div className={`progress-wheel-slice progress-wheel-slice-${sliceIndex}`} style={{ transform: `rotate(${widthDegrees}deg)`, background: `linear-gradient(${color}, white)`, }}>
                            <ConditionalFragment showIf={!!text}>
                                <div className="progress-wheel-slice-text" style={{ transform: `rotate(${0 - widthDegrees - startDegrees}deg)`, }}>
                                    <div className="progress-wheel-slice-text-heading">
                                        {/* We don't have much width and wrapping won't work so force text to split on to new lines with each space. */}
                                        <PlainTextWithBrs text={performManualWordWrap(text && replacePlaceholders(text, placeholderReplacementMode) || '')} />
                                    </div>
                                    <div className="progress-wheel-slice-text-call-to-action">
                                        {callToActionText}
                                    </div>
                                </div>
                            </ConditionalFragment>
                        </div>
                    </div>
                </>
            );
        }

        // Render a slice for each section..
        //
        let sectionsToRender = sections;

        // If we don't have sections yet we'll just render some fake slices.
        if (!sectionsToRender || !sectionsToRender.length) {
            const fakeSectionCount = 9;
            sectionsToRender = Array.from({ length: fakeSectionCount }, (item, index) => (
                {
                    id: `fake${index}`,
                    name: '',
                    progressWheelColor: 'lightgray',
                    questionCount: 0,
                    questionResponsesCount: 0,
                    keyQuestionsCount: 0,
                    keyQuestionResponsesCount: 0,
                    questionsCount: 0,
                    topicCount: 0,
                } as SectionDataItem
            ));
        }

        // If we only have one section to render, our maths is going to not work as we need at least 2, so add a fake one.
        if (sectionsToRender.length === 1) {
            sectionsToRender.push(
                {
                    id: `fakePlaceholder`,
                    name: '',
                    progressWheelColor: 'lightgray',
                    questionCount: 0,
                    questionResponsesCount: 0,
                    keyQuestionsCount: 0,
                    keyQuestionResponsesCount: 0,
                    questionsCount: 0,
                    topicCount: 0,
                } as SectionDataItem
            );
        }

        // Sizeing of slices.
        const sliceCount = sectionsToRender.length;
        const degreesPerSlice = (360 / sliceCount);
        const spacingDegrees = 0.5;

        
        // Start with the goal of the first slice being centred at the top.
        const initialDegreesOffset = 0 - ((degreesPerSlice + (2 * spacingDegrees)) / 2);
        let startDegrees = initialDegreesOffset;

        let ret: Array<React.ReactNode> = [];
        let lookup: Array<{ startDegrees: number, endDegrees: number, sectionId: string, sectionName: string, index: number, }> = [];
        let index = 0;
        for (const section of sectionsToRender) {
            const lookupStartDegrees = startDegrees;

            // Adjust width for the spacing.
            const myWidthDegrees = degreesPerSlice - (2 * spacingDegrees);

            // Adjust start for the spacing before we start.
            startDegrees += spacingDegrees;

            // Actual slice.
            const callToActionText = section.questionResponsesCount == 0 ? 'Click to start'
                : section.questionResponsesCount >= section.questionsCount ? `Completed all ${section.questionsCount}`
                    : currentTier === ProductTier.FullReview ? `${section.questionsCount - section.questionResponsesCount} more available`
                        : `${section.questionsCount - section.questionResponsesCount} more to go`
            ret.push(renderSlice(section.id, startDegrees, myWidthDegrees, section.progressWheelColor, section.name, callToActionText, index));
            startDegrees += myWidthDegrees;

            // Adjust next start for the spacing after we end.
            startDegrees += spacingDegrees;

            // Add the whole range into the lookup table as being for this section (including the spacing).
            lookup.push({
                startDegrees: lookupStartDegrees,
                endDegrees: startDegrees,
                sectionId: section.id,
                sectionName: section.name,
                index: index,
            });

            ++index;
        }

        return {
            nodes: ret,
            lookup: lookup,
        }
    }, [sections]);

    // Helper method that will convert an x,y cordinates within the inner circle to a slice.
    const innerCircleRef = React.useRef<HTMLDivElement | null>(null);
    const lookupSliceFromMouseEvent = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (!innerCircleRef.current) {
            return;
        }

        // Work out the position of the click within the bounds of the inner circle.
        const bounds = innerCircleRef.current.getBoundingClientRect();
        const clickPosition = {
            x: event.clientX - bounds.left,
            y: event.clientY - bounds.top,
        };

        // Get the centre position of the circle.
        const centre = {
            x: bounds.width / 2,
            y: bounds.height / 2,
        };

        // Use the click position and centre to calculate a delta.
        const clickDelta = {
            x: clickPosition.x - centre.x,
            y: clickPosition.y - centre.y,
        };

        // Calcualte the angle and convert into degrees.
        const rad = Math.atan2(clickDelta.y, clickDelta.x);
        const degrees = rad * (180 / Math.PI);

        // Helper method that absolutes degrees so we are always working with a posative degrees from zero, not negative degrees.
        const degreesAbs = (d: number) => {
            let ret = d;

            // Convert negative degrees to posative again.
            if (ret < 0) {
                ret += 360;
            }

            return ret;
        };

        // Helper method that lets us check if a degrees falls within a slice bounds.
        const isWithinSlice = (d: number, start: number, end: number) => {
            // Special case for where the start is pre the zero line and end is post the zero line.
            if (start > end) {
                if (d > start && d <= 360) {
                    return true;
                } else if (d < end && d > 0) {
                    return true;
                }
            }

            // Everything else is mathmatically simple.
            return d >= start && d < end;
        };

        // Rotate the degrees we are working with by 90 degrees so we are working from the top of the Y axis ratehr than the right of the x axis as returned by atan2.
        const rotatedDegrees = 90 + degrees;

        // Lookup the slice based off the degrees.
        const slice = sliceLookup.find(item => isWithinSlice(degreesAbs(rotatedDegrees), degreesAbs(item.startDegrees), degreesAbs(item.endDegrees)));

        //alert(`Delta: ${clickDelta.x},${clickDelta.y}; Degress: ${degrees}, Rotated degrees: ${rotatedDegrees}, Abs degrees: ${degreesAbs(rotatedDegrees)}, SectionId: ${slice && slice.sectionId}, SectionName: ${slice && slice.sectionName}`);

        return slice;
    }, [innerCircleRef, sliceLookup]);

    // Handle clicks on circle slices.
    // We can't use seperate event handlers per slice as the way we clip the slices causes them to overlap so some can't get the event
    // bubbled up to them.  Instead we handle it at the innerCiricle level and when we get a click, we calculate where the click is using some maths.
    const onInnerCircleClick = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        const activeSlice = lookupSliceFromMouseEvent(event);
        if (!activeSlice) {
            return;
        }

        // Don't let fake sections be clicked.
        if (activeSlice.sectionId.startsWith('fake')) {
            return;
        }

        if (!model) {
            return;
        }

        const firstTopic = topics.find(it => it.sectionId === activeSlice.sectionId);
        if (!firstTopic) {
            return;
        }

        navigation.push(`${projectSettings.baseRoute}/topic/${firstTopic.id}`);
    }, [lookupSliceFromMouseEvent, navigation, model, projectSettings, topics]);

    // Handle mouse over events to allow us to highlight the focused slice as we can't use the hover event on the slice for reasons already given above.
    const onInnerCircleHover = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (!innerCircleRef.current) {
            return;
        }

        const activeSlice = lookupSliceFromMouseEvent(event);

        const hoverClassPrefix = 'progress-wheel-hover-slice-';
        let hoverClass: string;
        if (activeSlice) {
            hoverClass = `${hoverClassPrefix}${activeSlice.index}`;
        } else {
            hoverClass = `${hoverClassPrefix}nothing`;
        }
        
        if (!innerCircleRef.current.classList.contains(hoverClass)) {
            // Remove any other highlights
            let classesToRemove: Array<string> = [];
            innerCircleRef.current.classList.forEach((className) => {
                if (className.indexOf(hoverClassPrefix) === 0) {
                    classesToRemove.push(className);
                }
            });
            if (classesToRemove.length) {
                for (const classToRemove of classesToRemove) {
                    innerCircleRef.current.classList.remove(classToRemove);
                }
            }

            // Add the new highlight.
            innerCircleRef.current.classList.add(hoverClass);
        }

    }, [lookupSliceFromMouseEvent]);
    
    // Render UI
    //
    return (
        <div className="progress-wheel">
            <div className="progress-wheel-outer-circle">
                <div ref={innerCircleRef} className={`progress-wheel-inner-circle progress-wheel-inner-circle-with-${sliceLookup.length}-slices`} onClick={onInnerCircleClick} onMouseMove={onInnerCircleHover}>
                    {sliceNodes}
                </div>
                <div className="progress-wheel-cutout-circle">
                    <ConditionalFragment showIf={isLoading || !model}>
                        <LoadingIndicator fullWidth />
                    </ConditionalFragment>
                </div>
            </div>
        </div>
    );
};

/**
 * Try and format the text to be shown on the wheel in the most optimal way possible.
 * 
 * NOTE we have to do this because we can't use built in word-wrapping due to how the wheel itself is constructed.
 * @param text
 */
function performManualWordWrap(text: string) {
    let ret = '';
    const parts = text.split(' ');

    let currentLineLength = 0;
    for (const part of parts) {
        // If this is the first word, just add it and continue.
        if (ret === '') {
            ret = part;
            currentLineLength = part.length;
            continue;
        }

        // If this word is 5 characters or less, add it to the previous line (unless our line is now more than 12 characters long).
        if (part.length <= 5) {
            if (currentLineLength <= 12) {
                ret += " " + part;
                currentLineLength = part.length;
                continue;
            }
        }

        // Otherwise add on a line of its own.
        ret += "\r\n" + part;
        currentLineLength = part.length;
    }

    return ret;
}