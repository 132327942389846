import * as React from 'react';
import { Col } from 'reactstrap';
import { Row } from 'reactstrap';
import { TwoValueSwitch } from '../../../shared/TwoValueSwitch';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Collapse } from 'reactstrap';
import { ReportBuilderOptionsProps } from '../../reportGenerator/ReportBuilderOptions';
import { useToggleStateArray } from '../../../shared/hooks/useToggleState/useToggleStateArray';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'reactstrap';
import { productTiers, productTierDisplayName } from '../../../../api/models/codeOnly/ProductTeir';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

/**
 * Options shown in the ReportBuilder.
 * @param props
 */
export const SEFOptions = (props: ReportBuilderOptionsProps) => {
    const {
        reportSettings,
        changeReportSettings,

        projectSettings,

        reportBuilderSections,
        reportBuilderTopics,
    } = props;


    // Expanding of sections.
    const [isSectionOpen, toggleSection] = useToggleStateArray();

    return (
        <div>
            <Row className="mb-2">
                <Col>
                    Report format
                </Col>
                <Col>
                    <Input type="select" name="tier" placeholder="Tier" value={reportSettings.baseReport} onChange={e => changeReportSettings({ baseReport: e.currentTarget.value as any })}>
                        <option value="executiveSummary">Executive summary</option>
                        <option value="sef">SEF</option>
                    </Input>
                </Col>
            </Row>

            <Row className="mb-2">
                <Col>
                    Include questions up to and including
                </Col>
                <Col>
                    <Input type="select" name="tier" placeholder="Tier" value={reportSettings.tier || ''} onChange={e => changeReportSettings({ tier: e.currentTarget.value || null })}>
                        <option value="">(All questions)</option>
                        {
                            productTiers.map(item => (
                                <option key={item} value={item}>{productTierDisplayName(item)}</option>
                            ))
                        }
                    </Input>
                </Col>
            </Row>

            <Row>
                <Col>
                    Overview chart
                </Col>
                <Col>
                    <TwoValueSwitch checked={reportSettings.overallResults} leftLabel="No" rightLabel="Yes" key="overall" onChange={(checked: boolean) => changeReportSettings({ overallResults: checked })} />
                </Col>
            </Row>
            <Row>
                <Col>
                    Strengths summary
                </Col>
                <Col>
                    <TwoValueSwitch checked={reportSettings.areasOfStrength} leftLabel="No" rightLabel="Yes" key="strengths" onChange={(checked: boolean) => changeReportSettings({ areasOfStrength: checked })} />
                </Col>
            </Row>

            <Row style={{ paddingTop: 10 }}>
                <Col>
                    <strong>Breakdown of strengths and potential developments</strong>
                </Col>
                <Col>
                    <TwoValueSwitch checked={reportSettings.breakdown} leftLabel="No" rightLabel="Yes" key="strengths" onChange={(checked: boolean) => changeReportSettings({ breakdown: checked })} />
                </Col>
            </Row>
            <Collapse isOpen={reportSettings.breakdown}>
                {
                    reportBuilderSections.map(section => {
                        const isOpen = isSectionOpen(section.originKey);
                        const myTopics = reportBuilderTopics.filter(item => item.sectionId === section.id);

                        const isSectionSelected = !!reportSettings.sectionIds.find(item => item === section.originKey);

                        return (
                            <React.Fragment key={section.originKey}>
                                <Row>
                                    <Col onClick={() => toggleSection(section.originKey)} style={{ cursor: 'pointer' }}>
                                        <FontAwesomeIcon icon={isOpen ? 'minus' : 'plus'} />
                                        <> </>
                                        <em>{replacePlaceholders(section.name, props.placeholderReplacementMode)}</em> 
                                    </Col>
                                    <Col>
                                        <TwoValueSwitch checked={isSectionSelected} leftLabel="No" rightLabel="Yes"
                                            onChange={(checked: boolean) => {
                                                if (checked) {
                                                    changeReportSettings({
                                                        sectionIds: [
                                                            ...reportSettings.sectionIds.filter(it => it !== section.originKey),
                                                            section.originKey,
                                                        ],
                                                    });
                                                } else {
                                                    changeReportSettings({
                                                        sectionIds: reportSettings.sectionIds.filter(it => it !== section.originKey),
                                                    });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpen}>
                                    {
                                        myTopics.map(topic => (
                                            <Row key={topic.originKey}>
                                                <Col>
                                                    <div style={{ minWidth: '30px', display: 'inline-block' }}></div>
                                                    <em>{replacePlaceholders(topic.name, props.placeholderReplacementMode)}</em>
                                                </Col>
                                                <Col>
                                                    <TwoValueSwitch checked={isSectionSelected && !!reportSettings.topicIds.find(item => item === topic.originKey)} leftLabel="No" rightLabel="Yes"
                                                        onChange={(checked: boolean) => {
                                                            if (checked) {
                                                                changeReportSettings({
                                                                    topicIds: [
                                                                        ...reportSettings.topicIds.filter(it => it !== topic.originKey),
                                                                        topic.originKey,
                                                                    ],
                                                                });
                                                            } else {
                                                                changeReportSettings({
                                                                    topicIds: reportSettings.topicIds.filter(it => it !== topic.originKey),
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </Collapse>
                            </React.Fragment>
                        );
                    })
                }
            </Collapse>
        </div>
    );
};
