import * as React from 'react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import moment, { Moment } from 'moment';
import { InputProps } from 'reactstrap';
import { DateTimeInputChangeEvent } from './DateTimeInput';

export interface ValidatedDateTimeInputProps extends InputProps {
    validationErrors: {
        [id: string]: Array<string>;
    },
    hideFormFeedback?: boolean,
    onChange?: (event: DateTimeInputChangeEvent) => void,
    format?: string
}

/**
 * Input with validation support for a datetime picker using moment() to keep our dates straight.
 * @param props
 */
export const ValidatedDateTimeInput = (props: ValidatedDateTimeInputProps) => {
    const { type, value, format, onChange, ...rest } = props;
    const theType = type || 'datetime-local';
    const theFormat = format || (theType == 'datetime' || theType == 'datetime-local'? 'YYYY-MM-DD[T]HH:mm': 'YYYY-MM-DD');

    // Handle the value
    let date = moment(value as string);
    let localDate = date.local();
    let theValue = localDate.format(theFormat);

    const onChangeWrapper = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const newValue: Moment = moment(event.currentTarget.value).utc();
            if (!newValue.isValid()) {
                return;
            }

            const newEvent: any = {
                ...event,
                currentTarget: {
                    ...event.currentTarget,
                    value: newValue.toISOString(),
                    valueAsDate: newValue.toDate(),
                    name: event.currentTarget.name
                },
                target: {
                    ...event.target,
                    value: newValue.toISOString(),
                    valueAsDate: newValue.toDate(),
                    name: event.currentTarget.name
                }
            };
            onChange(newEvent);
        }
    }, [onChange]);

    return (
        <ValidatedInput type={theType} value={theValue} onChange={onChangeWrapper} {...rest} />
    );
};
