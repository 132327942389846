import { ReportSettingsModel } from "./ReportSettingsModel";
import { standardReportsOriginKeyMap } from "./standardReportsOriginKeyMap";
import { ProductTier } from "../../../api/models/codeOnly/ProductTeir";

/**
 * Standard report settings that set the base for standard reports.
 */
export const standardReportSettings = {
    summary: {
        baseReport: 'executiveSummary',
        reportName: 'Self evaluation',
        customReportId: null,
        tier: null,

        introduction: true,
        schoolBackground: true,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: true,
        conclusion: true,

        sectionIds: [
            standardReportsOriginKeyMap.schoolImprovment.key,
            standardReportsOriginKeyMap.educationalEnvironment.key,
            standardReportsOriginKeyMap.governance.key,
            standardReportsOriginKeyMap.people.key,
            standardReportsOriginKeyMap.premises.key,
            standardReportsOriginKeyMap.finance.key,
            standardReportsOriginKeyMap.policies.key,
            standardReportsOriginKeyMap.reputationalRisk.key,
            standardReportsOriginKeyMap.legal.key,
        ],
        topicIds: [
            standardReportsOriginKeyMap.premises.topics.healthAndSafety,
        ],
    } as ReportSettingsModel,

    breakdown: {
        baseReport: 'executiveSummary',
        reportName: 'Self evaluation breakdown',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: false,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: true,
        conclusion: true,

        sectionIds: [
            standardReportsOriginKeyMap.schoolImprovment.key,
            standardReportsOriginKeyMap.educationalEnvironment.key,
            standardReportsOriginKeyMap.governance.key,
            standardReportsOriginKeyMap.people.key,
            standardReportsOriginKeyMap.premises.key,
            standardReportsOriginKeyMap.finance.key,
            standardReportsOriginKeyMap.policies.key,
            standardReportsOriginKeyMap.reputationalRisk.key,
            standardReportsOriginKeyMap.legal.key,
        ],
        topicIds: [
            standardReportsOriginKeyMap.schoolImprovment.topics.schoolImprovementsSelfEvaluationAndOfsted,

            standardReportsOriginKeyMap.educationalEnvironment.topics.leadershipAndManagement,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationIntent,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImplementation,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactLearning,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactData,
            standardReportsOriginKeyMap.educationalEnvironment.topics.behaviourAndAttitudes,
            standardReportsOriginKeyMap.educationalEnvironment.topics.personalDevelopment,

            standardReportsOriginKeyMap.governance.topics.governance,

            standardReportsOriginKeyMap.people.topics.wellbeing,
            standardReportsOriginKeyMap.people.topics.employmentAndHr,

            standardReportsOriginKeyMap.premises.topics.healthAndSafety,
            standardReportsOriginKeyMap.premises.topics.healthAndSafetyCovid19,
            standardReportsOriginKeyMap.premises.topics.assets,
            standardReportsOriginKeyMap.premises.topics.facilities,

            standardReportsOriginKeyMap.finance.topics.banking,
            standardReportsOriginKeyMap.finance.topics.proceduresAndPractices,
            standardReportsOriginKeyMap.finance.topics.incomeGeneration,
            standardReportsOriginKeyMap.finance.topics.vat,
            standardReportsOriginKeyMap.finance.topics.pensions,
            standardReportsOriginKeyMap.finance.topics.purchasingAndSlas,

            standardReportsOriginKeyMap.policies.topics.statutoryPolicies,

            standardReportsOriginKeyMap.reputationalRisk.topics.reputationalRisk,

            standardReportsOriginKeyMap.legal.topics.legalCompliance,
        ],
    } as ReportSettingsModel,

    
    headsReport: {
        baseReport: 'sef',
        reportName: 'Heads report',
        customReportId: null,
        tier: null,

        introduction: true,
        schoolBackground: true,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: true,
        conclusion: true,

        sectionIds: [
            standardReportsOriginKeyMap.schoolImprovment.key,
            standardReportsOriginKeyMap.educationalEnvironment.key,
            standardReportsOriginKeyMap.governance.key,
            standardReportsOriginKeyMap.people.key,
            standardReportsOriginKeyMap.premises.key,
            standardReportsOriginKeyMap.finance.key,
            standardReportsOriginKeyMap.policies.key,
            //standardReportsOriginKeyMap.reputationalRisk.key,
            standardReportsOriginKeyMap.legal.key,
        ],
        topicIds: [
            standardReportsOriginKeyMap.schoolImprovment.topics.schoolImprovementsSelfEvaluationAndOfsted,

            standardReportsOriginKeyMap.educationalEnvironment.topics.leadershipAndManagement,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationIntent,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImplementation,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactLearning,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactData,
            standardReportsOriginKeyMap.educationalEnvironment.topics.behaviourAndAttitudes,
            standardReportsOriginKeyMap.educationalEnvironment.topics.personalDevelopment,

            standardReportsOriginKeyMap.governance.topics.governance,

            standardReportsOriginKeyMap.people.topics.wellbeing,
            standardReportsOriginKeyMap.people.topics.employmentAndHr,

            standardReportsOriginKeyMap.premises.topics.healthAndSafety,
            standardReportsOriginKeyMap.premises.topics.healthAndSafetyCovid19,
            //standardReportsOriginKeyMap.premises.topics.assets,
            standardReportsOriginKeyMap.premises.topics.facilities,

            //standardReportsOriginKeyMap.finance.topics.banking,
            standardReportsOriginKeyMap.finance.topics.proceduresAndPractices,
            standardReportsOriginKeyMap.finance.topics.incomeGeneration,
            //standardReportsOriginKeyMap.finance.topics.vat,
            //standardReportsOriginKeyMap.finance.topics.pensions,
            standardReportsOriginKeyMap.finance.topics.purchasingAndSlas,

            standardReportsOriginKeyMap.policies.topics.statutoryPolicies,

            //standardReportsOriginKeyMap.reputationalRisk.topics.reputationalRisk,

            standardReportsOriginKeyMap.legal.topics.legalCompliance,
        ],
    } as ReportSettingsModel,

    governorReport: {
        baseReport: 'executiveSummary',
        reportName: 'Governor report',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: true,
        conclusion: true,

        sectionIds: [
            standardReportsOriginKeyMap.schoolImprovment.key,
            standardReportsOriginKeyMap.educationalEnvironment.key,
            standardReportsOriginKeyMap.governance.key,
            standardReportsOriginKeyMap.people.key,
            standardReportsOriginKeyMap.premises.key,
            standardReportsOriginKeyMap.finance.key,
            standardReportsOriginKeyMap.policies.key,
            standardReportsOriginKeyMap.reputationalRisk.key,
            standardReportsOriginKeyMap.legal.key,
        ],
        topicIds: [
            standardReportsOriginKeyMap.schoolImprovment.topics.schoolImprovementsSelfEvaluationAndOfsted,

            standardReportsOriginKeyMap.educationalEnvironment.topics.leadershipAndManagement,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationIntent,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImplementation,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactLearning,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactData,
            standardReportsOriginKeyMap.educationalEnvironment.topics.behaviourAndAttitudes,
            standardReportsOriginKeyMap.educationalEnvironment.topics.personalDevelopment,

            standardReportsOriginKeyMap.governance.topics.governance,

            standardReportsOriginKeyMap.people.topics.wellbeing,
            standardReportsOriginKeyMap.people.topics.employmentAndHr,

            standardReportsOriginKeyMap.premises.topics.healthAndSafety,
            standardReportsOriginKeyMap.premises.topics.healthAndSafetyCovid19,
            standardReportsOriginKeyMap.premises.topics.assets,
            standardReportsOriginKeyMap.premises.topics.facilities,

            standardReportsOriginKeyMap.finance.topics.banking,
            standardReportsOriginKeyMap.finance.topics.proceduresAndPractices,
            standardReportsOriginKeyMap.finance.topics.incomeGeneration,
            //standardReportsOriginKeyMap.finance.topics.vat,
            //standardReportsOriginKeyMap.finance.topics.pensions,
            standardReportsOriginKeyMap.finance.topics.purchasingAndSlas,

            standardReportsOriginKeyMap.policies.topics.statutoryPolicies,

            standardReportsOriginKeyMap.reputationalRisk.topics.reputationalRisk,

            standardReportsOriginKeyMap.legal.topics.legalCompliance,
        ],
    } as ReportSettingsModel,

    snapshot: {
        baseReport: 'sef',
        reportName: 'SEF Lite',
        customReportId: null,
        tier: ProductTier.Snapshot,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    sixtyMinuteSEF: {
        baseReport: 'sef',
        reportName: 'SEF Plus',
        customReportId: null,
        tier: ProductTier.SixtyMinuteSEF,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    fullSEF: {
        baseReport: 'sef',
        reportName: 'SEF',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    // This is used for "Create a new report" to create a blank report.
    custom: {
        baseReport: 'sef',
        reportName: 'Custom report',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: false,
        breakdown: false,
        areasOfStrength: false,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    // This is used when showing reports for a specific award.
    awardSummary: {
        baseReport: 'sef',
        reportName: 'Report', // Want to keep this name as generic as possible.
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    // This is used when showing reports for a specific award.
    subjectLeadershipReview: {
        baseReport: 'sef',
        reportName: 'Subject leadership review',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    // This is used when showing reports for a specific award.
    subjectHelpfulQuestions: {
        baseReport: 'rag',
        reportName: 'Subject helpful questions',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    // This is used when showing reports for a specific reading review
    readingReviewEYFSToKS2: {
        baseReport: 'rag',
        reportName: 'Phonic/Reading Focus | EYFS, Key Stage 1, Key Stage 2',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,


    // This is used when showing reports for a specific reading review
    readingReviewKS3ToKS4: {
        baseReport: 'rag',
        reportName: 'Reading Report | Key Stage 3 and Key Stage 4',
        customReportId: null,
        tier: null,

        introduction: false,
        schoolBackground: false,
        consideration: false,
        reviewers: false,
        overallResults: true,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: false,
        conclusion: false,

        sectionIds: [],
        topicIds: [],
    } as ReportSettingsModel,

    // Legacy SEF format, replaced with shortFormSEF below.  We have kept this for backwards compability with existing reports but it cannot be trigged via the UI anymore.
    sef: {
        baseReport: 'executiveSummary',
        reportName: 'Long format SEF', 
        customReportId: null,
        tier: null,

        introduction: true,
        schoolBackground: true,
        consideration: false,
        reviewers: false,
        overallResults: false,
        breakdown: true,
        areasOfStrength: true,
        areasForDevelopment: true,
        conclusion: true,

        sectionIds: [
            standardReportsOriginKeyMap.schoolImprovment.key,
            standardReportsOriginKeyMap.educationalEnvironment.key,
            standardReportsOriginKeyMap.governance.key,
            standardReportsOriginKeyMap.people.key,
            standardReportsOriginKeyMap.premises.key,
            standardReportsOriginKeyMap.finance.key,
            standardReportsOriginKeyMap.policies.key,
            //standardReportsOriginKeyMap.reputationalRisk.key,
            //standardReportsOriginKeyMap.legal.key,
        ],
        topicIds: [
            standardReportsOriginKeyMap.schoolImprovment.topics.schoolImprovementsSelfEvaluationAndOfsted,

            standardReportsOriginKeyMap.educationalEnvironment.topics.leadershipAndManagement,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationIntent,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImplementation,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactLearning,
            standardReportsOriginKeyMap.educationalEnvironment.topics.qualityOfEducationImpactData,
            standardReportsOriginKeyMap.educationalEnvironment.topics.behaviourAndAttitudes,
            standardReportsOriginKeyMap.educationalEnvironment.topics.personalDevelopment,

            standardReportsOriginKeyMap.governance.topics.governance,

            standardReportsOriginKeyMap.people.topics.wellbeing,
            //standardReportsOriginKeyMap.people.topics.employmentAndHr,

            standardReportsOriginKeyMap.premises.topics.healthAndSafety,
            standardReportsOriginKeyMap.premises.topics.healthAndSafetyCovid19,
            //standardReportsOriginKeyMap.premises.topics.assets,
            standardReportsOriginKeyMap.premises.topics.facilities,

            standardReportsOriginKeyMap.finance.topics.banking,
            //standardReportsOriginKeyMap.finance.topics.proceduresAndPractices,
            //standardReportsOriginKeyMap.finance.topics.incomeGeneration,
            //standardReportsOriginKeyMap.finance.topics.vat,
            //standardReportsOriginKeyMap.finance.topics.pensions,
            //standardReportsOriginKeyMap.finance.topics.purchasingAndSlas,

            standardReportsOriginKeyMap.policies.topics.statutoryPolicies,

            //standardReportsOriginKeyMap.reputationalRisk.topics.reputationalRisk,

            //standardReportsOriginKeyMap.legal.topics.legalCompliance,
        ],
    } as ReportSettingsModel,
};