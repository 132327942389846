import * as React from 'react';
import { List } from '../List';

/**
 * List filtered to show only "Reading review KS3 to KS4" question sets.
 */
export const ReadingReviewKS3ToKS4List = () => {
    const filter = React.useCallback((items: Array<any>) => {
        if (!items) {
            return items;
        }

        return items.filter(item => item.name === 'Reading review KS3 to KS4');
    }, []);

    return (
        <List filter={filter} baseRoute="/administration/reading-ks3-to-ks4" />
        );
};