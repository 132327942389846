import * as React from "react";
import { ValidationState } from "pojo-validator";
import { ContainerComponentProps } from "react-withcontainer";
import { ResetPassword } from "../../api/models/ResetPassword";
import { AuthenticationService } from "../../services/AuthenticationService";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { useUniversalNavigation } from "react-universal-navigation";
import { AppStateCore } from "../../store";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { Identity, login } from "../../store/user";
import { PasswordValidation } from "../../services/passwordValidation/PasswordValidation";
import { SaveOnlyEditUiPropsBase } from "../containers/common/SaveOnlyEditUiPropsBase";
import { useValidatorCallback } from "pojo-validator-react";
import { useAsyncCallback } from "react-use-async-callback";
import { generatePasswordRulesDescrption } from "../../utilities/generatePasswordRulesDescription";
import { isNullOrUndefined } from "util";


export interface ResetPasswordContainerProps extends ContainerComponentProps<ResetPasswordUiProps> {
    authenticationService: AuthenticationService,
    passwordValidation: PasswordValidation,

    // Usually from redux.
    isAuthenticated: boolean,
    login: (token: string | undefined, identity: Identity | undefined) => void
}

export interface ResetPasswordUiProps extends SaveOnlyEditUiPropsBase<ResetPassword> {
    isAuthenticated: boolean,
    passwordRulesDescription: string,

    tokenIsValid: boolean | undefined,
    checkingToken: boolean,
    checkTokenErrors: any
}

/**
 * Container that allows us to reset the password of a user after email validation.  PasswordResetContainer triggers the start of the process.
 * @param props
 */
export const _ResetPasswordContainer = (props: ResetPasswordContainerProps) => {
    let { component, authenticationService, passwordValidation, login, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const user = navigation.getParam('user', '');
    const token = navigation.getParam('token', '');

    const [model, setModel] = React.useState<ResetPassword>({ user: user, token: token, newPassword: '' });

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<ResetPassword>) => {
        setModel(prevState => ({
            ...prevState,
            ...changes
        }));
    }, [setModel]);

    const [tokenIsValid, setTokenIsValid] = React.useState<boolean | undefined>(undefined);
    const [checkToken, { isExecuting: checkingToken, errors: checkTokenErrors }] = useAsyncCallback(async () => {
        let result = await authenticationService.checkPasswordResetToken(user, token);
        setTokenIsValid(result);
    }, [user, token, authenticationService, setTokenIsValid]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('newPassword')) {
            validation.clearErrors('newPassword');
            validation.check('newPassword', () => !model.newPassword, 'Password is required');
            const [isPasswordValid, passwordErrors] = passwordValidation.check(model.newPassword);
            validation.check('newPassword', () => !isPasswordValid, `Password ${passwordErrors.join(', ')}.`)
        }
    }, [model]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Reset the user's password.
        await authenticationService.resetPassword(model.user, model.token, model.newPassword);

        // Login
        let result = await authenticationService.login(model.user, model.newPassword);
        // Save the current login session to the application state.
        login(result.token, result.identity);

        return true;
    }, [model, authenticationService, login]);

    React.useEffect(() => {
        if (isNullOrUndefined(tokenIsValid) && !checkingToken && !checkTokenErrors) {
            checkToken();
        }
    }, [checkToken, tokenIsValid, setTokenIsValid, checkTokenErrors]);

    // Generate a description of the required password rules by validating an empty password.
    const passwordRulesDescription = React.useMemo(() => generatePasswordRulesDescrption(passwordValidation), [passwordValidation]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            passwordRulesDescription={passwordRulesDescription}
            tokenIsValid={tokenIsValid} checkingToken={checkingToken} checkTokenErrors={checkTokenErrors}
        />
    );
};

export const __ResetPasswordContainer = withServiceProps<ResetPasswordContainerProps, AppServicesCore>(services => ({
    authenticationService: services.authenticationService(),
    passwordValidation: services.passwordValidation()
}))(_ResetPasswordContainer);

export const ResetPasswordContainer = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        isAuthenticated: state.user.isAuthenticated
    }),
    /* mapDispatchToProps */
    (dispatch: React.Dispatch<AnyAction>) => ({
        login: (token: string | undefined, identity: Identity | undefined) => dispatch(login(token, identity))
    })
)(__ResetPasswordContainer);
