// Import the correct css to match the theme provided to ReactQuill below.
//import 'react-quill/dist/quill.core.css'; // To use this set theme=""
//import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

import * as React from 'react';
import ReactQuill from 'react-quill';

export interface HtmlEditorProps {
    value?: string,
    readOnly?: boolean,
    onChange?: (value: string) => void,
    onBlur?: () => void,
}

/**
 * Html editor component that abstracts the editor actually being used from the code using it where its unimportant.
 * 
 * This version uses ReactQuill internally.
 * 
 * @param props
 */
export const HtmlEditor = (props: HtmlEditorProps) => {
    const { value, readOnly, onChange, onBlur } = props;

    const toolbarOptions = React.useMemo(() => ([
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

        ['link' /*, 'image' */],

        ['blockquote', 'code-block'],


        [{ 'align': [] }],

        //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        //[{ 'direction': 'rtl' }],                         // text direction

        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //[{ 'font': [] }],

        ['clean']                                         // remove formatting button
    ]), []);

    return (
        <ReactQuill value={value} theme="snow" readOnly={readOnly}
            modules={{
                toolbar: readOnly? false: toolbarOptions,
            }}
            onChange={value => {
                if (onChange) {
                    onChange(value);
                }
            }}
            onBlur={() => {
                if (onBlur) {
                    onBlur();
                }
            }}
        />
        );
};