import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

interface PrivateContainerProps {
    children: any;
    role?: string,
    user: RequiredUserState
}

/**
 * Container that will hide its children if the user is not logged in or does not have the right security role.
 * 
 * For routes with react-router use [[PrivateRoute]] instead of wrapping the [[Route]] in this component.
 * 
 * @param props
 */
export const _PrivateContainer = (props: PrivateContainerProps) => {
    const { children, user, role } = props;

    // If the user is not logged in return no children.
    if (!user || !user.isAuthenticated) {
        return null;
    }

    // If we need a specific role and the user doesn't have that role return no children.
    if (role && (!user.identity || !user.identity.roles || user.identity.roles.findIndex((item) => item == props.role) == -1)) {
        return null;
    }

    // Otherwise return the children
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};


interface RequiredUserState {
    isAuthenticated: boolean,

    identity: {
        roles: Array<string>
    }
}

interface RequiredAppState {
    user: RequiredUserState
}

export const PrivateContainer = connect(
    // mapStateToProps
    (state: RequiredAppState) => ({
        user: state.user
    }),
    //mapDispatchToProps
    (dispatch: React.Dispatch<AnyAction>) => ({
    }),
    null,
    {
        pure: false
    }
)(_PrivateContainer);
