/**
 * Tiers that schools using the product can be on, and to which questions and other functionality is assigned.
 */
export enum ProductTier {
    Snapshot = '10_Snapshot',
    SixtyMinuteSEF = '20_SixtyMinuteSEF',
    FullReview = '30_FullReview',
   // RichUnderstanding = '40_RichUnderstanding',
}

/**
 * All available ProductTiers in an array.
 */
export const productTiers = [
    ProductTier.Snapshot,
    ProductTier.SixtyMinuteSEF,
    ProductTier.FullReview,
    //ProductTier.RichUnderstanding,
];

/**
 * Returns the display name of a product tier.
 * @param value
 */
export function productTierDisplayName(value: ProductTier) {
    switch (value) {
        case ProductTier.Snapshot:
            return 'SEF Lite';
        case ProductTier.SixtyMinuteSEF:
            return 'SEF Plus';
        case ProductTier.FullReview:
            return 'Targeted analysis';
        //case ProductTier.FullReview:
        //    return 'Targeted analysis';
        //case ProductTier.RichUnderstanding:
        //    return 'Rich understanding';
    }
}

/**
 * Returns true if targetTier is unlocked for a school at schoolTier.
 * @param targetTier
 * @param schoolTier
 */
export function isProductTierUnlocked(targetTier: ProductTier, schoolTier: ProductTier) {
    // Make use of the fact our tier values are named with a numerical prefix to indicate order.
    return (schoolTier >= targetTier);
}