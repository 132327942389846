import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { BasicPagedListContainerProps, BasicPagedListContainer } from "../../containers/common/basicContainers/BasicPagedListContainer";
import { PagedListUiPropsBase } from "../../containers/common/PagedListUiPropsBase";

export interface DataItem {
    name: string,
    versions: Array<{
        id: string,
        name: string,
        versionNumber: number,
        patchNumber: number,
        publishDate: string | undefined
    }>
}

export interface ListUiProps extends PagedListUiPropsBase<DataItem> {
}

export const ListContainer = withServiceProps<BasicPagedListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.pages.viewModels.list()
}))(BasicPagedListContainer);
