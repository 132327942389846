import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Project } from '../../../../api/models/Project';
import { TimelineChart } from './TimelineChart';
import { SchoolTerm } from '../../../../api/models/SchoolTerm';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AwardTag } from '../../../../api/models/AwardTag';
import { PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';

export interface TimelineCardProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    timelineResults: Array<ProjectAnalysisResult>,
    placeholderReplacementMode: PlaceholderReplacementMode, //for replacing place holders in section names
    showOtherSchools?: boolean,
    chartHeight?: number,
    schoolTerms: Array<SchoolTerm>,
    awardTag: AwardTag | undefined,
}

export const TimelineCard = (props: TimelineCardProps) => {
    // Show the chart.
    return (
        <Card>
            {/*<CardHeader>*/}
            {/*    <h6>Improvement over time</h6>*/}
            {/*</CardHeader>*/}
            <CardBody>
                {
                    !props.timelineResults.length ? (
                        <div className="nothing-here">
                            <FontAwesomeIcon icon={['far', 'frown-open']} />
                            No information is available to create this dashboard display yet.
                        </div>
                    ) : (
                            <TimelineChart {...props} />
                        )
                }

                <ConditionalFragment showIf={props.timelineResults.length <= 1}>
                    <div className="text-muted">
                        As you update your self evaluation each term you will start to see your improvement over time mapped out here for you.  As this is only the first term you've used the self evaluation the information shown on this graph may not be that useful to you yet.
                    </div>
                </ConditionalFragment>
            </CardBody>
        </Card>
    );
};

