import * as React from 'react';
import { FormGroup, Label, Row, Col, Button, Collapse, CardHeader, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidatedElasticInput } from '../../../shared/ValidatedElasticInput';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';
import { ValidationErrors } from 'pojo-validator';
import { getOperationalLevels } from '../../../../utilities/getOperationalLevels';

export interface QuestionChoiceUiProps {
    model: QuestionChoice,
    isCreate: boolean,
    change: (changes: Partial<QuestionChoice>) => void,
    validate: (fieldsToCheck?: Array<string>) => void,
    validationErrors: ValidationErrors,
    remove: () => void,

    isPublished: boolean,
    isReadOnly: boolean,
}

export const QuestionChoiceUi = (props: QuestionChoiceUiProps) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(props.isCreate);
    const toggleOpen = React.useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, [setIsOpen]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.change({ [target.name]: (target.type == 'checkbox' ? target.checked : target.value) });
    }, [props.change]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const operationalLevels = React.useMemo(() => getOperationalLevels(), []);

    return (
        <Card>
            <CardHeader onClick={toggleOpen} style={{ cursor: 'pointer' }}>
                <Row>
                    <Col>
                        {props.model.name? props.model.name: '(New answer)'}
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen? 'caret-up': 'caret-down'} />
                    </Col>
                </Row>
            </CardHeader>

            <Collapse isOpen={isOpen}>
                {
                    isOpen ? (
                        <CardBody>
                            <FormGroup>
                                <Label htmlFor="name">Answer</Label>
                                <Row>
                                    <Col>
                                        <ValidatedElasticInput type="textarea" readOnly={props.isReadOnly} name="name" autoComplete="off" placeholder="Answer name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                                    </Col>
                                    {
                                        !props.isPublished /* Can't remove answers from a published question set. */ ? (
                                            <Col xs="auto">
                                                <Button color="link" onClick={props.remove}><FontAwesomeIcon icon="trash-alt" color="danger" /><span className="sr-only">Remove</span></Button>
                                            </Col>
                                            ): null
                                    }
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="operationalLevel">Operational level</Label>
                                <ValidatedInput type="select" readOnly={props.isReadOnly} name="operationalLevel" placeholder="(Select an operational level)" value={props.model.operationalLevel} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors}>
                                    {
                                        operationalLevels.map(item => (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                            ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </CardBody>
                        ): null
                }
            </Collapse>
        </Card>
    );
};
