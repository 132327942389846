import * as React from 'react';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from './shared/privateRoute/PrivateRoute';

export interface RouteConfig {
    path?: string,
    exact?: boolean,
    component: any //React.Component | React.FunctionComponent
    private?: boolean,
    role?: string,
    name?: string // Name to show in the breadcrumbs.

    childRoutes?: Array<RouteConfig> // If passed we will pass routes={} to the component via renderProps
}


interface AppRoutesProps {
    routes: Array<RouteConfig>
}

// Render a Route or PrivateRoute component for all passed in routes.
export const AppRoutes = (props: AppRoutesProps) => {
    const { routes, ...rest } = props;

    // Generate a route or PrivateRoute as appropriate for each route passed in.
    return (
        <Switch>
            {
                routes.map((item, index) => {
                    // If we have child routes we pass another AppRoutes instance with the child routes as props.children of the component.
                    if (item.childRoutes && item.childRoutes.length) {
                        if (item.private) {
                            return (
                                <PrivateRoute key={index} role={item.role} path={item.path} exact={item.exact} render={(renderProps: any) => (
                                    <item.component appRoutes={item.childRoutes} {...renderProps}>
                                        <AppRoutes routes={item.childRoutes || []} {...renderProps} />
                                    </item.component>
                                )} />
                            );
                        }

                        return (
                            <Route key={index} path={item.path} exact={item.exact} render={(renderProps: any) => (
                                <item.component appRoutes={item.childRoutes} {...renderProps}>
                                    <AppRoutes routes={item.childRoutes || []} {...renderProps} />
                                </item.component>
                            )} />
                        );
                    }

                    // Otherwise we just trigger the component directly.
                    if (item.private) {
                        return (<PrivateRoute key={index} role={item.role} path={item.path} exact={item.exact} component={item.component} {...rest} />);
                    }

                    return (
                        <Route key={index} path={item.path} exact={item.exact} component={item.component} {...rest} />
                    )
                })
            }
        </Switch>
    );
};