import * as React from 'react';
import {Container} from 'reactstrap';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useApiService } from '../../../api/useApiService';
import { useManagedChildModels } from '../../shared/hooks/useManagedChildModels';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ProjectTopic } from '../../../api/models/ProjectTopic';
import { Topic } from '../../../api/models/Topic';
import { ProjectSection } from '../../../api/models/ProjectSection';
import { Project } from '../../../api/models/Project';
import { useProjectSettings } from '../../../api/useProjectSettings';
import { SchoolTargetComparisonTable } from './SchoolTargetComparisonTable';
import { ProjectAnalysisLevel } from '../../../api/models/ProjectAnalaysisLevel';
import { ProjectAnalysisResult } from '../../../api/models/ProjectAnalaysisResult';
import { IdAndName } from '../../../api/models/IdAndName';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Section } from '../../../api/models/Section';

export interface EditTargetsProps {
}

/**
 * Edit of expectation targets by a trust for a school.
 * @param props
 */
export const EditTargets = (props: EditTargetsProps) => {
    const navigation = useUniversalNavigation(props);
    const projectId = navigation.getParam('projectId', '');
    const sectionOriginKey = navigation.getParam('sectionOriginKey', '');
    const topicOriginKey = navigation.getParam('topicOriginKey', '');

    const singleSchool = String(navigation.getParam('isForSingleSchool', ''));
    const isForSingleSchool = singleSchool === 'singleschool' ? true : false;

    // Get the various API calls we are going to need.
    const api = useApiService();
    const loadViewModel = api.projects.viewModels.editExpectations();
    const projectSectionsRepository = api.projectSections.repository();
    const projectTopicsRepository = api.projectTopics.repository();
    const loadDashboardViewModel = api.projects.viewModels.dashboard();


    // Data for editing the targets.
    const [model, setModel] = React.useState<Project | undefined>();
    const projectSections = useManagedChildModels<ProjectSection>(projectSectionsRepository);
    const projectTopics = useManagedChildModels<ProjectTopic>(projectTopicsRepository);
    const [sections, setSections] = React.useState<Array<Section>>([]);
    const [topics, setTopics] = React.useState<Array<Topic>>([]);

    // Load
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(projectId);
        projectSections.setModels(result.projectSections);
        projectTopics.setModels(result.projectTopics);
        setSections(result.sections);
        setTopics(result.topics);

        setModel(result.model);
    }, [loadViewModel, projectId, projectSections, projectTopics, setSections, setTopics, setModel]);

    const projectSettings = useProjectSettings(projectId);

    // Load if we need to.
    React.useEffect(() => {
        if (!projectId || isLoading || (model && model.id === projectId)) {
            return;
        }

        load();
    }, [projectId, isLoading, model, load]);

    // We also want results from the dashboard so we can show them.
    // Results from the dashboard.
    const [section, setSection] = React.useState<IdAndName | undefined>(undefined);
    //const [topic, setTopic] = React.useState<IdAndName | undefined>(undefined);
    const [results, setResults] = React.useState<Array<ProjectAnalysisResult>>([]);
    const [lastSectionOriginKey, setLastSectionOriginKey] = React.useState<string>(sectionOriginKey);

    // Load the dashboard
    const [loadDashboard, { isExecuting: isLoadingDashboard, errors: loadDashboardErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        // Work out the analysis level based on the filters.
        let anaysisLevel = ProjectAnalysisLevel.Section;
        if (topicOriginKey) {
            anaysisLevel = ProjectAnalysisLevel.Question;
        } else if (sectionOriginKey) {
            anaysisLevel = ProjectAnalysisLevel.Topic;
        }

        let result = await loadDashboardViewModel(projectId || 'defaults', undefined /* awardTagId */, anaysisLevel, sectionOriginKey, topicOriginKey, undefined /* quesitonaireId */);
        //setTrust(result.trust);
        //setSchool(result.school);
        setResults(result.results);
        setSection(result.section);
        //setTopic(result.topic);
        //setRisks(result.risks);
        //setSchoolTerms(result.schoolTerms);
        //setTimelineResults(result.timelineResults);

        // Store the last section and topic we loaded for.
        setLastSectionOriginKey(sectionOriginKey);
        //setLastTopicOriginKey(topicOriginKey);

        setModel(result.model);
        return true;
    }, [projectId, loadDashboardViewModel, setModel, /*setSchool, setTrust,*/ setResults, sectionOriginKey, topicOriginKey, setSection, /*setTopic, setRisks,*/ setLastSectionOriginKey /*, setLastTopicOriginKey */]);


    React.useEffect(() => {
        if ((!model || (projectId !== model.id) || (sectionOriginKey !== lastSectionOriginKey) /*|| (topicOriginKey !== lastTopicOriginKey) */) && !isLoadingDashboard && !loadDashboardErrors) {
            loadDashboard();
        }
    }, [model, isLoadingDashboard, loadDashboardErrors, loadDashboard]);

    // Save.
    const [save, { isExecuting: isSaving, errors: saveErrors }] = useAsyncCallback(async () => {
        await projectSections.save();
        await projectTopics.save();
    }, [projectSections, projectTopics]);

    // Save everything with some debounce support so we don't try and save after change if the user is making changes quickly.
    const [needToSave, setNeedToSave] = React.useState<boolean>(false);
    const saveDebounceTimerId = React.useRef<any>();
    const saveDebounce = () => {
        let delay = 400;
        if (saveDebounceTimerId.current) {
            clearTimeout(saveDebounceTimerId.current);
        }

        saveDebounceTimerId.current = setTimeout(() => {
            setNeedToSave(true);
        }, delay);
    };

    React.useEffect(() => {
        if (needToSave) {
            save();
            setNeedToSave(false);
        }
    }, [save, needToSave]);

    if (!model || isLoading) {
        return <LoadingIndicator />
    }

    return (
        <div className="main-container">
            <div className="main-heading">
                <h1>
                    <ConditionalFragment showIf={projectSettings.isSelfEvaluation && !isForSingleSchool}>
                        <>Trust targets for schools</>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={projectSettings.isSelfEvaluation && isForSingleSchool}>
                        <>School targets</>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={projectSettings.isDueDiligence}>
                        <>Due diligence expectations</>
                    </ConditionalFragment>
                   </h1>
            </div>

            <AlertOnErrors errors={[loadingErrors, saveErrors]} />

            <SchoolTargetComparisonTable
                model={model}
                projectSections={projectSections} projectTopics={projectTopics}
                sections={sections} topics={topics}
                results={results}
                filterBySectionOriginKey={sectionOriginKey}
                saveDebounce={saveDebounce}
                projectSettings={projectSettings}
                isForSingleSchool={isForSingleSchool}
            />

            <ConditionalFragment showIf={isLoading || isLoadingDashboard}>
                <LoadingIndicator />
            </ConditionalFragment>
        </div>
    );
};
