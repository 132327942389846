import { ApiFetch } from 'apifetch-json';
import { HttpRestRepository } from 'pojo-repository';

/**
 * Specialised subclass of HttpRestRepository that uses the Abidect convention of calling a GET on /defaults to perform a create with
 * values initialized on the server side.
 */
export class AmbidectHttpRestRepository<T = any, Key = any> extends HttpRestRepository {
    /**
     * Constructor.
     * @param url URL of the REST endpoint.
     * @param apiFetch custom ApiFetch object used for network access.
     */
    constructor(url: string, apiFetch?: ApiFetch) {
        super(url, apiFetch);
    }

    /**
     * Create a new model initialized on the server by calling GET on /defaults endpoint.
     *
     * NOTE this does not add the model to the store.  That is only done if save() is called on the returned model.
     */
    async create(): Promise<T> {
        // 
        // NOTE we don't allow the results to be cached as we want the server to perform any logic it needs, including time and user
        // sensative logic if required.
        let url = `${this.url}/defaults`;
        let ret = await this.api.get<T>(url);
        return ret;
    }
}