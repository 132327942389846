import * as React from 'react';
import { Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertOnErrors } from '../../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ElasticInput } from 'reactstrap-elasticinput';
import { ProjectSection } from '../../../../api/models/ProjectSection';

export interface EditProjectSectionOverviewOpinionModalProps {
    model: ProjectSection,
    changeModel: (changes: Partial<ProjectSection>) => void,
    save: () => Promise<boolean>,
    isSaving: boolean,
    savingErrors: any,
    cancel: () => void
}

export const EditProjectSectionOverviewOpinionModal = (props: EditProjectSectionOverviewOpinionModalProps) => {
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    // Display the UI.

    if (!props.model) {
        return (<></>);
    }

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                Edit overall thoughts for the section
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={props.savingErrors} />

                <Label htmlFor="overviewOpinion">Overall thoughts for the section</Label>
                <ElasticInput type="textarea" name="overviewOpinion" placeholder="Overall thoughts for the section" value={props.model.overviewOpinion} onChange={onChange} />
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <ButtonAsync color="primary" onClick={props.save} isExecuting={props.isSaving}
                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                    Save
                </ButtonAsync>
                <Button color="primary" outline onClick={props.cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
