import * as React from 'react';
import { Form, FormGroup, Label, Alert, Row, Col, Container, Input, Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormText, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Collapse, CardHeader, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export interface LinkUrlModalProps {
    originalUrlText: string,
    save: (urlText: string) => void,
    cancel: () => void,
}

/**
 * Link a URL.
 * @param props
 */
export const LinkUrlModal = (props: LinkUrlModalProps) => {
    const [urlText, setUrlText] = React.useState<string>(props.originalUrlText);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setUrlText(event.currentTarget.value);
    }, [setUrlText]);

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                Link to url
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label htmlFor="name">URL</Label>
                    <Input type="url" name="url" placeholder="" value={urlText} onChange={onChange}  />
                </FormGroup>
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <Button color="primary" onClick={() => props.save(urlText)}>
                    Save
                </Button>
                <Button color="primary" outline onClick={props.cancel}>Cancel</Button>
            </ModalFooter>            
        </Modal>
    );
};
