import * as React from 'react';
import { ValidatedInput, ValidatedInputProps } from 'pojo-validator-reactstrap';
import { ElasticInputProps, ElasticInput } from 'reactstrap-elasticinput';


export interface ValidatedElasticInputProps extends ElasticInputProps, ValidatedInputProps {
}

/**
 * ElasticInput and ValidatedInput wrapper class that makes it easy to use both together.
 * 
 * @param props
 */
export const ValidatedElasticInput = (props: ValidatedElasticInputProps) => {
    const { component, ...rest } = props;

    return (
        <ValidatedInput component={component || ElasticInput} {...rest} />
    );
};
