/**
 * Prioirty of actions.
 */
export enum ActionPriority {
    Urgent = '10_Urgent',
    High = '20_High',
    Medium = '30_Medium',
    Low = '30_Low',
}

/**
 * All available ProductTiers in an array.
 */
export const actionPriorities = [
    ActionPriority.Urgent,
    ActionPriority.High,
    ActionPriority.Medium,
    ActionPriority.Low,
];

/**
 * Returns the display name of a action priority.
 * @param value
 */
export function actionPriorityDisplayName(value: ActionPriority) {
    switch (value) {
        case ActionPriority.Urgent:
            return 'Urgent';
        case ActionPriority.High:
            return 'High';
        case ActionPriority.Medium:
            return 'Medium';
        case ActionPriority.Low:
            return 'Low';
    }
}
