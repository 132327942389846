import * as React from 'react';
import { Edit } from '../edit/Edit';
import { useUniversalNavigation } from 'react-universal-navigation';

/**
 * Edit for "subject leadership review" question sets.
 */
export const SubjectLeadershipReviewEdit = (props: any) => {
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');

    return (
        <Edit id={id}  baseRoute="/administration/subject-leadership-review" />
        );
};