import { isNullOrUndefined } from 'util';

/**
 * Generate a clickable URL by appending http:// on the front if we need to.
 * @param percentage
 */
export function generateClickableUrl(url: string): string {
    if (url.indexOf(':') == -1) {
        return `http://${url}`;
    }

    return url;
}