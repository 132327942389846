import * as React from 'react';
import { Button, Table, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { Link } from 'react-router-dom';
import { ListContainer, ListUiProps } from './ListContainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { InfiniteScrollingTable } from '../../shared/infiniteScrolling/InfiniteScrollingTable';
import { SearchAsYouTypeInput } from '../../shared/SearchAsYouTypeInput';
import { DateLabel } from '../../shared/DateLabel';
import { ConditionalFragment } from 'react-conditionalfragment';

export const ListUi = (props: ListUiProps) => {
    const navigation = useUniversalNavigation(props);

    const loadPage = React.useCallback((page: number) => {
        props.loadPage(page, props.search);
    }, [props.loadPage, props.search]);

    const [menuIsOpen, setMenuIsOpen] = React.useState<{ [id: string]: boolean }>({});
    const toggleMenuOpen = React.useCallback((id: string) => {
        setMenuIsOpen(prevState => ({
            ...prevState,
            [id]: (prevState[id] ? false : true)
        }));
    }, [setMenuIsOpen]);

    

    const maintainTextPefix = props.isForSingleSchool && props.schoolId ? `/school/${props.schoolId}/singleSchoolTrustUsers/${props.trustId}/` : `/manage/trustUsers/${props.trustId}/`;

    // Open the default action when the user clicks on a row.
    const onRowDoubleClick = React.useCallback((id: string, event: React.MouseEvent<HTMLTableRowElement>) => {
        navigation.push(`${maintainTextPefix}edit/${encodeURIComponent(id)}`);
    }, [navigation]);

    return (
        <div className="main-container">
            <AlertOnErrors errors={props.loadingErrors} />

            <div className="toolbar-top">
                <LinkContainer to={`${maintainTextPefix}add`}>
                    <Button color="primary" className="btn btn-primary"><FontAwesomeIcon icon="plus" /> <span>Add</span></Button>
                </LinkContainer>
            </div>

            <div className="search-bar-container">
                <SearchAsYouTypeInput value={props.search} onSearch={props.changeSearch} placeholder="Search" />
            </div>

            <div className="table-responsive">
                <Table striped hover className="table-sm">
                    <thead>
                        <tr>
                            <th><span className="d-none d-sm-inline">Forename</span></th>
                            <th><span className="d-none d-sm-inline">Surname</span></th>
                            <th><span className="d-none d-sm-inline">Email</span></th>
                            <ConditionalFragment showIf={props.isForSingleSchool}>
                                <th><span className="d-none d-sm-inline">Restricted access?</span></th>
                            </ConditionalFragment>
                            <th><span className="d-none d-sm-inline">Registered on</span></th>
                            <th><span className="d-none d-sm-inline">Last login</span></th>
                            <th><span className="d-inline d-sm-none">User</span></th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.model && !props.model.length ? (
                                <tr className="nothing-here">
                                    <td colSpan={7}>
                                        {
                                            props.search ? (
                                                <div>
                                                    <FontAwesomeIcon icon="search-minus" />
                                                    Sorry there are no users matching "{props.search}".
                                                </div>
                                            ) : (
                                                    <div>
                                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                                        You haven't added any users yet.<br />
                                                        You can <Link to={`${maintainTextPefix}add`}>add one</Link> to get started.
                                                </div>
                                                )
                                        }
                                    </td>
                                </tr>
                            ) : null
                        }

                        <InfiniteScrollingTable key={props.search} loadData={loadPage} nextPage={props.nextPage}>
                            {(props.model || []).map(item => (
                                <tr key={item.id} onDoubleClick={e => onRowDoubleClick(item.id, e)}>
                                    <td><span className="d-none d-sm-inline">{item.forename}</span></td>
                                    <td><span className="d-none d-sm-inline">{item.surname}</span></td>
                                    <td><span className="d-none d-sm-inline">{item.email}</span></td>
                                    <ConditionalFragment showIf={props.isForSingleSchool}>
                                        <td><span className="d-none d-sm-inline">{item.isLimitedToAwardTags ? 'Yes' : 'No'}</span></td>
                                    </ConditionalFragment>
                                    <td><span className="d-none d-sm-inline">
                                        {
                                            item.registrationDate ? (
                                                <DateLabel format="DD/MM/YYYY HH:mm" value={item.registrationDate} />
                                                ): null
                                        }
                                    </span></td>
                                    <td><span className="d-none d-sm-inline">
                                        {
                                            item.lastLoginDate ? (
                                                <DateLabel format="DD/MM/YYYY HH:mm" value={item.lastLoginDate} />
                                            ) : null
                                        }
                                    </span></td>
                                    <td>
                                        <div className="d-block d-sm-none">
                                            <div>{item.forename} {item.surname}</div>
                                            <div>{item.email}</div>
                                            <div className="text-muted">{item.roleGroup}</div>
                                        </div>
                                    </td>
                                    <td className="actions">
                                        <ButtonGroup>
                                            <LinkContainer to={`${maintainTextPefix}edit/${encodeURIComponent(item.id)}`}>
                                                <Button color="primary" outline>Edit</Button>
                                            </LinkContainer>
                                            <ButtonDropdown isOpen={menuIsOpen[item.id]} toggle={() => toggleMenuOpen(item.id)}>
                                                <DropdownToggle caret color="primary" outline />
                                                <DropdownMenu right>
                                                    <LinkContainer to={`${maintainTextPefix}delete/${encodeURIComponent(item.id)}`}>
                                                        <DropdownItem className="text-danger">
                                                            <FontAwesomeIcon icon="trash-alt" /> <span>Delete</span>
                                                        </DropdownItem>
                                                    </LinkContainer>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </InfiniteScrollingTable>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export const List = withContainer(ListContainer)(ListUi);
