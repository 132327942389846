import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { RAGPdfProps } from './RAGPdf';
import { Styles } from '../../executiveSummary/pdf/Styles';
import { Guid } from 'guid-string';
import { ProjectAnalysisResultItem, ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { getGradeString } from '../../../../utilities/getGradeString';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Strength } from '../../../../api/models/Strength';
import { Risk } from '../../../../api/models/Risk';
import { Question } from '../../../../api/models/Question';
import { QuestionResponse } from '../../../../api/models/QuestionResponse';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';


export interface RAGProjectTopicPdfUiProps extends RAGPdfProps {
    projectTopicId: Guid,
    isFirstTopic: boolean,
}

/**
 * Breakdown of questions by section and topic for RAG (pdf format).
 */
export const RAGProjectTopicPdfUi = (props: RAGProjectTopicPdfUiProps) => {
    const {
        questionResponses,
        questionChoices,
    } = props;

    // Find the appropriate items we want to work with.
    let [projectTopic, topic, results, questions, /*questionaires, */ excellences, strengths, risks, myResults] = React.useMemo(() => {
        let ps = props.projectTopics.models.find(item => item.id === props.projectTopicId);
        let topicId = '';
        if (ps) {
            topicId = ps.topicId;
        }

        let s = props.topics.find(item => item.id === topicId);

        let r = props.resultsByTopic.find(item => item.projectTopicId === props.projectTopicId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        const sectionResults = props.resultsBySection.find(item => !!s && item.sectionId === s.sectionId);
        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (sectionResults && sectionResults.results.length) {
            myResults = sectionResults.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        let q = props.questions.filter(item => item.topicId === topicId);
        //let qq = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));

        const strengthsx = props.strengths.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));
        const strengthsExcellences = strengthsx.filter(item => item.isExcellence);
        const strengthsAreasOfStrength = strengthsx.filter(item => !item.isExcellence);
        const risksx = props.risks.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));

        return [ps, s, rr, q, /*qq,*/ strengthsExcellences, strengthsAreasOfStrength, risksx, myResults];
    }, [props.projectTopics, props.topics, props.projectTopicId, props.questions, props.resultsByTopic, props.strengths, props.risks]);

    if (!props.model) {
        return (<></>);
    }


    if (!projectTopic) {
        return (<></>);
    }

    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <View wrap={false} style={{ marginBottom: 20, }}>
            <View style={{ ...Styles.row, marginBottom: 5, }}>
                <View style={{ ...Styles.col, width: '65%' }}>
                    <ConditionalFragment showIf={topic?.name !== props.sections.find(it => it.id === topic?.sectionId)?.name}>
                        <Text style={Styles.h5}>{topic && replacePlaceholders(topic.name, placeholderReplacementMode, { subjectName: props.subject?.name })}</Text>
                    </ConditionalFragment>
                </View>
            </View>
            <View>
                <TableFor
                    title="How confident are you in answering these questions?"
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    questions={questions}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    subjectName={props.subject?.name}
                />
            </View>
        </View>
    );
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and only used internall by the files main component.
 */
const TableFor = (props: {
    title: string,
    placeholderReplacementMode: PlaceholderReplacementMode,
    questions: Array<Question>,
    questionResponses: Array<QuestionResponse>,
    questionChoices: Array<QuestionChoice>,
    subjectName: string | undefined,
}) => {
    const {
        title,
        placeholderReplacementMode,
        questions,
        questionResponses,
        questionChoices,
        subjectName,
    } = props;

    return (
        <View style={{ ...Styles.table, marginBottom: '2' }}>
            <View style={Styles.thead}>
                <View style={Styles.trHead}>
                    <View style={{ ...Styles.th, width: '5%' }}><Text>{' '}</Text></View>
                    <View style={{ ...Styles.th, width: '75%' }}>
                        <Text>
                            {title}
                        </Text>
                    </View>
                    <View style={{ ...Styles.th, width: '20%' }}>
                        <Text>{'RAG'}</Text>
                    </View>
                </View>
            </View>
            <View style={Styles.tbody}>
                {
                    questions.map(item => {
                        // Without a question we can't show anything.
                        if (!item) {
                            return null;
                        }

                        // Get the RAG response and use the right colour for it
                        const RAGchoice = questionChoices.find(it => it.questionId === item.id)?.name;
                        if (RAGchoice === undefined) { 
                            return null; //No point showing an unanswered question
                        }
                        let RAGcolor = "";
                        switch (RAGchoice?.split(":")[0]) {

                            default:
                                break;
                            case "Green":
                                RAGcolor = "#4dbd74";
                                break;
                            case "Amber":
                                RAGcolor = "#ffc107";
                                break;
                            case "Red":
                                RAGcolor = "#f86c6b";
                                break;
                        }
                        return (
                            <View key={item.id} style={Styles.tr}>
                                <View style={{ ...Styles.th, width: '5%' }}>
                                    <Text>
                                        {item.questionNumber}
                                    </Text>
                                </View>
                                <View style={{ ...Styles.th, width: '75%' }}>
                                    <Text>
                                        <PlainTextWithBrsPdf text={replacePlaceholders(item.name, placeholderReplacementMode, { subjectName: subjectName })} />
                                    </Text>
                                </View>
                                <View style={{ ...Styles.th, width: '20%' }}>
                                    {
                                        !!RAGchoice ? (
                                            <View style={{ marginTop: '4', paddingTop: '2', borderLeftWidth: '1', borderLeftColor: 'lightgray', borderLeftStyle: 'dotted', color: '#606060', textAlign: 'right' }}>
                                                <Text style={{ color: RAGcolor }}>{RAGchoice}</Text>
                                            </View>
                                        ) : null
                                    }
                                </View>
                            </View>
                        );
                    })
                }
            </View>
        </View>
    );
};

