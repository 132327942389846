import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import {
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader
} from '@coreui/react';
import { SidebarNav, SidebarNavTitle } from '../../shared/sidebarNav';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { NavLinkTrackActive } from '../../shared/NavLinkTrackActive';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

export interface SidebarProps {
    subscriptionId: string | undefined
}

export const _Sidebar = (props: SidebarProps) => {
    return (
        <>
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <Nav>
                    <SidebarNavTitle className="d-block d-sm-none">
                        Evaluate-Ed
                    </SidebarNavTitle>
                    {
                        props.subscriptionId ? (
                            <PrivateContainer role="Manage">
                                <NavItem className="d-block d-sm-none admin-nav-sidebar">
                                    <NavLinkTrackActive to="/manage" tag={Link}>
                                        Manage
                                    </NavLinkTrackActive>
                                </NavItem>
                            </PrivateContainer>
                        ) : null
                    }
                    <PrivateContainer role="Administration">
                        <NavItem className="d-block d-sm-none admin-nav-sidebar">
                            <NavLinkTrackActive to="/administration" tag={Link}>
                                Administration
                            </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>


                    <PrivateContainer role="Administration">
                        <SidebarNavTitle>
                            Subscriptions
                        </SidebarNavTitle>

                        <NavItem>
                            <NavLinkTrackActive to="/administration/subscriptions" tag={Link}>
                                <FontAwesomeIcon icon="users" className="nav-icon" />
                                Subscriptions
                            </NavLinkTrackActive>
                        </NavItem>

                        <NavItem>
                            <NavLinkTrackActive to="/administration/dashboard" tag={Link}>
                                <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                                Dashboard
                            </NavLinkTrackActive>
                        </NavItem>


                        <SidebarNavTitle>
                            Self evaluation questions
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/school-self-evaluation" tag={Link}>
                                <FontAwesomeIcon icon="question" className="nav-icon" />
                                School self evaluation
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Due diligence questions
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/school-due-diligence" tag={Link}>
                                <FontAwesomeIcon icon="ruler-combined" className="nav-icon" />
                                School due diligence
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Subject leadership review questions
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/subject-leadership-review" tag={Link}>
                                <FontAwesomeIcon icon="brain" className="nav-icon" />
                                Subject leadership review
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/subject-helpful-questions" tag={Link}>
                                <FontAwesomeIcon icon="hand-holding-heart" className="nav-icon" />
                                Subject helpful questions
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/subjects" tag={Link}>
                                <FontAwesomeIcon icon="layer-group" className="nav-icon" />
                                Subjects
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Reading review questions
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/reading-eyfs-to-ks2" tag={Link}>
                                <FontAwesomeIcon icon="book-reader" className="nav-icon" />
                                EYFS, KS1, and KS2 reading
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/reading-ks3-to-ks4" tag={Link}>
                                <FontAwesomeIcon icon="book-open" className="nav-icon" />
                                KS3 and KS4 reading
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Terms
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/schoolTerms" tag={Link}>
                                <FontAwesomeIcon icon="calendar-alt" className="nav-icon" /> School terms
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Service administration
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/awardTags" tag={Link}>
                                <FontAwesomeIcon icon="certificate" className="nav-icon" />
                                Specific reviews
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/impacts" tag={Link}>
                                <FontAwesomeIcon icon="bomb" className="nav-icon" />
                                Impacts
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/tags" tag={Link}>
                                <FontAwesomeIcon icon="tag" className="nav-icon" />
                                School tags
                            </NavLinkTrackActive>
                        </NavItem>

                        
                        <SidebarNavTitle>
                            Micro-consultations
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/microConsultations" tag={Link}>
                                <FontAwesomeIcon icon="video" className="nav-icon" />
                                Browse library
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/videos" tag={Link}>
                                <FontAwesomeIcon icon="file-video" className="nav-icon" />
                                Videos
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/consultants" tag={Link}>
                                <FontAwesomeIcon icon="address-card" className="nav-icon" />
                                Consultants
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/videoTags" tag={Link}>
                                <FontAwesomeIcon icon="tag" className="nav-icon" />
                                Video tags
                            </NavLinkTrackActive>
                        </NavItem>

                        <NavItem>
                            <NavLinkTrackActive to="/administration/consultantReferrals" tag={Link}>
                                <FontAwesomeIcon icon="reply-all" className="nav-icon" />
                                Consultant referrals
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/videoViews" tag={Link}>
                                <FontAwesomeIcon icon="play" className="nav-icon" />
                                Video views
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Certificate administration
                         </SidebarNavTitle>

                        <NavItem>
                            <NavLinkTrackActive to="/administration/pendingCertificates" tag={Link}>
                                <FontAwesomeIcon icon="paper-plane" className="nav-icon" />
                                Pending certificates
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/awardedCertificates" tag={Link}>
                                <FontAwesomeIcon icon="certificate" className="nav-icon" />
                                Awarded certificates
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/failedCertificates" tag={Link}>
                                <FontAwesomeIcon icon="frown" className="nav-icon" />
                                Failed certificates
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Page administration
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/pages" tag={Link}>
                                <FontAwesomeIcon icon="code" className="nav-icon" />
                                Pages
                            </NavLinkTrackActive>
                        </NavItem>



                        <SidebarNavTitle>
                            User administration
                         </SidebarNavTitle>

                        <NavItem>
                            <NavLinkTrackActive to="/administration/users" tag={Link}>
                                <FontAwesomeIcon icon="users" className="nav-icon" />
                                Admin users
                                </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/subscriptionUsers" tag={Link}>
                                <FontAwesomeIcon icon="portrait" className="nav-icon" />
                                Subscription users
                            </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>
                </Nav>
            </SidebarNav>

            <AppSidebarFooter />
            {/*<AppSidebarMinimizer />*/}
        </>
    );
};

export const Sidebar = connect(
    (state: any) => ({
        subscriptionId: state.user.identity ? state.user.identity.subscriptionId != null ? state.user.identity.subscriptionId : undefined : undefined
    }),
    (dispatch: React.Dispatch<AnyAction>) => ({}),
    null,
    {
        pure: false
    }
)(_Sidebar);