import * as React from 'react';
import { Container } from 'reactstrap';
import { LoadingIndicator } from '../../shared/LoadingIndicator';

export interface LayoutProps {
    appRoutes: Array<any>,
    children: any
}

export const Layout = (props: LayoutProps) => {
    return (
        <div className="app print-layout">
            <main className="main">
                <Container fluid>
                    <React.Suspense fallback={<LoadingIndicator />}>
                        {props.children}
                    </React.Suspense>
                </Container>
            </main>
        </div>
    );
};