import * as React from 'react';
import { Table, Button, Spinner, ButtonGroup } from 'reactstrap';
import { isNullOrUndefined } from 'util';
import { Link } from 'react-router-dom';
import { dashboardDrilldownUrl } from '../../../../utilities/dashboardDrilldownUrl';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Project } from '../../../../api/models/Project';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useManagedChildModels, ManagedChildModels } from '../../../shared/hooks/useManagedChildModels';
import { ProjectSection } from '../../../../api/models/ProjectSection';
import { Section } from '../../../../api/models/Section';
import { useAsyncCallback } from 'react-use-async-callback';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getGradeString } from '../../../../utilities/getGradeString';
import { getGradeComparisonColor } from '../../../../utilities/getGradeComparisonColor';
import { AwardTag } from '../../../../api/models/AwardTag';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../../../utilities/replacePlaceholders';

export interface TrustComparisonTableProps {
    model: Project | undefined,
    results: Array<ProjectAnalysisResult>,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    sections?: Array<Section>,
    projectSections?: ManagedChildModels<ProjectSection>,
    isForSingleSchool: boolean,
    awardTag: AwardTag | undefined,
    placeholderReplacementMode: PlaceholderReplacementMode,
}

export const TrustComparisonTable = (props: TrustComparisonTableProps) => {
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTag && props.awardTag.id || undefined);

    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index as well.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    // Can navigate deeper from all levels except a question, which can only be navigated to if we're within a project for an individual school, not from the trust level.
    const canNavigateDeeper = !props.filterByTopicOriginKey || (props.model && !Guid.isEmpty(props.model.schoolId) || false);

    // Save the project sections if we need to.
    const [needToSaveProjectSection, setNeedToSaveProjectSection] = React.useState<string>('');
    const [saveProjectSectionPriority, isSavingProjectSectionPriority] = useAsyncCallback(async () => {
        if (!props.projectSections) {
            return;
        }

        await props.projectSections.save();
    }, [props.projectSections]);

    // Queue the saving of a project section.
    const updatePriority = React.useCallback((projectSectionId: string, isPriority: boolean) => {
        if (!props.projectSections) {
            return;
        }

        // Update the managed child model.
        props.projectSections.change(projectSectionId, { improvementTarget: isPriority ? 100 : 0 });

        // Queue the save to take place when we next hit the event loop.
        setNeedToSaveProjectSection(projectSectionId);
    }, [props.projectSections]);

    // If we need to save the project sections, do so now.
    React.useEffect(() => {
        if (needToSaveProjectSection) {
            setNeedToSaveProjectSection('');
            saveProjectSectionPriority();
        }
    }, [needToSaveProjectSection, saveProjectSectionPriority, setNeedToSaveProjectSection]);


    if (!props.results || props.results.length < 2) {
        return (<></>);
    }

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.results[0];
    let trustAverages = props.results[1];

    return (
        <div className="table-responsive">
            <Table striped className="table-sm">
                <thead>
                    <tr>
                        {
                            isForQuestions ? (
                                <th><span></span></th>
                                ): null
                        }
                        <th><span></span></th>
                        <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                            <ConditionalFragment showIf={!!props.model && !!props.model.schoolId && !props.filterBySectionOriginKey && !props.filterByTopicOriginKey && !!props.projectSections}>
                                {/* this is for the priority buttons on sections at the top level */}
                                <th></th>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!props.model && Guid.isEmpty(props.model.schoolId) /* Is a trust */}>
                            <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                            <th className="text-right"><span>
                                {
                                    thisProject.school ? thisProject.school.name
                                        : props.model && Guid.isEmpty(props.model.schoolId)? 'Trust targets'
                                        : thisProject.trust ? thisProject.trust.name
                                            : 'Project'}
                                </span></th>
                            </ConditionalFragment>

                            <th className="text-right"><span>
                                {
                                    props.model && Guid.isEmpty(props.model.schoolId) ? 'School averages' // Special case when showing a project for the trust itself
                                        : props.isForSingleSchool? 'School targets'
                                        : trustAverages.trust ? 'Trust targets'
                                            : 'Trust targets'
                                }
                            </span></th>


                            <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                <th className="text-right"><span>+/-</span></th>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!props.model || !Guid.isEmpty(props.model.schoolId) /* Is not a trust */}>
                            <th className="text-right"><span>
                                {
                                    thisProject.school ? thisProject.school.name
                                        : props.model && Guid.isEmpty(props.model.schoolId) ? 'Trust targets'
                                            : thisProject.trust ? thisProject.trust.name
                                                : 'Project'}
                            </span></th>
                            <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                <th className="text-right"><span>
                                    {
                                        props.model && Guid.isEmpty(props.model.schoolId) ? 'School averages' // Special case when showing a project for the trust itself
                                            : props.isForSingleSchool ? 'School targets'
                                                : trustAverages.trust ? 'Trust targets'
                                                    : 'Trust targets'
                                    }
                                </span></th>
                                <th className="text-right"><span>+/-</span></th>
                            </ConditionalFragment>
                        </ConditionalFragment>
                    </tr>
                </thead>
                <tbody>
                    {
                        thisProject.results.map((item) => {
                            let projectSection: ProjectSection | undefined = undefined;
                            if (props.sections && props.projectSections) {
                                let section = props.sections.find(it => it.originKey === item.originKey);
                                projectSection = !!section ? props.projectSections.models.find(it => !!section && it.sectionId === section.id) : undefined;
                            }
                            
                            let matchingRecord = trustAverages.results.find(it => it.originKey === item.originKey);
                            let trustScore = matchingRecord ? matchingRecord.percentageQuestionScore : null;
                            let difference = isNullOrUndefined(trustScore) ? null : (item.percentageQuestionScore - trustScore);
                            let differenceColor = getGradeComparisonColor(item.percentageQuestionScore, trustScore);

                            // Special case for when we are display for a trust - in this case we flip the meaning of difference around.
                            if (props.model && Guid.isEmpty(props.model.schoolId) && difference) {
                                difference = 0 - difference;
                                differenceColor = getGradeComparisonColor(trustScore, item.percentageQuestionScore);
                            }

                            return (
                                <tr key={item.originKey}>
                                    {
                                        isForQuestions ? (
                                            <td><span>{item.questionNumber}</span></td>
                                        ) : null
                                    }
                                    <td><span>
                                        {
                                            canNavigateDeeper ?  (
                                                <Link to={dashboardDrilldownUrl(projectSettings, props.filterBySectionOriginKey, props.filterByTopicOriginKey, item.originKey)}>
                                                    {replacePlaceholders(item.name, props.placeholderReplacementMode)}
                                                    </Link>
                                                    ) : (
                                                    <>{replacePlaceholders(item.name, props.placeholderReplacementMode)}</>
                                            )
                                        }
                                    </span></td>
                                    <ConditionalFragment showIf={!!props.model && !!props.model.schoolId && !props.filterBySectionOriginKey && !props.filterByTopicOriginKey && !!props.projectSections}>
                                        <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                            <ConditionalFragment showIf={!!projectSection && projectSection.improvementTarget === 0}>
                                                <td>
                                                    <ButtonAsync size="sm" color="primary" outline
                                                        isExecuting={isSavingProjectSectionPriority && !!projectSection && needToSaveProjectSection === projectSection.id}
                                                        executingChildren={<><Spinner size="sm" /><> </>Prioritising...</>}
                                                        onClick={() => { if (!projectSection) { return; } updatePriority(projectSection.id, true); }}
                                                        >
                                                        Prioritise
                                                    </ButtonAsync>
                                                </td>
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={!!projectSection && projectSection.improvementTarget > 0}>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button size="sm" color="primary" className="text-center">
                                                            Priority 
                                                        </Button>
                                                        <ButtonAsync size="sm" color="primary" outline
                                                            isExecuting={isSavingProjectSectionPriority && !!projectSection && needToSaveProjectSection === projectSection.id}
                                                            onClick={() => { if (!projectSection) { return; } updatePriority(projectSection.id, false); }}
                                                            >
                                                            <FontAwesomeIcon icon="times" />
                                                            <> </>
                                                        </ButtonAsync>
                                                    </ButtonGroup>

                                                </td>
                                            </ConditionalFragment>
                                        </ConditionalFragment>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!!props.model && Guid.isEmpty(props.model.schoolId) /* Is a trust */}>
                                        <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                            <td className="text-right"><span>
                                                <strong>{getGradeString(item.percentageQuestionScore)}</strong>
                                            </span></td>
                                        </ConditionalFragment>

                                        <td className="text-right"><span>
                                            {/*{isNullOrUndefined(trustScore) ? '' : matchingRecord && matchingRecord.questionScore === 0 ? 'N/A' : getGradeString(trustScore)}</span>*/}
                                            {isNullOrUndefined(trustScore) ? '' : getGradeString(trustScore)}</span>
                                        </td>

                                        <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                            <td className="text-right"><span className={isNullOrUndefined(difference) ? '' : `text-${differenceColor}`}>
                                                <>{isNullOrUndefined(difference) || difference < 0 ? '' : '+'}{isNullOrUndefined(difference) ? '' : (difference / 10.0).toFixed(1)}</>
                                            </span></td>
                                        </ConditionalFragment>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!props.model || !Guid.isEmpty(props.model.schoolId) /* Is not a trust */}>
                                        <td className="text-right"><span>
                                            <strong>{getGradeString(item.percentageQuestionScore)}</strong>
                                        </span></td>
                                        <ConditionalFragment showIf={!props.awardTag || !props.awardTag.hideTargets}>
                                            <td className="text-right"><span>
                                                {/*{isNullOrUndefined(trustScore) ? '' : matchingRecord && matchingRecord.questionScore === 0 ? 'N/A' : getGradeString(trustScore)}</span>*/}
                                                {isNullOrUndefined(trustScore) ? '' : getGradeString(trustScore)}</span>
                                            </td>
                                            <td className="text-right"><span className={isNullOrUndefined(difference) ? '' : `text-${differenceColor}`}>
                                                <>{isNullOrUndefined(difference) || difference < 0 ? '' : '+'}{isNullOrUndefined(difference) ? '' : (difference / 10.0).toFixed(1)}</>
                                            </span></td>
                                        </ConditionalFragment>
                                    </ConditionalFragment>
                                </tr>
                            );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

