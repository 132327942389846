import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { Project } from "../../api/models/Project";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { withServiceProps } from "inject-typesafe-react";
import { useManagedChildModels, ManagedChildModels } from "../shared/hooks/useManagedChildModels";
import { IdAndName } from "../../api/models/IdAndName";
import { ProjectReviewer } from "../../api/models/ProjectReviewer";
import { User } from "../../api/models/User";
import { ValidationState } from "pojo-validator";
import { Guid } from "guid-string";
import { Repository } from "pojo-repository";
import { AuthenticationService } from "../../services/AuthenticationService";
import { ProjectReviewerRestriction } from "../../api/models/ProjectReviewerRestriction";
import { Subscription } from "../../api/models/Subscription";


export interface ReviewersContainerProps extends ContainerComponentProps<ReviewersUiProps> {
    /* From dependency injection */
    loadModel: (id: string) => Promise<any>,
    projectReviewersRepository: Repository<ProjectReviewer>,
    usersRepository: Repository<User>,
    authenticationService: AuthenticationService,
    projectReviewerRestrictionRepository: Repository<ProjectReviewerRestriction>,
    sendQuestionEmail: (userId: string, projectId: string) => Promise<boolean>
}

export interface ReviewersUiProps {
    model: Project | undefined,

    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any,

    save: () => Promise<boolean>,
    isSaving: boolean,
    savingErrors: any,

    sendQuestionEmail: (userId: string) => Promise<boolean>,

    visibilityGroups: Array<IdAndName>,
    projectReviewers: ManagedChildModels<ProjectReviewer>,
    projectReviewersRepository: Repository<ProjectReviewer>,
    users: ManagedChildModels<User>,
    restrictions: ManagedChildModels<ProjectReviewerRestriction>,

    isSingleSchoolSubscription: boolean,

    newReviewerRoleGroup: string | undefined,
}

/**
 * Container for the reviewers screen of a Project.
 * 
 * @param props
 */
export const _ReviewersContainer = (props: ReviewersContainerProps) => {
    const { component, loadModel, projectReviewersRepository, usersRepository, authenticationService, projectReviewerRestrictionRepository, sendQuestionEmail, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('projectId', '');

    const [subscription, setSubscription] = React.useState<Subscription>();
    const [newReviewerRoleGroup, setNewReviewerRoleGroup] = React.useState<string | undefined>();

    const [model, setModel] = React.useState<Project | undefined>(undefined);
    const [visibilityGroups, setVisibilityGroups] = React.useState<Array<IdAndName>>([]);

    const changeModel = React.useCallback((changes: Partial<Project>) => {
        setModel(prevState => ({
            ...(prevState as Project),
            ...changes
        }));
    }, [setModel]);

    const projectReviewers = useManagedChildModels<ProjectReviewer>(projectReviewersRepository, (model: ProjectReviewer, validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('userId')) {
            validation.singleCheck('userId', () => Guid.isEmpty(model.userId), 'User is required');
        }
    });

    const users = useManagedChildModels<User>(usersRepository, (model: User, validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('forename', () => !model.forename, 'Forename is required');
        }
        if (!fieldsToCheck || fieldsToCheck.includes('surname')) {
            validation.singleCheck('surname', () => !model.surname, 'Surname is required');
        }
        if (!fieldsToCheck || fieldsToCheck.includes('email')) {
            validation.singleCheck('email', () => !model.email, 'Email is required');
        }
    });

    const restrictions = useManagedChildModels(projectReviewerRestrictionRepository);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadModel(id);
        setVisibilityGroups(result.visibilityGroups);
        projectReviewers.setModels(result.projectReviewers);
        users.setModels(result.users);
        restrictions.setModels(result.projectReviewerRestrictions);
        setModel(result.model);
        setSubscription(result.subscription);
        setNewReviewerRoleGroup(result.newReviewerRoleGroup);
        return true;
    }, [id, loadModel, setModel, users, restrictions, setSubscription]);

    // Send an invite to a user.
    const sendInviteToUser = React.useCallback(async (user: string) => {
        await authenticationService.sendInvite(user);
    }, [authenticationService]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        // Save the users.
        var newUsers = users.models.filter(item => users.isAddedModel(item.id));
        let ok = await users.save();
        if (!ok) {
            return false;
        }

        // Send invites to all the new users.
        for (let user of newUsers) {
            await sendInviteToUser(user.email);
        }

        // Save the project reviewers.
        await projectReviewers.save();
        
        // Save the restrictions.
        ok = await restrictions.save();
        if (!ok) {
            return false;
        }

        return true;
    }, [model, users, projectReviewers, sendInviteToUser, restrictions]);

    const sendQuestionEmailAction = React.useCallback(async (userId: string): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!sendQuestionEmail) {
            return false;
        }
        let result = await sendQuestionEmail(userId, model.id);
        return result;
    }, [model, sendQuestionEmail]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id !== model.id)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    // When we leave the screen, save any changes,.
    //React.useEffect(() => {
    //    // Do nothing for init.
    //    return () => {
    //        projectReviewers.save();
    //    };
    //}, [projectReviewers]);

    const Component = component;
    return (
        <Component {...rest} model={model}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            visibilityGroups={visibilityGroups}
            projectReviewers={projectReviewers}
            projectReviewersRepository={projectReviewersRepository}
            users={users}
            restrictions={restrictions}
            sendQuestionEmail={sendQuestionEmailAction}
            isSingleSchoolSubscription={!!subscription ? subscription.isForSingleSchool : false}
            newReviewerRoleGroup={newReviewerRoleGroup}
        />
    );
};

export const ReviewersContainer = withServiceProps<ReviewersContainerProps, AppServicesCore>(services => ({
    loadModel: services.api.projects.viewModels.reviewers(),
    projectReviewersRepository: services.api.projectReviewers.repository(),
    usersRepository: services.api.users.repository(),
    authenticationService: services.authenticationService(),
    projectReviewerRestrictionRepository: services.api.projectReviewerRestrictions.repository(),
    sendQuestionEmail: services.api.projectReviewers.actions.sendEmail()
}))(_ReviewersContainer);

