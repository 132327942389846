import * as React from 'react';
import { Text } from '@react-pdf/renderer';

export interface PlainTextWithBrsPdfProps {
    text: string
}

/**
 * Component that renders the plain text provided, split on newlines with <br /> inbetween, but without treating any input text as html, which could potentially be unsafe.
 * @param props
 */
export const PlainTextWithBrsPdf = (props: PlainTextWithBrsPdfProps) => {
    const lines = React.useMemo((): Array<string> => {
        if (!props.text) {
            return [];
        }

        return props.text.split('\n');
    }, [props.text]);

    return (
        <>
            {
                lines.map((item, index) => {
                    return (<Text key={index}>{item}</Text>)
                })
            }
        </>
    );
};
