import * as React from 'react';
import { Badge, Button, ButtonToggle, CardHeader, CardLink, Col, Collapse, Nav, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, Row, Tag } from 'reactstrap';
import {
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader
} from '@coreui/react';
import { SidebarNav, SidebarNavDropdown, SidebarNavTitle } from '../../shared/sidebarNav';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { NavLinkTrackActive } from '../../shared/NavLinkTrackActive';
import { useAsyncCallback } from 'react-use-async-callback';
import { School } from '../../../api/models/School';
import { useApiService } from '../../../api/useApiService';
import { Project } from '../../../api/models/Project';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Subscription } from '../../../api/models/Subscription';
import { AwardTag } from '../../../api/models/AwardTag';
import { User } from '../../../api/models/User';
import { ProductTier, isProductTierUnlocked } from '../../../api/models/codeOnly/ProductTeir';
import { SubscriptionLicense } from '../../../api/models/SubscriptionLicense';
import { Subject } from '../../../api/models/Subject';
import { NavLink } from 'reactstrap';
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';
import { PaginationLink } from 'reactstrap';
import TagManager from 'react-gtm-module';

export interface SidebarProps {
    model: School | undefined | null,
}

export const Sidebar = (props: SidebarProps) => {
    const { model } = props;

    const api = useApiService();
    const currentProjectFor = api.projects.viewModels.currentProjectFor();
    const getSubscriptionAndLicenseFromSchool = api.subscriptions.viewModels.getSubscriptionAndLicenseFromSchool();
    const loadViewModel = api.schools.viewModels.sidebar();

    // Current projects for the trust (shown in the menu)
    const [selfEvaluationProject, setSelfEvaluationProject] = React.useState<Project | null | undefined>();
    const [dueDiligenceProject, setDueDiligenceProject] = React.useState<Project | null | undefined>();

    // need the subscription for single school check
    const [subscription, setSubscription] = React.useState<Subscription | null | undefined>();
    const [license, setLicense] = React.useState<SubscriptionLicense | null | undefined>();

    // use this for single school trusts - we need it for targets
    const [trustDueDiligenceProject, setTrustDueDiligenceProject] = React.useState<Project | null | undefined>();
    const [trustSelfEvaluationProject, setTrustSelfEvaluationProject] = React.useState<Project | null | undefined>();

    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);
    const [user, setUser] = React.useState<User | undefined>();

    const [readingReviews, setReadingReviews] = React.useState<{ eyfsToKS2: boolean, ks3ToKS4: boolean }>({ eyfsToKS2: false, ks3ToKS4: false, });

    const [allSubjects, setAllSubjects] = React.useState<Array<Subject>>([]);
    const [showAllSubjects, setShowAllSubjects] = React.useState<boolean>(false);
    // A list of subjects that have projects for the current school
    const [projectSubjects, setProjectSubjects] = React.useState<Array<Subject>>([]);

    // Toggleable groups
    const [dueDiligenceIsOpen, setDueDiligencekIsOpen] = React.useState<boolean>(false);
    const toggleDueDiligence = React.useCallback(() => setDueDiligencekIsOpen(prevState => !prevState), [setDueDiligencekIsOpen]);

    const [loadProjects, { isExecuting: isLoading }] = useAsyncCallback(async () => {
        // Load the view models.
        const result = await loadViewModel(model && model.id || '');
        setUser(result.user);
        setAwardTags(result.awardTags);
        setAllSubjects(result.subjects);
        setReadingReviews(result.readingReviews);
        setProjectSubjects(result.projectSubjects);

        // Get the subscription.
        let thisSubscriptionResults = await getSubscriptionAndLicenseFromSchool(model && model.id || '');
        setSubscription(thisSubscriptionResults.subscription);
        setLicense(thisSubscriptionResults.license);

        // Due diligence.
        let dueDiligence = await currentProjectFor({ schoolId: model && model.id || '', trustId: model && model.trustId || '', questionSetName: 'School due diligence', createIfMissing: (model && model.isPotentialSchool)? true: false });
        setDueDiligenceProject(dueDiligence);

        // Trust level projects (used for single school subscription's target settings)
        const thisSubscription = thisSubscriptionResults.subscription;
        if (!!thisSubscription && thisSubscription.isForSingleSchool) {
            let trustDueDiligence = await currentProjectFor({ trustId: model && model.trustId || '', questionSetName: 'School due diligence' });
            setTrustDueDiligenceProject(trustDueDiligence);
        }

        if (model && !model.isPotentialSchool) {
            // Self evaluation
            let selfEvalulation = await currentProjectFor({ schoolId: model && model.id || '', trustId: model && model.trustId || '', questionSetName: 'School self evaluation' });
            setSelfEvaluationProject(selfEvalulation);

            if (!!thisSubscription && thisSubscription.isForSingleSchool) {
                let trustSelfEvalulation = await currentProjectFor({ trustId: model && model.trustId || '', questionSetName: 'School self evaluation' });
                setTrustSelfEvaluationProject(trustSelfEvalulation);
            }
        }
    }, [model, currentProjectFor, setSelfEvaluationProject, setDueDiligenceProject, subscription, setSubscription,
        getSubscriptionAndLicenseFromSchool, setAwardTags, setUser, loadViewModel, setLicense,
        setAllSubjects, setReadingReviews,
    ]);

    const [loadedForSchoolId, setLoadedForSchoolId] = React.useState<string>();
    React.useEffect(() => {
        if (!model || !model.id || isLoading || (model && model.id === loadedForSchoolId)) {
            return;
        }

        setLoadedForSchoolId(model.id);
        loadProjects();
    }, [model, loadedForSchoolId, setLoadedForSchoolId, loadProjects]);

    const currentTeir = model && model.tier as ProductTier || ProductTier.Snapshot;
    const isTrial = !!license && license.isTrial;

    return (
        <>
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <Nav>
                    <SidebarNavTitle className="d-block d-sm-none">
                        Evaluate-Ed
                    </SidebarNavTitle>
                    <PrivateContainer role="Manage">
                        <NavItem className="d-block d-sm-none  admin-nav-sidebar">
                            <NavLinkTrackActive to="/manage" tag={Link}>
                                Manage
                                </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>
                    <PrivateContainer role="Administration">
                        <NavItem className="d-block d-sm-none admin-nav-sidebar">
                            <NavLinkTrackActive to="/administration" tag={Link}>
                                Administration
                                </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>

                    <PrivateContainer role="School User">
                        <NavItem>
                            <NavLinkTrackActive to={`/school/${model && model.id || ''}/home`} tag={Link}>
                                <FontAwesomeIcon icon="home" className="nav-icon" />
                                Home
                                </NavLinkTrackActive>
                        </NavItem>

                        <ConditionalFragment showIf={!!user && !user.isLimitedToAwardTags}>
                            <NavItem>
                                <NavLinkTrackActive to={`/school/${model && model.id || ''}/schoolDetails`} tag={Link}>
                                    <FontAwesomeIcon icon="school" className="nav-icon" />
                                    School details
                                </NavLinkTrackActive>
                            </NavItem>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature && !!user && !user.isLimitedToAwardTags}>
                            <ConditionalFragment showIf={!!selfEvaluationProject && !(model && model.isPotentialSchool)}>
                                <SidebarNavTitle>
                                    Reporting
                                </SidebarNavTitle>
                                <NavItem className={`${!isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/reports`} tag={Link}>
                                        <FontAwesomeIcon icon="passport" className="nav-icon" />
                                        Reports manager

                                        <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                            <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                        </ConditionalFragment>
                                    </NavLinkTrackActive>
                                </NavItem>
                                <NavItem className={`${!isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/selfEvaluationDashboard/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                        <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                                        Self evaluation dashboard

                                        <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                            <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                        </ConditionalFragment>
                                    </NavLinkTrackActive>
                                </NavItem>
                            </ConditionalFragment>


                            <ConditionalFragment showIf={(!!model && model.isPotentialSchool) || !!dueDiligenceProject}>
                                <SidebarNavTitle>
                                    {
                                        model && model.isPotentialSchool ? (
                                            <>Due diligence</>
                                        ) : (
                                            <div style={{ cursor: 'pointer' }} onClick={() => toggleDueDiligence()}>
                                                <Row>
                                                    <Col>
                                                        Due diligence
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Badge color="warning">
                                                            Before joining trust
                                                            <> </>
                                                            <FontAwesomeIcon icon={dueDiligenceIsOpen ? 'caret-down' : 'caret-right'} />
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }
                                </SidebarNavTitle>
                                <Collapse isOpen={(model && model.isPotentialSchool /* Always open for potential schools */) || dueDiligenceIsOpen}>
                                    {
                                        dueDiligenceProject ? (
                                            <>
                                                <ConditionalFragment showIf={!!selfEvaluationProject}>
                                                    <NavItem>
                                                        <NavLinkTrackActive to={`/school/${model && model.id || ''}/dueDiligence/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                                            <FontAwesomeIcon icon="edit" className="nav-icon" />
                                                            Due diligence home
                                                        </NavLinkTrackActive>
                                                    </NavItem>
                                                </ConditionalFragment>
                                                <NavItem>
                                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/dueDiligenceDashboard/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                                        <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                                                        Due diligence dashboard
                                                    </NavLinkTrackActive>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/dueDiligenceExecutiveSummary/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                                        <FontAwesomeIcon icon="quote-right" className="nav-icon" />
                                                        Executive summary
                                                    </NavLinkTrackActive>
                                                </NavItem>
                                                <ConditionalFragment showIf={!!subscription && subscription.isForSingleSchool}>
                                                    <NavItem>
                                                        <NavLinkTrackActive to={`/school/${model && model.id || ''}/dueDiligenceExpectations/${trustDueDiligenceProject && trustDueDiligenceProject.id || ''}/${subscription && subscription.isForSingleSchool ? 'singleschool' : ''}`} tag={Link}>
                                                            <FontAwesomeIcon icon="edit" className="nav-icon" />
                                                            Due diligence expectations
                                                        </NavLinkTrackActive>
                                                    </NavItem>
                                                </ConditionalFragment>
                                                <NavItem>
                                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/dueDiligenceRiskReport/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                                        <FontAwesomeIcon icon="exclamation-circle" className="nav-icon" />
                                                        Risks
                                                    </NavLinkTrackActive>
                                                </NavItem>
                                            </>
                                        ) : (
                                            <NavItem style={{ width: '100%', textAlign: 'center' }}>
                                                <FontAwesomeIcon icon="spinner" spin />
                                            </NavItem>
                                        )
                                    }
                                </Collapse>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && (subscription.selfEvaluatationFeature || subscription.specificReviewsFeature) && !!user && !user.isLimitedToAwardTags}>
                            <SidebarNavTitle>
                                Improvement planning
                            </SidebarNavTitle>
                            <NavItem className={`${!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                <NavLinkTrackActive to={`/school/${model && model.id || ''}/improvementPlanner`} tag={Link}>
                                    <FontAwesomeIcon icon="calendar-plus" className="nav-icon" />
                                    Improvement planner

                                    <ConditionalFragment showIf={!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                        <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                    </ConditionalFragment>
                                </NavLinkTrackActive>
                            </NavItem>
                            <ConditionalFragment showIf={!!model && !model.isPotentialSchool}>
                                <NavItem className={`${!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/strengthReport/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                        <FontAwesomeIcon icon="heartbeat" className="nav-icon" />
                                        Areas of strength

                                        <ConditionalFragment showIf={!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                            <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                        </ConditionalFragment>
                                    </NavLinkTrackActive>
                                </NavItem>
                                <NavItem className={`${!isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/recommendationReport/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                        <FontAwesomeIcon icon="thumbs-up" className="nav-icon" />
                                        Areas for development

                                        <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                            <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                        </ConditionalFragment>
                                    </NavLinkTrackActive>
                                </NavItem>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!!model && model.isPotentialSchool}>
                                <NavItem>
                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/strengthReport/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                        <FontAwesomeIcon icon="heartbeat" className="nav-icon" />
                                        Areas of strength
                                    </NavLinkTrackActive>
                                </NavItem>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature && !!user && !user.isLimitedToAwardTags}>
                            <ConditionalFragment showIf={!!selfEvaluationProject && !(model && model.isPotentialSchool) && !!subscription && subscription.isForSingleSchool}>
                                <NavItem className={`${!isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                    <NavLinkTrackActive to={`/school/${model && model.id}/selfEvaluationTargets/${trustSelfEvaluationProject && trustSelfEvaluationProject.id || ''}/${subscription && subscription.isForSingleSchool ? 'singleschool' : ''}`} tag={Link}>
                                        <FontAwesomeIcon icon="edit" className="nav-icon" />
                                        School targets

                                        <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                            <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                        </ConditionalFragment>
                                    </NavLinkTrackActive>
                                </NavItem>
                            </ConditionalFragment>

                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && subscription.specificReviewsFeature}>
                            <ConditionalFragment showIf={!!model && !model.isPotentialSchool && !!selfEvaluationProject}>
                                <ConditionalFragment showIf={!!awardTags.length}>
                                    <SidebarNavTitle>
                                        Specific reviews
                                    </SidebarNavTitle>

                                    {
                                        awardTags.map(item => {
                                            return (
                                                <NavItem key={item.id} className={isTrial ? 'sidebar-locked-nav-item' : ''}>
                                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/awardHome/${item.id}/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                                        <FontAwesomeIcon icon="certificate" className="nav-icon" />
                                                        {item.name}

                                                        <ConditionalFragment showIf={isTrial}>
                                                            <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                                        </ConditionalFragment>
                                                    </NavLinkTrackActive>
                                                </NavItem>
                                            );
                                        })
                                    }
                                </ConditionalFragment>
                            </ConditionalFragment>
                        </ConditionalFragment>                        

                        <ConditionalFragment showIf={!!subscription && subscription.subjectReviewsFeature}>
                            <ConditionalFragment showIf={!!model && !model.isPotentialSchool}>
                                <ConditionalFragment showIf={!!projectSubjects}>
                                    <SidebarNavTitle>
                                        Subject leadership reviews
                                    </SidebarNavTitle>

                                    <ConditionalFragment showIf={!!projectSubjects && !showAllSubjects}>
                                        {
                                            projectSubjects.map(item => {
                                                if (user?.isLimitedToAwardTags && user.subjectId !== item.id
                                                    && user.subject2Id !== item.id && user.subject3Id !== item.id
                                                    && user.subject4Id !== item.id && user.subject5Id !== item.id
                                                    && user.subject6Id !== item.id && user.subject7Id !== item.id
                                                    && user.subject8Id !== item.id && user.subject9Id !== item.id
                                                    && user.subject10Id !== item.id)
                                                {
                                                    //return nothing
                                                }
                                                else {
                                                    return (
                                                        <NavItem key={item.id} className={isTrial ? 'sidebar-locked-nav-item' : ''}>
                                                            <NavLinkTrackActive to={`/school/${model && model.id || ''}/subjectHome/${item.id}`} tag={Link}>
                                                                <FontAwesomeIcon icon="brain" className="nav-icon" />
                                                                {item.name}

                                                                <ConditionalFragment showIf={isTrial}>
                                                                    <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                                                </ConditionalFragment>
                                                            </NavLinkTrackActive>
                                                        </NavItem>
                                                    );
                                                }
                                            })
                                        }
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!!allSubjects && !user?.isLimitedToAwardTags}>
                                        <NavItem className={isTrial ? 'sidebar-locked-nav-item' : ''} >
                                            <NavLink onClick={e => setShowAllSubjects(prevState => !prevState)} style={{ cursor: "pointer" }} tag={NavbarText}>
                                                <FontAwesomeIcon icon={showAllSubjects? 'minus': 'plus'} className="nav-icon" />
                                                {
                                                    showAllSubjects? 'Show active subjects'
                                                        : 'Show all subjects'
                                                }
                                            </NavLink>

                                            <ConditionalFragment showIf={isTrial}>
                                                <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                            </ConditionalFragment>
                                        </NavItem>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={!!allSubjects && showAllSubjects}>
                                        {
                                            allSubjects.map(item => {
                                                const isActive = !!projectSubjects.find(it => it.id === item.id);

                                                return (
                                                    <NavItem key={item.id} className={isTrial ? 'sidebar-locked-nav-item' : ''}>
                                                        <NavLinkTrackActive to={`/school/${model && model.id || ''}/subjectHome/${item.id}`} tag={Link} onClick={() => {
                                                            // Add ourselves to the active subjects if are not alread in there so that if the user collapses the subjects again we remain available.
                                                            if (isActive) {
                                                                return;
                                                            }

                                                            projectSubjects.push(item);
                                                        }}>
                                                            <FontAwesomeIcon icon="brain" className="nav-icon" />
                                                            <span style={{ fontWeight: isActive ? 'bold' : '' }}>
                                                                {item.name}
                                                            </span>
                                                            
                                                            <ConditionalFragment showIf={isTrial}>
                                                                <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                                            </ConditionalFragment>
                                                        </NavLinkTrackActive>
                                                    </NavItem>
                                                );
                                            })
                                        }
                                    </ConditionalFragment>
                                </ConditionalFragment>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!model && !model.isPotentialSchool}>
                                <ConditionalFragment showIf={readingReviews.eyfsToKS2 || readingReviews.ks3ToKS4}>
                                    <SidebarNavTitle>
                                        Reading reviews
                                    </SidebarNavTitle>

                                    <ConditionalFragment showIf={readingReviews.eyfsToKS2}>
                                        <NavItem className={isTrial ? 'sidebar-locked-nav-item' : ''}>
                                            <NavLinkTrackActive to={`/school/${model && model.id || ''}/readingReviewEYFSToKS2Home`} tag={Link}>
                                                <FontAwesomeIcon icon="book-reader" className="nav-icon" />
                                                EYFS, KS1, and KS2 reading

                                                <ConditionalFragment showIf={isTrial}>
                                                    <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                                </ConditionalFragment>
                                            </NavLinkTrackActive>
                                        </NavItem>
                                    </ConditionalFragment>

                                    <ConditionalFragment showIf={readingReviews.ks3ToKS4}>
                                        <NavItem className={isTrial ? 'sidebar-locked-nav-item' : ''}>
                                            <NavLinkTrackActive to={`/school/${model && model.id || ''}/readingReviewKS3ToKS4Home`} tag={Link}>
                                                <FontAwesomeIcon icon="book-open" className="nav-icon" />
                                                KS3 and KS4 reading

                                                <ConditionalFragment showIf={isTrial}>
                                                    <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                                </ConditionalFragment>
                                            </NavLinkTrackActive>
                                        </NavItem>
                                    </ConditionalFragment>
                                </ConditionalFragment>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && (subscription.selfEvaluatationFeature || subscription.specificReviewsFeature) && !!user && !user.isLimitedToAwardTags}>
                            <SidebarNavTitle>
                                Evidence and documents
                            </SidebarNavTitle>
                            <NavItem className={`${!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                <NavLinkTrackActive to={`/school/${model && model.id || ''}/evidenceLocker`} tag={Link}>
                                    <FontAwesomeIcon icon="cloud-upload-alt" className="nav-icon" />
                                    Evidence locker

                                    <ConditionalFragment showIf={!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                        <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                    </ConditionalFragment>
                                </NavLinkTrackActive>
                            </NavItem>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature && !!user && !user.isLimitedToAwardTags}>
                            <SidebarNavTitle>
                                Micro-consultations
                            </SidebarNavTitle>
                            <NavItem className={`${!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir) ? 'sidebar-locked-nav-item' : ''}`}>
                                <NavLinkTrackActive to={`/school/${model && model.id || ''}/microConsultations`} tag={Link}>
                                    <FontAwesomeIcon icon="video" className="nav-icon" />
                                    Micro-consultation videos

                                    <ConditionalFragment showIf={!(model && model.isPotentialSchool) && !isProductTierUnlocked(ProductTier.Snapshot, currentTeir)}>
                                        <FontAwesomeIcon icon="lock" className="sidebar-locked-nav-item-lock-icon" />
                                    </ConditionalFragment>
                                </NavLinkTrackActive>
                            </NavItem>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!user && !user.isLimitedToAwardTags}>
                            <SidebarNavTitle>Users</SidebarNavTitle>
                                <ConditionalFragment showIf={!subscription || !subscription.isForSingleSchool}>
                                    <NavItem>
                                        <NavLinkTrackActive to={`/school/${model && model.id || ''}/users`} tag={Link}>
                                            <FontAwesomeIcon icon="users" className="nav-icon" />
                                            School users
                                        </NavLinkTrackActive>
                                    </NavItem>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!subscription && subscription.isForSingleSchool}>
                                    <NavItem>
                                    <NavLinkTrackActive to={`/school/${model && model.id || ''}/singleSchoolTrustUsers/${model && model.trustId || ''}`} tag={Link}>
                                            <FontAwesomeIcon icon="users" className="nav-icon" />
                                            School users
                                        </NavLinkTrackActive>
                                    </NavItem>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        {/* Show reviewers for the Self Evluation project, or if that is not available, the due diligence project. */}
                        {/*<ConditionalFragment showIf={!!selfEvaluationProject}>*/}
                        {/*    <NavItem>*/}
                        {/*        <NavLinkTrackActive to={`/school/${model && model.id || ''}/reviewers/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>*/}
                        {/*            <FontAwesomeIcon icon="users" className="nav-icon" />*/}
                        {/*            Reviewers*/}
                        {/*        </NavLinkTrackActive>*/}
                        {/*    </NavItem>*/}
                        {/*</ConditionalFragment>*/}
                        {/*<ConditionalFragment showIf={!selfEvaluationProject && !!dueDiligenceProject}>*/}
                        {/*    <NavItem>*/}
                        {/*        <NavLinkTrackActive to={`/school/${model && model.id || ''}/reviewers/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>*/}
                        {/*            <FontAwesomeIcon icon="users" className="nav-icon" />*/}
                        {/*            Reviewers*/}
                        {/*        </NavLinkTrackActive>*/}
                        {/*    </NavItem>*/}
                        {/*</ConditionalFragment>*/}
                    </PrivateContainer>
                </Nav>
            </SidebarNav>

            <AppSidebarFooter />
            {/*<AppSidebarMinimizer />*/}
        </>
    );
};