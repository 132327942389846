import * as React from 'react';
import { View, Image, Text } from '@react-pdf/renderer';
import { RAGPdfProps } from './RAGPdf';
import { IImageEnumerator } from '../../executiveSummary/pdf/ImageEnumerator';
import { Question } from '../../../../api/models/Question';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../../../utilities/replacePlaceholders';
import { QuestionResponse } from '../../../../api/models/QuestionResponse';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';
import { Styles } from '../../executiveSummary/pdf/Styles';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import moment from 'moment';
import { isNullOrUndefined } from 'util';


export interface RAGProjectWillDoPdfUiProps extends RAGPdfProps {
    
}

/**
 * RAG question breakdown by RAG.
 */
export const RAGProjectWillDoPdfUi = (props: RAGProjectWillDoPdfUiProps) => {
    const {
        questionResponses,
        questionChoices,
    } = props;

    if (!props.model) {
        return (<></>);
    }

    let questions = props.questions;
    return (
        <View wrap={false} style={{ marginBottom: 20, }}>
            <View style={{ ...Styles.row, marginBottom: 5, }}>
                <View style={{ ...Styles.col, width: '65%' }}>
                    <Text style={Styles.h4}>
                    Commitments
                    </Text>
                </View>
            </View>
            <View>
                <TableFor
                    title="Green: Confidence is Strong"
                    items={questions}
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    colour={"#4dbd74"}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    RAG={"Green"}
                    subjectName={props.subject?.name}
                />
            </View>
            <View>
                <TableFor
                    title="Amber: Developing confidence"
                    items={questions}
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    colour={"#ffc107"}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    RAG={"Amber"}
                    subjectName={props.subject?.name}
                />
            </View>
            <View>
                <TableFor
                    title="Red: Confidence gaps"
                    items={questions}
                    placeholderReplacementMode={props.placeholderReplacementMode}
                    colour={"#f86c6b"}
                    questionResponses={questionResponses}
                    questionChoices={questionChoices}
                    RAG={"Red"}
                    subjectName={props.subject?.name}
                />
            </View>
        </View>
         
    );
};
/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and is only used internally by the file's main component
 */

const TableFor = (props: {
    title: string,
    items: Array<Question>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    colour: string,
    questionResponses: Array<QuestionResponse>,
    questionChoices: Array<QuestionChoice>,
    RAG: string,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        colour,
        questionResponses,
        questionChoices,
        RAG,
        subjectName,
    } = props;
    return (
        <View style={{ ...Styles.table, marginBottom: '2' }}>
            <View style={Styles.thead}>
                <View style={Styles.trHead}>
                    <View style={{ ...Styles.th, width: '5%' }}><Text>{' '}</Text></View>
                    <View style={{ ...Styles.th, width: '45%' }}>
                        <Text style={{ color: colour }}>
                            {title}
                        </Text>
                    </View>
                    <View style={{ ...Styles.th, width: '40%' }}>
                        <Text style={{ color: colour }}>
                            {RAG === "Green" ? "Maintaining and Sharing Confidence" : RAG === "Amber" ? "Embedding Confidence" : RAG === "Red" ? "Establishing Confidence" : ""}
                        </Text>
                    </View>
                    <View style={{ ...Styles.th, width: '10%' }}>
                        <Text style={{ color: colour }}>
                            Timeline
                        </Text>
                    </View>
                </View>
            </View>
            <View style={Styles.tbody}>
                {
                    items.map(item => {
                        // Without a question we can't show anything.
                        let willDoText = questionResponses.find(it => it.questionId === item.id)?.willDoText;
                        if (!item || willDoText === "") {
                            return null;
                        }
                        let willDoDate = questionResponses.find(it => it.questionId === item.id)?.willDoDate;
                        // Get the RAG response and filter each table to only show their own colour
                        const RAGchoice = questionChoices.find(it => it.questionId === item.id)?.name;

                        if (RAGchoice?.split(":")[0] !== RAG) {
                            return null;
                        }
                        return (
                            <View key={item.id} style={Styles.tr}>
                                <View style={{ ...Styles.th, width: '5%' }}>
                                    <Text>
                                        {item.questionNumber}
                                    </Text>
                                </View>
                                <View style={{ ...Styles.th, width: '45%' }}>
                                    <Text>
                                        <PlainTextWithBrsPdf text={replacePlaceholders(item.name, placeholderReplacementMode, { subjectName: subjectName })} />
                                    </Text>
                                </View>
                                <View style={{ ...Styles.th, width: '40%' }}>
                                    <Text>
                                        <PlainTextWithBrsPdf text={replacePlaceholders(willDoText, placeholderReplacementMode, { subjectName: subjectName })} />
                                    </Text>
                                </View>
                                <View style={{ ...Styles.th, width: '10%' }}>
                                    {
                                        !!willDoDate ? (
                                            <View style={{ marginTop: '4', paddingTop: '2', borderLeftWidth: '1', borderLeftColor: 'lightgray', borderLeftStyle: 'dotted', color: '#606060', textAlign: 'right' }}>
                                                <Text style={{ textAlign: 'right' }}>{moment(willDoDate).local().format('DD/MM/YYYY')}</Text>
                                            </View>
                                        ) : null
                                    }
                                </View>
                            </View>
                        );
                    })
                }
            </View>
        </View>
    );
};

