import * as React from 'react';
import { useApiService } from '../../api/useApiService';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Button, Col, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Subscription } from '../../api/models/Subscription';
import { SubscriptionLicense } from '../../api/models/SubscriptionLicense';
import { useNavigateWhenReady } from '../shared/hooks/useNavigateWhenReady';
import { ProgressWheel } from '../schools/progressWheel/ProgressWheel';
import { ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { Subject } from '../../api/models/Subject';
import { useLoadProjectWithRetry } from '../subjectReviews/useLoadProjectWithRetry';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../utilities/replacePlaceholders';
import { QuestionSet } from '../../api/models/QuestionSet';

export interface ReadingReviewOverviewProps {
    questionSetName: string,
    routePart: string,
    reportName: string,
}

// Specialised versions of ReadingReviewOveriew that match to specific question sets.
export const ReadingReviewEYFSToKS2Overview = (props: any) => (<ReadingReviewOverview {...props} questionSetName="Reading review EYFS to KS2" routePart="readingReviewEYFSToKS2" reportName="readingReviewEYFSToKS2" />);
export const ReadingReviewKS3ToKS4Overview = (props: any) => (<ReadingReviewOverview {...props} questionSetName="Reading review KS3 to KS4" routePart="readingReviewKS3ToKS4" reportName="readingReviewKS3ToKS4" />);

/**
 * Overview of a reading review.  Usually used via a specalised component such as ReadingReviewEYFSToKS2Overview.
 * @param props
 */
export const ReadingReviewOverview = (props: ReadingReviewOverviewProps) => {
    const {
        questionSetName,
        routePart,
        reportName,
    } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');

    const api = useApiService();
    const loadViewModel = api.schools.viewModels.readingReviewOverview();

    // Load the main model data.
    //
    const [model, setModel] = React.useState<School | undefined>();
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [license, setLicense] = React.useState<SubscriptionLicense | undefined>();
    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(id);
        setSubscription(result.subscription);
        setLicense(result.license);
        setModel(result.model);
        setPlaceholderReplacementMode(result.placeholderReplacementMode);
    }, [loadViewModel, setModel, id, setSubscription, setLicense, questionSetName, setPlaceholderReplacementMode]);
    React.useEffect(() => {
        if (!id || isLoading) {
            return;
        }

        if (model && model.id === id) {
            return;
        }

        load();
    }, [id, load, model, isLoading]);

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const {
        project,
        sections,
        topics,
        isLoadingProject,
        loadProjectErrors,
    } = useLoadProjectWithRetry({
        schoolId: model && model.id || '',
        trustId: model && model.trustId || '',
        subjectId: undefined,
        questionSetName: questionSetName,
        createIfMissing: true
    });

    const [navigateWhenReady, { isNavigationPending: navigateIsPending }] = useNavigateWhenReady(
        !!model && !!project,
        {
            'review': `/school/${model && model.id || ''}/${routePart}Dashboard/${project && project.id || ''}`,
            'report': `/school/${model && model.id || ''}/reports/${routePart}/${reportName}/${project && project.id || ''}`,
       }
    );


    if (!model) {
        return (<LoadingIndicator />);
    }

    // If we are for trial, don't show anything as subject reviews are not part of trials.
    if (license && license.isTrial) {
        return (
            <div className="main-container">
                <div className="main-heading">
                    <h1>Reading review</h1>
                </div>

                <Alert color="warning">
                    <FontAwesomeIcon icon="lock" />
                    <> </>
                    <strong>
                        This area is locked during your trial.
                    </strong>
                    <p>
                        Specific reviews are only available as part of your full Evaluate-Ed subscription.
                    </p>
                </Alert>
            </div>
            );
    }

    return (
        <>
            <div className="main-container overview">
                <AlertOnErrors errors={[loadingErrors, loadProjectErrors]} />

                <div className="overview-logo">
                </div>
                
                <Row>
                    {/* Show the progress wheel for subject leadership review */}
                    <Col xs={12} xl={6}>
                        <ProgressWheel
                            model={project || undefined}
                            sections={sections || []}
                            topics={topics || []}
                            currentTier={model.tier as ProductTier}
                            awardTagId={undefined}
                            isLoading={isLoading || isLoadingProject}
                            placeholderReplacementMode={placeholderReplacementMode}
                        />
                    </Col>

                    {/* Show the progress wheel for helpful questions */}
                    <Col xs={12} xl={6}>
                        <div className="overview-tier-heading">
                            <h3>
                                Reports
                            </h3>
                        </div>


                        <Row className="mt-4">
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('report')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('report') ? 'spinner' : 'passport'} spin={navigateIsPending('report')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                Reading review report
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('review')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('review') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('review')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                Review reading review dashboard
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};
