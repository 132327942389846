import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import {
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader
} from '@coreui/react';
import { SidebarNav, SidebarNavTitle } from '../../shared/sidebarNav';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { NavLinkTrackActive } from '../../shared/NavLinkTrackActive';
import { useAsyncCallback } from 'react-use-async-callback';
import { useApiService } from '../../../api/useApiService';
import { Project } from '../../../api/models/Project';
import { Trust } from '../../../api/models/Trust';

interface SidebarProps {
    model: Trust | undefined | null,
}

export const Sidebar = (props: SidebarProps) => {
    const { model } = props;
    
    return (
        <>
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <Nav>
                    <SidebarNavTitle className="d-block d-sm-none">
                        Evaluate-Ed
                    </SidebarNavTitle>
                    <PrivateContainer role="Manage">
                        <NavItem className="d-block d-sm-none  admin-nav-sidebar">
                            <NavLinkTrackActive to="/manage" tag={Link}>
                                Manage
                                </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>
                    <PrivateContainer role="Administration">
                        <NavItem className="d-block d-sm-none admin-nav-sidebar">
                            <NavLinkTrackActive to="/administration" tag={Link}>
                                Administration
                            </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>

                    <SidebarNavTitle>
                        Certificate administration
                         </SidebarNavTitle>

                    <NavItem>
                        <NavLinkTrackActive to={`/awardingOrganisation/pendingCertificates`} tag={Link}>
                            <FontAwesomeIcon icon="paper-plane" className="nav-icon" />
                                Pending certificates
                            </NavLinkTrackActive>
                    </NavItem>
                    <NavItem>
                        <NavLinkTrackActive to={`/awardingOrganisation/awardedCertificates`} tag={Link}>
                            <FontAwesomeIcon icon="certificate" className="nav-icon" />
                                Awarded certificates
                            </NavLinkTrackActive>
                    </NavItem>
                    <NavItem>
                        <NavLinkTrackActive to={`/awardingOrganisation/failedCertificates`} tag={Link}>
                            <FontAwesomeIcon icon="frown" className="nav-icon" />
                                Failed certificates
                            </NavLinkTrackActive>
                    </NavItem>
                        
                    <SidebarNavTitle>Administration</SidebarNavTitle>
                    <NavItem>
                        <NavLinkTrackActive to={`/awardingOrganisation/details/${model && model.id || ''}`} tag={Link}>
                            <FontAwesomeIcon icon="handshake" className="nav-icon" />
                            Organisation details
                        </NavLinkTrackActive>
                    </NavItem>
                    <NavItem>
                        <NavLinkTrackActive to={`/awardingOrganisation/trustUsers/${model && model.id || ''}`} tag={Link}>
                            <FontAwesomeIcon icon="users" className="nav-icon" />
                            Users
                        </NavLinkTrackActive>
                    </NavItem>

                    <SidebarNavTitle>
                        Subscription
                    </SidebarNavTitle>
                    <NavItem>
                        <NavLinkTrackActive to={`/awardingOrganisation/subscription`} tag={Link}>
                            <FontAwesomeIcon icon="id-badge" className="nav-icon" />
                            Subscription
                        </NavLinkTrackActive>
                    </NavItem>
                </Nav>
            </SidebarNav>

            <AppSidebarFooter />
            {/*<AppSidebarMinimizer />*/}
        </>
    );
};
