import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { Project } from "../../api/models/Project";
import { ProjectTopic } from "../../api/models/ProjectTopic";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { withServiceProps } from "inject-typesafe-react";
import { Question } from "../../api/models/Question";
import { ProjectQuestionaire } from "../../api/models/ProjectQuestionaire";


export interface ShowQuestionContainerProps extends ContainerComponentProps<ShowQuestionUiProps> {
    loadModel: (id: string, questionKey: string) => Promise<any>
}

export interface ShowQuestionUiProps {
    model: Project | undefined,
    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any,

    question: Question | undefined,
    projectQuestionaire: ProjectQuestionaire | undefined,
    projectTopic: ProjectTopic | undefined

    awardTagId: string | undefined,
}

/**
 * Container for locating and redirecting to a question within a project.
 * 
 * @param props
 */
export const _ShowQuestionContainer = (props: ShowQuestionContainerProps) => {
    const { component, loadModel, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('projectId', '');
    const questionKey = navigation.getParam('questionKey', '');
    const awardTagId = navigation.getParam('awardTagId', '');

    const [model, setModel] = React.useState<Project | undefined>(undefined);
    const [question, setQuestion] = React.useState<Question | undefined>(undefined);
    const [projectQuestionaire, setProjectQuestionaire] = React.useState<ProjectQuestionaire | undefined>(undefined);
    const [projectTopic, setProjectTopic] = React.useState<ProjectTopic | undefined>(undefined);


    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadModel(id, questionKey);
        setQuestion(result.question);
        setProjectQuestionaire(result.projectQuestionaire);
        setProjectTopic(result.projectTopic);

        setModel(result.model);
        return true;
    }, [id, loadModel, setModel, questionKey, setQuestion, setProjectQuestionaire, setProjectTopic]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest} model={model}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            question={question}
            projectQuestionaire={projectQuestionaire}
            projectTopic={projectTopic}
            awardTagId={awardTagId}
        />
    );
};

export const ShowQuestionContainer = withServiceProps<ShowQuestionContainerProps, AppServicesCore>(services => ({
    loadModel: services.api.projects.viewModels.showQuestion(),
}))(_ShowQuestionContainer);

