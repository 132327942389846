import * as React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { dashboardDrilldownUrl } from '../../../../utilities/dashboardDrilldownUrl';
import { Project } from '../../../../api/models/Project';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { AwardTag } from '../../../../api/models/AwardTag';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../../../utilities/replacePlaceholders';

export interface SchoolWithoutTrustComparisonTableProps {
    model: Project | undefined,
    results: Array<ProjectAnalysisResult>,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    awardTag: AwardTag | undefined,
    placeholderReplacementMode: PlaceholderReplacementMode,
}

export const SchoolWithoutTrustComparisonTable = (props: SchoolWithoutTrustComparisonTableProps) => {
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTag && props.awardTag.id || undefined);

    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index as well.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    if (!props.results || !props.results.length) {
        return (<></>);
    }

    // Note this is dependeant on the order of the projects in the results.
    // If you you change this order, the code will not work.
    let thisProject = props.results[0];

    return (
        <div className="table-responsive">
            <Table striped className="table-sm">
                <thead>
                    <tr>
                        {
                            isForQuestions ? (
                                <th><span></span></th>
                            ) : null
                        }
                        <th><span></span></th>
                        <th className="text-right"><span>
                            {thisProject.school ? thisProject.school.name : thisProject.trust ? thisProject.trust.name : 'Project'}
                        </span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        thisProject.results.map(item => {
                            return (
                                <tr key={item.originKey}>
                                    {
                                        isForQuestions ? (
                                            <td><span>{ item.questionNumber }</span></td>
                                        ) : null
                                    }
                                    <td><span>
                                        <Link to={dashboardDrilldownUrl(projectSettings, props.filterBySectionOriginKey, props.filterByTopicOriginKey, item.originKey)}>
                                            {replacePlaceholders(item.name, props.placeholderReplacementMode)}
                                        </Link>
                                    </span></td>
                                    <td className="text-right"><span><strong>{item.questionScore === 0 ? 'N/A' : `${item.percentageQuestionScore.toFixed(0)}%`}</strong></span></td>
                                </tr>
                            );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

