import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { GoBackLinkContainer } from './shared/GoBackLinkContainer';

/**
 * Component to show when the requested page was not found.
 */
export const NotFound = () => {
    return (
        <Container className="main-container">
            <div className="nothing-here">
                <FontAwesomeIcon icon={['far', 'frown-open']} />
                Sorry the page you are looking for could not be found.<br />
                You can <GoBackLinkContainer><a href="#back">go back to the previous page</a></GoBackLinkContainer> or <Link to="/">go to home page</Link> if you prefer.
            </div>
        </Container>
    );
};