import * as React from 'react';
import { Form, FormGroup, Label, Alert, Row, Col, Container, Input, Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormText, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Collapse, CardHeader, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Guid } from 'guid-string';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { AddToRiskReportModalUiProps, AddToRiskReportModalContainer } from '../risks/AddToRiskReportModalContainer';
import { ValidatedElasticInput } from '../../shared/ValidatedElasticInput';
import { withContainer } from 'react-withcontainer';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { User } from '../../../api/models/User';
import { ValidationErrors } from 'pojo-validator';
import { ManagedChildModels } from '../../../components/shared/hooks/useManagedChildModels';
import { ProjectReviewerRestriction } from '../../../api/models/ProjectReviewerRestriction';
import { useAsyncCallback } from 'react-use-async-callback';
import { withServiceProps } from 'inject-typesafe-react';
import { AppServicesCore } from '../../../configure/configureServicesCore';
import { Section } from '../../../api/models/Section';
import { Topic } from '../../../api/models/Topic';
import { Question } from '../../../api/models/Question';
import { isNullOrUndefined } from 'util';
import { Project } from '../../../api/models/Project';

export interface RestrictionsModalProps {
    project: Project,
    projectReviewerId: string,
    restrictions: ManagedChildModels<ProjectReviewerRestriction>,
    close: () => void,

    /* From dependency injection */
    loadModel: (questionSetId: string, subscriptionId: string) => Promise<any>,
}

export const _RestrictionsModal = (props: RestrictionsModalProps) => {
    const [sections, setSections] = React.useState<Array<Section> | undefined>(undefined);
    const [topics, setTopics] = React.useState<Array<Topic>>([]);
    const [questions, setQuestions] = React.useState<Array<Question>>([]);

    // Load the view model.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        let result = await props.loadModel(props.project.questionSetId, props.project.subscriptionId);

        setSections(result.sections);
        setTopics(result.topics);
        setQuestions(result.questions);
    }, [props.project.questionSetId, props.project.subscriptionId, props.loadModel, setSections, setTopics, setQuestions]);

    // Toggle the restriction on or off.
    const toggleRestriction = React.useCallback((targetId: string, targetType: string) => {
        let restriction = props.restrictions.models.find(item => item.projectReviewerId === props.projectReviewerId && item.targetId === targetId);
        if (restriction) {
            props.restrictions.removeModel(restriction.id);
        } else {
            props.restrictions.addModel({ projectReviewerId: props.projectReviewerId, targetId: targetId, targetType: targetType });
        }
    }, [props.restrictions, props.projectReviewerId]);

    const [expanded, setExpanded] = React.useState<{ [id: string]: boolean }>({});
    const toggleExpanded = React.useCallback((id: string) => {
        setExpanded(prevState => {
            let thisEntry = prevState[id];
            return {
                ...prevState,
                [id]: thisEntry ? false : true,
            };
        });
    }, [setExpanded]);

    // Load 
    React.useEffect(() => {
        if (!sections) {
            load();
        }
    }, [load, sections]);

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                What can this reviewer answer?
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={loadingErrors} />
                <ListGroup>
                    {
                        sections ? sections.map(section => {
                            let sectionRestriction = props.restrictions.models.find(it => it.projectReviewerId === props.projectReviewerId && it.targetId === section.id);
                            let myTopics = topics.filter(it => it.sectionId === section.id);
                            let sectionExpanded = expanded[section.id];

                            return (
                                <ListGroupItem key={section.id}>
                                    <ListGroupItemHeading>
                                        <Row>
                                            <Col xs="auto">
                                                <a href="#" onClick={e => { e.preventDefault(); toggleExpanded(section.id); } }>
                                                    <FontAwesomeIcon icon={sectionExpanded ? ['far', 'minus-square'] : ['far', 'plus-square']} />
                                                </a>
                                            </Col>
                                            <Col xs="auto" className="ml-2">
                                                <Input type="checkbox" checked={sectionRestriction? false: true} onChange={e => toggleRestriction(section.id, 'Section')} />
                                            </Col>
                                            <Col>
                                                {section.name}
                                            </Col>
                                        </Row>
                                    </ListGroupItemHeading>
                                    {
                                        sectionExpanded ? (
                                            <div>
                                                {
                                                    myTopics.map(topic => {
                                                        let topicRestriction = props.restrictions.models.find(it => it.projectReviewerId === props.projectReviewerId && it.targetId === topic.id);
                                                        let myQuestions = questions.filter(it => it.topicId === topic.id);
                                                        let topicExpanded = expanded[topic.id];

                                                        return (
                                                            <ListGroupItem key={topic.id}>
                                                                <ListGroupItemHeading>
                                                                    <Row>
                                                                        <Col xs="auto">
                                                                            <a href="#" onClick={e => { e.preventDefault(); toggleExpanded(topic.id); }}>
                                                                                <FontAwesomeIcon icon={topicExpanded ? ['far', 'minus-square'] : ['far', 'plus-square']} />
                                                                            </a>
                                                                        </Col>
                                                                        <Col xs="auto" className="ml-2">
                                                                            <Input type="checkbox" checked={sectionRestriction || topicRestriction ? false : true} onChange={e => { if (sectionRestriction) { return; } toggleRestriction(topic.id, 'Topic'); }} />
                                                                        </Col>
                                                                        <Col>
                                                                            {topic.name}
                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItemHeading>
                                                                {
                                                                    topicExpanded ? (
                                                                        <div>
                                                                            {
                                                                                myQuestions.map(question => {
                                                                                    let questionRestriction = props.restrictions.models.find(it => it.projectReviewerId === props.projectReviewerId && it.targetId === question.id);

                                                                                    return (
                                                                                        <ListGroupItem key={question.id}>
                                                                                            <div>
                                                                                                <Row>
                                                                                                    <Col xs="auto" className="ml-2">
                                                                                                        <Input type="checkbox" checked={sectionRestriction || topicRestriction || questionRestriction ? false : true} onChange={e => { if (sectionRestriction || topicRestriction) { return; } toggleRestriction(question.id, 'Question'); }} />
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        {question.name}
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        </ListGroupItem>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                        ): null
                                                                }
                                                                
                                                            </ListGroupItem>
                                                        );
                                                    })
                                                }
                                            </div>
                                            ): null
                                    }
                                </ListGroupItem>
                            );
                        })
                        : (
                            <LoadingIndicator />
                            )
                    }
                </ListGroup>
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <Button color="primary" outline onClick={props.close}>Close</Button>
            </ModalFooter>            
        </Modal>
    );
};

export const RestrictionsModel = withServiceProps<RestrictionsModalProps, AppServicesCore>(services => ({
    loadModel: services.api.projectReviewerRestrictions.viewModels.restrictionsModal(),
}))(_RestrictionsModal);