import * as React from 'react';
import { ContainerComponentProps } from 'react-withcontainer';
import { PagedListUiPropsBase } from '../../containers/common/PagedListUiPropsBase';
import { VideoTag } from '../../../api/models/VideoTag';
import { useAsyncCallback } from 'react-use-async-callback';
import { LoadPageResult } from '../../containers/common/basicContainers/BasicPagedListContainer';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Repository } from 'pojo-repository';
import { withServiceProps } from 'inject-typesafe-react';
import { AppServicesCore } from '../../../configure/configureServicesCore';
import { VideoView } from '../../../api/models/VideoView';
import { Video } from '../../../api/models/Video';
import { Consultant } from '../../../api/models/Consultant';
import { User } from '../../../api/models/User';
import { School } from '../../../api/models/School';
import { Subscription } from '../../../api/models/Subscription';

export interface ListContainerProps extends ContainerComponentProps<ListUiProps> {
    loadPage: (page: number, search?: string) => Promise<LoadPageResult<DataItem>>,
}

export interface ListUiProps extends PagedListUiPropsBase<DataItem> {

}

export interface DataItem {
    model: VideoView,
    video: Video,
    consultant: Consultant | undefined,
    videoTags: Array<VideoTag>,
    user: User,
    school: School | undefined,
    subscription: Subscription | undefined,
}

export const _ListContainer = (props: ListContainerProps) => {
    const { component, loadPage, ...rest } = props;

    const navigation = useUniversalNavigation(props);

    const [model, setModel] = React.useState<Array<DataItem> | undefined>();
    const [search, setSearch] = React.useState<string>(navigation.getParam('search', ''));
    const [nextPage, setNextPage] = React.useState<number>(0);

    // Callback for changing the next page.
    const changeNextPage = React.useCallback((page: number) => {
        setNextPage(page);
    }, [setNextPage]);

    // Wrap the loadPage method to have correct async behaviour.
    const [wrappedLoadPage, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (page: number, searchText?: string): Promise<boolean> => {
        let result = await loadPage(page, searchText);
        setModel(result.model);
        setNextPage(result.nextPage);
        return true;
    }, [loadPage, setModel, setNextPage]);

    // Callback for changing the search.  This will also load the first page for the new search.
    const changeSearch = React.useCallback((search: string) => {
        setSearch(search);
        wrappedLoadPage(1, search);
    }, [setSearch, wrappedLoadPage]);

    // Load the first page on mount if we haven't got a model.
    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            wrappedLoadPage(1, search);
        }
    }, [model, isLoading, loadingErrors, wrappedLoadPage, search]);

    const Component = component;
    return (
        <Component {...rest}
            model={model}
            loadPage={wrappedLoadPage} isLoading={isLoading} loadingErrors={loadingErrors}
            search={search} changeSearch={changeSearch}
            nextPage={nextPage} changeNextPage={changeNextPage}
        />
    );
};

export const ListContainer = withServiceProps<ListContainerProps, AppServicesCore>(services => (
    {
        loadPage: services.api.videoViews.viewModels.list()
    }
))(_ListContainer);