export interface ImageWithMetadata {
    dataUrl: string,
    width: number,
    height: number,
    aspectRatio: number,
}

export interface IImageEnumerator {
    getNextImage(): string;
    getNextImageWithMetadata(): ImageWithMetadata,
}

export class ImageEnumerator implements IImageEnumerator {
    _imageNumber: number = 0;
    _images: Array<ImageWithMetadata>;

    getNextImage(): string {
        let image = this._images[this._imageNumber];
        this._imageNumber += 1;
        return image.dataUrl;
    }

    getNextImageWithMetadata(): ImageWithMetadata {
        let image = this._images[this._imageNumber];
        this._imageNumber += 1;
        return image;
    }

    constructor(images: Array<ImageWithMetadata>) {
        this._images = images;
    }
}
