import { PasswordOptions, defaultPasswordOptions } from './PasswordOptions';


/**
 * Enforce and describe PasswordOptions.
 */
export class PasswordValidation {
    private readonly passwordOptions: PasswordOptions;

    constructor(passwordOptions?: PasswordOptions) {
        this.passwordOptions = passwordOptions || defaultPasswordOptions;
    }

    /**
     * Check password against the PasswordOptions and return a success indicator and an array of any errors, and a friendly description of all errors.
     * @param password
     */
    check(password: string): [boolean, Array<string>] {
        let ok = true;
        let errors: Array<string> = [];

        if (this.passwordOptions.requiredLength > password.length) {
            ok = false;
            errors.push(`must be at least ${this.passwordOptions.requiredLength} characters long`);
        }

        if (this.passwordOptions.requiredUniqueChars > 1) {
            let uniqueChars = 0;
            let charMap = Array<string>();
            for (let i = 0; i < password.length; ++i) {
                let char = password.charAt(i);
                if (charMap.indexOf(char) != -1) {
                    charMap.push(char);

                    // Break if we know enough to let the password pass on this rule.
                    if (charMap.length > this.passwordOptions.requiredUniqueChars) {
                        break;
                    }
                }
            }

            if (this.passwordOptions.requiredUniqueChars > charMap.length) {
                ok = false;
                errors.push(`must contain at least ${this.passwordOptions.requiredUniqueChars} unique characters`);
            }
        }

        if (this.passwordOptions.requireDigit) {
            var regExp = new RegExp('.*[0-9].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push('must contain a number');
            }
        }

        if (this.passwordOptions.requireLowercase) {
            var regExp = new RegExp('.*[a-z].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push('must contain a lowercase letter');
            }
        }

        if (this.passwordOptions.requireUppercase) {
            var regExp = new RegExp('.*[A-Z].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push('must contain a uppercase letter');
            }
        }

        if (this.passwordOptions.requireNonAlphanumeric) {
            var regExp = new RegExp('.*[^0-9a-zA-Z].*');
            if (!regExp.test(password)) {
                ok = false;
                errors.push('must contain a symbol (such as !$%&*@#)');
            }
        }

        return [ok, errors];
    }
}
