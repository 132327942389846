import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import {
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimiz
} from '@coreui/react';
import { SidebarNav, SidebarNavTitle } from '../../shared/sidebarNav';
import { Link } from 'react-router-dom';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { NavLinkTrackActive } from '../../shared/NavLinkTrackActive';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

export interface SidebarProps {
    subscriptionId: string | undefined
}

export const _Sidebar = (props: SidebarProps) => {
    return (
        <>
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <Nav>
                    {
                        props.subscriptionId ? (
                            <PrivateContainer role="Manage">
                                <SidebarNavTitle className="d-block d-sm-none">
                                    Evaluate-Ed
                                </SidebarNavTitle>
                                <NavItem className="d-block d-sm-none admin-nav-sidebar">
                                    <NavLinkTrackActive to="/manage" tag={Link}>
                                        Manage
                                    </NavLinkTrackActive>
                                </NavItem>
                            </PrivateContainer>
                        ) : null
                    }
                    <PrivateContainer role="Administration">
                        {
                            !props.subscriptionId ? (
                                <SidebarNavTitle className="d-block d-sm-none">
                                    Evaluate-Ed
                                </SidebarNavTitle>
                                ): null
                        }
                        <NavItem className="d-block d-sm-none admin-nav-sidebar">
                            <NavLinkTrackActive to="/administration" tag={Link}>
                                Administration
                            </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>
                </Nav>
            </SidebarNav>

            <AppSidebarFooter />
            {/*<AppSidebarMinimizer />*/}
        </>
    );
};

export const Sidebar = connect(
    (state: any) => ({
        subscriptionId: state.user.identity ? state.user.identity.subscriptionId != null ? state.user.identity.subscriptionId : undefined : undefined
    }),
    (dispatch: React.Dispatch<AnyAction>) => ({}),
    null,
    {
        pure: false
    }
)(_Sidebar);
