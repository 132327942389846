import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Button, Card, CardBody, Col, Collapse, FormText, Input, Label, Row, Spinner } from 'reactstrap';
import { BlobUrl } from '../../api/models/BlobUrl';
import { SchoolEvidence } from '../../api/models/SchoolEvidence';
import { DateLabel } from '../shared/DateLabel';
import { LinkUrlModal } from './LinkUrlModal';
import { generateClickableUrl } from '../../utilities/generateClickableUrl';
import { useAsyncCallback } from 'react-use-async-callback';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { Section } from '../../api/models/Section';
import { Topic } from '../../api/models/Topic';
import { EvidenceTopicModal } from './EvidenceTopicModal';

export interface EvidenceItemProps {
    model: SchoolEvidence | undefined | null,
    schoolEvidenceName: string,
    uploadFile: (files: FileList, isUpdate: boolean) => Promise<void>,
    isFileUploading: boolean,
    fileBlob: BlobUrl | undefined,
    updateUrl: (url: string, isUpdate: boolean) => Promise<void>,
    history: Array<SchoolEvidence>,
    historyFileBlobs: Array<BlobUrl>,
    canEditName?: boolean,
    setSchoolEvidenceName?: (value: string) => void,
    canRemove?: boolean,
    remove?: (id: string) => void,
    canSelect?: boolean,
    isSelected?: boolean,
    toggleSelected?: () => void,
    canViewHistory?: boolean,
    renameEvidence?: (newName: string) => Promise<void>,
    questionTopicOriginKey?: string,
    sections: Array<Section>,
    topics: Array<Topic>,
    updateTopicJson: (selectedTopics: Array<string>, EvidenceId: string) => void,
}

/**
 * One item of evidence for a school. 
 */
export const EvidenceItem = (props: EvidenceItemProps) => {
    const {
        model,
        schoolEvidenceName,
        uploadFile,
        isFileUploading,
        fileBlob,
        updateUrl,
        history,
        historyFileBlobs,
        canEditName = false,
        setSchoolEvidenceName,
        canRemove = false,
        remove,
        canSelect = false,
        isSelected = false,
        toggleSelected,
        canViewHistory = true,
        renameEvidence,
        questionTopicOriginKey,
        sections,
        topics,
        updateTopicJson,
    } = props;

    // Toggling of history.
    const [isHistoryOpen, setIsHistoryOpen] = React.useState<boolean>(false);
    const toggleHistory = React.useCallback(() => setIsHistoryOpen(prevState => !prevState), [setIsHistoryOpen]);

    // Toggling of URL modal.
    const [isUrlModalOpen, setIsUrlModalOpen] = React.useState<boolean>(false);
    const toggleUrlModal = React.useCallback(() => setIsUrlModalOpen(prevState => !prevState), [setIsUrlModalOpen]);

    const [isTopicModalOpen, setIsTopicModalOpen] = React.useState<boolean>(false);
    const toggleTopicModal = React.useCallback(() => setIsTopicModalOpen(prevState => !prevState), [setIsTopicModalOpen]);

    const updateTopicOriginKeyJson = React.useCallback((selectedTopicOriginKeys: Array<string>) => {
        if (model) {
            updateTopicJson(selectedTopicOriginKeys, model?.id);
            toggleTopicModal();
        }
    }, [updateTopicJson, toggleTopicModal]);

    // Upload a file.
    const [fileSizeError, setFileSizeError] = React.useState<string>('');
    const onUploadFile = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setFileSizeError('');

        if (!event.currentTarget.files || !event.currentTarget.files.length) {
            return;
        }

        // Limit the file size upload to 12mb as per specfieid requirements.
        const maximumFileSize = (1024 * 1024 * 12);
        if (event.currentTarget.files[0].size > maximumFileSize) {
            setFileSizeError(`The maximum file size you can upload is 12mb.  This file is ${(event.currentTarget.files[0].size / 1024.0 / 1024.0).toFixed(1)}mb and too large to be uploaded.`);
            return;
        }

        // Upload the files as the photo.
        uploadFile(event.currentTarget.files, !!model);
    }, [uploadFile, setFileSizeError, model]);

    // Renaming of the document.
    const [isRenaming, setIsRenaming] = React.useState<boolean>(false);
    const [newName, setNewName] = React.useState<string>(schoolEvidenceName);
    const [performRename, { isExecuting: isPerformingRename }] = useAsyncCallback(async () => {
        // Perform a rename at the database level.
        if (renameEvidence) {
            await renameEvidence(newName);
        }

        // Toggle this item as no longer being in renaming mode.
        setIsRenaming(false);
    }, [newName, setIsRenaming, schoolEvidenceName, renameEvidence]);

    return (
        <>
            <Card className="evidence-locker-item" style={{ marginLeft: 10, marginRight: 10 }}>
                <CardBody tag="div">
                    <Row>
                        <ConditionalFragment showIf={canSelect}>
                            <Button color="primary" outline={!isSelected} size="sm" style={{ maxHeight: '35px' }}
                                onClick={() => {
                                    if (!toggleSelected) {
                                        return;
                                    }
                                    toggleSelected();
                                }}>
                                {
                                    isSelected ? (<>Remove</>) : (<>Attach</>)
                                }
                            </Button>
                        </ConditionalFragment>
                        <Col>
                            {
                                canEditName ? (
                                    <>
                                        <Input placeholder="Give your document a name" value={schoolEvidenceName}
                                            onChange={e => {
                                                if (!setSchoolEvidenceName) {
                                                    return;
                                                }

                                                setSchoolEvidenceName(e.currentTarget.value);
                                            }
                                            } />
                                    </>
                                ) : (
                                    <>
                                        <ConditionalFragment showIf={!isRenaming}>
                                            <div>{newName}</div><> </>
                                            <Button color="secondary" size="sm" outline onClick={() => setIsRenaming(true)}>
                                                Rename
                                            </Button>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={isRenaming}>
                                            <Input className="mb-1" placeholder="Rename your document" value={newName}
                                                onChange={e => setNewName(e.currentTarget.value)} />
                                            <ButtonAsync color="secondary" size="sm" onClick={() => performRename()}
                                                isExecuting={isPerformingRename}
                                                executingChildren={<><Spinner size="sm" /><> </>Renaming...</>}
                                            >
                                                Save
                                            </ButtonAsync>
                                            <> </>
                                            <Button color="secondary" size="sm" outline onClick={() => { setNewName(schoolEvidenceName); setIsRenaming(false); }}>
                                                Cancel
                                            </Button>
                                        </ConditionalFragment>
                                    </>
                                )
                            }
                        </Col>

                        <Col>
                            <div className="file-link">
                                {
                                    fileBlob ? (
                                        <a href={fileBlob.url} download={fileBlob.filename} target="_blank">
                                            {fileBlob.filename}
                                        </a>
                                    ) : model && model.url ? (
                                        <a href={generateClickableUrl(model.url)} target="_blank">
                                            {model.url}
                                        </a>
                                    ) : (
                                        <span className="text-muted">(No file uploaded)</span>
                                    )
                                }
                            </div>
                            <ConditionalFragment showIf={!!model}>
                                <div className="text-muted">
                                    <small>
                                        Last updated: <DateLabel value={model && model.updatedDate || ''} format="DD/MM/YY HH:mm" />
                                    </small>
                                </div>
                            </ConditionalFragment>
                        </Col>

                        <Col>
                            <div>
                                <Row className="no-gutters">
                                    <Col xs="auto">
                                        <Label className="btn btn-outline-secondary file-upload-button">
                                            {
                                                isFileUploading ? (
                                                    <>
                                                        <FontAwesomeIcon icon="spinner" spin />
                                                        <> </><span>Uploading...</span>
                                                    </>
                                                ) : (
                                                    <span>
                                                        {
                                                            !model ? 'Upload file...' : 'Update file...'
                                                        }
                                                    </span>
                                                )
                                            }

                                            <Input type="file" name="files" onChange={onUploadFile} disabled={!schoolEvidenceName} />
                                        </Label>
                                    </Col>
                                    <Col className="pl-1">
                                        <Button type="button" color="secondary" outline onClick={toggleUrlModal}>
                                            Link to url...
                                        </Button>
                                    </Col>
                                    <ConditionalFragment showIf={!questionTopicOriginKey && !!model}>
                                        <Col className="pl-1">
                                            <Button type="button" color="secondary" outline onClick={toggleTopicModal}>
                                                Link to topics...
                                            </Button>
                                        </Col>
                                    </ConditionalFragment>
                                </Row>
                            </div>

                            {
                                fileSizeError ? (
                                    <div className="text-danger">
                                        {fileSizeError}
                                    </div>
                                ) : null
                            }
                        </Col>

                        <ConditionalFragment showIf={canViewHistory}>
                            <Col className="text-right">
                                <ConditionalFragment showIf={!!history.length}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => toggleHistory()}>
                                        {history.length} previous version(s)
                                        <> </>
                                        <FontAwesomeIcon icon={isHistoryOpen ? 'caret-up' : 'caret-down'} />
                                    </div>
                                </ConditionalFragment>
                            </Col>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={canRemove}>
                            <Col xs="auto">
                                <Button color="link" className="text-danger" onClick={() => {
                                    if (!remove) {
                                        return;
                                    }

                                    remove(model && model.id || '');
                                }}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <span className="sr-only">Remove additional document</span>
                                </Button>
                            </Col>
                        </ConditionalFragment>
                    </Row>

                    <ConditionalFragment showIf={isHistoryOpen}>
                        <Collapse isOpen={isHistoryOpen}>
                            {
                                history.map(item => {
                                    const myFileBlob = historyFileBlobs.find(it => it.id === item.blobId);


                                    return (
                                        <div key={item.id}>
                                            <Row>
                                                <Col>
                                                    {/* Empty */}
                                                </Col>
                                                <Col xs="auto">
                                                    <div className="file-link">
                                                        {
                                                            myFileBlob ? (
                                                                <a href={myFileBlob.url} download={myFileBlob.filename} target="_blank">
                                                                    {myFileBlob.filename}
                                                                </a>
                                                            ) : item.url ? (
                                                                <a href={generateClickableUrl(item.url)} target="_blank">
                                                                    {item.url}
                                                                </a>
                                                            ) : (
                                                                <span className="text-muted">(No file uploaded)</span>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                                <Col xs="auto">
                                                    <div className="text-muted">
                                                        Updated on: <DateLabel value={item && item.updatedDate || ''} format="DD/MM/YY HH:mm" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })
                            }
                        </Collapse>
                    </ConditionalFragment>
                </CardBody>
            </Card>

            <ConditionalFragment showIf={!!isUrlModalOpen}>
                <LinkUrlModal originalUrlText={model && model.url || ''}
                    cancel={() => toggleUrlModal()}
                    save={(url) => {
                        updateUrl(url, !!model);
                        toggleUrlModal();
                    }}
                />
            </ConditionalFragment>

            <ConditionalFragment showIf={!!isTopicModalOpen}>
                <EvidenceTopicModal
                    updateTopicOriginKeyJson={updateTopicOriginKeyJson}
                    sections={sections}
                    topics={topics}
                    cancel={() => toggleTopicModal()}
                    preSelectedTopics={model?.topicOriginKeysJson}
                />
            </ConditionalFragment>
        </>
    );

};
