import * as React from 'react';
import Chart from 'react-apexcharts';
import { ResultDataItem, SubscriptionDataItem } from '../AdminReportsContainer';
import { chartLargeColorPallet } from '../../../utilities/chartLargeColorPallet';

export interface ResponsesChartProps {
    subscriptions: Array<SubscriptionDataItem>,
    results: Array<ResultDataItem>,
    chartHeight?: number
}

/**
 * Chart that maps various statistics about resposnes.
 * @param props
 */
export const ResponsesChart = (props: ResponsesChartProps) => {
    const options = React.useMemo(() => ({
        labels: props.subscriptions.map(item => (`${item.name}`)),
        colors: chartLargeColorPallet,
    }), [props.subscriptions]);

    var series = React.useMemo(() => ([
        {
            name: 'Questions reviewed',
            data: props.subscriptions.map(item => {
                var result = props.results.find(it => it.subscriptionId === item.id);
                if (!result) {
                    return 0
                }

                return result.reviewerQuestionsAnswered;
            })
        },
        {
            name: 'Topics reviewed',
            data: props.subscriptions.map(item => {
                var result = props.results.find(it => it.subscriptionId === item.id);
                if (!result) {
                    return 0
                }

                return result.reviewerTopicsAnswered;
            })
        },
        {
            name: 'Sections reviewed',
            data: props.subscriptions.map(item => {
                var result = props.results.find(it => it.subscriptionId === item.id);
                if (!result) {
                    return 0
                }

                return result.reviewerSectionsAnswered;
            })
        },
        {
            name: 'Questionnaire responses',
            data: props.subscriptions.map(item => {
                var result = props.results.find(it => it.subscriptionId === item.id);
                if (!result) {
                    return 0
                }

                return result.questionaireRespondants;
            })
        },
        ]), [props.subscriptions, props.results]);
    
    // Show the chart.
    return (
        <Chart options={options} series={series} type={props.subscriptions.length <= 1? 'bar': 'line'} height={props.chartHeight ? props.chartHeight : 400} />
    );
};

