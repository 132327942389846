import * as React from 'react';
import { Col } from 'reactstrap';
import { Row } from 'reactstrap';
import { TwoValueSwitch } from '../../../shared/TwoValueSwitch';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Collapse } from 'reactstrap';
import { ReportBuilderOptionsProps } from '../../reportGenerator/ReportBuilderOptions';
import { useToggleStateArray } from '../../../shared/hooks/useToggleState/useToggleStateArray';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'reactstrap';
import { productTiers, productTierDisplayName } from '../../../../api/models/codeOnly/ProductTeir';

/**
 * Options shown in the ReportBuilder.
 * @param props
 */
export const RAGOptions = (props: ReportBuilderOptionsProps) => {
    const {
        //reportSettings,
        //changeReportSettings,

        //projectSettings,

        //reportBuilderSections,
        //reportBuilderTopics,
    } = props;

    return (
        <div>
            {/* RAG reports can't be custoised */}
        </div>
    );
};
