import * as React from 'react';
import { Form, FormGroup, Label, Row, Col, Container, Collapse, FormText, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from './EditContainer';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../shared/GoBackLinkContainer';
import { ValidatedElasticInput } from '../shared/ValidatedElasticInput';
import { Guid } from 'guid-string';
import { VideoResourceItem } from './VideoResourceItem';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    // Upload a video.
    const [videoFileSizeError, setVideoFileSizeError] = React.useState<string>('');
    const onUploadVideo = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setVideoFileSizeError('');

        if (!event.currentTarget.files || !event.currentTarget.files.length) {
            return;
        }

        // Only accept files of the right format (based on file extension).
        if (!!event.currentTarget.files[0]
            && !!event.currentTarget.files[0].name
            && !event.currentTarget.files[0].name.toLowerCase().endsWith('.mp4')) {
            setVideoFileSizeError(`The video must be in mp4 format.`);
            return;
        }

        // NOTE curretly no limit on the video size.
        //// Limit the file size upload to 12mb as per specfieid requirements.
        //const maximumFileSize = (1024 * 1024 * 12);
        //if (event.currentTarget.files[0].size > maximumFileSize) {
        //    setFileSizeError(`The maximum file size you can upload is 12mb.  This file is ${(event.currentTarget.files[0].size / 1024.0 / 1024.0).toFixed(1)}mb and too large to be uploaded.`);
        //    return;
        //}

        // Upload the files as the video.
        await props.uploadVideo(event.currentTarget.files);
    }, [props.uploadVideo, setVideoFileSizeError]);

    // Upload a thumbnail.
    const [thumbnailFileSizeError, setThumbnailFileSizeError] = React.useState<string>('');
    const onUploadThumbnail = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setThumbnailFileSizeError('');

        if (!event.currentTarget.files || !event.currentTarget.files.length) {
            return;
        }

        // Only accept files of the right format (based on file extension).
        if (event.currentTarget.files[0].name
            && !event.currentTarget.files[0].name.toLowerCase().endsWith('.png')
            && !event.currentTarget.files[0].name.toLowerCase().endsWith('.jpg')
            && !event.currentTarget.files[0].name.toLowerCase().endsWith('.jpeg')
        ) {
            setThumbnailFileSizeError(`The thumbnail must be in png or jpg format.`);
            return;
        }

        // Limit the file size upload to 12mb as per specfieid requirements.
        const maximumFileSize = (1024 * 1024 * 12);
        if (event.currentTarget.files[0].size > maximumFileSize) {
            setThumbnailFileSizeError(`The maximum file size you can upload for a thumbnail is 12mb.  This file is ${(event.currentTarget.files[0].size / 1024.0 / 1024.0).toFixed(1)}mb and too large to be uploaded.`);
            return;
        }

        // Upload the files as the vithumbnaildeo.
        await props.uploadThumbnail(event.currentTarget.files);
    }, [props.uploadThumbnail, setThumbnailFileSizeError]);


    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors, props.uploadVideoErrors, props.uploadThumbnailErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add video' : 'Edit video'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <ValidatedInput type="text" name="name" autoComplete="off" placeholder="Name" value={props.model.name} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>

                <FormGroup>
                    {
                        !props.videoBlob && !props.thumbnailBlob ? (
                            <div className="video-container video-container-admin video-thumbnail">
                                <div className="nothing-here">
                                    <div>
                                        <FontAwesomeIcon icon={['far', 'frown-open']} />
                                        You have not uploaded a video yet.
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <div className="embed-responsive embed-responsive-16by9 mb-4 video-container video-container-admin">
                                    <video
                                        src={props.videoBlob && props.videoBlob.url || ''}
                                        poster={props.thumbnailBlob && props.thumbnailBlob.url || ''}
                                        controls>
                                    </video>
                                </div>
                                )
                    }

                    <AlertOnErrors errors={[videoFileSizeError, thumbnailFileSizeError]} />

                    <div>
                        <Row className="no-gutters">
                            <Col xs="auto">
                                <Label className="btn btn-outline-secondary file-upload-button">
                                    {
                                        props.isUploadingVideo ? (
                                            <>
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <> </><span>Uploading...</span>
                                            </>
                                        ) : (
                                                <span>
                                                    {
                                                        Guid.isEmpty(props.model.videoBlobId) ? 'Upload video...' : 'Update video...'
                                                    }
                                                </span>
                                            )
                                    }

                                    <Input type="file" name="files" onChange={onUploadVideo} />
                                </Label>
                            </Col>
                            <Col xs="auto">
                                <Label className="btn btn-outline-secondary file-upload-button">
                                    {
                                        props.isUploadingThumbnail ? (
                                            <>
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <> </><span>Uploading...</span>
                                            </>
                                        ) : (
                                                <span>
                                                    {
                                                        Guid.isEmpty(props.model.thumbnailBlobId) ? 'Upload thumbnail...' : 'Update thumbnail...'
                                                    }
                                                </span>
                                            )
                                    }

                                    <Input type="file" name="files" onChange={onUploadThumbnail} />
                                </Label>
                            </Col>
                        </Row>
                    </div>
                    <FormText>
                        Videos should be in mp4 format and use the standard 16x9 widescreen aspect ratio.  Thumbnails should be in png or jpg format and use the same 16x9 widescreen aspect ratio.
                    </FormText>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="consultantId">Consultant</Label>
                    <ValidatedInput type="select" name="consultantId" value={props.model.consultantId || ''} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors}>
                        <option value="">(Select a consultant)</option>
                        {
                            props.consultants.map(item => (
                                <option key={item.id} value={item.id}>{item.forename} {item.surname} {item.email ? `(${item.email})` : ''}</option>
                            ))
                        }
                    </ValidatedInput>
                </FormGroup>

                <FormGroup>
                    <Label>Description</Label>
                    <ValidatedElasticInput type="textarea" name="description" value={props.model.description} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="tags">Categories</Label>
                    <div className="tag-component">
                        {
                            props.videoTags.filter(item => item.isCategory).map(item => {
                                const selected = !!props.videoVideoTags.models.find(it => it.videoTagId === item.id);

                                return (
                                    <div key={item.id} className={`tag-badge ${selected ? "selected" : ""}`} onClick={() => props.toggleVideoTag(item.id)}>
                                        {item.name}
                                    </div>
                                );
                            })
                        }
                    </div>
                </FormGroup>


                <FormGroup>
                    <Label htmlFor="tags">Audiences</Label>
                    <div className="tag-component">
                        {
                            props.videoTags.filter(item => item.isAudience).map(item => {
                                const selected = !!props.videoVideoTags.models.find(it => it.videoTagId === item.id);

                                return (
                                    <div key={item.id} className={`tag-badge ${selected ? "selected" : ""}`} onClick={() => props.toggleVideoTag(item.id)}>
                                        {item.name}
                                    </div>
                                );
                            })
                        }
                    </div>
                </FormGroup>


                <FormGroup>
                    <Label htmlFor="tags">Additional tags</Label>
                    <div className="tag-component">
                        {
                            props.videoTags.filter(item => !item.isCategory && !item.isAudience).map(item => {
                                const selected = !!props.videoVideoTags.models.find(it => it.videoTagId === item.id);

                                return (
                                    <div key={item.id} className={`tag-badge ${selected ? "selected" : ""}`} onClick={() => props.toggleVideoTag(item.id)}>
                                        {item.name}
                                    </div>
                                );
                            })
                        }
                    </div>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="videoResources">Useful resources</Label>
                    <div>
                        {
                            props.videoResources.models
                                .filter(item => !item.archived)
                                .map(item => (
                                        <VideoResourceItem key={item.id}
                                            model={item}
                                            changeModel={changes => props.videoResources.change(item.id, changes)}
                                            fileBlob={props.videoResourceBlobs.find(it => it.id === item.blobId)}
                                            uploadFile={async files => { await props.uploadVideoResourceFile(item.id, files); }}
                                            remove={() => props.videoResources.change(item.id, { archived: true })}
                                        />
                                        ))
                        }
                    </div>
                    <div>
                        <Button color="primary" outline onClick={() => props.videoResources.addModel({ videoId: props.model && props.model.id || '' })}>
                            Add resource
                        </Button>
                    </div>
                </FormGroup>

                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
