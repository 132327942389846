import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from "react-use-async-callback";
import { Project } from "../../../api/models/Project";
import { ProjectAnalysisResult } from "../../../api/models/ProjectAnalaysisResult";
import { Risk } from "../../../api/models/Risk";
import { Trust } from "../../../api/models/Trust";
import { School } from "../../../api/models/School";
import { Topic } from "../../../api/models/Topic";
import { ProjectTopic } from "../../../api/models/ProjectTopic";
import { ProjectSection } from "../../../api/models/ProjectSection";
import { Section } from "../../../api/models/Section";
import { ProjectQuestionaire } from "../../../api/models/ProjectQuestionaire";
import { AnonymousQuestionResponse } from "../../../api/models/AnonymousQuestionResponse";
import { Question } from "../../../api/models/Question";
import { Impact } from "../../../api/models/Impact";
import { QuestionResponse } from "../../../api/models/QuestionResponse";
import { Questionaire } from "../../../api/models/Questionaire";
import { QuestionChoice } from "../../../api/models/QuestionChoice";
import { User } from "../../../api/models/User";
import { BlobUrl } from "../../../api/models/BlobUrl";
import { ManagedChildModels, useManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { useProjectSettings } from "../../../api/useProjectSettings";
import { ProjectSettings } from "../../../utilities/getProjectSettingsFromQuestionSetName";
import { SchoolEvidence } from "../../../api/models/SchoolEvidence";
import { QuestionResponseSchoolEvidence } from "../../../api/models/QuestionResponseSchoolEvidence";
import { SchoolTagCertificate } from "../../../api/models/SchoolTagCertificate";
import { AwardTag } from "../../../api/models/AwardTag";
import { Strength } from "../../../api/models/Strength";
import { ReportSettingsModel } from "../reportSettings/ReportSettingsModel";
import { standardReportSettings } from "../reportSettings/standardReportSettings";
import { isNullOrUndefined } from "util";
import { CustomReport } from "../../../api/models/CustomReport";
import { AppState } from "../../../store";
import { useSelector } from "react-redux";
import moment from "moment";
import { useApiService } from "../../../api/useApiService";
import { pngDataUrlFromSvg } from "../../../utilities/pngFromSvg";
import { useServices } from "inject-typesafe-react";
import { AlertOnErrors } from "../../shared/AlertOnErrors";
import { useReportGeneratorSpecificReportUi } from "./useReportGeneratorSpecificReportUi";
import FileSaver from "file-saver";
import { generatePdfAsBlob } from "../../../utilities/generatePdfAsBlob";
import { ReportGenerationProgress } from "./ReportGenerationProgress";
import { OnScreenReport } from "./OnScreenReport";
import { AwardFeedbackAlert } from "./awards/AwardFeedbackAlert";
import { PassAwardModal } from "./awards/PassAwardModal";
import { ConditionalFragment } from "react-conditionalfragment";
import { FailAwardModal } from "./awards/FailAwardModal";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ReportBuilderOptions } from "./ReportBuilderOptions";
import { ReportProps } from "./ReportProps";
import { SubscriptionLicense } from "../../../api/models/SubscriptionLicense";
import { isProductTierUnlocked, ProductTier } from "../../../api/models/codeOnly/ProductTeir";
import { PlaceholderReplacementMode } from "../../../utilities/replacePlaceholders";
import { generateDocxAsBlob } from "../../../utilities/generateDocxAsBlob";
import { ImageWithMetadata } from "../executiveSummary/pdf/ImageEnumerator";
import { Subject } from "../../../api/models/Subject";


export interface ResultBySection {
    projectSectionId: string,
    sectionId: string,
    results: Array<ProjectAnalysisResult>,
}

export interface ResultByTopic {
    projectTopicId: string,
    topicId: string,
    results: Array<ProjectAnalysisResult>,
}

export interface ReportGeneratorProps {
    startWithReportBuilder?: boolean,
    readOnly?: boolean,
}

/**
 * Container for the executive summary of a Project.
 * 
 * @param props
 */
export const ReportGenerator = (props: ReportGeneratorProps) => {
    const {
        startWithReportBuilder,
        readOnly
    } = props;

    // Get the details from the route.
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('projectId', '');
    const awardTagId = navigation.getParam('awardTagId', '');
    const reportName = navigation.getParam('reportName', '');
    const customReportId = navigation.getParam('customReportId', '');

    // Get the API methods we need.
    const api = useApiService();
    const loadModel = api.projects.viewModels.executiveSummary();
    const repository = api.projects.repository();
    const projectSectionsRepository = api.projectSections.repository();
    const projectTopicsRepository = api.projectTopics.repository();
    const _markCertificate = api.schoolTagCertificates.actions.markCertificate();
    const loadSectionsAndTopics = api.projects.viewModels.projectSectionsAndTopics();
    const customReportRepository = api.customReports.repository();
    const schoolRepository = api.schools.repository();
    const savedReportsRepository = api.savedReports.repository();

    const blobUploadService = useServices(services => services.blobUploadService());

    // We need the settings for this project so we can cusotmise the output based on the type of project.
    const projectSettings = useProjectSettings(id, awardTagId);

    // State for everything needed in the reports.
    const [model, setModel] = React.useState<Project | undefined>(undefined);
    const [trust, setTrust] = React.useState<Trust | undefined>(undefined);
    const [school, setSchool] = React.useState<School | undefined>(undefined);
    const [results, setResults] = React.useState<Array<ProjectAnalysisResult>>([]);
    const [risks, setRisks] = React.useState<Array<Risk>>([]);
    const [strenths, setStrengths] = React.useState<Array<Strength>>([]);
    const [topics, setTopics] = React.useState<Array<Topic>>([]);
    const [questions, setQuestions] = React.useState<Array<Question>>([]);
    const [questionaires, setQuestionaires] = React.useState<Array<Questionaire>>([]);
    const projectTopics = useManagedChildModels<ProjectTopic>(projectTopicsRepository);
    const [projectQuestionaires, setProjectQuestionaires] = React.useState<Array<ProjectQuestionaire>>([]);
    const [sections, setSections] = React.useState<Array<Section>>([]);
    const projectSections = useManagedChildModels<ProjectSection>(projectSectionsRepository);
    const [impacts, setImpacts] = React.useState<Array<Impact>>([]);
    const [questionResponses, setQuestionResponses] = React.useState<Array<QuestionResponse>>([]);
    const [anonymousQuestionResponses, setAnonymousQuestionResponses] = React.useState<Array<AnonymousQuestionResponse>>([]);
    const [questionChoices, setQuestionChoices] = React.useState<Array<QuestionChoice>>([]);
    const [reviewers, setReviewers] = React.useState<Array<User>>([]);
    const [resultsBySection, setResultsBySection] = React.useState<Array<ResultBySection>>([]);
    const [resultsByTopic, setResultsByTopic] = React.useState<Array<ResultByTopic>>([]);
    const [schoolEvidences, setSchoolEvidences] = React.useState<Array<SchoolEvidence>>([]);
    const [questionResponseSchoolEvidences, setQuestionResponseSchoolEvidences] = React.useState<Array<QuestionResponseSchoolEvidence>>([]);
    const [schoolTagCertificate, setSchoolTagCertificate] = React.useState<SchoolTagCertificate | undefined>(undefined);
    const [awardTag, setAwardTag] = React.useState<AwardTag | undefined>(undefined);
    const [allSections, setAllSections] = React.useState<Array<Section>>([]);
    const [allTopics, setAllTopics] = React.useState<Array<Topic>>([]);
    const [license, setLicense] = React.useState<SubscriptionLicense | undefined>();
    const [subject, setSubject] = React.useState<Subject | undefined>();

    const [blobs, setBlobs] = React.useState<Array<BlobUrl>>([]);
    const [isForSingleSchool, setIsForSingleSchool] = React.useState<boolean>(false);
    const [isFilterDataLoaded, setIsFilterDataLoaded] = React.useState<boolean>(false);
    const [reportBuilderSections, setReportBuilderSections] = React.useState<Array<Section>>([]);
    const [reportBuilderTopics, setReportBuilderTopics] = React.useState<Array<Topic>>([]);
    const [reportBannerBlob, setReportBannerBlob] = React.useState<BlobUrl | undefined | null>();


    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');

    // Settings for the report.
    const [reportSettings, setReportSettings] = React.useState<ReportSettingsModel>(standardReportSettings.summary);
    const changeReportSettings = React.useCallback((changes: Partial<ReportSettingsModel>) => {
        setReportSettings(prevState => ({
            ...prevState,
            ...changes,
        }));
    }, [setReportSettings]);

    // Load the report settings given in the routing.
    const [reportSettingsInitialized, setReportSettingsInitialized] = React.useState<string | undefined>();
    React.useEffect(() => {
        // If we have a custom report in our route don't initialize as we'll initialize once we've loaded the custom report from the server.
        if (customReportId) {
            return;
        }

        // If we have a standard report in our route, initialise ourselves based on it.
        if (reportName && reportSettingsInitialized !== reportName) {
            switch (reportName) {
                case 'breakdown':
                    setReportSettings({ ...standardReportSettings.breakdown });
                    break;
                case 'governorReport':
                    setReportSettings({ ...standardReportSettings.governorReport });
                    break;
                case 'headsReport':
                    setReportSettings({ ...standardReportSettings.headsReport });
                    break;
                case 'sef':
                    setReportSettings({ ...standardReportSettings.sef });
                    break;
                case 'custom':
                    setReportSettings({ ...standardReportSettings.custom });
                    break;
                case 'snapshot':
                    setReportSettings({ ...standardReportSettings.snapshot });
                    break;
                case 'sixtyMinuteSEF':
                    setReportSettings({ ...standardReportSettings.sixtyMinuteSEF });
                    break;
                case 'fullSEF':
                    setReportSettings({ ...standardReportSettings.fullSEF });
                    break;
                case 'awardSummary':
                    setReportSettings({ ...standardReportSettings.awardSummary });
                    break;
                case 'summary':
                    setReportSettings({ ...standardReportSettings.summary });
                    break;
                case 'subjectLeadershipReview':
                    setReportSettings({ ...standardReportSettings.subjectLeadershipReview });
                    break;
                case 'subjectHelpfulQuestions':
                    setReportSettings({ ...standardReportSettings.subjectHelpfulQuestions });
                    break;
                case 'readingReviewEYFSToKS2':
                    setReportSettings({ ...standardReportSettings.readingReviewEYFSToKS2 });
                    break;
                case 'readingReviewKS3ToKS4':
                    setReportSettings({ ...standardReportSettings.readingReviewKS3ToKS4 });
                    break;
                default:
                    setReportSettings({ ...standardReportSettings.sef });
                    break;
            }

            setReportSettingsInitialized(reportName);
        }
    }, [reportName, customReportId, setReportSettings, reportSettingsInitialized, setReportSettingsInitialized]);

    // Get the correct report/options/pdf UIs for the specific base report being used.
    const specificReportUi = useReportGeneratorSpecificReportUi(reportSettings);

    // Manage report builder visibility.
    const [reportBuilderCompleted, setReportBuilderCompleted] = React.useState<boolean | undefined>(startWithReportBuilder ? false : undefined);
    // Show the report builder (works as a toggle back onto when the report is showing).
    const showReportBuilder = React.useCallback(() => {
        setReportBuilderCompleted(false);
        setModel(undefined);
    }, [setReportBuilderCompleted, setModel]);

    // Change the model.
    const changeModel = React.useCallback((changes: Partial<Project>) => {
        setModel(prevState => ({
            ...(prevState as Project),
            ...changes
        }));
    }, [setModel]);

    // Save changes to model.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        // NOTE we don't use any validation here.  That is by choice not by mistake.
        repository.save(model.id, model);

        return true;
    }, [model, repository]);

    // Mark the certificate as passed or failed.
    const [markCertificate, { isExecuting: isMarkingCertificate, errors: markCertificateErrors }] = useAsyncCallback(async (isPass: boolean, certificateNumber: string, feedback: string) => {
        if (!model || !awardTag) {
            return;
        }

        const certificate = await _markCertificate(model.id, awardTag.id, awardTag.name, awardTag.awardingOrganisationName, isPass, certificateNumber, feedback);
        if (certificate) {
            setSchoolTagCertificate(certificate);
        }
    }, [_markCertificate, setSchoolTagCertificate, model]);

    // Custom report (saving of settings into a report).
    const user = useSelector((store: AppState) => store.user.identity);
    const [customReport, setCustomReport] = React.useState<CustomReport | undefined>(undefined);

    // Save a custom report.
    const [saveCustomReport, { isExecuting: isSavingCustomReport, errors: saveCustomReportErrors }] = useAsyncCallback(async (): Promise<void> => {
        let report = customReport;
        let isNew = false;
        if (!report) {
            report = await customReportRepository.create();
            report.schoolId = school && school.id || null;
            report.trustId = school && school.trustId || '';
            isNew = true;
        }

        // Update details of the save.
        report.name = reportSettings.reportName;
        report.userId = user && user.userId || '';
        report.savedDate = moment().toISOString();

        // Preserve the report settings.
        const newReportSettings = {
            ...reportSettings,
            customReportId: report.id,
        };
        report.reportSettings = JSON.stringify(newReportSettings);

        // Save to the database.
        await customReportRepository.save(report.id, report, isNew);

        // Update state.
        setReportSettings(newReportSettings);
        setCustomReport(report);
    }, [user, customReportRepository, customReport, setCustomReport, reportSettings, setReportSettings, school]);

    // Delete a custom report.
    const [deleteCustomReport] = useAsyncCallback(async (): Promise<void> => {
        if (!customReport) {
            return;
        }

        let report = customReport;
        report.archived = true;

        // Save to the database.
        await customReportRepository.save(report.id, report, false);

        // Navigate off the deleted item.
        navigation.goBack();
    }, [customReportRepository, customReport, navigation]);

    // Load filter data
    const [loadFilterData] = useAsyncCallback(async (): Promise<boolean> => {
        const sectionsAndTopicsResult = await loadSectionsAndTopics(id, awardTagId);
        setReportBuilderSections(sectionsAndTopicsResult.sections);
        setReportBuilderTopics(sectionsAndTopicsResult.topics);
        setSchool(sectionsAndTopicsResult.school);

        let customReportResult: CustomReport | undefined = undefined;
        if (customReportId) {
            customReportResult = await customReportRepository.find(customReportId);
            setCustomReport(customReportResult);
        }

        if (customReportResult) {
            const savedSettings = JSON.parse(customReportResult.reportSettings) || {};

            // Update the report settings to match the saved custom report.
            setReportSettings(prevState => ({
                ...prevState,
                ...savedSettings
            }));
        } else {
            // Update the report settings to include all secitons and topics if the breakdown is exapnded.
            setReportSettings(prevState => ({
                ...prevState,

                sectionIds: prevState.sectionIds.length ? prevState.sectionIds : sectionsAndTopicsResult.sections.map((item: Section) => item.originKey),
                topicIds: prevState.topicIds.length ? prevState.topicIds : sectionsAndTopicsResult.topics.map((item: Topic) => item.originKey)
            }));
        }

        setIsFilterDataLoaded(true);
        return true;
    }, [setReportBuilderSections, setReportBuilderTopics, setIsFilterDataLoaded, setReportSettings, reportBuilderCompleted,
        customReportId, setCustomReport, setSchool, schoolRepository, customReportRepository, customReport, loadSectionsAndTopics,
    ]);

    // Load report data.
    const [loadReportData, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        // Load the data for the actual report.
        let result = await loadModel(id || 'defaults', awardTagId, JSON.stringify(reportSettings), reportSettings.breakdown);
        setTrust(result.trust);
        setSchool(result.school);
        setResults(result.results);
        setRisks(result.risks);
        setStrengths(result.strengths);
        setTopics(result.topics);
        setQuestions(result.questions);
        setQuestionaires(result.questionaires);
        projectTopics.setModels(result.projectTopics);
        setProjectQuestionaires(result.projectQuestionaires);
        setSections(result.sections);
        projectSections.setModels(result.projectSections);
        setImpacts(result.impacts);
        setQuestionResponses(result.questionResponses);
        setAnonymousQuestionResponses(result.anonymousQuestionResponses);
        setQuestionChoices(result.questionChoices);
        setReviewers(result.reviewers);
        setResultsBySection(result.resultsBySection);
        setResultsByTopic(result.resultsByTopic);
        setSchoolEvidences(result.schoolEvidences);
        setQuestionResponseSchoolEvidences(result.questionResponseSchoolEvidences);
        setBlobs(result.blobs);
        setAwardTag(result.awardTag);
        setSchoolTagCertificate(result.schoolTagCertificate);
        setAllSections(result.allSections);
        setAllTopics(result.allTopics);
        setLicense(result.license);
        setReportBannerBlob(result.reportBannerBlob);
        setSubject(result.subject);

        let subscription = result.subscription;
        setIsForSingleSchool(subscription && subscription.isForSingleSchool ? true : false);

        setPlaceholderReplacementMode(result.placeholderReplacementMode);

        // If we have an awardTag update the report name to match.
        if (result.awardTag) {
            changeReportSettings({
                reportName: result.awardTag.name,
            });
        }

        setModel(result.model);
        return true;
    }, [id, loadModel, setModel, setSchool, setTrust, setResults, setRisks, setTopics, setQuestions, setQuestionaires, projectTopics,
        setProjectQuestionaires, setSections, projectSections, setImpacts, setQuestionResponses, setAnonymousQuestionResponses,
        setQuestionChoices, setResultsBySection, setResultsByTopic, setBlobs, setIsForSingleSchool,
        setSchoolEvidences, setQuestionResponseSchoolEvidences, awardTagId, setSchoolTagCertificate, setAwardTag, setStrengths,
        setAllSections, reportSettings,
        customReportId, setCustomReport,
        setLicense, setPlaceholderReplacementMode, changeReportSettings, setSubject,
    ]);

    // Generate a dataURL version of the blob so it can be embedded in the PDFs and word versions.
    const [reportBannerBlobDataUrl, setReportBannerBlobDataUrl] = React.useState<ImageWithMetadata | undefined | null>();
    React.useEffect(() => {
        (async () => {
            // If we don't have a banner then leave it undefined.
            if (!reportBannerBlob || !reportBannerBlob.url) {
                setReportBannerBlobDataUrl(undefined);
                return;
            }

            // Download the banner and turn it into a data URL.
            const blob = await fetch(reportBannerBlob.url).then(r => r.blob());
            const dataUrl = await new Promise<string>(resolve => {
                let reader = new FileReader();
                reader.onload = () => resolve(reader.result as any);
                reader.readAsDataURL(blob);
            });

            let image = new Image();

            // Once the image has loaded we want set the data url with metadata for use in the reports.
            image.onload = () => {
                setReportBannerBlobDataUrl({
                    dataUrl: dataUrl,
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                    aspectRatio: (image.naturalWidth * 1.0) / (image.naturalHeight * 1.0),
                });
            };

            image.src = dataUrl;
        })();
    }, [setReportBannerBlobDataUrl, reportBannerBlob]);

    // Load the filter data if we're showing the filter.
    React.useEffect(() => {
        if (!isFilterDataLoaded) {
            loadFilterData();
        }
    }, [isFilterDataLoaded, loadFilterData]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        // If we haven't loaded the filter data, don't load the report data yet.
        if (!isFilterDataLoaded) {
            return;
        }

        // If we're still showing the report builder, don't try load the report data.
        if (!isNullOrUndefined(reportBuilderCompleted) && !reportBuilderCompleted) {
            return;
        }

        // Load the report data.
        if ((!model || (id !== model.id) || (awardTag && awardTagId != awardTag.id)) && !isLoading && !loadingErrors) {
            loadReportData();
        }
    }, [model, isLoading, loadingErrors, loadReportData, id, awardTag, awardTagId, reportBuilderCompleted, isFilterDataLoaded]);

    // We need consistant props for the PDF and for the on screen report, so generate those into an object now.
    const reportProps = {
            model: model,
            trust: trust, school: school,
            results: results,
            risks: risks,
            strengths: strenths,
            topics: topics,
            allTopics: allTopics,
            questions: questions,
            questionaires: questionaires,
            projectTopics: projectTopics,
            projectQuestionaires: projectQuestionaires,
            sections: sections,
            allSections: allSections,
            projectSections: projectSections,
            impacts: impacts,
            questionResponses: questionResponses,
            anonymousQuestionResponses: anonymousQuestionResponses,
            questionChoices: questionChoices,
            reviewers: reviewers,
            resultsBySection: resultsBySection,
            resultsByTopic: resultsByTopic,
            schoolEvidences: schoolEvidences,
            questionResponseSchoolEvidences: questionResponseSchoolEvidences,
            blobs: blobs,
            projectSettings: projectSettings,
            isForSingleSchool: isForSingleSchool,
            awardTag: awardTag,
            readOnly: props.readOnly,
            schoolTagCertificate: schoolTagCertificate,
            markCertificate: markCertificate, isMarkingCertificate: isMarkingCertificate, markCertificateErrors: markCertificateErrors,
            reportSettings: reportSettings,
            subject: subject,

            // Needed for on screen in place editing.
            changeModel: changeModel,
            save: save,
            isSaving: isSaving,
            savingErrors: savingErrors,

            placeholderReplacementMode: placeholderReplacementMode,
            reportBannerBlobDataUrl: reportBannerBlobDataUrl,
        } as ReportProps;

    // Prepare the chart images into the format needed for the PDF printout.
    const prepareChartImages = React.useCallback(async () => {
        let chartSvgs = document.getElementsByClassName('apexcharts-svg');

        let ret: Array<ImageWithMetadata> = [];
        for (let i = 0; i < chartSvgs.length; ++i) {
            let svg = chartSvgs[i];
            let pngDataUrl = await pngDataUrlFromSvg(svg);
            ret.push({
                dataUrl: pngDataUrl,
                width: svg.clientWidth,
                height: svg.clientHeight,
                aspectRatio: (svg.clientWidth * 1.0) / (svg.clientHeight * 1.0),
            });
        }

        return ret;
    }, []);

    // Saving reports as they are printed or downloaded.
    const reportFilename = React.useMemo(() => `${reportSettings.reportName} - ${school && school.name || 'School'} - ${moment().format('YYYY-MM-DD HHmm')}.pdf`, [reportSettings.reportName, school]);
    const reportFilenameDocx = React.useMemo(() => reportFilename.replace('.pdf', '.docx'), [reportFilename]);
    const saveReport = React.useCallback(async (blob: Blob, filename: string, fileFormat: string): Promise<void> => {
        // If we don't know which school we're in, do nothing.
        if (!school) {
            return;
        }

        // Upload the blob as to our blobs table.
        const blobUrl = await blobUploadService.uploadBlobObject(filename, blob);
        if (!blobUrl) {
            return;
        }

        // Save a link to the blob as a saved report.
        let savedReport = await savedReportsRepository.create();
        savedReport.blobId = blobUrl.id;
        savedReport.userId = user && user.userId || '';
        savedReport.schoolId = school.id;
        savedReport.trustId = school.trustId;
        savedReport.customReportId = reportSettings.customReportId;
        savedReport.reportSettings = JSON.stringify(reportSettings);
        savedReport.fileFormat = fileFormat;
        await savedReportsRepository.save(savedReport.id, savedReport, true);
    }, [blobUploadService, savedReportsRepository, user, school]);

    // Generate a PDF file as a blob.
    const generatePdf = React.useCallback(async (): Promise<Blob> => {
        let chartImages = await prepareChartImages();

        let blob = await generatePdfAsBlob(specificReportUi.PdfReport({ ...reportProps, chartImages: chartImages } as any /* TODO */));

        // Save the blob as a saved report to the database.
        saveReport(blob, reportFilename, 'pdf');

        return blob;
    }, [props, prepareChartImages, specificReportUi, reportProps, reportFilename]);

    // Generate the PDF and open it in a new window for printing.
    const [generateAndOpenPdf, { isExecuting: isGeneratingPdfForOpen }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        let url = URL.createObjectURL(blob);
        window.open(url);
    }, [generatePdf]);

    // Generate the PDF and download it.
    const [generateAndDownloadPdf, { isExecuting: isGeneratingPdfForDownload }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        FileSaver.saveAs(blob, reportFilename);
    }, [generatePdf, reportFilename]);

    // Generate a word docx file as a blob.
    const generateDocx = React.useCallback(async (): Promise<Blob> => {
        if (!specificReportUi.DocxReport) {
            return new Blob();
        }

        let chartImages = await prepareChartImages();

        const blob = await generateDocxAsBlob(specificReportUi.DocxReport({ ...reportProps, chartImages: chartImages } as any /* TODO */));

        // Save the blob as a saved report to the database.
        saveReport(blob, reportFilenameDocx, 'docx');

        return blob;
    }, [props, prepareChartImages, specificReportUi, reportProps, reportFilenameDocx]);

    // Generate the PDF and download it.
    const [generateAndDownloadDocx, { isExecuting: isGeneratingDocxForDownload }] = useAsyncCallback(async () => {
        let blob = await generateDocx();
        FileSaver.saveAs(blob, reportFilenameDocx);
    }, [generatePdf, reportFilenameDocx]);

    // Pass modal for awards and inspections.
    const [awardPassModalIsOpen, setAwardPassModalIsOpen] = React.useState<boolean>(false);
    const toggleAwardPassModal = React.useCallback(() => setAwardPassModalIsOpen(prevState => !prevState), [setAwardPassModalIsOpen]);
    const [saveAwardPass, { isExecuting: isSavingAwardPass, errors: saveAwardPassErrors }] = useAsyncCallback(async (certificateNumber: string, feedback: string) => {
        await markCertificate(true, certificateNumber, feedback);

        toggleAwardPassModal();
    }, [markCertificate, toggleAwardPassModal]);

    // Fail modal for awards and inspections.
    const [awardFailModalIsOpen, setAwardFailModalIsOpen] = React.useState<boolean>(false);
    const toggleAwardFailModal = React.useCallback(() => setAwardFailModalIsOpen(prevState => !prevState), [setAwardFailModalIsOpen]);
    const [saveAwardFail, { isExecuting: isSavingAwardFail, errors: saveAwardFailErrors }] = useAsyncCallback(async (feedback: string) => {
        await markCertificate(false, '', feedback);

        toggleAwardFailModal();
    }, [markCertificate, toggleAwardFailModal]);

    // Generate a list of errors that may need to be displayed.
    const errors = React.useMemo(() => [loadingErrors, savingErrors, saveCustomReportErrors], [loadingErrors, savingErrors, saveCustomReportErrors]);

    // If we are using the report builder, show a UI built around customising the report initially, and only show the actual report once its settings have been
    // confirmed.
    if (!isNullOrUndefined(reportBuilderCompleted) && !reportBuilderCompleted) {
        return (
            <ReportBuilderOptions
                reportSettings={reportSettings}
                changeReportSettings={changeReportSettings}
                projectSettings={projectSettings}
                reportBuilderComplete={reportBuilderCompleted}
                setReportBuilderCompleted={setReportBuilderCompleted}
                saveCustomReport={saveCustomReport} isSavingCustomReport={isSavingCustomReport}
                deleteCustomReport={deleteCustomReport}
                reportBuilderSections={reportBuilderSections}
                reportBuilderTopics={reportBuilderTopics}
                errors={errors}
                specificReportUi={specificReportUi}
                />
        );
    }

    // If we are generating the report, show a helpful message while we load so the user knows to wait.
    if (!model) {
        return (
            <ReportGenerationProgress
                reportSettings={reportSettings}
                errors={errors}
                />
            );
    }

    // Show the actual report on screen, with options to print or download.
    return (
        <>
            <AwardFeedbackAlert awardTag={awardTag} schoolTagCertificate={schoolTagCertificate} />

            <div className="main-container">
                <div className="main-heading">
                    <h1>
                        {
                            school ? school.name
                                : trust ? trust.name
                                    : ''
                        } <small className="text-muted">Evaluate-Ed {
                            awardTag ? `${awardTag.name} summary`
                                : subject && subject.name && reportSettings.reportName ? `${subject.name} ${reportSettings.reportName}`
                                    : reportSettings.reportName ? reportSettings.reportName
                                        : projectSettings.isSelfEvaluation ? 'self evaluation summary'
                                            : projectSettings.isDueDiligence ? 'executive summary' : 'project summary'
                        }</small>
                    </h1>
                </div>

                <AlertOnErrors errors={errors} />

                <div className="toolbar-top">
                    <ConditionalFragment showIf={!!license && !license.isTrial && !!school && isProductTierUnlocked(ProductTier.FullReview, school.tier as ProductTier)}>
                        <Button color="primary" outline onClick={() => showReportBuilder()}>
                            <FontAwesomeIcon icon="cogs" /> Customise
                        </Button>
                    </ConditionalFragment>
                    <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} isExecuting={isGeneratingPdfForOpen}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Generating PDF...</>}>
                        <FontAwesomeIcon icon="print" /> Print
                    </ButtonAsync>
                    <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} isExecuting={isGeneratingPdfForDownload}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Generating PDF...</>}>
                        <FontAwesomeIcon icon="file-download" /> Download PDF
                    </ButtonAsync>

                    {
                        // If this report supports word format, give that option too.
                        !!specificReportUi.DocxReport ? (
                            <ButtonAsync color="primary" outline onClick={generateAndDownloadDocx} isExecuting={isGeneratingDocxForDownload}
                                executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Generating Word docx...</>}>
                                <FontAwesomeIcon icon="file-download" /> Download Word
                            </ButtonAsync>
                        ): null
                    }
                    
                    <ConditionalFragment showIf={!!awardTag && awardTag.canSubmit && !!readOnly /* Means we are able to award for this school */}>
                        <Button color="success" onClick={() => toggleAwardPassModal()}>
                            Pass {awardTag && awardTag.name}
                        </Button>
                        <Button color="danger" onClick={() => toggleAwardFailModal()}>
                            Fail {awardTag && awardTag.name}
                        </Button>
                    </ConditionalFragment>
                </div>

                <OnScreenReport
                    {...reportProps}
                    specificReportUi={specificReportUi}
                    />

            </div>

            <ConditionalFragment showIf={awardPassModalIsOpen}>
                <PassAwardModal model={model}
                    awardTag={awardTag as AwardTag}
                    school={school}
                    save={saveAwardPass} isSaving={isSavingAwardPass} savingErrors={saveAwardPassErrors}
                    cancel={toggleAwardPassModal}
                    initialCertificateNumber={schoolTagCertificate && schoolTagCertificate.certificateNumber || ''}
                    initialFeedback={schoolTagCertificate && schoolTagCertificate.feedback || ''}
                />
            </ConditionalFragment>

            <ConditionalFragment showIf={awardFailModalIsOpen}>
                <FailAwardModal model={model}
                    awardTag={awardTag as AwardTag}
                    school={school}
                    save={saveAwardFail} isSaving={isSavingAwardFail} savingErrors={saveAwardFailErrors}
                    cancel={toggleAwardFailModal}
                    initialFeedback={schoolTagCertificate && schoolTagCertificate.feedback || ''}
                />
            </ConditionalFragment>
        </>
    );
};
