import * as React from "react";
import { ReportProps } from "../reportGenerator/ReportProps";
import { AlertOnErrors } from "../../shared/AlertOnErrors";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ConditionalFragment } from "react-conditionalfragment";
import { OverviewUi } from "./OverviewUi";
import { StrengthsUi } from "./StrengthsUi";
import { QuestionBreakdownUi } from "./QuestionBreakdownUi";
import { RisksUi } from "./RisksUi";
import { RecommendationsUi } from "./RecommendationsUi";
import { ConclusionUi } from "./ConclusionUi";
import { IntroductionUi } from "./IntroductionUi";

/**
 * ExecutiveSummary on screen report.
 */
export const ExecutiveSummaryScreenReport = (props: ReportProps) => {
    const {
        reportSettings,
        projectSettings,
        awardTag,
    } = props;

    return (
        <>
            {/* Show school logo/banner if it has one */}
            {
                !!props.reportBannerBlobDataUrl ? (
                    <img className="img-fluid" style={{ minWidth: '100%', }} src={props.reportBannerBlobDataUrl.dataUrl} alt="School logo or banner" />
                ) : null
            }

            <IntroductionUi {...props} displayIntroduction={reportSettings.introduction} displayReviewers={reportSettings.reviewers} displaySchoolBackground={reportSettings.schoolBackground} />
            <ConditionalFragment showIf={reportSettings.overallResults}>
                <OverviewUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={reportSettings.areasOfStrength}>
                <StrengthsUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={reportSettings.breakdown}>
                <QuestionBreakdownUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={projectSettings.allowRisks && reportSettings.areasForDevelopment}>
                <RisksUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={projectSettings.isSelfEvaluation && (!awardTag || !awardTag.hideTargets) && reportSettings.areasForDevelopment}>
                <RecommendationsUi {...props} />
            </ConditionalFragment>
            <ConditionalFragment showIf={reportSettings.conclusion}>
                <ConclusionUi {...props} />
            </ConditionalFragment>
        </>
    );
};