import * as React from 'react';
import { DashboardUiProps } from '../DashboardContainer';
import { Card, Table, CardHeader, CardBody, Button } from 'reactstrap';
import { TimelineComparisonTable } from './TimelineComparisonTable';
import { Guid } from 'guid-string';

export interface TimelineComparisonCardProps extends DashboardUiProps {
}

export const TimelineComparisonCard = (props: TimelineComparisonCardProps) => {
    if (!props.timelineResults || !props.timelineResults.length) {
        return (<></>);
    }

    return (
        <Card>
            <CardHeader>
                <h6>
                    Termly breakdown
                </h6>
            </CardHeader>
            
            <CardBody>
                <TimelineComparisonTable {...props} />                
            </CardBody>
        </Card>
    );
};

