import * as React from "react";
import { ValidationState } from "pojo-validator";
import { ContainerComponentProps } from "react-withcontainer";
import { AuthenticationService } from "../../services/AuthenticationService";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { PasswordReset } from "../../api/models/PasswordReset";
import { useValidatorCallback } from "pojo-validator-react";
import { useAsyncCallback } from "react-use-async-callback";
import { SaveOnlyEditUiPropsBase } from "../containers/common/SaveOnlyEditUiPropsBase";
import { useUniversalNavigation } from "react-universal-navigation";


export interface PasswordResetContainerProps extends ContainerComponentProps<PasswordResetUiProps> {
    authenticationService: AuthenticationService
}

export interface PasswordResetUiProps extends SaveOnlyEditUiPropsBase<PasswordReset> {
    saveSuccessful: boolean,
    isRedirect?: boolean
}

/**
 * Container that lets us start the password reset process that is continued by ResetPasswordContainer after email validation.
 * @param props
 */
export const _PasswordResetContainer = (props: PasswordResetContainerProps) => {
    let { component, authenticationService, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const isRedirect = navigation.getParam('isRedirect', '')? true: false;
    
    const [model, setModel] = React.useState<PasswordReset>({ user: navigation.getParam('email', '') });

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<PasswordReset>) => {
        setModel(prevState => ({
            ...prevState,
            ...changes
        }));
    }, [setModel]);

    // Validate the model.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>): void => {
        if (!fieldsToCheck || fieldsToCheck.includes('user')) {
            validation.singleCheck('user', () => !model.user, 'Email is required');
        }
    }, [model]);

    // Save the model.
    const [saveSuccessful, setSaveSuccessful] = React.useState<boolean>(false);
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        setSaveSuccessful(false);

        // Must be valid before we save it.
        if (!validate()) {
            return false;
        }

        // Send the password reset email.
        let ok = await authenticationService.sendPasswordReset(model.user);
        setSaveSuccessful(ok);
        return ok;
    }, [model, validate, authenticationService]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors} saveSuccessful={saveSuccessful}
            isRedirect={isRedirect}
        />
    );
};

export const PasswordResetContainer = withServiceProps<PasswordResetContainerProps, AppServicesCore>(services => ({
    authenticationService: services.authenticationService()
}))(_PasswordResetContainer);
