import * as React from 'react';
import { ContainerComponentProps } from "react-withcontainer";
import { EditUiPropsBase } from "../containers/common/EditUiPropsBase";
import { Video } from "../../api/models/Video";
import { Repository } from "pojo-repository";
import { useAsyncCallback } from 'react-use-async-callback';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidationState } from 'pojo-validator';
import { withServiceProps } from 'inject-typesafe-react';
import { AppServicesCore } from '../../configure/configureServicesCore';
import { Guid } from 'guid-string';
import { Consultant } from '../../api/models/Consultant';
import { ManagedChildModels, useManagedChildModels } from '../shared/hooks/useManagedChildModels';
import { VideoVideoTag } from '../../api/models/VideoVideoTag';
import { VideoTag } from '../../api/models/VideoTag';
import { BlobUrl } from '../../api/models/BlobUrl';
import { BlobUploadService } from '../../services/BlobUploadService';
import { VideoResource } from '../../api/models/VideoResource';


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<Video>,
    loadViewModel: (id: string) => Promise<any>,
    videoVideoTagsRepository: Repository<VideoVideoTag>,
    videoResourcesRepository: Repository<VideoResource>,

    blobUploadService: BlobUploadService,
}

export interface EditUiProps extends EditUiPropsBase<Video> {
    model: Video | undefined,
    save: () => Promise<boolean>,
    isSaving: boolean,

    consultants: Array<Consultant>,
    videoTags: Array<VideoTag>,
    videoVideoTags: ManagedChildModels<VideoVideoTag>,
    toggleVideoTag: (videoTagId: string) => void,

    videoBlob: BlobUrl | undefined | null,
    uploadVideo: (files: FileList) => Promise<BlobUrl | null>,
    isUploadingVideo: boolean,
    uploadVideoErrors: any,

    thumbnailBlob: BlobUrl | undefined | null,
    uploadThumbnail: (files: FileList) => Promise<BlobUrl | null>,
    isUploadingThumbnail: boolean,
    uploadThumbnailErrors: any,

    videoResources: ManagedChildModels<VideoResource>,
    videoResourceBlobs: Array<BlobUrl>,
    uploadVideoResourceFile: (id: string, files: FileList) => Promise<BlobUrl | null>,
}

export const _EditContainer = (props: EditContainerProps) => {
    const { component, repository, loadViewModel, videoVideoTagsRepository, blobUploadService, videoResourcesRepository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam("id", "");

    const [model, setModel] = React.useState<Video | undefined>();
    const [consultants, setConsultants] = React.useState<Array<Consultant>>([]);
    const [videoTags, setVideoTags] = React.useState<Array<VideoTag>>([]);
    const videoVideoTags = useManagedChildModels<VideoVideoTag>(videoVideoTagsRepository);
    const [videoBlob, setVideoBlob] = React.useState<BlobUrl | undefined | null>();
    const [thumbnailBlob, setThumbnailBlob] = React.useState<BlobUrl | undefined | null>();
    const videoResources = useManagedChildModels<VideoResource>(videoResourcesRepository);
    const [videoResourceBlobs, setVideoResourceBlobs] = React.useState<Array<BlobUrl>>([]);


    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        const result = await loadViewModel(id);
        setConsultants(result.consultants);
        setVideoTags(result.videoTags);
        videoVideoTags.setModels(result.videoVideoTags);
        setVideoBlob(result.videoBlob);
        setThumbnailBlob(result.thumbnailBlob);
        videoResources.setModels(result.videoResources);
        setVideoResourceBlobs(result.videoResourceBlobs);
        setModel(result.model);
        return true;
    }, [setModel, loadViewModel, setConsultants, setVideoTags, videoVideoTags, setVideoBlob, setThumbnailBlob, videoResources, setVideoResourceBlobs]);

    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, load, isLoading, loadingErrors]);

    const changeModel = React.useCallback((changes: Partial<Video>) => {
        if (!model) {
            return;
        }

        setModel({
            ...model,
            ...changes
        });
    }, [model, setModel]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('consultantId')) {
            validation.singleCheck('consultantId', () => Guid.isEmpty(model.consultantId), 'Consultant is required');
        }
    }, [model]);

    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        await repository.save(model.id, model);

        await videoVideoTags.save();
        await videoResources.save();
        return true;
    }, [model, repository, id, validate, videoVideoTags, videoResources]);

    // Toggle a tag associated with this video.
    const toggleVideoTag = React.useCallback((videoTagId: string) => {
        if (!model) {
            return;
        }

        const existing = videoVideoTags.models.find(it => it.videoTagId === videoTagId);
        if (existing) {
            videoVideoTags.removeModel(existing.id);
        } else {
            videoVideoTags.addModel({
                id: Guid.newGuid(),
                videoId: model.id,
                videoTagId: videoTagId,
            });
        }
    }, [videoVideoTags, model]);

    // Upload a file.
    const uploadFile = React.useCallback(async (files: FileList) => {
        if (!files) {
            return null;
        }

        if (!model) {
            return null;
        }

        // Do the upload.
        const blob = await blobUploadService.upload(files);
        return blob;
    }, [blobUploadService, model]);

    // Upload the video.
    const [uploadVideo, { isExecuting: isUploadingVideo, errors: uploadVideoErrors }] = useAsyncCallback(async (files: FileList) => {
        const blob = await uploadFile(files);
        if (!blob) {
            return null;
        }

        setVideoBlob(blob);
        changeModel({ videoBlobId: blob.id });

        return blob;
    }, [uploadFile, changeModel, setVideoBlob]);

    // Upload the thumbnail.
    const [uploadThumbnail, { isExecuting: isUploadingThumbnail, errors: uploadThumbnailErrors }] = useAsyncCallback(async (files: FileList) => {
        const blob = await uploadFile(files);
        if (!blob) {
            return null;
        }

        setThumbnailBlob(blob);
        changeModel({ thumbnailBlobId: blob.id });

        return blob;
    }, [uploadFile, changeModel, setThumbnailBlob]);

    // Upload a file for a VideoResource.
    const [uploadVideoResourceFile /*, isUploadingVideoResourceFile, uploadVideoResourceFileErrors */] = useAsyncCallback(async (id: string, files: FileList) => {
        const blob = await uploadFile(files);
        if (!blob) {
            return null;
        }

        setVideoResourceBlobs(prevState => [...prevState, blob]);
        videoResources.change(id, { blobId: blob.id });

        return blob;
    }, [uploadFile, setVideoResourceBlobs, videoResources]);


    const Component = component;

    return <Component {...rest}
        load={load} isLoading={isLoading} loadingErrors={loadingErrors}
        isCreate={id === ''}
        save={save} isSaving={isSaving} savingErrors={savingErrors}
        model={model} changeModel={changeModel}
        validate={validate} validationErrors={validationErrors}
        consultants={consultants} videoTags={videoTags}
        videoVideoTags={videoVideoTags} toggleVideoTag={toggleVideoTag}
        videoBlob={videoBlob} uploadVideo={uploadVideo} isUploadingVideo={isUploadingVideo} uploadVideoErrors={uploadVideoErrors}
        thumbnailBlob={thumbnailBlob} uploadThumbnail={uploadThumbnail} isUploadingThumbnail={isUploadingThumbnail} uploadThumbnailErrors={uploadThumbnailErrors}
        videoResources={videoResources} videoResourceBlobs={videoResourceBlobs} uploadVideoResourceFile={uploadVideoResourceFile}
    />
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.videos.repository(),
    loadViewModel: services.api.videos.viewModels.edit(),
    videoVideoTagsRepository: services.api.videoVideoTags.repository(),
    blobUploadService: services.blobUploadService(),
    videoResourcesRepository: services.api.videoResources.repository(),
}))(_EditContainer);