import * as React from 'react';
import { Input, InputGroup, InputGroupAddon, Button, InputProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SearchAsYouTypeInputProps extends InputProps {
    onSearch: { (searchText: string): void }
}

/**
 * Input that is hooked up for searching as the user types, by introducing an appropriate delay before raising the onSearch event.
 */
export const SearchAsYouTypeInput = (props: SearchAsYouTypeInputProps) => {
    const { type, onSearch, onChange, value, ...rest } = props;

    const [theValue, setValue] = React.useState(value ? value : '');
    const searchChangedTimerId = React.useRef<any>();

    const searchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        var searchText = event.currentTarget.value;

        setValue(searchText);

        let delay = 400;
        if (searchChangedTimerId.current) {
            clearTimeout(searchChangedTimerId.current);
        }

        searchChangedTimerId.current = setTimeout(() => {
            props.onSearch(searchText);
        }, delay);

        // If we have a normal onChange event trigger it too.
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <InputGroup className="search-as-you-type-input">
            <Input type={type || 'search'} value={theValue} onChange={searchChanged} {...rest} />
            <InputGroupAddon addonType="append">
                <Button>
                    <FontAwesomeIcon icon="search" />
                    <span className="sr-only">Search</span>
                </Button>
            </InputGroupAddon>
        </InputGroup>
    );
};