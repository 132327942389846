import * as React from 'react';
import { Container } from 'reactstrap';
import { AppBreadcrumb, AppFooter, AppHeader, AppSidebar } from '@coreui/react';
import { Header } from '../defaultLayout/Header';
import { Footer } from '../defaultLayout/Footer';

import { Sidebar } from './Sidebar';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { breadcrumbRoutes } from '../../../configure/routeConfig';

export interface LayoutProps {
    appRoutes: Array<any>,
    children: any
}

export const Layout = (props: LayoutProps) => {

    return (
        <div className="app">
            <AppHeader fixed>
                <Header />
            </AppHeader>
            <div className="app-body">
                <AppSidebar fixed display="lg">
                    <Sidebar />
                </AppSidebar>
                <main className="main">
                    <AppBreadcrumb appRoutes={breadcrumbRoutes} />
                    <React.Suspense fallback={<LoadingIndicator />}>
                        <Container fluid>
                            {props.children}
                        </Container>
                    </React.Suspense>
                </main>
            </div>
            <AppFooter>
                <Footer />
            </AppFooter>
        </div>
    );
};
