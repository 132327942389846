import * as React from 'react';
import { RAGProjectSectionUi } from './RAGProjectSectionUi';
import { ReportProps } from '../reportGenerator/ReportProps';
import { RAGProjectWillDoUi } from './RAGProjectWillDoUi';


/**
 * Breakdown that shows a section by section and topic by topic breakdown of areas of stength vs recommendations.
 */
export const RAGBreakdownUi = (props: ReportProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            {
                props.projectSections.models.map(item => (
                    <RAGProjectSectionUi key={item.id} projectSectionId={item.id} {...props} />
                    ))
            }
        </div>        
    );
};
