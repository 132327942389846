import * as React from 'react';
import { Nav, NavItem, NavLink, Button } from 'reactstrap';
import { UserNav } from '../defaultLayout/UserNav';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

export interface HeaderProps {
}

export const Header = (props: HeaderProps) => {
    return (
        <>
            <Link to="/">
                <div className="navbar-brand">
                    <div className="navbar-brand-full">
                    </div>
                    <div className="navbar-brand-minimized">
                    </div>
                </div>
            </Link>

            <Nav className="admin-nav ml-auto" navbar>
                {/*<NavItem>*/}
                {/*    <a className="nav-link" href="https://evaluate-ed.com/">*/}
                {/*        What is Evaluate-Ed?*/}
                {/*    </a>*/}
                {/*</NavItem>*/}
                <NavItem>
                    <Button color="secondary">
                        <a className="nav-link" href="https://www.evaluate-ed.com/free-trial/" >
                            Start free trial for your school
                        </a>
                    </Button>
                </NavItem>
            </Nav>
            <Nav className="signin-nav" navbar>
                <UserNav />
            </Nav>
        </>
    );
};
