import * as React from 'react';
import moment from 'moment';

export interface PlainTextWithBrsProps {
    text: string
}

/**
 * Component that renders the plain text provided, split on newlines with <br /> inbetween, but without treating any input text as html, which could potentially be unsafe.
 * @param props
 */
export const PlainTextWithBrs = (props: PlainTextWithBrsProps) => {
    const lines = React.useMemo((): Array<string> => {
        if (!props.text) {
            return [];
        }

        return props.text.split('\n');
    }, [props.text]);

    return (
        <>
            {
                lines.map((item, index) => {
                    if (index === lines.length - 1) {
                        return (<React.Fragment key={index}>{item}</React.Fragment>);
                    }

                    return (<React.Fragment key={index}>{item}<br /></React.Fragment>)
                })
            }
        </>
    );
};
