import * as React from "react";
import { User } from "../../../api/models/User";
import { Subscription } from '../../../api/models/Subscription';
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { SubscriptionLicense } from "../../../api/models/SubscriptionLicense";
import { connect } from "react-redux";
import { AppStateCore } from "../../../store";
import { AnyAction } from "redux";


export interface ShowContainerProps extends ContainerComponentProps<ShowUiProps> {
    /* From dependency injection */
    repository: Repository<Subscription>,
    loadViewModel: (id: string | undefined) => Promise<any>,

    /* From redux */
    subscriptionId: string
}

export interface ShowUiProps {
    model: Subscription | undefined,

    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any,

    user: User | undefined,
    licenses: Array<SubscriptionLicense>,
    subscriptionId: string
}


export const _ShowContainer = (props: ShowContainerProps) => {
    let { component, repository, loadViewModel, ...rest } = props;
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('trustId', props.subscriptionId);
    const isCreate = id ? false : true;

    const [model, setModel] = React.useState<Subscription | undefined>(undefined);
    const [inviteSent, setInviteSent] = React.useState<boolean>(!isCreate);
    const [user, setUser] = React.useState<User | undefined>(undefined);
    const [licenses, setLicenses] = React.useState<Array<SubscriptionLicense>>([]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadViewModel(id);
        setUser(result.user);
        setLicenses(result.licenses);

        setModel(result.model);
        return true;
    }, [loadViewModel, setModel, setUser, id, setLicenses]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id)) && !user && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            model={model}
            user={user}
            licenses={licenses}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            subscriptionId={props.subscriptionId}
        />
    );
};

export const __ShowContainer = withServiceProps<ShowContainerProps, AppServicesCore>(services => ({
    repository: services.api.subscriptions.repository(),
    loadViewModel: services.api.subscriptions.viewModels.edit(),
}))(_ShowContainer);

export const ShowContainer = connect(
    /*mapStateToProps*/
    (state: AppStateCore) => ({
        subscriptionId: state.user.identity ? state.user.identity.subscriptionId : ""
    }),

    /*mapDispatchToProps*/
    (dispatch: React.Dispatch<AnyAction>) => ({
    })
)(__ShowContainer);