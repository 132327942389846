import * as React from 'react';
import { Redirect } from 'react-router';
import { ManageContainer, ManageUiProps } from './ManageContainer'
import { withContainer } from 'react-withcontainer';
import { LoadingIndicator } from './shared/LoadingIndicator';

/**
 * navigate to correct manage overview depending on subscription.
 */
export const ManageUi = (props: ManageUiProps) => {

    // loading.
    if (!props.model) {
        return (
            <LoadingIndicator />
        );
    }

    // Redirect to a custom view for awarding organisations
    if (props.model.isForAwardingOrganisation) {
        return (<Redirect to={`/awardingOrganisation/pendingCertificates`} />);
    }

    // bypass trust views for single school trust
    if (props.model.isForSingleSchool && !!props.school) {
        return (
            <Redirect to={`/school/${props.school.id}`} />
        )
    }

    // default to the normal trust manage view
    return (
        <Redirect to={`/manage/trust`} />
    );
};

export const Manage = withContainer(ManageContainer)(ManageUi);
