import * as React from 'react';
import { useApiService } from '../../api/useApiService';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Button, Col, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Subscription } from '../../api/models/Subscription';
import { SubscriptionLicense } from '../../api/models/SubscriptionLicense';
import { useNavigateWhenReady } from '../shared/hooks/useNavigateWhenReady';
import { ProgressWheel } from '../schools/progressWheel/ProgressWheel';
import { ProductTier } from '../../api/models/codeOnly/ProductTeir';
import { Subject } from '../../api/models/Subject';
import { useLoadProjectWithRetry } from './useLoadProjectWithRetry';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../utilities/replacePlaceholders';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { Trust } from '../../api/models/Trust';
import { User } from '../../api/models/User';

export interface OverviewProps {
    subscriptionId: string,
}

/**
 * Overview of a subject review.
 * @param props
 */
export const _TrustSubjectReviewOverview = (props: OverviewProps) => {
    const {
        subscriptionId,
    } = props;

    const navigation = useUniversalNavigation(props);
    const subjectId = navigation.getParam('subjectId', '');


    const api = useApiService();
    const loadViewModel = api.trusts.viewModels.overview();
    const subjectRepository = api.subjects.repository();

    // Load the main model data.
    //
    const [model, setModel] = React.useState<Trust | undefined>();
    const [schools, setSchools] = React.useState<Array<School>>([]);
    const [user, setUser] = React.useState<User | undefined>();
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [subject, setSubject] = React.useState<Subject | undefined>();
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const subjectResult = await subjectRepository.find(subjectId);
        setSubject(subjectResult);

        const result = await loadViewModel(undefined, subscriptionId);
        setSchools(result.schools);
        setUser(result.user);
        setSubscription(result.subscription);
        setModel(result.model);
    }, [loadViewModel, setModel, setSchools, subscriptionId, setSubscription, setUser, subjectId, setSubject]);
    React.useEffect(() => {
        if (!subscriptionId || isLoading) {
            return;
        }

        if (model && model.subscriptionId === subscriptionId && subject && subject.id === subjectId) {
            return;
        }

        load();
    }, [subscriptionId, load, model, isLoading, subjectId, subject]);

    // Select the first school.
    const school = React.useMemo(() => schools.find(it => true), [schools]);

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const {
        project,
        loadProjectErrors,
    } = useLoadProjectWithRetry({
        schoolId: school && school.id || '',
        trustId: model && model.id || '',
        subjectId: subjectId,
        questionSetName: 'Subject leadership review',
        createIfMissing: true
    });

    // Also load the helpful questions project.
    const {
        project: helpfulQuestionsProject,
        loadProjectErrors: loadHelpfulQuestionsProjectErrors,
    } = useLoadProjectWithRetry({
        schoolId: school && school.id || '',
        trustId: model && model.id || '',
        subjectId: subjectId,
        questionSetName: 'Subject helpful questions',
        createIfMissing: true
    });

    const [navigateWhenReady, { isNavigationPending: navigateIsPending }] = useNavigateWhenReady(
        !!model && !!project,
        {
            'review': `/manage/subjectLeadershipReviewDashboard/${project && project.id || ''}`,
            'reviewHelpfulQuestions': `/manage/subjectHelpfulQuestionsDashboard/${helpfulQuestionsProject && helpfulQuestionsProject.id || ''}`,
            }
    );


    if (!model) {
        return (<LoadingIndicator />);
    }

    return (
        <>
            <div className="main-container overview">
                <AlertOnErrors errors={[loadingErrors, loadProjectErrors, loadHelpfulQuestionsProjectErrors]} />

                <Row>
                    <Col xs={12} md="">
                        <div className="overview-logo">
                        </div>
                    </Col>
                    <Col xs={12} md="auto">
                        <div className="text-muted">
                            <h3>{subject && subject.name}</h3>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
                
                <Row>
                    {/* Show the progress wheel for subject leadership review */}
                    <Col xs={12} xl={6}>
                        <div className="overview-tier-heading" style={{ textAlign: 'center' }}>
                            <h3>
                                Subject leadership review
                            </h3>
                        </div>

                        <Row className="mt-4">
                            <Col xs={12} lg={3}>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('review')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('report') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('review')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                {subject && subject.name} subject leadership review dashboard
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                            <Col xs={12} lg={3}>
                            </Col>
                        </Row>
                    </Col>

                    {/* Show the progress wheel for helpful questions */}
                    <Col xs={12} xl={6}>
                        <div className="overview-tier-heading" style={{ textAlign: 'center' }}>
                            <h3>
                                Helpful questions
                            </h3>
                        </div>

                        <Row className="mt-4">
                            <Col xs={12} lg={3}>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('reviewHelpfulQuestions')}>
                                    <Row>
                                        <Col xs="auto">
                                            <FontAwesomeIcon icon={navigateIsPending('report') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('reviewHelpfulQuestions')} />
                                        </Col>
                                        <Col>
                                            <div className="overview-navigation-button-text">
                                                {subject && subject.name} helpful questions dashboard
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                            <Col xs={12} lg={3}>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};


export const TrustSubjectReviewOverview = connect(
    (state: AppState) => ({
        subscriptionId: (state.user && state.user.identity) ? state.user.identity.subscriptionId : ''
    })
)(_TrustSubjectReviewOverview);
