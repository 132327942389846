import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { Project } from "../../../api/models/Project";
import { ManagedChildModels, useManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { Strength } from "../../../api/models/Strength";
import { Question } from "../../../api/models/Question";
import { Topic } from "../../../api/models/Topic";
import { Questionaire } from "../../../api/models/Questionaire";
import { Repository } from "pojo-repository";
import { ProjectTopic } from "../../../api/models/ProjectTopic";
import { ProjectQuestionaire } from "../../../api/models/ProjectQuestionaire";
import { ValidationErrors, ValidationState } from "pojo-validator";
import { useValidatorCallback } from "pojo-validator-react";
import { ProjectSection } from "../../../api/models/ProjectSection";
import { Section } from "../../../api/models/Section";
import { Impact } from "../../../api/models/Impact";
import { AnonymousQuestionResponse } from "../../../api/models/AnonymousQuestionResponse";
import { Action } from "../../../api/models/Action";
import { useApiService } from "../../../api/useApiService";
import { School } from "../../../api/models/School";
import { Trust } from "../../../api/models/Trust";
import { Video } from "../../../api/models/Video";
import { Consultant } from "../../../api/models/Consultant";
import { BlobUrl } from "../../../api/models/BlobUrl";
import { PlaceholderReplacementMode } from "../../../utilities/replacePlaceholders";

export interface Permission {
    id: string,
    hasPermission: boolean,
}

export interface StrengthReportContainerProps extends ContainerComponentProps<StrengthReportUiProps> {
}

export interface StrengthReportUiProps {
    model: Project | undefined,
    load: () => Promise<boolean>,
    isLoading: boolean,
    loadingErrors: any,

    validate: (fieldsToCheck?: Array<string>) => boolean,
    validationErrors: ValidationErrors,

    save: () => Promise<boolean>,
    isSaving: boolean,
    savingErrors: any,

    remove: (id: string) => Promise<boolean>,
    isRemoving: boolean,
    removingErrors: any,

    strengths: ManagedChildModels<Strength>,
    actions: ManagedChildModels<Action>,

    questions: Array<Question>,
    topics: Array<Topic>,
    questionaires: Array<Questionaire>,
    projectTopics: Array<ProjectTopic>,
    projectQuestionaires: Array<ProjectQuestionaire>,
    sections: Array<Section>,
    projectSections: Array<ProjectSection>,
    impacts: Array<Impact>,
    questionResponses: Array<AnonymousQuestionResponse>,
    sectionPermissions: Array<Permission>,
    topicPermissions: Array<Permission>,
    questionPermissions: Array<Permission>,
    schools: Array<School>,
    otherProjects: Array<Project>,
    trust: Trust | undefined,

    videos: Array<Video>,
    consultants: Array<Consultant>,
    videoThumbnailBlobs: Array<BlobUrl>,
    consultantPhotoBlobs: Array<BlobUrl>,

    placeholderReplacementMode: PlaceholderReplacementMode,
}

/**
 * Container for the home page of a Project.
 * 
 * @param props
 */
export const StrengthReportContainer = (props: StrengthReportContainerProps) => {
    const { component, ...rest } = props;

    const api = useApiService();
    const repository = api.strengths.repository();
    const loadModel = api.strengths.viewModels.strengthReport();
    const actionsRepository = api.actions.repository();

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('projectId', '');

    const [model, setModel] = React.useState<any | undefined>(undefined);
    const strengths = useManagedChildModels<Strength>(repository);
    const actions = useManagedChildModels<Action>(actionsRepository);
    const [questions, setQuestions] = React.useState<Array<Question>>([]);
    const [topics, setTopics] = React.useState<Array<Topic>>([]);
    const [questionaires, setQuestionaires] = React.useState<Array<Questionaire>>([]);
    const [projectTopics, setProjectTopics] = React.useState<Array<ProjectTopic>>([]);
    const [projectQuestionaires, setProjectQuestionaires] = React.useState<Array<ProjectQuestionaire>>([]);
    const [sections, setSections] = React.useState<Array<Section>>([]);
    const [projectSections, setProjectSections] = React.useState<Array<ProjectSection>>([]);
    const [impacts, setImpacts] = React.useState<Array<Impact>>([]);
    const [questionResponses, setQuestionResponses] = React.useState<Array<AnonymousQuestionResponse>>([]);
    const [sectionPermissions, setSectionPermissions] = React.useState<Array<Permission>>([]);
    const [topicPermissions, setTopicPermissions] = React.useState<Array<Permission>>([]);
    const [questionPermissions, setQuestionPermissions] = React.useState<Array<Permission>>([]);
    const [schools, setSchools] = React.useState<Array<School>>([]);
    const [otherProjects, setOtherProjects] = React.useState<Array<Project>>([]);
    const [trust, setTrust] = React.useState<Trust | undefined>(undefined);
    const [videos, setVideos] = React.useState<Array<Video>>([]);
    const [consultants, setConsultants] = React.useState<Array<Consultant>>([]);
    const [videoThumbnailBlobs, setVideoThumbnailBlobs] = React.useState<Array<BlobUrl>>([]);
    const [consultantPhotoBlobs, setConsultantPhotoBlobs] = React.useState<Array<BlobUrl>>([]);
    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');



    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadModel(id);
        strengths.setModels(result.strengths);
        actions.setModels(result.actions);
        setQuestions(result.questions);
        setTopics(result.topics);
        setQuestionaires(result.questionaires);
        setProjectTopics(result.projectTopics);
        setProjectQuestionaires(result.projectQuestionaires);
        setSections(result.sections);
        setProjectSections(result.projectSections);
        setImpacts(result.impacts);
        setQuestionResponses(result.questionResponses);
        setSectionPermissions(result.sectionPermissions);
        setTopicPermissions(result.topicPermissions);
        setQuestionPermissions(result.questionPermissions);
        setSchools(result.schools);
        setOtherProjects(result.otherProjects);
        setTrust(result.trust);

        setVideos(result.videos);
        setConsultants(result.consultants);
        setVideoThumbnailBlobs(result.videoThumbnailBlobs);
        setConsultantPhotoBlobs(result.consultantPhotoBlobs);

        setPlaceholderReplacementMode(result.placeholderReplacementMode);

        setModel(result.model);
        return true;
    }, [id, loadModel, setModel, strengths, actions, setQuestions, setTopics, setQuestionaires, setProjectTopics, setProjectQuestionaires, setSections, setProjectSections, setImpacts, setQuestionResponses,
        setSectionPermissions, setTopicPermissions, setQuestionPermissions, setSchools, setOtherProjects, setTrust,
        setVideos, setConsultants, setVideoThumbnailBlobs, setConsultantPhotoBlobs, setPlaceholderReplacementMode,
    ]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        // Validate all strengths
        if (!fieldsToCheck) {
            validation.singleCheck('strengths', () => !strengths.validateModels(), 'One or more strength is invalid.');
        }
    }, [model]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the strengths
        await strengths.save();

        return true;
    }, [model, validate, strengths]);

    // Remove a strength.
    const [remove, { isExecuting: isRemoving, errors: removingErrors }] = useAsyncCallback(async (id: string): Promise<boolean> => {
        strengths.removeModel(id);
        await repository.remove(id);

        return true;
    }, [repository, strengths]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id !== model.id)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest} model={model}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            validate={validate} validationErrors={validationErrors}
            remove={remove} isRemoving={isRemoving} removingErrors={removingErrors}
            strengths={strengths}
            actions={actions}
            questions={questions}
            topics={topics}
            questionaires={questionaires}
            projectTopics={projectTopics}
            projectQuestionaires={projectQuestionaires}
            sections={sections}
            projectSections={projectSections}
            impacts={impacts}
            questionResponses={questionResponses}
            sectionPermissions={sectionPermissions}
            topicPermissions={topicPermissions}
            questionPermissions={questionPermissions}
            schools={schools}
            otherProjects={otherProjects}
            trust={trust}
            videos={videos} consultants={consultants} videoThumbnailBlobs={videoThumbnailBlobs} consultantPhotoBlobs={consultantPhotoBlobs}
            placeholderReplacementMode={placeholderReplacementMode}
        />
    );
};

