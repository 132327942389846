import * as React from "react";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { ContainerComponentProps } from "react-withcontainer";
import { Validator, ValidationState } from "pojo-validator";
import { UserProfile } from "../../api/models/UserProfile";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { AppStateCore } from "../../store";
import { connect } from "react-redux";
import { useValidatorCallback } from "pojo-validator-react";
import { useAsyncCallback } from "react-use-async-callback";
import { EditUiPropsBase } from "../containers/common/EditUiPropsBase";
import { User } from "../../api/models/User";
import { AuthenticationService } from "../../services/AuthenticationService";


export interface EditDetailsContainerProps extends ContainerComponentProps<EditDetailsUiProps> {
    /* From dependency injection */
    repository: Repository<User>,
    renewToken: () => Promise<void>,

    /* From redux */
    id: string
}

export interface EditDetailsUiProps extends EditUiPropsBase<User> {
    saveSuccessful: boolean,
};

/**
 * Let the user change their own details under their profile.
 * @param props
 */
export const _EditDetailsContainer = (props: EditDetailsContainerProps) => {
    const { component, id, repository, renewToken, ...rest } = props;

    const [model, setModel] = React.useState<User | undefined>(undefined);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<User>) => {
        setModel(prevState => ({
            ...(prevState as User),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result: any = await repository.find(id);
        setModel(result);
        return true;
    }, [repository, setModel, id]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('forename')) {
            validation.singleCheck('forename', () => !model.forename, 'Forename is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('surname')) {
            validation.singleCheck('surname', () => !model.surname, 'Surname is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('email')) {
            validation.singleCheck('Email', () => !model.email, 'Email is required');
        }
    }, [model]);

    // Save to the store.
    const [saveSuccessful, setSaveSuccessful] = React.useState<boolean>(false);
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        setSaveSuccessful(false);

        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model);

        setSaveSuccessful(true);

        // Renew the user's token (to handle the case where their email has changed).
        renewToken();

        return true;
    }, [model, validate, repository, setSaveSuccessful, renewToken]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    let Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors} saveSuccessful={saveSuccessful}
            />
    );
};

export const __EditDetailsContainer = withServiceProps<EditDetailsContainerProps, AppServicesCore>((services) => ({
    repository: services.api.users.repository(),
    renewToken: services.renewToken(),
}))(_EditDetailsContainer);

export const EditDetailsContainer = connect(
    // mapStateToProps
    (state: AppStateCore) => ({
        id: state.user.identity && state.user.identity.userId || '',
        user: state.user,
    }),
)(__EditDetailsContainer);
