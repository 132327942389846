import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import {
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader
} from '@coreui/react';
import { NavLinkTrackActive } from '../../shared/NavLinkTrackActive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { SidebarNav } from '../../shared/sidebarNav';

export interface SidebarProps {
    projectId: string,
    awardTagId: string,
    schoolId: string,
    location: {
        pathname: string,
    }
}

export const Sidebar = (props: SidebarProps) => {
    // To manage the breadcrumbs we use lots of routes sharing this layout.  To make sure the links know where to go we
    // first need to work out which of these many routes we are currently using so we can use it.
    const baseRoute = React.useMemo(() => {
        let ret = props.location.pathname;
        const index = ret.lastIndexOf('/');
        if (index > -1) {
            ret = ret.substr(0, index);
        }
        
        return ret;
    }, [props.location]);

    return (
        <>
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <Nav>
                    <NavItem>
                        <NavLinkTrackActive to={`${baseRoute}/submittedReport`} tag={Link}>
                            <FontAwesomeIcon icon="quote-right" className="nav-icon" />
                                Submitted report
                            </NavLinkTrackActive>
                    </NavItem>
                    <NavItem>
                        <NavLinkTrackActive to={`${baseRoute}/certificate`} tag={Link}>
                            <FontAwesomeIcon icon="certificate" className="nav-icon" />
                                Certificate
                            </NavLinkTrackActive>
                    </NavItem>
                </Nav>
            </SidebarNav>

            <AppSidebarFooter />
            {/*<AppSidebarMinimizer />*/}
        </>
    );
};