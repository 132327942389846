import * as React from 'react';
import { Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertOnErrors } from '../../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ElasticInput } from 'reactstrap-elasticinput';
import { Project } from '../../../../api/models/Project';

export interface EditReasonForConsiderationModalProps {
    model: Project,
    changeModel: (changes: Partial<Project>) => void,
    save: () => Promise<boolean>,
    isSaving: boolean,
    savingErrors: any,
    cancel: () => void,
    isProjectGoals: boolean,
}

export const EditReasonForConsiderationModal = (props: EditReasonForConsiderationModalProps) => {
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    // Display the UI.

    if (!props.model) {
        return (<></>);
    }

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                {
                    props.isProjectGoals ? (
                        <>Edit project goals </>
                    ) : (
                            <>Edit reason for consideration</>
                        )
                }
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={props.savingErrors} />

                <Label htmlFor="reasonForConsideration">
                    {
                        props.isProjectGoals ? (
                            <>Project goals</>
                        ) : (
                                <>Reason for consideration</>
                            )
                    }                    
                </Label>
                <ElasticInput type="textarea" name="reasonForConsideration" placeholder={props.isProjectGoals? 'Project goals': 'Reason for consideration'} value={props.model.reasonForConsideration} onChange={onChange} />
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <ButtonAsync color="primary" onClick={props.save} isExecuting={props.isSaving}
                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                    Save
                </ButtonAsync>
                <Button color="primary" outline onClick={props.cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
