import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { persistReducer } from 'redux-persist';

import { createRootReducer, AppState } from './index';
import { persistConfig } from './persistConfig';

// this is so you can access the current runtime environment by using process.env.NODE_ENV,
// to enable the webpack "process.env" plugin
declare var process: {
    env: {
        NODE_ENV: string
    }
}

export default function configureStore(history: History, initialState: AppState) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';

    var windowAny = window as any;
    if (isDevelopment && typeof window !== 'undefined' && windowAny.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowAny.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        persistReducer(
            persistConfig,
            createRootReducer(history)
        ),
        initialState as any,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
