import { withServiceProps } from "inject-typesafe-react";
import { BasicRepositoryDeleteContainerProps, BasicRepositoryDeleteContainer } from "../containers/common/basicRepositoryContainers/BasicRepositoryDeleteContainer";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { DeleteUiPropsBase } from "../containers/common/DeleteUiPropsBase";
import { Video } from "../../api/models/Video";

export interface DeleteUiProps extends DeleteUiPropsBase<Video> {
}

export const DeleteContainer = withServiceProps<BasicRepositoryDeleteContainerProps, AppServicesCore>(services => ({
    repository: services.api.videos.repository()
}))(BasicRepositoryDeleteContainer);