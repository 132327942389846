import { RAGDocxProps, finalizeChildren } from './RAGDocx';
import { ProjectAnalysisResultItem, ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Paragraph, HeadingLevel, AlignmentType, Table, TableRow, TableCell, WidthType, BorderStyle, ShadingType, TextRun } from 'docx';
import { getGradeString } from '../../../../utilities/getGradeString';
import { Strength } from '../../../../api/models/Strength';
import { Risk } from '../../../../api/models/Risk';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../../../utilities/replacePlaceholders';
import { Question } from '../../../../api/models/Question';
import { QuestionResponse } from '../../../../api/models/QuestionResponse';
import { QuestionChoice } from '../../../../api/models/QuestionChoice';


export interface RAGProjectTopicDocxUiProps extends RAGDocxProps {
    projectTopicId: string,
    isFirstTopic: boolean,
}

/**
 * Breakdown of strengths and recommendations by sections and topics for RAG (docx format).
 */
export const RAGProjectTopicDocxUi = (props: RAGProjectTopicDocxUiProps) => {
    // Find the appropriate items we want to work with.  This is formatted to be similar to a useMemo() because the code is taken from there
    // from within the PDF.
    const {
        questionResponses,
        questionChoices,
    } = props;

    // Find the appropriate items we want to work with.
    const { projectTopic, topic, results, questions, /*questionaires, */ excellences, strengths, risks, myResults, } = (() => {
        let ps = props.projectTopics.models.find(item => item.id === props.projectTopicId);
        let topicId = '';
        if (ps) {
            topicId = ps.topicId;
        }

        let s = props.topics.find(item => item.id === topicId);

        let r = props.resultsByTopic.find(item => item.projectTopicId === props.projectTopicId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        const sectionResults = props.resultsBySection.find(item => !!s && item.sectionId === s.sectionId);
        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (sectionResults && sectionResults.results.length) {
            myResults = sectionResults.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        let q = props.questions.filter(item => item.topicId === topicId);
        //let qq = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));
        
        const strengthsx = props.strengths.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));
        const strengthsExcellences = strengthsx.filter(item => item.isExcellence);
        const strengthsAreasOfStrength = strengthsx.filter(item => !item.isExcellence);
        const risksx = props.risks.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));

        return { projectTopic: ps, topic: s, results: rr, questions: q, /*questionaires: qq,*/ excellences: strengthsExcellences, strengths: strengthsAreasOfStrength, risks: risksx, myResults: myResults };
    })();

    if (!props.model) {
        return null;
    }

    if (!projectTopic) {
        return null;
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return [
        // Heading.
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: topic?.name === props.sections.find(it => it.id === topic?.sectionId)?.name ? "" : topic && replacePlaceholders(topic.name, placeholderReplacementMode, { subjectName: props.subject?.name }),
                                    heading: HeadingLevel.HEADING_3,
                                })
                            ],
                        }),
                    ],
                }),
            ],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [
                80,
                20,
            ],
            borders: {
                top: {
                    style: BorderStyle.NONE,
                },
                bottom: {
                    style: BorderStyle.NONE,
                },
                left: {
                    style: BorderStyle.NONE,
                },
                right: {
                    style: BorderStyle.NONE,
                },
                insideHorizontal: {
                    style: BorderStyle.NONE,
                },
                insideVertical: {
                    style: BorderStyle.NONE,
                },
            },
        }),
        new Paragraph({ text: '', }), // For spacing and making editing easier.

        // Question details.
        //

        // RAG question breakdown topic table
            TableFor({
                title: "How confident are you in answering these questions?",
                items: questions,
                placeholderReplacementMode: props.placeholderReplacementMode,
                questionChoices: questionChoices,
                questionResponses: questionResponses,
                subjectName: props.subject?.name,
            })
    ];
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and only used internall by the files main component.
 */
const TableFor = (props: {
    title: string,
    items: Array<Question>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    questionChoices: Array<QuestionChoice>,
    questionResponses: Array<QuestionResponse>,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        questionChoices,
        questionResponses,
        subjectName,
    } = props;

    return [
        new Table({
            rows: finalizeChildren([
                // Heading row
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: '',
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                            width: {
                                size: 5,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: title,
                                            color: '#ffffff',
                                        }),
                                    ],
                                })
                            ],
                            width: {
                                size: 75,
                                type: WidthType.PERCENTAGE,
                            },
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "RAG",
                                            color: '#ffffff',
                                        }),
                                    ],
                                })
                            ],
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE,
                            },
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                        }),
                    ],
                    tableHeader: true,
                }),

                // Item rows
                ...items.map(item => {
                    // Without a question we can't show anything.
                    if (!item) {
                        return null;
                    }

                    // Get the RAG response and use the right colour for it
                    const RAGchoice = questionChoices.find(it => it.questionId === item.id)?.name;
                    if (RAGchoice === undefined) {
                        return null; //No point showing an unanswered question
                    }
                    let RAGcolor = "";
                    switch (RAGchoice?.split(":")[0]) {

                        default:
                            break;
                        case "Green":
                            RAGcolor = "#4dbd74";
                            break;
                        case "Amber":
                            RAGcolor = "#ffc107";
                            break;
                        case "Red":
                            RAGcolor = "#f56c6b";
                            break;
                    }

                    return new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        text: item.questionNumber,
                                    }),
                                ],
                                width: {
                                    size: 5,
                                    type: WidthType.PERCENTAGE,
                                },
                            }),
                            new TableCell({
                                children: finalizeChildren([
                                    new Paragraph({
                                        text: replacePlaceholders(item.name, placeholderReplacementMode, { subjectName: subjectName }),
                                    }),
                                ]),
                                width: {
                                    size: 75,
                                    type: WidthType.PERCENTAGE,
                                },
                            }),
                            new TableCell({
                                children: finalizeChildren([
                                    !!RAGchoice ? (
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: replacePlaceholders(RAGchoice, placeholderReplacementMode),
                                                    color: RAGcolor,
                                                }),
                                            ],
                                            border: {
                                                top: {
                                                    style: BorderStyle.DOTTED,
                                                },

                                            },

                                        })
                                    ) : null,
                                ]),
                                width: {
                                    size: 20,
                                    type: WidthType.PERCENTAGE,
                                },
                            })
                        ],
                        cantSplit: false,
                    });
                }),
            ]),
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [
                5,
                75,
                20,
            ],
            borders: {
                top: {
                    style: BorderStyle.SINGLE,
                },
                bottom: {
                    style: BorderStyle.SINGLE,
                },
                left: {
                    style: BorderStyle.NONE,
                },
                right: {
                    style: BorderStyle.NONE,
                },
                insideHorizontal: {
                    style: BorderStyle.SINGLE,
                },
                insideVertical: {
                    style: BorderStyle.NONE,
                },
            },
        }),
        new Paragraph({ text: '', }), // For spacing and making editing easier.
    ];
};

