import * as React from 'react';
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { PagedListUiPropsBase } from "../../containers/common/PagedListUiPropsBase";
import { Trust } from "../../../api/models/Trust";
import { ContainerComponentProps } from "react-withcontainer";
import { LoadPageResult } from "../../containers/common/basicContainers/BasicPagedListContainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { connect } from 'react-redux';
import { AppStateCore } from '../../../store';
import { Repository } from 'pojo-repository';
import { Subject } from '../../../api/models/Subject';


export interface ListUiProps extends PagedListUiPropsBase<any> {
    trustId: string,
}

export interface ListContainerProps extends ContainerComponentProps<ListUiProps> {
    /* From dependnecy injection */
    loadPage: (trustId: string, page: number, search?: string) => Promise<LoadPageResult<any>>,

}

export const _ListContainer = (props: ListContainerProps) => {
    const { component, loadPage, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const trustId = navigation.getParam('trustId', '');

    const [model, setModel] = React.useState<Array<any> | undefined>(undefined);
    const [search, setSearch] = React.useState<string>(navigation.getParam('search', ''));
    const [nextPage, setNextPage] = React.useState<number>(0);


    // Callback for changing the next page.
    const changeNextPage = React.useCallback((page: number) => {
        setNextPage(page);
    }, [setNextPage]);

    // Wrap the loadPage method to have correct async behaviour.
    const [wrappedLoadPage, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (page: number, searchText?: string): Promise<boolean> => {
        let result = await loadPage(trustId, page, searchText);
        setModel(result.model);
        setNextPage(result.nextPage);

        return true;
    }, [loadPage, setModel, setNextPage, trustId]);

    // Callback for changing the search.  This will also load the first page for the new search.
    const changeSearch = React.useCallback((search: string) => {
        setSearch(search);
        wrappedLoadPage(1, search);
    }, [setSearch, wrappedLoadPage]);


    // Load the first page on mount if we haven't got a model.
    React.useEffect(() => {
        if (!model && !isLoading && !loadingErrors) {
            wrappedLoadPage(1, search);
        }
    }, [model, isLoading, loadingErrors, wrappedLoadPage, search]);

    const Component = component;
    return (
        <Component {...rest}
            model={model}
            loadPage={wrappedLoadPage} isLoading={isLoading} loadingErrors={loadingErrors}
            search={search} changeSearch={changeSearch}
            nextPage={nextPage} changeNextPage={changeNextPage}
            trustId={trustId}
        />
    );
};


export const ListContainer = withServiceProps<ListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.users.viewModels.listSchoolUsers(),
    subjectsRepository: services.api.subjects.repository(),
}))(_ListContainer);
