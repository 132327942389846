import * as React from 'react';
import { useApiService } from '../../api/useApiService';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Project } from '../../api/models/Project';
import { ConditionalFragment } from 'react-conditionalfragment';
import moment, { Moment } from 'moment';
import { Button, Col, Row, Collapse, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { School } from '../../api/models/School';
import { LinkContainer } from 'react-router-bootstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { User } from '../../api/models/User';
import { AwardTag } from '../../api/models/AwardTag';
import { Subscription } from '../../api/models/Subscription';
import { SubscriptionLicense } from '../../api/models/SubscriptionLicense';
import { useNavigateWhenReady } from '../shared/hooks/useNavigateWhenReady';
import { ProgressWheel } from './progressWheel/ProgressWheel';
import { productTierDisplayName, ProductTier, isProductTierUnlocked } from '../../api/models/codeOnly/ProductTeir';
import { SectionDataItem, TopicDataItem } from '../projects/projectSections/ProjectSectionComponent';
import { SchoolTagCertificateProgress } from '../projects/awardTagSubmissions/SchoolTagCertificateProgress';
import { SchoolTagCertificate } from '../../api/models/SchoolTagCertificate';
import { AwardFeedbackAlert } from '../schoolReports/reportGenerator/awards/AwardFeedbackAlert';
import { useLoadProjectWithRetry } from '../subjectReviews/useLoadProjectWithRetry';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../utilities/replacePlaceholders';
import { Subject } from '../../api/models/Subject';

export interface OverviewProps {

}

/**
 * Overview of a school.
 * @param props
 */
export const Overview = (props: OverviewProps) => {
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('schoolId', '');
    const projectIdParam = navigation.getParam('projectId', '');

    const api = useApiService();
    const loadViewModel = api.schools.viewModels.overview();
    const schoolRepository = api.schools.repository();
    
    // Load the main model data.
    //
    const [model, setModel] = React.useState<School | undefined>();
    const [user, setUser] = React.useState<User | undefined>();
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [license, setLicense] = React.useState<SubscriptionLicense | undefined>();
    const [subjects, setSubjects] = React.useState<Array<Subject>>([]);
    const [readingReviews, setReadingReviews] = React.useState<{ eyfsToKS2: boolean, ks3ToKS4: boolean }>({ eyfsToKS2: false, ks3ToKS4: false, });

    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(id);
        setUser(result.user);
        setAwardTags(result.awardTags);
        setSubjects(result.subjects);
        setReadingReviews(result.readingReviews);
        setSubscription(result.subscription);
        setLicense(result.license);
        setModel(result.model);
        setPlaceholderReplacementMode(result.placeholderReplacementMode);
    }, [loadViewModel, setModel, id, setUser, setAwardTags, setSubscription, setLicense, setPlaceholderReplacementMode]);
    React.useEffect(() => {
        if (!id || isLoading) {
            return;
        }

        if (model && model.id === id) {
            return;
        }

        load();
    }, [id, load, model, isLoading]);

    // Load the project (with retries to cope with it being generated/regenerated)
    //
    const {
        project,
        sections,
        topics,
        isLoadingProject,
        loadProjectErrors,
    } = useLoadProjectWithRetry({
        schoolId: model && model.id || '',
        trustId: model && model.trustId || '',
        awardTagId: undefined,
        questionSetName: (model || {} as any /* Nasty hack due to errors in the hook checking of eslint */).isPotentialSchool ? 'School due diligence' : 'School self evaluation',
        createIfMissing: false
    });

    // Work out the first and first incomplete topic based on the user's progress in this tier so far.
    const {
        firstTopic,
        firstIncompleteTopic,
        isStarted,
        isTierComplete,
    } = React.useMemo(() => (
        {
            firstTopic: topics.find(it => true),
            firstIncompleteTopic: topics.find(it => it.questionResponsesCount < it.questionsCount),
            isStarted: !!topics.filter(it => it.questionResponsesCount > 0).length,
            isTierComplete: !!topics.length && !topics.filter(it => it.questionResponsesCount < it.questionsCount).length,
        }
    ), [topics]);

    // Workout the current tier for the school so we can customise the experience for them.
    const currentTeir = React.useMemo(() => model && model.tier as ProductTier || ProductTier.Snapshot, [model]);

    // Callback to let the tier of the school be changed.
    const [changeSchoolTier] = useAsyncCallback(async (newTier: ProductTier) => {
        // If we don't know which school we're working with, do nothing.
        if (!model) {
            return;
        }

        // If we are already at or above this tier, do nothing.
        if (isProductTierUnlocked(newTier, model.tier as ProductTier)) {
            return;
        }

        // If the current license is a free trial, do nothing as we can't change tier for schools on a trial.
        if (license && license.isTrial) {
            return;
        }

        // Update the tier into a new model object.
        const newModel = {
            ...model,
            tier: newTier,
        };

        // Save it to the database.
        await schoolRepository.save(newModel.id, newModel, false);
    }, [model, schoolRepository, license]);

    // Manage the opening and collapsing of the different teirs.
    const [isSnapshotOpen, setIsSnapshotOpen] = React.useState<boolean>(false);
    const toggleSnapshotOpen = React.useCallback(() => setIsSnapshotOpen(prevState => !prevState), [setIsSnapshotOpen]);
    const [isSixtyMinuteSEFOpen, setIsSixtyMinuteSEFOpen] = React.useState<boolean>(false);
    const toggleSixtyMinuteSEFOpen = React.useCallback(() => setIsSixtyMinuteSEFOpen(prevState => !prevState), [setIsSixtyMinuteSEFOpen]);
    const [isFullReviewOpen, setIsFullReviewOpen] = React.useState<boolean>(false);
    const toggleFullReviewOpen = React.useCallback(() => setIsFullReviewOpen(prevState => !prevState), [setIsFullReviewOpen]);
    const [isRichUnderstandingOpen, setIsRichUnderstandingOpen] = React.useState<boolean>(false);
    const toggleRichUnderstandingOpen = React.useCallback(() => setIsRichUnderstandingOpen(prevState => !prevState), [setIsRichUnderstandingOpen]);
    // Expand the right sections based on the tier of the school.
    React.useEffect(() => {
        if (!model) {
            return;
        }

        switch (currentTeir) {
            case ProductTier.Snapshot:
                setIsSnapshotOpen(true);
                break;
            case ProductTier.SixtyMinuteSEF:
                setIsSixtyMinuteSEFOpen(true);
                break;
            case ProductTier.FullReview:
            //case ProductTier.RichUnderstanding:
                setIsFullReviewOpen(true);
                setIsRichUnderstandingOpen(true);
                break;
        }
    }, [currentTeir, model]);

    // Navigate to a project URLs but making sure we wait until the projects are ready before doing so.
    const tagNavigateMap = React.useMemo(() => {
        let ret: { [key: string]: string } = {};
        for (const tag of awardTags) {
            ret[`${tag.id}::update`] = `/school/${model && model.id || ''}/awardHome/${tag.id}/${project && project.id || ''}`;
        }
        return ret;
    }, [awardTags, model, project]);

    const [navigateWhenReady, { isNavigationPending: navigateIsPending }] = useNavigateWhenReady(
        !!model && !!project && !!firstTopic,
        {
            'review': `/school/${model && model.id || ''}/selfEvaluationDashboard/${project && project.id || ''}`,
            'update': `/school/${model && model.id || ''}/selfEvaluation/${project && project.id || ''}/topic/${firstIncompleteTopic ? firstIncompleteTopic.id : firstTopic ? firstTopic.id : ''}`,

            // Specific tier updates (for when we are passed a tier but want to reopen just that tier).
            'updateSnapshot': currentTeir === ProductTier.Snapshot ? `/school/${model && model.id || ''}/selfEvaluation/${project && project.id || ''}/topic/${firstIncompleteTopic ? firstIncompleteTopic.id : firstTopic ? firstTopic.id : ''}`
                : `/school/${model && model.id || ''}/selfEvaluation/${project && project.id || ''}/topic/${firstIncompleteTopic ? firstIncompleteTopic.id : firstTopic ? firstTopic.id : ''}/tier/${ProductTier.Snapshot}`,
            'updateSixtyMinuteSEF': currentTeir === ProductTier.SixtyMinuteSEF ? `/school/${model && model.id || ''}/selfEvaluation/${project && project.id || ''}/topic/${firstIncompleteTopic ? firstIncompleteTopic.id : firstTopic ? firstTopic.id : ''}`
            : `/school/${model && model.id || ''}/selfEvaluation/${project && project.id || ''}/topic/${firstIncompleteTopic ? firstIncompleteTopic.id : firstTopic ? firstTopic.id : ''}/tier/${ProductTier.SixtyMinuteSEF}`,

            'dueDiligenceReview': `/school/${model && model.id || ''}/dueDiligenceDashboard/${project && project.id || ''}`,
            'dueDiligenceUpdate': `/school/${model && model.id || ''}/dueDiligence/${project && project.id || ''}/topic/${firstIncompleteTopic ? firstIncompleteTopic.id : firstTopic ? firstTopic.id : ''}`,

            'recommendationReport': `/school/${model && model.id || ''}/recommendationReport/${project && project.id || ''}`,
            'riskReport': `/school/${model && model.id || ''}/dueDiligenceRiskReport/${project && project.id || ''}`,
            'strengthReport': `/school/${model && model.id || ''}/strengthReport/${project && project.id || ''}`,

            'snapshotReport': `/school/${model && model.id || ''}/reports/standard/snapshot/${project && project.id || ''}?refresh=${Date.now()}`,
            'sefReport': `/school/${model && model.id || ''}/reports/standard/sixtyMinuteSEF/${project && project.id || ''}?refresh=${Date.now()}`,

            ...tagNavigateMap,
        }
    );


    if (!model) {
        return (<LoadingIndicator />);
    }

    return (
        <>
            <div className="main-container overview">
                <AlertOnErrors errors={[loadingErrors, loadProjectErrors, ]} />

                <div className="overview-logo">
                </div>

                <Row>
                    {/* Left hand side */}
                    <Col xs={12} xl={6}>
                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature}>
                            {/* If we are not restricted to specific awards and inspections, show our progress wheel.  Also show if we are asked to for a specific project. */}
                            <ConditionalFragment showIf={!!projectIdParam || (!(!!subscription && subscription.isLimitedToAwardTags) && !(!!user && user.isLimitedToAwardTags))}>
                                <ProgressWheel
                                    model={project || undefined}
                                    sections={sections || []}
                                    topics={topics || []}
                                    currentTier={model.tier as ProductTier}
                                    awardTagId={undefined}
                                    isLoading={isLoading || isLoadingProject}
                                    placeholderReplacementMode={placeholderReplacementMode}
                                />
                            </ConditionalFragment>
                        </ConditionalFragment>


                        {/* Show reviews for restricted users. */}
                        <ConditionalFragment showIf={(!!subscription && !!subscription.isLimitedToAwardTags) || (!!user && !!user.isLimitedToAwardTags)}>
                            <ConditionalFragment showIf={!!subscription && subscription.specificReviewsFeature}>
                                <ConditionalFragment showIf={!!awardTags.length}>
                                    <h3>Specific reviews</h3>
                                    <Row className="mb-2">
                                        {
                                            awardTags.map(tag => (
                                                <Col key={tag.id} xs={12} lg={6}>
                                                    <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady(`${tag.id}::update`)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending(`${tag.id}::update`) ? 'spinner' : 'certificate'} spin={navigateIsPending(`${tag.id}::update`)} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    {tag.name}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </ConditionalFragment>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!subscription && subscription.subjectReviewsFeature}>
                                <ConditionalFragment showIf={!!subjects.length}>
                                    <h3>Subject reviews</h3>
                                    <Row className="mb-2">
                                        {
                                            subjects.map(subject => (
                                                <Col key={subject.id} xs={12} lg={6}>
                                                    <LinkContainer to={`/school/${model && model.id || ''}/subjectHome/${subject.id}`}>
                                                        <Button color="primary" outline className="overview-navigation-button">
                                                            <Row>
                                                                <Col xs="auto">
                                                                    <FontAwesomeIcon icon={'brain'} />
                                                                </Col>
                                                                <Col>
                                                                    <div className="overview-navigation-button-text">
                                                                        {subject.name}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </LinkContainer>
                                                </Col>
                                            ))
                                        }
                                    </Row>

                                    <ConditionalFragment showIf={!!readingReviews.eyfsToKS2 || !!readingReviews.ks3ToKS4}>
                                        <h3>Reading reviews</h3>
                                        <Row className="mb-2">
                                            <ConditionalFragment showIf={readingReviews.eyfsToKS2}>
                                                <Col xs={12} lg={6}>
                                                    <LinkContainer to={`/school/${model && model.id || ''}/readingReviewEYFSToKS2Home`}>
                                                        <Button color="primary" outline className="overview-navigation-button">
                                                            <Row>
                                                                <Col xs="auto">
                                                                    <FontAwesomeIcon icon={'book-reader'} />
                                                                </Col>
                                                                <Col>
                                                                    <div className="overview-navigation-button-text">
                                                                        EYFS, KS1, and KS2 reading
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </LinkContainer>
                                                </Col>
                                            </ConditionalFragment>

                                            <ConditionalFragment showIf={readingReviews.ks3ToKS4}>
                                                <Col xs={12} lg={6}>
                                                    <LinkContainer to={`/school/${model && model.id || ''}/readingReviewKS3ToKS4Home`}>
                                                        <Button color="primary" outline className="overview-navigation-button">
                                                            <Row>
                                                                <Col xs="auto">
                                                                    <FontAwesomeIcon icon={'book-open'} />
                                                                </Col>
                                                                <Col>
                                                                    <div className="overview-navigation-button-text">
                                                                        KS3 and KS4 reading
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </LinkContainer>
                                                </Col>
                                            </ConditionalFragment>
                                        </Row>
                                    </ConditionalFragment>
                                </ConditionalFragment>
                            </ConditionalFragment>
                        </ConditionalFragment>
                    </Col>


                    {/* Right hand side */}
                    <ConditionalFragment showIf={!projectIdParam}>
                        <Col xs={12} xl={6}>
                            <div>
                                <ConditionalFragment showIf={!!model && !model.isPotentialSchool}>
                                    {/* Tier based navigation for normal schools.  This is the main navigation people will see here. */}
                                    <ConditionalFragment showIf={!((!!subscription && subscription.isLimitedToAwardTags) || (!!user && user.isLimitedToAwardTags))}>
                                        {/* SEF Lite (formally Snapshot) */}
                                        <div className="overview-tier-heading" onClick={() => toggleSnapshotOpen()}>
                                            <h3>
                                                {productTierDisplayName(ProductTier.Snapshot)}
                                                <> </>
                                                <small>
                                                    <FontAwesomeIcon icon={isSnapshotOpen ? 'caret-up' : 'caret-down'} />
                                                </small>
                                            </h3>
                                        </div>
                                        <Collapse isOpen={isSnapshotOpen}>
                                            <p>
                                                We will guide you through a 30 minute snapshot of your school and give you an SEF Lite report to better understand an overview of where you are.
                                            </p>
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <Button color="primary" outline className={`overview-navigation-button ${currentTeir === ProductTier.Snapshot && !isTierComplete ? 'overview-navigation-button-featured' : ''}`} onClick={() => navigateWhenReady('updateSnapshot')}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending('updateSnapshot') ? 'spinner' : 'play-circle'} spin={navigateIsPending('updateSnapshot')} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    <div>
                                                                        {
                                                                            isProductTierUnlocked(ProductTier.SixtyMinuteSEF, currentTeir) || isTierComplete ? 'Review SEF Lite'
                                                                                : !isStarted ? 'Start SEF Lite'
                                                                                    : 'Continue SEF Lite'
                                                                        }
                                                                        <div className="text-muted">
                                                                            <small>
                                                                                30 minutes is all you need
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Button color="primary" outline className={`overview-navigation-button ${currentTeir === ProductTier.Snapshot && isTierComplete ? 'overview-navigation-button-featured' : ''}`} disabled={!isProductTierUnlocked(ProductTier.SixtyMinuteSEF, currentTeir) && !isTierComplete}
                                                        onClick={() => {
                                                            (async () => {
                                                                await changeSchoolTier(ProductTier.SixtyMinuteSEF);
                                                                navigateWhenReady('snapshotReport');
                                                            })();
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending('snapshotReport') ? 'spinner' : 'file-alt'} spin={navigateIsPending('snapshotReport')} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    <div>
                                                                        Produce SEF Lite report

                                                                        <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.SixtyMinuteSEF, currentTeir) && !isTierComplete}>
                                                                            <div className="text-muted">
                                                                                <small>
                                                                                    <FontAwesomeIcon icon="lock" className="overview-navigation-button-lock-icon" />
                                                                                    <> </>
                                                                                    Answer all SEF Lite questions to unlock this report
                                                                                </small>
                                                                            </div>
                                                                        </ConditionalFragment>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                            </Row>

                                            <ConditionalFragment showIf={!!license && license.isTrial}>
                                                <Alert color="warning" className="mt-2">
                                                    <FontAwesomeIcon icon="lock" />
                                                    <> </>
                                                    <strong>
                                                        Your free trial allows you to complete and report on your SEF Lite as an introduction to understanding your school.
                                                    </strong>
                                                    {/*<p>*/}
                                                    {/*    The full version of Evaluate-Ed contains many additional reviews, reports, and tools to help you really understand and improve your school.*/}
                                                    {/*</p>*/}
                                                    <p>
                                                        To discover the full school improvement potential of Evaluate-Ed, take a free 20-minute online tour.
                                                        To book a time to suit you, <a href="https://www.evaluate-ed.com/take-a-tour/">click here</a>.
                                                    </p>
                                                </Alert>
                                            </ConditionalFragment>
                                        </Collapse>

                                        {/* SEF Plus - Formally 60 minute SEF */}
                                        <div className="overview-tier-heading" onClick={() => toggleSixtyMinuteSEFOpen()}>
                                            <h3>
                                                {productTierDisplayName(ProductTier.SixtyMinuteSEF)}
                                                <> </>
                                                <small>
                                                    <FontAwesomeIcon icon={isSixtyMinuteSEFOpen ? 'caret-up' : 'caret-down'} />

                                                    <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.SixtyMinuteSEF, currentTeir)}>
                                                        <> </>
                                                        <span className="text-muted">
                                                            <FontAwesomeIcon icon="lock" />
                                                        </span>
                                                    </ConditionalFragment>
                                                </small>
                                            </h3>
                                        </div>
                                        <Collapse isOpen={isSixtyMinuteSEFOpen}>
                                            {
                                                !isProductTierUnlocked(ProductTier.SixtyMinuteSEF, currentTeir) ?
                                                    (
                                                        <Alert color="warning">
                                                            <FontAwesomeIcon icon="lock" />
                                                            <> </>
                                                            <strong>
                                                                This area is locked.
                                                            </strong>
                                                            <p>
                                                                You will unlock the SEF Plus after you complete your SEF Lite.
                                                            </p>
                                                        </Alert>
                                                    ) : (
                                                        <p>
                                                            Now you have your SEF Lite, dig a little deeper and complete your SEF Plus.  We've carried forward all your answers from your SEF Lite to make this as easy as possible.
                                                        </p>
                                                    )
                                            }
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <Button color="primary" outline className={`overview-navigation-button ${currentTeir === ProductTier.SixtyMinuteSEF && !isTierComplete ? 'overview-navigation-button-featured' : ''}`} onClick={() => navigateWhenReady('updateSixtyMinuteSEF')} disabled={!isProductTierUnlocked(ProductTier.SixtyMinuteSEF, currentTeir)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending('updateSixtyMinuteSEF') ? 'spinner' : 'play-circle'} spin={navigateIsPending('updateSixtyMinuteSEF')} />
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <div>
                                                                        {
                                                                            isProductTierUnlocked(ProductTier.FullReview, currentTeir) || isTierComplete ? 'Review SEF Plus'
                                                                                : 'Continue SEF Plus'
                                                                        }
                                                                        <div className="text-muted">
                                                                            <small>
                                                                                Take 60 minutes to finalise your SEF Plus
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Button color="primary" outline className={`overview-navigation-button ${currentTeir === ProductTier.SixtyMinuteSEF && isTierComplete ? 'overview-navigation-button-featured' : ''}`} disabled={!isProductTierUnlocked(ProductTier.SixtyMinuteSEF, currentTeir) || (!isProductTierUnlocked(ProductTier.FullReview, currentTeir) && !isTierComplete)}
                                                        onClick={() => {
                                                            (async () => {
                                                                await changeSchoolTier(ProductTier.FullReview);
                                                                navigateWhenReady('sefReport');
                                                            })();
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending('sefReport') ? 'spinner' : 'chalkboard-teacher'} spin={navigateIsPending('sefReport')} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    <div>
                                                                        Produce SEF Plus report

                                                                        <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.FullReview, currentTeir) && !isTierComplete}>
                                                                            <div className="text-muted">
                                                                                <small>
                                                                                    <FontAwesomeIcon icon="lock" className="overview-navigation-button-lock-icon" />
                                                                                    <> </>
                                                                                    Answer all SEF Plus questions to unlock this report
                                                                                </small>
                                                                            </div>
                                                                        </ConditionalFragment>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Collapse>


                                        {/* Full review / Targeted Analysis (formally Detailed Analysis) */}
                                        <div className="overview-tier-heading" onClick={() => toggleFullReviewOpen()}>
                                            <h3>
                                                {productTierDisplayName(ProductTier.FullReview)}
                                                <> </>
                                                <small>
                                                    <FontAwesomeIcon icon={isFullReviewOpen ? 'caret-up' : 'caret-down'} />

                                                    <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                        <> </>
                                                        <span className="text-muted">
                                                            <FontAwesomeIcon icon="lock" />
                                                        </span>
                                                    </ConditionalFragment>
                                                </small>
                                            </h3>
                                        </div>
                                        <Collapse isOpen={isFullReviewOpen}>
                                            {
                                                !isProductTierUnlocked(ProductTier.FullReview, currentTeir) ?
                                                    (
                                                        <Alert color="warning">
                                                            <FontAwesomeIcon icon="lock" />
                                                            <> </>
                                                            <strong>
                                                                This area is locked.
                                                            </strong>
                                                            <p>
                                                                You will unlock targeted analysis after you complete your SEF Plus.
                                                            </p>
                                                        </Alert>
                                                    ) : (
                                                        <p>
                                                            Build on the work you've done to perform a targeted analysis of your school at your own pace and in the areas you want to focus on.  We have many questions available in each area so you can dive as deep as you need to.
                                                            <br />
                                                            You now have full access to everything in Evaluate-Ed.
                                                            <br />
                                                            <strong>
                                                                Select an area on the progress wheel to focus on it and increase your understanding of your school.
                                                            </strong>

                                                        </p>
                                                    )
                                            }
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('strengthReport')} disabled={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending('recommendationReport') ? 'spinner' : 'heartbeat'} spin={navigateIsPending('strengthReport')} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    View areas of strength
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('recommendationReport')} disabled={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending('recommendationReport') ? 'spinner' : 'thumbs-up'} spin={navigateIsPending('recommendationReport')} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    View possible areas for development
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <LinkContainer to={`/school/${model.id}/reports`}>
                                                        <Button color="primary" outline className="overview-navigation-button" disabled={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                            <Row>
                                                                <Col xs="auto">
                                                                    <FontAwesomeIcon icon="passport" />
                                                                </Col>
                                                                <Col>
                                                                    <div className="overview-navigation-button-text">
                                                                        Reports manager
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </LinkContainer>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('review')} disabled={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                        <Row>
                                                            <Col xs="auto">
                                                                <FontAwesomeIcon icon={navigateIsPending('review') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('review')} />
                                                            </Col>
                                                            <Col>
                                                                <div className="overview-navigation-button-text">
                                                                    Review self evaluation dashboard
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Collapse>

                                        {/* Rich understanding */}
                                        <div className="overview-tier-heading" onClick={() => toggleRichUnderstandingOpen()}>
                                            <h3>
                                                Rich understanding tools
                                                <> </>
                                                <small>
                                                    <FontAwesomeIcon icon={isRichUnderstandingOpen ? 'caret-up' : 'caret-down'} />

                                                    <ConditionalFragment showIf={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                        <> </>
                                                        <span className="text-muted">
                                                            <FontAwesomeIcon icon="lock" />
                                                        </span>
                                                    </ConditionalFragment>
                                                </small>
                                            </h3>
                                        </div>
                                        <Collapse isOpen={isRichUnderstandingOpen}>
                                            {
                                                !isProductTierUnlocked(ProductTier.FullReview, currentTeir) ?
                                                    (
                                                        <Alert color="warning">
                                                            <FontAwesomeIcon icon="lock" />
                                                            <> </>
                                                            <strong>
                                                                This area is locked.
                                                            </strong>
                                                            <p>
                                                                You will unlock the rich understanding tools after you complete your SEF Plus.
                                                            </p>
                                                        </Alert>
                                                    ) : (
                                                        <p>
                                                            As you work through your targeted analysis, use our rich understanding tools to gain a deeper understanding of your school and your future plans.
                                                        </p>
                                                    )
                                            }
                                        
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <LinkContainer to={`/school/${model.id}/improvementPlanner`}>
                                                        <Button color="primary" outline className="overview-navigation-button" disabled={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                            <Row>
                                                                <Col xs="auto">
                                                                    <FontAwesomeIcon icon="calendar-plus" />
                                                                </Col>
                                                                <Col>
                                                                    <div className="overview-navigation-button-text">
                                                                        Plan improvements for your school
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </LinkContainer>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <LinkContainer to={`/school/${model.id}/evidenceLocker`}>
                                                        <Button color="primary" outline className="overview-navigation-button" disabled={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                            <Row>
                                                                <Col xs="auto">
                                                                    <FontAwesomeIcon icon="cloud-upload-alt" />
                                                                </Col>
                                                                <Col>
                                                                    <div className="overview-navigation-button-text">
                                                                        Upload and update files in your evidence locker
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </LinkContainer>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <LinkContainer to={`/school/${model.id}/microConsultations`}>
                                                        <Button color="primary" outline className="overview-navigation-button" disabled={!isProductTierUnlocked(ProductTier.FullReview, currentTeir)}>
                                                            <Row>
                                                                <Col xs="auto">
                                                                    <FontAwesomeIcon icon="video" />
                                                                </Col>
                                                                <Col>
                                                                    <div className="overview-navigation-button-text">
                                                                        Browse our micro-consultations library
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </LinkContainer>
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </ConditionalFragment>
                                </ConditionalFragment>

                                {/* Special handling of potential schools. */}
                                <ConditionalFragment showIf={!!model && !!model.isPotentialSchool}>
                                    <h3>Due diligence</h3>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('dueDiligenceUpdate')}>
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon={navigateIsPending('dueDiligenceUpdate') ? 'spinner' : 'edit'} spin={navigateIsPending('dueDiligenceUpdate')} />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            Update due diligence
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('dueDiligenceReview')}>
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon={navigateIsPending('dueDiligenceReview') ? 'spinner' : 'solar-panel'} spin={navigateIsPending('dueDiligenceReview')} />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            Review due diligence dashboard
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </Col>
                                    </Row>

                                    <h3>Plan</h3>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('riskReport')}>
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon={navigateIsPending('riskReport') ? 'spinner' : 'exclamation-circle'} spin={navigateIsPending('riskReport')} />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            View identified risks for the school
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Button color="primary" outline className="overview-navigation-button" onClick={() => navigateWhenReady('strengthReport')}>
                                                <Row>
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon={navigateIsPending('recommendationReport') ? 'spinner' : 'heartbeat'} spin={navigateIsPending('strengthReport')} />
                                                    </Col>
                                                    <Col>
                                                        <div className="overview-navigation-button-text">
                                                            View areas of strength
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <LinkContainer to={`/school/${model.id}/improvementPlanner`}>
                                                <Button color="primary" outline className="overview-navigation-button">
                                                    <Row>
                                                        <Col xs="auto">
                                                            <FontAwesomeIcon icon="calendar-plus" />
                                                        </Col>
                                                        <Col>
                                                            <div className="overview-navigation-button-text">
                                                                Plan future improvements
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Button>
                                            </LinkContainer>
                                        </Col>
                                    </Row>
                                </ConditionalFragment>
                            </div>
                        </Col>
                    </ConditionalFragment>
                </Row>
            </div>
        </>
    );
};
