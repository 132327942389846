import * as React from 'react';
import { FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertOnErrors } from '../../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ElasticInput } from 'reactstrap-elasticinput';
import { Project } from '../../../../api/models/Project';
import { AwardTag } from '../../../../api/models/AwardTag';
import { School } from '../../../../api/models/School';

export interface FailAwardModalProps {
    model: Project,
    awardTag: AwardTag,
    school: School | undefined,
    save: (feedback: string) => Promise<void>,
    isSaving: boolean,
    savingErrors: any,
    cancel: () => void,
    initialFeedback?: string,
}

export const FailAwardModal = (props: FailAwardModalProps) => {
    const [feedback, setFeedback] = React.useState<string>(props.initialFeedback || '');

    // Display the UI.
    if (!props.model || !props.awardTag) {
        return (<></>);
    }

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                Fail {props.awardTag.name} for {props.school && props.school.name || 'school'}
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={props.savingErrors} />

                <FormGroup>
                    <Label htmlFor="feedback">Feedback</Label>
                    <ElasticInput type="textarea" name="feedback" placeholder="Feedback" value={feedback} onChange={e => setFeedback(e.currentTarget.value)} />
                </FormGroup>
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <ButtonAsync color="danger" onClick={() => props.save(feedback)} isExecuting={props.isSaving}
                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Sending...</>}>
                    Send feedback
                </ButtonAsync>
                <Button color="primary" outline onClick={props.cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
