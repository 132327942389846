import { SEFDocxProps } from './SEFDocx';
import { SEFProjectSectionDocxUi } from "./SEFProjectSectionDocxUi";


export interface SEFBreakdownDocxUiProps extends SEFDocxProps {
}

/**
 * Breakdown of strengths and recommendations by sections and topics for SEF (pdf format).
 */
export const SEFBreakdownDocxUi = (props: SEFBreakdownDocxUiProps) => {
    if (!props.model) {
        return null;
    }

    return [
        ...props.projectSections.models.map(item => (
            SEFProjectSectionDocxUi({ projectSectionId: item.id, ...props, })
        )),
    ];
};
