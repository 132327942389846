import { withServiceProps } from "inject-typesafe-react";
import { BasicRepositoryDeleteContainerProps, BasicRepositoryDeleteContainer } from "../../containers/common/basicRepositoryContainers/BasicRepositoryDeleteContainer";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { DeleteUiPropsBase } from "../../containers/common/DeleteUiPropsBase";
import { Subject } from "../../../api/models/Subject";


export interface DeleteUiProps extends DeleteUiPropsBase<Subject> {
}

export const DeleteContainer = withServiceProps<BasicRepositoryDeleteContainerProps, AppServicesCore>(services => ({
    repository: services.api.subjects.repository()
}))(BasicRepositoryDeleteContainer);