import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Card, CardBody, CardFooter, CardHeader, CardImg, CardImgOverlay, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import { BlobUrl } from '../../api/models/BlobUrl';
import { Consultant } from '../../api/models/Consultant';
import { Video } from '../../api/models/Video';

export interface VideoTileProps {
    model: Video,
    thumbnailBlob: BlobUrl | undefined,
    consultant: Consultant | undefined,
    consultantPhotoBlob: BlobUrl | undefined,

    watchVideo: () => void,

    tileSize?: 'fixed' | 'auto',
}

/**
 * 
 * @param props
 */
export const VideoTile = (props: VideoTileProps) => {
    const {
        model,
        thumbnailBlob,
        consultant,
        consultantPhotoBlob,
        watchVideo,
        tileSize = 'auto',
    } = props;

    return (
        <Card className={`video-browse-video video-browse-video-${tileSize}`} onClick={() => watchVideo()}>
            <CardImg className="video-thumbnail img-fluid" src={thumbnailBlob && thumbnailBlob.url || ''} />
            <CardImgOverlay className="video-thumbnail-overlay">
                <FontAwesomeIcon icon="play" />
            </CardImgOverlay>
            <CardBody tag="div">
                <CardTitle className="video-browse-video-name">
                    {model.name}
                </CardTitle>
                <CardSubtitle tag="div" className="video-browse-video-consultant">
                    <Row>
                        <Col xs="auto">
                            <div className="video-thumbnail-consultant-photo" style={{ backgroundImage: `url(${consultantPhotoBlob && consultantPhotoBlob.url || ''})` }}>
                                <ConditionalFragment showIf={!consultantPhotoBlob}>
                                    {consultant && `${consultant.forename.slice(0, 1)}${consultant.surname.slice(0, 1)}`.toUpperCase() || ''}
                                </ConditionalFragment>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                {consultant && `${consultant.forename} ${consultant.surname}` || ''}
                            </div>
                            <div><small>
                                {consultant && consultant.company || ''}
                            </small></div>
                        </Col>
                    </Row>
                </CardSubtitle>
            </CardBody>
        </Card>
        );
};