import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { DeleteUiPropsBase } from "../DeleteUiPropsBase";

export interface BasicDeleteContainerProps extends ContainerComponentProps<DeleteUiPropsBase> {
    loadModel: (id: string) => Promise<any>,
    removeModel: (id: string) => Promise<boolean>
}

/**
 * Basic implementation of a container containing the ability to load() and remove() via the loadModel() and removeModel() callbacks
 * passed in.
 * 
 * You can use this if you don't need any special logic for your delete container.
 * 
 * You cannot subclass it or try and extend it to do anything other than the basic handling, if you want to do any of that then
 * you should create your own DeleteContainer.
 * 
 * @param props
 */
export const BasicDeleteContainer = (props: BasicDeleteContainerProps) => {
    const { component, loadModel, removeModel, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');

    const [model, setModel] = React.useState<any | undefined>(undefined);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadModel(id);
        setModel(result);
        return true;
    }, [loadModel, setModel]);

    // Delete from storage.
    const [remove, { isExecuting: isRemoving, errors: removingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        let ok = await removeModel(model.id);
        return ok;
    }, [model]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest} model={model}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            remove={remove} isRemoving={isRemoving} removingErrors={removingErrors}
        />
    );
};
