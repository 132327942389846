import * as React from "react";
import { Button } from "reactstrap";
import { HelpRequestModal } from "./HelpRequestModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Button to start a help request (ask the expert).
 */
export const HelpRequestButton = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const toggleOpen = React.useCallback(() => setIsOpen(prevState => !prevState), [setIsOpen]);

    return (
        <>
            <Button size="sm"
                onClick={toggleOpen}>
                <FontAwesomeIcon icon="question" />
                <> </>
                Ask an expert
            </Button>
            {
                isOpen ? (
                    <HelpRequestModal
                        isOpen={isOpen}
                        toggle={toggleOpen}
                    />
                ): null
            }
        </>
        );
};