import * as React from 'react';
import Chart from 'react-apexcharts';
import { isNullOrUndefined } from 'util';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Project } from '../../../../api/models/Project';
import { chartLargeColorPallet } from '../../../../utilities/chartLargeColorPallet';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { Guid } from 'guid-string';

export interface StrengthsBarChartProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    data: Array<StrengthBarChartGroup>,

    showOtherSchools?: boolean,
    chartHeight?: number,
    awardTagId: string | undefined,
}

export interface StrengthBarChartGroup {
    projectId: string,
    schoolId: string,
    name: string,
    items: Array<StrengthBarChartItem>,
}

export interface StrengthBarChartItem {
    originKey: string,
    label: string,
    excellenceCount: number,
    strengthCount: number,
}

/**
 * Bar chart showing the number of strengths grouped by topic etc.
 * @param props
 */
export const StrengthsBarChart = (props: StrengthsBarChartProps) => {
    const navigation = useUniversalNavigation(props);
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTagId);

    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index index of the name.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    // Need the unique labels.
    const uniqueLabels = React.useMemo(() => {
        let ret: Array<{ originKey: string, label: string }> = [];
        for (const project of props.data) {
            for (const item of project.items) {
                if (!ret.find(it => it.originKey === item.originKey)) {
                    ret.push({ originKey: item.originKey, label: item.label });
                }
            }
        }
        return ret;
    }, [props.data]);

    const options = React.useMemo(() => ({
        labels: uniqueLabels.map(item => item.label),
        
        colors:
            !props.model || !Guid.isEmpty(props.model.schoolId) ? [chartLargeColorPallet[2], chartLargeColorPallet[1]] // If we are for a single school, use pink (excllence) and orange (strengths) for this report so its different to the purple used for areas for development.
                : chartLargeColorPallet, // For multiple schools use the full colour pallet, including the purple so schools end up assigned the same colours for strengths and developments.
        yaxis: {
            labels: {
                formatter: function (val: any) {
                    return parseFloat(val).toFixed(0);
                }
            },
        },

        chart: {
            toolbar: {
                show: false,
            },

            // If we only have one project, we are showing for a single school, so we want to split out strengths and excellences and stack them.
            stacked: props.data.length === 1? true: false,

            events: {
                // When the user clicks on a point in the chart, drill into that item.
                // NOTE dataPointSelection event doesn't work on raidal charts so we have to use the click event.
                click: (event: any, chartContext: any, config: any) => {


                    // If we didn't click on a datapoint, do nothing.
                    if (isNullOrUndefined(config.dataPointIndex)) {
                        return;
                    }

                    // We clicked on a datapoint so lets drill into it.
                    let myUniqueLabel = uniqueLabels[config.dataPointIndex];
                    if (!myUniqueLabel) {
                        return;
                    }
                    let myOriginKey = myUniqueLabel.originKey;
                    let myResult = props.data[config.seriesIndex].items.find(r => r.originKey === myOriginKey);
                    if (!myResult) {
                        return;
                    }
                    let mySchoolId = props.model && props.model.schoolId;
                    if (!mySchoolId) {
                        // trust project so jumping into a school report
                        mySchoolId = props.data[config.seriesIndex].schoolId;
                    }

                    // Do the actual navigation
                    let thisBaseRoute = `/school/${mySchoolId}`;
                    let url = `${thisBaseRoute}/strengthReport/${props.data[config.seriesIndex].projectId}?strengthId=${myResult.originKey}`;
                    navigation.navigate(url);
                },
            },
        },
    }), [uniqueLabels, projectSettings, props.data]);

    let series = React.useMemo(() => {
        // If we are for a single school, we want to split out strengths and excellences.
        if (props.data.length === 1) {
            return [
                // Excellence
                ...props.data.map(project => ({
                    name: 'Areas of excellence',
                    data: uniqueLabels.map(label => {
                        const match = project.items.find(it => it.originKey === label.originKey);
                        return match && match.excellenceCount || 0;
                    }),
                })),

                // Strengths
                ...props.data.map(project => ({
                    name: 'Areas of strengths',
                    data: uniqueLabels.map(label => {
                        const match = project.items.find(it => it.originKey === label.originKey);
                        return match && match.strengthCount || 0;
                    }),
                })),
            ];
        }

        // Otherwise we want to show one entry per school that includes both strengths and excellences.
        return props.data.map(project => ({
            name: project.name,
            data: uniqueLabels.map(label => {
                const match = project.items.find(it => it.originKey === label.originKey);
                return match && match.strengthCount + match.excellenceCount || 0;
            }),
        }));
    }, [props.data, uniqueLabels]);

    if (!props.data || !projectSettings.baseRoute) {
        return (<></>);
    }

    // Show the chart.
    return (
        <Chart options={options} series={series} type="bar" height={props.chartHeight ? props.chartHeight : 800} />
    );
};

