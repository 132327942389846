import * as React from 'react';
import { Edit } from '../edit/Edit';
import { useUniversalNavigation } from 'react-universal-navigation';

/**
 * Edit for "Reading review EYFS to KS2" question sets.
 */
export const ReadingReviewEYFSToKS2Edit = (props: any) => {
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');

    return (
        <Edit id={id} baseRoute="/administration/reading-eyfs-to-ks2" />
        );
};