import * as React from "react";
import { useUniversalNavigation } from "react-universal-navigation";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from "react-use-async-callback";
import { PagedListUiPropsBase } from "../PagedListUiPropsBase";

export interface LoadPageResult<T = any> {
    model: Array<T>;
    nextPage: number;
}

export interface BasicPagedListContainerProps extends ContainerComponentProps<PagedListUiPropsBase> {
    loadPage: (page: number, search?: string) => Promise<LoadPageResult<any>>
}

/**
 * Basic implementation of a container containing the ability to loadPage() from a repository.
 * 
 * If you want a non-paged list you should use BasicViewContainer.
 * 
 * You can use this if you don't need any special logic for your view container.
 * 
 * You cannot subclass it or try and extend it to do anything other than the basic handling, if you want to do any of that then
 * you should create your own ListContainer.
 * 
 * @param props
 */
export const BasicPagedListContainer = (props: BasicPagedListContainerProps) => {
    const { component, loadPage, ...rest } = props;

    const navigation = useUniversalNavigation(props);

    const [model, setModel] = React.useState<Array<any> | undefined>(undefined);
    const [search, setSearch] = React.useState<string>(navigation.getParam('search', ''));
    const [nextPage, setNextPage] = React.useState<number>(0);

    // Callback for changing the next page.
    const changeNextPage = React.useCallback((page: number) => {
        setNextPage(page);
    }, [setNextPage]);

    // Wrap the loadPage method to have correct async behaviour.
    const [wrappedLoadPage, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (page: number, searchText?: string): Promise<boolean> => {
        let result = await loadPage(page, searchText);
        setModel(result.model);
        setNextPage(result.nextPage);
        return true;
    }, [loadPage, setModel, setNextPage]);

    // Callback for changing the search.  This will also load the first page for the new search.
    const changeSearch = React.useCallback((search: string) => {
        setSearch(search);
        wrappedLoadPage(1, search);
    }, [setSearch, wrappedLoadPage]);


    // Load the first page on mount if we haven't got a model.
    React.useEffect(() => {
        if (!model  && !isLoading && !loadingErrors) {
            wrappedLoadPage(1, search);
        }
    }, [model, isLoading, loadingErrors, wrappedLoadPage, search]);

    const Component = component;
    return (
        <Component {...rest}
            model={model}
            loadPage={wrappedLoadPage} isLoading={isLoading} loadingErrors={loadingErrors}
            search={search} changeSearch={changeSearch}
            nextPage={nextPage} changeNextPage={changeNextPage}
        />
    );
};
