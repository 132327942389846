import * as React from 'react';
import { Form, FormGroup, Label, Alert, Row, Col, Container, Table, ButtonGroup, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { Guid } from "guid-string";
import { EditUiProps, EditContainer } from '../EditContainer';
import { AlertOnErrors } from '../../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../../shared/GoBackLinkContainer';
import { LinkContainer } from 'react-router-bootstrap';
import { CoreFields } from './CoreFields';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        //navigation.goBack();
    }, [props.save, navigation]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);


    // State and callbacks around our table of schools.
    // 

    const [schoolMenuIsOpen, setSchoolMenuIsOpen] = React.useState<{ [id: string]: boolean }>({});
    const toggleSchoolMenuOpen = React.useCallback((id: string) => {
        setSchoolMenuIsOpen(prevState => ({
            ...prevState,
            [id]: (prevState[id] ? false : true)
        }));
    }, [setSchoolMenuIsOpen]);

    // Open the default action when the user clicks on a row in the schools table.
    const onSchoolRowDoubleClick = React.useCallback((id: string, event: React.MouseEvent<HTMLTableRowElement>) => {
        navigation.push(`/trust/${props.model ? props.model.id : ''}/schools/edit/${encodeURIComponent(id)}`);
    }, [navigation, props.model]);

    // render UI
    //

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <div className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add trust' : 'Edit trust'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            <Form onSubmit={onSubmit}>
                <div className="toolbar-top">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>

                <CoreFields {...props} updateFromEdubase={props.updateFromEdubase} isUpdatingFromEdubase={props.isUpdatingFromEdubase}  />
            </Form>
        </div>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
