import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Collapse, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Input, Alert, } from 'reactstrap';
import { useApiService } from '../../api/useApiService';
import { useManagedChildModels } from '../shared/hooks/useManagedChildModels';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { EvidenceItem } from './EvidenceItem';
import moment from 'moment';
import { useServices } from 'inject-typesafe-react';
import { AppServices } from '../../configure/configureServices';
import { BlobUrl } from '../../api/models/BlobUrl';
import { isNullOrUndefined } from 'util';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { ConditionalFragment } from 'react-conditionalfragment';
import { SchoolEvidence } from '../../api/models/SchoolEvidence';
import { SearchAsYouTypeInput } from '../shared/SearchAsYouTypeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Topic } from '../../api/models/Topic';
import { Section } from '../../api/models/Section';
import { replacePlaceholders, PlaceholderReplacementMode } from '../../utilities/replacePlaceholders';
import { Subject } from '../../api/models/Subject';
import { useUniversalNavigation } from 'react-universal-navigation';

export interface EvidenceLockerCoreProps {
    schoolId: string,
    canSelect?: boolean,
    isSelected?: (SchoolEvidenceName: string) => boolean,
    toggleSelected?: (schoolEvidenceName: string) => void,
    canViewHistory?: boolean,
    onUploadComplete?: (model: SchoolEvidence) => void,
    canRemove?: boolean,
    questionTopicOriginKey?: string, // If we are assigning evidence to a question, we want to give the evidence the topic origin key
    placeholderReplacementMode: PlaceholderReplacementMode,

    subjectId: string | undefined,
    subjectName: string | undefined,
    setSubjectName: (subjectName: string) => void,
    isModal: boolean,

    slrSubjectName: string | undefined // Used for when the subject name is needed but we are not in a subject evidence locker;
}

/**
 * Evidence locker for a school.
 * @param props
 */
export const EvidenceLockerCore = (props: EvidenceLockerCoreProps) => {
    const {
        schoolId,
        canSelect = false,
        isSelected,
        toggleSelected,
        canViewHistory = true,
        onUploadComplete,
        canRemove = true,
        questionTopicOriginKey,
        placeholderReplacementMode,
        subjectId,
        subjectName,
        setSubjectName,
        isModal,
        slrSubjectName
    } = props;
    const navigation = useUniversalNavigation(props);

    const api = useApiService();
    const loadViewModel = api.schoolEvidence.viewModels.evidenceLocker();
    const schoolEvidenceRepository = api.schoolEvidence.repository();
    const renameEvidence = api.schoolEvidence.actions.rename();

    const [topics, setTopics] = React.useState<Array<Topic>>([]);
    const [sections, setSections] = React.useState<Array<Section>>([]);

    const [projectSubjects, setProjectSubjects] = React.useState<Array<Subject>>([]);
    const [subjectSections, setSubjectSections] = React.useState<Array<Section>>([]);
    const [subjectTopics, setSubjectTopics] = React.useState<Array<Topic>>([]);

    const [isSubjectLeader, setIsSubjectLeader] = React.useState<boolean>(false);
    const [isMultipleSubjectLeader, setIsMultipleSubjectLeader] = React.useState<boolean>(false);
    const [mySubjects, setMySubjects] = React.useState<Array<string>>([]);

    const blobUploadService = useServices((services: AppServices) => services.blobUploadService());

    const [fileBlobs, setFileBlobs] = React.useState<Array<BlobUrl>>([]);

    const schoolEvidenceManager = useManagedChildModels(schoolEvidenceRepository);
    const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);

    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(schoolId, subjectId);

        // If any of subject2Id - subject10Id are not null then we are a subject leader for multiple subjects and we don't want to redirect here
        if (result.user.subject2Id || result.user.subject3Id || result.user.subject4Id || result.user.subject5Id || result.user.subject6Id || result.user.subject7Id || result.user.subject8Id || result.user.subject9Id || result.user.subject10Id) {

            let subjects = [];

            // Check each subjectId and if not null, add it to the mySubjects array
            for (let i = 2; i <= 10; i++) {
                if (result.user[`subject${i}Id`] !== null) {
                    subjects.push(result.user[`subject${i}Id`]);
                }
            }
            if (result.user.subjectId !== null) {
                subjects.push(result.user.subjectId);
            }

            // If subjectId is not contained in subjects, then redirect the user to subjects[0]
            if (!subjects.includes(subjectId) && result.user.subjectId) {
                navigation.push(`/school/${schoolId}/evidenceLocker/${subjects[0]}`);
                return;
            }
            setMySubjects(subjects);
            setIsMultipleSubjectLeader(true);
        } else {
            // If we are a subject leader, we want to redirect to the subject evidence locker. Users only see their own subject Subject Leadership Reviews, but this prevents them adding the ID to the URL, as we will re-direct them to their own Subject Evidence Locker
            if (result.user.subjectId && !isMultipleSubjectLeader) {
                setIsSubjectLeader(true);
                if (result.user.subjectId !== subjectId) {
                    navigation.push(`/school/${schoolId}/evidenceLocker/${result.user.subjectId}`);
                    return;
                }
            }
        }

        setFileBlobs(result.fileBlobs);

        setTopics(result.topics);
        setSections(result.sections);

        setProjectSubjects(result.projectSubjects);
        setSubjectSections(result.subjectSections);
        setSubjectTopics(result.subjectTopics);

        // Set the subjectName to be the name of the subject in the EvidenceLocker.tsx file
        const currentSubject = result.projectSubjects.find((item: Subject) => item.id === subjectId);

        if (currentSubject && setSubjectName) {
            setSubjectName(currentSubject.name);
        }

        schoolEvidenceManager.setModels(result.schoolEvidences);
        setHasLoaded(true);
    }, [loadViewModel, schoolId, setFileBlobs, setSections, setTopics, schoolEvidenceManager, subjectId]);

    // Load if we need to.
    React.useEffect(() => {
        if (!schoolId || isLoading || hasLoaded) {
            return;
        }

        load();
    }, [schoolId, isLoading, load, hasLoaded, subjectId]);

    const UpdateTopicJson = React.useCallback((schoolEvidenceName: string) => {
        // Instead of just toggling, we also want to update the topicOriginKeyJson to either include or remove an origin key
        if (!!toggleSelected) {

            let existing = schoolEvidenceManager.models.find(item => item.name === schoolEvidenceName);
            if (!!existing) {
                let originKeyArray: Array<string> = JSON.parse(existing.topicOriginKeysJson);

                if (!!isSelected) {
                    if (isSelected(schoolEvidenceName)) {
                        // Means that the item is currently selected so need to remove the origin key
                        originKeyArray = originKeyArray.filter(item => item !== questionTopicOriginKey);
                    } else {
                        // If its not selected yet it means we are toggling it on and need to add the origin key
                        originKeyArray.push(questionTopicOriginKey!); // Using "!" to say we will always have this, toggling is only available on questions and therefore will always have a topic
                    }
                }

                // Update the evidence with the new origin key changes
                let changes = { topicOriginKeysJson: JSON.stringify(originKeyArray) };
                schoolEvidenceManager.change(existing.id, changes);

                existing = { ...existing, ...changes };
                schoolEvidenceRepository.save(existing.id, existing); // Save the changes
            }

            // Perform toggle
            toggleSelected(schoolEvidenceName);
        }
    }, [toggleSelected, schoolEvidenceManager, isSelected]);

    const [uploadMessage, setUploadMessage] = React.useState<string>('');
    const [updateRecord, isUpdatingRecord] = useAsyncCallback(async (schoolEvidenceName: string, blobId: string | undefined, url: string, topicOriginKeyJson: string, subjectId: string | undefined, isUpdate: boolean) => {
        // Find the existing item
        const now = moment().toISOString();
        let existing = schoolEvidenceManager.models.find(item => item.name === schoolEvidenceName && !item.archived);
   
        // If we have an existing item of the same schoolEvidenceName, and we are not updating, then we want to show an error message
        if (existing && !isUpdate) {
            setUploadMessage(`Evidence with the name "${schoolEvidenceName}" has already been added. Please choose a different name. If you're wanting to replace the evidence uploaded for "${schoolEvidenceName}", you can do this from the evidence locker.`);

            // Return here, having not made any changes
            return;
        }

        // If we have an existing item of the same schoolEvidenceName, and we are updating, then we want to archive the old item and create a new one with the same topicOriginKeyJson and the new file.
        if (existing && isUpdate) {
            // Archive the old record as its no longer live.
            let changes = { archived: true };
            schoolEvidenceManager.change(existing.id, changes);
            existing = { ...existing, ...changes };
        }

        let newItem = await schoolEvidenceManager.addModel({ schoolId: schoolId, name: schoolEvidenceName, updatedDate: now, blobId: blobId, url: url, archived: false, topicOriginKeysJson: existing?.topicOriginKeysJson ? existing?.topicOriginKeysJson : topicOriginKeyJson, subjectId });

        // Save to the database
        // NOTE we don't use schoolEvidenceRepository.save() here as that could lead to a race condition.
        await schoolEvidenceRepository.save(newItem.id, newItem);
        if (existing && isUpdate) {
            await schoolEvidenceRepository.save(existing.id, existing);
        }
      
        return newItem;
    }, [schoolEvidenceManager, schoolEvidenceRepository, schoolId]);

    const [selectedTopics, setSelectedTopics] = React.useState<Array<string>>([]);

    // Upload a file as evidence for a particular SchoolEvidenceName.
    const [schoolEvidenceNamesWithFileBlobsBeingUploaded, setSchoolEvidenceNamesWithFileBlobsBeingUploaded] = React.useState<Array<string>>([]);
    const [uploadFile, isAnyFileUploading] = useAsyncCallback(async (schoolEvidenceName: string, files: FileList, isUpdate: boolean) => {
        if (!files) {
            return null;
        }

        if (!schoolId) {
            return null;
        }

        // Record the fact we are uploading for this file.
        setSchoolEvidenceNamesWithFileBlobsBeingUploaded(prevState => [
            ...prevState,
            schoolEvidenceName
        ]);

        // Do the upload.
        let blob = await blobUploadService.upload(files);

        if (!blob) {
            // Show we are no longer uploading for this file
            setSchoolEvidenceNamesWithFileBlobsBeingUploaded(prevState => prevState.filter(it => it !== schoolEvidenceName));
            return null;
        }

        setFileBlobs(prevState => [
            ...(prevState || []),
            blob as BlobUrl,
        ]);

        // If we dont have a question topic origin key, just save an empty array
        const TopicJsonString = questionTopicOriginKey == undefined ? JSON.stringify([]) : JSON.stringify([questionTopicOriginKey]);


        const newItem = await updateRecord(schoolEvidenceName, blob.id, '', TopicJsonString, subjectId, isUpdate);

        // Show we are no longer uploading for this file
        setSchoolEvidenceNamesWithFileBlobsBeingUploaded(prevState => prevState.filter(it => it !== schoolEvidenceName));

        // Add to the list.
        if (newItem) {
            schoolEvidenceManager.setModels([...schoolEvidenceManager.models, newItem]);
        }

        // Run the onUploadComplete event from our parent if we have one.
        if (onUploadComplete && newItem) {
            setHasLoaded(false);
            onUploadComplete(newItem);
        }

        return newItem;
    }, [blobUploadService, updateRecord, setFileBlobs, schoolId, setSchoolEvidenceNamesWithFileBlobsBeingUploaded, schoolEvidenceManager]);

    // Return true if we are uploading a file for the school evidence name passed in.
    const isFileUploading = React.useCallback((schoolEvidenceName: string): boolean => {
        let existing = schoolEvidenceNamesWithFileBlobsBeingUploaded.find(it => it === schoolEvidenceName);
        if (existing) {
            return true;
        }

        return false;
    }, [schoolEvidenceNamesWithFileBlobsBeingUploaded]);

    // Update the URL as an alternative to a file.
    const [updateUrl, isUpdatingUrl] = useAsyncCallback(async (schoolEvidenceName: string, url: string, isUpdate: boolean) => {
        if (!url) {
            return null;
        }

        if (!schoolId) {
            return null;
        }

        // If we dont have a question topic origin key, just save an empty array
        const TopicJsonString = questionTopicOriginKey == undefined ? JSON.stringify([]) : JSON.stringify([questionTopicOriginKey]);

        const newItem = await updateRecord(schoolEvidenceName, undefined, url, TopicJsonString, subjectId, isUpdate);

        // Add to the list.
        if (newItem) {
            schoolEvidenceManager.setModels([...schoolEvidenceManager.models, newItem]);
        }

        // Run the onUploadComplete event from our parent if we have one.
        if (onUploadComplete && newItem) {
            setHasLoaded(false);
            onUploadComplete(newItem);
        }

        return newItem;
    }, [updateRecord, schoolId, schoolEvidenceManager]);

    // Add a new entry to the additional documents section.
    const [newAdditionalDocumentName, setNewAdditionalDocumentName] = React.useState<string | null>(null);
    const addNewAdditionalDocument = React.useCallback(() => setNewAdditionalDocumentName(''), [setNewAdditionalDocumentName]);
    const clearNewAdditionalDocument = React.useCallback(() => setNewAdditionalDocumentName(null), [setNewAdditionalDocumentName]);
    const isAddingNewAdditionalDocument = !isNullOrUndefined(newAdditionalDocumentName);
    const removeAdditionalDocument = React.useCallback((id: string) => {
        // Archive the current item for this evidence.
        let existing = schoolEvidenceManager.models.find(it => it.id === id);
        if (!existing) {
            return;
        }

        // Update in memory and in the database.
        const changes = { archived: true };
        schoolEvidenceManager.change(id, changes);
        existing = { ...existing, ...changes };
        schoolEvidenceRepository.save(id, existing);
    }, [schoolEvidenceManager, schoolEvidenceRepository]);

    const [searchText, setSearchText] = React.useState<string>('');

    const [isTopicList, setIsTopicList] = React.useState<boolean>(questionTopicOriginKey ? false : true); // If we have a questionTopicOriginKey it means we are attaching to a question, so we should show suggested items first.

    const updateSelectedTopics = React.useCallback((selectedTopics: Array<string>, EvidenceId: string) => {

        let changes = { topicOriginKeysJson: questionTopicOriginKey == undefined ? selectedTopics.length > 0 ? JSON.stringify(selectedTopics) : JSON.stringify([]) : JSON.stringify(questionTopicOriginKey) };

        schoolEvidenceManager.change(EvidenceId, { topicOriginKeysJson: questionTopicOriginKey == undefined ? JSON.stringify([]) : JSON.stringify(questionTopicOriginKey) });
        let existing = schoolEvidenceManager.models.find(item => item.id === EvidenceId);

        if (existing) {
            schoolEvidenceManager.change(existing.id, changes);

            existing = { ...existing, ...changes };
            schoolEvidenceRepository.save(existing.id, existing); // Save the changes
        }
    }, [schoolEvidenceManager, schoolEvidenceRepository]);

    // Tracking which topics and sections are open and which are closed
    const [openTopics, setOpenTopics] = React.useState<Array<string>>([]);
    const [openSections, setOpenSections] = React.useState<Array<string>>([]);

    // Check if the current topic has been set to open
    const isTopicOpen = React.useCallback((currentTopicOriginKey: string) => {
        if (openTopics?.find(item => item === currentTopicOriginKey)) {
            return true;
        } else {
            return false;
        }
    }, [openTopics]);

    // Check if the current section has been set to open
    const isSectionOpen = React.useCallback((currentSectionOriginKey: string) => {
        if (openSections?.find(item => item === currentSectionOriginKey)) {
            return true;
        } else {
            return false;
        }
    }, [openSections]);

    // Swap the topics open status
    const toggleTopicIsOpen = React.useCallback((currentTopicOriginKey: string) => {
        if (openTopics?.find(item => item === currentTopicOriginKey)) {
            // Means the item is already open so we should close it
            setOpenTopics(openTopics.filter(item => item !== currentTopicOriginKey));
        } else {
            // Means the item isnt open so we need to set it to be
            setOpenTopics(openTopics?.concat([currentTopicOriginKey]));
        }
    }, [openTopics, setOpenTopics]);

    // Swap the sections open status
    const toggleSectionIsOpen = React.useCallback((currentSectionOriginKey: string) => {
        if (openSections?.find(item => item === currentSectionOriginKey)) {
            // Means the item is already open so we should close it
            setOpenSections(openSections.filter(item => item !== currentSectionOriginKey));
        } else {
            // Means the item isnt open so we need to set it to be
            setOpenSections(openSections?.concat([currentSectionOriginKey]));
        }
    }, [openSections, setOpenSections]);

    const [isUnassignedOpen, setIsUnassignedOpen] = React.useState<boolean>(false);

    // Swap the unassigned open status
    const toggleIsUnassignedOpen = React.useCallback(() => {
        if (isUnassignedOpen) {
            setIsUnassignedOpen(false);
        } else {
            setIsUnassignedOpen(true);
        }
    }, [isUnassignedOpen, setIsUnassignedOpen]);

    const suggestedEvidence = React.useMemo(() => {
        let ret: Array<string> = [];

        schoolEvidenceManager.models.filter(it => !it.archived).forEach(item => {
            if (!(item?.topicOriginKeysJson === "[]")) {

                let originKeyArray: Array<string> = JSON.parse(item?.topicOriginKeysJson);

                originKeyArray.forEach(it => {
                    // If the evidence has the same topic origin key as the current question, then the evidence should be suggested
                    if (it === questionTopicOriginKey) {

                        // If we have a search value we only show items that fit the criteria
                        const lowerSearchText = searchText.toLocaleLowerCase();
                        if (lowerSearchText) {
                            let isMatch: boolean = false;
                            isMatch = (item.name.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                            if (!isMatch) {
                                isMatch = (item.url.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                            }
                            if (!isMatch && item.blobId) {
                                const fileBlob = fileBlobs.find(it => it.id === item.blobId);
                                isMatch = !!fileBlob && (fileBlob.filename.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                            }

                            // If we found a match for this item, then include it.
                            if (isMatch) {
                                const existing = ret.find(it => it === item.name);
                                if (!existing) {
                                    ret.push(item.name);
                                }
                            }
                        } else {
                            // If we dont have a search value we are fine to include this item
                            const existing = ret.find(it => it === item.name);
                            if (!existing) {
                                ret.push(item.name);
                            }
                        }
                    }
                });
            }
        });
        ret.sort();
        return ret;
    }, [schoolEvidenceManager.models, questionTopicOriginKey, searchText, fileBlobs]);

    const selectAllSuggestedEvidence = React.useCallback(() => {
        // Set all suggested evidence to be selected
        suggestedEvidence.forEach(item => {
            if (!!isSelected) {
                if (!isSelected(item) && !!toggleSelected) {
                    toggleSelected(item);
                }
            }
        });
    }, [isSelected, toggleSelected, suggestedEvidence]);

    // Filtered list of school evidence names that fit inside a topic
    const currentTopicEvidences = React.useCallback((currentTopic: Topic) => {
        let ret: Array<string> = [];

        schoolEvidenceManager.models.filter(it => !it.archived).forEach(item => {
            // Go through each school evidence that has text in topicOriginKeys.Json
            if (!(item?.topicOriginKeysJson === "[]")) {

                let originKeyArray: Array<string> = JSON.parse(item?.topicOriginKeysJson);

                originKeyArray.forEach(it => {
                    if (it === currentTopic.originKey) {

                        // If we have a search value we only show items that fit the criteria
                        const lowerSearchText = searchText.toLocaleLowerCase();
                        if (lowerSearchText) {
                            let isMatch: boolean = false;
                            isMatch = (item.name.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                            if (!isMatch) {
                                isMatch = (item.url.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                            }
                            if (!isMatch && item.blobId) {
                                const fileBlob = fileBlobs.find(it => it.id === item.blobId);
                                isMatch = !!fileBlob && (fileBlob.filename.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                            }

                            // If we found a match for this item, then include it.
                            if (isMatch) {
                                const existing = ret.find(it => it === item.name);
                                if (!existing) {
                                    ret.push(item.name);
                                }
                            }
                        } else {
                            const existing = ret.find(it => it === item.name);
                            if (!existing) {
                                // If we dont have a search value we are fine to include this item
                                ret.push(item.name);
                            }
                        }
                    }
                });
            }
        });

        return ret;
    }, [schoolEvidenceManager, searchText]);

    React.useEffect(() => {
        // If we are on topic screen and have a search value , we want open all topics and sections.
        if (isTopicList === true && !!searchText) {
            sections.forEach(item => {
                let alreadyOpen = openSections.find(it => it === item.originKey);
                // If we havent already set this section to open, then open it
                if (!alreadyOpen) {
                    setOpenSections(openSections.concat([item.originKey]));
                }
            });

            topics.forEach(item => {
                let alreadyOpen = openTopics.find(it => it === item.originKey);
                // If we havent already set this topic to open, then open it
                if (!alreadyOpen) {
                    setOpenTopics(openTopics.concat([item.originKey]));
                }
            });

            setIsUnassignedOpen(true);
        }
    }, [isTopicList, searchText, sections, topics, openSections, setOpenSections, openTopics, setOpenTopics]);

    const unassignedEvidence = React.useMemo(() => {
        let ret: Array<string> = [];
        let filteredSchoolEvidence = schoolEvidenceManager.models.filter(item => item.subjectId === null);

        // If we don't have a subjectId we want to show all evidence that has no subjectId
        if (subjectId) {
            filteredSchoolEvidence = schoolEvidenceManager.models.filter(item => item.subjectId === subjectId);
        }

        filteredSchoolEvidence.filter(it => !it.archived).forEach(item => {
            if (item.topicOriginKeysJson === "[]") {
                // Any evidence that has an empty topicOriginKeyJson means it hasnt been assigned anything yet.

                // If we have a search value we only show items that fit the criteria
                const lowerSearchText = searchText.toLocaleLowerCase();
                if (lowerSearchText) {
                    let isMatch: boolean = false;
                    isMatch = (item.name.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                    if (!isMatch) {
                        isMatch = (item.url.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                    }
                    if (!isMatch && item.blobId) {
                        const fileBlob = fileBlobs.find(it => it.id === item.blobId);
                        isMatch = !!fileBlob && (fileBlob.filename.toLocaleLowerCase().indexOf(lowerSearchText) !== -1);
                    }

                    // If we found a match for this item, then include it.
                    if (isMatch) {
                        ret.push(item.name);;
                    }
                } else {
                    // If we dont have a search value we are fine to include this item
                    ret.push(item.name);
                }
            }
        });
        return ret;

    }, [schoolEvidenceManager, searchText, fileBlobs, subjectId]);

    const checkIfSectionWillHaveTopics = React.useCallback((sectionId: string) => {
        // When we have a search value, we are hiding some of the topics with conditional fragments. Because of this we need to check if a section will contain any topics
        let currentSectionTopics = topics.filter(item => item.sectionId === sectionId);
        let anyTopicsContainItems = false;
        currentSectionTopics.forEach(item => {
            if (currentTopicEvidences(item).length > 0) {
                // Means we found a topic that would show evidence with the current search criteria
                anyTopicsContainItems = true;
            }
        });
        if (anyTopicsContainItems) {
            // We dont want to show the no evidence message if we found items to show
            return false;
        } else {
            return true;
        }

    }, [searchText, topics]);

    // Perform a rename of an item in the evidence locker (including its history ane links).
    const [performRename] = useAsyncCallback(async (name: string, newName: string) => {
        // Rename on the database
        await renameEvidence(schoolId, name, newName);

        // Now reload the view model.
        await load();
    }, [renameEvidence, schoolId, load]);

    // Handle changing the subjectId
    const handleChangeSubject = React.useCallback((subjectId: string) => {
        setHasLoaded(false);

        // We want to reload the page with the new subjectId
        navigation.push(`/school/${schoolId}/evidenceLocker/${subjectId}`);
    }, [setHasLoaded, navigation, schoolId]);

    if (!hasLoaded) {
        return (<LoadingIndicator />);
    }

    return (
        <>
            <ConditionalFragment showIf={uploadMessage !== ''}>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <Alert color="warning">
                        {uploadMessage}
                    </Alert>
                </Row>
            </ConditionalFragment>

            <div className="mb-2">
                <Row>
                    <ConditionalFragment showIf={!isModal}>
                        <ConditionalFragment showIf={!isSubjectLeader && !isMultipleSubjectLeader}>
                            <Col>
                                <Input type="select" name="subject" id="subject" label="Subject" value={subjectId ?? ''}
                                    onChange={(e) => handleChangeSubject(e.currentTarget.value)}>
                                    <option value="">School evidence locker</option>
                                    {projectSubjects?.map(subject => (
                                        <option key={subject.id} value={subject.id}>{subject.name} evidence locker</option>
                                    ))}
                                </Input>
                            </Col>
                        </ConditionalFragment>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isModal}>
                        <ConditionalFragment showIf={isMultipleSubjectLeader}>
                            <Col>
                                <Input type="select" name="subject" id="subject" label="Subject" value={subjectId ?? ''}
                                    onChange={(e) => handleChangeSubject(e.currentTarget.value)}>
                                    {projectSubjects?.filter(item => mySubjects?.includes(item.id)).map(subject => (
                                        <option key={subject.id} value={subject.id}>{subject.name} evidence locker</option>
                                    ))}
                                </Input>
                            </Col>
                        </ConditionalFragment>
                    </ConditionalFragment>
                    <Col>
                        <SearchAsYouTypeInput onSearch={value => setSearchText(value)} placeholder="Search evidence locker" />
                    </Col>
                    <Col xs={12} sm="auto">
                        <Button color="secondary" onClick={() => addNewAdditionalDocument()}>
                            Add a document
                        </Button>
                    </Col>
                </Row>
            </div>

            <AlertOnErrors errors={[loadingErrors]} />

            <ConditionalFragment showIf={!!questionTopicOriginKey}>
                { /*Navigating between showing the suggested evidence for the question and showing all evidence sorted into sections and topics*/}
                { /*Should only be visible if we are attaching to a question (cant see suggested if we dont have a question topic)*/}
                <Row>
                    <Button style={{ marginLeft: 10 }} color="secondary" outline={isTopicList} onClick={() => setIsTopicList(false)}>
                        Suggested Items
                    </Button>
                    <Button style={{ marginLeft: 5 }} color="secondary" outline={!isTopicList} onClick={() => setIsTopicList(true)}>
                        Topic List
                    </Button>
                </Row>
                <hr />
            </ConditionalFragment>

            <ConditionalFragment showIf={!isTopicList}>
                { /*Show all suggested evidence if we are attaching to a question*/}
                <Row>
                    <Col>
                        <h3>Suggested Evidence</h3>
                    </Col>
                    <Col xs={12} sm="auto">
                        <Button color="primary" outline onClick={() => selectAllSuggestedEvidence()}>
                            Select all suggested
                        </Button>
                    </Col>
                </Row>
                {suggestedEvidence.map(schoolEvidenceName => {
                    var myModel = schoolEvidenceManager.models.find(item => item.name === schoolEvidenceName && !item.archived);
                    var history = schoolEvidenceManager.models.filter(item => item.name === schoolEvidenceName && item.archived);
                    return (
                        <EvidenceItem key={schoolEvidenceName}
                            model={myModel}
                            schoolEvidenceName={schoolEvidenceName}
                            uploadFile={async (file, isUpdate) => { uploadFile(schoolEvidenceName, file, isUpdate); }}
                            isFileUploading={isFileUploading(schoolEvidenceName)}
                            fileBlob={fileBlobs.find(it => myModel && it.id === myModel.blobId)}
                            updateUrl={async (url, isUpdate) => { updateUrl(schoolEvidenceName, url, isUpdate); }}
                            history={history}
                            historyFileBlobs={fileBlobs /* No need to filter */}
                            canRemove={canRemove} remove={id => removeAdditionalDocument(id)}
                            canSelect={canSelect}
                            isSelected={isSelected && isSelected(schoolEvidenceName)}
                            toggleSelected={() => {
                                if (!toggleSelected) {
                                    return;
                                }

                                UpdateTopicJson(schoolEvidenceName);
                            }}
                            canViewHistory={canViewHistory}
                            renameEvidence={async (newName) => { await performRename(schoolEvidenceName, newName); }}
                            sections={sections}
                            topics={topics}
                            updateTopicJson={updateSelectedTopics}
                            questionTopicOriginKey={questionTopicOriginKey}
                        />
                    );
                })}
            </ConditionalFragment>


            <div className="evidence-locker-evidence-items">
                {/* Subject Evidence Locker */}
                <ConditionalFragment showIf={isTopicList && !!subjectId}>
                    { /*Show all evidence organised into sections and topics*/}
                    {subjectSections.map(section => {
                        // Only show if we have something to show.
                        let evidenceCount = 0;
                        for (const topic of subjectTopics.filter(item => item.sectionId === section.id)) {
                            var myEvidence = currentTopicEvidences(topic);
                            evidenceCount += myEvidence.length;
                        }

                        if (!evidenceCount) {
                            return null;
                        }

                        return (
                            <Card>
                                <CardHeader>
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleSectionIsOpen(section.originKey)}>
                                        <div style={{ textDecoration: 'underline', display: 'inline-block' }}>
                                            {replacePlaceholders(section.name, placeholderReplacementMode)}
                                        </div>
                                        <> </>
                                        <FontAwesomeIcon icon={isSectionOpen(section.originKey) ? 'caret-up' : 'caret-down'} />
                                    </div>
                                </CardHeader>
                                <ConditionalFragment showIf={subjectTopics.filter(item => item.sectionId === section.id).length === 0 || (subjectTopics.filter(item => item.sectionId === section.id).length === 0 && !!searchText && checkIfSectionWillHaveTopics(section.id))}>
                                    <CardBody>
                                        <div className="nothing-here">
                                            <div className="no-results-found-icon">
                                                <FontAwesomeIcon icon={['far', 'frown']} />
                                            </div>
                                            <p>No evidence found for this section.</p>
                                        </div>
                                    </CardBody>
                                </ConditionalFragment>

                                <Collapse isOpen={isSectionOpen(section.originKey)}>
                                    {subjectTopics.filter(item => item.sectionId === section.id).map(topic => {
                                        // Only show if we have something to show.
                                        var myEvidence = currentTopicEvidences(topic);
                                        if (!myEvidence.length) {
                                            return null;
                                        }

                                        return (
                                            <ConditionalFragment showIf={!searchText || (!!searchText && currentTopicEvidences(topic).length > 0)}>
                                                <Card style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}>
                                                    <CardHeader>
                                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleTopicIsOpen(topic.originKey)}>
                                                            {replacePlaceholders(topic.name, placeholderReplacementMode)}
                                                            <> </>
                                                            <FontAwesomeIcon icon={isTopicOpen(topic.originKey) ? 'caret-up' : 'caret-down'} />
                                                        </div>
                                                    </CardHeader>
                                                    <Collapse isOpen={isTopicOpen(topic.originKey)}>
                                                        <CardBody>
                                                            {
                                                                currentTopicEvidences(topic).map(schoolEvidenceName => {
                                                                    var myModel = schoolEvidenceManager.models.find(item => item.name === schoolEvidenceName && !item.archived);
                                                                    var history = schoolEvidenceManager.models.filter(item => item.name === schoolEvidenceName && item.archived);
                                                                    return (
                                                                        <EvidenceItem key={schoolEvidenceName}
                                                                            model={myModel}
                                                                            schoolEvidenceName={schoolEvidenceName}
                                                                            uploadFile={async (file, isUpdate) => { uploadFile(schoolEvidenceName, file, isUpdate); }}
                                                                            isFileUploading={isFileUploading(schoolEvidenceName)}
                                                                            fileBlob={fileBlobs.find(it => myModel && it.id === myModel.blobId)}
                                                                            updateUrl={async (url, isUpdate) => { updateUrl(schoolEvidenceName, url, isUpdate); }}
                                                                            history={history}
                                                                            historyFileBlobs={fileBlobs /* No need to filter */}
                                                                            canRemove={canRemove} remove={id => removeAdditionalDocument(id)}
                                                                            canSelect={canSelect}
                                                                            isSelected={isSelected && isSelected(schoolEvidenceName)}
                                                                            toggleSelected={() => {
                                                                                if (!toggleSelected) {
                                                                                    return;
                                                                                }

                                                                                UpdateTopicJson(schoolEvidenceName);
                                                                            }}
                                                                            canViewHistory={canViewHistory}
                                                                            renameEvidence={async (newName) => { await performRename(schoolEvidenceName, newName); }}
                                                                            sections={subjectSections}
                                                                            topics={subjectTopics}
                                                                            updateTopicJson={updateSelectedTopics}
                                                                            questionTopicOriginKey={questionTopicOriginKey}
                                                                        />
                                                                    );
                                                                })
                                                            }
                                                            <ConditionalFragment showIf={currentTopicEvidences(topic).length === 0}>
                                                                <div className="nothing-here">
                                                                    <div className="no-results-found-icon">
                                                                        <FontAwesomeIcon icon={['far', 'frown']} />
                                                                    </div>
                                                                    <p>No evidence found for this topic.</p>
                                                                </div>
                                                            </ConditionalFragment>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            </ConditionalFragment>
                                        );
                                    })}
                                </Collapse>
                            </Card>
                        );
                    })}
                </ConditionalFragment>

                {/* School Evidence Locker */}
                <ConditionalFragment showIf={isTopicList && !subjectId && !isSubjectLeader && !isMultipleSubjectLeader}>
                    { /*Show all evidence organised into sections and topics*/}
                    {sections.map(section => {
                        // Only show if we have something to show.
                        let evidenceCount = 0;
                        for (const topic of topics.filter(item => item.sectionId === section.id)) {
                            var myEvidence = currentTopicEvidences(topic);
                            evidenceCount += myEvidence.length;
                        }

                        if (!evidenceCount) {
                            return null;
                        }

                        return (
                            <Card>
                                <CardHeader>
                                    <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleSectionIsOpen(section.originKey)}>
                                        <div style={{ textDecoration: 'underline', display: 'inline-block' }}>
                                            {replacePlaceholders(section.name, placeholderReplacementMode)}
                                        </div>
                                        <> </>
                                        <FontAwesomeIcon icon={isSectionOpen(section.originKey) ? 'caret-up' : 'caret-down'} />
                                    </div>
                                </CardHeader>
                                <ConditionalFragment showIf={topics.filter(item => item.sectionId === section.id).length === 0 || (!!searchText && checkIfSectionWillHaveTopics(section.id))}>
                                    <CardBody>
                                        <div className="nothing-here">
                                            <div className="no-results-found-icon">
                                                <FontAwesomeIcon icon={['far', 'frown']} />
                                            </div>
                                            <p>No evidence found for this section.</p>
                                        </div>
                                    </CardBody>
                                </ConditionalFragment>

                                <Collapse isOpen={isSectionOpen(section.originKey)}>
                                    {topics.filter(item => item.sectionId === section.id).map(topic => {
                                        // Only show if we have something to show.
                                        var myEvidence = currentTopicEvidences(topic);
                                        if (!myEvidence.length) {
                                            return null;
                                        }

                                        return (
                                            <ConditionalFragment showIf={!searchText || (!!searchText && currentTopicEvidences(topic).length > 0)}>
                                                <Card style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}>
                                                    <CardHeader>
                                                        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleTopicIsOpen(topic.originKey)}>
                                                            {replacePlaceholders(topic.name, placeholderReplacementMode)}
                                                            <> </>
                                                            <FontAwesomeIcon icon={isTopicOpen(topic.originKey) ? 'caret-up' : 'caret-down'} />
                                                        </div>
                                                    </CardHeader>
                                                    <Collapse isOpen={isTopicOpen(topic.originKey)}>
                                                        <CardBody>
                                                            {
                                                                currentTopicEvidences(topic).map(schoolEvidenceName => {
                                                                    var myModel = schoolEvidenceManager.models.find(item => item.name === schoolEvidenceName && !item.archived);
                                                                    var history = schoolEvidenceManager.models.filter(item => item.name === schoolEvidenceName && item.archived);

                                                                    return (
                                                                        <EvidenceItem key={schoolEvidenceName}
                                                                            model={myModel}
                                                                            schoolEvidenceName={schoolEvidenceName}
                                                                            uploadFile={async (file, isUpdate) => { uploadFile(schoolEvidenceName, file, isUpdate); }}
                                                                            isFileUploading={isFileUploading(schoolEvidenceName)}
                                                                            fileBlob={fileBlobs.find(it => myModel && it.id === myModel.blobId)}
                                                                            updateUrl={async (url, isUpdate) => { updateUrl(schoolEvidenceName, url, isUpdate); }}
                                                                            history={history}
                                                                            historyFileBlobs={fileBlobs /* No need to filter */}
                                                                            canRemove={canRemove} remove={id => removeAdditionalDocument(id)}
                                                                            canSelect={canSelect}
                                                                            isSelected={isSelected && isSelected(schoolEvidenceName)}
                                                                            toggleSelected={() => {
                                                                                if (!toggleSelected) {
                                                                                    return;
                                                                                }

                                                                                UpdateTopicJson(schoolEvidenceName);
                                                                            }}
                                                                            canViewHistory={canViewHistory}
                                                                            renameEvidence={async (newName) => { await performRename(schoolEvidenceName, newName); }}
                                                                            sections={sections}
                                                                            topics={topics}
                                                                            updateTopicJson={updateSelectedTopics}
                                                                            questionTopicOriginKey={questionTopicOriginKey}
                                                                        />
                                                                    );
                                                                })
                                                            }
                                                            <ConditionalFragment showIf={currentTopicEvidences(topic).length === 0}>
                                                                <div className="nothing-here">
                                                                    <div className="no-results-found-icon">
                                                                        <FontAwesomeIcon icon={['far', 'frown']} />
                                                                    </div>
                                                                    <p>No evidence found for this topic.</p>
                                                                </div>
                                                            </ConditionalFragment>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            </ConditionalFragment>
                                        );
                                    })}
                                </Collapse>
                            </Card>
                        );
                    })}
                </ConditionalFragment>

                {/* unassignedEvidence */}
                <ConditionalFragment showIf={isTopicList && !!unassignedEvidence.length}>
                    <Card>
                        { /*Show all evidence that havent been attached yet (or at least have an empty topicOriginKeyJson)*/}
                        <CardHeader>
                            <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => toggleIsUnassignedOpen()}>
                                <div style={{ textDecoration: 'underline', display: 'inline-block' }}>
                                    {subjectName ? `(Additional evidence for ${subjectName} subject leadership review)` : slrSubjectName ? `(Additional evidence for ${slrSubjectName} subject leadership review)` : "(Evidence that has not been categorised)"}
                                </div>
                                <> </>
                                <FontAwesomeIcon icon={isUnassignedOpen ? 'caret-up' : 'caret-down'} />
                            </div>
                        </CardHeader>
                        <Collapse isOpen={isUnassignedOpen}>
                            <CardBody>
                                {
                                    unassignedEvidence.map(schoolEvidenceName => {
                                        var myModel = schoolEvidenceManager.models.find(item => item.name === schoolEvidenceName && !item.archived);
                                        var history = schoolEvidenceManager.models.filter(item => item.name === schoolEvidenceName && item.archived);

                                        // If we have a subjectId we want to show the sections and topics for that subject
                                        const mySections = subjectId ? subjectSections : sections;
                                        const myTopics = subjectId ? subjectTopics : topics;

                                        return (
                                            <EvidenceItem key={schoolEvidenceName}
                                                model={myModel}
                                                schoolEvidenceName={schoolEvidenceName}
                                                uploadFile={async (file, isUpdate) => { uploadFile(schoolEvidenceName, file, isUpdate); }}
                                                isFileUploading={isFileUploading(schoolEvidenceName)}
                                                fileBlob={fileBlobs.find(it => myModel && it.id === myModel.blobId)}
                                                updateUrl={async (url, isUpdate) => { updateUrl(schoolEvidenceName, url, isUpdate); }}
                                                history={history}
                                                historyFileBlobs={fileBlobs /* No need to filter */}
                                                canRemove={canRemove} remove={id => removeAdditionalDocument(id)}
                                                canSelect={canSelect}
                                                isSelected={isSelected && isSelected(schoolEvidenceName)}
                                                toggleSelected={() => {
                                                    if (!toggleSelected) {
                                                        return;
                                                    }

                                                    UpdateTopicJson(schoolEvidenceName);
                                                }}
                                                canViewHistory={canViewHistory}
                                                renameEvidence={async (newName) => { await performRename(schoolEvidenceName, newName); }}
                                                sections={mySections}
                                                topics={myTopics}
                                                updateTopicJson={updateSelectedTopics}
                                                questionTopicOriginKey={questionTopicOriginKey}
                                            />
                                        );
                                    })
                                }
                            </CardBody>
                        </Collapse>
                    </Card>
                </ConditionalFragment>

                <ConditionalFragment showIf={!isTopicList && suggestedEvidence.length === 0}>
                    <div className="text-muted text-center" style={{ width: '100%', paddingLeft: '8px', paddingRight: '8px', marginBottom: '8px' }}>
                        <div style={{ fontSize: '78px' }}>
                            <FontAwesomeIcon icon="cloud-upload-alt" />
                        </div>
                        {
                            !!searchText ? (
                                <p>
                                    Your evidence locker does not contain any matches for "{searchText}".
                                </p>
                            ) : (
                                <p>
                                    We have no suggestions for you from your current evidence locker. As you continue to upload evidence, more suggestions will be made available.
                                </p>
                            )
                        }
                        <p>
                            Your evidence locker automatically captures all uploaded documents and links that you use
                            as evidence for your school.  You can add new documents or links at any time using the "Add a document" button below, and reuse
                            your documents as evidence against multiple questions in the future without the need to re-upload any of the documents.
                        </p>
                    </div>
                </ConditionalFragment>
            </div>
            <div>
                <ConditionalFragment showIf={isAddingNewAdditionalDocument}>
                    <Modal size="xl" isOpen={isAddingNewAdditionalDocument} toggle={() => clearNewAdditionalDocument()}>
                        <ModalHeader toggle={() => clearNewAdditionalDocument()}>
                            Add a document
                        </ModalHeader>
                        <ModalBody tag="div">
                            <EvidenceItem
                                model={null}
                                schoolEvidenceName={newAdditionalDocumentName || ''}
                                uploadFile={async (file, isUpdate) => {
                                    // If no name has been set do nothing.
                                    if (!newAdditionalDocumentName) {
                                        return;
                                    }

                                    // Upload the new file.
                                    const newItem = await uploadFile(newAdditionalDocumentName, file, isUpdate);

                                    // If we can select then automatically select the new item.
                                    if (canSelect && newItem) {
                                        if (toggleSelected) {
                                            UpdateTopicJson(newItem.name);
                                        }
                                    }

                                    // Clear this as a new item as it now is a saved item.
                                    clearNewAdditionalDocument();
                                }}
                                isFileUploading={isFileUploading(newAdditionalDocumentName || '')}
                                fileBlob={undefined}
                                updateUrl={async (url, isUpdate) => {
                                    // Update the URL as a new entry int he history.
                                    const newItem = await updateUrl(newAdditionalDocumentName || '', url, isUpdate);

                                    // If we can select then automatically select the new item.
                                    if (canSelect && newItem) {
                                        if (toggleSelected) {
                                            toggleSelected(newItem.name);
                                        }
                                    }

                                    // Clear this as a new item as it now is a saved item.
                                    clearNewAdditionalDocument();
                                }}
                                history={[]}
                                historyFileBlobs={[]}
                                canEditName={true}
                                setSchoolEvidenceName={value => setNewAdditionalDocumentName(value)}
                                canRemove={false} remove={() => { }}
                                canSelect={false}
                                isSelected={false}
                                toggleSelected={() => { /* Do nothing */ }}
                                canViewHistory={canViewHistory}
                                questionTopicOriginKey={questionTopicOriginKey}
                                sections={sections}
                                topics={topics}
                                updateTopicJson={updateSelectedTopics}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" outline onClick={() => clearNewAdditionalDocument()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </ConditionalFragment>
            </div>
        </>
    );
};
