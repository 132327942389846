import * as React from "react";
import { Validator, ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { connect } from "react-redux";
import { AppStateCore } from "../../../store";
import { AnyAction } from "redux";
import { selectSubscription } from "../../../store/user/actions/selectSubscription";
import { SchoolTerm } from "../../../api/models/SchoolTerm";


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<SchoolTerm>,
    loadViewModel: (id?: string) => Promise<SchoolTerm>
}

export interface EditUiProps extends EditUiPropsBase<SchoolTerm> {
}


export const _EditContainer = (props: EditContainerProps) => {
    let { component, repository, loadViewModel } = props;
    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');
    const isCreate = id ? false : true;

    const [model, setModel] = React.useState<SchoolTerm | undefined>(undefined);
    
    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<SchoolTerm>) => {
        setModel(prevState => ({
            ...(prevState as SchoolTerm),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let model: SchoolTerm | undefined = undefined;
        let result = await loadViewModel(id);
        setModel(result);
        //setLicenses(result.licenses);
        return true;
    }, [setModel, id, repository, isCreate]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('name')) {
            validation.singleCheck('name', () => !model.name, 'Name is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('startDate')) {
            validation.singleCheck('startDate', () => !model.startDate, 'Start date is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('endDate')) {
            validation.singleCheck('endDate', () => !model.endDate, 'End date is required');
        }

    }, [model]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }
        await repository.save(model.id, model, isCreate);
        
        //return false;
        return true;
    }, [model, validate, repository, isCreate]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component model={model} changeModel={changeModel}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            validate={validate} validationErrors={validationErrors}
            isCreate={isCreate}
        />
        );
};

export const __EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.schoolTerms.repository(),
    loadViewModel: services.api.schoolTerms.viewModels.edit()
}))(_EditContainer);

export const EditContainer = connect(
    /*mapStateToProps*/
    (state: AppStateCore) => ({
        currentSubscription: state.user.identity ? state.user.identity.subscriptionId : ''
    }),

    /*mapDispatchToProps*/
    (dispatch: React.Dispatch<AnyAction>) => ({
        selectSubscription: (subscriptionId: string | undefined) => dispatch(selectSubscription(subscriptionId))
    })
)(__EditContainer);
