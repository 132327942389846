import * as React from 'react';
import { ActionUserTask } from '../../api/models/ActionUserTask';
import { Row, Col, FormGroup, Label, Button, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import moment from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { actionStates, ActionState } from '../../services/actionStates/actionStates';
import { UserProfile } from '../../api/models/UserProfile';
import { ElasticInput } from 'reactstrap-elasticinput';
import { ValidatedDateTimeInput } from '../shared/ValidatedDateTimeInput';

export interface EditActionUserTaskProps {
    model: ActionUserTask,
    change: (changes: Partial<ActionUserTask>) => void,
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    userProfiles: Array<UserProfile> | undefined | null,

    onTaskStateChanged?: (state: ActionState) => void,

    saveDebounce: () => void,
}

/**
 * Edit the task within an action.
 * @param props
 */
export const EditActionUserTask = (props: EditActionUserTaskProps) => {
    const {
        model, change, remove,
        /*validate,*/ validationErrors,
        userProfiles,
        saveDebounce,
        onTaskStateChanged,
    } = props;

    const taskState = React.useMemo(() => actionStates.findById(model.actionState), [model]);

    // Change the state of this task
    const changeState = React.useCallback((state: ActionState) => {
        change({
            actionState: state.id,
            completedDate: state.isCompleted? moment().toISOString(): null,
        });

        if (onTaskStateChanged) {
            onTaskStateChanged(state);
        }
        

        saveDebounce();
    }, [change, saveDebounce, onTaskStateChanged]);

    return (
        <Card>
            <CardBody tag="div">
                <FormGroup>
                    <Row>
                        <Col>
                            <Label htmlFor="tasksHtml">Task</Label>
                        </Col>
                        <ConditionalFragment showIf={taskState.isCompleted}>
                            <Col xs="auto">
                                <div className="text-success">
                                    <FontAwesomeIcon icon="check-circle" />
                                    <span className="sr-only">Completed</span>
                                </div>
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <ElasticInput value={model.tasks || ''} onChange={e => change({ tasks: e.currentTarget.value })} onBlur={() => saveDebounce()} />
                        </Col>
                        <Col xs="auto">
                            <Button color="link" className="text-danger" onClick={() => { remove(); saveDebounce(); }}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <span className="sr-only">Delete action</span>
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
                <Row>
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="userId">Assigned to</Label>
                            <ValidatedInput name="name" autoComplete="off" type="select" value={model.userId || ''} onChange={e => change({ userId: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors}>
                                <option value="">(No user assigned yet)</option>
                                {
                                    (userProfiles || []).map(item => (
                                        <option key={item.id} value={item.id}>{item.forename} {item.surname}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md="auto">
                        <FormGroup>
                            <Label htmlFor="actionAddedDate">Target date</Label>
                            <ValidatedDateTimeInput name="actionAddedDate" type="date" value={model.actionAddedDate || ''} onChange={e => change({ actionAddedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md="auto">
                        <FormGroup>
                            <ConditionalFragment showIf={!!model.completedDate}>
                                <Label htmlFor="completedDate">
                                    Completed on
                                </Label>
                                <Row>
                                    <Col>
                                        <ValidatedDateTimeInput name="completedDate" type="date" value={model.completedDate || ''} onChange={e => change({ completedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="danger" outline onClick={() => changeState(actionStates.outstanding)}>
                                            <FontAwesomeIcon icon="undo" />
                                            <> </>Undo task completion
                                        </Button>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!model.completedDate}>
                                <Label htmlFor="completedDate">&nbsp;</Label>
                                <div>
                                    <Button color="success" outline onClick={() => changeState(actionStates.completed)}>
                                        <FontAwesomeIcon icon="check" />
                                        <> </>
                                        Complete task
                                    </Button>
                                </div>
                            </ConditionalFragment>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        );
}