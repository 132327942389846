import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { BasicPagedListContainerProps, BasicPagedListContainer } from "../../containers/common/basicContainers/BasicPagedListContainer";
import { PagedListUiPropsBase } from "../../containers/common/PagedListUiPropsBase";
import { QuestionSet } from "../../../api/models/QuestionSet";

export interface DataItem {
    originKey: string,
    name: string,
    versions: Array<{
        id: string,
        name: string,
        versionNumber: number,
        patchNumber: number,
        publishDate: string | undefined
    }>
}

export interface ListUiProps extends PagedListUiPropsBase<DataItem> {
    filter?: (items: Array<any>) => Array<any>,
    baseRoute?: string,
}

export const ListContainer = withServiceProps<BasicPagedListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.questionSets.viewModels.list()
}))(BasicPagedListContainer);
