import * as React from "react";
import { ValidationState, ValidationErrors } from "pojo-validator";
import { ContainerComponentProps } from "react-withcontainer";
import { useAsyncCallback } from 'react-use-async-callback';
import { useValidatorCallback } from "pojo-validator-react";
import { Action } from "../../api/models/Action";


export interface AddToImprovementPlannerModalContainerProps extends ContainerComponentProps<AddToImprovementPlannerModalUiProps> {
    model: Action | undefined,
    changeModel: (changes: Partial<Action>) => void,

    save: (id: string, model: Action) => Promise<boolean>,
    cancel: (id: string, model: Action) => void
}

export interface AddToImprovementPlannerModalUiProps {
    model: Action | undefined,
    changeModel: (changes: Partial<Action>) => void,
    isCreate?: boolean,

    validate: (fieldsToCheck?: Array<string>) => boolean,
    validationErrors: ValidationErrors,

    save: () => Promise<boolean>,
    isSaving: boolean,
    savingErrors: any

    cancel: () => void
}

export const AddToImprovementPlannerModalContainer = (props: AddToImprovementPlannerModalContainerProps) => {
    let { component, model, changeModel, ...rest } = props;

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        //if (!fieldsToCheck || fieldsToCheck.includes('description')) {
        //    validation.singleCheck('description', () => !model || !model.description, 'Description is required');
        //}
    }, [model]);
    
    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await props.save(model.id, model);

        return true;
    }, [model, validate, props.save]);

    // Cancel the attempt to add this risk.
    const cancel = React.useCallback(() => {
        if (!model) {
            return;
        }

        props.cancel(model.id, model);
    }, [model, props.cancel]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            cancel={cancel}
            />
    );
};

