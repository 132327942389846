import * as React from 'react';
import Chart from 'react-apexcharts';
import { RiskChartItem } from '../DashboardContainer';
import { isNullOrUndefined } from 'util';
import { useUniversalNavigation } from 'react-universal-navigation';
import { chartLargeColorPallet } from '../../../../utilities/chartLargeColorPallet';
import { useProjectSettings } from '../../../../api/useProjectSettings';

export interface RiskScattergraphChartProps {
    risks: Array<RiskChartItem>,
    chartHeight?: number,

    awardTagId: string | undefined,
}

export const RiskScattergraphChart = (props: RiskScattergraphChartProps) => {
    const navigation = useUniversalNavigation(props);
    const projectSettings = useProjectSettings(props.risks.length && props.risks[0].projectId || '', props.awardTagId);

    // Work out some scales for the chart based on the available data.
    const [xmin, xmax, ymin, ymax] = React.useMemo(() => {
        let xmax = 4;
        let ymax = 6;
        for (let result of props.risks) {
            if (result.operationalLevel > xmax) {
                xmax = result.operationalLevel;
            }

            if (result.impactMultiplier > ymax) {
                ymax = result.impactMultiplier;
            }
        }

        return [0, xmax, 0, ymax];
    }, [props.risks]);

    const options = React.useMemo(() => ({
        yaxis: {
            // Impact multiplier.
            labels: {
                formatter: function (val: any) {
                    let nVal = parseFloat(val);
                    if (nVal > ymax) {
                        return '';
                    }
                    return nVal.toFixed(0);
                }
            },

            min: ymin,
            max: ymax + 1 /* Otherwise 0,ymax points don't plot */,
            tickAmount: ymax + 1
        },

        xaxis: {
            // Operational level
            labels: {
                formatter: function (val: any) {
                    return parseFloat(val).toFixed(0);
                }
            },

            min: xmin,
            max: xmax,
            tickAmount: xmax
        },

        colors: chartLargeColorPallet,
        
        chart: {
            toolbar: {
                show: false,
                tools: {
                    download: false, // Hide the download tool on this graph as the way we have to draw the background gradient doesn't translate well to a saved SVG file.
                }
            },

            events: {
                // When the user clicks on a point in the chart, drill into that item.
                // NOTE dataPointSelection event doesn't work on raidal charts so we have to use the click event.
                click: (event: any, chartContext: any, config: any) => {
                    // If we didn't click on a datapoint, do nothing.
                    if (isNullOrUndefined(config.seriesIndex)) {
                        return;
                    }

                    // We clicked on a datapoint so lets drill into it.
                    let myRisk = props.risks[config.seriesIndex];
                    if (!myRisk) {
                        return;
                    }
                    let thisBaseRoute = projectSettings.baseRoute;
                    if (!!props.risks[config.seriesIndex].schoolId) {
                         thisBaseRoute = `/school/${props.risks[config.seriesIndex].schoolId}/dueDiligenceRiskReport/${myRisk.projectId}`;
                    }
                    thisBaseRoute = thisBaseRoute.replace('/dueDiligence/', '/dueDiligenceRiskReport/');
                    let url = `${thisBaseRoute}?riskId=${myRisk.id}`;
                    navigation.navigate(url);
                },
            },
            background: 'linear-gradient(to top right, rgba(58, 180, 78, 1) 0%, rgba(235, 130, 18, 1) 50%, rgba(247, 0, 0, 1) 100%)'
        },

        tooltip: {
            y: {
                formatter: function (val: any) {
                    return Math.ceil(parseFloat(val)).toFixed(0);
                }
            }
        },

        legend: {
            show: false,
        }
    }), [props.risks, xmin, xmax, ymin, ymax, projectSettings]);

    var series = React.useMemo(() => {
        // Generate the x,y data series but include some adjustment along the y axsis for the purpose of not hiding items under each other.
        let adjustmentForApperance: Array<Array<{ x: number, y: number}>> = [];
        for (let x = xmin; x <= xmax; ++x) {
            let thisEntry = [];
            for (let y = ymin; y <= ymax; ++y) {
                thisEntry[y] = { x: 0, y: 0 };
            }

            adjustmentForApperance[x] = thisEntry;
        }

        let data = props.risks.map(item => {
            const adjustmentStepSizeY = 0.3;
            const adjustmentStepSizeX = 0.1;
            let adjustmentArray = adjustmentForApperance[Math.ceil(item.operationalLevel)];
            let adjustment = { x: 0, y: 0 };
            if (adjustmentArray) {
                adjustment = adjustmentArray[Math.ceil(item.impactMultiplier)] || { x: 0, y: 0 };
                if (adjustment.y - adjustmentStepSizeY <= -1) {
                    if (adjustment.x - adjustmentStepSizeX <= -1) {
                        adjustmentArray[Math.ceil(item.impactMultiplier)] = { x: 0, y: 0 };
                    } else {
                        adjustmentArray[Math.ceil(item.impactMultiplier)] = { x: adjustment.x - adjustmentStepSizeX, y: 0 };
                    }
                } else {
                    adjustmentArray[Math.ceil(item.impactMultiplier)] = { x: adjustment.x, y: adjustment.y - adjustmentStepSizeY };
                }
            }

            // Special handling if scale is at zero so we don't go off the display area.
            if (item.operationalLevel <= 0) {
                adjustment.x = 0 - adjustment.x;
            }
            if (item.operationalLevel <= 0) {
                adjustment.y = 0 - adjustment.y;
            }

            let ret = { name: item.description, data: [[item.operationalLevel + adjustment.x, item.impactMultiplier + adjustment.y]] };
            return ret;
        });

        return data;
    }, [props.risks]);


    if (!props.risks || !projectSettings.baseRoute) {
        return (<></>);
    }
    
    // Show the chart.
    return (
        <Chart options={options} series={series} type="scatter" height={props.chartHeight ? props.chartHeight: 300} />
    );
};

