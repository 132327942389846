import * as React from "react";
import { Container, Button, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, FormGroup, FormText, Input } from "reactstrap";
import { AlertOnErrors } from "../../shared/AlertOnErrors";
import { ButtonGroup } from "reactstrap";
import { ButtonDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalBody } from "reactstrap";
import { Label } from "reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { ModalFooter } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Spinner } from "reactstrap";
import { SpecificReportUi } from "./useReportGeneratorSpecificReportUi";
import { ReportProps } from "./ReportProps";

export interface OnScreenReportProps extends ReportProps {
    specificReportUi: SpecificReportUi,
}

/**
 * ReportBuilder that appears in ReportGenerator when we are customising what we want to build into the report.
 */
export const OnScreenReport = (props: OnScreenReportProps) => {
    const {
        specificReportUi: SpecificReportUi,
        ...rest
    } = props;
    return (
        <SpecificReportUi.ScreenReport {...rest} />
    );
};