import * as React from "react";
import { ValidationState } from "pojo-validator";
import { ContainerComponentProps } from "react-withcontainer";
import { Join } from "../../api/models/Join";
import { AuthenticationService } from "../../services/AuthenticationService";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { useUniversalNavigation } from "react-universal-navigation";
import { AppStateCore } from "../../store";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { Identity, login } from "../../store/user";
import { PasswordValidation } from "../../services/passwordValidation/PasswordValidation";
import { useAsyncCallback } from "react-use-async-callback";
import { useValidatorCallback } from "pojo-validator-react";
import { EditUiPropsBase } from "../containers/common/EditUiPropsBase";
import { generatePasswordRulesDescrption } from "../../utilities/generatePasswordRulesDescription";


export interface JoinContainerProps extends ContainerComponentProps<JoinUiProps> {
    authenticationService: AuthenticationService,
    loadViewModel: (user: string, token: string) => Promise<any>,
    passwordValidation: PasswordValidation,

    // Usually from redux.
    isAuthenticated: boolean,
    login: (token: string | undefined, identity: Identity | undefined) => void
}

export interface JoinUiProps extends EditUiPropsBase<Join> {
    isAuthenticated: boolean,
    passwordRulesDescription: string,
    alreadyRegistered: boolean,
    acceptTermsAndConditions: boolean,
    toggleAcceptTermsAndConditions: () => void,
}

/**
 * Container that allows us to change the password of the current user.
 * @param props
 */
export const _JoinContainer = (props: JoinContainerProps) => {
    let { component, authenticationService, loadViewModel, passwordValidation, login, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const user = navigation.getParam<string>('user', '');
    const token = navigation.getParam<string>('token', '');

    const [model, setModel] = React.useState<Join | undefined>(undefined);
    const [alreadyRegistered, setAlreadyRegistered] = React.useState<boolean>(false);
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = React.useState<boolean>(false);
    const toggleAcceptTermsAndConditions = React.useCallback(() => setAcceptTermsAndConditions(prevState => !prevState), [setAcceptTermsAndConditions]);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<Join>) => {
        setModel(prevState => ({
            ...(prevState as Join),
            ...changes
        }));
    }, [setModel]);

    // Load with default values form the server and our own code
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        try {
            let result = await loadViewModel(user, token);
            setModel({
                ...result.model,
                user: user,
                token: token,
                newPassword: ''
            });
        } catch (error) {
            if (error.code == 'already_registered') {
                setAlreadyRegistered(true);
                return true;
            }

            throw error;
        }
        
        return true;
    }, [user, token, loadViewModel, setModel]);

    // Validate the model
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('newPassword')) {
            validation.clearErrors('newPassword');
            validation.check('newPassword', () => !model.newPassword, 'Password is required');
            const [isPasswordValid, passwordErrors] = passwordValidation.check(model.newPassword);
            validation.check('newPassword', () => !isPasswordValid, `Password ${passwordErrors.join(', ')}.`)
        }

        // We can only progress if we accept the T&Cs
        if (!fieldsToCheck || fieldsToCheck.includes('acceptTermsAndConditions')) {
            validation.singleCheck('acceptTermsAndConditions', () => !acceptTermsAndConditions, 'You must accept our terms and conditions of use in order to finish your registration.');
        }
    }, [model, acceptTermsAndConditions]);

    // Save the values back to the database.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Confirm the invite and set the user's password.
        let ok = await authenticationService.confirmInvite(model.user, model.token, model.newPassword);
        if (!ok) {
            return false;
        }

        // Login
        let result = await authenticationService.login(model.user, model.newPassword);
        // Save the current login session to the application state.
        login(result.token, result.identity);

        return true;
    }, [authenticationService, login, model, validate]);
    
    // Generate a description of the required password rules by validating an empty password.
    const passwordRulesDescription = React.useMemo(() => generatePasswordRulesDescrption(passwordValidation), [passwordValidation]);

    // Load the model on mount if we've not already loaded it.
    React.useEffect(() => {
        if (!model  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            passwordRulesDescription={passwordRulesDescription}
            alreadyRegistered={alreadyRegistered}
            acceptTermsAndConditions={acceptTermsAndConditions} toggleAcceptTermsAndConditions={toggleAcceptTermsAndConditions}
        />
    );
};

export const __JoinContainer = withServiceProps<JoinContainerProps, AppServicesCore>(services => ({
    authenticationService: services.authenticationService(),
    loadViewModel: services.api.users.viewModels.join(),
    passwordValidation: services.passwordValidation()
}))(_JoinContainer);

export const JoinContainer = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        isAuthenticated: state.user.isAuthenticated
    }),
    /* mapDispatchToProps */
    (dispatch: React.Dispatch<AnyAction>) => ({
        login: (token: string | undefined, identity: Identity | undefined) => dispatch(login(token, identity))
    })
)(__JoinContainer);
