import * as React from 'react';
import { Redirect } from 'react-router';
import { withContainer } from 'react-withcontainer';
import { HomeContainer, HomeUiProps } from './HomeContainer';

export const HomeUi = (props: HomeUiProps) => {
    return (
        <Redirect to={`/school/${props.schoolId}/home`} />
    );
};

export const Home = withContainer(HomeContainer)(HomeUi);
