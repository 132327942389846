import * as React from 'react';
import moment from 'moment';

export interface DateLabelProps {
    value: string,
    localize?: boolean,
    format?: string
}

/**
 * Label that shows a date in local time formatted via moment().
 * @param props
 */
export const DateLabel = (props: DateLabelProps) => {
    const date = moment(props.value);
    const format = props.format || 'DD/MM/YYYY';
    const localize = props.localize || true;

    let theDate = date;
    if (localize) {
        theDate = date.local();
    }


    return (
        <span>{theDate.format(format)}</span>
    );
};
