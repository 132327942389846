import * as React from 'react';
import { Row, Col, Card, CardBody, Table, Progress } from 'reactstrap';
import { SpidergraphCard } from '../../projects/dashboard/charts/SpidergraphCard';
import { SchoolWithoutTrustComparisonTable } from '../../projects/dashboard/tables/SchoolWithoutTrustComparisonTable';
import { TrustComparisonTable } from '../../projects/dashboard/tables/TrustComparisonTable';
import { ProjectAnalysisResult } from '../../../api/models/ProjectAnalaysisResult';
import { Guid } from 'guid-string';
import { getQuestionaireScaleChoices } from '../../../utilities/getQuestionaireScaleChoices';
import { ProjectTopicOverviewOpinionUi } from './ProjectTopicOverviewOpinionUi';
import { PlainTextWithBrs } from '../../shared/PlainTextWithBrs';
import { BlobUrl } from '../../../api/models/BlobUrl';
import { ConditionalFragment } from 'react-conditionalfragment';
import { generateClickableUrl } from '../../../utilities/generateClickableUrl';
import { ReportProps } from '../reportGenerator/ReportProps';
import { replacePlaceholders } from '../../../utilities/replacePlaceholders';


export interface ProjectTopicUiProps extends ReportProps {
    projectTopicId: string
}

/**
 * Question breakdown for a project topic.
 */
export const ProjectTopicUi = (props: ProjectTopicUiProps) => {
    // Find the appropriate items we want to work with.
    let [projectTopic, topic, results, questions, questionaires] = React.useMemo(() => {
        let ps = props.projectTopics.models.find(item => item.id === props.projectTopicId);
        let topicId = '';
        if (ps) {
            topicId = ps.topicId;
        }

        let s = props.topics.find(item => item.id === topicId);

        let r = props.resultsByTopic.find(item => item.projectTopicId === props.projectTopicId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        let q = props.questions.filter(item => item.topicId === topicId);
        let qq = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));

        return [ps, s, rr, q, qq];
    }, [props.projectTopics, props.topics, props.projectTopicId, props.questions, props.resultsByTopic]);

    if (!props.model) {
        return (<></>);
    }


    if (!projectTopic) {
        return (<></>);
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return (
        <div>
            <h5>{topic && replacePlaceholders(topic.name, placeholderReplacementMode) || 'Topic'}</h5>
            {
                topic && topic.description ? (
                    <>
                        <h6>Topic description</h6>
                        <p>
                            <PlainTextWithBrs text={replacePlaceholders(topic.description, placeholderReplacementMode)} />
                        </p>
                    </>
                ) : null
            }
            <h6>Results</h6>
            <Row>
                <Col sm={8}>
                    <SpidergraphCard model={props.model} results={results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={topic && topic.originKey || ''} chartHeight={400} isForSingleSchool={props.isForSingleSchool} awardTag={props.awardTag} placeholderReplacementMode={'general'} />
                </Col>
                <Col sm={4}>
                    <div style={{ maxHeight: '456px', overflowY: 'scroll' }}>
                    {
                        props.results.length > 1 ? (
                                <TrustComparisonTable model={props.model} results={results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={topic && topic.originKey || ''} isForSingleSchool={props.isForSingleSchool} awardTag={props.awardTag} placeholderReplacementMode={props.placeholderReplacementMode} />
                        ) : (
                                    <SchoolWithoutTrustComparisonTable model={props.model} results={results} filterBySectionOriginKey={undefined} filterByTopicOriginKey={topic && topic.originKey || ''} awardTag={props.awardTag} placeholderReplacementMode={props.placeholderReplacementMode} />
                            )
                        }
                    </div>
                </Col>
            </Row>

            <h6>Reviewer responses</h6>
            <div className="table-responsive">
                <Table striped className="table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th><span>Question</span></th>
                            <th><span>Response</span></th>
                            <th><span>Feedback</span></th>
                            <th><span>Supporting documents</span></th>
                            <th><span>Reviewer</span></th>
                            <ConditionalFragment showIf={props.projectSettings.isDueDiligence}>
                                <th><span>Risk?</span></th>
                            </ConditionalFragment>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            questions.filter(item => Guid.isEmpty(item.questionaireId)).map(item => {
                                let response = props.questionResponses.find(it => it.questionId === item.id);
                                let questionChoiceId = '';
                                let userId = '';
                                if (response) {
                                    questionChoiceId = response.questionChoiceId;
                                    userId = response.userId;
                                }

                                let questionChoice = props.questionChoices.find(it => it.id === questionChoiceId);
                                let reviewer = props.reviewers.find(it => it.id === userId);

                                let risk = props.risks.find(it => it.questionId === item.id);

                                const questionResponseSchoolEvidences = props.questionResponseSchoolEvidences.filter(it => !it.archived && response && it.questionResponseId === response.id);

                                // If the question hasn't been answered, skip it.
                                if (!response) {
                                    return null;
                                }

                                return (
                                    <tr key={item.originKey}>
                                        <td><span>{ item.questionNumber }</span></td>
                                        <td><span>
                                            {replacePlaceholders(item.name, props.placeholderReplacementMode)}
                                        </span></td>
                                        <td><span>
                                            {questionChoice ? replacePlaceholders(questionChoice.name, props.placeholderReplacementMode) : response && response.scale > 0 ? response.scale.toString() : ''}
                                        </span></td>
                                        <td><span>
                                            <PlainTextWithBrs text={response && response.feedback || ''} />
                                        </span></td>
                                        <td><span>
                                            {
                                                questionResponseSchoolEvidences ? (
                                                    <>
                                                        {
                                                            questionResponseSchoolEvidences.map(questionResponseSchoolEvidence => {
                                                                const schoolEvidence = props.schoolEvidences.find(it => it.name === questionResponseSchoolEvidence.schoolEvidenceName);
                                                                if (!schoolEvidence) {
                                                                    return null;
                                                                }

                                                                let blob: BlobUrl | undefined;
                                                                if (schoolEvidence && schoolEvidence.blobId) {
                                                                    const blobId = schoolEvidence.blobId;
                                                                    blob = props.blobs.find(it => it.id === blobId);
                                                                }

                                                                return (
                                                                    <div>
                                                                        {schoolEvidence.name} (
                                                                        <>
                                                                            <ConditionalFragment showIf={!!blob}>
                                                                                <a href={blob && blob.url || ''} download={blob && blob.filename || ''} target="_blank">
                                                                                    {blob && blob.filename || ''}
                                                                                </a>
                                                                            </ConditionalFragment>
                                                                            <ConditionalFragment showIf={!!schoolEvidence.url}>
                                                                                <a href={generateClickableUrl(schoolEvidence.url)} target="_blank">
                                                                                    {schoolEvidence.url}
                                                                                </a>
                                                                            </ConditionalFragment>
                                                                        </>
                                                                        )
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                    ) : null
                                                
                                            }
                                        </span></td>
                                        <td><span>
                                            {reviewer && `${reviewer.forename} ${reviewer.surname}`}
                                        </span></td>
                                        <ConditionalFragment showIf={props.projectSettings.isDueDiligence}>
                                            <td><span>
                                                {risk ? 'Yes' : 'No'}
                                            </span></td>
                                        </ConditionalFragment>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>

            {
                questionaires.map(questionaire => (
                    <div key={questionaire.id}>
                        <h6>{questionaire.name} questionnaire</h6>
                        {
                            questionaire.description ? (
                                <>
                                    <p>
                                        <PlainTextWithBrs text={replacePlaceholders(questionaire.description, placeholderReplacementMode)} />
                                    </p>
                                </>
                            ) : null
                        }
                        <div className="table-responsive">
                            <Table striped className="table-sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th><span>Question</span></th>
                                        <th><span>Responses</span></th>
                                        <th><span>Feedback</span></th>
                                        <ConditionalFragment showIf={props.projectSettings.isDueDiligence}>
                                            <th><span>Risk?</span></th>
                                        </ConditionalFragment>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        questions.filter(item => item.questionaireId === questionaire.id).map(item => {
                                            let responses = props.anonymousQuestionResponses.filter(it => it.questionId === item.id);

                                            // Work out the average, highest, and lowest responses.
                                            let total = 0;
                                            let count = 0;
                                            let lowest = 10000;
                                            let highest = -10000;
                                            for (let item of responses) {
                                                total += item.scale;
                                                ++count;

                                                if (item.scale < lowest) {
                                                    lowest = item.scale;
                                                }

                                                if (item.scale > highest) {
                                                    highest = item.scale;
                                                }
                                            }

                                            let average = 0;
                                            if (count > 0) {
                                                average = total / count;
                                            }

                                            const scaleChoices = getQuestionaireScaleChoices();

                                            let percentage = ((average - scaleChoices[0].scale) / (scaleChoices[scaleChoices.length - 1].scale - scaleChoices[0].scale)) * 100.0;
                                            let lowestScaleChoice = scaleChoices.find(it => it.scale === lowest);
                                            let highestScaleChoice = scaleChoices.find(it => it.scale === highest);

                                            let risk = props.risks.find(it => it.questionId === item.id);

                                            return (
                                                <tr key={item.originKey}>
                                                    <td><span>{item.questionNumber}</span></td>
                                                    <td><span>
                                                        {replacePlaceholders(item.name, props.placeholderReplacementMode)}
                                                    </span></td>
                                                    <td><span>
                                                        <Row>
                                                            {
                                                                scaleChoices.map((item, index) => (
                                                                    <Col key={index} className={index === 0 ? 'text-left' : index === scaleChoices.length - 1 ? 'text-right' : 'text-center'}>
                                                                        {replacePlaceholders(item.name, props.placeholderReplacementMode)} <br /><small className="text-muted">(Selected {responses.filter(it => it.scale === item.scale).length} times)</small>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Progress color="primary" value={percentage}>
                                                                    Average of {average.toFixed(1)} out of {scaleChoices[scaleChoices.length - 1].scale.toFixed(1)}
                                                                </Progress>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-center">
                                                                <small className="text-muted">
                                                                    {
                                                                        lowest === highest ? (
                                                                            <>Ratings were all {lowestScaleChoice ? lowestScaleChoice.name : lowest.toFixed(1)}.</>
                                                                        ) : lowestScaleChoice && highestScaleChoice ? (
                                                                            <>Ratings ranged from {lowestScaleChoice.name} to {highestScaleChoice.name}.</>
                                                                            ): null
                                                                    }
                                                                </small>
                                                            </Col>
                                                        </Row>
                                                    </span></td>
                                                    <td><span>
                                                        {
                                                            responses.filter(it => it.feedback).map((it, index) => (
                                                                <Card key={index}>
                                                                    <CardBody>
                                                                        <PlainTextWithBrs text={replacePlaceholders(it.feedback, placeholderReplacementMode)} />
                                                                    </CardBody>
                                                                </Card>
                                                            ))
                                                        }
                                                    </span></td>
                                                    <ConditionalFragment showIf={props.projectSettings.isDueDiligence}>
                                                        <td><span>
                                                            {risk? 'Yes': 'No'}
                                                        </span></td>
                                                    </ConditionalFragment>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    ))
            }

            <ProjectTopicOverviewOpinionUi {...props} projectTopic={projectTopic} />
        </div>        
    );
};
