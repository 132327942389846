import * as React from 'react';
import Chart from 'react-apexcharts';
import { isNullOrUndefined } from 'util';
import { useUniversalNavigation } from 'react-universal-navigation';
import { Project } from '../../../../api/models/Project';
import { chartLargeColorPallet } from '../../../../utilities/chartLargeColorPallet';
import { useProjectSettings } from '../../../../api/useProjectSettings';

export interface RisksAndRecommendationsBarChartProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    data: Array<RiskBarChartGroup>,

    showOtherSchools?: boolean,
    chartHeight?: number,
    awardTagId: string | undefined,
}

export interface RiskBarChartGroup {
    projectId: string,
    schoolId: string,
    name: string,
    items: Array<RiskBarChartItem>,
}

export interface RiskBarChartItem {
    originKey: string,
    label: string,
    count: number,
}

/**
 * Bar chart showing the number of risks/recommendations grouped by topic etc.
 * @param props
 */
export const RisksAndRecommendationsBarChart = (props: RisksAndRecommendationsBarChartProps) => {
    const navigation = useUniversalNavigation(props);
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTagId);

    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index index of the name.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    // Need the unique labels.
    const uniqueLabels = React.useMemo(() => {
        let ret: Array<{ originKey: string, label: string }> = [];
        for (const project of props.data) {
            for (const item of project.items) {
                if (!ret.find(it => it.originKey === item.originKey)) {
                    ret.push({ originKey: item.originKey, label: item.label });
                }
            }
        }
        return ret;
    }, [props.data]);

    const options = React.useMemo(() => ({
        labels: uniqueLabels.map(item => item.label),


        colors: chartLargeColorPallet,

        yaxis: {
            labels: {
                formatter: function (val: any) {
                    return parseFloat(val).toFixed(0);
                }
            },
        },

        chart: {
            toolbar: {
                show: false,
            },

            events: {
                // When the user clicks on a point in the chart, drill into that item.
                // NOTE dataPointSelection event doesn't work on raidal charts so we have to use the click event.
                click: (event: any, chartContext: any, config: any) => {


                    // If we didn't click on a datapoint, do nothing.
                    if (isNullOrUndefined(config.dataPointIndex)) {
                        return;
                    }

                    // We clicked on a datapoint so lets drill into it.
                    let myUniqueLabel = uniqueLabels[config.dataPointIndex];
                    if (!myUniqueLabel) {
                        return;
                    }
                    let myOriginKey = myUniqueLabel.originKey;
                    let myResult = props.data[config.seriesIndex].items.find(r => r.originKey === myOriginKey);
                    if (!myResult) {
                        return;
                    }
                    let mySchoolId = props.model && props.model.schoolId;
                    if (!mySchoolId) {
                        // trust project so jumping into a school report
                        mySchoolId = props.data[config.seriesIndex].schoolId;
                    }

                    // Do the actual navigation
                    let thisBaseRoute = `/school/${mySchoolId}`;
                    let url = `${thisBaseRoute}/recommendationReport/${props.data[config.seriesIndex].projectId}?recommendationId=${myResult.originKey}`;
                    navigation.navigate(url);
                },
            }
        },

    }), [uniqueLabels, projectSettings, props.data]);

    let series = React.useMemo(() => props.data.map(project => ({
        name: project.name,
        data: uniqueLabels.map(label => {
            const match = project.items.find(it => it.originKey === label.originKey);
            return match && match.count || 0;
        }),
    })), [props.data, uniqueLabels]);

    if (!props.data || !projectSettings.baseRoute) {
        return (<></>);
    }

    // Show the chart.
    return (
        <Chart options={options} series={series} type="bar" height={props.chartHeight ? props.chartHeight : 800} />
    );
};

