import * as React from "react";
import { ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { Guid } from "guid-string";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { ProjectTopic } from "../../../api/models/ProjectTopic";
import { Question } from "../../../api/models/Question";
import { QuestionChoice } from "../../../api/models/QuestionChoice";
import { Topic } from "../../../api/models/Topic";
import { QuestionResponse } from "../../../api/models/QuestionResponse";
import { useManagedChildModels, ManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { connect } from "react-redux";
import { AppStateCore } from "../../../store";
import { BlobUploadService } from "../../../services/BlobUploadService";
import { BlobUrl } from "../../../api/models/BlobUrl";
import { IdAndName } from "../../../api/models/IdAndName";
import { Risk } from "../../../api/models/Risk";
import { Action } from "../../../api/models/Action";
import { Project } from "../../../api/models/Project";
import { QuestionSet } from "../../../api/models/QuestionSet";
import { Section } from "../../../api/models/Section";
import { SchoolEvidence } from "../../../api/models/SchoolEvidence";
import { QuestionResponseSchoolEvidence } from "../../../api/models/QuestionResponseSchoolEvidence";
import { Video } from "../../../api/models/Video";
import { Consultant } from "../../../api/models/Consultant";
import { Strength } from "../../../api/models/Strength";
import { ProductTier } from "../../../api/models/codeOnly/ProductTeir";
import { PlaceholderReplacementMode } from "../../../utilities/replacePlaceholders";


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    /* From dependnecy injection */
    repository: Repository<ProjectTopic>,
    loadViewModel: (id: string, awardTagId: string | undefined, tier: string | undefined) => Promise<any>,
    questionResponsesRepository: Repository<QuestionResponse>,
    blobUploadService: BlobUploadService,
    risksRepository: Repository<Risk>,
    strengthsRepository: Repository<Strength>,
    actionsRepository: Repository<Action>,
    projectsRepository: Repository<Project>,
    schoolEvidencesRepository: Repository<SchoolEvidence>,
    questionResponseSchoolEvidencesRepository: Repository<QuestionResponseSchoolEvidence>,
    /* From redux */
    userId: string | undefined

    /* Can be passed in as props */
    id?: string | undefined,

    subjectName?: string | undefined,
}

export interface EditUiProps extends EditUiPropsBase<ProjectTopic> {
    project: Project | undefined,
    topic: Topic | undefined,
    section: Section | undefined,
    questions: Array<Question>,
    questionChoices: Array<QuestionChoice>,
    questionResponses: ManagedChildModels<QuestionResponse>,
    schoolEvidences: ManagedChildModels<SchoolEvidence>,
    questionResponseSchoolEvidences: ManagedChildModels<QuestionResponseSchoolEvidence>,
    risks: ManagedChildModels<Risk>,
    strengths: ManagedChildModels<Strength>,
    actions: ManagedChildModels<Action>,
    questionsHiddenByRestrictionsCount: number,

    selectQuestionChoice: (questionId: string, questionChoiceId: string) => Promise<void>,
    selectingErrors: any,

    fileBlobs: Array<BlobUrl>,
    //uploadFile: (questionId: string, files: FileList) => Promise<void>,
    //isFileUploading: (questionId: string) => boolean,

    changeFeedbackText: (questionId: string, feedback: string) => void,
    saveFeedbackText: (questionId: string, feedback: string) => Promise<void>,
    changeWillDoText: (questionId: string, willDoText: string) => void,
    saveWillDoText: (questionId: string, willDoText: string) => Promise<void>,
    changeWillDoDate: (questionId: string, willDoDate: string) => void,
    saveWillDoDate: (questionId: string, willDoDate: string) => Promise<void>,

    //saveUrl: (questionId: string, urlText: string) => Promise<void>,
    //isSavingUrl: boolean,
    //savingUrlErrors: any,

    nextTopic: IdAndName | undefined,
    previousTopic: IdAndName | undefined,

    hasPermission: boolean,
    hasKeyQuestions: boolean,

    saveSchoolEvidenceNames: (questionId: string, names: Array<string>) => Promise<void>,
    isSavingSchoolEvidenceNames: boolean,
    savingSchoolEvidenceNamesErrors: any,

    awardTagId: string | undefined,

    videos: Array<Video>,
    consultants: Array<Consultant>,
    videoThumbnailBlobs: Array<BlobUrl>,
    consultantPhotoBlobs: Array<BlobUrl>,

    currentTier: ProductTier,

    tierParam: string | undefined | null,
    placeholderReplacementMode: PlaceholderReplacementMode,
    subjectName: string | undefined,
    subjectId: string | undefined,
    isProjectSection?: boolean,
}

export const _EditContainer = (props: EditContainerProps) => {
    let {
        component, repository, loadViewModel, questionResponsesRepository, risksRepository, actionsRepository, projectsRepository,
        schoolEvidencesRepository, questionResponseSchoolEvidencesRepository, strengthsRepository,
        id: idParam,
        ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = idParam || navigation.getParam('id', '');
    const awardTagId = navigation.getParam('awardTagId', '');
    const tierParam = navigation.getParam('tier', '');

    const [model, setModel] = React.useState<ProjectTopic | undefined>(undefined);
    const [project, setProject] = React.useState<Project | undefined>(undefined);
    const [topic, setTopic] = React.useState<Topic | undefined>(undefined);
    const [section, setSection] = React.useState<Section | undefined>(undefined);
    const [questionSet, setQuestionSet] = React.useState<QuestionSet | undefined>(undefined);
    const [questions, setQuestions] = React.useState<Array<Question>>([]);
    const [questionChoices, setQuestionChoices] = React.useState<Array<QuestionChoice>>([]);
    const questionResponses = useManagedChildModels<QuestionResponse>(questionResponsesRepository);
    const schoolEvidences = useManagedChildModels<SchoolEvidence>(schoolEvidencesRepository);
    const questionResponseSchoolEvidences = useManagedChildModels<QuestionResponseSchoolEvidence>(questionResponseSchoolEvidencesRepository);
    const risks = useManagedChildModels<Risk>(risksRepository);
    const strengths = useManagedChildModels<Strength>(strengthsRepository);
    const actions = useManagedChildModels<Action>(actionsRepository);
    const [fileBlobs, setFileBlobs] = React.useState<Array<BlobUrl>>([]);
    const [nextTopic, setNextTopic] = React.useState<IdAndName | undefined>(undefined);
    const [previousTopic, setPreviousTopic] = React.useState<IdAndName | undefined>(undefined);
    const [hasPermission, setHasPermission] = React.useState<boolean>(true);
    const [questionsHiddenByRestrictionsCount, setQuestionsHiddenByRestrictionsCount] = React.useState<number>(0);
    const [videos, setVideos] = React.useState<Array<Video>>([]);
    const [consultants, setConsultants] = React.useState<Array<Consultant>>([]);
    const [videoThumbnailBlobs, setVideoThumbnailBlobs] = React.useState<Array<BlobUrl>>([]);
    const [consultantPhotoBlobs, setConsultantPhotoBlobs] = React.useState<Array<BlobUrl>>([]);
    const [currentTier, setCurrentTier] = React.useState<ProductTier>(ProductTier.Snapshot);
    const [placeholderReplacementMode, setPlaceholderReplacementMode] = React.useState<PlaceholderReplacementMode>('general');

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<ProjectTopic>) => {
        setModel(prevState => ({
            ...(prevState as ProjectTopic),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await props.loadViewModel(id ? id : 'defaults', awardTagId, tierParam || undefined);
        setProject(result.project);
        setTopic(result.topic);
        setSection(result.section);
        setQuestionSet(result.questionSet);
        setQuestions(result.questions);
        setQuestionChoices(result.questionChoices);
        questionResponses.setModels(result.questionResponses);
        schoolEvidences.setModels(result.schoolEvidences);
        questionResponseSchoolEvidences.setModels(result.questionResponseSchoolEvidences);
        risks.setModels(result.risks);
        actions.setModels(result.actions);
        setFileBlobs(result.fileBlobs);
        setNextTopic(result.nextTopic);
        setPreviousTopic(result.previousTopic);
        setHasPermission(result.hasPermission);
        setQuestionsHiddenByRestrictionsCount(result.questionsHiddenByRestrictionsCount);

        setVideos(result.videos);
        setConsultants(result.consultants);
        setVideoThumbnailBlobs(result.videoThumbnailBlobs);
        setConsultantPhotoBlobs(result.consultantPhotoBlobs);

        strengths.setModels(result.strengths);

        setCurrentTier(result.currentTier);

        setPlaceholderReplacementMode(result.placeholderReplacementMode);

        setModel(result.model);

        return true;
    }, [repository, setModel, id, setProject, setTopic, setQuestions, setQuestionChoices, questionResponses, setFileBlobs, setNextTopic,
            setPreviousTopic, setHasPermission, setQuestionsHiddenByRestrictionsCount,
            schoolEvidences, questionResponseSchoolEvidences, awardTagId,
        setVideos, setConsultants, setVideoThumbnailBlobs, setConsultantPhotoBlobs,
        setCurrentTier, tierParam, setPlaceholderReplacementMode,
        ]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }
    }, [model]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model);

        return true;
    }, [model, validate, repository]);

    // Link to a custom entry in the evidence log instead of uploading a file
    const [saveSchoolEvidenceNames, { isExecuting: isSavingSchoolEvidenceNames, errors: savingSchoolEvidenceNamesErrors }] = useAsyncCallback(async (questionId: string, names: Array<string>): Promise<void> => {
        if (!model) {
            return;
        }

        let newQuestionResponseSchoolEvidencesModels = [...questionResponseSchoolEvidences.models];

        // Find the existing item
        let questionResponse = questionResponses.models.find(item => item.questionId === questionId);
        if (!questionResponse) {
            // No current saved answer so create one.
            questionResponse = await questionResponses.addModel({ projectId: model.projectId, questionId: questionId, userId: props.userId, questionChoiceId: undefined });

            // Save to the database
            // NOTE we don't use questionResponses.save() here as that could lead to a race condition.
            await questionResponsesRepository.save(questionResponse.id, questionResponse);
        }

        // Get all current attachments for this question.
        let existingQuestionResponses = questionResponseSchoolEvidences.models.filter(it => !it.archived && questionResponse && it.questionResponseId === questionResponse.id);

        // For each selected name, find an active link, or create one.
        for (const name of names) {
            // If we already have a link, leave it alone.
            const existing = existingQuestionResponses.find(it => it.schoolEvidenceName === name);
            if (!existing) {
                // Otherwise create a link.
                const newItem: QuestionResponseSchoolEvidence = {
                    id: Guid.newGuid(),
                    questionResponseId: questionResponse.id,
                    schoolEvidenceName: name,
                    archived: false,
                };
                questionResponseSchoolEvidencesRepository.save(newItem.id, newItem, true);

                // Add the the new list of models.
                newQuestionResponseSchoolEvidencesModels.push(newItem);
            }

            // Remove this name from the list of existing names.
            existingQuestionResponses = existingQuestionResponses.filter(it => it.schoolEvidenceName !== name);
        }

        // When we get here if anything is left in existingQuestionResponses we should remove them.
        for (const existing of existingQuestionResponses) {
            await questionResponseSchoolEvidencesRepository.remove(existing.id);

            // Remove from the list of models.
            newQuestionResponseSchoolEvidencesModels = newQuestionResponseSchoolEvidencesModels.filter(it => it.id !== existing.id);
        }

        // Use set model as making multiple changes (including removes) to ManagedChildModels within a single
        // state change can cause issues, so we just update the models all at once.
        questionResponseSchoolEvidences.setModels(newQuestionResponseSchoolEvidencesModels);
    }, [questionResponsesRepository, questionResponses, model, questionResponseSchoolEvidences, questionResponseSchoolEvidencesRepository]);

    // Select a choice for a question.
    const [selectQuestionChoice, { isExecuting: isSelecting, errors: selectingErrors }] = useAsyncCallback(async (questionId: string, questionChoiceId: string): Promise<void> => {
        if (!model) {
            return;
        }

        // Find the existing item
        let existing = questionResponses.models.find(item => item.questionId === questionId);
        let remove: boolean = false;
        let unSelectingOption: boolean = false;
        if (existing) {
            //Check if we are unselecting an answer
            let changes;
            if (questionChoiceId == existing.questionChoiceId) {
                //remove = existing.feedback == '' && !existing.blobId && !existing.url;
                unSelectingOption = true;
                changes = { questionChoiceId: '', userId: props.userId || '' };
            } else {
                // Saved answer needs changing.
                changes = { questionChoiceId: questionChoiceId, userId: props.userId || '' };
            }
            questionResponses.change(existing.id, changes);
            existing = { ...existing, ...changes };
        } else {
            // No current saved answer so create one.
            existing = await questionResponses.addModel({ projectId: model.projectId, questionId: questionId, userId: props.userId, questionChoiceId: questionChoiceId });
        }

        if (remove) {
            await questionResponsesRepository.remove(existing.id);
            questionResponses.removeModel(existing.id);
        }
        else {
            // Save to the database
            // NOTE we don't use questionResponses.save() here as that could lead to a race condition.
            await questionResponsesRepository.save(existing.id, existing);
        }

        // If this question has an automatic risk report entry based on its value, add or remove it now.
        let thisQuestion = questions.find(it => it.id === questionId);
        let thisChoice = questionChoices.find(it => it.id === questionChoiceId);
        if (thisQuestion && thisChoice && thisQuestion.autoAddToRiskReport) {
            if (!unSelectingOption && thisChoice.operationalLevel >= thisQuestion.riskReportMinimumOperationalLevel) {
                // Add the risk if it is not already there.
                let risk = risks.models.find(it => it.projectId === model.projectId && it.questionId === questionId && it.isAutoAdded);
                if (!risk) {
                    risk = await risks.addModel({ projectId: model.projectId, topicId: model.topicId, questionId: questionId, questionResponseId: existing.id, isAutoAdded: true, description: thisQuestion.riskReportDescription });
                    // NOTE we don't use risks.save() here as that could lead to a race condition.
                    await risksRepository.save(risk.id, risk, true);
                }
            } else {
                // Remove the risk as it no longer applies.
                let risk = risks.models.find(it => it.projectId === model.projectId && it.questionId === questionId && it.isAutoAdded);
                if (risk) {
                    risks.removeModel(risk.id);
                    // NOTE we don't use risks.save() here as that could lead to a race condition.
                    await risksRepository.remove(risk.id);
                }                
            }
        }

        // If this question has an automatic strength report entry based on its value, add or remove it now.
        // NOTE this also covers excellence statements.
        if (thisQuestion && thisChoice && (thisQuestion.autoAddToStrengthsReport || thisQuestion.autoAddToExcellenceReport)) {



            if (
                !unSelectingOption && thisChoice.operationalLevel !== 0
                && (
                    (thisQuestion.autoAddToStrengthsReport && thisChoice.operationalLevel <= thisQuestion.strengthsReportMinimumOperationalLevel)
                    || (thisQuestion.autoAddToExcellenceReport && thisChoice.operationalLevel <= thisQuestion.excellenceReportMinimumOperationalLevel)
                )
            ) {
                // Work out if we are excellence or strength.  If it is ambigous excellence wins because its considered better.
                const isExcellence = (thisQuestion.autoAddToExcellenceReport && thisChoice.operationalLevel <= thisQuestion.excellenceReportMinimumOperationalLevel);


                // Add the strength if it is not already there.
                let strength = strengths.models.find(it => it.projectId === model.projectId && it.questionId === questionId && it.isAutoAdded);
                if (!strength) {
                    strength = await strengths.addModel({
                        projectId: model.projectId, topicId: model.topicId, questionId: questionId, questionResponseId: existing.id, isAutoAdded: true,
                        description: isExcellence ? thisQuestion.excellenceReportDescription : thisQuestion.strengthsReportDescription,
                        isExcellence: isExcellence,
                    });

                    // NOTE we don't use strengths.save() here as that could lead to a race condition.
                    await strengthsRepository.save(strength.id, strength, true);
                } else {
                    // Update the description and excellence flag if we move between excellence and strength (in either direction).
                    const changes: Partial<Strength> = {
                        description: isExcellence ? thisQuestion.excellenceReportDescription : thisQuestion.strengthsReportDescription,
                        isExcellence: isExcellence,
                    };

                    strength = {
                        ...strength,
                        ...changes,
                    };
                    strengths.change(strength.id, changes);

                    // NOTE we don't use strengths.save() here as that could lead to a race condition.
                    await strengthsRepository.save(strength.id, strength, true);
                }
            } else {
                // Remove the strength as it no longer applies.
                let strenth = strengths.models.find(it => it.projectId === model.projectId && it.questionId === questionId && it.isAutoAdded);
                if (strenth) {
                    strengths.removeModel(strenth.id);
                    // NOTE we don't use risks.save() here as that could lead to a race condition.
                    await strengthsRepository.remove(strenth.id);
                }
            }
        }
    }, [model, questionResponses, props.userId, questions, questionChoices, risks, risksRepository, strengths, strengthsRepository,]);

    // Change (but don't save) the feedback text for a question's reponse.
    const changeFeedbackText = React.useCallback((questionId: string, feedback: string): void => {
        if (!model) {
            return;
        }

        // Find the existing item
        let existing = questionResponses.models.find(item => item.questionId === questionId);
        if (existing) {
            // Saved answer needs changing.
            questionResponses.change(existing.id, { feedback: feedback, userId: props.userId || '' });
        } else {
            // No current saved answer so create one.
            questionResponses.addModel({ projectId: model.projectId, questionId: questionId, userId: props.userId, feedback: feedback });
        }
    }, [model, questionResponses, props.userId]);

    // Save the feedback text for the question's reponse
    const [saveFeedbackText] = useAsyncCallback(async (questionId: string, feedback: string): Promise<void> => {
        let existing = questionResponses.models.find(it => it.questionId === questionId);
        if (!existing) {
            return;
        }

        existing = { ...existing, feedback: feedback, userId: props.userId || '' };

        // Save to the database
        // NOTE we don't use questionResponses.save() here as that could lead to a race condition.
        questionResponsesRepository.save(existing.id, existing);
    }, [model, questionResponses]);

    // Change (but don't save) the will do text for a question's reponse.
    const changeWillDoText = React.useCallback((questionId: string, willDoText: string): void => {
        if (!model) {
            return;
        }

        // Find the existing item
        let existing = questionResponses.models.find(item => item.questionId === questionId);
        if (existing) {
            // Saved answer needs changing.
            questionResponses.change(existing.id, { willDoText: willDoText, userId: props.userId || '' });
        } else {
            // No current saved answer so create one.
            questionResponses.addModel({ projectId: model.projectId, questionId: questionId, userId: props.userId, willDoText: willDoText });
        }
    }, [model, questionResponses, props.userId]);

    // Save the will do text for the question's reponse
    const [saveWillDoText] = useAsyncCallback(async (questionId: string, willDoText: string): Promise<void> => {
        let existing = questionResponses.models.find(it => it.questionId === questionId);
        if (!existing) {
            return;
        }

        existing = { ...existing, willDoText: willDoText, userId: props.userId || '' };

        // Save to the database
        // NOTE we don't use questionResponses.save() here as that could lead to a race condition.
        questionResponsesRepository.save(existing.id, existing);
    }, [model, questionResponses]);

    // Change (but don't save) the will do date for a question's reponse.
    const changeWillDoDate = React.useCallback((questionId: string, willDoDate: string | null): void => {
        if (!model) {
            return;
        }

        // Find the existing item
        let existing = questionResponses.models.find(item => item.questionId === questionId);
        if (existing) {
            // Saved answer needs changing.
            questionResponses.change(existing.id, { willDoDate: willDoDate, userId: props.userId || '' });
        } else {
            // No current saved answer so create one.
            questionResponses.addModel({ projectId: model.projectId, questionId: questionId, userId: props.userId, willDoDate: willDoDate });
        }
    }, [model, questionResponses, props.userId]);

    // Save the will do date for the question's reponse
    const [saveWillDoDate] = useAsyncCallback(async (questionId: string, willDoDate: string | null): Promise<void> => {
        let existing = questionResponses.models.find(it => it.questionId === questionId);
        if (!existing) {
            return;
        }

        existing = { ...existing, willDoDate: willDoDate, userId: props.userId || '' };

        // Save to the database
        // NOTE we don't use questionResponses.save() here as that could lead to a race condition.
        questionResponsesRepository.save(existing.id, existing);
    }, [model, questionResponses]);

    const hasKeyQuestions = React.useMemo(() => { return questions.filter(item => item.isKeyQuestion).length > 0 ? true : false },[questions])

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id)) && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load, id]);



    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            topic={topic}
            section={section}
            project={project}
            questions={questions}
            questionChoices={questionChoices}
            questionResponses={questionResponses}
            schoolEvidences={schoolEvidences}
            questionResponseSchoolEvidences={questionResponseSchoolEvidences}
            risks={risks}
            strengths={strengths}
            actions={actions}
            questionsHiddenByRestrictionsCount={questionsHiddenByRestrictionsCount}
            selectQuestionChoice={selectQuestionChoice} selectingErrors={selectingErrors}
            fileBlobs={fileBlobs}
            changeFeedbackText={changeFeedbackText} saveFeedbackText={saveFeedbackText}
            changeWillDoText={changeWillDoText} saveWillDoText={saveWillDoText}
            changeWillDoDate={changeWillDoDate} saveWillDoDate={saveWillDoDate}
            nextTopic={nextTopic} previousTopic={previousTopic}
            hasPermission={hasPermission}
            hasKeyQuestions={hasKeyQuestions}
            saveSchoolEvidenceNames={saveSchoolEvidenceNames}
            isSavingSchoolEvidenceNames={isSavingSchoolEvidenceNames} savingSchoolEvidenceNamesErrors={savingSchoolEvidenceNamesErrors}
            awardTagId={awardTagId}
            videos={videos} consultants={consultants} videoThumbnailBlobs={videoThumbnailBlobs} consultantPhotoBlobs={consultantPhotoBlobs}
            currentTier={currentTier}
            tierParam={tierParam}
            placeholderReplacementMode={placeholderReplacementMode}
            subjectName={props.subjectName}
        />
    );
};

export const __EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.projectTopics.repository(),
    loadViewModel: services.api.projectTopics.viewModels.edit(),
    questionResponsesRepository: services.api.questionResponses.repository(),
    blobUploadService: services.blobUploadService(),
    risksRepository: services.api.risks.repository(),
    actionsRepository: services.api.actions.repository(),
    schoolEvidencesRepository: services.api.schoolEvidence.repository(),
    questionResponseSchoolEvidencesRepository: services.api.questionResponseSchoolEvidences.repository(),
    strengthsRepository: services.api.strengths.repository(),
}))(_EditContainer);

export const EditContainer = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        userId: state.user.identity && state.user.identity.userId || ''
    })
)(__EditContainer);
