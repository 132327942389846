import * as React from "react";
import { login, Identity } from "../../store/user";
import { connect } from "react-redux";
import { AppStateCore } from "../../store";
import { AnyAction } from "redux";
import { ValidationState } from "pojo-validator";
import { ContainerComponentProps } from "react-withcontainer";
import { Login } from "../../api/models/Login";
import { withServiceProps } from "inject-typesafe-react";
import { AuthenticationService } from "../../services/AuthenticationService";
import { AppServicesCore } from "../../configure/configureServicesCore";
import { useValidatorCallback } from 'pojo-validator-react';
import { useAsyncCallback } from "react-use-async-callback";
import { SaveOnlyEditUiPropsBase } from "../containers/common/SaveOnlyEditUiPropsBase";
import { useUniversalNavigation } from "react-universal-navigation";

interface LoginContainerProps extends ContainerComponentProps<LoginUiProps> {
    authenticationService: AuthenticationService,

    // Usually from redux.
    isAuthenticated: boolean,
    login: (token: string | undefined, identity: Identity | undefined) => void
}

export interface LoginUiProps extends SaveOnlyEditUiPropsBase<Login> {
    isAuthenticated: boolean,
    isRedirectFromJoin: boolean
}

/**
 * Container with the logic for manging logging in a user.
 * @param props
 */
export const _LoginContainer = (props: LoginContainerProps) => {
    let { component, authenticationService, login, ...rest } = props;

    const navigation = useUniversalNavigation(props);

    const [model, setModel] = React.useState<Login>({ user: navigation.getParam('user', ''), password: '' });
    const isRedirectFromJoin = navigation.getParam('isRedirectFromJoin', '') ? true : false;

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<Login>) => {
        setModel(prevState => ({
            ...prevState,
            ...changes
        }));
    }, [setModel]);

    // Validate the model.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>): void => {
        if (!fieldsToCheck || fieldsToCheck.includes('user')) {
            validation.singleCheck('user', () => !model.user, 'Email is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('password')) {
            validation.singleCheck('password', () => !model.password, 'Password is required');
        }
    }, [model]);

    // Save the model.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        // Must be valid before we save it.
        if (!validate()) {
            return false;
        }

        // Perform the actual login.
        var result = await authenticationService.login(model.user, model.password);
        // Save the current login session to the application state.
        login(result.token, result.identity);

        return true;
    }, [model, validate, authenticationService, login]);

    const Component = component;
    return (
        <Component {...rest}
            isAuthenticated={props.isAuthenticated}
            model={model} changeModel={changeModel}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            isRedirectFromJoin={isRedirectFromJoin}
        />
    );
};

export const __LoginContainer = withServiceProps<LoginContainerProps, AppServicesCore>(services => ({
    authenticationService: services.authenticationService()
}))(_LoginContainer);


export const LoginContainer = connect(
    /* mapStateToProps */
    (state: AppStateCore) => ({
        isAuthenticated: state.user.isAuthenticated
    }),
    /* mapDispatchToProps */
    (dispatch: React.Dispatch<AnyAction>) => ({
        login: (token: string | undefined, identity: Identity | undefined) => dispatch(login(token, identity))
    })
)(__LoginContainer);
