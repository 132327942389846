import * as React from 'react';
import { useUniversalNavigation } from "react-universal-navigation";

/**
 * Hook used for navigation links that need to wait until we are ready before being able to navigate, then perform the navigation automatically.
 */
export function useNavigateWhenReady(isReady: boolean, urls: { [key: string]: string }): [(key: string) => void, { isReady(key: string): boolean, isNavigationPending(key: string): boolean }]
{
    const navigation = useUniversalNavigation({});

    // True when we are waiting to be able to navigate (happens if we try navigate before we are ready this gets set).
    const [navigationPendingForKey, setNavigationPendingForKey] = React.useState<string | undefined>();

    // Navigate to a URL, either straight away if we are ready, or by delaying the navigation until we are ready.
    const navigateWhenReady = React.useCallback((key: string) => {
        if (!isReady) {
            setNavigationPendingForKey(key);
            return;
        }

        navigation.navigate(urls[key]);
    }, [navigation, isReady, setNavigationPendingForKey, urls]);

    const isReadyCallback = React.useCallback((key: string) => {
        return isReady;
    }, [isReady]);

    const isNavigationPendingCallback = React.useCallback((key: string) => {
        return (navigationPendingForKey === key)? true: false;
    }, [navigationPendingForKey]);

    // If we are waiting to be able to navigate and we become ready, make sure we navigate now.
    React.useEffect(() => {
        if (!navigationPendingForKey || !isReady) {
            return;
        }

        navigation.navigate(urls[navigationPendingForKey]);
        setNavigationPendingForKey(undefined);
    }, [navigationPendingForKey, setNavigationPendingForKey, urls, isReady]);

    // Return everything for the user in a way that makes it easy for them to hook this up to a button or similar UI element that shows appropriate user feedback.
    return [
        navigateWhenReady,
        {
            isReady: isReadyCallback,
            isNavigationPending: isNavigationPendingCallback,
        }
    ];
}