import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { PlainTextWithBrsPdf } from '../../../shared/pdf/PlainTextWithBrsPdf';
import { ExecutiveSummaryPdfProps } from './ExecutiveSummaryPdf';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

/**
 * ReasonForConsideration section of the executive summary (pdf format).
 */
export const ReasonForConsiderationPdfUi = (props: ExecutiveSummaryPdfProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <View>
            <Text style={Styles.h4}>
                {
                    !props.school && props.trust ? (
                        <>Project goals</>
                    ) : (
                            <>Reason for consideration</>
                        )
                }
            </Text>
            <Text style={Styles.p}>
                <PlainTextWithBrsPdf text={replacePlaceholders(props.model.reasonForConsideration, props.placeholderReplacementMode)} />
            </Text>
        </View>
    );
};

