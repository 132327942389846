import { RAGDocxProps } from './RAGDocx';
import { RAGProjectSectionDocxUi } from "./RAGProjectSectionDocxUi";


export interface RAGBreakdownDocxUiProps extends RAGDocxProps {
}

/**
 * Breakdown of strengths and recommendations by sections and topics for RAG (pdf format).
 */
export const RAGBreakdownDocxUi = (props: RAGBreakdownDocxUiProps) => {
    if (!props.model) {
        return null;
    }

    return [
        ...props.projectSections.models.map(item => (
            RAGProjectSectionDocxUi({ projectSectionId: item.id, ...props, })
        )),
    ];
};
