import * as React from "react";
import { User } from "../../../api/models/User";
import { Validator, ValidationState } from "pojo-validator";
import { Repository } from "pojo-repository";
import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { ContainerComponentProps } from "react-withcontainer";
import { useUniversalNavigation } from "react-universal-navigation";
import { AuthenticationService } from "../../../services/AuthenticationService";
import { useAsyncCallback } from 'react-use-async-callback';
import { EditUiPropsBase } from '../../containers/common/EditUiPropsBase';
import { useValidatorCallback } from "pojo-validator-react";
import { UserProfileAwardTag } from "../../../api/models/UserProfileAwardTag";
import { ManagedChildModels, useManagedChildModels } from "../../shared/hooks/useManagedChildModels";
import { AwardTag } from "../../../api/models/AwardTag";
import { Subscription } from "../../../api/models/Subscription";
import { Subject } from "../../../api/models/Subject";


export interface EditContainerProps extends ContainerComponentProps<EditUiProps> {
    repository: Repository<User>,
    authenticationService: AuthenticationService,
    loadViewModel: (id: string, schoolId: string) => Promise<any>,
    renewToken: () => Promise<void>,
    userProfileAwardTagsRepository: Repository<UserProfileAwardTag>,
    subjectsRepository: Repository<Subject>,
}

export interface EditUiProps extends EditUiPropsBase<User> {
    inviteSent: boolean,
    resendInvite: () => Promise<void>,
    resendingInvite: boolean,
    inviteResent: boolean,
    resendInviteErrors: any,
    userProfileAwardTags: ManagedChildModels<UserProfileAwardTag>,
    awardTags: Array<AwardTag>,
    subscription: Subscription | undefined,
    subjects: Array<Subject>,
}


export const _EditContainer = (props: EditContainerProps) => {
    let { component, repository, loadViewModel, authenticationService, renewToken, userProfileAwardTagsRepository, subjectsRepository, ...rest } = props;

    const navigation = useUniversalNavigation(props);
    const id = navigation.getParam('id', '');
    const schoolId = navigation.getParam('schoolId', '');
    const isCreate = id ? false : true;

    const [model, setModel] = React.useState<User | undefined>(undefined);
    const [inviteSent, setInviteSent] = React.useState<boolean>(!isCreate);
    const userProfileAwardTags = useManagedChildModels(userProfileAwardTagsRepository);
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [subjects, setSubjects] = React.useState<Array<Subject>>([]);

    // Change the fields in the model in a controlled way using setModel.
    const changeModel = React.useCallback((changes: Partial<User>) => {
        setModel(prevState => ({
            ...(prevState as User),
            ...changes
        }));
    }, [setModel]);

    // Load from storage.
    const [load, { isExecuting: isLoading, errors: loadingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        let result = await loadViewModel(id ? id : 'defaults', schoolId);
        setAwardTags(result.awardTags);
        userProfileAwardTags.setModels(result.userProfileAwardTags);
        setSubscription(result.subscription);
        setModel(result.model);
        setSubjects(result.subjects);

        return true;
    }, [loadViewModel, setModel, id, isCreate, setAwardTags, userProfileAwardTags, setSubscription, setSubjects]);

    // Validate the input.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>) => {
        if (!model) {
            return;
        }

        if (!fieldsToCheck || fieldsToCheck.includes('forename')) {
            validation.singleCheck('forename', () => !model.forename, 'Forename is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('surname')) {
            validation.singleCheck('surname', () => !model.surname, 'Surname is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('email')) {
            validation.singleCheck('email', () => !model.surname, 'Email is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('roleGroup')) {
            validation.singleCheck('roleGroup', () => !model.roleGroup, 'Security level is required');
        }
    }, [model]);
    
    // Send the user an invite.
    const sendInvite = React.useCallback(async (user: string) => {
        authenticationService.sendInvite(user);
        setInviteSent(true);
    }, [authenticationService, setInviteSent]);

    // Save to the store.
    const [save, { isExecuting: isSaving, errors: savingErrors }] = useAsyncCallback(async (): Promise<boolean> => {
        if (!model) {
            return false;
        }

        if (!validate()) {
            return false;
        }

        // Save the model.
        await repository.save(model.id, model, isCreate);

        // Send the user an invite if they have just been created.
        if (!inviteSent) {
            await sendInvite(model.email);
        }

        // Update the award tags associated with this user.
        userProfileAwardTags.save();

        // Renew the token in case the user being changed is the current user.
        renewToken();

        //return false;
        return true;
    }, [model, validate, repository, isCreate, inviteSent, sendInvite, renewToken, userProfileAwardTags]);

    // Resend the invite to a the user.
    const [resendInvite, { isExecuting: resendingInvite, errors: resendInviteErrors, successfullyExecuted: inviteResent }] = useAsyncCallback(async (): Promise<void> => {
        if (!model || !model.email) {
            return;
        }

        await sendInvite(model.email);
    }, [model, sendInvite]);

    // Load on mount if we haven't got a model.
    React.useEffect(() => {
        if ((!model || (id && id !== model.id))  && !isLoading && !loadingErrors) {
            load();
        }
    }, [model, isLoading, loadingErrors, load, id]);

    const Component = component;
    return (
        <Component {...rest}
            model={model} changeModel={changeModel} isCreate={isCreate}
            load={load} isLoading={isLoading} loadingErrors={loadingErrors}
            validate={validate} validationErrors={validationErrors}
            save={save} isSaving={isSaving} savingErrors={savingErrors}
            resendInvite={resendInvite} inviteSent={inviteSent} inviteResent={inviteResent} resendingInvite={resendingInvite} resendInviteErrors={resendInviteErrors}
            awardTags={awardTags} userProfileAwardTags={userProfileAwardTags}
            subscription={subscription}
            subjects={subjects}
        />
    );
};

export const EditContainer = withServiceProps<EditContainerProps, AppServicesCore>(services => ({
    repository: services.api.users.repository(),
    loadViewModel: services.api.users.viewModels.schoolUserEditSchoolUser(),
    authenticationService: services.authenticationService(),
    renewToken: services.renewToken(),
    userProfileAwardTagsRepository: services.api.userProfileAwardTags.repository(),
    subjectsRepository: services.api.subjects.repository(),
}))(_EditContainer);

