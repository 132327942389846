import { withServiceProps } from "inject-typesafe-react";
import { AppServicesCore } from "../../../configure/configureServicesCore";
import { BasicPagedListContainerProps, BasicPagedListContainer } from "../../containers/common/basicContainers/BasicPagedListContainer";
import { PagedListUiPropsBase } from "../../containers/common/PagedListUiPropsBase";
import { SchoolTerm } from "../../../api/models/SchoolTerm";

export interface ListUiProps extends PagedListUiPropsBase<SchoolTerm> {
}

export const ListContainer = withServiceProps<BasicPagedListContainerProps, AppServicesCore>(services => ({
    loadPage: services.api.schoolTerms.viewModels.list()
}))(BasicPagedListContainer);
