import { ReportBuilderOptionsProps } from "./ReportBuilderOptions";
import { ReportSettingsModel } from "../reportSettings/ReportSettingsModel";
import { ExecutiveSummaryOptions } from "../executiveSummary/reportBuilder/ExecutiveSummaryOptions";
import { ExecutiveSummaryPdf } from "../executiveSummary/pdf/ExecutiveSummaryPdf";
import { useMemo } from "react";
import { ReportProps } from "./ReportProps";
import { ExecutiveSummaryScreenReport } from "../executiveSummary/ExecutiveSummaryScreenReport";
import { SEFOptions } from "../sef/reportBuilder/SEFOptions";
import { SEFScreenReport } from "../sef/SEFScreenReport";
import { SEFPdf } from "../sef/pdf/SEFPdf";
import { SEFDocx } from "../sef/docx/SEFDocx";
import { RAGOptions } from "../rag/reportBuilder/RAGOptions";
import { RAGScreenReport } from "../rag/RAGScreenReport";
import { RAGPdf } from "../rag/pdf/RAGPdf";
import { RAGDocx } from "../rag/docx/RAGDocx";

/**
 * Hook that will return the components that should be used by ReportGenerator to show a specific report.
 */
export function useReportGeneratorSpecificReportUi(reportSettings: ReportSettingsModel) {
    return useMemo(() => {
        switch (reportSettings.baseReport) {
            case 'executiveSummary':
                return {
                    ReportBuilderOptions: ExecutiveSummaryOptions,
                    ScreenReport: ExecutiveSummaryScreenReport,
                    PdfReport: ExecutiveSummaryPdf,
                    DocxReport: undefined, // No docx format available.
                };
            case 'sef':
                return {
                    ReportBuilderOptions: SEFOptions,
                    ScreenReport: SEFScreenReport,
                    PdfReport: SEFPdf,
                    DocxReport: SEFDocx,
                };
            case 'rag':
                return {
                    ReportBuilderOptions: RAGOptions,
                    ScreenReport: RAGScreenReport,
                    PdfReport: RAGPdf,
                    DocxReport: RAGDocx,
                };
        }
    }, [reportSettings.baseReport]);
}

/**
 * UI required for a specific report.
 */
export interface SpecificReportUi {
    ReportBuilderOptions: (props: ReportBuilderOptionsProps) => JSX.Element,
    ScreenReport: (props: ReportProps) => JSX.Element,
    PdfReport: (props: ReportProps & { chartImages: Array<string> }) => JSX.Element,
}