import * as React from 'react';
import { Row, Col, ListGroup, ListGroupItem, Spinner, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { AwardTag } from '../../../api/models/AwardTag';
import { School } from '../../../api/models/School';
import { SchoolTagCertificate } from '../../../api/models/SchoolTagCertificate';
import { LinkContainer } from 'react-router-bootstrap';

interface SchoolTagCertificateProgressProps {
    model: School | undefined,
    awardTag: AwardTag | undefined,
    schoolTagCertificate: SchoolTagCertificate | undefined,
    projectId: string | undefined,

    hasAnsweredAllQuestions: boolean,

    submitCertificate: () => Promise<void>,
    isSubmittingCertificate: boolean,
    claimCertificate: () => Promise<void>,
    isClaimingCertificate: boolean,
}

/**
 * Component showing progress through the SchoolTagCertificate (e.g. SchoolMark) submission process.
 */
export const SchoolTagCertificateProgress = (props: SchoolTagCertificateProgressProps) => {
    const {
        model,
        awardTag,
        schoolTagCertificate,
        projectId,

        hasAnsweredAllQuestions,

        submitCertificate,
        isSubmittingCertificate,
        claimCertificate,
        isClaimingCertificate
    } = props;


    const needSubmitButton = !!awardTag && awardTag.canSubmit
        && !(schoolTagCertificate && schoolTagCertificate.isSubmitted)
        && !(schoolTagCertificate && schoolTagCertificate.isAwarded);

    const submitState = !!awardTag && awardTag.canSubmit && schoolTagCertificate
        && (schoolTagCertificate.isSubmitted || schoolTagCertificate.isAwarded) ? 'complete'
        : hasAnsweredAllQuestions ? 'active' : 'not-ready';
    const certificateState = schoolTagCertificate && schoolTagCertificate.isAwarded ? 'complete'
        : !!awardTag && !awardTag.canSubmit && hasAnsweredAllQuestions ? 'can-claim'
            : submitState === 'complete' ? 'active'
            : 'not-ready';

    if (!model || !awardTag) {
        return (<></>);
    }
  
    return (
        <div>
            <ListGroup>
                <ListGroupItem>
                    <Row>
                        <Col xs="auto" className={`text-center ${hasAnsweredAllQuestions? 'text-primary': ''}`}>
                            <FontAwesomeIcon icon="users" size="4x" />
                            <ConditionalFragment showIf={hasAnsweredAllQuestions}>
                                <FontAwesomeIcon icon="check-circle" color="success" />
                            </ConditionalFragment>
                        </Col>
                        <Col>
                            Complete all questions required for the {awardTag.name} review.
                        </Col>
                    </Row>
                </ListGroupItem>
                <ConditionalFragment showIf={awardTag.canSubmit}>
                    <ListGroupItem className={`${submitState === 'not-ready' ? 'text-muted' : ''}`}>
                        <Row>
                            <Col xs="auto" className={`text-center ${submitState === 'complete' ? 'text-primary' : ''}`}>
                                <FontAwesomeIcon icon="paper-plane" size="4x" />
                                <ConditionalFragment showIf={submitState === 'complete'}>
                                    <FontAwesomeIcon icon="check-circle" color="success" />
                                </ConditionalFragment>
                            </Col>
                            <Col>
                                Submit your {awardTag.name} review and evidence to {awardTag.awardingOrganisationName}.
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ConditionalFragment>
                {
                    // If we have a certificate, we need a step that involves claiming it.
                    awardTag.hasCertificate ? (
                        <ListGroupItem className={`${certificateState === 'not-ready' ? 'text-muted' : ''}`}>
                            <Row>
                                <Col xs="auto" className={`text-center ${certificateState === 'complete' ? 'text-primary' : ''}`}>
                                    <FontAwesomeIcon icon="certificate" size="4x" />
                                    <ConditionalFragment showIf={certificateState === 'complete'}>
                                        <FontAwesomeIcon icon="check-circle" color="success" />
                                    </ConditionalFragment>
                                </Col>
                                <Col>
                                    <ConditionalFragment showIf={certificateState === 'complete' && awardTag.canSubmit}>
                                        You have been awarded the {awardTag.name} certificate.
                                        <div className="mt-1">
                                            <LinkContainer to={`/school/${schoolTagCertificate && schoolTagCertificate.schoolId || ''}/awardCertificate/${awardTag.id}/${projectId || ''}`}>
                                                <Button color="primary">
                                                    View certificate
                                                </Button>
                                            </LinkContainer>
                                        </div>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={certificateState === 'complete' && !awardTag.canSubmit}>
                                        You have claimed the {awardTag.name} certificate.
                                        <div className="mt-1">
                                            <LinkContainer to={`/school/${schoolTagCertificate && schoolTagCertificate.schoolId || ''}/awardCertificate/${awardTag.id}/${projectId || ''}`}>
                                                <Button color="primary">
                                                    View certificate
                                                </Button>
                                            </LinkContainer>
                                        </div>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={certificateState === 'active'}>
                                        Waiting for {awardTag.awardingOrganisationName} to confirm your certificate.
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={certificateState === 'not-ready' && awardTag.canSubmit}>
                                        Receive your {awardTag.name} certificate from {awardTag.awardingOrganisationName}.
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={certificateState === 'can-claim' && !awardTag.canSubmit}>
                                        Claim your {awardTag.name} certificate.
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={certificateState === 'not-ready' && !awardTag.canSubmit}>
                                        Claim your {awardTag.name} certificate.
                                    </ConditionalFragment>
                                </Col>
                            </Row>
                        </ListGroupItem>
                        ): null
                }
            </ListGroup>

            {
                // If we have a certificate, we need buttons to self claim or submit it.
                awardTag.hasCertificate ? (
                    <div className="mt-2">
                        <Row>
                            <Col>
                            </Col>
                            <Col xs="auto" className="text-right">
                                <ConditionalFragment showIf={(certificateState === 'complete' || certificateState === 'can-claim') && (!schoolTagCertificate || !schoolTagCertificate.isAwarded)}>
                                    <ButtonAsync color="secondary" onClick={() => claimCertificate()}
                                        isExecuting={isClaimingCertificate}
                                        executingChildren={<><Spinner size="sm" /> Claiming certificate...</>}
                                    >
                                        <FontAwesomeIcon icon="certificate" />
                                        <> </>
                                        Claim {awardTag.name} certificate
                                    </ButtonAsync>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={needSubmitButton}>
                                    <ButtonAsync color="secondary" disabled={submitState !== 'active'} onClick={() => submitCertificate()}
                                        isExecuting={isSubmittingCertificate}
                                        executingChildren={<><Spinner size="sm" /> Submitting to {awardTag.awardingOrganisationName}...</>}>
                                        <FontAwesomeIcon icon="paper-plane" />
                                        <> </>

                                        {schoolTagCertificate && schoolTagCertificate.feedback ? 'Resubmit' : 'Submit'}
                                        <> </>
                                        {awardTag.name} to {awardTag.awardingOrganisationName}
                                    </ButtonAsync>
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </div>
                ): null
            }
        </div>
    );
};
