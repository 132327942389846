import * as React from 'react';
import { Form, FormGroup, Label, Alert, Row, Col, Container, Button, Collapse, FormText, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { EditUiProps, EditContainer } from '../EditContainer';
import { AlertOnErrors } from '../../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../../shared/GoBackLinkContainer';
import { SubscriptionLicense } from '../../../../api/models/SubscriptionLicense';
import { LicenseEdit } from './LicenseEdit';
import { LinkContainer } from 'react-router-bootstrap';
import { TwoValueSwitch } from '../../../shared/TwoValueSwitch';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Subscription } from '../../../../api/models/Subscription';
import { Guid } from 'guid-string';
import { ComparisonTypes, comparisonTypeDisplayName, comparisonTypes } from '../../../../api/models/codeOnly/ComparisonTypes';

export const EditUi = (props: EditUiProps) => {
    const navigation = useUniversalNavigation(props);

    const [newLicense, setNewLicense] = React.useState<SubscriptionLicense | undefined>(undefined);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const ok = await props.save();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.save, navigation]);

    const onChangeUser = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;

        props.changeUser({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });

    }, [props.changeUser]);

    const onChangeModel = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;

        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });

        if (target.name === 'companyName') {
            props.changeTrust({ name: target.value });
            props.changeSchool({ name: target.value });
        }
        // Disabled because we now use defReference as the unique identifier for the subscription, which is internal to Evaluate-Ed.
        //else if (target.name === 'dfeReference') {
        //    props.changeTrust({ dfeReference: target.value });
        //    props.changeSchool({ dfeReference: target.value });
        //}

    }, [props.changeUser, props.changeModel, props.changeTrust, props.changeSchool]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    const onResendInvite = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        props.resendInvite();
    }, [props.resendInvite]);

    const changeNewLicense = React.useCallback((changes: Partial<SubscriptionLicense>) => {
        setNewLicense(prevState => ({
            ...(prevState as SubscriptionLicense),
            ...changes
        }));
    }, [setNewLicense]);

    // Manage the subscription by changing the user's current subscription in redux and redirecting to the manage screen.
    const manageSubscription = React.useCallback((subscriptionId: string) => {
        props.selectSubscription(subscriptionId);
        navigation.push('/manage/');
    }, [props.selectSubscription, navigation]);


    const toggleAwardTag = React.useCallback((awardTagId: string) => {
        if (!props.model) {
            return;
        }

        const existing = props.userProfileAwardTags.models.find(it => it.awardTagId === awardTagId);
        if (existing) {
            props.userProfileAwardTags.removeModel(existing.id);
        } else {
            props.userProfileAwardTags.addModel({ id: Guid.newGuid(), awardTagId: awardTagId, userId: null, subscriptionId: props.model.id });
        }
    }, [props.userProfileAwardTags, props.model]);


    if (!props.model || !props.user) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    {props.isCreate ? 'Add subscription' : 'Edit subscription'}
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.savingErrors]} />

            {
                props.inviteResent ? (
                    <Alert color="success">
                        Invite has been resent.
                    </Alert>
                ) : props.resendInviteErrors ? (
                    <Alert color="danger">
                        Could not resend invite: {props.resendInviteErrors}
                    </Alert>
                ) : null
            }

            <Form onSubmit={onSubmit}>
                <div className="toolbar-top">
                    <ButtonAsync type="submit" color="primary" isExecuting={props.isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                        <FontAwesomeIcon icon="save" /> Save
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isSaving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                    {
                        props.model && props.isCreate != true ?
                            props.model && props.currentSubscription === props.model.id ? <Button color="danger" onClick={(ev) => {
                                ev.preventDefault();
                                props.selectSubscription('');
                            }}>
                                <FontAwesomeIcon icon="atom" /> Stop Managing
                            </Button> :
                                <LinkContainer to={`/simulate/subscription/${props.model.id}`}>
                                    <Button color="primary" outline onClick={(e) => {
                                        e.preventDefault();
                                        if (props.model) {
                                            manageSubscription(props.model.id);
                                        }
                                    }}>
                                        <FontAwesomeIcon icon="atom" /> Manage
                                    </Button>
                                </LinkContainer> :
                            null
                    }

                </div>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="forename">Forename</Label>
                            <ValidatedInput type="text" name="forename" placeholder="Forename" value={props.user.forename} onChange={onChangeUser} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="surname">Surname</Label>
                            <ValidatedInput type="text" name="surname" placeholder="Surname" value={props.user.surname} onChange={onChangeUser} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label htmlFor="name">Email</Label>
                    <Row>
                        <Col>
                            <ValidatedInput /*readOnly={props.inviteSent}*/ type="email" name="email" placeholder="Email" value={props.user.email} onChange={onChangeUser} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </Col>
                        {
                            props.inviteSent ? (
                                <Col xs="auto">
                                    <ButtonAsync color="primary" outline onClick={onResendInvite} isExecuting={props.resendingInvite}
                                        executingChildren={(<><FontAwesomeIcon icon="spinner" spin /> Sending...</>)}>
                                        <FontAwesomeIcon icon="envelope" /> Resend invite
                                    </ButtonAsync>
                                </Col>
                            ) : null
                        }
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="phoneNumber">Phone</Label>
                    <ValidatedInput type="tel" name="phoneNumber" placeholder="Phone" value={props.model.phoneNumber} onChange={onChangeModel} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>

                <ConditionalFragment showIf={!props.model.isForAwardingOrganisation}>
                    <FormGroup>
                        <span className="sr-only">
                            <Label htmlFor="isTrustOrSchool">Trust or single school</Label>
                        </span>
                        <TwoValueSwitch leftLabel="Trust" rightLabel="Single School"
                            checked={props.model && props.model.isForSingleSchool}
                            onChange={checked => props.isCreate && props.changeModel({ isForSingleSchool: checked })}
                        />
                    </FormGroup>
                </ConditionalFragment>

                <FormGroup>
                    <Label htmlFor="companyName">{props.model.isForSingleSchool ? 'School' : props.model.isForAwardingOrganisation ? 'Organisation' : 'Trust'}</Label>
                    <ValidatedInput type="text" name="companyName" placeholder={props.model.isForSingleSchool ? 'School' : props.model.isForAwardingOrganisation ? 'Organisation' : 'Trust'} value={props.model.companyName} onChange={onChangeModel} onBlur={onValidate} validationErrors={props.validationErrors} />
                </FormGroup>
                <Row>
                    <ConditionalFragment showIf={!props.model.isForAwardingOrganisation}>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="dfeReference">Unique identifier</Label>
                                <ValidatedInput type="text" name="dfeReference" placeholder="Dfe number" value={props.model.dfeReference} onChange={onChangeModel} onBlur={onValidate} validationErrors={props.validationErrors} />
                            </FormGroup>
                        </Col>
                    </ConditionalFragment>
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="postcode">Postcode</Label>
                            <ValidatedInput type="text" name="postcode" placeholder="Postcode" value={props.model.postcode} onChange={onChangeModel} onBlur={onValidate} validationErrors={props.validationErrors} />
                        </FormGroup>
                    </Col>
                </Row>
                <div>
                    <h2 className="sub-heading">Licensed features</h2>

                    <Row>
                        <Col>
                            <FormGroup>
                                <TwoValueSwitch leftLabel="" rightLabel="Self evaluation" checked={props.model.selfEvaluatationFeature} onChange={checked => props.changeModel({ selfEvaluatationFeature: checked })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <TwoValueSwitch leftLabel="" rightLabel="Specific reviews" checked={props.model.specificReviewsFeature} onChange={checked => props.changeModel({ specificReviewsFeature: checked })} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <TwoValueSwitch leftLabel="" rightLabel="Subject reviews" checked={props.model.subjectReviewsFeature} onChange={checked => props.changeModel({ subjectReviewsFeature: checked })} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="selfEvaluationComparison">Self evaluation comparison within a trust</Label>
                                <Input name="selfEvaluationComparison" type="select" value={props.model.selfEvaluationComparison} onChange={(e) => props.changeModel({ selfEvaluationComparison: e.currentTarget.value })}>
                                    {comparisonTypes.filter(it => it !== ComparisonTypes.SubjectLeader).map(comparisonType => (
                                        <option value={comparisonType} key={comparisonType}>{comparisonTypeDisplayName(comparisonType)}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label htmlFor="specificReviewComparison">Specific review comparison within a trust</Label>
                                <Input name="specificReviewComparison" type="select" value={props.model.specificReviewComparison} onChange={(e) => props.changeModel({ specificReviewComparison: e.currentTarget.value })}>
                                    {comparisonTypes.filter(it => it !== ComparisonTypes.SubjectLeader).map(comparisonType => (
                                        <option value={comparisonType} key={comparisonType}>{comparisonTypeDisplayName(comparisonType)}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label htmlFor="subjectLeadershipCrossSchoolComparison">Subject leadership comparison within a trust (cross school)</Label>
                                <Input name="subjectLeadershipCrossSchoolComparison" type="select" value={props.model.subjectLeadershipCrossSchoolComparison} onChange={(e) => props.changeModel({ subjectLeadershipCrossSchoolComparison: e.currentTarget.value })}>
                                    {comparisonTypes.map(comparisonType => (
                                        <option value={comparisonType} key={comparisonType}>{comparisonTypeDisplayName(comparisonType)}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label htmlFor="subjectLeadershipCrossSubjectComparison">Subject leadership comparison within a school (cross subject)</Label>
                                <Input name="subjectLeadershipCrossSubjectComparison" type="select" value={props.model.subjectLeadershipCrossSubjectComparison} onChange={(e) => props.changeModel({ subjectLeadershipCrossSubjectComparison: e.currentTarget.value })}>
                                    {comparisonTypes.map(comparisonType => (
                                        <option value={comparisonType} key={comparisonType}>{comparisonTypeDisplayName(comparisonType)}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!props.model.isForAwardingOrganisation}>
                        <Collapse isOpen={props.model.specificReviewsFeature}>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label htmlFor="whichAwards">These specific reviews are available to all schools:</Label>
                                        <div className="mt-2">
                                            {
                                                props.awardTags
                                                    .filter(item => item.isVisibleToSchools)
                                                    .map(tag => {
                                                        // If we are restricting to only some award tags let the user select which ones.
                                                        if (props.model?.isLimitedToAwardTags) {
                                                            return (
                                                                <div key={tag.id}>
                                                                    <TwoValueSwitch leftLabel="" rightLabel={tag.name}
                                                                        checked={!!props.userProfileAwardTags.models.find(it => it.awardTagId === tag.id)} onChange={checked => toggleAwardTag(tag.id)} />
                                                                </div>
                                                            );
                                                        }

                                                        // Otherwise show hem without the check boxes.
                                                        return (
                                                            <div key={tag.id}>
                                                                {tag.name}
                                                            </div>
                                                        );
                                                    })
                                            }
                                        </div>

                                        <div className="mt-1">
                                            <Row>
                                                <Col xs="auto">
                                                    <Label htmlFor="isLimitedToAwardTags">Would you like to restrict access to only some of the above specific reviews?</Label>
                                                </Col>
                                                <Col xs="auto">
                                                    <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={props.model.isLimitedToAwardTags} onChange={checked => props.changeModel({ isLimitedToAwardTags: checked })} />
                                                </Col>
                                            </Row>
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label htmlFor="whichAwards2">These specific reviews are not yet available to all schools but can be added to the subscription if you want (e.g. for testing):</Label>
                                        <div className="mt-2">
                                            {
                                                props.awardTags
                                                    .filter(item => !item.isVisibleToSchools)
                                                    .map(tag => (
                                                        <div key={tag.id}>
                                                            <TwoValueSwitch leftLabel="" rightLabel={tag.name}
                                                                checked={!!props.userProfileAwardTags.models.find(it => it.awardTagId === tag.id)} onChange={checked => toggleAwardTag(tag.id)} />
                                                        </div>
                                                    ))
                                            }
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormText className="mb-3">Please note that where appropriate school types and phases also need to be set in the school details for each individual school if they are to see the relevant specific reviews regardless of the settings selected here.</FormText>
                        </Collapse>
                    </ConditionalFragment>

                </div>



                <div>
                    <h2 className="sub-heading">Licence periods</h2>
                    <div>
                        {
                            props.licenses.models.map((item, index) => (
                                <LicenseEdit key={item.id} model={item} change={(changes: Partial<SubscriptionLicense>) => props.licenses.change(item.id, changes)}
                                    validate={(fieldsToCheck?: Array<string>) => props.licenses.validateModel(item.id, fieldsToCheck)} validationErrors={props.licenses.validationErrorsFor(item.id)}
                                    remove={() => props.licenses.removeModel(item.id)}
                                />
                            ))
                        }
                    </div>
                    <Button color="secondary" onClick={() => props.licenses.addModel({ subscriptionId: props.model ? props.model.id : "" })}>Add licence</Button>
                </div>
            </Form>
        </Container>
    );
};

export const Edit = withContainer(EditContainer)(EditUi);
export const CreateTrust = withContainer(EditContainer,
    {
        initializeCreate: (model: Subscription) => { model.isForSingleSchool = false; model.isForAwardingOrganisation = false; },
    } as any
)(EditUi);
export const CreateSingleSchool = withContainer(EditContainer,
    {
        initializeCreate: (model: Subscription) => { model.isForSingleSchool = true; model.isForAwardingOrganisation = false; },
    } as any
)(EditUi);
export const CreateAwardingOrganisation = withContainer(EditContainer,
    {
        initializeCreate: (model: Subscription) => { model.isForSingleSchool = false; model.isForAwardingOrganisation = true; },
    } as any
)(EditUi);
