import * as React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { Styles } from '../../executiveSummary/pdf/Styles';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ReportProps } from '../../reportGenerator/ReportProps';
import { OverviewChartPdfUi } from './OverviewChartPdfUi';
import { StrengthsSummaryPdfUi } from './StrengthsSummaryPdfUi';
import { ImageEnumerator, ImageWithMetadata } from '../../executiveSummary/pdf/ImageEnumerator';
import { SEFBreakdownPdfUi } from './SEFBreakdownPdfUi';
import { replacePlaceholders } from '../../../../utilities/replacePlaceholders';

export interface SEFPdfProps extends ReportProps {
    chartImages: Array<ImageWithMetadata>
}

/**
 * Executive summary screen for a project, this gives a long report-like breakdown of the project results.
 */
export const SEFPdf = (props: SEFPdfProps) => {
    const { projectSettings, reportSettings } = props;
    const imageEnumerator = new ImageEnumerator(props.chartImages);

    if (!props.model) {
        return (
            <Document>
                <Page size="A4">
                    <Text>PDF could not be generated.</Text>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page size="A4" style={{ ...Styles.page, paddingTop: 20, paddingBottom: 20 }}>
                <View style={Styles.body}>
                    <View>
                        <Text style={Styles.h1}>
                            <Text>
                                {
                                    props.school ? props.school.name
                                        : props.trust ? props.trust.name
                                            : ''
                                }
                            </Text>
                        </Text>
                        <Text style={{ ...Styles.h1Small }}>Evaluate-Ed {
                            replacePlaceholders(
                            props.awardTag ? `${props.awardTag.name} summary`
                                : props.subject && props.subject.name && reportSettings.reportName ? `${props.subject.name} ${reportSettings.reportName}`
                                    : reportSettings.reportName ? reportSettings.reportName
                                        : projectSettings.isSelfEvaluation ? 'self evaluation summary'
                                            : projectSettings.isDueDiligence ? 'executive summary' : 'project summary',
                            props.placeholderReplacementMode, { subjectName: props.subject?.name })
                        }</Text>
                    </View>

                    {/* Show school logo/banner if it has one */}
                    {
                        !!props.reportBannerBlobDataUrl ? (
                            <Image src={props.reportBannerBlobDataUrl.dataUrl} style={{ marginBottom: 4 }} />
                        ) : null
                    }

                    <ConditionalFragment showIf={reportSettings.overallResults}>
                        <OverviewChartPdfUi {...props} imageEnumerator={imageEnumerator}  />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={reportSettings.areasOfStrength}>
                        <StrengthsSummaryPdfUi {...props} imageEnumerator={imageEnumerator}  />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={reportSettings.breakdown}>
                        <SEFBreakdownPdfUi {...props} />
                    </ConditionalFragment>
                </View>

                {/*
                <View style={Styles.footer} fixed>
                    <Text style={Styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </View>
                */}
            </Page>
        </Document>
    );
};

