import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import {
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader
} from '@coreui/react';
import { SidebarNav, SidebarNavTitle } from '../../shared/sidebarNav';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrivateContainer } from '../../shared/privateRoute/PrivateContainer';
import { NavLinkTrackActive } from '../../shared/NavLinkTrackActive';
import { useAsyncCallback } from 'react-use-async-callback';
import { useApiService } from '../../../api/useApiService';
import { Project } from '../../../api/models/Project';
import { Trust } from '../../../api/models/Trust';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Subscription } from '../../../api/models/Subscription';
import { AwardTag } from '../../../api/models/AwardTag';
import { User } from '../../../api/models/User';
import { Subject } from '../../../api/models/Subject';

interface SidebarProps {
    model: Trust | undefined | null,
}

export const Sidebar = (props: SidebarProps) => {
    const { model } = props;

    const api = useApiService();
    const getSubscriptionFromTrust = api.subscriptions.viewModels.getSubscriptionFromTrust();
    const currentProjectFor = api.projects.viewModels.currentProjectFor();
    const loadViewModel = api.trusts.viewModels.sidebar();

    // Current projects for the trust (shown in the menu)
    const [selfEvaluationProject, setSelfEvaluationProject] = React.useState<Project | null | undefined>();
    const [dueDiligenceProject, setDueDiligenceProject] = React.useState<Project | null | undefined>();
    const [subscription, setSubscription] = React.useState<Subscription | undefined>();
    const [awardTags, setAwardTags] = React.useState<Array<AwardTag>>([]);
    const [user, setUser] = React.useState<User | undefined>();

    const [subjects, setSubjects] = React.useState<Array<Subject>>([]);
    const [readingReviews, setReadingReviews] = React.useState<{ eyfsToKS2: boolean, ks3ToKS4: boolean }>({ eyfsToKS2: false, ks3ToKS4: false, });

    const [loadProjects, { isExecuting: isLoading }] = useAsyncCallback(async () => {
        // Load the view models.
        const result = await loadViewModel(model && model.id || '');
        setUser(result.user);
        setAwardTags(result.awardTags);
        setSubjects(result.subjects);
        setReadingReviews(result.readingReviews);


        // Get the subscription.
        const subscriptionResult = await getSubscriptionFromTrust(model && model.id || '');
        setSubscription(subscriptionResult);

        let dueDiligence = await currentProjectFor({ trustId: model && model.id || '', questionSetName: 'School due diligence' });
        setDueDiligenceProject(dueDiligence);

        let selfEvalulation = await currentProjectFor({ trustId: model && model.id || '', questionSetName: 'School self evaluation' });
        setSelfEvaluationProject(selfEvalulation);
    }, [model, currentProjectFor, setSelfEvaluationProject, setDueDiligenceProject, getSubscriptionFromTrust, setSubscription,
        loadViewModel, setUser, setAwardTags, setSubjects, setReadingReviews]);
    const [loadedForTrustId, setLoadedForTrustId] = React.useState<string>();

    React.useEffect(() => {
        if (!model || !model.id || isLoading || (model && model.id === loadedForTrustId)) {
            return;
        }

        setLoadedForTrustId(model.id);
        loadProjects();
    }, [model, loadedForTrustId, setLoadedForTrustId, loadProjects, isLoading]);

    return (
        <>
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <Nav>
                    <SidebarNavTitle className="d-block d-sm-none">
                        Evaluate-Ed
                    </SidebarNavTitle>
                    <PrivateContainer role="Manage">
                        <NavItem className="d-block d-sm-none  admin-nav-sidebar">
                            <NavLinkTrackActive to="/manage" tag={Link}>
                                Manage
                                </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>
                    <PrivateContainer role="Administration">
                        <NavItem className="d-block d-sm-none admin-nav-sidebar">
                            <NavLinkTrackActive to="/administration" tag={Link}>
                                Administration
                            </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>

                    <PrivateContainer role="Manage">
                        <NavItem>
                            <NavLinkTrackActive to="/manage/home" tag={Link}>
                                <FontAwesomeIcon icon="home" className="nav-icon" />
                                Home
                                </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to={`/manage/details/${model && model.id || ''}`} tag={Link}>
                                <FontAwesomeIcon icon="handshake" className="nav-icon" />
                                Trust details
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to={`/manage/schools`} tag={Link}>
                                <FontAwesomeIcon icon="school" className="nav-icon" />
                                Schools
                            </NavLinkTrackActive>
                        </NavItem>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature}>
                            <SidebarNavTitle>
                                Self evaluation
                            </SidebarNavTitle>
                            {
                                selfEvaluationProject ? (
                                    <>
                                        <NavItem>
                                            <NavLinkTrackActive to={`/manage/selfEvaluation/${selfEvaluationProject && selfEvaluationProject.id || ''}/dashboard`} tag={Link}>
                                                <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                                                Self evaluation dashboard
                                            </NavLinkTrackActive>
                                        </NavItem>
                                        {/*
                                        <NavItem>
                                            <NavLinkTrackActive to={`/manage/selfEvaluationExecutiveSummary/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                                <FontAwesomeIcon icon="quote-right" className="nav-icon" />
                                                Self evaluation summary
                                            </NavLinkTrackActive>
                                        </NavItem>
                                        */}
                                        <NavItem>
                                            <NavLinkTrackActive to={`/manage/selfEvaluationTargets/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                                <FontAwesomeIcon icon="edit" className="nav-icon" />
                                                Trust targets for schools
                                            </NavLinkTrackActive>
                                        </NavItem>
                                    </>
                                ) : (
                                        <NavItem style= {{width: '100%', textAlign: 'center' }}>
                                            <FontAwesomeIcon icon="spinner" spin />
                                        </NavItem>
                                        )
                            }
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && (subscription.selfEvaluatationFeature || subscription.specificReviewsFeature)}>
                            <SidebarNavTitle>
                                Improvement planning
                            </SidebarNavTitle>
                            <NavItem>
                                <NavLinkTrackActive to={`/manage/improvementPlanner`} tag={Link}>
                                    <FontAwesomeIcon icon="calendar-plus" className="nav-icon" />
                                    Trust improvement planner
                                </NavLinkTrackActive>
                            </NavItem>
                            <NavItem>
                                <NavLinkTrackActive to={`/manage/schoolImprovementPlanner`} tag={Link}>
                                    <FontAwesomeIcon icon="calendar-plus" className="nav-icon" />
                                    School improvement planner
                                </NavLinkTrackActive>
                            </NavItem>
                            <NavItem>
                                <NavLinkTrackActive to={`/manage/strengthReport/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                    <FontAwesomeIcon icon="heartbeat" className="nav-icon" />
                                    Areas of strength
                                </NavLinkTrackActive>
                            </NavItem>
                            <NavItem>
                                <NavLinkTrackActive to={`/manage/recommendationReport/${selfEvaluationProject && selfEvaluationProject.id || ''}`} tag={Link}>
                                    <FontAwesomeIcon icon="thumbs-up" className="nav-icon" />
                                    Areas for development
                                </NavLinkTrackActive>
                            </NavItem>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature}>
                            <SidebarNavTitle>
                                Due diligence
                            </SidebarNavTitle>
                            {
                                dueDiligenceProject ? (
                                    <>
                                        <NavItem>
                                            <NavLinkTrackActive to={`/manage/dueDiligence/${dueDiligenceProject && dueDiligenceProject.id || ''}/dashboard`} tag={Link}>
                                                <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                                                Due diligence dashboard
                                            </NavLinkTrackActive>
                                        </NavItem>
                                        {/*
                                    <NavItem>
                                        <NavLinkTrackActive to={`/manage/dueDiligenceExecutiveSummary/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                            <FontAwesomeIcon icon="quote-right" className="nav-icon" />
                                            Executive summary
                                        </NavLinkTrackActive>
                                    </NavItem>
                                    */}
                                        <NavItem>
                                            <NavLinkTrackActive to={`/manage/dueDiligenceRiskReport/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                                <FontAwesomeIcon icon="exclamation-circle" className="nav-icon" />
                                                Risks
                                            </NavLinkTrackActive>
                                        </NavItem>
                                        <NavItem>
                                            <NavLinkTrackActive to={`/manage/dueDiligenceExpectations/${dueDiligenceProject && dueDiligenceProject.id || ''}`} tag={Link}>
                                                <FontAwesomeIcon icon="edit" className="nav-icon" />
                                                Due diligence expectations
                                            </NavLinkTrackActive>
                                        </NavItem>
                                    </>
                                ) : (
                                    <NavItem style={{ width: '100%', textAlign: 'center' }}>
                                        <FontAwesomeIcon icon="spinner" spin />
                                    </NavItem>
                                )
                            }

                            <SidebarNavTitle>
                                Growth planning
                            </SidebarNavTitle>
                            <NavItem>
                                <NavLinkTrackActive to={`/manage/growthPlan/${model && model.id || ''}`} tag={Link}>
                                    <FontAwesomeIcon icon="seedling" className="nav-icon" />
                                    Growth plan
                                </NavLinkTrackActive>
                            </NavItem>

                            <NavItem>
                                <NavLinkTrackActive to={`/manage/potentialSchools`} tag={Link}>
                                    <FontAwesomeIcon icon="school" className="nav-icon" />
                                    Potential schools
                                </NavLinkTrackActive>
                            </NavItem>
                        </ConditionalFragment>
                        

                        <ConditionalFragment showIf={!!subscription && subscription.specificReviewsFeature}>
                            <ConditionalFragment showIf={!!awardTags.length}>
                                <SidebarNavTitle>
                                    Specific reviews
                                </SidebarNavTitle>
                                {
                                    awardTags.map((item, index) => (
                                        <NavItem key={item.id}>
                                            <NavLinkTrackActive to={`/manage/award/${item.id}/${selfEvaluationProject && selfEvaluationProject.id || ''}/dashboard`} tag={Link}>
                                                <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                                                {item.name} dashboard
                                                </NavLinkTrackActive>
                                        </NavItem>
                                    ))
                                }
                            </ConditionalFragment>
                        </ConditionalFragment>


                        <ConditionalFragment showIf={!!subscription && subscription.subjectReviewsFeature}>
                            <ConditionalFragment showIf={!!subjects.length}>
                                <SidebarNavTitle>
                                    Subject leadership reviews
                                </SidebarNavTitle>

                                {
                                    subjects.map(item => {
                                        return (
                                            <NavItem key={item.id}>
                                                <NavLinkTrackActive to={`/manage/subjectHome/${item.id}`} tag={Link}>
                                                    <FontAwesomeIcon icon="brain" className="nav-icon" />
                                                    {item.name}
                                                </NavLinkTrackActive>
                                            </NavItem>
                                        );
                                    })
                                }
                            </ConditionalFragment>

                            <ConditionalFragment showIf={readingReviews.eyfsToKS2 || readingReviews.ks3ToKS4}>
                                <SidebarNavTitle>
                                    Reading reviews
                                </SidebarNavTitle>

                                <ConditionalFragment showIf={readingReviews.eyfsToKS2}>
                                    <NavItem>
                                        <NavLinkTrackActive to={`/manage/readingReviewEYFSToKS2Home`} tag={Link}>
                                            <FontAwesomeIcon icon="book-reader" className="nav-icon" />
                                            EYFS, KS1, and KS2 reading
                                        </NavLinkTrackActive>
                                    </NavItem>
                                </ConditionalFragment>

                                <ConditionalFragment showIf={readingReviews.ks3ToKS4}>
                                    <NavItem>
                                        <NavLinkTrackActive to={`/manage/readingReviewKS3ToKS4Home`} tag={Link}>
                                            <FontAwesomeIcon icon="book-open" className="nav-icon" />
                                            KS3 and KS4 reading
                                        </NavLinkTrackActive>
                                    </NavItem>
                                </ConditionalFragment>
                            </ConditionalFragment>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!subscription && subscription.selfEvaluatationFeature}>
                            <SidebarNavTitle>
                                Micro-consultations
                            </SidebarNavTitle>
                            <NavItem>
                                <NavLinkTrackActive to={`/manage/microConsultations`} tag={Link}>
                                    <FontAwesomeIcon icon="video" className="nav-icon" />
                                    Micro-consultation videos
                                </NavLinkTrackActive>
                            </NavItem>
                        </ConditionalFragment>

                        <SidebarNavTitle>Users</SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to={`/manage/trustUsers/${model && model.id || ''}`} tag={Link}>
                                <FontAwesomeIcon icon="users" className="nav-icon" />
                                Trust users
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to={`/manage/schoolUsers/${model && model.id || ''}`} tag={Link}>
                                <FontAwesomeIcon icon="users" className="nav-icon" />
                                School users
                            </NavLinkTrackActive>
                        </NavItem>

                        <SidebarNavTitle>
                            Subscription
                        </SidebarNavTitle>
                        <NavItem>
                            <NavLinkTrackActive to={`/manage/subscription`} tag={Link}>
                                <FontAwesomeIcon icon="id-badge" className="nav-icon" />
                                Subscription
                            </NavLinkTrackActive>
                        </NavItem>
                    </PrivateContainer>
                </Nav>
            </SidebarNav>

            <AppSidebarFooter />
            {/*<AppSidebarMinimizer />*/}
        </>
    );
};
