import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { AppState } from '../../../store';
import { logout, UserState } from '../../../store/user';
import { RouteProps } from 'react-router';
import { NavLink, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, NavItem } from 'reactstrap';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';


interface UserNavProps extends RouteProps {
    user: UserState,
    logout: () => any,
    push: (path: string, state?: any) => any
}

interface UserNavState {
    dropdownOpen: boolean
}

// Area in a NavMenu that lets the user login/logout.
class _UserNav extends React.Component<UserNavProps, UserNavState> {
    constructor(props: UserNavProps) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    private onLogIn = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        this.props.push('/account/login', { from: this.props.location });
    };

    private onLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        this.props.logout();
        this.props.push('/account/login', { from: '/' });
    };

    private toggleDropdown = () => {
        this.setState(state => ({
            dropdownOpen: !state.dropdownOpen
        }));
    };

    render() {
        if (!this.props.user.isAuthenticated) {
            return (
                <NavItem>
                    <NavLink href="/" onClick={this.onLogIn}>
                        Login
                    </NavLink>
                </NavItem>
            );
        }

        let userDisplayName = '';
        let email = '';
        if (this.props.user.identity) {
            userDisplayName = `${this.props.user.identity.forename} ${this.props.user.identity.surname}`;
            email = this.props.user.identity.user;
        }

        return (
            <Dropdown direction="down" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} nav>
                <DropdownToggle nav>
                    <Avatar size="32" round name={userDisplayName} email={email} />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
                    <LinkContainer to="/profile">
                        <DropdownItem>
                            <FontAwesomeIcon icon="user" /> Profile
                        </DropdownItem>
                    </LinkContainer>
                    <DropdownItem divider />
                    <DropdownItem onClickCapture={this.onLogout}><FontAwesomeIcon icon="lock" /> Sign Out</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export const UserNav = connect(
    (state: AppState) => ({
        user: state.user
    }),
    (dispatch: Dispatch<AnyAction>) => ({
        logout: () => dispatch(logout()),
        push: (path: string, state?: any) => dispatch(push(path, state))
    })
)(_UserNav);
