import * as React from 'react';
import {  FormGroup, Label, Container, Input } from 'reactstrap';
import { withContainer } from 'react-withcontainer';
import { ShowUiProps, ShowContainer } from './ShowContainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { DateLabel } from '../../shared/DateLabel';
import moment from 'moment';

export const ShowUi = (props: ShowUiProps) => {
    let [earliestStateDate, latestEndDate] = React.useMemo(() => {
        if (!props.licenses || !props.licenses.length) {
            let now = moment().toISOString();
            return [now, now]
        }

        let earliestDate = props.licenses[0].startDate;
        let latestDate = props.licenses[0].endDate;

        for (var license of props.licenses) {
            if (license.startDate < earliestDate) {
                earliestDate = license.startDate;
            }

            if (license.endDate > latestDate) {
                latestDate = license.endDate;
            }
        }

        return [earliestDate, latestDate];
    }, [props.licenses]);

    if (!props.model || !props.user) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    Subscription
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors]} />

            <div>
                <FormGroup>
                    <Label htmlFor="">Subscription owner</Label>
                    <Input type="text" readOnly plaintext value={`${props.user.forename} ${props.user.surname}`} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="">Email</Label>
                    <Input type="email" readOnly plaintext value={props.user.email} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="">Joined Evaluate-Ed on</Label>
                    <div>
                        <DateLabel format="DD/MM/YYYY" value={earliestStateDate} />
                    </div>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="">Next renewal date</Label>
                    <div>
                        <DateLabel format="DD/MM/YYYY" value={latestEndDate} />
                    </div>
                </FormGroup>
                <p className="text-muted">
                    Evaluate-Ed will automatically renew for you on your renewal date.
                </p>
            </div>
        </Container>
    );
};

export const Show = withContainer(ShowContainer)(ShowUi);
