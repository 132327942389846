import * as React from 'react';
import { Container } from 'reactstrap';
import { AppFooter, AppHeader } from '@coreui/react';
import { Header } from './Header';
import { Footer } from '../defaultLayout/Footer';
import { LoadingIndicator } from '../../shared/LoadingIndicator';

export interface LayoutProps {
    appRoutes: Array<any>,
    children: any
}

export const Layout = (props: LayoutProps) => {
    return (
        <div className="app login-layout">
            <AppHeader fixed>
                <Header />
            </AppHeader>
            <div className="app-body">
                <main className="main">
                    <Container fluid>
                        <React.Suspense fallback={<LoadingIndicator />}>
                            {props.children}
                        </React.Suspense>
                    </Container>
                </main>
            </div>
            <AppFooter>
                <Footer />
            </AppFooter>
        </div>
    );
};