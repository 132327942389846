import * as React from 'react';
import { Form, FormGroup, Label, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withContainer } from 'react-withcontainer';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useUniversalNavigation } from 'react-universal-navigation';
import { DeleteContainer, DeleteUiProps } from './DeleteContainer';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { GoBackLinkContainer } from '../../shared/GoBackLinkContainer';

export const DeleteUi = (props: DeleteUiProps) => {
    const navigation = useUniversalNavigation(props);

    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let ok = await props.remove();
        if (!ok) {
            return;
        }

        navigation.goBack();
    }, [props.remove, navigation]);

    if (!props.model) {
        return (
            <>
                <AlertOnErrors errors={[props.loadingErrors, props.removingErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    return (
        <Container className="main-container">
            <div className="main-heading">
                <h1>
                    Are you sure you want to delete this consultant?
                </h1>
            </div>

            <AlertOnErrors errors={[props.loadingErrors, props.removingErrors]} />

            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Label htmlFor="forename">Forename</Label>
                    <div>
                        {props.model.forename}
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="surname">Surname</Label>
                    <div>
                        {props.model.surname}
                    </div>
                </FormGroup>

                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="danger" isExecuting={props.isRemoving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Deleting...</>}>
                        <FontAwesomeIcon icon="trash-alt" /> Delete
                    </ButtonAsync>
                    <GoBackLinkContainer>
                        <ButtonAsync color="primary" outline isExecuting={props.isRemoving}>Cancel</ButtonAsync>
                    </GoBackLinkContainer>
                </div>
            </Form>
        </Container>
    );
};

export const Delete = withContainer(DeleteContainer)(DeleteUi);