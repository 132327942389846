

/**
 * Placeholder replacement mode.
 */
export type PlaceholderReplacementMode = 'general' | 'eyfs' | 'post16' /* sixth form */ | 'post16college';



/**
 * Replace the placeholders in @param original with the right replacement based on @param mode.
 */
export function replacePlaceholders(original: string | undefined, mode: PlaceholderReplacementMode = 'general', options?: { subjectName?: string | undefined, }): string {
    if (!original) {
        return '';
    }

    // Quick logic for originals without any placeholder indicators '{' if we don't find this we know the string contains
    // no placeholders so return it unchanged.
    if (original.indexOf('{') <= -1) {
        return original;
    }

    // Process the original fully as it may contain one or more placeholders.
    //
    let ret = original;

    // Replace any placeholders in the original string.
    for (const rule of _placeholderReplacements) {
        if (ret.indexOf(rule.placeholder) > -1) {
            ret = ret.replaceAll(rule.placeholder, rule.replacements[mode]);
        }
    }
    
    // Replace special placeholders
    //{subject}
    if (!!options && !!options.subjectName) {
        ret = ret.replaceAll("{subject}", options.subjectName);
        ret = ret.replaceAll("{Subject}", options.subjectName);
    }

    return ret;
}


interface PlaceholderReplacement {
    placeholder: string,
    replacements: { [key: string]: string, }
}

/**
 * List of placeholders with their replacements.
 */
const _placeholderReplacements: Array<PlaceholderReplacement> = [
    // Pupil
    {
        placeholder: '{pupil}',
        replacements: {
            'general': 'pupil',
            'eyfs': 'child',
            'post16': 'student',
            'post16college': 'student',
        },
    },
    {
        placeholder: '{Pupil}',
        replacements: {
            'general': 'Pupil',
            'eyfs': 'Child',
            'post16': 'Student',
            'post16college': 'Student',
        },
    },

    // Pupils
    {
        placeholder: '{pupils}',
        replacements: {
            'general': 'pupils',
            'eyfs': 'children',
            'post16': 'students',
            'post16college': 'students',
        },
    },
    {
        placeholder: '{Pupils}',
        replacements: {
            'general': 'Pupils',
            'eyfs': 'Children',
            'post16': 'Students',
            'post16college': 'Students',
        },
    },


    // Pupils'
    {
        placeholder: "{pupils'}",
        replacements: {
            'general': "pupils'",
            'eyfs': "children's",
            'post16': "students'",
            'post16college': "students'",
        },
    },
    {
        placeholder: "{Pupils'}",
        replacements: {
            'general': "Pupils'",
            'eyfs': "Children's",
            'post16': "Students'",
            'post16college': "Students'",
        },
    },

    // School
    {
        placeholder: '{school}',
        replacements: {
            'general': 'school',
            'eyfs': 'EYFS',
            'post16': 'Sixth Form',
            'post16college': 'college',
        },
    },
    {
        placeholder: '{School}',
        replacements: {
            'general': 'School',
            'eyfs': 'EYFS',
            'post16': 'Sixth Form',
            'post16college': 'College',
        },
    },

    // Pupil Premium
    {
        placeholder: '{Pupil Premium}',
        replacements: {
            'general': 'Pupil Premium',
            'eyfs': 'Pupil Premium',
            'post16': 'Bursary Funded',
            'post16college': 'Bursary Funded',
        },
    },

    // English
    {
        placeholder: '{English}',
        replacements: {
            'general': 'English',
            'eyfs': 'ELG',
            'post16': 'English',
            'post16college': 'English',
        },
    },

    // Curriculum
    {
        placeholder: '{curriculum}',
        replacements: {
            'general': 'curriculum',
            'eyfs': 'ELG curriculum',
            'post16': 'curriculum',
            'post16college': 'curriculum',
        },
    },
    {
        placeholder: '{Curriculum}',
        replacements: {
            'general': 'Curriculum',
            'eyfs': 'ELG curriculum',
            'post16': 'Curriculum',
            'post16college': 'Curriculum',
        },
    },

    // SEND
    {
        placeholder: '{SEND}',
        replacements: {
            'general': 'SEND',
            'eyfs': 'SEND',
            'post16': 'high needs',
            'post16college': 'high needs',
        },
    },
];
