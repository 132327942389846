import * as React from 'react';
import Chart from 'react-apexcharts';
import { isNullOrUndefined } from 'util';
import { useUniversalNavigation } from 'react-universal-navigation';
import { dashboardDrilldownUrl } from '../../../../utilities/dashboardDrilldownUrl';
import { ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Project } from '../../../../api/models/Project';
import { chartLargeColorPallet, chartLargeColorPallet_SpecialForTrustTargetSpidergraph } from '../../../../utilities/chartLargeColorPallet';
import { useProjectSettings } from '../../../../api/useProjectSettings';
import { Guid } from 'guid-string';
import { getGradeString } from '../../../../utilities/getGradeString';
import { AwardTag } from '../../../../api/models/AwardTag';

export interface SpidergraphBarChartProps {
    model: Project | undefined,
    filterBySectionOriginKey: string | undefined,
    filterByTopicOriginKey: string | undefined,
    results: Array<ProjectAnalysisResult>,

    showOtherSchools?: boolean,
    chartHeight?: number

    isForSingleSchool: boolean

    awardTag: AwardTag | undefined,
}

export const SpidergraphBarChart = (props: SpidergraphBarChartProps) => {
    const navigation = useUniversalNavigation(props);
    const projectSettings = useProjectSettings(props.model && props.model.id || '', props.awardTag && props.awardTag.id || undefined);

    // Work out if we are showing questions as if we are we know the names will be long so want to show a code/index index of the name.
    let isForQuestions = React.useMemo(() => {
        if (props.filterByTopicOriginKey) {
            return true;
        }

        return false;
    }, [props.filterByTopicOriginKey]);

    const canNavigateDeeper = !isForQuestions || (props.model && !Guid.isEmpty(props.model.schoolId) || false);

    // Get the section of the results we care about.
    const results = React.useMemo(() => {
        // Include all available result sets so we can show all schools.
        if (props.showOtherSchools) {
            // Special handling if we need to hide the targets for an award while still showing all other results.
            if ((props.awardTag && props.awardTag.hideTargets) || !projectSettings.questionSetType.trustTargets) {
                if (Guid.isEmpty(props.model && props.model.schoolId) && props.results.length > 1) {
                    return props.results.slice(1);
                } else {
                    return [props.results[0], ...props.results.slice(2)];
                }
            }

            return props.results;
        }

        // Include only results for this school (and its trust if it has one).
        if (props.results.length <= 1) {
            return props.results;
        }

        // If we're showing for an award/inspection that hides targets just return the school ignoring the trust.
        if ((props.awardTag && props.awardTag.hideTargets) || !projectSettings.questionSetType.trustTargets) {
            if (Guid.isEmpty(props.model && props.model.schoolId) && props.results.length > 1) {
                return [props.results[1]];
            } else {
                return [props.results[0]];
            }
        }

        return [props.results[0], props.results[1]];
    }, [props.results, props.showOtherSchools, props.awardTag, projectSettings, props.model]);

    // Work out some scales and color for the background of the chart based on its data.
    const [min, max, colors] = React.useMemo(() => {
        if (!results || !results.length) {
            return [0, 1, []];
        }

        let dataMax = 100;
        for (let series of results) {
            for (let record of series.results) {
                if (record.percentageQuestionScore > dataMax) {
                    dataMax = record.percentageQuestionScore;
                }
            }
        }

        // Work out the range of the colours to show in the background.
        const redMax = 30;
        const orangeMax = 50;
        const greenMax = 80;

        // Colors need to be built in reverse order.
        let colors: Array<string> = [];
        let currentOpacity = 1.0;
        for (let i = 0; i < dataMax; i += 10) {
            if (i < redMax) {
                colors = [`rgba(253, 33, 33, ${currentOpacity})`, ...colors];
            } else if (i < orangeMax) {
                colors = [`rgba(253, 162, 33, ${currentOpacity})`, ...colors];
            } else if (i < greenMax) {
                colors = [`rgba(52, 207, 52, ${currentOpacity})`, ...colors];
            } else {
                colors = [`rgba(188, 198, 204, ${currentOpacity})`, ...colors];
            }

            if (currentOpacity > 0.2) {
                currentOpacity -= 0.1;
            }
        }

        return [0, dataMax, colors];
    }, [results]);
   

    const options = React.useMemo(() => ({
        labels: results[0].results.map(item => isForQuestions ? item.questionNumber : item.name) || [],

        chart: {
            toolbar: {
                show: false,
            },

            events: {
                // When the user clicks on a point in the chart, drill into that item.
                // NOTE dataPointSelection event doesn't work on raidal charts so we have to use the click event.
                click: (event: any, chartContext: any, config: any) => {
                    // If we didn't click on a datapoint, do nothing.
                    if (isNullOrUndefined(config.dataPointIndex)) {
                        return;
                    }

                    // We clicked on a datapoint so lets drill into it.
                    let myResult = results[0].results[config.dataPointIndex];
                    if (!myResult) {
                        return;
                    }

                    // If we don't allow any deeper navigation, stop.
                    if (!canNavigateDeeper) {
                        return;
                    }

                    // Do the actual navigation
                    let url = dashboardDrilldownUrl(projectSettings, props.filterBySectionOriginKey, props.filterByTopicOriginKey, myResult.originKey);
                    navigation.navigate(url);
                },
            }
        },

        colors: props.model && Guid.isEmpty(props.model.schoolId) ? chartLargeColorPallet_SpecialForTrustTargetSpidergraph : chartLargeColorPallet,

        dataLabels: {
            formatter: function (val: any) {
                return getGradeString(val);
            }
        },

        grid: {
            row: {
                colors: colors,
            }
        },

        tooltip: {
            y: {
                formatter: function (val: any) {
                    return getGradeString(val);
                }
            },
            //x: {
            //    formatter: function (val: any) {
            //        if (!isForQuestions) {
            //            return val;
            //        }

            //        let sIndex = val.substring(1, val.length - 1);
            //        let index = parseInt(sIndex);
            //        if (isNaN(index)) {
            //            return val;
            //        }

            //        let match = results[0].results.find(it => it.index === (index - 1));
            //        if (!match) {
            //            return val;
            //        }

            //        return `${val} ${match.name}`;
            //    }
            //},
        },

        yaxis: {
            min: min,
            max: max,
            tickAmount: (max / 10),
            labels: {
                formatter: function (val: any) {
                    return getGradeString(val);
                },
                minWidth: 125,
                style: {
                    fontSize: '10px',
                },
            },
        }
    }), [results, min, max, colors, projectSettings, canNavigateDeeper, props.model]);

    var series = React.useMemo(() => results.map((item, index) => ({
        name:
            item.school ? item.school.name // School name
                // Special case for when the trust is showing its own project
                : Guid.isEmpty(props.model && props.model.schoolId || '') && index === 0 && props.awardTag && props.awardTag.hideTargets ? 'School averages'
                : Guid.isEmpty(props.model && props.model.schoolId || '') && index === 0 ? 'Trust targets'
                    : Guid.isEmpty(props.model && props.model.schoolId || '') && index === 1 ? 'School averages'
                        : item.trust && !props.isForSingleSchool ? 'Trust targets'
                            : props.isForSingleSchool ? 'School targets'
                                : 'School averages',
        data: results[0].results.map(resultZeroItem => {
                // Always ensure the data points match the order (and only include the items within the first result (i.e. this project)
                const myItem = item.results.find(itt => itt.originKey === resultZeroItem.originKey);
                if (!myItem) {
                    return undefined;
                }

                return myItem.percentageQuestionScore;
            })
        })), [results]);


    if (!props.results || !projectSettings.baseRoute) {
        return (<></>);
    }

    // Show the chart.
    return (
        <Chart options={options} series={series} type="bar" height={props.chartHeight ? props.chartHeight : 800} />
    );
};

