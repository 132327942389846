import './styles/app.scss'
import './configure/polyfill';

import * as React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter, goBack, push } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store/configureStore';
import { routes } from './configure/routeConfig';
import { persistStore } from 'redux-persist';
import { ServiceProvider } from "inject-typesafe";
import { ServiceProviderContext } from "inject-typesafe-react";
import { createConfigureServices } from './configure/configureServices';
import { initializeIcons } from './configure/iconsConfig';
import { library } from '@fortawesome/fontawesome-svg-core';
import { universalNavigation } from 'react-universal-navigation';
import { AppRoutes } from './components/AppRoutes';
import { LoadingIndicator } from './components/shared/LoadingIndicator';
import TagManager from "react-gtm-module";

// Create browser history to use in the Redux store
const baseUrl: string | undefined | null = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: (baseUrl == null ? "/" : (baseUrl as string | undefined)) });

// Get the application-wide store instance, prepopulating with state from the server and persisted local data where available.
var windowAny = window as any;
const initialState = windowAny.initialReduxState;
const store = configureStore(history, initialState);
const persistor = persistStore(store);

// Configure services used by the app.
const configureServices = createConfigureServices(store);
const serviceProvider = new ServiceProvider(configureServices);

// Initialize the icons we use in our app (as svg).
initializeIcons(library);

// Configure universalNavigation to use connected-react-router.
universalNavigation.useConnectedReactRouter(store.dispatch, goBack, push);

// Configure automatic token renewal every 30 minutes (NOTE: you should make sure this is signifcantly less than your expiry so the token renewal
// can happen unattended during normal use.  This apps current token expiry is 2 hours).
const tokenRenewalInterval = 30 * 60 * 1000; /* 30 minutes */
setInterval(() => {
    serviceProvider.services().renewToken()(false);
}, tokenRenewalInterval);


// Configure google tag manager.
var identity = store.getState().user.identity
TagManager.initialize({
    gtmId: 'G-PH5T8ZZB15',
    dataLayer: {
        userId: identity? identity.user: '',
    },
});

// App UI
export const App = (props: any) => {
    return (
        <ServiceProviderContext.Provider value={serviceProvider}>
            <Provider store={store}>
                <PersistGate loading={<LoadingIndicator />} persistor={persistor} onBeforeLift={() => serviceProvider.services().renewToken()()} >
                    <ConnectedRouter history={history}>
                        <AppRoutes routes={routes} />
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </ServiceProviderContext.Provider>
    );
};

export default App;