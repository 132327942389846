import * as React from 'react';
import { ProjectSectionUi } from './ProjectSectionUi';
import { ReportProps } from '../reportGenerator/ReportProps';


/**
 * Question breakdown that shows a section by section and topic by topic breakdown of responses.
 */
export const QuestionBreakdownUi = (props: ReportProps) => {
    if (!props.model) {
        return (<></>);
    }

    return (
        <div>
            <h3>Breakdown of results</h3>

            {
                props.projectSections.models.map(item => (
                    <ProjectSectionUi key={item.id} projectSectionId={item.id} {...props} />
                    ))
            }
        </div>        
    );
};
