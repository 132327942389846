import * as React from 'react';
import Chart from 'react-apexcharts';
import { chartLargeColorPallet } from '../../../utilities/chartLargeColorPallet';

export interface DonutChartData {
    subscription: string,
    data: number,
}

export interface DonutChartProps {
    title: string,
    model: Array<DonutChartData>,
    chartHeight?: number
}

/**
 * Generic reusable donut chart for showing stats by subscription.
 * @param props
 */
export const DonutChart = (props: DonutChartProps) => {
    const options = React.useMemo(() => ({
        legend: {
            show: false,
        },

        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: props.title || 'Total'
                        }
                    }
                }
            }
        },

        dataLabels: {
            enabled: false,
        },

        colors: chartLargeColorPallet,

        labels: props.model.map(item => item.subscription),
    }), [props.model]);

    var series = React.useMemo(() => props.model.map(item => item.data), [props.model]);
    
    // Show the chart.
    return (
        <Chart options = { options } series = { series } type = "donut" height = { props.chartHeight ? props.chartHeight : 400 } />
    );
};

