import * as React from 'react';
import { Link } from 'react-router-dom';

export class Footer extends React.Component {
    render() {
        return (
            <>
                <div className="footer-brand">
                </div>
                <div className="footer-links">
                    <Link to="/terms-and-conditions">
                        Terms and Conditions
                    </Link>
                    <Link to="/privacy-poliy">
                        Privacy Policy
                    </Link>
                </div>
            </>
        );
    }
}
