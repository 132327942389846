/**
 * Enum that maps to the ProjectAnalysisLevel C# enum to specify which level we'd like the project to be at.
 */
export enum ProjectAnalysisLevel {
    Overall,
    Section,
    Topic,
    Questionaire,
    Question,
    Mixed
}
