import * as React from 'react';
import moment from 'moment';
import { Text } from '@react-pdf/renderer';

export interface DateLabelPdfProps {
    value: string,
    localize?: boolean,
    format?: string
}

/**
 * Label that shows a date in local time formatted via moment().
 * @param props
 */
export const DateLabelPdf = (props: DateLabelPdfProps) => {
    const date = moment(props.value);
    const format = props.format || 'DD/MM/YYYY';
    const localize = props.localize || true;

    let theDate = date;
    if (localize) {
        theDate = date.local();
    }


    return (
        <Text>{theDate.format(format)}</Text>
    );
};
