import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LinkContainer } from 'react-router-bootstrap';
import { useUniversalNavigation } from 'react-universal-navigation';
import { useAsyncCallback } from 'react-use-async-callback';
import { Alert, Button, Col, Row } from 'reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { AwardTag } from '../../../api/models/AwardTag';
import { School } from '../../../api/models/School';
import { SchoolTagCertificate } from '../../../api/models/SchoolTagCertificate';
import { useApiService } from '../../../api/useApiService';
import { generatePdfAsBlob } from '../../../utilities/generatePdfAsBlob';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { DateLabel } from '../../shared/DateLabel';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { CertificatePdf } from './CertificatePdf';

export interface CertificateProps {
}

/**
 * Certificate for an award or inspection.
 * @param props
 */
export const Certificate = (props: CertificateProps) => {
    const navigation = useUniversalNavigation(props);
    const projectId = navigation.getParam('projectId', '');
    const awardTagId = navigation.getParam('awardTagId', '');
    const schoolId = navigation.getParam('schoolId', '');

    //const projectSettings = useProjectSettings(projectId, awardTagId);

    const api = useApiService();
    const loadViewModel = api.schoolTagCertificates.viewModels.certificate();

    const [model, setModel] = React.useState<SchoolTagCertificate | undefined>(undefined);
    const [school, setSchool] = React.useState<School | undefined>(undefined);
    const [awardTag, setAwardTag] = React.useState<AwardTag | undefined>(undefined);

    const [load, { isExecuting: isLoading, errors: loadErrors }] = useAsyncCallback(async () => {
        const result = await loadViewModel(schoolId, awardTagId);
        setSchool(result.school);
        setAwardTag(result.awardTag);
        setModel(result.model);
    }, [schoolId, awardTagId, setModel, setSchool]);

    React.useEffect(() => {
        load();
    }, [schoolId, awardTagId, load]);

    // Generate a PDF file as a blob.
    const generatePdf = React.useCallback(async (): Promise<Blob> => {
        let blob = await generatePdfAsBlob(CertificatePdf({
            model: model as SchoolTagCertificate,
            school: school as School,
            awardTag: awardTag as AwardTag,
        }));
        return blob;
    }, [model, school, awardTag]);

    // Generate the PDF and open it in a new window for printing.
    const [generateAndOpenPdf, { isExecuting: isGeneratingPdfForOpen }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        let url = URL.createObjectURL(blob);
        window.open(url);
    }, [generatePdf]);

    // Generate the PDF and download it.
    const [generateAndDownloadPdf, { isExecuting: isGeneratingPdfForDownload }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        FileSaver.saveAs(blob, `${model && model.awardName || 'Evaluate-Ed'} certificate.pdf`);
    }, [generatePdf, model]);

    if (!school || !awardTag) {
        return (
            <>
                <AlertOnErrors errors={[loadErrors]} />
                <LoadingIndicator />
            </>
        );
    }

    // Show an alert if we haven't earnt the certificate yet.
    if (!model || !model.isAwarded) {
        return (
            <Alert color="warning">
                <p><strong>
                    You have not yet received the {awardTag.name} certificate.
                        </strong></p>
                <ConditionalFragment showIf={awardTag.canSubmit}>
                    <p>
                        To receive the certificate you need to complete the required review and submit it to {awardTag.awardingOrganisationName} who will issue the certificate.
                            </p>
                </ConditionalFragment>
                <ConditionalFragment showIf={!awardTag.canSubmit}>
                    <p>
                        To receive the certificate you need to complete the required review and claim the certificate.
                            </p>
                </ConditionalFragment>

                <p>
                    <LinkContainer to={`/school/${schoolId}/awardHome/${awardTagId}/${projectId}`}>
                        <Button color="primary">
                            You can view your progress towards the certificate here
                        </Button>
                    </LinkContainer>
                </p>
            </Alert>
            );
    }

    return (
        <>
            <ConditionalFragment showIf={!!model && model.isAwarded}>
                <div className="toolbar-top">
                    <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} isExecuting={isGeneratingPdfForOpen}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Generating PDF...</>}>
                        <FontAwesomeIcon icon="print" /> Print
                    </ButtonAsync>
                    <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} isExecuting={isGeneratingPdfForDownload}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Generating PDF...</>}>
                        <FontAwesomeIcon icon="file-download" /> Download PDF
                    </ButtonAsync>
                </div>
            </ConditionalFragment>

            <AlertOnErrors errors={[loadErrors]} />

            <div className="certificate">
                <div className="certificate-header">
                </div>

                <div className="certificate-body">
                    <div className="certificate-school">
                        {school.name}
                    </div>
                    <div className="certificate-has-acheived">
                        Has successfully achieved the following certification
                    </div>
                    <div className="certificate-name">
                        {model && model.awardName || ''}
                    </div>

                    <Row className="certificate-details">
                        <Col className="certificate-details-number">
                            <ConditionalFragment showIf={!!model && !!model.certificateNumber}>
                                Certificate number: {model && model.certificateNumber || ''}
                            </ConditionalFragment>
                        </Col>

                        <Col className="certificate-details-awardedby">
                            <ConditionalFragment showIf={!!model && !!model.awardingOrganisationName}>
                                Awarded by: {model && model.awardingOrganisationName || ''}
                            </ConditionalFragment>
                        </Col>
                    </Row>
                </div>
                <div className="certificate-footer">
                </div>
            </div>
        </>
        );
};