import { AnyAction, Dispatch } from 'redux';
import { Identity } from '../state';
import { AuthenticationService } from '../../../services/AuthenticationService';

export const loginType = '@@User/LOGIN';

export interface LoginAction {
    type: typeof loginType,
    payload: {
        identity: Identity,
        token: string
    }
}

/**
 * Login has been completed (we don't deal with the networking), and we've been given the details of the user to consider
 * logged in for this user.
 * @param user
 * @param password
 */
export function login(token: string | undefined, identity: Identity | undefined) {
    return {
        type: loginType,
        payload: {
            token: token,
            identity: identity
        }
    } as LoginAction;
}
