import * as React from 'react';
import { Redirect } from 'react-router';
import { withContainer } from 'react-withcontainer';
import { HomeContainer, HomeUiProps } from './HomeContainer';
import { LoadingIndicator } from './shared/LoadingIndicator';

export const HomeUi = (props: HomeUiProps) => {
    if (!props.user) {
        return <LoadingIndicator />
    }

    if (!props.user.isAuthenticated || !props.user.identity) {
        return (<Redirect to="/account/login" />);
    }

    if (props.user.identity.roles.find(item => item === 'Administration')) {
        return (<Redirect to="/administration" />);
    }

    if (props.user.identity.roles.find(item => item === 'Manage')) {
        return (<Redirect to={`/manage`} />);
    }

    return (<Redirect to={`/school/${props.user.identity.schoolId ? props.user.identity.schoolId : ''}`} />);    
};

export const Home = withContainer(HomeContainer)(HomeUi);
