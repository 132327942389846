import { SEFDocxProps, finalizeChildren } from './SEFDocx';
import { ProjectAnalysisResultItem, ProjectAnalysisResult } from '../../../../api/models/ProjectAnalaysisResult';
import { Paragraph, HeadingLevel, AlignmentType, Table, TableRow, TableCell, WidthType, BorderStyle, ShadingType, TextRun } from 'docx';
import { getGradeString } from '../../../../utilities/getGradeString';
import { Strength } from '../../../../api/models/Strength';
import { Risk } from '../../../../api/models/Risk';
import { PlaceholderReplacementMode, replacePlaceholders } from '../../../../utilities/replacePlaceholders';
import { Question } from '../../../../api/models/Question';
import { QuestionResponse } from '../../../../api/models/QuestionResponse';


export interface SEFProjectTopicDocxUiProps extends SEFDocxProps {
    projectTopicId: string,
    isFirstTopic: boolean,
}

/**
 * Breakdown of strengths and recommendations by sections and topics for SEF (docx format).
 */
export const SEFProjectTopicDocxUi = (props: SEFProjectTopicDocxUiProps) => {
    // Find the appropriate items we want to work with.  This is formatted to be similar to a useMemo() because the code is taken from there
    // from within the PDF.
    const {
        questionResponses,
    } = props;

    // Find the appropriate items we want to work with.
    const { projectTopic, topic, results, questions, /*questionaires, */ excellences, strengths, risks, myResults, } = (() => {
        let ps = props.projectTopics.models.find(item => item.id === props.projectTopicId);
        let topicId = '';
        if (ps) {
            topicId = ps.topicId;
        }

        let s = props.topics.find(item => item.id === topicId);

        let r = props.resultsByTopic.find(item => item.projectTopicId === props.projectTopicId);
        let rr: Array<ProjectAnalysisResult> = [];
        if (r) {
            rr = r.results;
        }

        const sectionResults = props.resultsBySection.find(item => !!s && item.sectionId === s.sectionId);
        let myResults: ProjectAnalysisResultItem | undefined = undefined;
        if (sectionResults && sectionResults.results.length) {
            myResults = sectionResults.results[0].results.find(it => s && it.originKey === s.originKey);
        }

        let q = props.questions.filter(item => item.topicId === topicId);
        //let qq = props.questionaires.filter(item => q.find(it => it.questionaireId === item.id));

        const strengthsx = props.strengths.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));
        const strengthsExcellences = strengthsx.filter(item => item.isExcellence);
        const strengthsAreasOfStrength = strengthsx.filter(item => !item.isExcellence);
        const risksx = props.risks.filter(item => item.topicId === topicId || !!q.find(it => it.id === item.questionId));

        return { projectTopic: ps, topic: s, results: rr, questions: q, /*questionaires: qq,*/ excellences: strengthsExcellences, strengths: strengthsAreasOfStrength, risks: risksx, myResults: myResults };
    })();

    if (!props.model) {
        return null;
    }

    if (!projectTopic) {
        return null;
    }

    if (!excellences.length && !strengths.length && !risks.length) {
        return null;
    }
    let placeholderReplacementMode = props.placeholderReplacementMode;
    return [
        // Heading.
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: topic && replacePlaceholders(topic.name, placeholderReplacementMode, { subjectName: props.subject?.name }) || '',
                                    heading: HeadingLevel.HEADING_3,
                                })
                            ],
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: getGradeString(myResults && myResults.percentageQuestionScore || 0),
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.RIGHT,
                                })
                            ],
                        }),
                    ],
                }),
            ],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [
                80,
                20,
            ],
            borders: {
                top: {
                    style: BorderStyle.NONE,
                },
                bottom: {
                    style: BorderStyle.NONE,
                },
                left: {
                    style: BorderStyle.NONE,
                },
                right: {
                    style: BorderStyle.NONE,
                },
                insideHorizontal: {
                    style: BorderStyle.NONE,
                },
                insideVertical: {
                    style: BorderStyle.NONE,
                },
            },
        }),
        new Paragraph({ text: '', }), // For spacing and making editing easier.

        // Question details.
        //

        // Areas of excellence
        !!excellences.length ? (
            TableFor({
                title: "Areas of excellence",
                items: excellences,
                placeholderReplacementMode: props.placeholderReplacementMode,
                questions: questions,
                questionResponses: questionResponses,
                subjectName: props.subject?.name,
            })
        ) : null,

        // Areas of strength
        !!strengths.length ? (
            TableFor({
                title: "Areas of strength",
                items: strengths,
                placeholderReplacementMode: props.placeholderReplacementMode,
                questions: questions,
                questionResponses: questionResponses,
                subjectName: props.subject?.name,
            })
        ) : null,

        // Areas for development
        !!risks.length ? (
            TableFor({
                title: "Areas for development",
                items: risks,
                placeholderReplacementMode: props.placeholderReplacementMode,
                questions: questions,
                questionResponses: questionResponses,
                subjectName: props.subject?.name,
            })
        ) : null,
    ];
};

/**
 * Child component to generate a table showing the passed in items.
 * This is not exported from this file and only used internall by the files main component.
 */
const TableFor = (props: {
    title: string,
    items: Array<Strength | Risk>,
    placeholderReplacementMode: PlaceholderReplacementMode,
    questions: Array<Question>,
    questionResponses: Array<QuestionResponse>,
    subjectName: string | undefined,
}) => {
    const {
        title,
        items,
        placeholderReplacementMode,
        questions,
        questionResponses,
        subjectName,
    } = props;

    return [
        new Table({
            rows: finalizeChildren([
                // Heading row
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: '',
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                            width: {
                                size: 10,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: title,
                                            color: '#ffffff',
                                        }),
                                    ],
                                })
                            ],
                            width: {
                                size: 90,
                                type: WidthType.PERCENTAGE,
                            },
                            borders: {
                                top: {
                                    style: BorderStyle.THICK,
                                },
                                bottom: {
                                    style: BorderStyle.THICK,
                                },
                            },
                            shading: {
                                type: ShadingType.SOLID,
                                color: '#1c599c',
                            },
                        }),
                    ],
                    tableHeader: true,
                }),

                // Item rows
                ...items.map(item => {
                    // Without an associated question we can't show anything.
                    const question = questions.find(it => it.id === item.questionId);
                    if (!question) {
                        return null;
                    }

                    // Get the response to look up the feedback.  If any has been written we'll include it in the table.
                    const response = questionResponses.find(it => it.id === item.questionResponseId);
                    let feedback = '';
                    if (response) {
                        feedback = response.feedback;
                    }

                    return new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        text: question.questionNumber,
                                    }),
                                ],
                                width: {
                                    size: 10,
                                    type: WidthType.PERCENTAGE,
                                },
                            }),
                            new TableCell({
                                children: finalizeChildren([
                                    new Paragraph({
                                        text: replacePlaceholders(item.description, placeholderReplacementMode, { subjectName: subjectName }),
                                    }),
                                    !!feedback ? (
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: replacePlaceholders(feedback, placeholderReplacementMode, { subjectName: subjectName }),
                                                    color: '#606060',
                                                }),
                                            ],
                                            border: {
                                                top: {
                                                    style: BorderStyle.DOTTED,
                                                },
                                            },
                                        })
                                    ): null,
                                ]),
                                width: {
                                    size: 90,
                                    type: WidthType.PERCENTAGE,
                                },
                            }),
                        ],
                        cantSplit: false,
                    });
                }),
            ]),
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [
                10,
                90,
            ],
            borders: {
                top: {
                    style: BorderStyle.SINGLE,
                },
                bottom: {
                    style: BorderStyle.SINGLE,
                },
                left: {
                    style: BorderStyle.NONE,
                },
                right: {
                    style: BorderStyle.NONE,
                },
                insideHorizontal: {
                    style: BorderStyle.SINGLE,
                },
                insideVertical: {
                    style: BorderStyle.NONE,
                },
            },
        }),
        new Paragraph({ text: '', }), // For spacing and making editing easier.
    ];
};

