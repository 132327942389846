import * as React from 'react';
import { Form, FormGroup, Label, Button, Alert, Container, Input } from 'reactstrap';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { LinkContainer } from 'react-router-bootstrap';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { AlertOnErrors } from '../shared/AlertOnErrors';
import { TrialSignUp as TrialSignUpModel } from '../../api/models/TrialSignUp';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidationState } from 'pojo-validator';
import { useAsyncCallback } from 'react-use-async-callback';
import { useApiService } from '../../api/useApiService';

export const TrialSignUp = () => {
    const api = useApiService();
    const signUp = api.trial.actions.signUp();

    const [model, setModel] = React.useState<TrialSignUpModel>(() => ({
        forename: '',
        surname: '',
        email: '',
        phoneNumber: '',
        jobTitle: '',
        schoolName: '',
        dfeReference: '',
        postcode: '',
        isAcademy: false,
        isMaintained: true,
        isEarlyYears: false,
        isPrimary: false,
        isInfant: false,
        isJunior: false,
        isSecondary: false,
        isPost16: false,
        specialisms: [],
    }));

    const changeModel = React.useCallback((changes: Partial<TrialSignUpModel>) => {
        setModel(prevState => ({
            ...prevState,
            ...changes
        }));
    }, [setModel]);

    // Toggle a specialsim on or off.
    const toggleSpecialism = React.useCallback((name: string) => {
        setModel(prevState => {
            const existing = prevState.specialisms.find(it => it === name);
            if (!!existing) {
                // Remove this specialism.
                return ({
                    ...prevState,
                    specialisms: prevState.specialisms.filter(it => it !== name),
                });
            } else {
                // Add this specialism.
                return ({
                    ...prevState,
                    specialisms: [
                        ...prevState.specialisms,
                        name,
                        ],
                });
            }
        });
    }, [setModel])

    // Validate the model.
    const [validate, validationErrors] = useValidatorCallback((validation: ValidationState, fieldsToCheck?: Array<string>): void => {
        if (!fieldsToCheck || fieldsToCheck.includes('forename')) {
            validation.singleCheck('forename', () => !model.forename, 'Forename is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('surname')) {
            validation.singleCheck('surname', () => !model.forename, 'Surname is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('email')) {
            validation.singleCheck('email', () => !model.email, 'Email is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('schoolName')) {
            validation.singleCheck('schoolName', () => !model.schoolName, 'School is required');
        }

        if (!fieldsToCheck || fieldsToCheck.includes('postcode')) {
            validation.singleCheck('postcode', () => !model.postcode, 'Postcode is required');
        }
    }, [model]);

    const [isSignedUp, setIsSignedUp] = React.useState<boolean>(false);
    const [signUpError, setSignUpError] = React.useState<string | undefined | null>();

    const [save, { isExecuting: isSaving, errors: saveErrors }] = useAsyncCallback(async () => {
        // Must be valid before we save it.
        if (!validate()) {
            return false;
        }

        // Save to the database.
        const result = await signUp(model);

        // Update our state based on the server's sign up result.
        setIsSignedUp(result.success);
        setSignUpError(result.error);

        return true;
    }, [signUp, model, setIsSignedUp, setSignUpError, validate]);

    // Save.
    const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        await save();
    }, [save]);

    return (
        <Container className="main-container account">
            <div className="main-heading">
                <img className="logo img-fluid" src="/img/brand/logo-full.png" />
            </div>

            <div className="help-text">
                You can start a free trial of Evaluate-Ed for your school which will allow you complete a full SEF Lite of your school and experience Evaluate-Ed for yourself.
                <br />
                All we need to get you started are a few details about yourself and your school.
            </div>

            <AlertOnErrors errors={[saveErrors, signUpError]} />

            {
                (!!isSignedUp) ? (
                    <Alert color="success">
                        You have been sent an email to {model.email} with a link to confirm your email and start your free trial.
                    </Alert>
                ) : null
            }

            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Label htmlFor="forename">First name</Label>
                    <ValidatedInput type="text" name="forename" placeholder="" value={model.forename} onChange={e => changeModel({ forename: e.currentTarget.value })} onBlur={() => validate('forename')} validationErrors={validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="surname">Last name</Label>
                    <ValidatedInput type="text" name="surname" placeholder="" value={model.surname} onChange={e => changeModel({ surname: e.currentTarget.value })} onBlur={() => validate('surname')} validationErrors={validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <ValidatedInput type="email" name="email" placeholder="" value={model.email} onChange={e => changeModel({ email: e.currentTarget.value })} onBlur={() => validate('email')} validationErrors={validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="schoolName">School</Label>
                    <ValidatedInput type="text" name="schoolName" placeholder="" value={model.schoolName} onChange={e => changeModel({ schoolName: e.currentTarget.value })} onBlur={() => validate('schoolName')} validationErrors={validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="schoolType">What type of school do you work for?</Label>
                    <Input type="select" name="schoolType" placeholder=""
                        value={model.isAcademy ? 'academy' : 'maintained'}
                        onChange={e => changeModel({ isMaintained: (e.currentTarget.value === 'maintained'), isAcademy: (e.currentTarget.value === 'academy'), })}
                    >
                        <option value="maintained">Maintained school</option>
                        <option value="academy">Academy</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="postcode">School postcode</Label>
                    <ValidatedInput type="text" name="postcode" placeholder="" value={model.postcode} onChange={e => changeModel({ postcode: e.currentTarget.value })} onBlur={() => validate('postcode')} validationErrors={validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="dfeReference">DfE number (optional)</Label>
                    <ValidatedInput type="text" name="dfeReference" placeholder="" value={model.dfeReference} onChange={e => changeModel({ dfeReference: e.currentTarget.value })} onBlur={() => validate('dfeReference')} validationErrors={validationErrors} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="schoolPhases">School phases</Label>
                    <div className="tag-component">
                        <div className={`tag-badge ${model.isEarlyYears ? "selected" : ""}`} onClick={(ev) => changeModel({ isEarlyYears: !model.isEarlyYears })}>
                            Early Years
                        </div>
                        <div className={`tag-badge ${model.isInfant ? "selected" : ""}`} onClick={(ev) => changeModel({ isInfant: !model.isInfant })}>
                            Infant
                        </div>
                        <div className={`tag-badge ${model.isJunior ? "selected" : ""}`} onClick={(ev) => changeModel({ isJunior: !model.isJunior })}>
                            Junior
                        </div>
                        <div className={`tag-badge ${model.isSecondary ? "selected" : ""}`} onClick={(ev) => changeModel({ isSecondary: !model.isSecondary })}>
                            Secondary
                        </div>
                        <div className={`tag-badge ${model.isPost16 ? "selected" : ""}`} onClick={(ev) => changeModel({ isPost16: !model.isPost16 })}>
                            Post-16
                        </div>
                    </div>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="specialisms">School specialisms</Label>
                    <div className="tag-component">
                        <div className={`tag-badge ${!!model.specialisms.find(it => it === "Roman Catholic Faith") ? "selected" : ""}`} onClick={(ev) => toggleSpecialism('Roman Catholic Faith')}>
                            Roman Catholic Faith
                        </div>
                        <div className={`tag-badge ${!!model.specialisms.find(it => it === "CoE Faith") ? "selected" : ""}`} onClick={(ev) => toggleSpecialism('CoE Faith')}>
                            CoE Faith
                        </div>
                    </div>
                </FormGroup>



                <div className="toolbar-bottom">
                    <ButtonAsync type="submit" color="primary" isExecuting={isSaving}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Starting trial...</>}>
                        Start trial
                    </ButtonAsync>

                    <div className="float-right float-bottom">
                        <a href="https://evaluate-ed.com/">
                            Want to learn more?
                        </a>
                    </div>
                </div>
            </Form>
        </Container>
    );
};