import * as React from 'react';
import { Card as BootstrapCard, CardTitle, CardBody, Row, Col, Container, CardFooter, Button, Input } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadMoreWaypoint } from './LoadMoreWaypoint';
import { InfiniteScrollingList, InfiniteScrollingListProps } from './InfiniteScrollingList';

/**
 * InfinateScrollingList that conforms to the structure of a table by wrapping the waypoint in <tr><td></td></tr>.
 * @param props
 */
export const InfiniteScrollingTable = (props: InfiniteScrollingListProps) => {
    return (
        <InfiniteScrollingList {...props} wrapWaypoint={(jsx) => (<tr><td>{jsx}</td></tr>)} />
        );
};
