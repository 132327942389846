import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertOnErrors } from '../../shared/AlertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { AddToRecommendationsModalUiProps, AddToRecommendationsModalContainer } from './AddToRecommendationsModalContainer';
import { ValidatedElasticInput } from '../../shared/ValidatedElasticInput';
import { withContainer } from 'react-withcontainer';

export interface ExtendedAddToRecommendationsModalUiProps extends AddToRecommendationsModalUiProps {
}

export const AddToRecommendationsModalUi = (props: ExtendedAddToRecommendationsModalUiProps) => {
    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.currentTarget;
        props.changeModel({
            [target.name]: (target.type == 'checkbox' ? target.checked : target.value)
        });
    }, [props.changeModel]);

    const onValidate = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        props.validate([event.currentTarget.name]);
    }, [props.validate]);

    // Display the UI.

    if (!props.model) {
        return (<></>);
    }

    return (
        <Modal size="lg" isOpen={true /* If the modal is rendered we render it open */}>
            <ModalHeader>
                {
                    <>Why do you want to add this question to the possible areas for development?</>
                }
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={props.savingErrors} />

                <ValidatedElasticInput type="textarea" name="description" placeholder="Area for development description" value={props.model.description} onChange={onChange} onBlur={onValidate} validationErrors={props.validationErrors} />
            </ModalBody>
            <ModalFooter className="toolbar-bottom">
                <ButtonAsync color="primary" onClick={props.save} isExecuting={props.isSaving}
                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> Saving...</>}>
                    Save
                </ButtonAsync>
                <Button color="primary" outline onClick={props.cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export const AddToRecommendationsModal = withContainer(AddToRecommendationsModalContainer)(AddToRecommendationsModalUi);